import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RouteBrick } from './route-brick';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthenticationPageGuardService } from './services/authentication-page-guard.service';
import { CriticalErrorPageComponent } from './shared-ui/pages/critical-error/critical-error-page.component';
import { PageNotFoundComponent } from './shared-ui/pages/page-not-found/page-not-found.component';

export const ROUTES: Routes = [
    {
        // TODO: The route is dedicated for development reasons only. It bypasses the
        // authorization and app loading phases.
        // Protect it when going live!
        path: RouteBrick.Dev,
        loadChildren: () => import('./features/dev/dev.module').then((m) => m.DevModule),
    },
    {
        path: RouteBrick.Authentication,
        canLoad: [AuthenticationPageGuardService],
        loadChildren: () =>
            import('./authentication-page/authentication-page.module').then(
                (m) => m.AuthenticationPageModule
            ),
    },
    { path: RouteBrick.Error, component: CriticalErrorPageComponent },
    {
        path: '',
        canLoad: [AuthGuardService],
        loadChildren: () => import('./main-app/main-app.module').then((m) => m.MainAppModule),
    },

    { path: '**', component: PageNotFoundComponent },
];

export const AppRoutingModule = RouterModule.forRoot(ROUTES, {
    useHash: false,
    preloadingStrategy: PreloadAllModules,
    enableTracing: false,
    relativeLinkResolution: 'legacy',
});
