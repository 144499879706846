import { AddRiskPayload, PatchRiskPayload } from 'app/core/store/risks/risk.payloads';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import {
    LoadByProjectIdPayload,
    LoadBySiteIdPayload,
} from '../../../../store/shared/models/load.payload';
import { IdPayload } from '../../../../store/shared/models/shared-payloads';

export const getRiskCommand = (payload: IdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projectRisks', value: payload.id }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadRisksByProjectIdCommand = (
    payload: LoadByProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projects', value: payload.projectId }, { name: 'risks' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadRisksBySiteIdCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'risks' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addRiskCommand = (payload: AddRiskPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projectRisks' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchRiskCommand = (payload: PatchRiskPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projectRisks', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteRiskCommand = (payload: IdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projectRisks', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};
