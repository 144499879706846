import { FileNode } from '../../document';
import { Model } from '../../shared/models/base.model';

export class Vendor extends Model {
    id: string;
    projectId: string;
    name: string;
    description?: string;
    expenses?: VendorExpense[];

    constructor(init?: Partial<Vendor>) {
        super();
        Object.assign(this, init);
    }
}

export interface VendorExpense {
    id: string;
    name: string;
    estimates: VendorExpenseSection;
    actuals: VendorExpenseSection;
}

export interface VendorExpenseSection {
    id: string;
    value?: number;
}

export interface VendorExtended extends Vendor {
    estimatesTotalValue?: number;
    actualsTotalValue?: number;
    attachedDocuments: FileNode[];
}
