import { ImportSystemTemplatePayload } from 'app/core/models/shared-models';
import {
    AddListContainerPayload,
    ChangeListContainerTemplatePayload,
    LoadListContainersBySiteIdPayload,
    PatchListContainerPayload,
} from 'app/core/store/project-phase-container/list-container.payloads';
import {
    LoadByProjectIdPayload,
    LoadBySiteIdPayload,
} from 'app/core/store/shared/models/load.payload';
import { IdPayload } from 'app/core/store/shared/models/shared-payloads';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';

export const loadSystemListContainersCommand = (
    payload: undefined,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'listContainers/systemTemplates' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadSystemListContainersBySiteIdCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'listContainers/systemTemplates' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadListContainersBySiteIdCommand = (
    payload: LoadListContainersBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'listContainers' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadListContainerByProjectIdCommand = (
    payload: LoadByProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projects', value: payload.projectId },
        { name: 'listContainers' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const importListContainerCommand = (
    payload: ImportSystemTemplatePayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'listContainers/systemTemplates/import' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const addListContainerCommand = (
    payload: AddListContainerPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'listContainers' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchListContainerCommand = (
    payload: PatchListContainerPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'listContainers', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteListContainerCommand = (payload: IdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'listContainers', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};

export const changeListContainerTemplateCommand = (
    payload: ChangeListContainerTemplatePayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'listContainers/changeTemplate', value: payload.templateId },
        { name: 'projects', value: payload.projectId },
    ]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};
