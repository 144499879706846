import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getSubscriptionFromDto, Subscription } from 'app/core/models/billing/subscription.model';
import { StateEntry } from '../state-entry';
import { SubscriptionSelectors } from '../subscription';
import { adapter, LoadingStatus, State } from './site.store';

export const selectSiteEntry = createFeatureSelector<State>(StateEntry.Site);

export const selectLoadingStatus = createSelector(
    selectSiteEntry,
    (state): LoadingStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
    })
);

// Simplified object destructuring is consciously not used due to incorrect work of
// find usage feature (Shift+F12) - remove right hand side destructuring component
// (after semicolon) and try to find usages - it results to many unwanted entries found
// TODO: track related issue https://github.com/Microsoft/TypeScript/issues/15787
export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectSiteEntry);

// TODO: consider replacing all site selectors with ones that encounter 'loaded' flag
export const selectTotalLoaded = createSelector(selectTotal, selectLoadingStatus, (total, status) =>
    status?.loaded ? total : null
);

export const selectAllLoaded = createSelector(selectAll, selectLoadingStatus, (sites, status) =>
    status?.loaded ? sites : null
);

export const selectInitialSiteId = createSelector(selectSiteEntry, (state) => state.initialSiteId);

export const selectCurrentSiteId = createSelector(selectSiteEntry, (state) => state.currentSiteId);

export const selectCurrentSite = createSelector(selectSiteEntry, (state) =>
    state.currentSiteId ? state.entities[state.currentSiteId] : null
);

export const selectCurrentSiteSubscription = createSelector(
    selectCurrentSiteId,
    SubscriptionSelectors.selectAll,
    (id, subscriptions) => {
        const subscription = subscriptions.find((s) => s.siteId === id);
        return subscription ? getSubscriptionFromDto(subscription) : null;
    }
);

export const currentSiteSubscriptionIsGrace = createSelector(
    selectCurrentSiteSubscription,
    (subscription) => subscription?.isGrace()
);

export const selectNotExpiredSites = createSelector(
    selectAll,
    SubscriptionSelectors.selectAll,
    (sites, subscriptions) => {
        const subscriptionDictionary: { [id: string]: Subscription } = {};
        subscriptions.forEach((sub) => {
            subscriptionDictionary[sub.siteId] = getSubscriptionFromDto(sub);
        });
        return sites.filter(
            (site) =>
                subscriptionDictionary[site.id] && !subscriptionDictionary[site.id].isExpired()
        );
    }
);

export const selectCurrentSiteMetrics = createSelector(selectSiteEntry, (state) => {
    return state.currentSiteMetrics;
});

export const selectCurrentSiteMetricsLoadingStatus = createSelector(
    selectSiteEntry,
    (state) => state.siteMetricsLoading
);

export const selectSignalrConnectionId = createSelector(
    selectSiteEntry,
    (state) => state.signalrConnectionId
);
