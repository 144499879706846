import { ScheduleTableConfig } from 'app/shared-ui/smart-components/grid-lab/table/extensions/schedule/schedule.types';
import { TreeTableConfig } from 'app/shared-ui/smart-components/grid-lab/table/extensions/tree';
import { ColumnShape, GlRowData, TextStyle } from 'app/shared-ui/smart-components/grid-lab/types';
import { Model } from '../../shared/models/base.model';

export class GlTableSettings extends Model {
    id: string;
    listContainerId?: string;

    columnShapes: ColumnShape[];
    rows: GlRowData[];
    glOrderedColumnIds?: number[];
    glOrderedRowIds?: string[];
    glStyle?: TextStyle;
    glCollapsedRowIds?: string[];

    // Only FE props
    glDataObjectKeyProperty: string;
    glHiddenColumnIds?: number[];
    glTree?: TreeTableConfig;
    glSchedule?: ScheduleTableConfig;

    glMinimumRowCount?: number;
    glAutoAddRowCount?: number;

    constructor(init?: Partial<GlTableSettings>) {
        super();
        Object.assign(this, init);
    }
}

export const glTableSettingsPatchableProperties: (keyof GlTableSettings)[] = [
    'columnShapes',
    'rows',
    'glOrderedColumnIds',
    'glOrderedRowIds',
    'glCollapsedRowIds',
    'glStyle',
];
