import { Action, createAction, props } from '@ngrx/store';
import { ImportSystemTemplatePayload } from 'app/core/models/shared-models';
import { ProjectTemplate } from '.';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import { LoadBySiteIdPayload } from './../shared/models/load.payload';
import {
    AddProjectTemplatePayload,
    PatchProjectTemplatePayload,
    ProjectTemplateIdPayload,
    SetDefaultProjectTemplatePayload,
    UpdateProjectTemplatePayload,
} from './project-template.payloads';

// Get ProjectTemplates actions

export const loadCompletixProjectTemplates = createAction(
    '[ProjectTemplate] Load Completix templates'
);

export const loadCompletixProjectTemplatesSuccess = createAction(
    '[ProjectTemplate] Load Completix templates success',
    props<{ templates: ProjectTemplate[] }>()
);

export const loadCompletixProjectTemplatesFail = createAction(
    '[ProjectTemplate] Load Completix templates fail',
    props<{ error: any }>()
);

export const loadCompletixProjectTemplatesBySiteId = createAction(
    '[ProjectTemplate] Load Completix templates by Site id',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadProjectTemplates = createAction(
    '[ProjectTemplate] Load',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadProjectTemplatesSuccess = createAction(
    '[ProjectTemplate] Load success',
    props<{ templates: ProjectTemplate[] }>()
);

export const loadProjectTemplatesFail = createAction(
    '[ProjectTemplate] Load fail',
    props<{ error: any }>()
);

// Add Project Template actions

export const importCompletixProjectTemplate = createAction(
    '[ProjectTemplate] Import Completix template',
    props<{ payload: ImportSystemTemplatePayload }>()
);

export const addProjectTemplate = createAction(
    '[ProjectTemplate] Add',
    props<{ payload: AddProjectTemplatePayload }>()
);

export const addProjectTemplateSuccess = createAction(
    '[ProjectTemplate] Add success',
    props<{ template: ProjectTemplate }>()
);

export const addProjectTemplateFail = createAction(
    '[ProjectTemplate] Add fail',
    props<{ error: any }>()
);

// Update Project Template actions

export const updateProjectTemplate = createAction(
    '[ProjectTemplate] Update',
    props<{ payload: UpdateProjectTemplatePayload; options?: OptimisticableActionOptions }>()
);

export const patchProjectTemplate = createAction(
    '[ProjectTemplate] Patch',
    props<{ payload: PatchProjectTemplatePayload; options?: OptimisticableActionOptions }>()
);

export const patchProjectTemplateSuccess = createAction(
    '[ProjectTemplate] Patch success',
    props<PatchProjectTemplatePayload>()
);

export const patchProjectTemplateFail = createAction(
    '[ProjectTemplate] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Set Default Project Template actions

export const setDefaultProjectTemplate = createAction(
    '[ProjectTemplate] Set default',
    props<SetDefaultProjectTemplatePayload>()
);

// Delete ProjectTemplate actions

export const deleteProjectTemplate = createAction(
    '[ProjectTemplate] Delete',
    props<{ payload: ProjectTemplateIdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteProjectTemplateSuccess = createAction(
    '[ProjectTemplate] Delete success',
    props<ProjectTemplateIdPayload>()
);

export const deleteProjectTemplateFail = createAction(
    '[ProjectTemplate] Delete fail',
    props<{ error: any; originAction: Action }>()
);
