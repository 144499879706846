import { JsonProperty } from 'app/utils/json-mapper';
import { Model } from './base.model';

export abstract class Role extends Model {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('name')
    name: string = undefined;

    @JsonProperty('siteId')
    siteId?: string;

    @JsonProperty('isTemplate')
    isTemplate?: boolean;

    @JsonProperty('description')
    description?: string;

    @JsonProperty('descriptionIdealFor')
    descriptionIdealFor?: string;

    constructor(init?: Partial<Role>) {
        super();
        Object.assign(this, init);
    }
}
