import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import {
    AddReminderPayload,
    PatchReminderPayload,
    ReminderIdPayload,
} from '../../../../store/reminder/reminder.payloads';
import { LoadBySiteIdPayload } from '../../../../store/shared/models/load.payload';

export const loadRemindersBySiteIdCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'reminders' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addReminderCommand = (payload: AddReminderPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'reminders' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchReminderCommand = (
    payload: PatchReminderPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'reminders', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteReminderCommand = (payload: ReminderIdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'reminders', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};
