import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { getSubscriptionFromDto } from 'app/core/models/billing';
import {
    AllSiteRoles,
    GetCurrentSiteResponse,
    GetRolesBySiteResponse,
} from 'app/core/services/rest-api/features/site/site.responses';
import { Site } from 'app/core/store/site';
import { SiteSettings } from 'app/core/store/site-settings';
import { RouteBrick } from 'app/route-brick';
import { BootstrapService } from 'app/services/bootstrap.service';
import { SnackBarService } from 'app/services/snack-bar/snack-bar.service';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    map,
    mergeMap,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { SiteSelectors } from '.';
import { AppStore } from '..';
import { RestApiService } from '../../services/rest-api';
import { SignalRApiService } from '../../services/signalr-api/signalr-api.service';
import { AccountActions, AccountSelectors } from '../account';
import { ApprovalRequestActions, ApprovalState } from '../approval-request';
import { LayoutActions } from '../layout';
import { LayoutSettings } from '../layout/models';
import { ProgramRole, ProgramRoleActions } from '../program-role';
import { ProjectRole, ProjectRoleActions } from '../project-role';
import { okEmptyAction } from '../project/project.actions';
import { SiteMember, SiteMemberActions } from '../resource';
import { Model } from '../shared/models/base.model';
import { OptimisticDefaults } from '../shared/optimisticable-action';
import { SiteRole, SiteRoleActions } from '../site-role';
import { SiteSettingsActions } from '../site-settings';
import { SubscriptionActions, SubscriptionSelectors } from '../subscription';
import { selectAccountSiteMembership } from './../shared/account-mebership.selectors';
import * as SiteActions from './site.actions';

@Injectable()
export class SiteEffects {
    loadSites$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteActions.loadSites),
            switchMap((action) => {
                return this.restApiService.process(action).pipe(
                    map((result: CommandResult) =>
                        result.payload.map((dto) => Model.createFromDto(Site, dto))
                    ),
                    switchMap((sites) => {
                        // const loadSubscriptionActions = sites.map((s) =>
                        //     SubscriptionActions.getActiveSubscription({ payload: { siteId: s.id } })
                        // );
                        return [SiteActions.loadSitesSuccess({ sites })];
                    }),
                    catchError((error: any) => {
                        return of(SiteActions.loadSitesFail({ error }));
                    })
                );
            })
        )
    );

    loadInitialSite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteActions.loadInitialSite),
            switchMap((action) => {
                return this.restApiService.process(action).pipe(
                    switchMap((response: GetCurrentSiteResponse) => {
                        if (!response.payload?.site) {
                            this.router.navigate([RouteBrick.Welcome]);
                            return [SiteActions.setCurrentSiteLoaded({ siteId: null })];
                        }
                        return [
                            SiteActions.loadInitialSiteSuccess({
                                site: Model.createFromDto(Site, response.payload.site),
                            }),
                            SiteSettingsActions.loadSiteSettingsSuccess({
                                settings: Model.createFromDto(
                                    SiteSettings,
                                    response.payload.settings
                                ),
                            }),
                            SubscriptionActions.getActiveSubscriptionSuccess({
                                subscription: getSubscriptionFromDto(response.payload.subscription),
                            }),
                            SiteMemberActions.loadMySiteMemberSuccess({
                                siteMember: Model.createFromDto(
                                    SiteMember,
                                    response.payload.siteMember
                                ),
                            }),
                            ...this.getGetRolesSuccessActions(
                                response.payload.roles,
                                response.payload.site.id
                            ),
                            SiteActions.setCurrentSiteLoaded({ siteId: response.payload.site.id }),
                            LayoutActions.loadLayoutSettingsSuccess(
                                Model.createFromDto(LayoutSettings, response.payload.layoutSettings)
                            ),
                        ];
                    }),
                    catchError((error: any) => {
                        return of(SiteActions.loadSitesFail({ error }));
                    })
                );
            })
        )
    );

    loadRolesBySite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteActions.loadRolesBySite),
            switchMap((action) => {
                return this.restApiService.process(action).pipe(
                    switchMap((result: GetRolesBySiteResponse) =>
                        this.getGetRolesSuccessActions(result.payload, action.payload.siteId)
                    ),
                    catchError((error: any) => {
                        return of(SiteActions.loadRolesBySiteFail({ error }));
                    })
                );
            })
        )
    );

    loadSiteMetrics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteActions.loadSiteMetrics),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) =>
                        SiteActions.loadSiteMetricsSuccess({
                            siteMetrics: response.payload,
                        })
                    ),
                    catchError((error: any) => {
                        return of(SiteActions.loadSiteMetricsFail({ error }));
                    })
                )
            )
        )
    );

    getSiteById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteActions.getSiteById),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((result: CommandResult) => Model.createFromDto(Site, result.payload)),
                    switchMap((site) => {
                        return [
                            SiteActions.getSiteByIdSuccess({ site }),
                            // SubscriptionActions.getActiveSubscription({
                            //     payload: { siteId: site.id },
                            // }),
                        ];
                    }),
                    catchError((error: any) => {
                        return of(SiteActions.getSiteByIdFail({ error }));
                    })
                )
            )
        )
    );

    addSite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteActions.addSite),
            withLatestFrom(this.store.select(SiteSelectors.selectAll)),
            mergeMap(([action, allSites]) => {
                this.snackBarService.showInfiniteBar('Creating site...');
                return this.restApiService.process(action).pipe(
                    map((result: CommandResult) => Model.createFromDto(Site, result.payload)),
                    tap(() => this.snackBarService.hide()),
                    switchMap((site) => {
                        const changeCurrentSiteIdAction = SiteActions.changeCurrentSiteId({
                            payload: { siteId: site.id },
                        });
                        return [
                            SiteActions.addSiteSuccess({ site }),
                            SubscriptionActions.getActiveSubscription({
                                payload: { siteId: site.id },
                            }),
                            !allSites.length ? changeCurrentSiteIdAction : null,
                        ].filter((a) => !!a);
                    }),
                    catchError((error: any) => {
                        this.snackBarService.hide();
                        return of(SiteActions.addSiteFail({ error }));
                    })
                );
            })
        )
    );

    addSiteSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteActions.addSiteSuccess),
            mergeMap((action) => {
                return this.restApiService.process(action).pipe(
                    map((result: CommandResult) => Model.createFromDto(Site, result.payload)),
                    map((site) => {
                        return SiteActions.addSiteSuccess({ site });
                    }),
                    catchError((error: any) => of(SiteActions.addSiteFail({ error })))
                );
            })
        )
    );

    patchSite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteActions.patchSite),
            switchMap((action) =>
                this.restApiService.process(action).pipe(
                    map((result: CommandResult) => {
                        return SiteActions.patchSiteSuccess({
                            siteId: action.payload.siteId,
                            patch: result.payload,
                        });
                    }),
                    catchError((error: any) => of(SiteActions.patchSiteFail({ error })))
                )
            )
        )
    );

    changeCurrentSite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteActions.changeCurrentSiteId),
            filter((action) => action.payload.siteId != null),
            withLatestFrom(this.store.select(SubscriptionSelectors.selectAll)),
            tap(([action, subscriptions]) => {
                this.signalrApiService.listenSite(action.payload.siteId);
                const subscription = subscriptions.find((s) => s.siteId === action.payload.siteId);
                if (!subscription) {
                    this.store.dispatch(
                        SubscriptionActions.getActiveSubscription({
                            payload: { siteId: action.payload.siteId },
                        })
                    );
                }
            }),
            withLatestFrom(this.store.select(AccountSelectors.selectUserAccount)),
            mergeMap(([[action], account]) =>
                of(
                    AccountActions.updateAccount({
                        payload: {
                            accountId: account.id,
                            changes: { lastOpenedSiteId: action.payload.siteId },
                        },
                        options: OptimisticDefaults,
                    })
                )
            )
        )
    );

    requestChangeSiteOwner$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteActions.requestChangeSiteOwner),
            switchMap((action) => {
                if (!action.options?.optimistic) {
                    this.snackBarService.showInfiniteBar('Changing the site owner...');
                }
                return this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        this.snackBarService.hide();
                        return SiteActions.patchSiteSuccess({
                            siteId: action.payload.siteId,
                            patch: response.payload,
                        });
                    }),
                    catchError((error: any) => {
                        this.snackBarService.hide();
                        return of(
                            SiteActions.requestChangeSiteOwnerFail({ error, originAction: action })
                        );
                    })
                );
            })
        )
    );

    cancelRequestChangeSiteOwner$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteActions.cancelRequestChangeSiteOwner),
            switchMap((action) => {
                if (!action.options?.optimistic) {
                    this.snackBarService.showInfiniteBar('Canceling the site owner change...');
                }
                return this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        this.snackBarService.hide();
                        return SiteActions.patchSiteSuccess({
                            siteId: action.payload.siteId,
                            patch: response.payload,
                        });
                    }),
                    catchError((error: any) => {
                        this.snackBarService.hide();
                        return of(
                            SiteActions.cancelRequestChangeSiteOwnerFail({
                                error,
                                originAction: action,
                            })
                        );
                    })
                );
            })
        )
    );

    acceptRequestChangeSiteOwner$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteActions.acceptRequestChangeSiteOwner),
            switchMap((action) => {
                this.snackBarService.showInfiniteBar('Accepting the site owner change...');
                return this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        this.snackBarService.hide();
                        response.payload.siteMemberPatches.forEach((memberPatchResponse) =>
                            this.store.dispatch(
                                SiteMemberActions.patchSiteMemberSuccess({
                                    id: memberPatchResponse.id,
                                    patch: memberPatchResponse.patch,
                                })
                            )
                        );
                        this.store.dispatch(
                            SiteActions.patchSiteSuccess({
                                siteId: response.payload.siteId,
                                patch: response.payload.sitePatch,
                            })
                        );
                        this.bootService.loadCurrentSiteData(action.payload.siteId);
                        return SiteActions.acceptRequestChangeSiteOwnerSuccess({
                            siteId: action.payload.siteId,
                        });
                    }),
                    catchError((error: any) => {
                        this.snackBarService.hide();
                        return of(SiteActions.acceptRequestChangeSiteOwnerFail({ error }));
                    })
                );
            })
        )
    );

    manageSiteOwnerChangeApproval$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteActions.manageSiteOwnerChangeApproval),
            withLatestFrom(this.store.select(selectAccountSiteMembership)),
            mergeMap(([action, accountSiteMember]) => {
                this.store.dispatch(
                    ApprovalRequestActions.updateApprovalState({
                        id: action.payload.approvalId,
                        siteMemberId: accountSiteMember.id,
                        state: action.payload.approvalState,
                    })
                );
                const approvalAction =
                    action.payload.approvalState === ApprovalState.Approved
                        ? SiteActions.acceptRequestChangeSiteOwner({
                              payload: { siteId: action.payload.siteId },
                          })
                        : SiteActions.cancelRequestChangeSiteOwner({
                              payload: { siteId: action.payload.siteId },
                              options: OptimisticDefaults,
                          });
                return of(approvalAction);
            })
        )
    );

    constructor(
        private actions$: Actions,
        private store: Store<AppStore.AppState>,
        private restApiService: RestApiService,
        private snackBarService: SnackBarService,
        private signalrApiService: SignalRApiService,
        private bootService: BootstrapService,
        private router: Router
    ) {}

    private getGetRolesSuccessActions(payload: AllSiteRoles, siteId: string): Action[] {
        return [
            SiteRoleActions.loadRolesBySiteSuccess({
                roles: payload.siteRoles.map((role) => Model.createFromDto(SiteRole, role)),
                siteId: siteId,
            }),
            ProgramRoleActions.loadProgramRolesBySiteSuccess({
                roles: payload.programRoles.map((role) => Model.createFromDto(ProgramRole, role)),
            }),
            ProjectRoleActions.loadProjectRolesBySiteSuccess({
                roles: payload.projectRoles.map((role) => Model.createFromDto(ProjectRole, role)),
            }),
        ];
    }
}
