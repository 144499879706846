import { createFeatureSelector, createSelector } from '@ngrx/store';
import { KanbanBoardSelectors, KanbanBoardShapeLink } from '../kanban';
import { ProjectSelectors } from '../project';
import { CtxFieldItemType, ProjectFieldSelectors, ProjectFieldType } from '../project-field';
import { ProjectRoleSelectors } from '../project-role';
import { selectCurrentProjectId } from '../project/project.selectors';
import { selectAccountSiteMembership } from '../shared/account-mebership.selectors';
import { getRaidStatusListByProjectMap } from '../shared/models/raid-shared.model';
import { getRaidRecordPermissionsMap } from '../shared/models/shared-permissions.model';
import { StateEntry } from '../state-entry';
import { adapter, LoadingStatus, State } from './issue.store';
import { IssueRecord } from './models';

export const selectIssueEntry = createFeatureSelector<State>(StateEntry.Issue);

export const selectLoadingStatus = createSelector(
    selectIssueEntry,
    (state): LoadingStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
    })
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
    selectEntityStoredInDb: selectEntityStoredInDb,
} = adapter.getCtxSelectors(selectIssueEntry);

export const selectCurrentProjectIssues = createSelector(
    selectAll,
    ProjectSelectors.selectCurrentProjectId,
    (Issues, projectId) => Issues.filter((i) => i.projectId === projectId)
);

export const selectCurrentProjectOpenIssues = createSelector(
    selectCurrentProjectIssues,
    ProjectFieldSelectors.selectCurrentSiteProjectFieldItemsByType(ProjectFieldType.IssuesStatus),
    (issues, issueStatuses) => {
        const openIssueStatuses = (issueStatuses || []).filter(
            (status) => status.ctxItemType !== CtxFieldItemType.Closed
        );
        return issues.filter((issue) =>
            openIssueStatuses.map((s) => s.id).includes(issue.statusId)
        );
    }
);

export const selectIssuePermissionsMap = createSelector(
    selectAll,
    ProjectRoleSelectors.selectAccountProjectRolesMap,
    selectAccountSiteMembership,
    (issues, projectRolesMap, accountSiteMembership) =>
        getRaidRecordPermissionsMap(
            issues,
            projectRolesMap,
            'issues',
            (issue: IssueRecord) => issue.assignedMember?.siteMemberId === accountSiteMembership.id
        )
);

export const selectCurrentSiteIssuesStatusListMap = createSelector(
    ProjectSelectors.selectCurrentSiteProjects,
    KanbanBoardSelectors.selectAll,
    ProjectFieldSelectors.selectCurrentSiteProjectFieldByType(ProjectFieldType.IssuesStatus),
    (projects, kanbanBoards, risksProjectField) =>
        getRaidStatusListByProjectMap(
            projects,
            kanbanBoards,
            KanbanBoardShapeLink.Issues,
            risksProjectField
        )
);

export const selectCurrentProjectIssueStatuses = createSelector(
    selectCurrentSiteIssuesStatusListMap,
    selectCurrentProjectId,
    (map, currentProjectId) => map[currentProjectId]
);
