import { Subscription, SubscriptionPlan } from 'app/core/models/billing';
import { LayoutSettings } from 'app/core/store/layout/models';
import { ProgramRole } from 'app/core/store/program-role';
import { ProjectRole } from 'app/core/store/project-role';
import { SiteMember } from 'app/core/store/resource';
import { SiteRole } from 'app/core/store/site-role';
import { SiteSettings } from 'app/core/store/site-settings';
import { CommandResult } from 'app/utils/network';
import { JsonResponse } from 'app/utils/network/responses/json.response';
import { Operation } from 'fast-json-patch';
import { Site } from '../../../../store/site';

export class GetSiteSubscriptionPlansResponse extends JsonResponse {
    subscriptionPlans: SubscriptionPlan[];

    constructor(commandResult: CommandResult) {
        super(commandResult);
        this.subscriptionPlans = this.deserializeModels(SubscriptionPlan);
    }
}

export class LoadSitesResponse extends JsonResponse {
    sites: Site[];

    constructor(commandResult: CommandResult) {
        super(commandResult);
        this.sites = this.deserializeModels(Site);
    }
}

export class GetSiteByIdResponse extends JsonResponse {
    site: Site;

    constructor(commandResult: CommandResult) {
        super(commandResult);
        this.site = this.deserializeModel(Site);
    }
}

export class AddSiteResponse extends JsonResponse {
    site: Site;

    constructor(commandResult: CommandResult) {
        super(commandResult);
        this.site = this.deserializeModel(Site);
    }
}

export class PatchSiteResponse extends JsonResponse {
    site: Site;

    constructor(commandResult: CommandResult) {
        super(commandResult);
        this.site = this.deserializeModel(Site);
    }
}

export class GetSubscriptionPlanByIdResponse extends JsonResponse {
    subscriptionPlan: SubscriptionPlan;

    constructor(commandResult: CommandResult) {
        super(commandResult);
        this.subscriptionPlan = this.deserializeModel(SubscriptionPlan);
    }
}

export class ChangeSiteOwnerResponse {
    payload: {
        site: Site;
        siteMemberPatches: { id: string; patch: Operation[] }[];
    };
}

export interface AllSiteRoles {
    siteRoles: SiteRole[];
    programRoles: ProgramRole[];
    projectRoles: ProjectRole[];
}

export class GetRolesBySiteResponse {
    payload: AllSiteRoles;
}

export class GetCurrentSiteResponse {
    payload: {
        site: Site;
        subscription: Subscription;
        settings: SiteSettings;
        roles: AllSiteRoles;
        siteMember: SiteMember;
        layoutSettings: LayoutSettings;
    };
}
