import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getPatch } from 'app/utils/json-patch';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { RestApiService } from '../../services/rest-api';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import * as AppStore from '../store';
import * as GlTableSettingsActions from './gl-table-settings.actions';
import * as GlTableSettingsSelectors from './gl-table-settings.selectors';
import { GlTableSettings, glTableSettingsPatchableProperties } from './models';

@Injectable()
export class GlTableSettingsEffects {
    loadGlTableSettingssBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GlTableSettingsActions.loadGlTableSettingsById),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        GlTableSettingsActions.loadGlTableSettingsByIdSuccess({
                            glTableSettings: Model.createFromDto(GlTableSettings, response.payload),
                        })
                    ),
                    catchError((error) =>
                        of(
                            GlTableSettingsActions.loadGlTableSettingsByIdFail({
                                error,
                                id: action.payload.id,
                            })
                        )
                    )
                )
            )
        )
    );

    updateGlTableSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GlTableSettingsActions.updateGlTableSettings),
            withLatestFrom(this.store$.select(GlTableSettingsSelectors.selectAll)),
            mergeMap(([action, glTableSettingss]) => {
                const glTableSettings = glTableSettingss.find((c) => c.id === action.payload.id);
                const patch = getPatch(glTableSettings, action.payload.changes);
                return of(
                    GlTableSettingsActions.patchGlTableSettings({
                        payload: {
                            id: action.payload.id,
                            patch: patch.filter((op) =>
                                glTableSettingsPatchableProperties.some((prop) =>
                                    op.path.includes(prop)
                                )
                            ),
                        },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchGlTableSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GlTableSettingsActions.patchGlTableSettings),
            mergeMap((action) => {
                return this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return GlTableSettingsActions.patchGlTableSettingsSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) =>
                        of(
                            GlTableSettingsActions.patchGlTableSettingsFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>
    ) {}
}
