import { LoadBySiteIdPayload } from 'app/core/store/shared/models/load.payload';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import {
    AddSiteRolePayload,
    PatchSiteRolePayload,
    SetSiteRoleTagPayload,
    SiteRoleIdPayload,
} from './../../../../store/site-role/site-role.payloads';

export const loadSiteRoleTemplates = (payload: undefined, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'sites' }, { name: 'roles' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadSiteRolesBySite = (payload: LoadBySiteIdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'roles' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addSiteRole = (payload: AddSiteRolePayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'sites' }, { name: 'roles' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchSiteRole = (payload: PatchSiteRolePayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'sites' }, { name: 'roles', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const setSiteRoleTag = (payload: SetSiteRoleTagPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([
        { name: 'sites' },
        { name: 'roles', value: payload.roleId },
        { name: 'setTag' },
    ]);
    baseCommand.payload.setData(payload.tag);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const deleteSiteRole = (payload: SiteRoleIdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'sites' }, { name: 'roles', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};
