import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import { getRecordNewTextId } from '../shared/models/raid-shared.model';
import { RiskRecord } from './models';
import * as RiskActions from './risk.actions';
import { adapter, initialState } from './risk.store';

export const reducer = createReducer(
    initialState,

    on(RiskActions.getRiskSuccess, (state, payload) =>
        adapter.addOne(payload.risk, adapter.removeOne(payload.risk.id, state))
    ),

    on(RiskActions.loadRisksBySiteId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(RiskActions.loadRisksBySiteIdSuccess, (state, payload) => {
        const newState = adapter.addMany(payload.risks, adapter.removeAll(state));
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(RiskActions.loadRisksBySiteIdFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(RiskActions.loadRisksByProjectId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(RiskActions.loadRisksByProjectIdSuccess, (state, payload) => {
        const newState = adapter.addMany(
            payload.risks,
            adapter.removeMany(
                payload.risks.map((r) => r.id),
                state
            )
        );
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(RiskActions.loadRisksByProjectIdFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(RiskActions.addRisk, (state, action) => {
        if (action.options?.optimistic) {
            const risks = Object.values(state.entities).filter(
                (r) => action.payload.projectId === r.projectId
            );
            return adapter.addOneOptimistic(
                new RiskRecord({
                    ...action.payload,
                    creationDate: new Date().toString(),
                    dateRaised: new Date(),
                    textId: getRecordNewTextId(
                        risks.map((r) => r.textId),
                        'R'
                    ),
                }),
                state
            );
        }
        return { ...state };
    }),

    on(RiskActions.addRiskSuccess, (state, payload) =>
        payload.options?.optimistic
            ? adapter.addOneOptimisticSuccess(payload.risk, state)
            : adapter.addOne(payload.risk, state)
    ),

    on(RiskActions.addRiskFail, (state, { riskId }) => adapter.addOneOptimisticFail(riskId, state)),

    on(RiskActions.patchRisk, (state, action) => {
        if (!action.options?.optimistic) return;
        const patched = getPatched(state.entities[action.payload.id], action.payload.patch);
        const update: Update<RiskRecord> = { id: patched.id, changes: patched };
        return adapter.updateOne(update, state);
    }),

    on(RiskActions.patchRiskSuccess, (state, payload) => {
        const patched = getPatched(state.entities[payload.id], payload.patch);
        const update: Update<RiskRecord> = { id: patched.id, changes: patched };
        return adapter.updateOne(update, state);
    }),

    on(RiskActions.patchRiskFail, (state) => state),

    on(RiskActions.deleteRisk, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(RiskActions.deleteRiskSuccess, (state, payload) => adapter.removeOne(payload.id, state)),

    on(RiskActions.deleteRiskFail, (state) => state)
);
