import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import {
    AsyncUpdateStatusDefaultLoad,
    AsyncUpdateStatusDefaultLoadSuccess,
} from '../shared/async-update-status.store';
import { SiteSettings } from './models';
import * as SiteSettingsActions from './site-settings.actions';
import { adapter, initialState } from './site-settings.store';

export const reducer = createReducer(
    initialState,
    on(SiteSettingsActions.loadSiteSettingsCompletixTemplates, (state) => ({
        ...state,
        ...AsyncUpdateStatusDefaultLoad,
    })),

    on(SiteSettingsActions.loadSiteSettingsCompletixTemplatesSuccess, (state, payload) => {
        const templateIds = Object.values(state.entities)
            .filter((s) => s.isCompletixTemplate)
            .map((s) => s.id);
        const newState = adapter.addMany(payload.settings, adapter.removeMany(templateIds, state));
        return { ...newState, ...AsyncUpdateStatusDefaultLoadSuccess };
    }),

    on(SiteSettingsActions.loadSiteSettingsCompletixTemplatesFail, (state) => ({
        ...state,
        ...AsyncUpdateStatusDefaultLoad,
        serverRequestInProgress: false,
    })),

    on(SiteSettingsActions.loadSiteSettings, (state) => ({
        ...state,
    })),

    on(SiteSettingsActions.loadSiteSettingsSuccess, (state, payload) =>
        adapter.addOne(payload.settings, adapter.removeOne(payload.settings.id, state))
    ),

    on(SiteSettingsActions.loadSiteSettingsFail, (state) => ({
        ...state,
    })),

    on(SiteSettingsActions.addSiteSettingsSuccess, (state, payload) =>
        adapter.addOne(payload.settings, state)
    ),

    on(SiteSettingsActions.patchSiteSettings, (state, action) => {
        if (!action.options?.optimistic) return state;
        const settings = state.entities[action.payload.id];
        const patched = getPatched(settings, action.payload.patch);
        const update: Update<SiteSettings> = {
            id: patched.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(SiteSettingsActions.patchSiteSettingsSuccess, (state, payload) => {
        const settings = state.entities[payload.id];
        const patched = getPatched(settings, payload.patch);
        const update: Update<SiteSettings> = {
            id: patched.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(SiteSettingsActions.deleteSiteSettings, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(SiteSettingsActions.deleteSiteSettingsSuccess, (state, payload) => {
        return adapter.removeOne(payload.id, state);
    })
);
