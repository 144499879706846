import { createCtxEntityAdapter, CtxEntityAdapter, CtxEntityState } from '../store';
import { ActionRecord } from './models';

export interface LoadingStatus {
    serverRequestInProgress: boolean;
    loaded: boolean;
}

export interface State extends CtxEntityState<ActionRecord>, LoadingStatus {}

export const adapter: CtxEntityAdapter<ActionRecord> = createCtxEntityAdapter<ActionRecord>();

export const initialState: State = adapter.getCtxInitialState({
    serverRequestInProgress: false,
    loaded: false,
});
