import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import { deepClone } from 'fast-json-patch';
import {
    AsyncUpdateStatusDefault,
    AsyncUpdateStatusDefaultLoad,
    AsyncUpdateStatusDefaultLoadSuccess,
} from '../shared/async-update-status.store';
import { Vendor } from './models';
import * as VendorActions from './vendor.actions';
import { adapter, initialState } from './vendor.store';

export const reducer = createReducer(
    initialState,
    on(VendorActions.loadVendorsByProjectId, (state, action) => {
        const loadingStatusMap = state.loadingStatusByProjectId;
        const projectId = action.payload.projectId;

        return {
            ...state,
            loadingStatusByProjectId: {
                ...loadingStatusMap,
                [projectId]: {
                    ...(loadingStatusMap[projectId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoad,
                },
            },
        };
    }),

    on(VendorActions.loadVendorsByProjectIdSuccess, (state, payload) => {
        const projectRequestIds = Object.values(state.entities)
            .filter((cr) => cr.projectId === payload.projectId)
            .map((cr) => cr.id);
        const newState = adapter.addMany(
            payload.vendors,
            adapter.removeMany(projectRequestIds, state)
        );
        const loadingStatusMap = state.loadingStatusByProjectId;
        const projectId = payload.projectId;

        return {
            ...newState,
            loadingStatusByProjectId: {
                ...loadingStatusMap,
                [projectId]: {
                    ...(loadingStatusMap[projectId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                },
            },
        };
    }),

    on(VendorActions.loadVendorsByProjectIdFail, (state, payload) => {
        const loadingStatusMap = state.loadingStatusByProjectId;
        const projectId = payload.projectId;

        return {
            ...state,
            loadingStatusByProjectId: {
                ...loadingStatusMap,
                [projectId]: {
                    ...(loadingStatusMap[projectId] ?? {}),
                    ...AsyncUpdateStatusDefault,
                    error: payload.error,
                    loadingFailed: true,
                },
            },
        };
    }),

    on(VendorActions.loadVendorsBySiteId, (state, action) => {
        const loadingStatusMap = state.loadingStatusByProjectId;
        const siteId = action.payload.siteId;

        return {
            ...state,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoad,
                },
            },
        };
    }),

    on(VendorActions.loadVendorsBySiteIdSuccess, (state, payload) => {
        const projectIds = payload.projectIds;
        const projectRequestIds = Object.values(state.entities)
            .filter((cr) => projectIds.includes(cr.projectId))
            .map((cr) => cr.id);
        const newState = adapter.addMany(
            payload.vendors,
            adapter.removeMany(projectRequestIds, state)
        );
        const loadingStatusMap = state.loadingStatusBySiteId;
        const siteId = payload.siteId;
        const loadingByProjectIdMap = deepClone(state.loadingStatusByProjectId);
        projectIds.forEach(
            (id) =>
                (loadingByProjectIdMap[id] = {
                    ...(loadingByProjectIdMap[id] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                })
        );

        return {
            ...newState,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                },
            },
            loadingStatusByProjectId: loadingByProjectIdMap,
        };
    }),

    on(VendorActions.loadVendorsBySiteIdFail, (state, payload) => {
        const loadingStatusMap = state.loadingStatusByProjectId;
        const siteId = payload.siteId;

        return {
            ...state,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefault,
                    error: payload.error,
                    loadingFailed: true,
                },
            },
        };
    }),
    on(VendorActions.addVendorSuccess, (state, payload) => ({
        ...adapter.addOne(payload.vendor, state),
    })),

    on(VendorActions.addVendorFail, (state) => ({ ...state })),

    on(VendorActions.patchVendor, (state, action) => {
        if (!action.options?.optimistic) return;
        const vendor = state.entities[action.payload.id];
        const patchedRequest = getPatched(vendor, action.payload.patch);
        const update: Update<Vendor> = {
            id: patchedRequest.id,
            changes: patchedRequest,
        };
        return adapter.updateOne(update, state);
    }),

    on(VendorActions.patchVendorSuccess, (state, payload) => {
        const vendor = state.entities[payload.id];
        const patchedRequest = getPatched(vendor, payload.patch);
        const update: Update<Vendor> = {
            id: patchedRequest.id,
            changes: patchedRequest,
        };
        return adapter.updateOne(update, state);
    }),

    on(VendorActions.patchVendorFail, (state) => ({ ...state })),

    on(VendorActions.deleteVendor, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(VendorActions.deleteVendorSuccess, (state, payload) => adapter.removeOne(payload.id, state)),

    on(VendorActions.deleteVendorFail, (state) => ({ ...state }))
);
