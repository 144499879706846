import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import {
    AddAllocationPayload,
    AddManualAllocationPayload,
    AllocationIdPayload,
    LoadAllocationsByProjectId,
    LoadAllocationsBySiteId,
    PatchAllocationPayload,
} from '../../../../store/allocation/allocation.payloads';

export const loadAllocationsBySiteId = (
    payload: LoadAllocationsBySiteId,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'allocations' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadAllocationsByProjectId = (
    payload: LoadAllocationsByProjectId,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projects', value: payload.projectId },
        { name: 'allocations' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addAllocation = (payload: AddAllocationPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'allocations' }]);
    baseCommand.payload.setData(payload.createOptions);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const addManualAllocation = (
    payload: AddManualAllocationPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'allocations/manual' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchAllocation = (payload: PatchAllocationPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'allocations', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteAllocation = (payload: AllocationIdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'allocations', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};
