import {
    AddScheduleTaskPayload,
    PatchScheduleTaskPayload,
} from 'app/core/store/schedule-template/schedule-task.payloads';
import { LoadBySiteIdPayload } from 'app/core/store/shared/models/load.payload';
import { IdPayload } from 'app/core/store/shared/models/shared-payloads';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';

export const loadScheduleTasksByContainerIdCommand = (
    payload: { id: string; siteId?: string },
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'scheduleTasks/listContainers', value: payload.id },
        { name: 'sites', value: payload.siteId },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadScheduleTasksBySiteIdCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'scheduleTasks/sites', value: payload.siteId }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addScheduleTaskCommand = (
    payload: AddScheduleTaskPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'scheduleTasks' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchScheduleTaskCommand = (
    payload: PatchScheduleTaskPayload[],
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'scheduleTasks' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteScheduleTaskCommand = (payload: IdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'scheduleTasks', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};
