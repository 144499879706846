import { EntityScope } from 'app/core/models/shared-models';
import { GlTableSettings } from '../../gl-table-settings';
import { Model } from '../../shared/models/base.model';

export class ListContainer extends Model {
    id: string;
    scope: EntityScope;
    name?: string;
    containerType: ListContainerType;
    tableViewId?: string;
    templateContainerId?: string;
    isTemplate?: boolean;
    isDefault?: boolean;
    isPublished?: boolean;
    isInitialTemplate?: boolean;
    orderedIds?: string[];
    tableView?: GlTableSettings;

    constructor(init?: Partial<ListContainer>) {
        super();
        Object.assign(this, init);
    }
}

export enum ListContainerType {
    Schedule = 'Schedule',
    Risks = 'Risks',
    Issues = 'Issues',
    Actions = 'Actions',
    Decisions = 'Decisions',
    Gating = 'Gating',
    Backlog = 'Backlog',
}
