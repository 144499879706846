import { JsonProperty } from 'app/utils/json-mapper';
import { CtxMember, Priority } from '../../../models/shared-models';

export class BacklogTask {
    @JsonProperty('id')
    id: string;

    @JsonProperty('textId')
    textId?: string;

    @JsonProperty('listContainerId')
    listContainerId: string;

    @JsonProperty('sprintId')
    sprintId: string;

    @JsonProperty('title')
    title?: string;

    @JsonProperty('description')
    description?: string;

    @JsonProperty('progress')
    progress?: number;

    @JsonProperty('priority')
    priority?: Priority;

    @JsonProperty('status')
    status?: BacklogTaskStatus;

    @JsonProperty('members')
    members: CtxMember[];

    @JsonProperty('effort')
    effort?: number;

    constructor(init?: Partial<BacklogTask>) {
        Object.assign(this, init);
    }
}

export enum BacklogTaskStatus {
    Backlog = 'Backlog',
    Todo = 'Todo',
    InProgress = 'InProgress',
    Done = 'Done',
}

export const backlogStatusNamesMap: Record<BacklogTaskStatus, string> = {
    [BacklogTaskStatus.Backlog]: BacklogTaskStatus.Backlog,
    [BacklogTaskStatus.Todo]: BacklogTaskStatus.Todo,
    [BacklogTaskStatus.InProgress]: 'In Progress',
    [BacklogTaskStatus.Done]: BacklogTaskStatus.Done,
};
