import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getSubscriptionFromDto } from 'app/core/models/billing';
import { BootstrapService } from 'app/services/bootstrap.service';
import { SnackBarService } from 'app/services/snack-bar/snack-bar.service';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { RestApiService } from '../../services/rest-api';
import * as SubscriptionActions from './subscription.actions';

@Injectable()
export class SubscriptionEffects {
    getSubscription$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SubscriptionActions.getActiveSubscription),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    switchMap((result: CommandResult) => {
                        return of(
                            SubscriptionActions.getActiveSubscriptionSuccess({
                                subscription: getSubscriptionFromDto(result.payload),
                            })
                        );
                    }),
                    catchError((error: any) => {
                        return of(
                            SubscriptionActions.getActiveSubscriptionFail({
                                error,
                            })
                        );
                    })
                )
            )
        )
    );

    changeSubscription$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SubscriptionActions.changeSubscription),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((result: CommandResult) => {
                        return SubscriptionActions.changeSubscriptionSuccess({
                            subscription: getSubscriptionFromDto(result.payload),
                        });
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(
                            SubscriptionActions.changeSubscriptionFail({
                                error: errorResponse.error || {
                                    message: 'Subscription error. Contact us for support.',
                                },
                            })
                        );
                    })
                )
            )
        )
    );

    // subscribeSiteSuccess$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(SubscriptionActions.changeSubscriptionSuccess),
    //         tap(() => this.router.navigate([`/${RouteBrick.Site}/${RouteBrick.Billing}`]))
    //     )
    // );

    patchBillingDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SubscriptionActions.patchBillingDetails),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((result: CommandResult) => getSubscriptionFromDto(result.payload)),
                    map((subscription) =>
                        SubscriptionActions.patchBillingDetailsSuccess({ subscription })
                    ),
                    catchError((error: any) =>
                        of(SubscriptionActions.patchBillingDetailsFail({ error }))
                    )
                )
            )
        )
    );

    cancelSubscription$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SubscriptionActions.cancelSubscription),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((result: CommandResult) => {
                        return SubscriptionActions.cancelSubscriptionSuccess({
                            subscription: getSubscriptionFromDto(result.payload),
                        });
                    }),
                    catchError((error: any) => {
                        return of(SubscriptionActions.cancelSubscriptionFail({ error }));
                    })
                )
            )
        )
    );

    changeSubscriptionQuantity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SubscriptionActions.changeSubscriptionQuantity),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((result: CommandResult) => {
                        this.snackBarService.show('License quantity updated');
                        return SubscriptionActions.changeSubscriptionQuantitySuccess({
                            subscription: getSubscriptionFromDto(result.payload),
                        });
                    }),
                    catchError((error: any) => {
                        return of(SubscriptionActions.changeSubscriptionQuantityFail({ error }));
                    })
                )
            )
        )
    );

    addAdditionalSubscriptions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SubscriptionActions.addAdditionalSubscriptions),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Updating subscription...');
                return this.restApiService.process(action).pipe(
                    map((result: CommandResult) => {
                        this.snackBarService.hide();
                        this.bootService.loadCurrentSiteData(action.payload.siteId);
                        return SubscriptionActions.addAdditionalSubscriptionsSuccess({
                            subscription: getSubscriptionFromDto(result.payload),
                        });
                    }),
                    catchError((error: any) => {
                        this.snackBarService.hide();
                        return of(SubscriptionActions.addAdditionalSubscriptionsFail({ error }));
                    })
                );
            })
        )
    );

    removeAdditionalSubscription$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SubscriptionActions.removeAdditionalSubscription),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Updating subscription...');
                return this.restApiService.process(action).pipe(
                    map((result: CommandResult) => {
                        this.snackBarService.hide();
                        this.bootService.loadCurrentSiteData(action.payload.siteId);
                        return SubscriptionActions.removeAdditionalSubscriptionSuccess({
                            subscription: getSubscriptionFromDto(result.payload),
                        });
                    }),
                    catchError((error: any) => {
                        this.snackBarService.hide();
                        return of(SubscriptionActions.removeAdditionalSubscriptionFail({ error }));
                    })
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private snackBarService: SnackBarService,
        private bootService: BootstrapService
    ) {}
}
