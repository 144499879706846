import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { LoadingStatusByProjectId, LoadingStatusBySiteId } from 'app/core/models/shared-models';
import { ChangeRequest, ChangeRequestApprover } from './models';

export interface State
    extends EntityState<ChangeRequest>,
        LoadingStatusByProjectId,
        LoadingStatusBySiteId {
    expandedCrIds: string[];
    approvers: ChangeRequestApprover[];
}

export const adapter: EntityAdapter<ChangeRequest> = createEntityAdapter<ChangeRequest>();

export const initialState: State = adapter.getInitialState({
    expandedCrIds: [],
    approvers: [],
    loadingStatusByProjectId: {},
    loadingStatusBySiteId: {},
});
