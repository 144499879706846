import { EntitySubmission } from 'app/core/models/shared-models';
import { JsonProperty } from 'app/utils/json-mapper';
import { Model } from '../../shared/models/base.model';

export class ProjectPhase extends Model {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('title')
    name: string = undefined;

    @JsonProperty('containerId')
    containerId?: string;

    @JsonProperty('description')
    description?: string = undefined;

    @JsonProperty('deliverables')
    deliverables?: Deliverable[] = undefined;

    @JsonProperty('completion')
    completion?: ProjectPhaseCompletion;

    @JsonProperty('submission')
    submission?: EntitySubmission;

    constructor(init?: Partial<ProjectPhase>) {
        super();
        Object.assign(this, init);
    }
}

export interface ProjectPhaseCompletion {
    completed: boolean;
    completedByAdvance?: boolean;
    completedDate: Date;
    siteMemberId: string;
}

export class Deliverable {
    id: string;
    name: string;
    note?: string;
    templateDocumentId?: string;
    attachedDocumentId?: string;
    completed?: boolean;

    constructor(init?: Partial<Deliverable>) {
        Object.assign(this, init);
    }
}

export function phaseIsReadonly(phase: ProjectPhase): boolean {
    return !!phase?.completion?.completed || phase?.submission?.submitted;
}
