import { IdPayload } from 'app/core/store/shared/models/shared-payloads';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import {
    AddFolderPayload,
    LoadFoldersPayload,
    PatchFolderPayload,
} from '../../../../store/folder/folder.payloads';

export const loadFoldersByTagCommand = (
    payload: LoadFoldersPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'nonDocumentFolders' },
        { name: 'projects', value: payload.projectId },
        { name: 'tag', value: payload.tag },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addFolderCommand = (payload: AddFolderPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'nonDocumentFolders' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchFolderCommand = (payload: PatchFolderPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'nonDocumentFolders', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteFolderCommand = (payload: IdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'nonDocumentFolders', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};
