import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ProjectBudgetTable } from './models';

export interface LoadingStatus {
    serverRequestInProgress: boolean;
    loaded: boolean;
    projectBudgetLoading: boolean;
    completixTemplatesLoading: boolean;
}

export interface State extends EntityState<ProjectBudgetTable>, LoadingStatus {}

export const adapter: EntityAdapter<ProjectBudgetTable> = createEntityAdapter<ProjectBudgetTable>();

export const initialState: State = adapter.getInitialState({
    serverRequestInProgress: false,
    loaded: false,
    projectBudgetLoading: false,
    completixTemplatesLoading: false,
});
