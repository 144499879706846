import { Action, createAction, props } from '@ngrx/store';
import { OptimisticableActionOptions } from 'app/core/store/shared/optimisticable-action';
import { LoadBySiteIdPayload } from '../shared/models/load.payload';
import { SiteRole } from './models';
import {
    AddSiteRolePayload,
    PatchSiteRolePayload,
    SetSiteRoleTagPayload,
    SiteRoleIdPayload,
    UpdateSiteRolePayload,
} from './site-role.payloads';

// Load SiteRoles actions

export const loadSiteRoleTemplates = createAction('[SiteRole] Load templates');

export const loadSiteRoleTemplatesSuccess = createAction(
    '[SiteRole] Load templates success',
    props<{ roles: SiteRole[] }>()
);

export const loadSiteRoleTemplatesFail = createAction(
    '[SiteRole] Load templates fail',
    props<{ error: any }>()
);

export const loadRolesBySite = createAction(
    '[SiteRole] Load by Site',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadRolesBySiteSuccess = createAction(
    '[SiteRole] Load by Site success',
    props<{ roles: SiteRole[]; siteId: string }>()
);

export const loadRolesBySiteFail = createAction(
    '[SiteRole] Load by Site fail',
    props<{ error: any }>()
);

// Add SiteRole actions

export const addSiteRole = createAction('[SiteRole] Add', props<{ payload: AddSiteRolePayload }>());

export const addSiteRoleSuccess = createAction(
    '[SiteRole] Add success',
    props<{ role: SiteRole }>()
);

export const addSiteRoleFail = createAction('[SiteRole] Add fail', props<{ error: any }>());

// Update SiteRole actions

export const updateSiteRole = createAction(
    '[SiteRole] Update',
    props<{ payload: UpdateSiteRolePayload; options: OptimisticableActionOptions }>()
);

export const patchSiteRole = createAction(
    '[SiteRole] Patch',
    props<{ payload: PatchSiteRolePayload; options: OptimisticableActionOptions }>()
);

export const patchSiteRoleSuccess = createAction(
    '[SiteRole] Patch success',
    props<PatchSiteRolePayload>()
);

export const patchSiteRoleFail = createAction(
    '[SiteRole] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Delete SiteRole actions

export const deleteSiteRole = createAction(
    '[SiteRole] Delete',
    props<{ payload: SiteRoleIdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteSiteRoleSuccess = createAction(
    '[SiteRole] Delete success',
    props<SiteRoleIdPayload>()
);

export const deleteSiteRoleFail = createAction(
    '[SiteRole] Delete fail',
    props<{ error: any; originAction: Action }>()
);

// Set role tag actions

export const setSiteRoleTag = createAction(
    '[SiteRole] Set tag',
    props<{ payload: SetSiteRoleTagPayload; options: OptimisticableActionOptions }>()
);

export const setSiteRoleTagSuccess = createAction(
    '[SiteRole] Set tag success',
    props<{ siteRoles: SiteRole[] }>()
);

export const setSiteRoleTagFail = createAction(
    '[SiteRole] Set tag fail',
    props<{ error: any; originAction: Action }>()
);
