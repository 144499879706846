import { CalendarCalculator } from 'app/core/models/calendar/calendar-calculator';
import { isBefore, isEqual } from 'date-fns';
import { CompoundValue } from './compound-value';
import { TaskDiff, TaskId, TaskInitInternal } from './schedule.types';
import { TaskDependency } from './task-dependency';

export class Task {
    taskId: TaskId;
    parentTaskId = new CompoundValue<TaskId | null>();
    childTaskIds = new CompoundValue<Array<TaskId> | null>();

    startDate = new CompoundValue<Date | null>();
    endDate = new CompoundValue<Date | null>();
    duration = new CompoundValue<number | null>();

    dependencies = new CompoundValue<TaskDependency[] | null>();

    progress = new CompoundValue<number | null>();

    /**
     * If both target dates are provided, they mean 'start not earlier' and 'end not earlier'
     * appropriately
     */
    // TODO: make sure using simple Date instead of compound value is not enough
    targetStartDate = new CompoundValue<Date | null>();
    targetEndDate = new CompoundValue<Date | null>();

    private calculator: CalendarCalculator;

    constructor(init: TaskInitInternal) {
        this.taskId = init.taskId;
        this.startDate.setInitial(init.start);
        this.targetStartDate.setInitial(init.targetStart);
        this.endDate.setInitial(init.end);
        this.targetEndDate.setInitial(init.targetEnd);
        this.duration.setInitial(init.duration);
        this.dependencies.setInitial(init.dependencies);
        this.parentTaskId.setInitial(init.parentTaskId);
        this.childTaskIds.setInitial(init.childTaskIds);
        this.progress.setInitial(init.progress);
    }

    get isModified(): boolean {
        return (
            this.startDate.isModified ||
            this.endDate.isModified ||
            this.duration.isModified ||
            this.dependencies.isModified ||
            this.parentTaskId.isModified ||
            this.progress.isModified
        );
    }

    get isGroup(): boolean {
        return !!this.childTaskIds.getValue()?.length;
    }

    get isLeaf(): boolean {
        return !this.isGroup;
    }

    change(init: Omit<TaskInitInternal, 'taskId'>) {
        this.startDate.setChanged(init.start);
        this.targetStartDate.setChanged(init.targetStart);
        this.endDate.setChanged(init.end);
        this.targetEndDate.setChanged(init.targetEnd);
        this.duration.setChanged(init.duration);
        this.dependencies.setChanged(init.dependencies);
        this.parentTaskId.setChanged(init.parentTaskId);
        this.childTaskIds.setChanged(init.childTaskIds);
        this.progress.setChanged(init.progress);
    }

    calculate(calculator: CalendarCalculator) {
        this.calculator = calculator;

        this.clearCalculations();

        if (this.isGroup) {
            this.calculateGroupTask();
        } else {
            this.calculateNonGroupTask();
        }
    }

    clearCalculations() {
        this.startDate.clearCalculated();
        this.endDate.clearCalculated();
        this.duration.clearCalculated();
        this.progress.clearCalculated();

        // TODO: it seems we do not calculate them, so that the following calls are excessive
        this.targetStartDate.clearCalculated();
        this.targetEndDate.clearCalculated();
    }

    clearTargetDates() {
        this.targetStartDate = new CompoundValue<Date | null>();
        this.targetEndDate = new CompoundValue<Date | null>();
    }

    setTargetStart(newValue: Date) {
        this.targetStartDate.setInitial(newValue);
    }

    setTargetEnd(newValue: Date) {
        this.targetEndDate.setInitial(newValue);
    }

    getDiff(): TaskDiff | null {
        if (!this.isModified) return null;

        return {
            taskId: this.taskId,
            startDiff: this.startDate.getDiff(),
            endDiff: this.endDate.getDiff(),
            durationDiff: this.duration.getDiff(),
            dependenciesDiff: this.dependencies.getDiff(),
            parentTaskIdDiff: this.parentTaskId.getDiff(),
            progressDiff: this.progress.getDiff(),
        };
    }

    private calculateNonGroupTask() {
        const noTargetStart = this.targetStartDate.getValue() === null;
        const noTargetEnd = this.targetEndDate.getValue() === null;

        if (noTargetStart || noTargetEnd) {
            this.updateStartDate(null);
            this.updateEndDate(null);
            return;
        }

        if (!this.targetStartDate.isEmpty) {
            this.updateStartDate(new Date(this.targetStartDate.getValue()));
        }
        if (!this.targetEndDate.isEmpty) {
            this.updateEndDate(new Date(this.targetEndDate.getValue()));
        }

        let bindTaskTo: 'startDate' | 'endDate';
        const bothTargetsProvided = !this.targetStartDate.isEmpty && !this.targetEndDate.isEmpty;

        if (bothTargetsProvided && !this.duration.isEmpty) {
            const secondsBetweenTargets = this.calculator.workSecondsBetween(
                this.targetStartDate.getValue(),
                this.targetEndDate.getValue()
            );
            const cannotPutTaskBetweenTargets = this.duration.getValue() > secondsBetweenTargets;
            if (cannotPutTaskBetweenTargets) {
                bindTaskTo = 'startDate';
                this.endDate.clearCalculated();
            } else {
                bindTaskTo = 'endDate';
                this.startDate.clearCalculated();
            }
        } else if (!this.targetEndDate.isEmpty) {
            bindTaskTo = 'endDate';
        } else {
            bindTaskTo = 'startDate';
        }

        if (this.autoFillIfOnlyStartDateModifiedAndNotEmpty()) return;
        if (this.autoFillIfOnlyEndDateModifiedAndNotEmpty()) return;

        if (bindTaskTo === 'startDate') {
            this.alignEndDateWithStartDate();
        }

        const onlyDurationNotModified =
            this.startDate.isModified && this.endDate.isModified && !this.duration.isModified;
        const onlyStartDateModified =
            this.startDate.isModified && !this.endDate.isModified && !this.duration.isModified;
        const onlyEndDateModified =
            this.endDate.isModified && !this.startDate.isModified && !this.duration.isModified;
        const onlyDurationModified =
            this.duration.isModified && !this.startDate.isModified && !this.endDate.isModified;

        let propsToCalculate: CalculationOptions;
        if (onlyDurationNotModified) {
            propsToCalculate = { duration: true };
        } else if (onlyStartDateModified) {
            propsToCalculate = { end: true, duration: true };
        } else if (onlyEndDateModified) {
            propsToCalculate = { start: true, duration: true };
        } else if (onlyDurationModified) {
            propsToCalculate = { start: true, end: true };
        } else {
            propsToCalculate = { start: true, end: true, duration: true };
        }

        if (bindTaskTo === 'startDate') {
            this.calculateEndDateDurationStartDate(propsToCalculate);
        } else {
            this.calculateStartDateDurationEndDate(propsToCalculate);
        }

        this.clearDurationIfEitherStartOrEndDatePresent();
        this.clearEndDateIfStartDatePresentAndDurationEmpty();
    }

    private calculateGroupTask() {
        if (this.targetStartDate.hasValue) {
            this.updateStartDate(this.targetStartDate.getValue());
        }

        if (this.targetEndDate.hasValue) {
            this.updateEndDate(this.targetEndDate.getValue());
        }

        if (this.startDate.isEmpty || this.endDate.isEmpty) {
            this.updateDuration(null);
        } else {
            const duration = this.calculator.workSecondsBetween(
                this.startDate.getValue(),
                this.endDate.getValue()
            );

            this.updateDuration(duration);
        }
    }

    private updateStartDate(newValue: Date) {
        const currentStartDate = this.startDate.getValue();
        if (currentStartDate == null && newValue == null) return;
        if (isEqual(currentStartDate, newValue)) return;
        this.startDate.setCalculated(newValue);
    }

    private updateEndDate(newValue: Date) {
        const currentEndDate = this.endDate.getValue();
        if (currentEndDate == null && newValue == null) return;
        if (isEqual(currentEndDate, newValue)) return;
        this.endDate.setCalculated(newValue);
    }

    private updateDuration(newValue: number | null) {
        const currentDuration = this.duration.getValue();
        if (currentDuration == null && newValue == null) return;
        if (currentDuration === newValue) return;
        this.duration.setCalculated(newValue);
    }

    private calculateEndDateDurationStartDate(options: CalculationOptions) {
        if (options.end) this.recalculateEndDate();
        if (options.duration) this.recalculateDuration();
        if (options.start) this.recalculateStartDate();
    }

    private calculateStartDateDurationEndDate(options: CalculationOptions) {
        if (options.start) this.recalculateStartDate();
        if (options.duration) this.recalculateDuration();
        if (options.end) this.recalculateEndDate();
    }

    private autoFillIfOnlyStartDateModifiedAndNotEmpty(): boolean {
        const startModifiedNotEmpty = this.startDate.isModified && !this.startDate.isEmpty;
        const endEmptyNotModified = !this.endDate.isModified && this.endDate.isEmpty;
        const durationEmptyNotModified = !this.duration.isModified && this.duration.isEmpty;

        if (startModifiedNotEmpty && endEmptyNotModified && durationEmptyNotModified) {
            const duration = this.calculator.calendar.workingHoursPerDay * 3600;
            this.updateDuration(duration);
            const start = this.startDate.getValue();
            const end = this.calculator.addWorkSeconds(start, duration);
            this.updateEndDate(end);
            return true;
        }
        return false;
    }

    private autoFillIfOnlyEndDateModifiedAndNotEmpty(): boolean {
        const endModifiedNotEmpty = this.endDate.isModified && !this.endDate.isEmpty;
        const startEmptyNotModified = !this.startDate.isModified && this.startDate.isEmpty;
        const durationEmptyNotModified = !this.duration.isModified && this.duration.isEmpty;

        if (endModifiedNotEmpty && startEmptyNotModified && durationEmptyNotModified) {
            const duration = this.calculator.calendar.workingHoursPerDay * 3600;
            this.updateDuration(duration);
            const end = this.endDate.getValue();
            const start = this.calculator.subWorkSeconds(end, duration);
            this.updateStartDate(start);
            return true;
        }
        return false;
    }

    private recalculateEndDate() {
        if (
            !this.endDate.isEmpty &&
            !this.startDate.isEmpty &&
            isBefore(this.endDate.getValue(), this.startDate.getValue())
        ) {
            this.updateEndDate(new Date(this.startDate.getValue()));
        }

        if (this.startDate.isEmpty || this.duration.isEmpty) return;
        const offset = this.duration.getValue();
        const newEndDate = this.calculator.addWorkSeconds(this.startDate.getValue(), offset);
        this.updateEndDate(newEndDate);
    }

    private alignEndDateWithStartDate() {
        if (
            this.endDate.isModified &&
            !this.endDate.isEmpty &&
            !this.startDate.isEmpty &&
            isBefore(this.endDate.getValue(), this.startDate.getValue())
        ) {
            this.updateEndDate(new Date(this.startDate.getValue()));
        }
    }

    private recalculateStartDate() {
        if (this.endDate.isEmpty || this.duration.isEmpty) return;
        const offset = this.duration.getValue();
        const newStartDate = this.calculator.subWorkSeconds(this.endDate.getValue(), offset);
        this.updateStartDate(newStartDate);
    }

    private recalculateDuration() {
        if (this.startDate.isEmpty || this.endDate.isEmpty) return;

        const newDuration = this.calculator.workSecondsBetween(
            this.startDate.getValue(),
            this.endDate.getValue()
        );
        this.updateDuration(newDuration);
    }

    private clearDurationIfEitherStartOrEndDatePresent() {
        if (this.duration.isEmpty) return;
        if (
            (this.startDate.isEmpty && !this.endDate.isEmpty) ||
            (!this.startDate.isEmpty && this.endDate.isEmpty)
        ) {
            this.updateDuration(null);
        }
    }

    private clearEndDateIfStartDatePresentAndDurationEmpty() {
        if (this.endDate.isEmpty) return;
        if (this.duration.isEmpty && !this.startDate.isEmpty) {
            this.updateEndDate(null);
        }
    }
}

interface CalculationOptions {
    start?: boolean;
    end?: boolean;
    duration?: boolean;
}
