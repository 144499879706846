import { ImportSystemTemplatePayload } from 'app/core/models/shared-models';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import {
    AddCommunicationPayload,
    ChangeProjectCommunicationTemplatePayload,
    CommunicationIdPayload,
    PatchCommunicationPayload,
} from '../../../../store/communication/communication.payloads';
import {
    LoadByProjectIdPayload,
    LoadBySiteIdPayload,
} from '../../../../store/shared/models/load.payload';

export const loadCommunicationsByProjectIdCommand = (
    payload: LoadByProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projects', value: payload.projectId },
        { name: 'communication' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadCommunicationsBySiteIdCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'communications' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadCompletixCommunicationTemplatesCommand = (
    payload: any,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'communications/completixTemplates' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadCompletixCommunicationTemplatesBySiteIdCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'communications/completixTemplates' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addCommunicationCommand = (
    payload: AddCommunicationPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'communications' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const importCompletixCommunicationTemplateCommand = (
    payload: ImportSystemTemplatePayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'communications/completixTemplates/import' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchCommunicationCommand = (
    payload: PatchCommunicationPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'communications', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteCommunicationCommand = (
    payload: CommunicationIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'communications', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};

export const changeProjectCommunicationTemplateCommand = (
    payload: ChangeProjectCommunicationTemplatePayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projects', value: payload.projectId },
        { name: 'communication', value: payload.templateId },
    ]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};
