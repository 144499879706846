import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import * as LayoutActions from './layout.actions';
import { initialState } from './layout.store';

export const reducer = createReducer(
    initialState,
    on(LayoutActions.loadLayoutSettings, (state, payload) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(LayoutActions.loadLayoutSettingsSuccess, (state, payload) => ({
        ...state,
        layoutSettings: { ...payload },
        serverRequestInProgress: false,
        loaded: true,
    })),

    on(LayoutActions.loadLayoutSettingsFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(LayoutActions.setSidebarAnimationCompleted, (state, { opened }) => ({
        ...state,
        sidebarAnimationCompleted: { opened },
    })),

    on(LayoutActions.setPageName, (state, payload) => ({
        ...state,
        pageName: payload.name,
    })),

    on(LayoutActions.patchLayoutSettings, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patched = getPatched(state.layoutSettings, action.payload.patch);
        return {
            ...state,
            layoutSettings: patched,
        };
    }),

    on(LayoutActions.patchLayoutSettingsSuccess, (state, payload) => ({
        ...state,
        layoutSettings: { ...state.layoutSettings, ...payload },
    })),

    on(LayoutActions.patchLayoutSettingsFail, (state) => ({
        ...state,
    })),

    on(LayoutActions.updateLayoutSettings, (state) => ({
        ...state,
    }))
);
