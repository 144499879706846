import { CommandPayload } from './base.command.payload';

export class FormDataPayload extends CommandPayload {
    constructor(payload?: any) {
        super(payload || {});
    }

    concat(payload: FormDataPayload): this {
        return this;
    }

    serialize(): string | Blob | ArrayBuffer | FormData {
        return this._data;
    }

    setData(data: FormData) {
        this._data = data;
    }

    appendPair(key: any, value: any) {
        (this._data as FormData).append(key, value);
    }

    // When we don't have a mimeType, the browser will set the correct boundary for FormData
    // Source: https://stackoverflow.com/questions/40351429/formdata-how-to-get-or-set-boundary-in-multipart-form-data-angular
    get mimeType(): string {
        return undefined;
    }
}
