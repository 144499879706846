import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CtxNotification } from '.';
import {
    AsyncUpdateStatusDefaultLoad,
    AsyncUpdateStatusDefaultLoadSuccess,
} from '../shared/async-update-status.store';
import * as NotificationActions from './notification.actions';
import { adapter, initialState } from './notification.store';

export const reducer = createReducer(
    initialState,

    on(NotificationActions.loadAccountLevelNotifications, (state) => ({
        ...state,
        ...AsyncUpdateStatusDefaultLoad,
    })),

    on(NotificationActions.loadAccountLevelNotificationsSuccess, (state, payload) => ({
        ...adapter.addMany(
            payload.notifications,
            adapter.removeMany(
                payload.notifications.map((n) => n.id),
                state
            )
        ),
        ...AsyncUpdateStatusDefaultLoadSuccess,
    })),

    on(NotificationActions.loadAccountLevelNotificationsFail, (state, payload) => ({
        ...state,
        ...AsyncUpdateStatusDefaultLoad,
        error: payload.error,
    })),

    on(NotificationActions.loadSiteLevelNotifications, (state) => ({
        ...state,
        ...AsyncUpdateStatusDefaultLoad,
    })),

    on(NotificationActions.loadSiteLevelNotificationsSuccess, (state, payload) => ({
        ...adapter.addMany(
            payload.notifications,
            adapter.removeMany(
                payload.notifications.map((n) => n.id),
                state
            )
        ),
        ...AsyncUpdateStatusDefaultLoadSuccess,
    })),

    on(NotificationActions.loadSiteLevelNotificationsFail, (state, payload) => ({
        ...state,
        ...AsyncUpdateStatusDefaultLoad,
        error: payload.error,
    })),

    on(NotificationActions.markNotificationRead, (state, action) => {
        if (!action.options?.optimistic) return state;
        const change: Update<CtxNotification> = { id: action.payload.id, changes: { read: true } };
        return adapter.updateOne(change, state);
    }),

    on(NotificationActions.markNotificationReadSuccess, (state, payload) =>
        adapter.addOne(payload.notification, adapter.removeOne(payload.notification.id, state))
    ),

    on(NotificationActions.markNotificationReadFail, (state, payload) => state),

    on(NotificationActions.addNotification, (state, payload) =>
        adapter.addOne(payload.notification, adapter.removeOne(payload.notification.id, state))
    ),

    on(NotificationActions.deleteNotification, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(NotificationActions.deleteNotificationSuccess, (state, payload) =>
        adapter.removeOne(payload.id, state)
    ),

    on(NotificationActions.deleteNotificationFail, (state, payload) => state)
);
