import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getRandomBrightColor } from 'app/core/models/shared-models';
import { EntityPatchSuccessResponse } from 'app/core/services/rest-api/features/patch.response';
import { SnackBarService } from 'app/services/snack-bar/snack-bar.service';
import { getPatch } from 'app/utils/json-patch';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { Minute, MinuteActions, MinuteSelectors } from '.';
import { RestApiService } from '../../services/rest-api';
import { generateUUID } from '../id-generator';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import { AppState } from '../store';
import { OptimisticDefaults } from './../shared/optimisticable-action';
import {
    addMinuteFail,
    addMinuteSuccess,
    deleteMinuteFail,
    deleteMinuteSuccess,
    loadMinutesByProjectIdFail,
    loadMinutesByProjectIdSuccess,
    patchMinuteFail,
    patchMinuteSuccess,
} from './minute.actions';
import { MinuteTopic, MinuteTopicType } from './models';

@Injectable()
export class MinuteEffects {
    loadMinutesByProjectId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MinuteActions.loadMinutesByProjectId),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map(
                        (response) =>
                            loadMinutesByProjectIdSuccess({
                                minutes: response.payload.map((dto) => this.getMinuteFromDto(dto)),
                            }),
                        catchError((error) => of(loadMinutesByProjectIdFail({ error })))
                    )
                )
            )
        )
    );

    loadMinutesBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MinuteActions.loadMinutesBySiteId),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map(
                        (response) =>
                            MinuteActions.loadMinutesBySiteIdSuccess({
                                minutes: response.payload.map((dto) => this.getMinuteFromDto(dto)),
                            }),
                        catchError((error) => of(MinuteActions.loadMinutesBySiteIdFail({ error })))
                    )
                )
            )
        )
    );

    addMinute$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MinuteActions.addMinute),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Creating a new Minute...');
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBarService.hide()),
                    map((response) =>
                        addMinuteSuccess({
                            minute: Model.createFromDto(Minute, response.payload),
                        })
                    ),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(addMinuteFail({ error }));
                    })
                );
            })
        )
    );

    cloneMinute$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MinuteActions.cloneMinute),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Duplicating a Minute...');
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBarService.hide()),
                    map((response) =>
                        addMinuteSuccess({
                            minute: Model.createFromDto(Minute, response.payload),
                        })
                    ),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(addMinuteFail({ error }));
                    })
                );
            })
        )
    );

    updateMinute$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MinuteActions.updateMinute),
            withLatestFrom(this.store$.select(MinuteSelectors.selectAll)),
            mergeMap(([action, minutes]) => {
                const minute = minutes.find((m) => m.id === action.payload.id);
                const patch = getPatch(minute, action.payload.changes);
                return of(
                    MinuteActions.patchMinute({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchdMinute$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MinuteActions.patchMinute),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: EntityPatchSuccessResponse) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return patchMinuteSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) => of(patchMinuteFail({ error, originAction: action })))
                )
            )
        )
    );

    deleteMinute$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MinuteActions.deleteMinute),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    filter(() => action.options?.optimistic),
                    map(() => deleteMinuteSuccess(action.payload)),
                    catchError((error) => of(deleteMinuteFail({ error, originAction: action })))
                )
            )
        )
    );

    addTopic$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MinuteActions.addTopic),
            withLatestFrom(this.store$.select(MinuteSelectors.selectAll)),
            mergeMap(([payload, minutes]) => {
                const options = payload.createOptions.options;
                const topic = new MinuteTopic({
                    id: generateUUID(),
                    type: options.type || MinuteTopicType.Info,
                    note: options.note || '',
                    owner: null,
                    dueDate: options.dueDate || null,
                });

                const minute = minutes.find((min) => min.id === payload.createOptions.minuteId);
                const changes: Partial<Minute> = { topics: [...(minute.topics || []), topic] };

                return of(
                    MinuteActions.updateMinute({
                        payload: {
                            id: payload.createOptions.minuteId,
                            changes,
                        },
                        options: OptimisticDefaults,
                    })
                );
            })
        )
    );

    deleteTopic$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MinuteActions.deleteTopic),
            withLatestFrom(this.store$.select(MinuteSelectors.selectAll)),
            mergeMap(([payload, minutes]) => {
                const minute = minutes.find((min) => min.id === payload.minuteId);
                const changes: Partial<Minute> = {
                    topics: [...minute.topics.filter((t) => t.id !== payload.id)],
                };

                return of(
                    MinuteActions.updateMinute({
                        payload: {
                            id: payload.minuteId,
                            changes,
                        },
                        options: OptimisticDefaults,
                    })
                );
            })
        )
    );

    updateTopic$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MinuteActions.updateTopic),
            withLatestFrom(this.store$.select(MinuteSelectors.selectAll)),
            mergeMap(([payload, minutes]) => {
                const minute = minutes.find((min) => min.topics?.some((t) => t.id === payload.id));
                const topicIdx = minute.topics.findIndex((t) => t.id === payload.id);
                const topic = minute.topics.find((t) => t.id === payload.id);
                const updatedTopic = new MinuteTopic({ ...topic, ...payload.changes });
                const changes: Partial<Minute> = {
                    topics: [
                        ...minute.topics.slice(0, topicIdx),
                        updatedTopic,
                        ...minute.topics.slice(topicIdx + 1),
                    ],
                };
                return of(
                    MinuteActions.updateMinute({
                        payload: {
                            id: minute.id,
                            changes,
                        },
                        options: OptimisticDefaults,
                    })
                );
            })
        )
    );

    sendMinutePreview$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MinuteActions.sendMinutePreview),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => okEmptyAction()),
                    catchError(() => {
                        this.snackBarService.show('Error sending the minute preview');
                        return of(okEmptyAction());
                    })
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppState>,
        private snackBarService: SnackBarService
    ) {}

    getMinuteFromDto(dto: any): Minute {
        const minute = Model.createFromDto(Minute, dto);
        return {
            ...minute,
            attendees: minute.attendees?.map((a) => ({
                ...a,
                avatarColor: getRandomBrightColor(),
            })),
        };
    }
}
