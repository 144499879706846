import { EntityScope } from 'app/core/models/shared-models';
import { Model } from '../../shared/models/base.model';

export class CtxComment extends Model {
    id: string = undefined;
    parentId?: string;
    authorSiteMemberId: string = undefined;
    originObjectId?: string;
    scope: EntityScope;
    link: CtxCommentLink = undefined;
    date: Date = undefined;
    text: string = undefined;
    mentions: CommentMenstion[];

    constructor(init?: Partial<CtxComment>) {
        super();
        Object.assign(this, init);
    }
}

export interface CommentMenstion {
    startIndex: number;
    name: string;
    memberId: string;
}

export enum CtxCommentLink {
    Action = 'Action',
    Risk = 'Risk',
    Issue = 'Issue',
    Decision = 'Decision',
    BacklogTask = 'BacklogTask',
    ProjectRequest = 'ProjectRequest',
    Schedule = 'Schedule',
}
