import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectSelectors } from '../project';
import { CtxFieldItemType, ProjectFieldSelectors, ProjectFieldType } from '../project-field';
import { ProjectRoleSelectors } from '../project-role';
import { selectAccountSiteMembership } from '../shared/account-mebership.selectors';
import { getRaidRecordPermissionsMap } from '../shared/models/shared-permissions.model';
import { StateEntry } from '../state-entry';
import { adapter, LoadingStatus, State } from './decision.store';
import { DecisionRecord } from './models';

export const selectDecisionEntry = createFeatureSelector<State>(StateEntry.Decision);

export const selectLoadingStatus = createSelector(
    selectDecisionEntry,
    (state): LoadingStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
        updateServerRequstInProgressIds: [],
    })
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
    selectEntityStoredInDb: selectEntityStoredInDb,
} = adapter.getCtxSelectors(selectDecisionEntry);

export const selectCurrentProjectDecisions = createSelector(
    selectAll,
    ProjectSelectors.selectCurrentProjectId,
    (Decisions, projectId) => Decisions.filter((i) => i.projectId === projectId)
);

export const selectDecisionUpdateInProgressIds = createSelector(
    selectDecisionEntry,
    (entry) => entry.updateServerRequstInProgressIds
);

export const selectDecisionPermissionsMap = createSelector(
    selectAll,
    ProjectRoleSelectors.selectAccountProjectRolesMap,
    selectAccountSiteMembership,
    ProjectFieldSelectors.selectCurrentSiteProjectFieldByType(ProjectFieldType.DecisionStatus),
    (decisions, projectRolesMap, accountSiteMembership, decisionProjectField) => {
        const additionalDeletePredicate = (decision: DecisionRecord) => {
            const approvedStatus = decisionProjectField?.items?.find(
                (s) => s.ctxItemType === CtxFieldItemType.Approved
            );
            const rejectedStatus = decisionProjectField?.items?.find(
                (s) => s.ctxItemType === CtxFieldItemType.Rejected
            );
            if (
                decision.statusId !== approvedStatus?.id &&
                decision.statusId !== rejectedStatus?.id
            ) {
                return true;
            }

            return projectRolesMap[decision.projectId].permissions?.raid?.decisions
                ?.deleteApprovedRejected;
        };

        const additionalUpdatePredicate = (decision: DecisionRecord) => {
            const approvedStatus = decisionProjectField?.items?.find(
                (s) => s.ctxItemType === CtxFieldItemType.Approved
            );
            const rejectedStatus = decisionProjectField?.items?.find(
                (s) => s.ctxItemType === CtxFieldItemType.Rejected
            );
            return (
                decision.statusId !== approvedStatus?.id && decision.statusId !== rejectedStatus?.id
            );
        };
        return getRaidRecordPermissionsMap(
            decisions,
            projectRolesMap,
            'decisions',
            (decision: DecisionRecord) =>
                decision.assignedMembers?.some((m) => m.siteMemberId === accountSiteMembership.id),
            (decision: DecisionRecord) => additionalDeletePredicate(decision),
            (decision: DecisionRecord) => additionalUpdatePredicate(decision)
        );
    }
);

export const selectDecisionById = (id: string) =>
    createSelector(selectAll, (decisions) => decisions.find((d) => d.id === id));
