import { HubConnection } from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { AccountActions, AccountModel } from 'app/core/store/account';
import { ActionActions } from 'app/core/store/actions';
import { BacklogTaskActions } from 'app/core/store/backlog-task';
import { IssueActions } from 'app/core/store/issue';
import {
    CtxNotification,
    NotificationActions,
    NotificationType,
} from 'app/core/store/notification';
import { ProjectActions } from 'app/core/store/project';
import { ProjectMemberActions } from 'app/core/store/project-member';
import { RiskActions } from 'app/core/store/risks';
import { Model } from 'app/core/store/shared/models/base.model';
import { SiteActions, SiteSelectors } from 'app/core/store/site';
import { first } from 'rxjs/operators';
import { AppStore } from '../../store';
import { SignalRPayload } from './../../../utils/network/commands/payloads/signalr.command.payload';
import { SignalRCommand } from './../../../utils/network/commands/signalr.command';
import { SignalRGateway } from './../../../utils/network/gateways/signalr.gateway';

export class AccountHubClient {
    private readonly endpoint = '/account';
    private readonly reconnectTimeout = 2000;

    private connection: HubConnection;
    private connected = false;
    private reconnectTimer: NodeJS.Timer = null;

    private listeningAccountId: string;

    constructor(private signalRGateway: SignalRGateway, private store: Store<AppStore.AppState>) {}

    connect() {
        if (this.connection || this.reconnectTimer) return;

        this.connection = this.signalRGateway.buildConnection(this.endpoint);
        this.registerHandlers(this.connection);

        this.connection.onclose((error?: Error) => {
            console.log('SignalR: connection closed', error);

            const disconnectedUnexpectedly = this.connection != null;
            if (disconnectedUnexpectedly) {
                this.clearCurrentConnection();
                this.scheduleConnect();
            }
        });

        this.connection
            .start()
            .then(() => {
                console.log('SignalR: connected');
                this.connected = true;
                this.onConnected();
            })
            .catch((error) => {
                this.clearCurrentConnection();
                this.scheduleConnect();
            });
    }

    disconnect(): Promise<void> {
        if (!this.connection) return;
        const currentConnection = this.connection;
        this.clearCurrentConnection();
        return currentConnection.stop();
    }

    registerHandlers(connection: HubConnection) {
        connection.on('AccountUpdated', (data) => {
            console.log('SignalR message received: AccountUpdated', data);
            const account = Model.createFromDto(AccountModel, data);
            this.store.dispatch(
                AccountActions.updateAccountSuccess({ accountId: account.id, changes: account })
            );
        });

        connection.on('NotificationReceived', (data) => {
            console.log('SignalR message received: NotificationReceived', data);
            const notification = Model.createFromDto(CtxNotification, data);
            this.handleNotification(notification);
        });

        connection.on('NotificationDeleted', (id: string) => {
            console.log('SignalR message received: NotificationDeleted', id);
            this.store.dispatch(NotificationActions.deleteNotificationSuccess({ id }));
        });
    }

    handleNotification(notification: CtxNotification) {
        this.store.dispatch(NotificationActions.addNotification({ notification }));

        this.store
            .select(SiteSelectors.selectCurrentSiteId)
            .pipe(first())
            .subscribe((currentSiteId) => {
                switch (notification.type) {
                    case NotificationType.InvitedRegisteredToSite:
                        this.store.dispatch(
                            SiteActions.getSiteById({
                                payload: { siteId: notification.originObjectId },
                            })
                        );
                        break;

                    case NotificationType.InvitedRegisteredToProject:
                    case NotificationType.AssignedRegisteredPMToProject:
                        if (currentSiteId !== notification.siteId) break;
                        this.store.dispatch(
                            ProjectActions.getProject({
                                payload: { projectId: notification.originObjectId },
                            })
                        );
                        this.store.dispatch(
                            ProjectMemberActions.loadProjectMembersByProjectId({
                                payload: { projectId: notification.originObjectId },
                            })
                        );
                        break;

                    case NotificationType.AssignedToAction:
                        this.store.dispatch(
                            ActionActions.getAction({
                                payload: { id: notification.originObjectId },
                            })
                        );
                        break;

                    case NotificationType.AssignedToIssue:
                        this.store.dispatch(
                            IssueActions.getIssue({ payload: { id: notification.originObjectId } })
                        );
                        break;

                    case NotificationType.AssignedToRisk:
                        this.store.dispatch(
                            RiskActions.getRisk({ payload: { id: notification.originObjectId } })
                        );
                        break;

                    case NotificationType.AssignedToTask:
                        this.store.dispatch(
                            BacklogTaskActions.getBacklogTask({
                                payload: { id: notification.originObjectId },
                            })
                        );
                        break;

                    default:
                        break;
                }
            });
    }

    listenAccount(accountId: string) {
        this.listeningAccountId = accountId;
        if (!this.connected) return;

        console.log('SignalR: start listening account ' + accountId);

        const payload = new SignalRPayload();
        payload.setData(accountId);
        const command = new SignalRCommand(payload);
        command.method = 'Join';
        this.signalRGateway.send(this.connection, command);
    }

    private onConnected() {
        if (this.listeningAccountId) this.listenAccount(this.listeningAccountId);
    }

    private scheduleConnect() {
        console.log('SignalR: schedule connection after ' + this.reconnectTimeout + 'ms');
        this.reconnectTimer = setTimeout(() => {
            this.clearReconnectTimer();
            this.connect();
        }, this.reconnectTimeout);
    }

    private clearCurrentConnection() {
        this.connection = null;
        this.connected = false;
        this.clearReconnectTimer();
    }

    private clearReconnectTimer() {
        if (this.reconnectTimer == null) return;
        clearTimeout(this.reconnectTimer);
        this.reconnectTimer = null;
    }
}
