import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getPatch } from 'app/utils/json-patch';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ProjectRoleSelectors } from '.';
import { AppStore } from '..';
import { RestApiService } from '../../services/rest-api';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import { LoadProjectRolesResponse } from './../../services/rest-api/features/project-role/project-role.responses';
import { ProjectRole } from './models';
import * as ProjectRoleActions from './project-role.actions';

@Injectable()
export class ProjectRoleEffects {
    loadProjectRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectRoleActions.loadProjectRoleTemplates),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: LoadProjectRolesResponse) =>
                        response.payload.map((dto) => Model.createFromDto(ProjectRole, dto))
                    ),
                    map((roles) => ProjectRoleActions.loadProjectRoleTemplatesSuccess({ roles })),
                    catchError((error) =>
                        of(ProjectRoleActions.loadProjectRoleTemplatesFail({ error }))
                    )
                )
            )
        )
    );

    loadRolesBySite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectRoleActions.loadProjectRolesBySite),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: LoadProjectRolesResponse) =>
                        response.payload.map((dto) => Model.createFromDto(ProjectRole, dto))
                    ),
                    map((roles) => ProjectRoleActions.loadProjectRolesBySiteSuccess({ roles })),
                    catchError((error) =>
                        of(ProjectRoleActions.loadProjectRolesBySiteFail({ error }))
                    )
                )
            )
        )
    );

    addProjectRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectRoleActions.addProjectRole),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) =>
                        ProjectRoleActions.addProjectRoleSuccess({
                            role: Model.createFromDto(ProjectRole, response.payload),
                        })
                    ),
                    catchError((error) => of(ProjectRoleActions.addProjectRoleFail({ error })))
                )
            )
        )
    );

    updateProjectRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectRoleActions.updateProjectRole),
            withLatestFrom(this.store$.select(ProjectRoleSelectors.selectAll)),
            mergeMap(([action, roles]) => {
                const roleToPatch = roles.find((role) => role.id === action.payload.id);
                const patch = getPatch(roleToPatch, action.payload.changes);
                return of(
                    ProjectRoleActions.patchProjectRole({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchProjectRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectRoleActions.patchProjectRole),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ProjectRoleActions.patchProjectRoleSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) =>
                        of(ProjectRoleActions.patchProjectRoleFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    setProjectRoleTag$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectRoleActions.setProjectRoleTag),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ProjectRoleActions.setProjectRoleTagSuccess({
                            projectRoles: response.payload.map((dto) =>
                                Model.createFromDto(ProjectRole, dto)
                            ),
                        });
                    }),
                    catchError((error) =>
                        of(
                            ProjectRoleActions.setProjectRoleTagFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteProjectRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectRoleActions.deleteProjectRole),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ProjectRoleActions.deleteProjectRoleSuccess({
                            id: action.payload.id,
                        });
                    }),
                    catchError((error) =>
                        of(
                            ProjectRoleActions.deleteProjectRoleFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>
    ) {}
}
