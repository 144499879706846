import { TaskId } from './schedule.types';
import { formatTaskDuration, parseTaskDuration, TaskDurationOptions } from './task-duration';

export interface TaskDependency {
    taskId: TaskId;
    type: TaskDependencyType;
    // Dependency lag in seconds
    lag?: number;
}

export enum TaskDependencyType {
    FS = 'FS',
    SS = 'SS',
    FF = 'FF',
    SF = 'SF',
}

export function parseDependencies(
    input: string,
    refIdToTaskId: (refId: number) => TaskId,
    options: TaskDurationOptions
): TaskDependency[] {
    if (!input) return [];
    return input
        .split(/(?:[;,])/)
        .map((depString) => parseDependency(depString, refIdToTaskId, options))
        .filter((dep) => !!dep);
}

function parseDependency(
    input: string,
    visualRowNumberToTaskId: (rowNumber: number) => TaskId,
    options: TaskDurationOptions
): TaskDependency {
    input = input.trim();
    if (!input) return null;

    const regexp = /^(\d+)\s*(SF|SS|FS|FF)?\s*(.*)?$/i;

    const groups = input.match(regexp);
    if (!groups) return null;

    const [, rowNumberStr, typeStr, lagDurationStr] = groups;

    const rowNumber = parseInt(rowNumberStr, 10);
    if (isNaN(rowNumber)) return null;

    const taskId = visualRowNumberToTaskId(rowNumber);
    if (!taskId) return null;

    let type = TaskDependencyType.FS;
    if (typeStr) {
        const typeUpper = typeStr.toUpperCase();
        if (!Object.values<string>(TaskDependencyType).includes(typeUpper)) return null;
        type = typeUpper as TaskDependencyType;
    }

    let lag = lagDurationStr ? parseTaskDuration(lagDurationStr, options) ?? 0 : 0;

    return { taskId, type, lag };
}

export function stringifyDependencies(
    deps: TaskDependency[] | null,
    taskIdToVisualRowNumber: (taskId: TaskId) => number,
    options: TaskDurationOptions
): string[] {
    if (!deps) return [];

    return deps.map((dep) => {
        const rowNumber = taskIdToVisualRowNumber(dep.taskId);
        if (typeof rowNumber !== 'number' || !isFinite(rowNumber)) return '#REF';

        const typeText = dep.type === TaskDependencyType.FS && !dep.lag ? '' : dep.type;
        const lagText = dep.lag
            ? (dep.lag > 0 ? '+' : '') + formatTaskDuration(dep.lag, options)
            : '';
        return rowNumber + typeText + lagText;
    });
}

export function dependencyListsAreEqual(
    depsA: TaskDependency[] | null,
    depsB: TaskDependency[] | null
): boolean {
    if (!depsA?.length && !depsB?.length) return true;

    return (
        depsA?.length === depsB?.length &&
        depsA.every((depA, i) => dependenciesAreEqual(depA, depsB[i]))
    );
}

function dependenciesAreEqual(depA: TaskDependency, depB: TaskDependency): boolean {
    return depA.taskId === depB.taskId && depA.type === depB.type && depA.lag === depB.lag;
}
