import { Action, createAction, props } from '@ngrx/store';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import { SiteIdPayload } from '../site/site.payloads';
import { Reminder } from './models';
import {
    AddReminderPayload,
    PatchReminderPayload,
    ReminderIdPayload,
    UpdateReminderPayload,
} from './reminder.payloads';

// Load Reminders actions

export const loadReminders = createAction(
    '[Reminder] Load Reminders ',
    props<{ payload: SiteIdPayload }>()
);

export const loadRemindersSuccess = createAction(
    '[Reminder] Load Reminders success',
    props<{ reminders: Reminder[] }>()
);

export const loadRemindersFail = createAction(
    '[Reminder] Load Reminders fail',
    props<{ error: any }>()
);

// Add Reminder actions

export const addReminder = createAction('[Reminder] Add', props<{ payload: AddReminderPayload }>());

export const addReminderSuccess = createAction('[Reminder] Add success', props<Reminder>());

export const addReminderFail = createAction('[Reminder] Add fail', props<{ error: any }>());

// Update Reminder actions

export const updateReminder = createAction(
    '[Reminder] Update',
    props<{ payload: UpdateReminderPayload; options?: OptimisticableActionOptions }>()
);

export const patchReminder = createAction(
    '[Reminder] Patch',
    props<{ payload: PatchReminderPayload; options?: OptimisticableActionOptions }>()
);

export const patchReminderSuccess = createAction(
    '[Reminder] Patch success',
    props<{ reminder: Reminder }>()
);

export const patchReminderFail = createAction(
    '[Reminder] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Delete Reminder actions

export const deleteReminder = createAction(
    '[Reminder] Delete',
    props<{ payload: ReminderIdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteReminderSuccess = createAction(
    '[Reminder] Delete success',
    props<ReminderIdPayload>()
);

export const deleteReminderFail = createAction(
    '[Reminder] Delete fail',
    props<{ error: any; originAction: Action }>()
);
