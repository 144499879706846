import { AccountPayloads } from 'app/core/store/account';
import {
    PatchSystemAdminDataPayload,
    UpdateAccountPayload,
} from 'app/core/store/account/account.payloads';
import { PatchLayoutSettingsPayload } from 'app/core/store/layout/payloads/layout.payloads';
import { LoadBySiteIdPayload } from 'app/core/store/shared/models/load.payload';
import { RestfulCommand, RestfulCommandBuilder, RestfulMethod } from 'app/utils/network';

export const registerAccount: RestfulCommandBuilder = (
    payload: undefined,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'accounts' }]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const getMyAccount: RestfulCommandBuilder = (
    payload: undefined,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'accounts' }, { name: 'myaccount' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const getAccount: RestfulCommandBuilder = (
    payload: AccountPayloads.AccountIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'accounts', value: payload.accountId }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const getSystemAdminData: RestfulCommandBuilder = (
    payload: undefined,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'admin/SystemAdminData' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const patchSystemAdminData: RestfulCommandBuilder = (
    payload: PatchSystemAdminDataPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'admin/SystemAdminData', value: payload.id }]);
    baseCommand.method = RestfulMethod.PATCH;
    baseCommand.payload.setData(payload.patch);
    return baseCommand;
};

export const getUserAppMessage: RestfulCommandBuilder = (
    payload: undefined,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'accounts/appMessage' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const updateAccount = (payload: UpdateAccountPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'accounts', value: payload.accountId as string }]);
    baseCommand.payload.setData(payload.changes);
    baseCommand.method = RestfulMethod.PUT;
    return baseCommand;
};

export const deleteAccount = (
    payload: AccountPayloads.AccountIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'accounts', value: payload.accountId }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};

export const getAccountImage: RestfulCommandBuilder = (
    payload: AccountPayloads.AccountIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'accounts', value: payload.accountId }, { name: 'image' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const updateAccountImage = (
    payload: AccountPayloads.UpdateAccountImagePayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'accounts', value: payload.accountId }, { name: 'image' }]);
    baseCommand.payload.setData(payload.image);
    baseCommand.method = RestfulMethod.PUT;
    return baseCommand;
};

export const patchAccountSetting: RestfulCommandBuilder = (
    payload: PatchLayoutSettingsPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'accounts' },
        { name: 'sites', value: payload.siteId },
        { name: 'layoutSettings' },
    ]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const loadAccountLayoutSettings: RestfulCommandBuilder = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'accounts' },
        { name: 'sites', value: payload.siteId },
        { name: 'layoutSettings' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};
