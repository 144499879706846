import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ApprovalRequest, ApprovalRequestSelectors } from 'app/core/store/approval-request';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ApprovalRequestActions } from '.';
import { AppStore } from '..';
import { RestApiService } from '../../services/rest-api';
import { ChangeRequestActions } from '../change-request';
import { DecisionActions } from '../decision';
import { ProjectPhaseActions } from '../project-phase';
import { selectAccountSiteMembership } from '../shared/account-mebership.selectors';
import { Model } from '../shared/models/base.model';
import { OptimisticDefaults } from '../shared/optimisticable-action';
import { SiteActions } from '../site';
import { TimesheetActions } from '../timesheet';
import { ApprovalType } from './models';

@Injectable()
export class ApprovalRequestEffects {
    loadApprovalRequests$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ApprovalRequestActions.loadApprovalRequests),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        ApprovalRequestActions.loadApprovalRequestsSuccess({
                            requests: response.payload.map((dto) =>
                                Model.createFromDto(ApprovalRequest, dto)
                            ),
                        })
                    ),
                    catchError((error) =>
                        of(ApprovalRequestActions.loadApprovalRequestsFail({ error }))
                    )
                )
            )
        )
    );

    processApproval$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ApprovalRequestActions.processApprovals),
            withLatestFrom(
                this.store$.select(ApprovalRequestSelectors.selectAll),
                this.store$.select(selectAccountSiteMembership)
            ),
            mergeMap(([payload, approvals, accountSiteMembership]) => {
                return payload.ids
                    .flatMap((approvalId) => {
                        const approval = approvals.find((a) => a.id === approvalId);
                        switch (approval.type) {
                            case ApprovalType.Timesheet:
                                return [
                                    TimesheetActions.manageActivityApproval({
                                        payload: {
                                            activityId: approval.originObjectId,
                                            approvalState: payload.state,
                                        },
                                        options: OptimisticDefaults,
                                    }),
                                    ApprovalRequestActions.updateApprovalState({
                                        id: approval.id,
                                        state: payload.state,
                                        siteMemberId: accountSiteMembership.id,
                                    }),
                                ] as Action[];

                            case ApprovalType.Gate:
                                return ProjectPhaseActions.manageProjectPhaseApproval({
                                    payload: {
                                        approvalId: approval.id,
                                        phaseId: approval.originObjectId,
                                        approvalState: payload.state,
                                    },
                                });

                            case ApprovalType.ChangeRequest:
                                return ChangeRequestActions.manageChangeRequestApproval({
                                    payload: {
                                        approvalId: approval.id,
                                        changeRequestApprovalId: approval.originObjectId,
                                        approvalState: payload.state,
                                    },
                                });

                            case ApprovalType.Decision:
                                return DecisionActions.manageDecisionApproval({
                                    payload: {
                                        approvalId: approval.id,
                                        decisionApproverId: approval.originObjectId,
                                        approvalState: payload.state,
                                    },
                                });

                            case ApprovalType.SiteOwnerChange:
                                return SiteActions.manageSiteOwnerChangeApproval({
                                    payload: {
                                        approvalId: approval.id,
                                        siteId: approval.originObjectId,
                                        approvalState: payload.state,
                                    },
                                });

                            default:
                                return null;
                        }
                    })
                    .filter((action) => !!action);
            })
        )
    );

    constructor(
        private store$: Store<AppStore.AppState>,
        private actions$: Actions,
        private restApiService: RestApiService
    ) {}
}
