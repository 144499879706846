import { Action, createAction, props } from '@ngrx/store';
import { LoadByProjectIdPayload, LoadBySiteIdPayload } from '../shared/models/load.payload';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import { IdPayload } from './../shared/models/shared-payloads';
import { AddActionPayload, PatchActionPayload, UpdateActionPayload } from './action.payloads';
import { ActionRecord } from './models/action.model';

// Get action actions

export const getAction = createAction('[Action] Get Action', props<{ payload: IdPayload }>());

export const getActionSuccess = createAction(
    '[Action] Get Action success',
    props<{ action: ActionRecord }>()
);

export const getActionFail = createAction('[Action] Get Action fail', props<{ error: any }>());

// Load Actions by Site Id actions

export const loadActionsBySiteId = createAction(
    '[Action] Load Actions by Site Id',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadActionsBySiteIdSuccess = createAction(
    '[Action] Load Actions by Site Id success',
    props<{ actions: ActionRecord[] }>()
);

export const loadActionsBySiteIdFail = createAction(
    '[Action] Load Actions by Site Id fail',
    props<{ error: any }>()
);

// Load Actions by Project Id actions

export const loadActionsByProjectId = createAction(
    '[Action] Load Actions by Project Id',
    props<{ payload: LoadByProjectIdPayload }>()
);

export const loadActionsByProjectIdSuccess = createAction(
    '[Action] Load Actions by Project Id success',
    props<{ actions: ActionRecord[] }>()
);

export const loadActionsByProjectIdFail = createAction(
    '[Action] Load Actions by Project Id fail',
    props<{ error: any }>()
);

// Add Action actions

export const addAction = createAction(
    '[Action] Add',
    props<{ payload: AddActionPayload; options?: OptimisticableActionOptions }>()
);

export const addActionSuccess = createAction(
    '[Action] Add success',
    props<{ action: ActionRecord; options?: OptimisticableActionOptions }>()
);

export const addActionFail = createAction(
    '[Action] Add fail',
    props<{ error: any; actionId: string; originAction: Action }>()
);

// Update Action actions

export const updateAction = createAction(
    '[Action] Update',
    props<{ payload: UpdateActionPayload; options?: OptimisticableActionOptions }>()
);

export const patchAction = createAction(
    '[Action] Patch',
    props<{ payload: PatchActionPayload; options?: OptimisticableActionOptions }>()
);

export const patchActionSuccess = createAction(
    '[Action] Patch success',
    props<PatchActionPayload>()
);

export const patchActionFail = createAction(
    '[Action] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Delete Action actions

export const deleteAction = createAction(
    '[Action] Delete',
    props<{ payload: IdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteActionSuccess = createAction('[Action] Delete success', props<IdPayload>());

export const deleteActionFail = createAction(
    '[Action] Delete fail',
    props<{ error: any; originAction: Action }>()
);
