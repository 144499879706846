import { JsonProperty } from 'app/utils/json-mapper';
import { CtxColor, CtxMember } from '../../../models/shared-models';
import { Model } from '../../shared/models/base.model';
import { RaidRecord } from '../../shared/models/raid-shared.model';

export class ActionRecord extends Model implements RaidRecord {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('projectId')
    projectId: string = undefined;

    @JsonProperty('textId')
    textId?: string;

    @JsonProperty('dueDate')
    dueDate?: Date;

    @JsonProperty('creationDate')
    creationDate: string = undefined;

    @JsonProperty('closingDate')
    closingDate: Date;

    @JsonProperty('title')
    title?: string;

    @JsonProperty('description')
    description?: string;

    @JsonProperty('statusId')
    statusId: string = undefined;

    @JsonProperty('attachedDocumentIds')
    attachedDocumentIds?: string[];

    @JsonProperty('members')
    members?: CtxMember[];

    @JsonProperty('checklist')
    checklist?: ActionCheckItem[];

    @JsonProperty('progress')
    progress?: number;

    @JsonProperty('color')
    color?: CtxColor;

    @JsonProperty('originObjectId')
    originObjectId?: string;

    constructor(init?: Partial<ActionRecord>) {
        super();
        Object.assign(this, init);
    }
}

export interface ActionCheckItem {
    isChecked: boolean;
    name: string;
}
