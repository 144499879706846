import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LoadProgramRolesResponse } from 'app/core/services/rest-api/features/program-role/program-role.responses';
import { getPatch } from 'app/utils/json-patch';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ProgramRoleSelectors } from '.';
import { AppStore } from '..';
import { RestApiService } from '../../services/rest-api';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import { ProgramRole } from './models';
import * as ProgramRoleActions from './program-role.actions';

@Injectable()
export class ProgramRoleEffects {
    loadProgramRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramRoleActions.loadProgramRoleTemplates),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: LoadProgramRolesResponse) =>
                        response.payload.map((dto) => Model.createFromDto(ProgramRole, dto))
                    ),
                    map((roles) => ProgramRoleActions.loadProgramRoleTemplatesSuccess({ roles })),
                    catchError((error) =>
                        of(ProgramRoleActions.loadProgramRoleTemplatesFail({ error }))
                    )
                )
            )
        )
    );

    loadRolesBySite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramRoleActions.loadProgramRolesBySite),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: LoadProgramRolesResponse) =>
                        response.payload.map((dto) => Model.createFromDto(ProgramRole, dto))
                    ),
                    map((roles) => ProgramRoleActions.loadProgramRolesBySiteSuccess({ roles })),
                    catchError((error) =>
                        of(ProgramRoleActions.loadProgramRolesBySiteFail({ error }))
                    )
                )
            )
        )
    );

    addProgramRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramRoleActions.addProgramRole),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) =>
                        ProgramRoleActions.addProgramRoleSuccess({
                            role: Model.createFromDto(ProgramRole, response.payload),
                        })
                    ),
                    catchError((error) => of(ProgramRoleActions.addProgramRoleFail({ error })))
                )
            )
        )
    );

    updateProgramRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramRoleActions.updateProgramRole),
            withLatestFrom(this.store$.select(ProgramRoleSelectors.selectAll)),
            mergeMap(([action, roles]) => {
                const roleToPatch = roles.find((role) => role.id === action.payload.id);
                const patch = getPatch(roleToPatch, action.payload.changes);
                return of(
                    ProgramRoleActions.patchProgramRole({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchProgramRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramRoleActions.patchProgramRole),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ProgramRoleActions.patchProgramRoleSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) =>
                        of(ProgramRoleActions.patchProgramRoleFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    setProgramRoleTag$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramRoleActions.setProgramRoleTag),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ProgramRoleActions.setProgramRoleTagSuccess({
                            programRoles: response.payload.map((dto) =>
                                Model.createFromDto(ProgramRole, dto)
                            ),
                        });
                    }),
                    catchError((error) =>
                        of(
                            ProgramRoleActions.setProgramRoleTagFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteProgramRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramRoleActions.deleteProgramRole),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ProgramRoleActions.deleteProgramRoleSuccess({
                            id: action.payload.id,
                        });
                    }),
                    catchError((error) =>
                        of(
                            ProgramRoleActions.deleteProgramRoleFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>
    ) {}
}
