import { JsonProperty } from 'app/utils/json-mapper';
import { CellProperties } from 'handsontable/settings';

export class HandsontableSettings {
    @JsonProperty('id')
    id: string;

    @JsonProperty('originTableId')
    originTableId?: string;

    @JsonProperty('projectId')
    projectId?: string;

    @JsonProperty('originTableType')
    originTableType?: HandsontableSettingsOriginType;

    @JsonProperty('cellsMeta')
    cellsMeta?: CellProperties[];

    constructor(init?: Partial<HandsontableSettings>) {
        Object.assign(this, init);
    }
}

export enum HandsontableSettingsOriginType {
    Risks = 'risks',
    Actions = 'actions',
    Issues = 'issues',
    Decisions = 'decisions',
    Raci = 'raci',
}
