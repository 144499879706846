import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Minute } from './models';

export interface State extends EntityState<Minute> {
    serverRequestInProgress: boolean;
    loaded: boolean;
    expandedFolderIdsMap: Record<string, string[]>;
    openedFolderIdMap: Record<string, string>;
}

export const adapter = createEntityAdapter<Minute>();

export const initialState: State = adapter.getInitialState({
    serverRequestInProgress: false,
    loaded: false,
    expandedFolderIdsMap: {},
    openedFolderIdMap: {},
});
