import { createSelector } from '@ngrx/store';
import { AccountSelectors } from 'app/core/store/account';
import { AccountProjectMemberStatusDictionary } from 'app/features/site/timesheet-page/timesheet/timesheet.component';
import { ProgramMemberSelectors } from '../program-member';
import { ProjectMemberSelectors } from '../project-member';
import { SiteMemberSelectors } from '../resource';
import { selectCurrentSiteId } from './../site/site.selectors';

export const selectAccountSiteMembership = createSelector(
    SiteMemberSelectors.selectSiteMemberEntry,
    SiteMemberSelectors.selectAll,
    AccountSelectors.selectUserAccount,
    selectCurrentSiteId,
    (siteMemberEntry, siteMembers, account, siteId) => {
        const siteMember = siteMembers.find(
            (m) => m.accountId === account.id && m.siteId === siteId
        );
        if (siteMember) return siteMember;
        return siteMemberEntry.currentSiteMember;
    }
);

export const selectAccountProjectMembership = (projectId: string) =>
    createSelector(
        ProjectMemberSelectors.selectAll,
        selectAccountSiteMembership,
        (projectMembers, currentSiteMember) =>
            projectMembers.find(
                (m) => m.projectId === projectId && m.siteMemberId === currentSiteMember.id
            )
    );

export const selectAccountProjectMembers = createSelector(
    ProjectMemberSelectors.selectAll,
    selectAccountSiteMembership,
    (projectMembers, accountSiteMember) =>
        projectMembers.filter((m) => m.siteMemberId === accountSiteMember?.id)
);

export const selectAccountProjectMembersStatusMap = createSelector(
    selectAccountProjectMembers,
    (members) => {
        const map: AccountProjectMemberStatusDictionary = {};
        members.forEach(
            (member) =>
                (map[member.projectId] = {
                    status: member.status,
                    statusChangeDate: member.statusChangeDate,
                })
        );
        return map;
    }
);

export const selectAccountProgramMembers = createSelector(
    ProgramMemberSelectors.selectAll,
    selectAccountSiteMembership,
    (programMembers, accountSiteMember) =>
        programMembers.filter((m) => m.siteMemberId === accountSiteMember?.id)
);
