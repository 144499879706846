import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Timesheet } from './models';

export interface LoadingStatus {
    serverRequestInProgress: boolean;
    loaded: boolean;
}

export interface State extends EntityState<Timesheet>, LoadingStatus {}

export const adapter: EntityAdapter<Timesheet> = createEntityAdapter<Timesheet>();

export const initialState: State = adapter.getInitialState({
    serverRequestInProgress: false,
    loaded: false,
});
