import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AsyncUpdateStatus, AsyncUpdateStatusDefault } from '../shared/async-update-status.store';
import { UserLog } from './models';

export interface State extends EntityState<UserLog>, AsyncUpdateStatus {}

export const adapter: EntityAdapter<UserLog> = createEntityAdapter<UserLog>();

export const initialState: State = adapter.getInitialState({
    ...AsyncUpdateStatusDefault,
});
