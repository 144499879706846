import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';

@Injectable()
export class AppOperationEffects {
    // @Effect()
    // userNotAuthorizedResponseReceived$ = this.actions$
    //     .ofType(appOperationActions.USER_NOT_AUTHORIZED_RESPONSE_RECEIVED)
    //     // TODO: here we probably have to inform user that they should relogin
    //     .switchMap(() => Observable.of(new authActions.Logout()));

    constructor(private actions$: Actions) {}
}
