import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { AsyncUpdateStatus, AsyncUpdateStatusDefault } from '../shared/async-update-status.store';
import { ProjectPermissions } from './../shared/models/shared-permissions.model';
import { Project, ProjectRaciTable, ProjectSettings } from './project.model';

export interface State extends EntityState<Project>, AsyncUpdateStatus {
    currentProjectId: string;
    currentProjectPermissions: ProjectPermissions;
    projectSettingsMap: Record<string, ProjectSettings>;
    projectSettingsLoadingMap: Record<string, AsyncUpdateStatus>;
    raciTablesMap: Record<string, ProjectRaciTable>;
    initialProjectId: string;
}

export const adapter = createEntityAdapter<Project>();

export const initialState: State = adapter.getInitialState({
    ...AsyncUpdateStatusDefault,
    currentProjectId: null,
    projectSettingsMap: {},
    projectSettingsLoadingMap: {},
    currentProjectPermissions: null,
    raciTablesMap: {},
    initialProjectId: undefined,
});
