import {
    LoadByProjectIdPayload,
    LoadBySiteIdPayload,
} from 'app/core/store/shared/models/load.payload';
import { IdPayload } from 'app/core/store/shared/models/shared-payloads';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import { AddVendorPayload, PatchVendorPayload } from '../../../../store/vendor/vendor.payloads';

export const loadVendorsByProjectIdCommand = (
    payload: LoadByProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projects', value: payload.projectId }, { name: 'vendors' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadVendorsBySiteIdCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'vendors' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addVendorCommand = (payload: AddVendorPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'vendors' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchVendorCommand = (payload: PatchVendorPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'vendors', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteVendorCommand = (payload: IdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'vendors', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};
