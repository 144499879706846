import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AsyncUpdateStatus } from '../shared/async-update-status.store';
import { SiteSettings } from './models';

export interface State extends EntityState<SiteSettings>, AsyncUpdateStatus {}

export const adapter: EntityAdapter<SiteSettings> = createEntityAdapter<SiteSettings>();

export const initialState: State = adapter.getInitialState({
    serverRequestInProgress: false,
    loaded: false,
    error: null,
    loadingFailed: false,
});
