import { Action, createAction, props } from '@ngrx/store';
import { OptimisticableActionOptions } from 'app/core/store/shared/optimisticable-action';
import { LoadByProjectIdPayload, LoadBySiteIdPayload } from '../shared/models/load.payload';
import { IdPayload } from '../shared/models/shared-payloads';
import { Vendor } from './models';
import { AddVendorPayload, PatchVendorPayload, UpdateVendorPayload } from './vendor.payloads';

// Load vendors actions
export const loadVendorsByProjectId = createAction(
    '[Vendor] Load vendors by Project Id',
    props<{ payload: LoadByProjectIdPayload }>()
);

export const loadVendorsByProjectIdSuccess = createAction(
    '[Vendor] Load vendors by Project success',
    props<{ vendors: Vendor[]; projectId: string }>()
);

export const loadVendorsByProjectIdFail = createAction(
    '[Vendor] Load vendors by Project fail',
    props<{ error: any; projectId: string }>()
);

export const loadVendorsBySiteId = createAction(
    '[Vendor] Load vendors by Site Id',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadVendorsBySiteIdSuccess = createAction(
    '[Vendor] Load vendors by Site success',
    props<{ vendors: Vendor[]; siteId: string; projectIds: string[] }>()
);

export const loadVendorsBySiteIdFail = createAction(
    '[Vendor] Load vendors by Site fail',
    props<{ error: any; siteId: string }>()
);

// Add vendor actions

export const addVendor = createAction('[Vendor] Add', props<{ payload: AddVendorPayload }>());

export const addVendorSuccess = createAction('[Vendor] Add success', props<{ vendor: Vendor }>());

export const addVendorFail = createAction('[Vendor] Add fail', props<{ error: any }>());

// Update vendor actions

export const updateVendor = createAction(
    '[Vendor] Update',
    props<{ payload: UpdateVendorPayload; options?: OptimisticableActionOptions }>()
);
export const patchVendor = createAction(
    '[Vendor] Patch',
    props<{ payload: PatchVendorPayload; options?: OptimisticableActionOptions }>()
);

export const patchVendorSuccess = createAction(
    '[Vendor] Patch success',
    props<PatchVendorPayload>()
);

export const patchVendorFail = createAction(
    '[Vendor] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Delete vendor actions

export const deleteVendor = createAction(
    '[Vendor] Delete',
    props<{ payload: IdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteVendorSuccess = createAction('[Vendor] Delete success', props<IdPayload>());

export const deleteVendorFail = createAction(
    '[Vendor] Delete fail',
    props<{ error: any; originAction: Action }>()
);
