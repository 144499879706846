import { Action, createAction, props } from '@ngrx/store';
import { LoadByProjectIdPayload, LoadBySiteIdPayload } from '../shared/models/load.payload';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import { ErrorPayload } from '../shared/payloads';
import { ProjectStatusReport } from './models/project-status-report.model';
import {
    AddProjectStatusPayload,
    PatchProjectStatusPayload,
    ProjectStatusIdPayload,
    UpdateProjectStatusPayload,
} from './project-status-report.payloads';

// Get Project Statuses actions

export const loadProjectStatuses = createAction(
    '[ProjectStatus] Load',
    props<{ payload: LoadByProjectIdPayload }>()
);

export const loadProjectStatusesSuccess = createAction(
    '[ProjectStatus] Load success',
    props<{ statuses: ProjectStatusReport[]; projectId: string }>()
);

export const loadProjectStatusesFail = createAction(
    '[ProjectStatus] Load fail',
    props<{ error: any; projectId: string }>()
);

export const loadProjectStatusesBySiteId = createAction(
    '[ProjectStatus] Load by Site id',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadProjectStatusesBySiteIdSuccess = createAction(
    '[ProjectStatus] Load by Site id success',
    props<{ statuses: ProjectStatusReport[]; siteId: string; projectIds: string[] }>()
);

export const loadProjectStatusesBySiteIdFail = createAction(
    '[ProjectStatus] Load by Site id fail',
    props<{ error: any; siteId: string }>()
);

// Add Project Status actions

export const addProjectStatus = createAction(
    '[ProjectStatus] Add',
    props<{ payload: AddProjectStatusPayload; showSnackBar: boolean }>()
);

export const addProjectStatusSuccess = createAction(
    '[ProjectStatus] Add success',
    props<{ report: ProjectStatusReport }>()
);

export const addProjectStatusFail = createAction(
    '[ProjectStatus] Add fail',
    props<{ error: any }>()
);

// Update Project Status actions

export const updateProjectStatus = createAction(
    '[ProjectStatus] Update',
    props<{ payload: UpdateProjectStatusPayload; options?: OptimisticableActionOptions }>()
);

export const patchProjectStatus = createAction(
    '[ProjectStatus] Patch',
    props<{ payload: PatchProjectStatusPayload; options?: OptimisticableActionOptions }>()
);

export const patchProjectStatusSuccess = createAction(
    '[ProjectStatus] patch success',
    props<PatchProjectStatusPayload>()
);

export const patchProjectStatusFail = createAction(
    '[ProjectStatus] patch fail',
    props<{ payload: ErrorPayload; originAction: Action }>()
);

// Delete Project Status actions

export const deleteProjectStatus = createAction(
    '[ProjectStatus] Delete',
    props<{ payload: ProjectStatusIdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteProjectStatusSuccess = createAction(
    '[ProjectStatus] Delete success',
    props<ProjectStatusIdPayload>()
);

export const deleteProjectStatusFail = createAction(
    '[ProjectStatus] Delete fail',
    props<{ payload: ErrorPayload; originAction: Action }>()
);
