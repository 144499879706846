import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StateEntry } from '../state-entry';
import { State } from './app-operation.store';

const selectAppOperationEntry = createFeatureSelector<State>(StateEntry.AppOperation);

export const selectAppIsReady = createSelector(
    selectAppOperationEntry,
    (state: State) => state.isReady
);
export const selectApiServerError = createSelector(
    selectAppOperationEntry,
    (state: State) => state.apiServerError
);
export const selectUserNotAuthorizedResponseReceived = createSelector(
    selectAppOperationEntry,
    (state: State) => state.userNotAuthorizedResponseReceived
);

export const selectAuthenticationRedirectUrl = createSelector(
    selectAppOperationEntry,
    (state: State) => state.authenticationRedirectUrl
);
