import { Action, createAction, props } from '@ngrx/store';
import { ProgramPermissions } from '../shared/models/shared-permissions.model';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import { SitePayloads } from '../site';
import { Program } from './program.model';
import {
    AddProgramPayload,
    PatchProgramPayload,
    ProgramIdPayload,
    UpdateProgramPayload,
} from './program.payloads';

// Get programs actions

export const loadPrograms = createAction(
    '[Programs] Load',
    props<{ payload: SitePayloads.SiteIdPayload }>()
);

export const loadProgramsSuccess = createAction(
    '[Programs] Load success',
    props<{ programs: Program[] }>()
);

export const loadProgramsFail = createAction('[Programs] Load fail', props<{ error: any }>());

// Get program by id actions

export const getProgramById = createAction(
    '[Programs] Get by id',
    props<{ payload: ProgramIdPayload }>()
);

export const getProgramByIdSuccess = createAction(
    '[Programs] Get by id success',
    props<{ program: Program }>()
);

export const getProgramByIdFail = createAction(
    '[Programs] Get by id fail',
    props<{ error: any }>()
);

// Get initial program

export const getInitialProgram = createAction(
    '[Programs] Get initial program',
    props<{ payload: ProgramIdPayload }>()
);

export const getInitialProgramFail = createAction(
    '[Programs] Get initial program fail',
    props<{ error: any }>()
);

export const setInitialProgramLoaded = createAction(
    '[Programs] Set initial program loaded',
    props<ProgramIdPayload>()
);

// Get program permissions actions

export const getProgramPermissions = createAction(
    '[Programs] Get permissions',
    props<{ payload: ProgramIdPayload }>()
);

export const getProgramPermissionsSuccess = createAction(
    '[Programs] Get permissions success',
    props<{ permissions: ProgramPermissions }>()
);

export const getProgramPermissionsFail = createAction(
    '[Programs] Get permissions fail',
    props<{ error: any }>()
);

// Add program actions

export const addProgram = createAction('[Programs] Add', props<{ payload: AddProgramPayload }>());

export const addProgramSuccess = createAction(
    '[Programs] Add success',
    props<{ program: Program }>()
);

export const addProgramFail = createAction('[Programs] Add fail', props<{ error: any }>());

// Update program actions

export const updateProgram = createAction(
    '[Programs] Update',
    props<{ payload: UpdateProgramPayload; options?: OptimisticableActionOptions }>()
);

export const patchProgram = createAction(
    '[Programs] Patch',
    props<{ payload: PatchProgramPayload; options?: OptimisticableActionOptions }>()
);

export const patchProgramSuccess = createAction(
    '[Programs] Patch success',
    props<PatchProgramPayload>()
);

export const patchProgramFail = createAction(
    '[Programs] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Delete program actions

export const deleteProgram = createAction(
    '[Programs] Delete',
    props<{ payload: ProgramIdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteProgramSuccess = createAction(
    '[Programs] Delete success',
    props<{ payload: ProgramIdPayload }>()
);

export const deleteProgramFail = createAction('[Programs] Delete fail', props<{ error: any }>());

// Current program actions

export const changeCurrentProgramId = createAction(
    '[Programs] Change current program id',
    props<{ payload: ProgramIdPayload }>()
);
