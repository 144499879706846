import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProgramRoleSelectors } from 'app/core/store/program-role';
import {
    mergeWithSubscriptionPermissionsPredicate,
    ProjectPermissions,
    ProjectRoleTag,
} from 'app/core/store/shared/models/shared-permissions.model';
import { deepClone } from 'fast-json-patch';
import { mergeDeepWithKey } from 'ramda';
import { ProjectSelectors } from '../project';
import { ProjectMemberSelectors } from '../project-member';
import { selectAccountSiteMembership } from '../shared/account-mebership.selectors';
import { SiteSelectors } from '../site';
import { SiteRoleSelectors } from '../site-role';
import { StateEntry } from '../state-entry';
import { ProjectRole } from './models';
import { adapter, State } from './project-role.store';

export const selectProjectRoleEntry = createFeatureSelector<State>(StateEntry.ProjectRole);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectProjectRoleEntry);

export const selectProjectRoleTemplatesLoadingStatus = createSelector(
    selectProjectRoleEntry,
    (state) => state.templatesLoadingStatus
);

export const selectCurrentSiteProjectRolesLoadingStatus = createSelector(
    selectProjectRoleEntry,
    (state) => state.currentSiteRolesLoadingStatus
);

export const selectCurrentSiteProjectRoles = createSelector(
    selectAll,
    SiteSelectors.selectCurrentSiteId,
    (roles, siteId) => roles.filter((r) => r.siteId === siteId)
);

export const selectProjectRoleTemplates = createSelector(selectAll, (roles) =>
    roles.filter((r) => r.isTemplate)
);

export const selectAllLoadedTemplates = createSelector(
    selectProjectRoleTemplates,
    selectProjectRoleTemplatesLoadingStatus,
    (all, status) => (status?.loaded ? all : null)
);

export const selectAllLoadedCurrentSiteProjectRoles = createSelector(
    selectCurrentSiteProjectRoles,
    selectCurrentSiteProjectRolesLoadingStatus,
    (all, status) => (status?.loaded ? all : null)
);

export const selectAccountProjectRolesMap = createSelector(
    selectCurrentSiteProjectRoles,
    SiteRoleSelectors.selectCurrentSitePermissions,
    ProgramRoleSelectors.selectProgramRolesMap,
    selectAccountSiteMembership,
    ProjectMemberSelectors.selectAll,
    ProjectSelectors.selectAll,
    SiteRoleSelectors.selectCurrentSiteSubscriptionPermissions,
    (
        projectRoles,
        sitePermissions,
        programRolesMap,
        userSiteMembership,
        projectMembrs,
        projects,
        subscriptionPermissions
    ) => {
        const map: { [projectId: string]: ProjectRole } = {};
        if (!sitePermissions) return map;
        const siteLevelProjectPermissions: ProjectPermissions = sitePermissions?.projects || {};
        projects.forEach((project) => {
            const projectMembership = projectMembrs?.find(
                (pm) => pm.siteMemberId === userSiteMembership?.id && pm.projectId === project.id
            );
            const projectRole = projectRoles.find((role) => role.id === projectMembership?.roleId);
            const projectPermissions = projectRole?.permissions || {};
            const programLevelProjectPermissions: ProjectPermissions =
                programRolesMap[project.programId]?.permissions?.projects || {};
            const mergedProjectToProgramPermissions = mergeDeepWithKey(
                (k, l, r) => l || r,
                deepClone(programLevelProjectPermissions),
                deepClone(projectPermissions)
            );
            const mergedAllLevelPermissions = mergeDeepWithKey(
                (k, l, r) => l || r,
                deepClone(siteLevelProjectPermissions),
                mergedProjectToProgramPermissions
            );
            map[project.id] = {
                ...(projectRole || new ProjectRole()),
                permissions: mergeDeepWithKey(
                    mergeWithSubscriptionPermissionsPredicate,
                    mergedAllLevelPermissions,
                    deepClone(subscriptionPermissions.projects) || {}
                ),
            };
        });
        return map;
    }
);

export const selectCurrentProjectRole = createSelector(
    ProjectSelectors.selectCurrentProjectId,
    selectAccountProjectRolesMap,
    (projectId, rolesMap) => rolesMap[projectId]
);

export const selectCurrentProjectPermissions = createSelector(
    selectCurrentProjectRole,
    ProjectSelectors.selectProjectEntry,
    (role, entry) => role?.permissions || entry.currentProjectPermissions || undefined
);

export const selectCurrentSiteProjectRoleByTag = (tag: ProjectRoleTag) =>
    createSelector(selectCurrentSiteProjectRoles, (roles) => roles.find((r) => r.tag === tag));
