import { Command } from './base.command';

export class SignalRCommand extends Command {
    get method(): string {
        return this._method;
    }

    set method(value: string) {
        this._method = value;
    }
}
