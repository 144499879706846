import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Portfolio } from './models';

export interface LoadingStatus {
    serverRequestInProgress: boolean;
    loaded: boolean;
}

export interface State extends EntityState<Portfolio>, LoadingStatus {}

export const adapter: EntityAdapter<Portfolio> = createEntityAdapter<Portfolio>();

export const initialState: State = adapter.getInitialState({
    serverRequestInProgress: false,
    loaded: false,
});
