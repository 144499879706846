import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SiteSelectors } from '../site';
import { StateEntry } from '../state-entry';
import { adapter, State } from './program.store';

export const selectProgramEntry = createFeatureSelector<State>(StateEntry.Program);

// Pay attention: adapter selectors return programs for all sites
// TODO: do not store data for different sites at once, clear the store on site change instead

// Simplified object destructuring is consciously not used due to incorrect work of
// find usage feature (Shift+F12) - remove right hand side destructuring component
// (after semicolon) and try to find usages - it results to many unwanted entries found
// TODO: track related issue https://github.com/Microsoft/TypeScript/issues/15787
export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectProgramEntry);

export const selectAllLoaded = createSelector(selectAll, selectProgramEntry, (items, entry) =>
    entry.loaded ? items : null
);

export const selectCurrentSitePrograms = createSelector(
    selectAll,
    SiteSelectors.selectCurrentSiteId,
    (programs, siteId) => programs.filter((program) => program.siteId === siteId)
);

export const selectNotArchivedPrograms = createSelector(selectCurrentSitePrograms, (programs) =>
    programs.filter((p) => !p.isArchived)
);

export const selectArchivedPrograms = createSelector(selectCurrentSitePrograms, (programs) =>
    programs.filter((p) => p.isArchived)
);

export const selectCurrentProgramId = createSelector(
    selectProgramEntry,
    (state: State) => state.currentProgramId
);

export const selectCurrentProgram = createSelector(
    selectEntities,
    selectCurrentProgramId,
    (programEntities, programId) => programEntities[programId]
);

export const selectProgramById = (id: string) =>
    createSelector(selectNotArchivedPrograms, (programs) => programs.find((p) => p.id === id));

export const selectInitialProgramId = createSelector(
    selectProgramEntry,
    (state) => state.initialProgramId
);
