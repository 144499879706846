import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { LoadingStatusByProjectId } from 'app/core/models/shared-models';
import { Sprint } from './models/sprint.model';

export interface State extends EntityState<Sprint>, LoadingStatusByProjectId {}

export const adapter: EntityAdapter<Sprint> = createEntityAdapter<Sprint>();

export const initialState: State = adapter.getInitialState({
    loadingStatusByProjectId: {},
});
