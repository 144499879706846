import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EntityPatchSuccessResponse } from 'app/core/services/rest-api/features/patch.response';
import { ProjectFieldSelectors } from 'app/core/store/project-field';
import { getPatch } from 'app/utils/json-patch';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { RestApiService } from '../../services/rest-api';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import * as AppStore from '../store';
import { CommandResult } from './../../../utils/network/commands/base.command';
import { ProjectField } from './models';
import * as ProjectFieldActions from './project-field.actions';

@Injectable()
export class ProjectFieldEffects {
    loadProjectFieldsBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectFieldActions.loadProjectFieldsBySiteId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        ProjectFieldActions.loadProjectFieldsBySiteIdSuccess({
                            fields: response.payload.map((dto) =>
                                Model.createFromDto(ProjectField, dto)
                            ),
                        })
                    ),
                    catchError((error) =>
                        of(
                            ProjectFieldActions.loadProjectFieldsFail({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    loadProjectFieldsByCompletixSettingsTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectFieldActions.loadProjectFieldsByCompletixSettingsTemplate),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        ProjectFieldActions.loadProjectFieldsByCompletixSettingsTemplateSuccess({
                            fields: response.payload.map((dto) =>
                                Model.createFromDto(ProjectField, dto)
                            ),
                        })
                    ),
                    catchError((error) =>
                        of(
                            ProjectFieldActions.loadProjectFieldsByCompletixSettingsTemplateFail({
                                error,
                                id: action.payload.id,
                            })
                        )
                    )
                )
            )
        )
    );

    updateProjectField$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectFieldActions.updateProjectField),
            withLatestFrom(this.store$.select(ProjectFieldSelectors.selectAll)),
            mergeMap(([action, projectFields]) => {
                const updatedField = projectFields.find((pf) => pf.id === action.payload.id);
                const patch = getPatch(updatedField, action.payload.changes);
                return of(
                    ProjectFieldActions.patchProjectField({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchProjectField$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectFieldActions.patchProjectField),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: EntityPatchSuccessResponse) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ProjectFieldActions.patchProjectFieldSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) =>
                        of(
                            ProjectFieldActions.patchProjectFielddFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>
    ) {}
}
