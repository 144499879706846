import { deserialize } from '../../json-mapper';
import { BaseResponse } from './base.response';

export abstract class JsonResponse extends BaseResponse {
    protected deserializeModel<T>(modelClass: new () => T): T {
        return deserialize(modelClass, this.commandResult.payload);
    }

    protected deserializeModels<T>(modelClass: new () => T): T[] {
        return (this.commandResult.payload as any[]).map((dto) => deserialize(modelClass, dto));
    }
}
