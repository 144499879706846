import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import { deepClone } from 'fast-json-patch';
import {
    AsyncUpdateStatusDefault,
    AsyncUpdateStatusDefaultLoad,
    AsyncUpdateStatusDefaultLoadSuccess,
} from '../shared/async-update-status.store';
import { ProjectStatusReport } from './models';
import * as ProjectStatusReportActions from './project-status-report.actions';
import { adapter, initialState } from './project-status-report.store';

export const reducer = createReducer(
    initialState,

    on(ProjectStatusReportActions.loadProjectStatuses, (state, action) => {
        const loadingStatusMap = state.loadingStatusByProjectId;
        const projectId = action.payload.projectId;

        return {
            ...state,
            loadingStatusByProjectId: {
                ...loadingStatusMap,
                [projectId]: {
                    ...(loadingStatusMap[projectId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoad,
                },
            },
        };
    }),

    on(ProjectStatusReportActions.loadProjectStatusesSuccess, (state, payload) => {
        const projectReportIds = Object.values(state.entities)
            .filter((r) => r.projectId === payload.projectId)
            .map((r) => r.id);
        const newState = adapter.addMany(
            payload.statuses,
            adapter.removeMany(projectReportIds, state)
        );
        const loadingStatusMap = state.loadingStatusByProjectId;
        const projectId = payload.projectId;

        return {
            ...newState,
            loadingStatusByProjectId: {
                ...loadingStatusMap,
                [projectId]: {
                    ...(loadingStatusMap[projectId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                },
            },
        };
    }),

    on(ProjectStatusReportActions.loadProjectStatusesFail, (state, action) => {
        const loadingStatusMap = state.loadingStatusByProjectId;
        const projectId = action.projectId;

        return {
            ...state,
            loadingStatusByProjectId: {
                ...loadingStatusMap,
                [projectId]: {
                    ...(loadingStatusMap[projectId] ?? {}),
                    ...AsyncUpdateStatusDefault,
                    loadingFailed: true,
                    error: action.error,
                },
            },
        };
    }),

    on(ProjectStatusReportActions.loadProjectStatusesBySiteId, (state, action) => {
        const loadingStatusMap = state.loadingStatusBySiteId;
        const siteId = action.payload.siteId;

        return {
            ...state,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoad,
                },
            },
        };
    }),

    on(ProjectStatusReportActions.loadProjectStatusesBySiteIdSuccess, (state, payload) => {
        const projectIds = payload.projectIds;
        const projectReportIds = Object.values(state.entities)
            .filter((r) => projectIds.includes(r.projectId))
            .map((r) => r.id);
        const newState = adapter.addMany(
            payload.statuses,
            adapter.removeMany(projectReportIds, state)
        );

        const loadingStatusMap = state.loadingStatusBySiteId;
        const siteId = payload.siteId;
        const loadingByProjectIdMap = deepClone(state.loadingStatusByProjectId);
        projectIds.forEach(
            (id) =>
                (loadingByProjectIdMap[id] = {
                    ...(loadingByProjectIdMap[id] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                })
        );

        return {
            ...newState,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                },
            },
            loadingStatusByProjectId: loadingByProjectIdMap,
        };
    }),

    on(ProjectStatusReportActions.loadProjectStatusesBySiteIdFail, (state, action) => {
        const loadingStatusMap = state.loadingStatusBySiteId;
        const siteId = action.siteId;

        return {
            ...state,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefault,
                    loadingFailed: true,
                    error: action.error,
                },
            },
        };
    }),

    on(ProjectStatusReportActions.addProjectStatusSuccess, (state, payload) => {
        const reportExists = !!state.ids.some((id) => id === payload.report.id);
        const modifiedState = reportExists ? adapter.removeOne(payload.report.id, state) : state;
        return adapter.addOne(payload.report, modifiedState);
    }),

    on(ProjectStatusReportActions.patchProjectStatus, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patched = getPatched(state.entities[action.payload.id], action.payload.patch);
        const update: Update<ProjectStatusReport> = {
            id: patched.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(ProjectStatusReportActions.patchProjectStatusSuccess, (state, payload) => {
        const patched = getPatched(state.entities[payload.id], payload.patch);
        const update: Update<ProjectStatusReport> = {
            id: patched.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(ProjectStatusReportActions.patchProjectStatusFail, (state) => state),

    on(ProjectStatusReportActions.deleteProjectStatus, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(ProjectStatusReportActions.deleteProjectStatusSuccess, (state, payload) =>
        adapter.removeOne(payload.id, state)
    ),

    on(ProjectStatusReportActions.deleteProjectStatusFail, (state) => state)
);
