import { DocumentPayloads } from 'app/core/store/document';
import {
    DocumentIdPayload,
    DownloadDocumentPayload,
    ImportDocumentPayload,
    LoadDocumentsByProgramPayload,
    LoadDocumentsByProjectPayload,
    LoadDocumentsBySitePayload,
} from 'app/core/store/document/document.payloads';
import { LoadBySiteIdPayload } from 'app/core/store/shared/models/load.payload';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import { FormDataPayload } from 'app/utils/network/commands/payloads/form-data.command.payload';

export const loadCompletixDocumentTemplates = (payload: any, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'documents' }, { name: 'completixTemplates' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadCompletixDocumentTemplatesBySiteId = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'documents' },
        { name: 'completixTemplates' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadDocumentsByProject = (
    payload: LoadDocumentsByProjectPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projects', value: payload.projectId },
        { name: 'documents' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadDocumentsByProgram = (
    payload: LoadDocumentsByProgramPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'programs', value: payload.programId },
        { name: 'documents' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadDocumentsBySite = (
    payload: LoadDocumentsBySitePayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'documents' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadDocumentChangeRequestFormBySiteId = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'documents/changeRequestForm' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadCompletixDocumentChangeRequestFormBySiteId = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'documents/completixTemplates/changeRequestForm' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadCompletixDocumentChangeRequestForm = (
    payload: any,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'documents/completixTemplates/changeRequestForm' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addDocument = (
    payload: DocumentPayloads.AddDocumentPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'documents' }]);
    const formData = new FormData();
    for (const prop in payload.createOptions) {
        if (prop) {
            const value = payload.createOptions[prop];
            if (value !== undefined) {
                formData.append(prop, value);
            }
        }
    }
    baseCommand.payload = new FormDataPayload();
    baseCommand.payload.setData(formData);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const importDocument = (payload: ImportDocumentPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'documents', value: payload.documentId }, { name: 'import' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchDocument = (
    payload: DocumentPayloads.PatchDocumentPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'documents', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const addDocumentVersion = (
    payload: DocumentPayloads.DocumentIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'documents', value: payload.documentId },
        { name: 'versions' },
    ]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const copyDocuments = (
    payload: DocumentPayloads.CopyDocumentsPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'documents' }, { name: 'copy' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const deleteDocument = (
    payload: DocumentPayloads.DocumentIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'documents', value: payload.documentId }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};

export const downloadDocument = (payload: DownloadDocumentPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([
        { name: 'documents', value: payload.documentId },
        { name: 'download' },
    ]);
    baseCommand.payload.setData(payload.type);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const getDocumentPreview = (payload: DocumentIdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([
        { name: 'documents', value: payload.documentId },
        { name: 'preview' },
    ]);
    baseCommand.payload.setData({
        documentId: payload.documentId,
    });
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};
