import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class FileDownloadService {
    constructor(private sanitizer: DomSanitizer) {}

    downloadFileByBlob(blob: Blob, name?: string) {
        const mime = require('mime');
        const extension = mime.getExtension(blob.type);
        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = `${name || 'file'}.${extension}`;
        link.click();
    }

    downloadPdfByUrl(url: string, name: string) {
        const link = document.createElement('a');
        link.href = url;
        link.download = `${name || 'file'}.pdf`;
        link.click();
    }
}
