import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EntityPatchSuccessResponse } from 'app/core/services/rest-api/features/patch.response';
import { LoadProjectRequestsResponse } from 'app/core/services/rest-api/features/project-request/project-request.responses';
import { SnackBarService } from 'app/services/snack-bar/snack-bar.service';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';
import { RestApiService } from '../../services/rest-api';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import { ProjectRequest } from './models';
import * as ProjectRequestActions from './project-request.actions';

@Injectable()
export class ProjectRequestEffects {
    loadProjectRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectRequestActions.loadProjectRequests),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: LoadProjectRequestsResponse) =>
                        ProjectRequestActions.loadProjectRequestsSuccess({
                            projectRequests: response.payload.map((dto) =>
                                Model.createFromDto(ProjectRequest, dto)
                            ),
                        })
                    ),
                    catchError((error) =>
                        of(ProjectRequestActions.loadProjectRequestsFail({ error }))
                    )
                )
            )
        )
    );

    addProjectRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectRequestActions.addProjectRequest),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Creating a new Project Request...');
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBarService.hide()),
                    map((response: CommandResult) =>
                        ProjectRequestActions.addProjectRequestSuccess({
                            request: Model.createFromDto(ProjectRequest, response.payload),
                        })
                    ),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(ProjectRequestActions.addProjectRequestFail({ error }));
                    })
                );
            })
        )
    );

    patchProjectRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectRequestActions.patchProjectRequest),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: EntityPatchSuccessResponse) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ProjectRequestActions.patchProjectRequestSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) =>
                        of(
                            ProjectRequestActions.patchProjectRequestFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteProjectRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectRequestActions.deleteProjectRequest),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() =>
                        ProjectRequestActions.deleteProjectRequestSuccess({
                            id: action.payload.id,
                        })
                    ),
                    catchError((error) =>
                        of(
                            ProjectRequestActions.deleteProjectRequestFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private snackBarService: SnackBarService
    ) {}
}
