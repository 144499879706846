import {
    add,
    compareAsc,
    differenceInCalendarDays,
    differenceInSeconds,
    isEqual,
    isSameDay,
    isValid,
    startOfDay,
    sub,
    toDate,
} from 'date-fns';
import { CalendarBase } from './calendar.model';

export class CalendarCalculator {
    private workWeekDays: Set<Day>;
    private holidayDates: Set<number>;
    private workDates: Set<number>;

    constructor(public calendar: CalendarBase) {
        this.init();
    }

    private init() {
        this.validateWorkweek(this.calendar.workingDaysOfWeek);

        this.workWeekDays = new Set(this.calendar.workingDaysOfWeek);

        this.holidayDates = new Set();
        this.calendar.holidays?.forEach((d) => {
            const holidayStart = startOfDay(new Date(d.date));
            this.holidayDates.add(holidayStart.getTime());
        });

        this.workDates = new Set();
        this.calendar.workingDays?.forEach((d) => {
            const workdayStart = startOfDay(new Date(d.date));
            this.workDates.add(workdayStart.getTime());
        });
    }

    private validateWorkweek(workweek: number[]) {
        if (!workweek.length) {
            throw new Error(`Workweek is empty`);
        }

        const invalidDay = workweek.find((d) => d < 0 || d > 6);
        if (invalidDay != null) {
            throw new Error(`Workweek day ${invalidDay} is invalid`);
        }
    }

    /**
     * Checks whether the given date is on the work day
     */
    isWorkDay(agnosticDate: Date): boolean {
        this.validateDate(agnosticDate);

        const normalized = startOfDay(agnosticDate).getTime();
        return (
            (this.workWeekDays.has(agnosticDate.getDay() as Day) &&
                !this.holidayDates.has(normalized)) ||
            this.workDates.has(normalized)
        );
    }

    addWorkDays(date: Date, amount: number): Date {
        this.validateDate(date);

        if (amount === 0) {
            return date;
        }

        const step = amount < 0 ? -1 : 1;
        let restDays = Math.abs(amount);
        if (this.isWorkDay(date) && amount > 0) restDays--;

        let loopCounter = 0;
        const maxAllowedNonWorkDays = 30000;
        const loopProtectionLimit = restDays + maxAllowedNonWorkDays;

        const result = new Date(date);
        while (restDays) {
            result.setDate(result.getDate() + step);
            if (this.isWorkDay(result)) restDays--;

            if (++loopCounter > loopProtectionLimit) {
                throw new Error(
                    `Calculated period cannot include more than ${maxAllowedNonWorkDays} non-work days`
                );
            }
        }

        return result;
    }

    subWorkDays(date: Date, amount: number): Date {
        return this.addWorkDays(date, -amount);
    }

    /**
     * Calculates the number of work days between the given dates.
     *
     * If the smaller date is a work day, it is included in the calculated interval.
     * If the larger date is a work day, it is excluded from the calculated interval.
     *
     * @example In case of a mon-fri workweek and no holidays,
     * workDaysBetween(thisTuesday, thisFriday) === 3
     * where tuesday included, friday excluded
     *
     * workDaysBetween(thisTuesday, previousFriday) === 2
     * where previous friday included, this tuesday excluded
     *
     * @param dateA first date
     * @param dateB second date
     * @returns 0 if dates are on the same day, a positive number of work days
     * if dateA is before dateB, and a negative number otherwise.
     */
    workDaysBetween(dateA: Date, dateB: Date): number {
        [dateA, dateB].forEach(this.validateDate);
        if (Math.abs(differenceInCalendarDays(dateA, dateB)) > 366 * 10) {
            throw new Error('Periods longer than 10 years are not supported');
        }

        let [fromDate, toDate] = [startOfDay(dateA), startOfDay(dateB)].sort(compareAsc);
        let count = this.isWorkDay(fromDate) ? 1 : 0;

        if (isSameDay(fromDate, toDate)) {
            return count;
        }

        while (fromDate < toDate) {
            fromDate = this.addDurationDstAgnostic(fromDate, { days: 1 });
            if (this.isWorkDay(fromDate)) count++;
        }

        return dateA < dateB ? count : -count;
    }

    // Returns a positive number of work seconds between dateA and dateB
    // in case dateA < dateB, and a negative number otherwise.
    workSecondsBetween(dateA: Date, dateB: Date): number {
        [dateA, dateB].forEach(this.validateDate);
        const daysDiff = differenceInCalendarDays(dateB, dateA);
        const sign = daysDiff >= 0 ? 1 : -1;
        if (Math.abs(daysDiff) > 366 * 10) {
            throw new Error('Periods longer than 10 years are not supported');
        }

        const [from, to] = [dateA, dateB].sort(compareAsc);

        const sameDay = daysDiff === 0;

        if (sameDay) {
            if (!this.isWorkDay(from)) return 0;
            const workDayStart = startOfDay(from);
            const workDayEnd = this.addDurationDstAgnostic(workDayStart, {
                hours: this.calendar.workingHoursPerDay,
            });
            const startTime = Math.min(from.getTime(), workDayEnd.getTime());
            const endTime = Math.min(to.getTime(), workDayEnd.getTime());
            return sign * this.differenceInSecondsDstAgnostic(endTime, startTime);
        }

        let firstDayWorkSeconds = 0;
        if (this.isWorkDay(from)) {
            const firstDayStart = startOfDay(from);
            const firstDayEnd = this.addDurationDstAgnostic(firstDayStart, {
                hours: this.calendar.workingHoursPerDay,
            });
            const firstDayStartTime = Math.min(from.getTime(), firstDayEnd.getTime());
            firstDayWorkSeconds = this.differenceInSecondsDstAgnostic(
                firstDayEnd,
                firstDayStartTime
            );
        }

        let lastDayWorkSeconds = 0;
        if (this.isWorkDay(to)) {
            const lastDayStart = startOfDay(to);
            const lastDayEnd = this.addDurationDstAgnostic(lastDayStart, {
                hours: this.calendar.workingHoursPerDay,
            });
            const lastDayEndTime = Math.min(to.getTime(), lastDayEnd.getTime());
            lastDayWorkSeconds = this.differenceInSecondsDstAgnostic(lastDayEndTime, lastDayStart);
        }

        let workDaysBetween = 0;
        let cursor = this.addDurationDstAgnostic(from, { days: 1 });
        while (!isSameDay(cursor, to)) {
            if (this.isWorkDay(cursor)) workDaysBetween++;
            cursor = this.addDurationDstAgnostic(cursor, { days: 1 });
        }
        const workSecondsBetween = workDaysBetween * this.calendar.workingHoursPerDay * 3600;

        return sign * (firstDayWorkSeconds + workSecondsBetween + lastDayWorkSeconds);
    }

    shiftWorkSeconds(date: Date, amount: number): Date {
        return amount >= 0
            ? this.addWorkSeconds(date, amount)
            : this.subWorkSeconds(date, Math.abs(amount));
    }

    addWorkSeconds(date: Date, amount: number): Date {
        this.validateDate(date);

        if (amount === 0) {
            return date;
        }

        let currentDate = date;
        let amountLeft = amount;
        while (amountLeft > 0) {
            if (!this.isWorkDay(currentDate)) {
                currentDate = startOfDay(this.addDurationDstAgnostic(currentDate, { days: 1 }));
                continue;
            }
            const currentDayStart = startOfDay(currentDate);
            const dayEnd = this.addDurationDstAgnostic(currentDayStart, {
                hours: this.calendar.workingHoursPerDay,
            });
            const startTime = Math.min(currentDate.getTime(), dayEnd.getTime());
            const availableSeconds = this.differenceInSecondsDstAgnostic(dayEnd, startTime);
            if (availableSeconds >= amountLeft) {
                currentDate = this.addDurationDstAgnostic(currentDate, { seconds: amountLeft });
                amountLeft = 0;
            } else {
                amountLeft -= availableSeconds;
                currentDate = startOfDay(this.addDurationDstAgnostic(currentDate, { days: 1 }));
            }
        }

        return currentDate;
    }

    subWorkSeconds(date: Date, amount: number): Date {
        this.validateDate(date);

        if (amount === 0) {
            return date;
        }

        let currentDate = date;
        let amountLeft = amount;
        while (amountLeft > 0) {
            if (!this.isWorkDay(currentDate)) {
                currentDate = startOfDay(this.subDurationDstAgnostic(currentDate, { days: 1 }));
                currentDate = this.addDurationDstAgnostic(currentDate, {
                    hours: this.calendar.workingHoursPerDay,
                });
                continue;
            }
            const currentDayStart = startOfDay(currentDate);
            const dayEnd = this.addDurationDstAgnostic(currentDayStart, {
                hours: this.calendar.workingHoursPerDay,
            });
            const endTime = Math.min(currentDate.getTime(), dayEnd.getTime());
            const availableSeconds = this.differenceInSecondsDstAgnostic(endTime, currentDayStart);
            if (availableSeconds >= amountLeft) {
                currentDate = this.subDurationDstAgnostic(currentDate, { seconds: amountLeft });
                amountLeft = 0;
            } else {
                amountLeft -= availableSeconds;
                currentDate = startOfDay(this.subDurationDstAgnostic(currentDate, { days: 1 }));
                currentDate = this.addDurationDstAgnostic(currentDate, {
                    hours: this.calendar.workingHoursPerDay,
                });
            }
        }

        return currentDate;
    }

    private closestWorkDay(date: Date, direction: 'right' | 'left'): Date {
        this.validateDate(date);

        let loopCounter = 0;
        const maxAllowedNonWorkDays = 366;
        const step = 'right' ? 1 : -1;

        let cursor = date;
        while (!this.isWorkDay(cursor)) {
            cursor = this.addDurationDstAgnostic(cursor, { days: step });

            if (++loopCounter > maxAllowedNonWorkDays) {
                throw new Error(
                    `Calculated period cannot include more than ${maxAllowedNonWorkDays} non-work days`
                );
            }
        }
        return cursor;
    }

    closestWorkDayRight(date: Date): Date {
        return this.closestWorkDay(date, 'right');
    }

    closestWorkDayLeft(date: Date): Date {
        return this.closestWorkDay(date, 'left');
    }

    alignNextWorkDayIfWorkTimeEnd(date: Date): Date {
        if (!this.isWorkTimeEnd(date)) {
            return date;
        }
        const nextWorkDay = this.closestWorkDayRight(
            this.addDurationDstAgnostic(date, { days: 1 })
        );
        return this.startOfWorkDay(nextWorkDay);
    }

    alignPrevWorkDayIfWorkTimeStart(date: Date): Date {
        if (!this.isWorkTimeStart(date)) {
            return date;
        }
        const prevWorkDay = this.closestWorkDayLeft(this.subDurationDstAgnostic(date, { days: 1 }));
        return this.endOfWorkDay(prevWorkDay);
    }

    endOfWorkDay(date: Date): Date {
        this.validateDate(date);

        const start = startOfDay(date);
        return this.addDurationDstAgnostic(start, { hours: this.calendar.workingHoursPerDay });
    }

    startOfWorkDay(date: Date): Date {
        this.validateDate(date);

        return startOfDay(date);
    }

    isWorkTimeEnd(date: Date): boolean {
        return isEqual(date, this.endOfWorkDay(date));
    }

    isWorkTimeStart(date: Date): boolean {
        return isEqual(date, startOfDay(date));
    }

    private validateDate(date: Date) {
        if (!isValid(date)) {
            throw new Error(`Invalid date: ${date}`);
        }
    }

    private addDurationDstAgnostic(date: Date, duration: Duration): Date {
        const { years, months, weeks, days, hours, minutes, seconds } = duration;
        const yearToDayDuration =
            years || months || weeks || days ? { years, months, weeks, days } : null;
        const hourToSecondDuration =
            hours || minutes || seconds ? { hours, minutes, seconds } : null;

        // DST jump has no effect on such addition (when adding whole days),
        // so we don't need any additional calculations when adding yearToDayDuration.
        const withAddedWholeDays = yearToDayDuration ? add(date, yearToDayDuration) : date;

        if (!hourToSecondDuration) return withAddedWholeDays;

        const offsetBefore = withAddedWholeDays.getTimezoneOffset();
        let withAddedTheRest = add(withAddedWholeDays, hourToSecondDuration);
        const offsetAfter = withAddedTheRest.getTimezoneOffset();

        if (offsetBefore !== offsetAfter) {
            // In case a DST jump occurs during adding hours/minutes/seconds, the result
            // time will be one hour bigger/smaller than expected (the jump will be
            // reflected in the timezone change though). Since we ignore timezones
            // in the calendar calculations, we have to adjust the result time as if
            // there's was no DST jump.
            const adjustedByDstJump = add(withAddedTheRest, {
                minutes: offsetAfter - offsetBefore,
            });
            return adjustedByDstJump;
        }

        return withAddedTheRest;
    }

    private subDurationDstAgnostic(date: Date, duration: Duration): Date {
        const offsetBefore = date.getTimezoneOffset();
        let newDate = sub(date, duration);
        const offsetAfter = newDate.getTimezoneOffset();
        if (offsetBefore !== offsetAfter) {
            newDate = sub(newDate, { minutes: offsetAfter - offsetBefore });
        }
        return newDate;
    }

    private differenceInSecondsDstAgnostic(left: Date | number, right: Date | number): number {
        const dateLeft = toDate(left);
        const offsetLeft = dateLeft.getTimezoneOffset();

        const dateRight = toDate(right);
        const offsetRight = dateRight.getTimezoneOffset();

        const difference = differenceInSeconds(dateLeft, dateRight);

        if (offsetLeft === offsetRight) {
            return difference;
        }

        return difference + (offsetRight - offsetLeft) * 60;
    }
}
