import { createFeatureSelector, createSelector } from '@ngrx/store';
import { generateUUID } from 'app/core/store/id-generator';
import { selectCurrentSiteProgramsOverview } from '../shared/program-overview.selectors';
import { selectCurrentSiteProjectsOverview } from '../shared/project-overview.selectors';
import { selectCurrentSiteId } from '../site/site.selectors';
import { StateEntry } from '../state-entry';
import { adapter, LoadingStatus, State } from './executive-dashboard.store';
import { ExecutiveDashboard } from './models';

export const selectTimesheetEntry = createFeatureSelector<State>(StateEntry.ExecutiveDashboard);

export const selectLoadingStatus = createSelector(
    selectTimesheetEntry,
    (state): LoadingStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
    })
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectTimesheetEntry);

export const selectCurrentSiteExecutiveDashboard = createSelector(
    selectCurrentSiteProjectsOverview,
    selectCurrentSiteProgramsOverview,
    selectCurrentSiteId,
    (projectsOverview, programsOverview, siteId) => {
        const dashboard: ExecutiveDashboard = {
            id: generateUUID(),
            siteId,
            projectsOverview,
            programsOverview,
        };
        return dashboard;
    }
);
