import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CalendarCalculator } from 'app/core/models/calendar/calendar-calculator';
import { EntityPatchSuccessResponse } from 'app/core/services/rest-api/features/patch.response';
import { GetScheduleTasksBySiteIdResponse } from 'app/core/services/rest-api/features/schedule-task/schedule-task.responses';
import { Schedule } from 'app/services/schedule';
import { getPatch } from 'app/utils/json-patch';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import {
    catchError,
    filter,
    first,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { RestApiService } from '../../services/rest-api';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import * as AppStore from '../store';
import {
    getScheduleTaskPatchedFromDiffs,
    getTaskInit,
    ScheduleTask,
    scheduleTaskPatchableProperties,
} from './models';
import * as ScheduleTaskActions from './schedule-task.actions';
import { PatchScheduleTaskPayload } from './schedule-task.payloads';
import * as ScheduleTaskSelectors from './schedule-task.selectors';

@Injectable()
export class ScheduleTaskTemplateEffects {
    loadScheduleTasksByContainerId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ScheduleTaskActions.loadScheduleTasksByContainerId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        ScheduleTaskActions.loadScheduleTasksByContainerIdSuccess({
                            tasks: response.payload.map((dto) =>
                                Model.createFromDto(ScheduleTask, dto)
                            ),
                            containerId: action.payload.id,
                        })
                    ),
                    catchError((error) =>
                        of(
                            ScheduleTaskActions.loadScheduleTasksByContainerIdFail({
                                error,
                                containerId: action.payload.id,
                            })
                        )
                    )
                )
            )
        )
    );

    loadScheduleTasksBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ScheduleTaskActions.loadScheduleTasksBySiteId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    switchMap((response: GetScheduleTasksBySiteIdResponse) => [
                        ScheduleTaskActions.loadScheduleTasksBySiteIdSuccess({
                            tasks: response.payload.tasks.map((dto) =>
                                Model.createFromDto(ScheduleTask, dto)
                            ),
                            siteId: action.payload.siteId,
                            listContainerIds: response.payload.listContainers.map((c) => c.id),
                        }),
                    ]),
                    catchError((error) =>
                        of(
                            ScheduleTaskActions.loadScheduleTasksBySiteIdFail({
                                error,
                                siteId: action.payload.siteId,
                            })
                        )
                    )
                )
            )
        )
    );

    addScheduleTask$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ScheduleTaskActions.addScheduleTask),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        return ScheduleTaskActions.addScheduleTaskSuccess({
                            task: Model.createFromDto(ScheduleTask, response.payload),
                            options: action.options,
                        });
                    }),
                    catchError((error) =>
                        of(
                            ScheduleTaskActions.addScheduleTaskFail({
                                error,
                                originAction: action,
                                taskId: action.payload.id,
                            })
                        )
                    )
                )
            )
        )
    );

    updateScheduleTask$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ScheduleTaskActions.updateScheduleTasks),
            withLatestFrom(this.store$.select(ScheduleTaskSelectors.selectAll)),
            mergeMap(([action, tasks]) => {
                return of(
                    ScheduleTaskActions.patchScheduleTasks({
                        payload: action.payload.map((p) => {
                            const task = tasks.find((t) => t.id === p.id);
                            return { id: p.id, patch: getPatch(task, p.changes) };
                        }),
                        options: action.options,
                    })
                );
            })
        )
    );

    updateScheduleTaskWithCalculation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ScheduleTaskActions.updateScheduleTaskWithCalculation),
            withLatestFrom(this.store$.select(ScheduleTaskSelectors.selectAll)),
            mergeMap(([action, tasks]) => {
                const task: ScheduleTask = tasks.find(
                    (t) => t.id === action.payload.taskPatch.taskId
                );
                const siblingTasks = tasks.filter(
                    (t) => t.listContainerId === task.listContainerId
                );
                const calendarCalculator = new CalendarCalculator(action.payload.calendar);
                const schedule = new Schedule(
                    siblingTasks.map((t) => getTaskInit(t)),
                    calendarCalculator
                );

                const changeRes = schedule.change([action.payload.taskPatch]);
                if (changeRes.duplicateDependencyFound || changeRes.invalidDependencyFound) {
                    console.error('The change was failed (duplicate or invalid dependency found)');
                    return of(okEmptyAction());
                }
                const diffs = schedule.getChanges();
                const patchPayloads = getScheduleTaskPatchedFromDiffs(siblingTasks, diffs);
                const payload: PatchScheduleTaskPayload[] = patchPayloads
                    .filter((p) => p.patch?.length)
                    .map((payload) => ({ id: payload.id, patch: payload.patch }));
                return of(
                    ScheduleTaskActions.patchScheduleTasks({
                        payload,
                        options: action.options,
                    })
                );
            })
        )
    );

    patchScheduleTask$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ScheduleTaskActions.patchScheduleTasks),
            filter((action) => !!action.payload.length),
            map((action) => {
                return {
                    ...action,
                    payload: action.payload.map((p) => ({
                        ...p,
                        patch: p.patch.filter((op) =>
                            scheduleTaskPatchableProperties.some((prop) => op.path.includes(prop))
                        ),
                    })),
                };
            }),
            mergeMap((action) =>
                this.store$
                    .select(
                        ScheduleTaskSelectors.selectManyEntitiesStoredInDb(
                            action.payload.map((p) => p.id)
                        )
                    )
                    .pipe(
                        first((stored) => stored),
                        switchMap(() =>
                            this.restApiService.process(action).pipe(
                                map((response: EntityPatchSuccessResponse) => {
                                    if (action.options.optimistic) return okEmptyAction();
                                    return ScheduleTaskActions.patchScheduleTasksSuccess({
                                        payload: action.payload,
                                    });
                                }),
                                catchError((error) =>
                                    of(
                                        ScheduleTaskActions.patchScheduleTasksFail({
                                            error,
                                            originAction: action,
                                        })
                                    )
                                )
                            )
                        )
                    )
            )
        )
    );

    deleteScheduleTask$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ScheduleTaskActions.deleteScheduleTask),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options.optimistic) return okEmptyAction();
                        return ScheduleTaskActions.deleteScheduleTaskSuccess({
                            id: action.payload.id,
                        });
                    }),
                    catchError((error) =>
                        of(
                            ScheduleTaskActions.deleteScheduleTaskFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>
    ) {}
}
