import { Action, createAction, props } from '@ngrx/store';
import { LoadBySiteIdPayload } from '../shared/models/load.payload';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import {
    AddCommentPayload,
    CommentIdPayload,
    PatchCommentPayload,
    UpdateCommentPayload,
} from './comment.payloads';
import { CtxComment } from './models';

// Load Comment actions

export const loadCommentsBySiteId = createAction(
    '[Comment] Load Comments',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadCommentsBySiteIdSuccess = createAction(
    '[Comment] Load Comments success',
    props<{ comments: CtxComment[] }>()
);

export const loadCommentsBySiteIdFail = createAction(
    '[Comment] Load Comments fail',
    props<{ error: any }>()
);

// Add Comment actions

export const addComment = createAction('[Comment] Add', props<{ payload: AddCommentPayload }>());

export const addCommentSuccess = createAction(
    '[Comment] Add success',
    props<{ comment: CtxComment }>()
);

export const addCommentFail = createAction('[Comment] Add fail', props<{ error: any }>());

// Update Comment actions

export const updateComment = createAction(
    '[Comment] Update',
    props<{ payload: UpdateCommentPayload; options?: OptimisticableActionOptions }>()
);

export const patchComment = createAction(
    '[Comment] Patch',
    props<{ payload: PatchCommentPayload; options?: OptimisticableActionOptions }>()
);

export const patchCommentSuccess = createAction(
    '[Comment] Patch success',
    props<{ comment: CtxComment }>()
);

export const patchCommentFail = createAction(
    '[Comment] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Delete Comment actions

export const deleteComment = createAction(
    '[Comment] Delete',
    props<{ payload: CommentIdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteCommentSuccess = createAction(
    '[Comment] Delete success',
    props<CommentIdPayload>()
);

export const deleteCommentFail = createAction(
    '[Comment] Delete fail',
    props<{ error: any; originAction: Action }>()
);
