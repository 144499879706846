import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import {
    LoadByProjectIdPayload,
    LoadBySiteIdPayload,
} from '../../../../store/shared/models/load.payload';
import { PatchKanbanBoardPayload } from './../../../../store/kanban/kanban.payloads';

export const loadKanbanBoardsByProjectIdCommand = (
    payload: LoadByProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projects', value: payload.projectId },
        { name: 'kanbanBoards' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadKanbanBoardsBySiteIdCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'kanbanBoards/sites', value: payload.siteId }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const patchKanbanBoardCommand = (
    payload: PatchKanbanBoardPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'kanbanBoards', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};
