import { createAction, props } from '@ngrx/store';
import { ApprovalRequest } from '.';
import { IdPayload } from '../shared/models/shared-payloads';
import { SiteIdPayload } from '../site/site.payloads';
import { ProcessApprovalsPayload, UpdateApprovalStatePayload } from './approval-request.payloads';

// Get Approval Requests actions

export const loadApprovalRequests = createAction(
    '[ApprovalRequest] Load',
    props<{ payload: SiteIdPayload }>()
);

export const loadApprovalRequestsSuccess = createAction(
    '[ApprovalRequest] Load success',
    props<{ requests: ApprovalRequest[] }>()
);

export const loadApprovalRequestsFail = createAction(
    '[ApprovalRequest] Load fail',
    props<{ error: any }>()
);

// Manager approval actions

export const processApprovals = createAction(
    '[ApprovalRequest] Process Approvals',
    props<ProcessApprovalsPayload>()
);

// Update approval state action

export const updateApprovalState = createAction(
    '[ApprovalRequest] Process Approval success',
    props<UpdateApprovalStatePayload>()
);

// Add approval action

export const addPendingApproval = createAction(
    '[ApprovalRequest] Add pending',
    props<{ approval: ApprovalRequest }>()
);

export const addApproval = createAction(
    '[ApprovalRequest] Add',
    props<{ approval: ApprovalRequest }>()
);

// Delete approval action

export const deletePendingApproval = createAction('[ApprovalRequest] Delete', props<IdPayload>());

export const deleteApprovalByProjectId = createAction(
    '[ApprovalRequest] Delete by Project id',
    props<IdPayload>()
);
