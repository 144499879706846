import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProgramSelectors } from '../program';
import { StateEntry } from '../state-entry';
import { adapter, LoadingStatus, State } from './program-member.store';

export const selectProgramMemberEntry = createFeatureSelector<State>(StateEntry.ProgramMember);

export const selectLoadingStatus = createSelector(
    selectProgramMemberEntry,
    (state): LoadingStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
    })
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectProgramMemberEntry);

export const selectAllLoaded = createSelector(selectAll, selectLoadingStatus, (members, status) =>
    status?.loaded ? members : null
);

export const selectProgramMemberById = (id: string) =>
    createSelector(selectAll, (members) => members.find((r) => r.id === id));

export const selectCurrentProgramMembers = createSelector(
    selectAll,
    ProgramSelectors.selectCurrentProgramId,
    (members, programId) => members.filter((m) => m.programId === programId)
);

export const selectNotCurrentProgramMembers = createSelector(
    selectAll,
    ProgramSelectors.selectCurrentProgramId,
    (members, programId) => members.filter((m) => m.programId !== programId)
);
