import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ctx-svg-definitions',
    templateUrl: './svg-definitions.component.html',
})
export class SvgDefinitionsComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
