import { format } from 'date-fns';
import { Model } from '../../shared/models/base.model';

export class UserLog extends Model {
    id: string;
    siteId: string;
    originLevelObjectId: string;
    originLevelObjectName: string;
    level: UserLogLevel;
    operation: UserLogOperation;
    objectType: UserLogObjectType;
    objectName: string;
    date: Date;
    objectId: string;
    userName: string;
    userEmail: string;

    constructor(init?: Partial<UserLog>) {
        super();
        Object.assign(this, init);
    }
}

export enum UserLogLevel {
    Site = 'Site',
    Program = 'Program',
    Project = 'Project',
}

export enum UserLogOperation {
    Created = 'Created',
    Updated = 'Updated',
    Deleted = 'Deleted',
}

export enum UserLogObjectType {
    Site = 'Site',
    Program = 'Program',
    Project = 'Project',
    SiteMember = 'SiteMember',
    ProjectMember = 'ProjectMember',
    ProjectStatusReport = 'ProjectStatusReport',
    ProjectPhase = 'ProjectPhase',
    ProjectPhaseContainer = 'ProjectPhaseContainer',
    ProjectBudget = 'ProjectBudget',
    ProjectSchedule = 'ProjectSchedule',
    Risk = 'Risk',
    Action = 'Action',
    Issue = 'Issue',
    Decision = 'Decision',
    Sprint = 'Sprint',
    BacklogTask = 'BacklogTask',
    ChangeRequest = 'ChangeRequest',
    Minute = 'Minute',
    Document = 'Document',
    ProgramMember = 'ProgramMember',
    Timesheet = 'Timesheet',
    ProjectRequest = 'ProjectRequest',
    ProjectSettings = 'ProjectSettings',
    Subscription = 'Subscription',
    SiteSettings = 'SiteSettings',
    ProjectField = 'ProjectField',
    ProjectBudgetTemplate = 'ProjectBudgetTemplate',
    CommuniationTable = 'CommuniationTable',
    ProjectTemplate = 'ProjectTemplate',
}

export function getUserLogTitle(log: UserLog): string {
    const objectTypeName = log.objectType.split(/(?=[A-Z])/).join(' ');

    return `${format(new Date(log.date), 'MMM dd, yyyy')} -
    ${objectTypeName} "${log.objectName}" ${log.operation}`;
}

export function getSiteUserLogTitle(log: UserLog): string {
    const objectTypeName = log.objectType.split(/(?=[A-Z])/).join(' ');

    return `${format(new Date(log.date), 'MMM dd, yyyy')} -
    ${objectTypeName} "${log.objectName}" ${log.operation} ${
        log.level != UserLogLevel.Site &&
        log.objectType !== UserLogObjectType.Project &&
        log.objectType !== UserLogObjectType.Program
            ? 'in ' + log.level.toLowerCase() + ' ' + log.originLevelObjectName
            : ''
    }`;
}
