import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ProjectRequest } from './models';

export interface LoadingStatus {
    serverRequestInProgress: boolean;
    loaded: boolean;
}

export interface State extends EntityState<ProjectRequest>, LoadingStatus {
    expandedPrIds: string[];
}

export const adapter: EntityAdapter<ProjectRequest> = createEntityAdapter<ProjectRequest>();

export const initialState: State = adapter.getInitialState({
    serverRequestInProgress: false,
    loaded: false,
    expandedPrIds: [],
});
