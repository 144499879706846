import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SiteSelectors } from '../site';
import { StateEntry } from '../state-entry';
import { ProjectFieldType } from './models';
import { adapter, LoadingStatus, State } from './project-field.store';

export const selectProjectFieldEntry = createFeatureSelector<State>(StateEntry.ProjectField);

export const selectLoadingStatus = createSelector(
    selectProjectFieldEntry,
    (state): LoadingStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
    })
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectProjectFieldEntry);

export const selectCurrentSiteTemplateFields = createSelector(
    selectAll,
    SiteSelectors.selectCurrentSiteId,
    (fields, siteId) => fields.filter((field) => field.siteId === siteId)
);

export const selectCurrentSiteProjectFields = createSelector(
    selectAll,
    SiteSelectors.selectCurrentSiteId,
    (fields, siteId) => fields.filter((field) => field.siteId === siteId)
);

export const selectCurrentSiteProjectFieldByType = (type: ProjectFieldType) =>
    createSelector(selectCurrentSiteProjectFields, (fields) =>
        fields.find((field) => field.type === type)
    );

export const selectCurrentSiteProjectFieldItemsByType = (type: ProjectFieldType) =>
    createSelector(selectCurrentSiteProjectFieldByType(type), (field) => field?.items);

export const selectProjectFieldsByType = (type: ProjectFieldType) =>
    createSelector(selectAll, (fields) => fields.filter((f) => f.type === type));

export const selectLoadingStatusesPerSettingsTemplateId = createSelector(
    selectProjectFieldEntry,
    (state) => state.loadingStatusBySettingsTemplateId
);

export const selectProjectFieldsByCompletixSettingsTemplateId = (id: string) =>
    createSelector(selectAll, (fields) =>
        fields.filter((f) => f.completixSettingsTemplateId === id)
    );
