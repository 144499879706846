import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Communication } from '.';

export interface LoadingStatus {
    serverRequestInProgress: boolean;
    loaded: boolean;
}

export interface State extends EntityState<Communication>, LoadingStatus {
    completixCommunicationsLoading: boolean;
}

export const adapter: EntityAdapter<Communication> = createEntityAdapter<Communication>();

export const initialState: State = adapter.getInitialState({
    serverRequestInProgress: false,
    loaded: false,
    completixCommunicationsLoading: false,
});
