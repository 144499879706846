import { createReducer, on } from '@ngrx/store';
import { BillingDetails, PaymentInfo, Subscription } from 'app/core/models/billing';
import { getPatched } from 'app/utils/json-patch';
import * as SubscriptionActions from './subscription.actions';
import { adapter, initialState } from './subscription.store';

export const reducer = createReducer(
    initialState,
    on(SubscriptionActions.getActiveSubscription, (state) => state),

    on(SubscriptionActions.getActiveSubscriptionSuccess, (state, payload) => {
        return {
            ...adapter.addOne(
                payload.subscription,
                adapter.removeOne(payload.subscription.id, state)
            ),
        };
    }),

    on(SubscriptionActions.getActiveSubscriptionFail, (state) => ({
        ...state,
    })),

    on(SubscriptionActions.changeSubscription, (state) => ({
        ...state,
        isSubscriptionSucceeded: false,
    })),

    on(SubscriptionActions.changeSubscriptionSuccess, (state, payload) => {
        const siteId = payload.subscription.siteId;
        const newState = adapter.removeMany(
            (state.ids as string[]).filter((id) => state.entities[id].siteId === siteId),
            state
        );

        return {
            ...adapter.addOne(payload.subscription, newState),
            subscriptionError: null,
            isSubscriptionSucceeded: true,
        };
    }),

    on(SubscriptionActions.cancelSubscriptionSuccess, (state, payload) => {
        const siteId = payload.subscription.siteId;
        const newState = adapter.removeMany(
            (state.ids as string[]).filter((id) => state.entities[id].siteId === siteId),
            state
        );

        return {
            ...adapter.addOne(payload.subscription, newState),
            subscriptionError: null,
        };
    }),

    on(SubscriptionActions.changeSubscriptionFail, (state, payload) => ({
        ...state,
        subscriptionError: payload.error,
        isSubscriptionSucceeded: false,
    })),

    on(SubscriptionActions.patchBillingDetails, (state, action) => {
        if (!action.options.optimistic) return state;

        const subscription = state.entities[action.payload.subscriptionId];
        const paymentInfo = subscription && subscription.paymentInfo;
        const billingDetails = paymentInfo && paymentInfo.billingDetails;
        if (!billingDetails) return state;

        const patchedDetails = getPatched(billingDetails, action.payload.patch);

        const updatedSubscription = new Subscription({
            ...subscription,
            paymentInfo: new PaymentInfo({
                ...paymentInfo,
                billingDetails: new BillingDetails(patchedDetails),
            }),
        });

        return adapter.addOne(
            updatedSubscription,
            adapter.removeOne(action.payload.subscriptionId, state)
        );
    }),

    on(SubscriptionActions.patchBillingDetailsSuccess, (state, payload) =>
        adapter.addOne(payload.subscription, adapter.removeOne(payload.subscription.id, state))
    ),

    on(SubscriptionActions.finishSubscription, (state) => ({
        ...state,
        isSubscriptionSucceeded: null,
    })),

    on(SubscriptionActions.changeSubscriptionQuantitySuccess, (state, payload) =>
        adapter.addOne(payload.subscription, adapter.removeOne(payload.subscription.id, state))
    ),

    on(SubscriptionActions.addAdditionalSubscriptionsSuccess, (state, payload) =>
        adapter.addOne(payload.subscription, adapter.removeOne(payload.subscription.id, state))
    ),

    on(SubscriptionActions.removeAdditionalSubscriptionSuccess, (state, payload) =>
        adapter.addOne(payload.subscription, adapter.removeOne(payload.subscription.id, state))
    )
);
