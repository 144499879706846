import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectSelectors } from '../project';
import { SiteSelectors } from '../site';
import { StateEntry } from '../state-entry';
import { adapter, LoadingStatus, State } from './budget-table.store';

export const selectBudgetTemplateEntry = createFeatureSelector<State>(StateEntry.BudgetTemplate);

export const selectLoadingStatus = createSelector(
    selectBudgetTemplateEntry,
    (state): LoadingStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
        projectBudgetLoading: state.projectBudgetLoading,
        completixTemplatesLoading: state.completixTemplatesLoading,
    })
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectBudgetTemplateEntry);

export const selectCurrentSiteBudgetTemplates = createSelector(
    selectAll,
    SiteSelectors.selectCurrentSiteId,
    (tables, siteId) => {
        return tables.filter((t) => t.siteId === siteId && t.isTemplate);
    }
);

export const selectCurrentProjectBudgetTable = createSelector(
    selectAll,
    ProjectSelectors.selectCurrentProjectId,
    (tables, projectId) => tables.find((t) => t.projectId === projectId)
);

export const selectBudgetTableById = (id: string) =>
    createSelector(selectAll, (tables) => tables.find((t) => t.id === id));

export const selectBudgetCompletixTemplates = createSelector(selectAll, (tables) =>
    tables.filter((t) => t.isTemplate && t.isCompletixTemplate)
);
