import { Action, createAction, props } from '@ngrx/store';
import { ImageModel } from '../shared/models';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import { AccountModel, SystemAdminData, UserAppMessage } from './account.model';
import {
    AccountIdPayload,
    GetAccountSettingPayload,
    PatchSystemAdminDataPayload,
    UpdateAccountImagePayload,
    UpdateAccountPayload,
    UpdateAccountSettingPayload,
    UpdateSystemAdminDataPayload,
} from './account.payloads';

export const registerAccount = createAction('[Accounts] Register');

// Get my account actions

export const getMyAccount = createAction('[Accounts] Get my account', (forceRefresh = false) => ({
    forceRefresh,
}));

export const getMyAccountSuccess = createAction(
    '[Accounts] Get my account success',
    props<{ account: AccountModel }>()
);

export const getMyAccountFail = createAction(
    '[Accounts] Get my account fail',
    props<{ error: any }>()
);

// Get account by ID actions

export const getAccount = createAction('[Accounts] Get by id', props<AccountIdPayload>());

export const getAccountSuccess = createAction(
    '[Accounts] Get by id success',
    props<{ account: AccountModel }>()
);

export const getAccountFail = createAction('[Accounts] Get by id fail', props<{ error: any }>());

// Update account actions

export const updateAccount = createAction(
    '[Accounts] Update',
    props<{ payload: UpdateAccountPayload; options?: OptimisticableActionOptions }>()
);

export const updateAccountSuccess = createAction(
    '[Accounts] Update success',
    props<UpdateAccountPayload>()
);

export const updateAccountFail = createAction(
    '[Accounts] Update fail',
    props<{ error: any; originAction: Action }>()
);

// Delete account actions

export const deleteAccount = createAction('[Accounts] Delete', props<AccountIdPayload>());

export const deleteAccountSuccess = createAction(
    '[Accounts] Delete success',
    props<AccountIdPayload>()
);

export const deleteAccountFail = createAction('[Accounts] Delete fail', props<{ error: any }>());

// Get account image actions

export const getAccountImage = createAction(
    '[Accounts] Get image',
    props<{ payload: AccountIdPayload }>()
);

export const getAccountImageSuccess = createAction(
    '[Accounts] Get image success',
    props<{ image: ImageModel }>()
);

export const getAccountImageFail = createAction(
    '[Accounts] Get image fail',
    props<{ error: any }>()
);

// Get system admin data

export const getSystemAdminData = createAction('[Accounts] Get system admin data');

export const getSystemAdminDataSuccess = createAction(
    '[Accounts] Get system admin data success',
    props<{ data: SystemAdminData }>()
);

export const getSystemAdminDataFail = createAction(
    '[Accounts] Get system admin data fail',
    props<{ error: any }>()
);

// Get app message

export const getUserAppMessage = createAction('[Accounts] Get app message');

export const getUserAppMessageSuccess = createAction(
    '[Accounts] Get app message success',
    props<{ message: UserAppMessage }>()
);

export const getUserAppMessageFail = createAction(
    '[Accounts] Get app message fail',
    props<{ error: any }>()
);

// Update system admin data

export const updateSystemAdminData = createAction(
    '[Accounts] Update system admin data',
    props<{ payload: UpdateSystemAdminDataPayload; options?: OptimisticableActionOptions }>()
);

export const patchSystemAdminData = createAction(
    '[Accounts] Patch system admin data',
    props<{ payload: PatchSystemAdminDataPayload; options?: OptimisticableActionOptions }>()
);

export const patchSystemAdminDataSuccess = createAction(
    '[Accounts] Update system admin data success',
    props<{ data: SystemAdminData }>()
);

export const patchSystemAdminDataFail = createAction(
    '[Accounts] Update system admin data fail',
    props<{ error: any; originAction: Action }>()
);

// Update account image actions

export const updateAccountImage = createAction(
    '[Accounts] Update image',
    props<{ payload: UpdateAccountImagePayload }>()
);

export const updateAccountImageSuccess = createAction(
    '[Accounts] Update image success',
    props<{ image: ImageModel }>()
);

export const updateAccountImageFail = createAction(
    '[Accounts] Update image fail',
    props<{ error: any }>()
);

// Get setting actions

export const getAccountSetting = createAction(
    '[Accounts] Get setting',
    props<GetAccountSettingPayload>()
);

export const getAccountSettingSuccess = createAction(
    '[Accounts] Get setting success',
    props<{ settingId: number; value: string }>()
);

export const getAccountSettingFail = createAction(
    '[Accounts] Get setting fail',
    props<{ error: any }>()
);

// Update setting actions

export const updateAccountSetting = createAction(
    '[Accounts] Update setting',
    props<UpdateAccountSettingPayload>()
);

export const updateAccountSettingSuccess = createAction(
    '[Accounts] Update setting success',
    props<{ settingId: number; value: string }>()
);

export const updateAccountSettingFail = createAction(
    '[Accounts] Update setting fail',
    props<{ error: any }>()
);
