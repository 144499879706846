import { Action, createAction, props } from '@ngrx/store';
import { LoadByProgramIdPayload, LoadBySiteIdPayload } from '../shared/models/load.payload';
import { IdPayload } from '../shared/models/shared-payloads';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import { ErrorPayload } from '../shared/payloads';
import { ProgramStatusReport } from './models/program-status-report.model';
import {
    AddProgramStatusReportPayload,
    PatchProgramStatusReportPayload,
    UpdateProgramStatusReportPayload,
} from './program-status-report.payloads';

// Get Program Statuses actions

export const loadProgramStatuses = createAction(
    '[ProgramStatus] Load',
    props<{ payload: LoadByProgramIdPayload }>()
);

export const loadProgramStatusesSuccess = createAction(
    '[ProgramStatus] Load success',
    props<{ statuses: ProgramStatusReport[]; programId: string }>()
);

export const loadProgramStatusesFail = createAction(
    '[ProgramStatus] Load fail',
    props<{ error: any; programId: string }>()
);

export const loadProgramStatusesBySiteId = createAction(
    '[ProgramStatus] Load by Site id',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadProgramStatusesBySiteIdSuccess = createAction(
    '[ProgramStatus] Load by Site id success',
    props<{ statuses: ProgramStatusReport[]; siteId: string; programIds: string[] }>()
);

export const loadProgramStatusesBySiteIdFail = createAction(
    '[ProgramStatus] Load by Site id fail',
    props<{ error: any; siteId: string }>()
);

// Add Program Status actions

export const addProgramStatus = createAction(
    '[ProgramStatus] Add',
    props<{ payload: AddProgramStatusReportPayload; hideSnackbar?: boolean }>()
);

export const addProgramStatusSuccess = createAction(
    '[ProgramStatus] Add success',
    props<{ report: ProgramStatusReport }>()
);

export const addProgramStatusFail = createAction(
    '[ProgramStatus] Add fail',
    props<{ error: any }>()
);

// Update Program Status actions

export const updateProgramStatus = createAction(
    '[ProgramStatus] Update',
    props<{ payload: UpdateProgramStatusReportPayload; options?: OptimisticableActionOptions }>()
);

export const patchProgramStatus = createAction(
    '[ProgramStatus] Patch',
    props<{ payload: PatchProgramStatusReportPayload; options?: OptimisticableActionOptions }>()
);

export const patchProgramStatusSuccess = createAction(
    '[ProgramStatus] patch success',
    props<PatchProgramStatusReportPayload>()
);

export const patchProgramStatusFail = createAction(
    '[ProgramStatus] patch fail',
    props<{ payload: ErrorPayload; originAction: Action }>()
);

// Delete Program Status actions

export const deleteProgramStatus = createAction(
    '[ProgramStatus] Delete',
    props<{ payload: IdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteProgramStatusSuccess = createAction(
    '[ProgramStatus] Delete success',
    props<IdPayload>()
);

export const deleteProgramStatusFail = createAction(
    '[ProgramStatus] Delete fail',
    props<{ payload: ErrorPayload; originAction: Action }>()
);
