import { CommandPayload } from './base.command.payload';

export class SignalRPayload extends CommandPayload {
    private _payloads: SignalRPayload[] = [];

    constructor(payload?: any) {
        super(payload || {});
    }

    concat(payload: SignalRPayload): this {
        this._payloads.push(payload);
        return this;
    }

    serialize(): string | Blob | ArrayBuffer | any[] {
        if (!this._payloads.length) return this._data;
        return [this._data].concat(this._payloads.map((c) => c.serialize()));
    }

    setData(data: any) {
        this._data = data;
    }

    appendPair(key: any, value: any) {
        this._data[key.toString()] = value;
    }

    get mimeType(): string {
        return 'application/json-patch+json';
    }
}
