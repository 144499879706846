import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import { ProjectTemplate } from './models';
import * as ProjectTemplateActions from './project-template.actions';
import { adapter, initialState } from './project-template.store';

export const reducer = createReducer(
    initialState,

    on(ProjectTemplateActions.loadCompletixProjectTemplates, (state) => ({
        ...state,
        completixTemplatesLoading: true,
    })),

    on(ProjectTemplateActions.loadCompletixProjectTemplatesSuccess, (state, payload) => ({
        ...adapter.addMany(
            payload.templates,
            adapter.removeMany(
                payload.templates.map((t) => t.id),
                state
            )
        ),
        completixTemplatesLoading: false,
    })),

    on(ProjectTemplateActions.loadCompletixProjectTemplatesFail, (state) => ({
        ...state,
        completixTemplatesLoading: false,
    })),

    on(ProjectTemplateActions.loadCompletixProjectTemplatesBySiteId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(ProjectTemplateActions.loadProjectTemplates, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(ProjectTemplateActions.loadProjectTemplatesSuccess, (state, payload) => {
        const newState = adapter.addMany(
            payload.templates,
            adapter.removeMany(
                payload.templates.map((t) => t.id),
                state
            )
        );
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(ProjectTemplateActions.loadProjectTemplatesFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(ProjectTemplateActions.addProjectTemplateSuccess, (state, payload) =>
        adapter.addOne(payload.template, state)
    ),

    on(ProjectTemplateActions.patchProjectTemplate, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patched = getPatched(state.entities[action.payload.id], action.payload.patch);
        const update: Update<ProjectTemplate> = {
            id: patched.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(ProjectTemplateActions.patchProjectTemplateSuccess, (state, payload) => {
        const patched = getPatched(state.entities[payload.id], payload.patch);
        const update: Update<ProjectTemplate> = {
            id: patched.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(ProjectTemplateActions.patchProjectTemplateFail, (state) => state),

    on(ProjectTemplateActions.deleteProjectTemplate, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(ProjectTemplateActions.deleteProjectTemplateSuccess, (state, payload) =>
        adapter.removeOne(payload.id, state)
    )
);
