import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StateEntry } from '../state-entry';
import { Reminder, ReminderLink } from './models';
import { adapter, LoadingStatus, State } from './reminder.store';

export const selectReminderEntry = createFeatureSelector<State>(StateEntry.Reminder);

export const selectLoadingStatus = createSelector(
    selectReminderEntry,
    (state): LoadingStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
    })
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectReminderEntry);

export const selectRemindersByLink = (link: ReminderLink) =>
    createSelector(selectAll, (reminders) => reminders.filter((r) => r.link === link));

export const selectObjectIdToReminder = (link: ReminderLink) =>
    createSelector(selectRemindersByLink(link), (reminders) => {
        const map = new Map<string, Reminder>();
        reminders.forEach((r) => {
            if (r.originObjectId) map.set(r.originObjectId, r);
        });
        return map;
    });
