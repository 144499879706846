import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import { AddSprintPayload, PatchSprintPayload } from '../../../../store/sprint/sprint.payloads';
import { LoadByProjectIdPayload } from './../../../../store/shared/models/load.payload';
import { IdPayload } from './../../../../store/shared/models/shared-payloads';

export const loadSprintsByProjectId = (
    payload: LoadByProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projects', value: payload.projectId }, { name: 'sprints' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addSprintCommand = (payload: AddSprintPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'sprints' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchSprintCommand = (payload: PatchSprintPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'sprints', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteSprintCommand = (payload: IdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'sprints', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};
