import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import { LoadBySiteIdPayload } from '../../../../store/shared/models/load.payload';

export const loadApprovalsBySiteIdCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'approvals' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};
