import { Action } from '@ngrx/store';

export enum ActionTypes {
    SetInit = '[App operation] Set init',
    SetReady = '[App operation] Set ready',
    SetNotReady = '[App operation] Set not ready',
    SetApiServerError = '[App operation] Set API server error',
    ClearApiServerError = '[App operation] Clear API server error',
    UserNotAuthorizedResponseReceived = '[App operation] User not authorized response received',
    SetLocalStorage = '[App operation] Set local storage',
    SetAuthenticationRedirectUrl = '[App operation] Set Authentication Redirect Url',
    LogOut = '[App operation] Log out',
}

export class SetInit implements Action {
    readonly type = ActionTypes.SetInit;
}

export class SetReady implements Action {
    readonly type = ActionTypes.SetReady;
}

export class SetNotReady implements Action {
    readonly type = ActionTypes.SetNotReady;
}

export class SetApiServerError implements Action {
    readonly type = ActionTypes.SetApiServerError;
    constructor(public payload: { error: any }) {}
}

export class ClearApiServerError implements Action {
    readonly type = ActionTypes.ClearApiServerError;
}

export class UserNotAuthorizedResponseReceived implements Action {
    readonly type = ActionTypes.UserNotAuthorizedResponseReceived;
}

export class SetLocalStorage implements Action {
    readonly type = ActionTypes.SetLocalStorage;
}

export class SetAuthenticationRedirectUrl implements Action {
    readonly type = ActionTypes.SetAuthenticationRedirectUrl;
    constructor(public payload: { url: string }) {}
}

export class LogOut implements Action {
    readonly type = ActionTypes.LogOut;
}

export type UnionAction =
    | SetInit
    | SetReady
    | SetNotReady
    | SetApiServerError
    | ClearApiServerError
    | UserNotAuthorizedResponseReceived
    | SetLocalStorage
    | SetAuthenticationRedirectUrl
    | LogOut;
