import { SendNotificationPayload } from 'app/core/store/notification';
import { IdPayload } from 'app/core/store/shared/models/shared-payloads';
import { SiteIdPayload } from 'app/core/store/site/site.payloads';
import { RestfulCommand, RestfulCommandBuilder, RestfulMethod } from 'app/utils/network';

export const loadAccountLevelNotifications: RestfulCommandBuilder = (
    payload: undefined,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'accounts/myNotifications' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadSiteLevelNotifications: RestfulCommandBuilder = (
    payload: SiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'myNotifications' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const markNotificationRead: RestfulCommandBuilder = (
    payload: IdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'accounts/notifications', value: payload.id },
        { name: 'markRead' },
    ]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const deleteNotification: RestfulCommandBuilder = (
    payload: IdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'accounts/notifications', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};

export const sendNotification: RestfulCommandBuilder = (
    payload: SendNotificationPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'nitifications' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};
