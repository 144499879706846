import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import {
    LoadByProjectIdPayload,
    LoadBySiteIdPayload,
} from '../../../../store/shared/models/load.payload';
import {
    AddProjectStatusPayload,
    PatchProjectStatusPayload,
    ProjectStatusIdPayload,
} from './../../../../store/project-status-report/project-status-report.payloads';

export const loadProjectStatusReportsByProjectIdCommand = (
    payload: LoadByProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projects', value: payload.projectId },
        { name: 'statusReports' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadProjectStatusReportsBySiteIdCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectStatusReports/sites', value: payload.siteId }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addProjectStatusReportCommand = (
    payload: AddProjectStatusPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectStatusReports' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchProjectStatusReportCommand = (
    payload: PatchProjectStatusPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectStatusReports', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteProjectStatusReportCommand = (
    payload: ProjectStatusIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectStatusReports', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};
