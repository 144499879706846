import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AsyncUpdateStatus } from '../shared/async-update-status.store';
import { Folder } from './models/folder.model';

export interface State extends EntityState<Folder>, AsyncUpdateStatus {}

export const adapter: EntityAdapter<Folder> = createEntityAdapter<Folder>();

export const initialState: State = adapter.getInitialState({
    serverRequestInProgress: false,
    loaded: false,
    loadingFailed: false,
    error: null,
});
