export const commonText = {
    buttons: {
        remove: 'Delete',
        save: 'Save',
        rename: 'Rename',
        copy: 'Copy',
        cut: 'Cut',
        paste: 'Paste',
        download: 'Download',
        upload: 'Upload',
        open: 'Open',
        replace: 'Replace',
        cancel: 'Cancel',
        add: 'Create',
        preview: 'Preview',
    },
};

export function getChangeProjectPageTemplateText(pageName: string): string {
    return `Any changes you made to ${pageName} will be lost`;
}
