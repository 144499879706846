import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import {
    InviteProgramMemberPayload,
    LoadProgramMembersByProgramIdPayload,
    LoadProgramMembersBySiteIdPayload,
    PatchProgramMemberPayload,
    ProgramMemberIdPayload,
} from '../../../../store/program-member/program-member.payloads';

export const loadMembersByProgramId = (
    payload: LoadProgramMembersByProgramIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'programs', value: payload.programId }, { name: 'members' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadMembersBySiteId = (
    payload: LoadProgramMembersBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'programs' },
        { name: 'members' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const inviteProgramMember = (
    payload: InviteProgramMemberPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'programs', value: payload.programId },
        { name: 'members' },
        { name: 'invite' },
    ]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchProgramMember = (
    payload: PatchProgramMemberPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'programs' }, { name: 'members', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteProgramMember = (
    payload: ProgramMemberIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'programs' }, { name: 'members', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};
