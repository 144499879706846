import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AsyncUpdateStatus } from '../shared/async-update-status.store';
import { GlTableSettings } from './models';

export interface LoadingByIdStatusMap {
    loadingStatusById: Record<string, AsyncUpdateStatus>;
}

export interface State extends EntityState<GlTableSettings>, LoadingByIdStatusMap {}

export const adapter: EntityAdapter<GlTableSettings> = createEntityAdapter<GlTableSettings>();

export const initialState: State = adapter.getInitialState({
    loadingStatusById: {},
});
