import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable()
export class SnackBarService {
    constructor(public snackBar: MatSnackBar) {}

    show(message: string, duration?: number) {
        const config = new MatSnackBarConfig();
        config.duration = duration != null ? duration : 2000;
        config.verticalPosition = 'top';
        this.snackBar.open(message, null, config);
    }

    showInfiniteBar(message: string) {
        const config = new MatSnackBarConfig();
        config.verticalPosition = 'top';
        this.snackBar.open(message, null, config);
    }

    hide() {
        this.snackBar.dismiss();
    }
}
