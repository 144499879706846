import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import { deepClone } from 'fast-json-patch';
import { ProgramActions } from '../program';
import {
    AsyncUpdateStatusDefault,
    AsyncUpdateStatusDefaultLoad,
    AsyncUpdateStatusDefaultLoadSuccess,
} from './../shared/async-update-status.store';
import * as ProjectActions from './project.actions';
import { Project } from './project.model';
import { adapter, initialState, State } from './project.store';

export const reducer = createReducer(
    initialState,

    on(ProgramActions.deleteProgram, (state, action) => {
        if (!action.options.optimistic) return state;
        return adapter.removeMany(getProjectIdsByProgramId(state, action.payload.programId), state);
    }),

    on(ProgramActions.deleteProgramSuccess, (state, action) =>
        adapter.removeMany(getProjectIdsByProgramId(state, action.payload.programId), state)
    ),

    on(ProjectActions.addProjectSuccess, (state, payload) =>
        adapter.addOne(payload.project, state)
    ),

    on(ProjectActions.addProjectFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(ProjectActions.getProjectsByProgram, (state) => ({
        ...state,
        serverRequestInProgress: true,
        loadingFailed: false,
        error: null,
    })),

    on(ProjectActions.getProjectsByProgramSuccess, (state, payload) => {
        const projectIds = payload.projects.map((p) => p.id);
        const stateWithRemoved = adapter.removeMany(projectIds, state);
        return {
            ...adapter.addMany(payload.projects, stateWithRemoved),
            loaded: true,
        };
    }),

    on(ProjectActions.getProjectsByProgramFail, (state, payload) => ({
        ...state,
        serverRequestInProgress: false,
        loadingFailed: true,
        error: payload.error,
    })),

    on(ProjectActions.getProjectsBySite, (state) => ({
        ...state,
        serverRequestInProgress: true,
        loadingFailed: false,
        error: null,
    })),

    on(ProjectActions.getProjectsBySiteSuccess, (state, payload) => {
        const projectIdList = payload.projects.map((p) => p.id);
        const stateWithRemovedProjects = adapter.removeMany(projectIdList, state);
        return {
            ...adapter.addMany(payload.projects, stateWithRemovedProjects),
            loaded: true,
        };
    }),

    on(ProjectActions.getProjectsBySiteFail, (state, payload) => ({
        ...state,
        serverRequestInProgress: false,
        loadingFailed: true,
        error: payload.error,
    })),

    on(ProjectActions.getProject, (state) => ({
        ...state,
        serverRequestInProgress: true,
        loadingFailed: false,
        error: null,
    })),

    on(ProjectActions.getProjectSuccess, (state, payload) =>
        adapter.addOne(payload.project, adapter.removeOne(payload.project.id, state))
    ),

    on(ProjectActions.getProjectFail, (state, payload) => ({
        ...state,
        serverRequestInProgress: false,
        loadingFailed: true,
        error: payload.error,
    })),

    on(ProjectActions.getProjectPermissions, (state) => ({
        ...state,
        currentProjectPermissions: null,
    })),

    on(ProjectActions.getProjectPermissionsSuccess, (state, payload) => ({
        ...state,
        currentProjectPermissions: payload.permissions,
    })),

    on(ProjectActions.getProjectSettings, (state, action) => {
        const loadingStatusMap = state.projectSettingsLoadingMap;
        const projectId = action.payload.projectId;

        return {
            ...state,
            projectSettingsLoadingMap: {
                ...loadingStatusMap,
                [projectId]: {
                    ...(loadingStatusMap[projectId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoad,
                },
            },
        };
    }),

    on(ProjectActions.getProjectSettingsSuccess, (state, payload) => {
        const loadingStatusMap = state.projectSettingsLoadingMap;
        const projectId = payload.settings.projectId;

        return {
            ...state,
            projectSettingsMap: {
                ...state.projectSettingsMap,
                [payload.settings.projectId]: payload.settings,
            },
            projectSettingsLoadingMap: {
                ...loadingStatusMap,
                [projectId]: {
                    ...(loadingStatusMap[projectId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                },
            },
        };
    }),

    on(ProjectActions.getProjectSettingsFail, (state, payload) => {
        const loadingStatusMap = state.projectSettingsLoadingMap;
        const projectId = payload.projectId;

        return {
            ...state,
            projectSettingsLoadingMap: {
                ...loadingStatusMap,
                [projectId]: {
                    ...(loadingStatusMap[projectId] ?? {}),
                    ...AsyncUpdateStatusDefault,
                    loadingFailed: true,
                    error: payload.error,
                },
            },
        };
    }),

    on(ProjectActions.patchProjectSettings, (state, action) => {
        if (!action.options?.optimistic) return state;
        const settingsMap = deepClone(state.projectSettingsMap);
        const patchedSettings = getPatched(
            settingsMap[action.payload.projectId],
            action.payload.patch
        );
        settingsMap[action.payload.projectId] = patchedSettings;
        return { ...state, projectSettingsMap: settingsMap };
    }),

    on(ProjectActions.patchProjectSettingsSuccess, (state, payload) => {
        const settingsMap = deepClone(state.projectSettingsMap);
        const patchedSettings = getPatched(settingsMap[payload.projectId], payload.patch);
        settingsMap[payload.projectId] = patchedSettings;
        return { ...state, projectSettingsMap: settingsMap };
    }),

    on(ProjectActions.patchProjectSettingsFail, (state, payload) => ({
        ...state,
        error: payload.error,
    })),

    on(ProjectActions.updateProjectSuccess, (state, payload) => {
        const update: Update<Project> = {
            id: payload.projectId,
            changes: payload.changes,
        };
        return adapter.updateOne(update, state);
    }),

    on(ProjectActions.updateProjectFail, (state, payload) => ({ ...state, error: payload.error })),

    on(ProjectActions.patchProject, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patchedProject = getPatched(state.entities[action.payload.id], action.payload.patch);
        const update: Update<Project> = {
            id: patchedProject.id,
            changes: patchedProject,
        };
        return adapter.updateOne(update, state);
    }),

    on(ProjectActions.patchProjectSuccess, (state, payload) => {
        const entity = state.entities[payload.id];
        if (!entity) return state;
        const patchedProject = getPatched(state.entities[payload.id], payload.patch);
        const update: Update<Project> = {
            id: patchedProject.id,
            changes: patchedProject,
        };
        return adapter.updateOne(update, state);
    }),

    on(ProjectActions.patchProjectFail, (state, payload) => ({ ...state, error: payload.error })),

    on(ProjectActions.archiveProjectSuccess, (state, payload) =>
        adapter.addOne(payload.project, adapter.removeOne(payload.project.id, state))
    ),

    on(ProjectActions.archiveProjectFail, (state, payload) => ({ ...state, error: payload.error })),

    on(ProjectActions.unarchiveProjectSuccess, (state, payload) =>
        adapter.addOne(payload.project, adapter.removeOne(payload.project.id, state))
    ),

    on(ProjectActions.unarchiveProjectFail, (state, payload) => ({
        ...state,
        error: payload.error,
    })),

    on(ProjectActions.deleteProject, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.projectId, state);
    }),

    on(ProjectActions.deleteProjectSuccess, (state, payload) =>
        adapter.removeOne(payload.projectId, state)
    ),

    on(ProjectActions.deleteProjectFail, (state, payload) => ({ ...state, error: payload.error })),

    on(ProjectActions.changeCurrentProjectId, (state, payload) => ({
        ...state,
        currentProjectId: payload.projectId,
    })),

    on(ProjectActions.getRaciTableByProjectSuccess, (state, payload) => {
        return {
            ...state,
            raciTablesMap: { ...state.raciTablesMap, [payload.table.projectId]: payload.table },
        };
    }),

    on(ProjectActions.updateRaciTable, (state, action) => {
        if (!action.options?.optimistic) return state;
        return {
            ...state,
            raciTablesMap: { ...state.raciTablesMap, [action.payload.projectId]: action.payload },
        };
    }),

    on(ProjectActions.updateRaciTableSuccess, (state, payload) => {
        return {
            ...state,
            raciTablesMap: { ...state.raciTablesMap, [payload.projectId]: payload },
        };
    }),

    on(ProjectActions.setInitialProjectLoaded, (state, payload) => ({
        ...state,
        initialProjectId: payload.projectId,
    })),

    on(ProjectActions.getInitialProjectFail, (state) => ({
        ...state,
        initialProjectId: null,
    }))
);

function getProjectIdsByProgramId(state: State, programId: string): string[] {
    return (state.ids as string[]).filter((id) => state.entities[id].programId === programId);
}
