import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ctx-critical-error-page',
    templateUrl: './critical-error-page.component.html',
    styleUrls: ['./critical-error-page.component.scss'],
})
export class CriticalErrorPageComponent implements OnInit {
    constructor(private location: Location) {}

    ngOnInit(): void {}
}
