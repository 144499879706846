import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Calendar } from 'app/core/models/calendar';

export interface State extends EntityState<Calendar>, LoadingStatus {
    completixCalendarTemplatesLoading: boolean;
}

export interface LoadingStatus {
    serverRequestInProgress: boolean;
    loaded: boolean;
}

export const adapter: EntityAdapter<Calendar> = createEntityAdapter<Calendar>();

export const initialState: State = adapter.getInitialState({
    serverRequestInProgress: false,
    loaded: false,
    completixCalendarTemplatesLoading: false,
});
