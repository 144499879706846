import { JsonProperty } from 'app/utils/json-mapper';
import { Model } from '../../shared/models/base.model';

export class ProgramMember extends Model {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('programId')
    programId: string = undefined;

    @JsonProperty('siteMemberId')
    siteMemberId: string = undefined;

    @JsonProperty('roleId')
    roleId: string = undefined;

    constructor(init?: Partial<ProgramMember>) {
        super();
        Object.assign(this, init);
    }
}
