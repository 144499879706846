import { ProjectListTableColumn } from 'app/shared-ui/dumb-components/project-list-table/project-list-table.data';
import { JsonProperty } from 'app/utils/json-mapper';
import { Model } from '../../shared/models/base.model';
import { UserTaskType } from '../../shared/user-task.model';
import { RouteBrick } from './../../../../route-brick';

export class LayoutSettings extends Model {
    @JsonProperty('ctxTextEditor')
    ctxTextEditor: CtxTextEditorSettings;

    @JsonProperty('sidebar')
    sidebar: SidebarSettings;

    @JsonProperty('lastOpenedRoute')
    lastOpenedRoute?: string;

    @JsonProperty('myDashboard')
    myDashboard: ExecutiveDashboardLayoutSettings;

    @JsonProperty('myDashboard')
    myCalendar: MyCalendarLayoutSettings;

    @JsonProperty('myDashboard')
    dashboardHiddenColumns?: ProjectListTableColumn[];

    @JsonProperty('programsDashboard')
    programsDashboard: ExecutiveDashboardLayoutSettings;

    @JsonProperty('projectsDashboard')
    projectsDashboard: ExecutiveDashboardLayoutSettings;

    @JsonProperty('portfoliosDashboard')
    portfoliosDashboard: PortfolioExecutiveDashboardLayoutSettings;

    @JsonProperty('programSummary')
    programSummary: ExecutiveDashboardLayoutSettings;

    @JsonProperty('managersDashboard')
    managersDashboard: ExecutiveDashboardLayoutSettings;

    @JsonProperty('resourcesDashboard')
    resourcesDashboard: ResourcesDashboardLayoutSettings;

    @JsonProperty('projectsLayoutSettings')
    projectsLayoutSettings?: ProjectLayoutSettings[];

    @JsonProperty('programsLayoutSettings')
    programsLayoutSettings?: ProgramLayoutSettings[];

    @JsonProperty('lastOpenedExecutiveDashboardRoute')
    lastOpenedExecutiveDashboardRoute?: string;

    @JsonProperty('projectRequestsLayoutSettings')
    projectRequestsLayoutSettings?: ProjectRequestsLayoutSettings;
}

export interface ProjectLayoutSettings extends SiteEntityLayoutSettings {
    projectId: string;
    projectRaid?: RaidPageLayoutSettings;
    minutesPage?: MinutesPageSettings;
    statusPage?: StatusPageSettings;
    changeRequestsPage?: ChangeRequestsPageSettings;
    homePage?: HomePageSettings;
    budgetPage?: BudgetPageSettings;
    projectSummaryDashboard?: ExecutiveDashboardLayoutSettings;
}

export interface ProgramLayoutSettings extends SiteEntityLayoutSettings {
    programId: string;
}

export interface SiteEntityLayoutSettings {
    lastOpenedRoute?: string;
    documentRepositoryPage?: DocumentRepositoryLayoutSettings;
}

export interface MinutesPageSettings {
    splitAreaSize?: number;
}

export interface ChangeRequestsPageSettings {
    viewMode?: PageViewMode;
}

export enum HomePageAdditionalSection {
    Logs = 'Logs',
    Calendar = 'Calendar',
    None = 'None',
}

export interface HomePageSettings {
    contentSplitterSize?: number;
    visibleAdditionalSection?: HomePageAdditionalSection;
    expandedPanels?: HomePagePanel[];
}

export enum HomePagePanel {
    Details = 'Details',
    Documents = 'Documents',
    Scope = 'Scope',
    Assumptions = 'Assumptions',
    Communication = 'Communication',
    Closure = 'Closure',
}

export interface StatusPageSettings {
    splitAreaSize?: number;
}

export interface BudgetPageSettings {
    splitAreaSize?: number;
}

export enum RaidViewMode {
    List = 'List',
    Board = 'Board',
}

export enum PageViewMode {
    Grid = 'Grid',
    List = 'List',
}

export interface RaidRecordLayoutSettings {
    viewMode?: RaidViewMode;
}

export interface RaidPageLayoutSettings {
    lastOpenedPage?: RaidRecordPage;
    risks?: RaidRecordLayoutSettings;
    actions?: RaidRecordLayoutSettings;
    issues?: RaidRecordLayoutSettings;
    decisions?: RaidRecordLayoutSettings;
}

export enum RaidRecordPage {
    Risks = 'Risks',
    Actions = 'Actions',
    Issues = 'Issues',
    Decisions = 'Decisions',
}

export const raidRecordPageRouteMap: Record<RaidRecordPage, RouteBrick> = {
    [RaidRecordPage.Risks]: RouteBrick.Risks,
    [RaidRecordPage.Actions]: RouteBrick.Actions,
    [RaidRecordPage.Issues]: RouteBrick.Issues,
    [RaidRecordPage.Decisions]: RouteBrick.Decisions,
};

export const routeRaidRecordPageMap: Record<string, RaidRecordPage> = {
    [RouteBrick.Risks]: RaidRecordPage.Risks,
    [RouteBrick.Actions]: RaidRecordPage.Actions,
    [RouteBrick.Issues]: RaidRecordPage.Issues,
    [RouteBrick.Decisions]: RaidRecordPage.Decisions,
};

export interface CtxTextEditorSettings {
    autoSaveEnabled?: boolean;
}

export interface DocumentRepositoryLayoutSettings {
    previewSectionOpened?: boolean;
    splitAreaSize?: number;
    lastOpenedDocumentId?: string;
    dontAskToConvertDocumentIds?: string[];
}

export interface SidebarSettings {
    opened?: boolean;
    projectTree?: ProjectTreeLayout;
}

export interface ProjectTreeLayout {
    orderedIds?: string[];
    expandedIds?: string[];
    autoSort?: boolean;
}

export interface ExecutiveDashboardLayoutSettings {
    showClosedSwitchState?: ExecutiveDashboardPanelState;
    showHighOnlySwitchState?: ExecutiveDashboardPanelState;
    expandedPanelState?: ExecutiveDashboardPanelState;
    hiddenUserTaskTypes?: UserTaskType[];
    filterSettings?: ExecutiveDashboardPanelFilterSettings;
}

export interface PortfolioExecutiveDashboardLayoutSettings
    extends ExecutiveDashboardLayoutSettings {
    lastOpenedPortfolioId?: string;
}

export interface ExecutiveDashboardPanelState {
    projects?: boolean;
    programs?: boolean;
    tasks?: boolean;
    risks?: boolean;
    issues?: boolean;
    reminders?: boolean;
    timeline?: boolean;
    budget?: boolean;
    financials?: boolean;
}

export enum ResourcesDashboardViewMode {
    Overall = 'Overall',
    AllocationMonthly = 'AllocationMonthly',
    AllocationYearly = 'AllocationYearly',
}

export interface ResourcesDashboardLayoutSettings {
    viewMode?: ResourcesDashboardViewMode;
}

export interface MyCalendarLayoutSettings {
    showOverdueSwitchState?: boolean;
    showClosedSwitchState?: boolean;
}

export interface ProjectRequestsLayoutSettings {
    viewMode?: PageViewMode;
}

export interface ExecutiveDashboardPanelFilterSettings {
    projects?: TableFilterLayoutSettings;
    programs?: TableFilterLayoutSettings;
    tasks?: TableFilterLayoutSettings;
    risks?: TableFilterLayoutSettings;
    issues?: TableFilterLayoutSettings;
    reminders?: TableFilterLayoutSettings;
}

export interface TableFilterLayoutSettings {
    enabled?: boolean;
    filters?: TableFilter[];
}

export interface TableFilter {
    id: string;
    value: string;
}
