import { Action } from '@ngrx/store';

export interface OptimisticableActionOptions {
    optimistic: boolean;
    cancelOnFail: boolean;
}

export const PessimisticDefaults: OptimisticableActionOptions = {
    optimistic: false,
    cancelOnFail: false,
};

export const OptimisticDefaults: OptimisticableActionOptions = {
    optimistic: true,
    cancelOnFail: true,
};

export interface OptimisticableAction extends Action {
    options: OptimisticableActionOptions;
}

export function isOptimisticableAction(action: Action): action is OptimisticableAction {
    return (
        (action as OptimisticableAction).options != null &&
        (action as OptimisticableAction).options.optimistic != null &&
        (action as OptimisticableAction).options.cancelOnFail != null
    );
}
