import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getPatch } from 'app/utils/json-patch';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { ProgramMemberSelectors } from '.';
import { AppStore } from '..';
import { SnackBarService } from '../../../services/snack-bar/snack-bar.service';
import { RestApiService } from '../../services/rest-api';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import { ProgramMember } from './models';
import * as ProgramMemberActions from './program-member.actions';

@Injectable()
export class ProgramMemberEffects {
    loadMembersByProgramId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramMemberActions.loadProgramMembersByProgramId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) =>
                        response.payload.map((member) => Model.createFromDto(ProgramMember, member))
                    ),
                    map((members: ProgramMember[]) =>
                        ProgramMemberActions.loadProgramMembersSuccess({
                            programMembers: members,
                        })
                    ),
                    catchError((error) =>
                        of(ProgramMemberActions.loadProgramMembersFail({ error }))
                    )
                )
            )
        )
    );

    loadMembersBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramMemberActions.loadProgramMembersBySiteId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) =>
                        response.payload.map((member) => Model.createFromDto(ProgramMember, member))
                    ),
                    map((members: ProgramMember[]) =>
                        ProgramMemberActions.loadProgramMembersSuccess({
                            programMembers: members,
                        })
                    ),
                    catchError((error) =>
                        of(ProgramMemberActions.loadProgramMembersFail({ error }))
                    )
                )
            )
        )
    );

    inviteProgramMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramMemberActions.inviteProgramMember),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Inviting member to the Program...');
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBarService.hide()),
                    map((response) =>
                        ProgramMemberActions.inviteProgramMemberSuccess({
                            member: Model.createFromDto(ProgramMember, response.payload),
                        })
                    ),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(ProgramMemberActions.inviteProgramMemberFail({ error }));
                    })
                );
            })
        )
    );

    updateProgramMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramMemberActions.updateProgramMember),
            withLatestFrom(this.store$.select(ProgramMemberSelectors.selectAll)),
            mergeMap(([action, programMembers]) => {
                const memberToPatch = programMembers.find(
                    (member) => member.id === action.payload.id
                );
                const patch = getPatch(memberToPatch, action.payload.changes);
                return of(
                    ProgramMemberActions.patchProgramMember({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchProgramMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramMemberActions.patchProgramMember),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ProgramMemberActions.patchProgramMemberSuccess({
                            member: Model.createFromDto(ProgramMember, response.payload),
                        });
                    }),
                    catchError((error) => {
                        this.snackBarService.show('Change failed');
                        return of(
                            ProgramMemberActions.patchProgramMemberFail({
                                error,
                                originAction: action,
                            })
                        );
                    })
                )
            )
        )
    );

    deleteManyProgramMembers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramMemberActions.deleteManyProgramMembers),
            mergeMap((payload) => {
                const actions = payload.ids.map((id) =>
                    ProgramMemberActions.deleteProgramMember({ payload: { id } })
                );
                return actions;
            })
        )
    );

    deleteProgramMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramMemberActions.deleteProgramMember),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ProgramMemberActions.deleteProgramMemberSuccess({
                            id: action.payload.id,
                        });
                    }),
                    catchError((error) =>
                        of(
                            ProgramMemberActions.deleteProgramMemberFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>,
        private snackBarService: SnackBarService
    ) {}
}
