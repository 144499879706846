import { SubscriptionPlan, SubscriptionPlanCreateOptions } from 'app/core/models/billing';
import { IdPayload } from 'app/core/store/shared/models/shared-payloads';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import { CreatePayload, PatchPayload } from '../../../../store/shared/payloads';

export const loadSubscriptionPlans = (payload: undefined, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'admin' }, { name: 'subscriptionPlans' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addSubscriptionPlan = (
    payload: CreatePayload<SubscriptionPlanCreateOptions>,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'admin' }, { name: 'subscriptionPlans' }]);
    baseCommand.method = RestfulMethod.POST;
    baseCommand.payload.setData(payload.entity);
    return baseCommand;
};

export const patchSubscriptionPlan = (
    payload: PatchPayload<SubscriptionPlan>,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'admin' },
        { name: 'subscriptionPlans', value: '' + payload.id },
    ]);
    baseCommand.method = RestfulMethod.PATCH;
    baseCommand.payload.setData(payload.patch);
    return baseCommand;
};

export const deleteSubscriptionPlan = (payload: IdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'admin' }, { name: 'subscriptionPlans', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};
