import { ApprovalState } from 'app/core/store/approval-request';
import { LoadBySiteIdPayload } from 'app/core/store/shared/models/load.payload';
import { IdPayload } from 'app/core/store/shared/models/shared-payloads';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import {
    AddProjectPhaseDeliverablePayload,
    AddProjectPhasePayload,
    ChangeGatingTemplatePayload,
    DeleteProjectPhaseDeliverablePayload,
    LoadPhasesByContainerIdPayload,
    ManageProjectPhaseApprovalPayload,
    PatchProjectPhaseDeliverablePayload,
    PatchProjectPhasePayload,
    ProjectPhaseIdPayload,
} from './../../../../store/project-phase/project-phase.payloads';

export const loadProjectPhasesByContainerIdCommand = (
    payload: LoadPhasesByContainerIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projectPhases' },
        { name: 'container', value: payload.containerId },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadProjectPhasesBySiteIdCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectPhases/sites', value: payload.siteId }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const getProjectPhaseByIdCommand = (payload: IdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projectPhases', value: payload.id }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addProjectPhaseCommand = (
    payload: AddProjectPhasePayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectPhases' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchProjectPhaseCommand = (
    payload: PatchProjectPhasePayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectPhases', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const advanceProjectPhaseCommand = (
    payload: ProjectPhaseIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectPhases', value: payload.id }, { name: 'advance' }]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const submitProjectPhaseCommand = (
    payload: ProjectPhaseIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projectPhases', value: payload.id },
        { name: 'submission', value: 'submit' },
    ]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const unsubmitProjectPhaseCommand = (
    payload: ProjectPhaseIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projectPhases', value: payload.id },
        { name: 'submission', value: 'unsubmit' },
    ]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const manageProjectPhaseApprovalCommand = (
    payload: ManageProjectPhaseApprovalPayload,
    baseCommand: RestfulCommand
) => {
    const approvalEndPoint =
        payload.approvalState === ApprovalState.Approved
            ? 'approve'
            : payload.approvalState === ApprovalState.Rejected
            ? 'reject'
            : null;
    baseCommand.setResource([
        { name: 'projectPhases', value: payload.phaseId },
        { name: 'approval', value: approvalEndPoint },
    ]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const deleteProjectPhaseCommand = (
    payload: ProjectPhaseIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectPhases', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};

export const changeGatingTemplateCommand = (
    payload: ChangeGatingTemplatePayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projects', value: payload.projectId },
        { name: 'phaseContainer', value: payload.templateContainerId },
    ]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const addProjectPhaseDeliverableCommand = (
    payload: AddProjectPhaseDeliverablePayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projectPhases', value: payload.phaseId },
        { name: 'deliverables' },
    ]);
    baseCommand.payload.setData(payload.deliverable);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchProjectPhaseDeliverableCommand = (
    payload: PatchProjectPhaseDeliverablePayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projectPhases', value: payload.phaseId },
        { name: 'deliverables', value: payload.deliverableId },
    ]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteProjectPhaseDeliverableCommand = (
    payload: DeleteProjectPhaseDeliverablePayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projectPhases', value: payload.phaseId },
        { name: 'deliverables', value: payload.deliverableId },
    ]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};
