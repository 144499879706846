import { LoadBySiteIdPayload } from 'app/core/store/shared/models/load.payload';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import {
    AddProjectRolePayload,
    PatchProjectRolePayload,
    ProjectRoleIdPayload,
    SetProjectRoleTagPayload,
} from './../../../../store/project-role/project-role.payloads';

export const loadProjectRoleTemplates = (payload: undefined, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projects' }, { name: 'roles' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadProjectRolesBySite = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projects/sites', value: payload.siteId }, { name: 'roles' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addProjectRole = (payload: AddProjectRolePayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projects' }, { name: 'roles' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchProjectRole = (payload: PatchProjectRolePayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projects' }, { name: 'roles', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const setProjectRoleTag = (
    payload: SetProjectRoleTagPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projects' },
        { name: 'roles', value: payload.roleId },
        { name: 'setTag' },
    ]);
    baseCommand.payload.setData(payload.tag);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const deleteProjectRole = (payload: ProjectRoleIdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projects' }, { name: 'roles', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};
