import {
    LoadByProjectIdPayload,
    LoadBySiteIdPayload,
} from 'app/core/store/shared/models/load.payload';
import { IdPayload } from 'app/core/store/shared/models/shared-payloads';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import {
    AddMinutePayload,
    MinuteIdPayload,
    PatchMinutePayload,
    SendMinutePreviewPayload,
} from './../../../../store/minute/minute.payloads';

export const loadMinutesByProjectIdCommand = (
    payload: LoadByProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projects', value: payload.projectId }, { name: 'minutes' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadMinutesBySiteIdCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'minutes' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addMinuteCommand = (payload: AddMinutePayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'meetingMinutes' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const cloneMinuteCommand = (payload: IdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'meetingMinutes', value: payload.id }, { name: 'clone' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchMinuteCommand = (payload: PatchMinutePayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'meetingMinutes', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteMinuteCommand = (payload: MinuteIdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'meetingMinutes', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};

export const sendMinutePreview = (
    payload: SendMinutePreviewPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'meetingMinutes', value: payload.minuteId },
        { name: 'sendPreview' },
    ]);
    baseCommand.method = RestfulMethod.POST;
    baseCommand.payload.setData(payload);
    return baseCommand;
};
