export class Amount {
    static currencySigns = {
        USD: '$',
        EUR: '€',
    };

    constructor(private cents: number, private currency?: string) {
        this.currency = currency && currency.toUpperCase();
    }

    static getCurrencyPrefix(currency: string): string {
        return this.currencySigns[currency.toUpperCase()] || '';
    }

    static getCurrencySuffix(currency: string): string {
        return this.getCurrencyPrefix(currency) === '' ? currency.toUpperCase() : '';
    }

    toString(): string {
        const textAmount = this.cents / 100;

        if (this.currency && Amount.currencySigns[this.currency]) {
            return Amount.getCurrencyPrefix(this.currency) + ' ' + textAmount;
        }

        const suffix = Amount.getCurrencySuffix(this.currency);
        return textAmount + (suffix ? ' ' + suffix : '');
    }

    toNumberAmount(): number {
        return this.cents;
    }
}
