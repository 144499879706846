import { Inject, Injectable, InjectionToken } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { AuthenticationService } from 'app/services/authentication.service';
import { environment } from 'environments/environment';
import { Command } from '../commands/base.command';

export const SignalRBaseUrl = new InjectionToken('SIGNALR_BASE_URL');

@Injectable()
export class SignalRGateway {
    constructor(
        private authenticationService: AuthenticationService,
        @Inject(SignalRBaseUrl) private baseUrl: string
    ) {}

    send(connection: signalR.HubConnection, command: Command) {
        return connection
            .invoke(command.method, command.payload.serialize())
            .catch((error) =>
                console.error(
                    `SignalR: ${(error && error.message) || 'cannot send command'}`,
                    command
                )
            );
    }

    buildConnection(endpoint: string) {
        console.log(this.baseUrl + endpoint);
        const options: signalR.IHttpConnectionOptions = {
            accessTokenFactory: () => this.authenticationService.getAuthenticationToken(),
            ...environment.signalRApiConfig.connectionOptions,
        };

        return new signalR.HubConnectionBuilder()
            .withUrl(this.baseUrl + endpoint, options)
            .configureLogging(
                environment.production ? signalR.LogLevel.Error : signalR.LogLevel.Error
            )
            .build();
    }
}
