import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import { deepClone } from 'fast-json-patch';
import {
    AsyncUpdateStatusDefault,
    AsyncUpdateStatusDefaultLoad,
    AsyncUpdateStatusDefaultLoadSuccess,
} from '../shared/async-update-status.store';
import { ProgramStatusReport } from './models';
import * as ProgramStatusReportActions from './program-status-report.actions';
import { adapter, initialState } from './program-status-report.store';

export const reducer = createReducer(
    initialState,

    on(ProgramStatusReportActions.loadProgramStatuses, (state, action) => {
        const loadingStatusMap = state.loadingStatusByProgramId;
        const programId = action.payload.programId;

        return {
            ...state,
            loadingStatusByProgramId: {
                ...loadingStatusMap,
                [programId]: { ...loadingStatusMap[programId], ...AsyncUpdateStatusDefaultLoad },
            },
        };
    }),

    on(ProgramStatusReportActions.loadProgramStatusesSuccess, (state, payload) => {
        const programReportIds = Object.values(state.entities)
            .filter((r) => r.programId === payload.programId)
            .map((r) => r.id);
        const newState = adapter.addMany(
            payload.statuses,
            adapter.removeMany(programReportIds, state)
        );
        const loadingStatusMap = state.loadingStatusByProgramId;
        const programId = payload.programId;

        return {
            ...newState,
            loadingStatusByProgramId: {
                ...loadingStatusMap,
                [programId]: {
                    ...loadingStatusMap[programId],
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                },
            },
        };
    }),

    on(ProgramStatusReportActions.loadProgramStatusesFail, (state, payload) => {
        const loadingStatusMap = state.loadingStatusByProgramId;
        const programId = payload.programId;

        return {
            ...state,
            loadingStatusByProgramId: {
                ...loadingStatusMap,
                [programId]: {
                    ...loadingStatusMap[programId],
                    ...AsyncUpdateStatusDefaultLoad,
                    error: payload.error,
                },
            },
        };
    }),

    on(ProgramStatusReportActions.loadProgramStatusesBySiteId, (state, action) => {
        const loadingStatusMap = state.loadingStatusBySiteId;
        const siteId = action.payload.siteId;

        return {
            ...state,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoad,
                },
            },
        };
    }),

    on(ProgramStatusReportActions.loadProgramStatusesBySiteIdSuccess, (state, payload) => {
        const programIds = payload.programIds;
        const projectReportIds = Object.values(state.entities)
            .filter((r) => programIds.includes(r.programId))
            .map((r) => r.id);
        const newState = adapter.addMany(
            payload.statuses,
            adapter.removeMany(projectReportIds, state)
        );

        const loadingStatusMap = state.loadingStatusBySiteId;
        const siteId = payload.siteId;
        const loadingByProgramIdMap = deepClone(state.loadingStatusByProgramId);
        programIds.forEach(
            (id) =>
                (loadingByProgramIdMap[id] = {
                    ...(loadingByProgramIdMap[id] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                })
        );

        return {
            ...newState,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                },
            },
            loadingStatusByProgramId: loadingByProgramIdMap,
        };
    }),

    on(ProgramStatusReportActions.loadProgramStatusesBySiteIdFail, (state, action) => {
        const loadingStatusMap = state.loadingStatusBySiteId;
        const siteId = action.siteId;

        return {
            ...state,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefault,
                    loadingFailed: true,
                    error: action.error,
                },
            },
        };
    }),

    on(ProgramStatusReportActions.addProgramStatusSuccess, (state, payload) =>
        adapter.addOne(payload.report, state)
    ),

    on(ProgramStatusReportActions.patchProgramStatus, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patched = getPatched(state.entities[action.payload.id], action.payload.patch);
        const update: Update<ProgramStatusReport> = {
            id: patched.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(ProgramStatusReportActions.patchProgramStatusSuccess, (state, payload) => {
        const patched = getPatched(state.entities[payload.id], payload.patch);
        const update: Update<ProgramStatusReport> = {
            id: patched.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(ProgramStatusReportActions.patchProgramStatusFail, (state) => state),

    on(ProgramStatusReportActions.deleteProgramStatus, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(ProgramStatusReportActions.deleteProgramStatusSuccess, (state, payload) =>
        adapter.removeOne(payload.id, state)
    ),

    on(ProgramStatusReportActions.deleteProgramStatusFail, (state) => state)
);
