import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Timesheet } from 'app/core/store/timesheet';
import { SnackBarService } from 'app/services/snack-bar/snack-bar.service';
import { getPatch } from 'app/utils/json-patch';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { TimesheetActions, TimesheetSelectors } from '.';
import { AppStore } from '..';
import { RestApiService } from '../../services/rest-api';
import { Model } from '../shared/models/base.model';
import { okEmptyAction } from './../project/project.actions';
import { ExternalWeeklyActivity, ProjectWeeklyActivity } from './models';

@Injectable()
export class TimesheetEffects {
    loadTimesheetBySiteMemberId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TimesheetActions.loadTimesheetBySiteMemberId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        TimesheetActions.loadTimesheetBySiteMemberIdSuccess({
                            timesheet: this.getModelFromDto(response.payload),
                        })
                    ),
                    catchError((error) =>
                        of(TimesheetActions.loadTimesheetBySiteMemberIdFail({ error }))
                    )
                )
            )
        )
    );

    loadTimesheetsBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TimesheetActions.loadTimesheetsBySiteId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        TimesheetActions.loadTimesheetsBySiteIdSuccess({
                            timesheets: response.payload.map((dto) => this.getModelFromDto(dto)),
                        })
                    ),
                    catchError((error) =>
                        of(TimesheetActions.loadTimesheetsBySiteIdFail({ error }))
                    )
                )
            )
        )
    );

    updateTimesheet$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TimesheetActions.updateTimesheet),
            withLatestFrom(this.store$.select(TimesheetSelectors.selectAll)),
            mergeMap(([action, timesheets]) => {
                const timesheet = timesheets.find((t) => t.id === action.payload.id);
                const patch = getPatch(timesheet, action.payload.changes);
                return of(
                    TimesheetActions.patchTimesheet({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchTimesheet$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TimesheetActions.patchTimesheet),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return TimesheetActions.patchTimesheetSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) =>
                        of(TimesheetActions.patchTimesheetFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    manageActivityApproval$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TimesheetActions.manageActivityApproval),
            concatMap((action) => {
                if (!action.options?.optimistic) {
                    this.snackBarService.showInfiniteBar('Processing an Approval...');
                }
                return this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        this.snackBarService.hide();
                        if (action.options?.optimistic) return okEmptyAction();
                        return TimesheetActions.manageActivityApprovalSuccess({
                            timesheet: this.getModelFromDto(response.payload),
                        });
                    }),
                    catchError((error) => {
                        this.snackBarService.show('Timesheet Approval failed');
                        return of(
                            TimesheetActions.manageActivityApprovalFail({
                                error,
                                originAction: action,
                            })
                        );
                    })
                );
            })
        )
    );

    reOpenTimesheet$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TimesheetActions.reOpenTimesheetWeek),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Re-Opening the Timesheet...');
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBarService.hide()),
                    map((response: CommandResult) =>
                        TimesheetActions.updateTimesheetSuccess({
                            timesheet: this.getModelFromDto(response.payload),
                        })
                    ),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(
                            TimesheetActions.patchTimesheetFail({
                                error,
                                originAction: action,
                            })
                        );
                    })
                );
            })
        )
    );

    submitTimesheetWeek$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TimesheetActions.submitTimesheetWeek),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Submitting...');
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBarService.hide()),
                    map((response: CommandResult) =>
                        TimesheetActions.patchTimesheetSuccess({
                            id: action.payload.timesheetId,
                            patch: response.payload,
                        })
                    ),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(
                            TimesheetActions.patchTimesheetFail({
                                error,
                                originAction: action,
                            })
                        );
                    })
                );
            })
        )
    );

    reSubmitTimesheetWeekActivity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TimesheetActions.reSubmitTimesheetWeekActivity),
            concatMap((action) => {
                return this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return TimesheetActions.patchTimesheetSuccess({
                            id: action.payload.timesheetId,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) => {
                        return of(
                            TimesheetActions.patchTimesheetFail({
                                error,
                                originAction: action,
                            })
                        );
                    })
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>,
        private snackBarService: SnackBarService
    ) {}

    getModelFromDto(dto): Timesheet {
        const timesheet: Timesheet = Model.createFromDto(Timesheet, dto);
        return {
            ...timesheet,
            projectActivities: timesheet.projectActivities.map((activity) => ({
                ...Model.createFromDto(ProjectWeeklyActivity, activity),
                weekStartDate: new Date(activity.weekStartDate),
            })),
            externalActivities: timesheet.externalActivities.map((activity) => ({
                ...Model.createFromDto(ExternalWeeklyActivity, activity),
                weekStartDate: new Date(activity.weekStartDate),
            })),
        };
    }
}
