import { allWeekDays, Calendar, isWorkingDate } from 'app/core/models/calendar';
import { getRandomBrightColor } from 'app/core/models/shared-models';
import { dateIsBetweenOrEqualDates, getDateArrayBetweenDates } from 'app/date-format';
import { JsonProperty } from 'app/utils/json-mapper';
import {
    addDays,
    differenceInCalendarDays,
    endOfMonth,
    getDay,
    isEqual,
    isSameDay,
    startOfMonth,
} from 'date-fns';
import { ExtendedProjectMemberAllocation } from '../../allocation';
import { ProjectMember } from '../../project-member';
import { Model } from '../../shared/models/base.model';
import { HourlyRate } from './../../shared/models/hourly-rate.model';

// Only initialized fields exist on DB
export class SiteMember extends Model {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('siteId')
    siteId: string = undefined;

    @JsonProperty('accountId')
    accountId?: string = undefined;

    @JsonProperty('name')
    name?: string = undefined;

    @JsonProperty('email')
    email: string = undefined;

    @JsonProperty('title')
    title?: string = undefined;

    @JsonProperty('imageDataUrl')
    imageDataUrl?: string = undefined;

    @JsonProperty('projectMembership')
    projectMembership?: ProjectMember[];

    @JsonProperty('hourlySiteRate')
    hourlySiteRate?: HourlyRate = undefined;

    @JsonProperty('mainRoleId')
    mainRoleId?: string = undefined;

    @JsonProperty('additionalRoleIds')
    additionalRoleIds?: string[] = undefined;

    @JsonProperty('teamDept')
    teamDept?: string = undefined;

    @JsonProperty('invitation')
    invitation?: {
        emailSent: boolean;
        createdDate: Date;
    } = undefined;

    @JsonProperty('reportsToSiteMemberId')
    reportsToSiteMemberId?: string = undefined;

    @JsonProperty('avatarColor')
    avatarColor?: string = getRandomBrightColor();

    @JsonProperty('location')
    location?: string = undefined;

    @JsonProperty('externalProjectMembership')
    externalProjectMembership?: SiteMemberExternalProjectMember[] = undefined;

    deleted?: boolean;

    constructor(init?: Partial<SiteMember>) {
        super();
        Object.assign(this, init);
    }
}

export interface SiteMemberExternalProjectMember {
    id: string;
    projectName: string;
    role?: string;
}

export enum SiteLevelMemberPermission {
    WorkManager = 'Work Manager',
    SubmitProjectRequest = 'Submit Intake',
    GateApprover = 'Gate Approver',
}

export interface SiteMemberCreateOptions {
    name: string;
    email: string;
}

export interface SiteMemberAllocationPeriod {
    percent: number;
    startDate: Date;
    endDate: Date;
}

export interface AllocationTotal {
    percent: number;
    hours: number;
    averageWeeklyHours: number;
    cost: number;
}

export enum SiteAccessRole {
    Admin = 'Admin',
    Coordinator = 'Coordinator',
    PowerViewer = 'PowerViewer',
    TeamMember = 'TeamMember',
    Guest = 'Guest',
}

export interface CurrentAllocationTotalDictionary {
    [projectMemberId: string]: AllocationTotal;
}

export interface AllocationTotalsDictionary {
    [allocationId: string]: AllocationTotal;
}

export function getAllocationTotal(
    allocation: ExtendedProjectMemberAllocation,
    rate: number,
    calendar: Calendar
): AllocationTotal {
    if (!allocation || !calendar) return { hours: 0, cost: 0, percent: 0, averageWeeklyHours: 0 };

    const end = new Date(allocation?.end);
    const notWorkingDays = allWeekDays.filter((day) => !calendar.workingDaysOfWeek?.includes(day));
    const holidayDates = calendar.holidays?.map((holiday) => holiday.date);

    let start = new Date(allocation?.start);
    const isStartDateHoliday = holidayDates?.some((date) =>
        isEqual(new Date(date), new Date(start))
    );
    const durationRange: Day[] = isStartDateHoliday ? [] : [getDay(start)];
    while (differenceInCalendarDays(end, start) > 0) {
        start = addDays(start, 1);
        const isHoliday = holidayDates?.some((date) => isEqual(new Date(date), new Date(start)));
        if (!isHoliday) durationRange.push(getDay(start));
    }

    const rangeWithoutHolidays = durationRange.filter(
        (day) => !notWorkingDays.some((d) => d === day) && calendar.workingDaysOfWeek.includes(day)
    );
    const totalHours = +(
        calendar?.workingHoursPerDay *
        rangeWithoutHolidays.length *
        (allocation?.percentValue / 100)
    ).toFixed(1);
    const totalCost = rate * totalHours;
    const totalWeeks = Math.ceil(
        differenceInCalendarDays(new Date(allocation.end), new Date(allocation.start)) / 7
    );
    const total: AllocationTotal = {
        hours: +totalHours.toFixed(2),
        cost: +totalCost.toFixed(),
        percent: allocation?.percentValue,
        averageWeeklyHours: +(totalHours / (totalWeeks || 1)).toFixed(2),
    };

    return total;
}

export function getAllocationsContainsDate(
    date: Date,
    allocations: ExtendedProjectMemberAllocation[],
    calendar: Calendar
): ExtendedProjectMemberAllocation[] {
    return (
        allocations?.filter((al) => {
            const isHoliday =
                !calendar?.workingDays?.some((day) => isSameDay(new Date(day.date), date)) &&
                (!calendar?.workingDaysOfWeek?.includes(getDay(date)) ||
                    calendar?.holidays?.some((holiday) => isSameDay(new Date(holiday.date), date)));
            return (
                !isHoliday && dateIsBetweenOrEqualDates(date, new Date(al.start), new Date(al.end))
            );
        }) || []
    );
}

export function getAllocationsMonthTotal(
    allocations: ExtendedProjectMemberAllocation[],
    rate: number,
    calendar: Calendar,
    month: Date
) {
    const monthStart = startOfMonth(month);
    const monthEnd = endOfMonth(month);
    const monthDates = getDateArrayBetweenDates(monthStart, monthEnd);
    let totalHours: number = 0;
    let percentsSum: number = 0;
    const workingMonthDates = monthDates.filter((date) => isWorkingDate(calendar, date));
    workingMonthDates.forEach((date) => {
        const dateAllocations = getAllocationsContainsDate(date, allocations, calendar);
        const datePercent = dateAllocations.reduce((acc, curr) => acc + curr.percentValue, 0);
        percentsSum += datePercent;
        const dateHours = (datePercent / 100) * calendar.workingHoursPerDay;
        totalHours += dateHours;
    });

    const totalWeeks = Math.ceil(
        differenceInCalendarDays(new Date(monthEnd), new Date(monthStart)) / 7
    );

    const total: AllocationTotal = {
        hours: +totalHours.toFixed(2),
        cost: +(totalHours * rate).toFixed(),
        percent: percentsSum / (workingMonthDates.length ?? 1),
        averageWeeklyHours: +(totalHours / (totalWeeks ?? 1)).toFixed(2),
    };

    return total;
}
