import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import {
    AddPortfolioPayload,
    ManagePortfolioProjectPayload,
    PatchPortfolioPayload,
    PortfolioIdPayload,
} from './../../../../store/portfolio/portfolio.payloads';
import { LoadBySiteIdPayload } from './../../../../store/shared/models/load.payload';

export const loadPortfoliosBySiteIdCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'portfolios' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addPortfolioCommand = (payload: AddPortfolioPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'portfolios' }]);
    baseCommand.payload.setData(payload.createOptions);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchPortfolioCommand = (
    payload: PatchPortfolioPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'portfolios', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const addProjectToPortfolioCommand = (
    payload: ManagePortfolioProjectPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'portfolios', value: payload.portfolioId },
        { name: 'project', value: payload.projectId },
    ]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const removeProjectFromPortfolioCommand = (
    payload: ManagePortfolioProjectPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'portfolios', value: payload.portfolioId },
        { name: 'project', value: payload.projectId },
    ]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};

export const deletePortfolioCommand = (
    payload: PortfolioIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'portfolios', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};
