import { JsonProperty } from 'app/utils/json-mapper';
import { CtxColor, CtxMember } from '../../../models/shared-models';
import { Model } from '../../shared/models/base.model';
import { LowMediumHighStatus, RaidRecord } from '../../shared/models/raid-shared.model';

export class IssueRecord extends Model implements RaidRecord {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('projectId')
    projectId: string = undefined;

    @JsonProperty('textId')
    textId?: string;

    @JsonProperty('description')
    description?: string;

    @JsonProperty('mitigationPlan')
    mitigationPlan?: string;

    @JsonProperty('dateRaised')
    dateRaised?: Date;

    @JsonProperty('dueDate')
    dueDate?: Date;

    @JsonProperty('creationDate')
    creationDate: string = undefined;

    @JsonProperty('closingDate')
    closingDate: string = undefined;

    @JsonProperty('statusId')
    statusId: string = undefined;

    @JsonProperty('impact')
    impact: LowMediumHighStatus = undefined;

    @JsonProperty('attachedDocumentIds')
    attachedDocumentIds?: string[];

    @JsonProperty('assignedMember')
    assignedMember?: CtxMember;

    @JsonProperty('isTopFive')
    isTopFive?: boolean;

    @JsonProperty('color')
    color?: CtxColor;

    @JsonProperty('originObjectId')
    originObjectId?: string;

    constructor(init?: Partial<IssueRecord>) {
        super();
        Object.assign(this, init);
    }
}
