import { Calendar } from 'app/core/models/calendar';
import { ProjectHiddenPage } from 'app/core/store/project';
import { JsonProperty } from 'app/utils/json-mapper';
import { ProjectBudgetTable } from '../../budget-table';
import { Communication } from '../../communication';
import { ListContainer } from '../../project-phase-container/models/list-container.model';
import {
    ProjectDelivery,
    ProjectLockedPage,
    ProjectSettingOptions,
    UserTaskTypeColor,
} from '../../project/project.model';
import { Model } from '../../shared/models/base.model';
import { UserTaskType } from '../../shared/user-task.model';

export class ProjectTemplate extends Model {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('siteId')
    siteId: string = undefined;

    @JsonProperty('name')
    name: string = undefined;

    @JsonProperty('gatingTemplateId')
    gatingTemplateId?: string;

    @JsonProperty('scheduleTemplateId')
    scheduleTemplateId?: string;

    @JsonProperty('calendarTemplateId')
    calendarTemplateId?: string;

    @JsonProperty('budgetTemplateId')
    budgetTemplateId?: string;

    @JsonProperty('communicationTemplateId')
    communicationTemplateId?: string;

    @JsonProperty('settingOptions')
    options: ProjectSettingOptions;

    @JsonProperty('visibleInCalendarTasks')
    visibleInCalendarTasks: UserTaskType[];

    @JsonProperty('taskTypeColorsInCalendar')
    taskTypeColorsInCalendar?: UserTaskTypeColor[];

    @JsonProperty('hiddenProjectTabRoutes')
    hiddenPages: ProjectHiddenPage;

    @JsonProperty('lockedPages')
    lockedPages: ProjectLockedPage;

    @JsonProperty('isDefault')
    isDefault?: boolean;

    @JsonProperty('delivery')
    delivery?: ProjectDelivery;

    @JsonProperty('isCompletixTemplate')
    isCompletixTemplate?: boolean;

    @JsonProperty('isPublished')
    isPublished?: boolean;

    @JsonProperty('isInitialTemplate')
    isInitialTemplate?: boolean;

    constructor(init?: Partial<ProjectTemplate>) {
        super();
        Object.assign(this, init);
    }
}

export interface ProjectTemplateCreateOptions {
    siteId: string;
    name: string;
    isCompletixTemplate: boolean;
}

export interface ImportCompletixProjectTemplateOptions {
    projectTemplate: ProjectTemplate;
    gatingTemplate: ListContainer;
    scheduleTemplate: ListContainer;
    projectCalendarTemplate: Calendar;
    projectBudgetTableTemplate: ProjectBudgetTable;
    communicationTemplate: Communication;
}
