import { createFeatureSelector, createSelector } from '@ngrx/store';
import { defaultProjectCalendar, typicalWorkWeekWorkingDaysMap } from 'app/core/models/calendar';
import { SiteSettingsSelectors } from 'app/core/store/site-settings';
import { ProjectSelectors } from '../project';
import { SiteSelectors } from '../site';
import { StateEntry } from '../state-entry';
import { adapter, LoadingStatus, State } from './calendar.store';

export const selectCalendarEntry = createFeatureSelector<State>(StateEntry.Calendar);

export const selectLoadingStatus = createSelector(
    selectCalendarEntry,
    (state): LoadingStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
    })
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectCalendarEntry);

export const selectCurrentSiteCalendarTemplates = createSelector(
    selectAll,
    SiteSelectors.selectCurrentSiteId,
    (calendars, siteId) => calendars.filter((c) => c.isTemplate && c.siteId === siteId).reverse()
);

export const selectCurrentSiteDefaultCalendarTemplate = createSelector(
    selectCurrentSiteCalendarTemplates,
    (templates) => templates.find((t) => t.isDefault)
);

export const selectCompletixCalendarTemplates = createSelector(selectAll, (calendars) =>
    calendars.filter((c) => c.isCompletixTemplate && c.isTemplate)
);

export const selectCompletixDefaultCalendarTemplate = createSelector(
    selectCompletixCalendarTemplates,
    (templates) => templates.find((t) => t.isDefault)
);

export const selectCompletixCalendarTemplatesLoadingStatus = createSelector(
    selectCalendarEntry,
    (state) => state.completixCalendarTemplatesLoading
);

export const selectCurrentProjectCalendar = createSelector(
    selectAll,
    ProjectSelectors.selectCurrentProjectId,
    (calendars, projectId) => {
        return calendars.find((c) => c.projectId === projectId);
    }
);

export const selectCalendarById = (id: string) =>
    createSelector(selectAll, (calendars) => calendars.find((c) => c.id === id));

export const selectCalendarByProjectId = (projectId: string) =>
    createSelector(selectAll, (calendars) =>
        calendars.find((c) => projectId && c.projectId === projectId)
    );

export const selectCurrentSiteDefaultManualCalendar = createSelector(
    SiteSettingsSelectors.selectCurrentSiteSettings,
    (siteSettings) => ({
        ...defaultProjectCalendar,
        workingDaysOfWeek: typicalWorkWeekWorkingDaysMap[siteSettings?.options?.typicalWorkingWeek],
    })
);
