import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import {
    AsyncUpdateStatusDefault,
    AsyncUpdateStatusDefaultLoad,
    AsyncUpdateStatusDefaultLoadSuccess,
} from '../shared/async-update-status.store';
import * as AccountActions from './account.actions';
import { AccountModel } from './account.model';
import { initialState } from './account.store';

export const reducer = createReducer(
    initialState,
    on(AccountActions.getMyAccount, (state) => ({
        ...state,
        serverRequestInProgress: true,
        loadingFailed: false,
        error: null,
    })),

    on(AccountActions.getMyAccountSuccess, (state, payload) => ({
        ...state,
        userAccount: payload.account,
        serverRequestInProgress: false,
        loaded: true,
        loadingFailed: false,
        error: null,
    })),

    on(AccountActions.getMyAccountFail, (state, payload) => ({
        ...state,
        userAccount: null,
        serverRequestInProgress: false,
        loadingFailed: true,
        error: payload.error,
    })),

    on(AccountActions.getSystemAdminData, (state) => ({
        ...state,
        systemAdminDataLoadingStatus: {
            ...state.systemAdminDataLoadingStatus,
            ...AsyncUpdateStatusDefaultLoad,
        },
    })),

    on(AccountActions.getSystemAdminDataSuccess, (state, payload) => ({
        ...state,
        systemAdminData: payload.data,
        systemAdminDataLoadingStatus: {
            ...state.systemAdminDataLoadingStatus,
            ...AsyncUpdateStatusDefaultLoadSuccess,
        },
    })),

    on(AccountActions.getSystemAdminDataFail, (state, payload) => ({
        ...state,
        systemAdminDataLoadingStatus: {
            ...state.systemAdminDataLoadingStatus,
            ...AsyncUpdateStatusDefault,
            error: payload.error,
        },
    })),

    on(AccountActions.updateAccount, (state, action) => {
        if (!action.options?.optimistic) return state;
        return {
            ...state,
            userAccount: new AccountModel({ ...state.userAccount, ...action.payload.changes }),
        };
    }),

    on(AccountActions.updateAccountSuccess, (state, payload) => ({
        ...state,
        userAccount: new AccountModel({ ...state.userAccount, ...payload.changes }),
    })),

    on(AccountActions.updateAccountFail, (state, payload) => ({
        ...state,
        error: payload.error,
    })),

    on(AccountActions.updateAccountImageSuccess, (state, payload) => ({
        ...state,
        avatar: payload.image,
    })),

    on(AccountActions.updateAccountImageFail, (state, payload) => ({
        ...state,
        avatar: null,
        error: payload.error,
    })),

    on(AccountActions.getAccountImageSuccess, (state, payload) => ({
        ...state,
        avatar: payload.image,
    })),

    on(AccountActions.getAccountImageFail, (state, payload) => ({
        ...state,
        avatar: null,
        error: payload.error,
    })),

    on(AccountActions.getUserAppMessageSuccess, (state, payload) => ({
        ...state,
        appMessage: payload.message,
    })),

    on(AccountActions.patchSystemAdminData, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patchedData = getPatched(state.systemAdminData, action.payload.patch);
        return { ...state, systemAdminData: patchedData };
    }),

    on(AccountActions.patchSystemAdminDataSuccess, (state, payload) => ({
        ...state,
        systemAdminData: payload.data,
    }))
);
