import { Update } from '@ngrx/entity/src';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import { ProjectMember } from './models';
import { ProjectMemberStatus } from './models/project-member.model';
import {
    deleteProjectMemberFail,
    deleteProjectMemberSuccess,
    getProjectMemberSuccess,
    inviteProjectMemberFail,
    inviteProjectMemberSuccess,
    loadProjectMembersByProjectIdFail,
    loadProjectMembersByProjectIdSuccess,
    loadProjectMembersBySiteIdFail,
    loadProjectMembersBySiteIdSuccess,
    patchProjectMember,
    patchProjectMemberFail,
    patchProjectMemberFromSignalR,
    patchProjectMemberSuccess,
    reOpenProjectMember,
    reOpenProjectMemberFail,
    setPrimaryProjectManager,
    setPrimaryProjectManagerFail,
    setPrimaryProjectManagerSuccess,
} from './project-member.actions';
import { adapter, initialState } from './project-member.store';

export const reducer = createReducer(
    initialState,

    on(loadProjectMembersBySiteIdSuccess, (state, payload) => {
        const newState = adapter.addMany(payload.projectMembers, adapter.removeAll(state));
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(loadProjectMembersBySiteIdFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(loadProjectMembersByProjectIdSuccess, (state, payload) => {
        const replaceIds = Object.values(state.entities)
            .filter((m) => m.projectId === payload.projectId)
            .map((m) => m.id);
        return adapter.addMany(payload.projectMembers, adapter.removeMany(replaceIds, state));
    }),

    on(loadProjectMembersByProjectIdFail, (state) => state),

    on(inviteProjectMemberSuccess, (state, payload) => adapter.addOne(payload.member, state)),

    on(inviteProjectMemberFail, (state) => ({ ...state })),

    on(patchProjectMember, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patchedDocument = getPatched(state.entities[action.payload.id], action.payload.patch);
        const memberUpdate: Update<ProjectMember> = {
            id: patchedDocument.id,
            changes: patchedDocument,
        };
        return adapter.updateOne(memberUpdate, state);
    }),

    on(patchProjectMemberSuccess, (state, payload) => {
        const entity = state.entities[payload.id];
        const patchedEntity = getPatched(entity, payload.patch);
        const update: Update<ProjectMember> = {
            id: patchedEntity.id,
            changes: patchedEntity,
        };
        return adapter.updateOne(update, state);
    }),

    on(patchProjectMemberFail, (state) => ({ ...state })),

    on(patchProjectMemberFromSignalR, (state, action) => {
        const entity = state.entities[action.payload.id];
        const patchedEntity = getPatched(entity, action.payload.patch);
        const update: Update<ProjectMember> = {
            id: patchedEntity.id,
            changes: patchedEntity,
        };
        return adapter.updateOne(update, state);
    }),

    // on(deleteProjectMember, (state, action) => {
    //     return adapter.removeOne(action.payload.id, state);
    // }),

    on(deleteProjectMemberSuccess, (state, payload) => {
        if (payload.deleted) return adapter.removeOne(payload.id, state);
        const member = state.entities[payload.id];
        const update: Update<ProjectMember> = {
            id: payload.id,
            changes: { status: ProjectMemberStatus.Closed },
        };
        return adapter.updateOne(update, state);
    }),

    on(deleteProjectMemberFail, (state) => ({ ...state })),

    on(setPrimaryProjectManager, (state, action) => {
        if (!action.options?.optimistic) return state;
        const newPrimaryManager = state.entities[action.payload.id];
        const newPrimaryManagerUpdate: Update<ProjectMember> = {
            id: newPrimaryManager.id,
            changes: { isPrimaryManager: true },
        };
        const oldPrimaryManager = Object.values(state.entities)
            .filter((m) => m.projectId === newPrimaryManager.projectId)
            .find((m) => m.isPrimaryManager);
        const oldPrimaryManagerUpdate: Update<ProjectMember> = {
            id: oldPrimaryManager?.id,
            changes: { isPrimaryManager: false },
        };
        return adapter.updateMany([newPrimaryManagerUpdate, oldPrimaryManagerUpdate], state);
    }),

    on(setPrimaryProjectManagerSuccess, (state, payload) => {
        const newPrimaryManager = state.entities[payload.id];
        const newPrimaryManagerUpdate: Update<ProjectMember> = {
            id: newPrimaryManager.id,
            changes: { isPrimaryManager: true },
        };
        const oldPrimaryManager = Object.values(state.entities)
            .filter((m) => m.projectId === newPrimaryManager.projectId)
            .find((m) => m.isPrimaryManager);
        const oldPrimaryManagerUpdate: Update<ProjectMember> = {
            id: oldPrimaryManager?.id,
            changes: { isPrimaryManager: false },
        };
        return adapter.updateMany([newPrimaryManagerUpdate, oldPrimaryManagerUpdate], state);
    }),

    on(setPrimaryProjectManagerFail, (state) => state),

    on(reOpenProjectMember, (state, action) => {
        if (!action.options?.optimistic) return state;
        const member = state.entities[action.payload.id];
        const update: Update<ProjectMember> = {
            id: member.id,
            changes: { deleted: false, status: ProjectMemberStatus.Active },
        };
        return adapter.updateOne(update, state);
    }),

    on(reOpenProjectMemberFail, (state) => state),

    on(getProjectMemberSuccess, (state, payload) =>
        adapter.addOne(payload.member, adapter.removeOne(payload.member.id, state))
    )
);
