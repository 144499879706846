import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from 'app/services/authentication.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(this.addAuthToken(request));
    }

    addAuthToken(request: HttpRequest<any>) {
        const token = this.authenticationService.getAuthenticationToken();

        if (!token?.length) return request;

        return request.clone({
            setHeaders: {
                Authorization: token,
            },
        });
    }
}
