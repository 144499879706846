import { JsonProperty } from 'app/utils/json-mapper';
import { Role } from '../../shared/models/role.base.model';
import { ProgramPermissions } from '../../shared/models/shared-permissions.model';
import { ProgramRoleTag } from './../../shared/models/shared-permissions.model';

export class ProgramRole extends Role {
    @JsonProperty('tag')
    tag?: ProgramRoleTag;

    @JsonProperty('permissions')
    permissions: ProgramPermissions;

    constructor(init?: Partial<ProgramRole>) {
        super();
        Object.assign(this, init);
    }
}
