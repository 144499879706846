import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppStore } from 'app/core/store';
import { AppOperationActions } from 'app/core/store/app-operation';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthGuardService implements CanLoad {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private store: Store<AppStore.AppState>
    ) {}

    async canLoad(route: Route, segments: UrlSegment[]) {
        const redirectUrl = this.router.getCurrentNavigation()?.extractedUrl.toString() || '/';
        this.store.dispatch(
            new AppOperationActions.SetAuthenticationRedirectUrl({ url: redirectUrl })
        );
        return await this.authenticationService.authenticateCurrentSession();
    }
}
