import { createReducer, on } from '@ngrx/store';
import { deserialize } from 'app/utils/json-mapper';
import { getPatched } from 'app/utils/json-patch';
import * as AllocationActions from './allocation.actions';
import { adapter, initialState } from './allocation.store';
import { ExtendedProjectMemberAllocation } from './models';

export const reducer = createReducer(
    initialState,
    on(AllocationActions.loadAllocationsBySite, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(AllocationActions.loadAllocationsByProject, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(AllocationActions.loadAllocationsSuccess, (state, payload) => {
        const newState = adapter.addMany(payload.allocations, adapter.removeAll(state));
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(AllocationActions.loadAllocationsFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(AllocationActions.addAllocationSuccess, (state, payload) =>
        adapter.addOne(payload.allocation, state)
    ),

    on(AllocationActions.addAllocationFail, (state, payload) => state),

    on(AllocationActions.patchAllocation, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patched = getPatched(state.entities[action.payload.id], action.payload.patch);
        return adapter.addOne(
            deserialize(ExtendedProjectMemberAllocation, patched),
            adapter.removeOne(patched.id, state)
        );
    }),

    on(AllocationActions.patchAllocationSuccess, (state, payload) => {
        const patched = getPatched(state.entities[payload.id], payload.patch);
        return adapter.addOne(
            deserialize(ExtendedProjectMemberAllocation, patched),
            adapter.removeOne(patched.id, state)
        );
    }),

    on(AllocationActions.patchAllocationFail, (state, payload) => state),

    on(AllocationActions.deleteAllocation, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(AllocationActions.deleteAllocationSuccess, (state, payload) =>
        adapter.removeOne(payload.id, state)
    ),

    on(AllocationActions.deleteAllocationFail, (state, payload) => state)
);
