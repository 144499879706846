import { EntitySubmission } from 'app/core/models/shared-models';
import { AccountProjectMemberStatusDictionary } from 'app/features/site/timesheet-page/timesheet/timesheet.component';
import { JsonProperty } from 'app/utils/json-mapper';
import { addDays, isAfter, isBefore, isSameDay } from 'date-fns';
import { ApprovalState } from '../../approval-request';
import { ProjectMemberStatus } from '../../project-member';
import { Project } from '../../project/project.model';
import { SiteMember } from '../../resource';
import { Model } from '../../shared/models/base.model';

export class Timesheet extends Model {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('resourceId')
    siteMemberId: string = undefined;

    @JsonProperty('projectActivities')
    projectActivities: ProjectWeeklyActivity[] = undefined;

    @JsonProperty('projectIds')
    projectIds: string[] = undefined;

    @JsonProperty('externalActivities')
    externalActivities: ExternalWeeklyActivity[] = undefined;

    constructor(init?: Partial<Timesheet>) {
        super();
        Object.assign(this, init);
    }
}

export interface ActivityWeekSubmission {
    weekStartDate: Date;
    submissionDate: Date;
}

export class WeeklyActivity {
    id: string;
    weekStartDate: Date;
    hours: number[];
}

export class ProjectWeeklyActivity extends WeeklyActivity {
    projectId: string;
    approvalState?: ApprovalState;
    submission?: EntitySubmission;

    constructor(init?: Partial<ProjectWeeklyActivity>) {
        super();
        Object.assign(this, init);
    }
}

export interface ProjectWeeklyActivityExtended extends ProjectWeeklyActivity {
    timesheetId: string;
}

export class ExternalWeeklyActivity extends WeeklyActivity {
    name: string;

    constructor(init?: Partial<ExternalWeeklyActivity>) {
        super();
        Object.assign(this, init);
    }
}

export enum TimesheetStatus {
    Open = 'open',
    Pending = 'pending',
    Approved = 'approved',
    PartiallyApproved = 'partially-approved',
}

export function getTimesheetWeekStatus(
    projectActivities: ProjectWeeklyActivity[]
): TimesheetStatus {
    let status: TimesheetStatus = TimesheetStatus.Open;
    const submitted =
        projectActivities?.length && projectActivities.every((a) => a.submission?.submitted);
    const allApproved =
        projectActivities?.length &&
        projectActivities.every((a) => a.approvalState === ApprovalState.Approved);
    const someApproved =
        projectActivities?.length &&
        projectActivities?.some((a) => a.approvalState === ApprovalState.Approved);
    if (allApproved) {
        status = TimesheetStatus.Approved;
    } else if (someApproved) {
        status = TimesheetStatus.PartiallyApproved;
    } else if (submitted) {
        status = TimesheetStatus.Pending;
    }
    return status;
}

export function getTimesheetProjectActivities(
    timesheet: Timesheet,
    weekStartDate: Date,
    accountProjectMemberStatusDict: AccountProjectMemberStatusDictionary,
    projects: Project[]
): ProjectWeeklyActivity[] {
    return timesheet.projectIds
        .map((projectId) => {
            const existingActivity = timesheet.projectActivities.find(
                (a) => a.projectId === projectId && isSameDay(a.weekStartDate, weekStartDate)
            );
            const emptyActivity = new ProjectWeeklyActivity({
                projectId,
                hours: new Array(7).fill(0),
                weekStartDate: weekStartDate,
            });

            const projectMembershipClosed =
                accountProjectMemberStatusDict[projectId]?.status === ProjectMemberStatus.Closed;
            if (projectMembershipClosed) {
                const statusChangeDate = new Date(
                    accountProjectMemberStatusDict[projectId].statusChangeDate
                );
                const weekIsAfterProjectMembershipStatusChange = isAfter(
                    weekStartDate,
                    statusChangeDate
                );
                if (weekIsAfterProjectMembershipStatusChange) return null;
            }

            const selectedWeekEnd = addDays(weekStartDate, 7);
            const project = projects.find((p) => p.id === projectId);
            const weekIsBeforeProjectStart = isBefore(
                selectedWeekEnd,
                new Date(project?.startDate)
            );
            return existingActivity || (weekIsBeforeProjectStart ? null : emptyActivity);
        })
        .filter((a) => a);
}

export interface OutstandingTimesheetReportRecord {
    id: string;
    siteMember: SiteMember;
    projectName: string;
    startDate: Date;
    endDate: Date;
    status: TimesheetStatus;
}
