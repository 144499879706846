import { JsonProperty } from 'app/utils/json-mapper';

export class InvoicePayment {
    @JsonProperty('date')
    date: Date = undefined;

    @JsonProperty('paymentMethod')
    paymentMethod: string = undefined;

    @JsonProperty('amountCents')
    amountCents: number = undefined;
}
