import { JsonProperty } from 'app/utils/json-mapper';

export class ApprovalRequest {
    @JsonProperty('id')
    id: string;

    @JsonProperty('siteId')
    siteId: string;

    @JsonProperty('projectId')
    projectId?: string;

    @JsonProperty('projectName')
    projectName?: string;

    @JsonProperty('originObjectId')
    originObjectId: string;

    @JsonProperty('topLevelOriginObjectId')
    topLevelOriginObjectId: string;

    @JsonProperty('authorSiteMemberId')
    authorSiteMemberId: string;

    @JsonProperty('authorSiteMemberName')
    authorSiteMemberName: string;

    @JsonProperty('authorSiteMemberEmail')
    authorSiteMemberEmail: string;

    @JsonProperty('approverSiteMemberIds')
    approverSiteMemberIds: string[];

    @JsonProperty('approverSiteMemberNames')
    approverSiteMemberNames: string[];

    @JsonProperty('type')
    type: ApprovalType;

    @JsonProperty('description')
    description: string;

    @JsonProperty('state')
    state: ApprovalState;

    @JsonProperty('creationDate')
    creationDate: Date;

    @JsonProperty('closingDate')
    closingDate?: Date;

    authorAvatarColor: string;
    authorImageDataUrl: string;

    constructor(init?: Partial<ApprovalRequest>) {
        Object.assign(this, init);
    }
}

export enum ApprovalState {
    Pending = 'Pending',
    Approved = 'Approved',
    Rejected = 'Rejected',
}

export enum ApprovalType {
    ChangeRequest = 'ChangeRequest',
    Gate = 'Gate',
    Timesheet = 'Timesheet',
    ProjectRequest = 'ProjectRequest',
    Decision = 'Decision',
    SiteOwnerChange = 'SiteOwnerChange',
}
