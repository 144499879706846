import { Action, createAction, props } from '@ngrx/store';
import { LoadBySiteIdPayload } from '../shared/models/load.payload';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import { Portfolio } from './models';
import {
    AddPortfolioPayload,
    ManagePortfolioProjectPayload,
    PatchPortfolioPayload,
    PortfolioIdPayload,
    UpdatePortfolioPayload,
} from './portfolio.payloads';

// Get Portfolios actions

export const loadPortfolios = createAction(
    '[Portfolio] Load',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadPortfoliosSuccess = createAction(
    '[Portfolio] Load success',
    props<{ portfolios: Portfolio[] }>()
);

export const loadPortfoliosFail = createAction('[Portfolio] Load fail', props<{ error: any }>());

// Add Portfolio actions

export const addPortfolio = createAction(
    '[Portfolio] Add',
    props<{ payload: AddPortfolioPayload }>()
);

export const addPortfolioSuccess = createAction(
    '[Portfolio] Add success',
    props<{ portfolio: Portfolio }>()
);

export const addPortfolioFail = createAction('[Portfolio] Add fail', props<{ error: any }>());

// Add Project to Portfolio actions

export const addProjectToPortfolio = createAction(
    '[Portfolio] Add project',
    props<{ payload: ManagePortfolioProjectPayload; options?: OptimisticableActionOptions }>()
);

// Remove Project from Portfolio actions

export const removeProjectFromPortfolio = createAction(
    '[Portfolio] Remove project',
    props<{ payload: ManagePortfolioProjectPayload; options?: OptimisticableActionOptions }>()
);

// Update Portfolio actions

export const updatePortfolio = createAction(
    '[Portfolio] Update',
    props<{ payload: UpdatePortfolioPayload; options?: OptimisticableActionOptions }>()
);

export const patchPortfolio = createAction(
    '[Portfolio] Patch',
    props<{ payload: PatchPortfolioPayload; options?: OptimisticableActionOptions }>()
);

export const patchPortfolioSuccess = createAction(
    '[Portfolio] Update success',
    props<{ portfolio: Portfolio }>()
);

export const patchPortfolioFail = createAction(
    '[Portfolio] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Delete Portfolio actions

export const deletePortfolio = createAction(
    '[Portfolio] Delete',
    props<{ payload: PortfolioIdPayload; options?: OptimisticableActionOptions }>()
);

export const deletePortfolioSuccess = createAction(
    '[Portfolio] Delete success',
    props<PortfolioIdPayload>()
);

export const deletePortfolioFail = createAction(
    '[Portfolio] Delete fail',
    props<{ error: any; originAction: Action }>()
);
