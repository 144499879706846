import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityLevel } from 'app/core/models/shared-models';
import { ProjectSelectors } from '../project';
import { SiteSelectors } from '../site';
import { StateEntry } from '../state-entry';
import { adapter, State } from './list-container.store';
import { ListContainerType } from './models';

export const selectListContainerEntry = createFeatureSelector<State>(StateEntry.ListContainer);

export const selectLoadingStatusesPerSiteId = createSelector(
    selectListContainerEntry,
    (state) => state.loadingStatusBySiteId
);

export const selectLoadingStatusForCurrentSite = createSelector(
    selectLoadingStatusesPerSiteId,
    SiteSelectors.selectCurrentSiteId,
    (map, siteId) => map?.[siteId]
);

export const selectLoadingStatusesPerProjectId = createSelector(
    selectListContainerEntry,
    (state) => state.loadingStatusByProjectId
);

export const selectLoadingStatusForCurrentProject = createSelector(
    selectLoadingStatusForCurrentSite,
    (status) => !status?.loaded
);

export const selectLoadingStatusSystem = createSelector(
    selectListContainerEntry,
    (state) => state.systemTemplatesLoadingStatus
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectListContainerEntry);

export const selectAllListContainersByType = (type: ListContainerType) =>
    createSelector(selectAll, (containers) => containers.filter((c) => c.containerType === type));

export const selectCurrentSiteListContainerTemplates = (type: ListContainerType) =>
    createSelector(
        selectAllListContainersByType(type),
        SiteSelectors.selectCurrentSiteId,
        (containers, siteId) =>
            containers.filter(
                (c) => c.scope.id === siteId && c.scope.level === EntityLevel.Site && c.isTemplate
            )
    );

export const selectCurrentProjectListContainer = (type: ListContainerType) =>
    createSelector(
        selectAllListContainersByType(type),
        ProjectSelectors.selectCurrentProjectId,
        (containers, projectId) =>
            containers.find(
                (c) => c.scope.id === projectId && c.scope.level === EntityLevel.Project
            )
    );

export const selectSystemListContainers = (type: ListContainerType) =>
    createSelector(selectAllListContainersByType(type), (containers) =>
        containers.filter((c) => c.isTemplate && c.scope.level === EntityLevel.System)
    );

export const selectSystemPublishedListContainers = (type: ListContainerType) =>
    createSelector(selectSystemListContainers(type), (containers) =>
        containers.filter((c) => c.isPublished)
    );
