import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import {
    addProgramFail,
    addProgramSuccess,
    changeCurrentProgramId,
    deleteProgram,
    deleteProgramFail,
    deleteProgramSuccess,
    getInitialProgramFail,
    getProgramByIdFail,
    getProgramByIdSuccess,
    getProgramPermissions,
    getProgramPermissionsSuccess,
    loadPrograms,
    loadProgramsFail,
    loadProgramsSuccess,
    patchProgram,
    patchProgramFail,
    patchProgramSuccess,
    setInitialProgramLoaded,
} from './program.actions';
import { Program } from './program.model';
import { adapter, initialState } from './program.store';

export const reducer = createReducer(
    initialState,
    on(loadPrograms, (state) => ({
        ...state,
        serverRequestInProgress: true,
        loadingFailed: false,
        error: null,
    })),

    on(loadProgramsSuccess, (state, payload) => {
        const newState = adapter.addMany(payload.programs, adapter.removeAll(state));
        return {
            ...newState,
            serverRequestInProgress: false,
            loaded: true,
            error: null,
        };
    }),

    on(loadProgramsFail, (state, payload) => ({
        ...state,
        serverRequestInProgress: false,
        loadingFailed: true,
        error: payload.error,
    })),

    on(getProgramByIdSuccess, (state, payload) => {
        return adapter.addOne(payload.program, adapter.removeOne(payload.program.id, state));
    }),

    on(getProgramByIdFail, (state, payload) => ({ ...state, error: payload.error })),

    on(getProgramPermissions, (state) => ({ ...state, currentProgramPermissions: null })),

    on(getProgramPermissionsSuccess, (state, payload) => ({
        ...state,
        currentProgramPermissions: payload.permissions,
    })),

    on(addProgramSuccess, (state, payload) => adapter.addOne(payload.program, state)),

    on(addProgramFail, (state, payload) => ({ ...state, error: payload.error })),

    on(patchProgram, (state, action) => {
        if (!action.options?.optimistic) return state;
        const program = state.entities[action.payload.programId];
        const updatedProgram = getPatched(program, action.payload.patch);
        const update: Update<Program> = {
            id: updatedProgram.id,
            changes: updatedProgram,
        };
        return adapter.updateOne(update, state);
    }),

    on(patchProgramSuccess, (state, payload) => {
        const program = state.entities[payload.programId];
        const updatedProgram = getPatched(program, payload.patch);
        const update: Update<Program> = {
            id: updatedProgram.id,
            changes: updatedProgram,
        };
        return adapter.updateOne(update, state);
    }),

    on(patchProgramFail, (state, action) => ({ ...state, error: action.error })),

    on(deleteProgram, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.programId, state);
    }),

    on(deleteProgramSuccess, (state, action) => adapter.removeOne(action.payload.programId, state)),

    on(deleteProgramFail, (state, payload) => ({ ...state, error: payload.error })),

    on(changeCurrentProgramId, (state, action) => ({
        ...state,
        currentProgramId: action.payload.programId,
    })),

    on(setInitialProgramLoaded, (state, payload) => ({
        ...state,
        initialProgramId: payload.programId,
    })),

    on(getInitialProgramFail, (state) => ({
        ...state,
        initialProgramId: null,
    }))
);
