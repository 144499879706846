import { Observable, Observer } from 'rxjs';
import { Gateway } from '../gateways/base.gateway';
import { CommandPayload } from './payloads/base.command.payload';

export enum CommandState {
    IDLE,
    EXECUTING,
    INVOKED,
}

export interface CommandResult {
    command: Command;
    payload: any;
}

export abstract class Command {
    protected static _id = 0;
    protected _state: CommandState;
    protected _payload: CommandPayload;
    protected _commands: Command[] = [];
    protected _method: any;
    protected _gateway: Gateway;
    protected _id = 0;
    protected _siteId: string = null;
    protected _siteHubConnectionId: string = null;

    constructor(payload?: CommandPayload) {
        this._payload = payload;
        Command._id += 1;
        this._id = Command._id;
    }

    get id(): number {
        return this._id;
    }

    get payload(): CommandPayload {
        return this._payload;
    }

    set payload(value: CommandPayload) {
        this._payload = value;
    }

    get siteId(): string {
        return this._siteId;
    }

    set siteId(value: string) {
        this._siteId = value;
    }

    get siteHubConnectionId(): string {
        return this._siteHubConnectionId;
    }

    set siteHubConnectionId(value: string) {
        this._siteHubConnectionId = value;
    }

    get method(): any {
        return this._method;
    }

    set method(value: any) {
        this._method = value;
    }

    set gateway(value: Gateway) {
        this._gateway = value;
    }

    get mimeType() {
        return this._payload.mimeType;
    }

    concat(command: Command): void {
        this._payload.concat(command.payload);
    }

    serialize(): string | Blob | ArrayBuffer | any[] | FormData {
        return this._payload.serialize();
    }

    invoke(context?: Command): Observable<CommandResult> {
        context = context || this;
        context.state = CommandState.EXECUTING;
        const result = Observable.create((observer: Observer<CommandResult>) => {
            this._gateway.send(context).subscribe(
                (response: Observer<any>) => {
                    context.state = CommandState.INVOKED;
                    observer.next({
                        command: context,
                        payload: response,
                    });
                },
                (error: any) => observer.error(error),
                () => observer.complete()
            );
        });
        return result;
    }

    set state(value: CommandState) {
        this._state = value;
    }

    get state(): CommandState {
        return this._state;
    }
}
