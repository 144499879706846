import { AsyncUpdateStatus, AsyncUpdateStatusDefault } from '../shared/async-update-status.store';
import { ImageModel } from '../shared/models';
import { AccountModel, SystemAdminData, UserAppMessage } from './account.model';

export interface State extends AsyncUpdateStatus {
    userAccount: AccountModel;
    avatar: ImageModel;
    systemAdminData: SystemAdminData;
    systemAdminDataLoadingStatus: AsyncUpdateStatus;
    appMessage: UserAppMessage;
}

export const initialState: State = {
    ...AsyncUpdateStatusDefault,
    userAccount: null,
    avatar: null,
    systemAdminData: null,
    systemAdminDataLoadingStatus: null,
    appMessage: null,
};
