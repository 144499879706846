import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import { ProjectField } from './models';
import * as ProjectFieldActions from './project-field.actions';
import { adapter, initialState } from './project-field.store';

export const reducer = createReducer(
    initialState,

    on(ProjectFieldActions.loadProjectFieldsBySiteId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(ProjectFieldActions.loadProjectFieldsBySiteIdSuccess, (state, payload) => {
        const siteId = payload.fields[0]?.siteId;
        const existingFieldIds = Object.values(state.entities)
            .filter((pf) => pf.siteId === siteId)
            .map((pf) => pf.id);
        const newState = adapter.addMany(
            payload.fields,
            adapter.removeMany(existingFieldIds, state)
        );
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(ProjectFieldActions.loadProjectFieldsFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(ProjectFieldActions.loadProjectFieldsByCompletixSettingsTemplate, (state, action) => ({
        ...state,
        loadingStatusBySettingsTemplateId: {
            ...state.loadingStatusBySettingsTemplateId,
            [action.payload.id]: {
                ...state.loadingStatusBySettingsTemplateId[action.payload.id],
                serverRequestInProgress: true,
            },
        },
    })),

    on(
        ProjectFieldActions.loadProjectFieldsByCompletixSettingsTemplateSuccess,
        (state, payload) => {
            const existingFieldIds = Object.values(state.entities)
                .filter(
                    (pf) =>
                        pf.completixSettingsTemplateId ===
                        payload.fields[0].completixSettingsTemplateId
                )
                .map((pf) => pf.id);
            const newState = adapter.addMany(
                payload.fields,
                adapter.removeMany(existingFieldIds, state)
            );
            return {
                ...newState,
                loadingStatusBySettingsTemplateId: {
                    ...state.loadingStatusBySettingsTemplateId,
                    [payload.fields[0].completixSettingsTemplateId]: {
                        loaded: true,
                        serverRequestInProgress: false,
                    },
                },
            };
        }
    ),

    on(ProjectFieldActions.loadProjectFieldsByCompletixSettingsTemplateFail, (state, payload) => ({
        ...state,
        loadingStatusBySettingsTemplateId: {
            ...state.loadingStatusBySettingsTemplateId,
            [payload.id]: {
                ...state.loadingStatusBySettingsTemplateId[payload.id],
                serverRequestInProgress: false,
            },
        },
    })),

    on(ProjectFieldActions.patchProjectField, (state, action) => {
        if (!action.options?.optimistic) return;
        const patched = getPatched(state.entities[action.payload.id], action.payload.patch);
        const update: Update<ProjectField> = {
            id: patched.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(ProjectFieldActions.patchProjectFieldSuccess, (state, payload) => {
        const patched = getPatched(state.entities[payload.id], payload.patch);
        const update: Update<ProjectField> = {
            id: patched.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(ProjectFieldActions.patchProjectFielddFail, (state) => ({ ...state }))
);
