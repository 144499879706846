import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getMonthAllWeekStartDates } from 'app/date-format';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import isSameMonth from 'date-fns/isSameMonth';
import isSameYear from 'date-fns/isSameYear';
import min from 'date-fns/min';
import startOfWeek from 'date-fns/startOfWeek';
import { ApprovalState } from '../approval-request';
import { generateUUID } from '../id-generator';
import { ProjectSelectors } from '../project';
import { SiteMemberSelectors } from '../resource';
import {
    selectAccountProjectMembersStatusMap,
    selectAccountSiteMembership,
} from '../shared/account-mebership.selectors';
import { StateEntry } from '../state-entry';
import {
    getTimesheetProjectActivities,
    getTimesheetWeekStatus,
    OutstandingTimesheetReportRecord,
    ProjectWeeklyActivityExtended,
} from './models';
import { adapter, LoadingStatus, State } from './timesheet.store';

export const selectTimesheetEntry = createFeatureSelector<State>(StateEntry.Timesheet);

export const selectLoadingStatus = createSelector(
    selectTimesheetEntry,
    (state): LoadingStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
    })
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectTimesheetEntry);

export const selectTimesheetById = (id: string) =>
    createSelector(selectAll, (timesheets) => timesheets.find((t) => t.id === id));

export const selectTimesheetByResourceId = (resourceId: string) =>
    createSelector(selectAll, (timesheets) =>
        timesheets.find((t) => t.siteMemberId === resourceId)
    );

export const selectCurrentAccountTimesheet = createSelector(
    selectAll,
    selectAccountSiteMembership,
    (timesheets, currentSiteMemberhip) =>
        timesheets.find((t) => t.siteMemberId === currentSiteMemberhip?.id)
);

export const selectCurrentSiteOutstandingTimesheets = createSelector(
    SiteMemberSelectors.selectCurrentSiteMembers,
    selectAll,
    ProjectSelectors.selectCurrentSiteProjects,
    selectAccountProjectMembersStatusMap,
    (siteMembers, timesheets, projects, accountProjectMemberStatusDict) => {
        const records: OutstandingTimesheetReportRecord[] = [];
        const currentWeekStartDate = startOfWeek(new Date(), { weekStartsOn: 1 });
        const siteMemberIds = siteMembers.map((sm) => sm.id);
        const siteMemberTimesheets = timesheets.filter((ts) =>
            siteMemberIds.includes(ts.siteMemberId)
        );
        const today = new Date();
        const projectStartDatesBeforeToday = projects
            .map((prj) => new Date(prj.startDate))
            .filter((date) => date.getFullYear() > 2010)
            .filter((date) => differenceInCalendarDays(today, date) >= 0);
        if (!projectStartDatesBeforeToday?.length) return [];
        let earliestProjectStart = min(projectStartDatesBeforeToday);
        const allMonthDates = [earliestProjectStart];
        let loopCount = 0;
        while (
            !isSameMonth(today, earliestProjectStart) ||
            !isSameYear(today, earliestProjectStart)
        ) {
            if (loopCount > 120) break;
            earliestProjectStart = addMonths(earliestProjectStart, 1);
            allMonthDates.push(earliestProjectStart);
            loopCount++;
        }

        const monthWeekStartDates = allMonthDates
            .map((monthDate) => getMonthAllWeekStartDates(monthDate, 1))
            .flat();
        const allProjectActivities: ProjectWeeklyActivityExtended[] = siteMemberTimesheets
            .map((timesheet) =>
                monthWeekStartDates
                    .map((date) =>
                        getTimesheetProjectActivities(
                            timesheet,
                            date,
                            accountProjectMemberStatusDict,
                            projects
                        )
                    )
                    .flat()
                    .map(
                        (activity) =>
                            ({
                                ...activity,
                                timesheetId: timesheet.id,
                            } as ProjectWeeklyActivityExtended)
                    )
            )
            .flat()
            .filter(
                (activity) =>
                    activity.approvalState !== ApprovalState.Approved &&
                    isBefore(activity.weekStartDate, currentWeekStartDate)
            );

        allProjectActivities.forEach((activity) => {
            const weekActivities = allProjectActivities.filter((a) =>
                isSameDay(activity.weekStartDate, a.weekStartDate)
            );
            const status = getTimesheetWeekStatus(weekActivities);
            const project = projects.find((p) => p.id === activity.projectId);
            const timesheet = timesheets.find((ts) => ts.id === activity.timesheetId);
            const siteMember = siteMembers.find((sm) => sm.id === timesheet.siteMemberId);
            const weekRecord: OutstandingTimesheetReportRecord = {
                id: generateUUID(),
                siteMember,
                projectName: project?.name,
                startDate: activity.weekStartDate,
                endDate: addDays(activity.weekStartDate, 6),
                status,
            };
            records.push(weekRecord);
        });

        return records;
    }
);
