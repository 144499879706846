import { Action, createAction, props } from '@ngrx/store';
import { SiteIdPayload } from '../site/site.payloads';
import { OptimisticableActionOptions } from './../shared/optimisticable-action';
import { LayoutSettings, ProgramLayoutSettings, ProjectLayoutSettings } from './models';
import {
    PatchLayoutSettingsPayload,
    UpdateLayoutSettingsPayload,
} from './payloads/layout.payloads';
import {
    ChangeRaidRecordViewModePayload,
    UpdateProjectTreeLayoutPayload,
} from './payloads/update-layout.payload';

export const loadLayoutSettings = createAction(
    '[Layout] Load settings',
    props<{ payload: SiteIdPayload }>()
);

export const loadLayoutSettingsSuccess = createAction(
    '[Layout] Load settings success',
    props<LayoutSettings>()
);

export const loadLayoutSettingsFail = createAction(
    '[Layout] Load settings fail',
    props<{ error: any }>()
);

export const openSidebar = createAction('[Layout] Open sidebar');

export const closeSidebar = createAction('[Layout] Close sidebar');

export const setSidebarAnimationCompleted = createAction(
    '[Layout] Sidebar animation completed',
    props<{ opened: boolean }>()
);

export const setPageName = createAction('[Layout] Set page name', props<{ name: string }>());

export const toggleCtxEditorAutoSave = createAction('[Layout] Toggle ctx editor auto save');

export const updateMinutesPageSplitAreaSize = createAction(
    '[Layout] Update minutes page splitter size',
    props<{ size: number }>()
);

export const updateStatusPageSplitAreaSize = createAction(
    '[Layout] Update status page splitter size',
    props<{ size: number }>()
);

// Update project tree layout action

export const updateProjectTreeLayout = createAction(
    '[Layout] Update project tree',
    props<{ payload: UpdateProjectTreeLayoutPayload; options?: OptimisticableActionOptions }>()
);

// Update project settings

export const updateCurrentProjectLayoutSettings = createAction(
    '[Layout] Update current project layout setting',
    props<{ payload: Partial<ProjectLayoutSettings> }>()
);

// Update project raid settings

export const changeCurrentProjectRaidRecordViewMode = createAction(
    '[Layout] Change raid record view mode',
    props<{ payload: ChangeRaidRecordViewModePayload; options?: OptimisticableActionOptions }>()
);

// Update program settings

export const updateCurrentProgramLayoutSettings = createAction(
    '[Layout] Update current program layout setting',
    props<{ payload: Partial<ProgramLayoutSettings> }>()
);

// Update layout settings actions

export const updateLayoutSettings = createAction(
    '[Layout] Update',
    props<{ payload: UpdateLayoutSettingsPayload; options?: OptimisticableActionOptions }>()
);

// Patch layout settings
export const patchLayoutSettings = createAction(
    '[Layout] Patch settings',
    props<{ payload: PatchLayoutSettingsPayload; options?: OptimisticableActionOptions }>()
);

export const patchLayoutSettingsSuccess = createAction(
    '[Layout] Patch settings success',
    props<LayoutSettings>()
);

export const patchLayoutSettingsFail = createAction(
    '[Layout] Patch settings fail',
    props<{ error: any; originAction: Action }>()
);
