import { JsonProperty } from 'app/utils/json-mapper';
import { Model } from '../../shared/models/base.model';

export class Folder extends Model {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('projectId')
    projectId: string = undefined;

    @JsonProperty('parentId')
    parentId?: string;

    @JsonProperty('name')
    name: string = undefined;

    @JsonProperty('tag')
    tag: FolderTag = undefined;

    constructor(init?: Partial<Folder>) {
        super();
        Object.assign(this, init);
    }
}

export enum FolderTag {
    Minutes = 'Minutes',
}
