import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EntityPatchSuccessResponse } from 'app/core/services/rest-api/features/patch.response';
import { ProgramPermissions } from 'app/core/store/shared/models/shared-permissions.model';
import { getPatch } from 'app/utils/json-patch';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { ProgramSelectors } from '.';
import { AppStore } from '..';
import { RestApiService } from '../../services/rest-api';
import { ProgramResponses } from '../../services/rest-api/features/program';
import { okEmptyAction } from '../project/project.actions';
import { selectAccountSiteMembership } from '../shared/account-mebership.selectors';
import { Model } from '../shared/models/base.model';
import * as ProgramActions from './program.actions';
import { Program } from './program.model';

@Injectable()
export class ProgramsEffects {
    loadPrograms$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramActions.loadPrograms),
            switchMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: ProgramResponses.GetProgramsResponse) =>
                        response.payload.map((dto) => Model.createFromDto(Program, dto))
                    ),
                    map((programs: Program[]) => ProgramActions.loadProgramsSuccess({ programs })),
                    catchError((error: any) => of(ProgramActions.loadProgramsFail({ error })))
                )
            )
        )
    );

    getProgramById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramActions.getProgramById),
            mergeMap((action) => {
                return this.restApiService.process(action).pipe(
                    map((response: ProgramResponses.GetProgramByIdResponse) =>
                        Model.createFromDto(Program, response.payload)
                    ),
                    map((program: Program) => ProgramActions.getProgramByIdSuccess({ program })),
                    catchError((error: any) => of(ProgramActions.getProgramByIdFail({ error })))
                );
            })
        )
    );

    getProgramPermissions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramActions.getProgramPermissions),
            mergeMap((action) => {
                return this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        ProgramActions.getProgramPermissionsSuccess({
                            permissions: response.payload as ProgramPermissions,
                        })
                    ),
                    catchError((error: any) =>
                        of(ProgramActions.getProgramPermissionsFail({ error }))
                    )
                );
            })
        )
    );

    getInitialProgram$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramActions.getInitialProgram),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    switchMap((response: ProgramResponses.GetInitialProgramResponse) => {
                        if (!response.payload) {
                            return [ProgramActions.setInitialProgramLoaded({ programId: null })];
                        }

                        return [
                            ProgramActions.getProgramByIdSuccess({
                                program: Model.createFromDto(Program, response.payload.program),
                            }),
                            ProgramActions.getProgramPermissionsSuccess({
                                permissions: response.payload.permissions as ProgramPermissions,
                            }),
                            ProgramActions.setInitialProgramLoaded({
                                programId: response.payload.program.id,
                            }),
                        ];
                    }),
                    catchError((error: any) => of(ProgramActions.getInitialProgramFail({ error })))
                )
            )
        )
    );

    addProgram$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramActions.addProgram),
            mergeMap((action) => {
                return this.restApiService.process(action).pipe(
                    withLatestFrom(this.store$.select(selectAccountSiteMembership)),
                    map(([response, account]) => {
                        const program = Model.createFromDto(Program, {
                            ...response.payload,
                            managerIds: [account.id],
                        });
                        return ProgramActions.addProgramSuccess({ program });
                    }),
                    catchError((error: any) => of(ProgramActions.addProgramFail({ error })))
                );
            })
        )
    );

    updateProgram$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramActions.updateProgram),
            withLatestFrom(this.store$.select(ProgramSelectors.selectAll)),
            mergeMap(([action, programs]) => {
                const updatingProgram = programs.find((p) => p.id === action.payload.programId);
                const patch = getPatch(updatingProgram, action.payload.changes);
                return of(
                    ProgramActions.patchProgram({
                        payload: { programId: action.payload.programId, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchProgram$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramActions.patchProgram),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(
                        (response: EntityPatchSuccessResponse) => {
                            if (action.options?.optimistic) return okEmptyAction();
                            return ProgramActions.patchProgramSuccess({
                                programId: action.payload.programId,
                                patch: response.payload,
                            });
                        },
                        catchError((error) =>
                            of(ProgramActions.patchProgramFail({ error, originAction: action }))
                        )
                    )
                )
            )
        )
    );

    deleteProgram$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramActions.deleteProgram),
            mergeMap((action) => {
                return this.restApiService.process(action).pipe(
                    map(() => ProgramActions.deleteProgramSuccess(action)),
                    catchError((error: any) => of(ProgramActions.deleteProgramFail({ error })))
                );
            })
        )
    );

    constructor(
        private store$: Store<AppStore.AppState>,
        private actions$: Actions,
        private restApiService: RestApiService
    ) {}
}
