import { HttpTransportType } from '@microsoft/signalr';
import { EnvironmentConfig } from './environment.interface';
import { QuadernoTestPublicKey } from './quaderno-keys';
import { StripeTestPublicKey } from './stripe-keys';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular.json`.

export const environment: EnvironmentConfig = {
    name: 'test',
    production: false,

    authConfig: {
        clientID: 'Z8oRFOH2JXXzc2urn3iMsOr4Gr9NTHh1',
        domain: 'completixtest.auth0.com',
        audience: 'https://apitest.completix.com',
        jwtWhitelistedDomains: [
            'apitest.completix.com',
            'completixapi-test.azurewebsites.net',
            'completixapitest.azurewebsites.net',
            'localhost:8080',
        ],
        jwtBlacklistedRoutes: [],
        redirectUri: 'https://apptest.completix.com/',
    },

    stytchConfig: {
        googleOAuthStartUrl:
            'https://test.stytch.com/v1/public/oauth/google/start?public_token=public-token-test-5690e12f-14f6-488e-b901-fa5832db3be2',
        microsoftOAuthStartUrl:
            'https://test.stytch.com/v1/public/oauth/microsoft/start?public_token=public-token-test-5690e12f-14f6-488e-b901-fa5832db3be2',
    },

    restApiConfig: {
        baseUrl: 'https://apitest.completix.com/v1',
    },

    signalRApiConfig: {
        baseUrl: 'https://apitest.completix.com/v1/hubs',
        connectionOptions: { skipNegotiation: false, transport: HttpTransportType.None },
    },

    stripeConfig: {
        publicKey: StripeTestPublicKey,
    },

    quadernoConfig: {
        publickKey: QuadernoTestPublicKey,
    },
};
