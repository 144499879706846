import { ActionTypes, UnionAction } from './app-operation.actions';
import { initialState, State } from './app-operation.store';

export function reducer(state: State = initialState, action: UnionAction): State {
    switch (action.type) {
        case ActionTypes.SetReady: {
            return { ...state, isReady: true };
        }
        case ActionTypes.SetNotReady: {
            return { ...state, isReady: false };
        }
        case ActionTypes.SetApiServerError: {
            return { ...state, apiServerError: action.payload.error };
        }
        case ActionTypes.ClearApiServerError: {
            return { ...state, apiServerError: null };
        }
        case ActionTypes.UserNotAuthorizedResponseReceived: {
            return { ...state, userNotAuthorizedResponseReceived: true };
        }
        case ActionTypes.SetAuthenticationRedirectUrl: {
            return { ...state, authenticationRedirectUrl: action.payload.url };
        }
        default: {
            return state;
        }
    }
}
