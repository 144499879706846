import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import { ProgramIdPayload } from '../../../../store/program/program.payloads';
import {
    LoadByProjectIdPayload,
    LoadBySiteIdPayload,
} from '../../../../store/shared/models/load.payload';

export const loadUserLogsBySiteId = (payload: LoadBySiteIdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'logs' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadUserLogsByProgramId = (payload: ProgramIdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'programs', value: payload.programId }, { name: 'logs' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadUserLogsByProjectId = (
    payload: LoadByProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projects', value: payload.projectId }, { name: 'logs' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};
