import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { AsyncUpdateStatus, AsyncUpdateStatusDefault } from '../shared/async-update-status.store';
import { ProgramPermissions } from '../shared/models/shared-permissions.model';
import { Program } from './program.model';

export interface State extends EntityState<Program>, AsyncUpdateStatus {
    currentProgramId: string;
    currentProgramPermissions: ProgramPermissions;
    initialProgramId: string;
}

export const adapter = createEntityAdapter<Program>();

export const initialState: State = adapter.getInitialState({
    ...AsyncUpdateStatusDefault,
    currentProgramId: null,
    currentProgramPermissions: null,
    initialProgramId: undefined,
});
