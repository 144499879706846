import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StateEntry } from '../state-entry';
import { adapter, LoadingStatus, State } from './handsontable-settings.store';
import { HandsontableSettingsOriginType } from './models/handsontable-settings.model';

export const selectReminderEntry = createFeatureSelector<State>(StateEntry.HandsontableSettings);

export const selectLoadingStatus = createSelector(
    selectReminderEntry,
    (state): LoadingStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
    })
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectReminderEntry);

export const selectSettingsByOriginType = (
    projectId: string,
    type: HandsontableSettingsOriginType
) =>
    createSelector(selectAll, (settingsList) =>
        settingsList.find(
            (setting) => setting.projectId === projectId && setting.originTableType === type
        )
    );

export const selectSettingsByOriginId = (id: string) =>
    createSelector(selectAll, (settingsList) =>
        settingsList.find((setting) => setting.originTableId === id)
    );
