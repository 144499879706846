import { ImportSystemTemplatePayload } from 'app/core/models/shared-models';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import {
    AddProjectTemplatePayload,
    PatchProjectTemplatePayload,
    ProjectTemplateIdPayload,
} from './../../../../store/project-template/project-template.payloads';
import { LoadBySiteIdPayload } from './../../../../store/shared/models/load.payload';

export const loadProjectTemplatesCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'projectTemplates' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadCompletixProjectTemplatesCommand = (payload: any, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projectTemplates' }, { name: 'completixTemplates' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadCompletixProjectTemplatesBySiteIdCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'projectTemplates' },
        { name: 'completixTemplates' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const importProjectTemplateCommand = (
    payload: ImportSystemTemplatePayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectTemplates' }, { name: 'completixTemplates/import' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const addProjectTemplateCommand = (
    payload: AddProjectTemplatePayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectTemplates' }]);
    baseCommand.payload.setData(payload.createOptions);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchProjectTemplateCommand = (
    payload: PatchProjectTemplatePayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectTemplates', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteProjectTemplateCommand = (
    payload: ProjectTemplateIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectTemplates', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};
