import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppStore } from 'app/core/store';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthenticationPageGuardService implements CanLoad {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private store: Store<AppStore.AppState>
    ) {}

    async canLoad(route: Route, segments: UrlSegment[]) {
        const authenticated = await this.authenticationService.sessionIsAuthenticated();
        if (authenticated) {
            this.router.navigate([]);
            return false;
        }

        return true;
    }
}
