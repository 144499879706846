import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Site, SiteMetrics } from './models';

export interface State extends EntityState<Site>, LoadingStatus {
    currentSiteId: string;
    siteSettingsLoading: boolean;
    currentSiteMetrics: SiteMetrics;
    siteMetricsLoading: boolean;
    signalrConnectionId: string;
    initialSiteId: string;
}

export interface LoadingStatus {
    serverRequestInProgress: boolean;
    loaded: boolean;
}

export const adapter: EntityAdapter<Site> = createEntityAdapter<Site>();

export const initialState: State = adapter.getInitialState({
    serverRequestInProgress: false,
    loaded: false,
    currentSiteId: null,
    siteSettingsLoading: false,
    currentSiteMetrics: null,
    siteMetricsLoading: false,
    signalrConnectionId: null,
    initialSiteId: undefined,
});
