import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { AppStore } from 'app/core/store';
import { SiteSelectors } from 'app/core/store/site';
import { AsyncService, JsonPayload, RestfulCommand, RestfulGateway } from 'app/utils/network';
import { combineLatest, Observable, Observer } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { CommandBuilderFactory } from './command-builder-factory';

@Injectable()
export class RestApiService extends AsyncService {
    private factory = new CommandBuilderFactory();
    private currentSiteId$ = this.store.select(SiteSelectors.selectCurrentSiteId);

    constructor(private restfulGateway: RestfulGateway, private store: Store<AppStore.AppState>) {
        super();
    }

    process(action: Action): Observable<any> {
        const baseCommand = new RestfulCommand();
        baseCommand.payload = new JsonPayload();
        baseCommand.gateway = this.restfulGateway;

        const commandBuilderWithPayload = this.factory.getCommandBuilderWithPayload(action);
        if (!commandBuilderWithPayload) {
            console.warn('This command is not supported');
            return Observable.create((obs: Observer<any>) => obs.complete());
        }

        return combineLatest([
            this.currentSiteId$,
            this.store.select(SiteSelectors.selectSignalrConnectionId),
        ]).pipe(
            take(1),
            switchMap(([siteId, signalrConnectionId]) => {
                baseCommand.siteId = siteId;
                baseCommand.siteHubConnectionId = signalrConnectionId;
                return commandBuilderWithPayload(baseCommand).invoke();
            })
        );
    }
}
