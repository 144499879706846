import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { EntityLevel } from 'app/core/models/shared-models';
import { getPatched } from 'app/utils/json-patch';
import { deepClone } from 'fast-json-patch';
import {
    AsyncUpdateStatus,
    AsyncUpdateStatusDefault,
    AsyncUpdateStatusDefaultLoad,
    AsyncUpdateStatusDefaultLoadSuccess,
} from '../shared/async-update-status.store';
import * as ListContainerActions from './list-container.actions';
import { adapter, initialState } from './list-container.store';
import { ListContainer } from './models';

export const reducer = createReducer(
    initialState,

    on(ListContainerActions.loadSystemListContainers, (state) => ({
        ...state,
        systemTemplatesLoadingStatus: {
            ...state.systemTemplatesLoadingStatus,
            ...AsyncUpdateStatusDefaultLoad,
        },
    })),

    on(ListContainerActions.loadSystemListContainersSuccess, (state, payload) => {
        const existingContainers = Object.values(state.entities).filter(
            (c) => c.scope.level === EntityLevel.System
        );
        const newState = adapter.addMany(
            payload.containers,
            adapter.removeMany(
                existingContainers.map((c) => c.id),
                state
            )
        );
        return {
            ...newState,
            systemTemplatesLoadingStatus: {
                ...state.systemTemplatesLoadingStatus,
                ...AsyncUpdateStatusDefaultLoadSuccess,
            },
        };
    }),

    on(ListContainerActions.loadSystemListContainersFail, (state, payload) => ({
        ...state,
        systemTemplatesLoadingStatus: {
            ...state.systemTemplatesLoadingStatus,
            ...AsyncUpdateStatusDefault,
            loadingFailed: true,
            error: payload.error,
        },
    })),

    on(ListContainerActions.loadSystemListContainersBySiteIdSuccess, (state, payload) => {
        const existingContainers = Object.values(state.entities).filter((c) =>
            payload.containers.some((pc) => c.id === pc.id)
        );
        const newState = adapter.addMany(
            payload.containers,
            adapter.removeMany(
                existingContainers.map((c) => c.id),
                state
            )
        );
        const loadingStatusBySiteMap = state.loadingStatusBySiteId;
        const siteId = payload.siteId;

        return {
            ...newState,
            loadingStatusBySiteId: {
                ...loadingStatusBySiteMap,
                [siteId]: {
                    ...(loadingStatusBySiteMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                },
            },
        };
    }),

    on(ListContainerActions.loadListContainersBySiteId, (state, action) => {
        const loadingStatusMap = state.loadingStatusBySiteId;
        const siteId = action.payload.siteId;

        return {
            ...state,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: { ...(loadingStatusMap[siteId] ?? {}), ...AsyncUpdateStatusDefaultLoad },
            },
        };
    }),

    on(ListContainerActions.loadListContainersBySiteIdSuccess, (state, payload) => {
        const existingContainers = Object.values(state.entities).filter(
            (c) =>
                c.scope.level === EntityLevel.Site ||
                c.scope.level === EntityLevel.Project ||
                c.scope.level === EntityLevel.Program
        );
        const newState = adapter.addMany(
            payload.containers,
            adapter.removeMany(
                existingContainers.map((c) => c.id),
                state
            )
        );
        const loadingStatusMap = state.loadingStatusBySiteId;
        const siteId = payload.siteId;

        const loadingByProjectStatusMap: Record<string, AsyncUpdateStatus> = deepClone(
            state.loadingStatusByProjectId
        );
        const projectIds = payload.containers
            .filter((c) => c.scope.level === EntityLevel.Project)
            .map((c) => c.scope.id);
        projectIds.forEach(
            (id) =>
                (loadingByProjectStatusMap[id] = {
                    ...(loadingByProjectStatusMap[id] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                })
        );

        return {
            ...newState,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                },
            },
            loadingStatusByProjectId: loadingByProjectStatusMap,
        };
    }),

    on(ListContainerActions.loadListContainersBySiteIdFail, (state, payload) => {
        const loadingStatusMap = state.loadingStatusBySiteId;
        const siteId = payload.siteId;

        return {
            ...state,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoad,
                    error: payload.error,
                },
            },
        };
    }),

    on(ListContainerActions.loadListContainersByProjectId, (state, action) => {
        const loadingStatusMap = state.loadingStatusByProjectId;
        const projectId = action.payload.projectId;

        return {
            ...state,
            loadingStatusByProjectId: {
                ...loadingStatusMap,
                [projectId]: {
                    ...(loadingStatusMap[projectId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoad,
                },
            },
        };
    }),

    on(ListContainerActions.loadListContainersByProjectIdSuccess, (state, payload) => {
        const existingListContainers = Object.values(state.entities).filter(
            (c) => c.scope.id === payload.projectId && c.scope.level === EntityLevel.Project
        );
        const stateWithoutExisting = !existingListContainers
            ? state
            : adapter.removeMany(
                  existingListContainers.map((c) => c.id),
                  state
              );
        const newState = adapter.addMany(payload.containers, stateWithoutExisting);
        const loadingStatusMap = state.loadingStatusByProjectId;
        const projectId = payload.projectId;
        return {
            ...newState,
            loadingStatusByProjectId: {
                ...loadingStatusMap,
                [projectId]: {
                    ...(loadingStatusMap[projectId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                },
            },
        };
    }),

    on(ListContainerActions.loadListContainersByProjectIdFail, (state, payload) => {
        const loadingStatusMap = state.loadingStatusByProjectId;
        const projectId = payload.projectId;

        return {
            ...state,
            loadingStatusByProjectId: {
                ...loadingStatusMap,
                [projectId]: {
                    ...(loadingStatusMap[projectId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoad,
                    loadingFailed: true,
                    error: payload.error,
                },
            },
        };
    }),

    on(ListContainerActions.getListContainerByIdSuccess, (state, payload) =>
        adapter.addOne(payload.listContainer, adapter.removeOne(payload.listContainer.id, state))
    ),

    on(ListContainerActions.addListContainerSuccess, (state, payload) =>
        adapter.addOne(payload.container, state)
    ),

    on(ListContainerActions.addListContainerFail, (state) => ({ ...state })),

    on(ListContainerActions.patchListContainer, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patchedContainer = getPatched(
            state.entities[action.payload.id],
            action.payload.patch
        );
        const update: Update<ListContainer> = {
            id: patchedContainer.id,
            changes: patchedContainer,
        };
        return adapter.updateOne(update, state);
    }),

    on(ListContainerActions.patchListContainerSuccess, (state, payload) => {
        const patchedContainer = getPatched(state.entities[payload.id], payload.patch);
        const update: Update<ListContainer> = {
            id: patchedContainer.id,
            changes: patchedContainer,
        };
        return adapter.updateOne(update, state);
    }),

    on(ListContainerActions.patchListContainerFail, (state) => ({ ...state })),

    on(ListContainerActions.deleteListContainer, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(ListContainerActions.deleteListContainerSuccess, (state, payload) =>
        adapter.removeOne(payload.id, state)
    ),

    on(ListContainerActions.deleteListContainerFail, (state) => ({ ...state })),

    on(ListContainerActions.replaceListContainer, (state, payload) => {
        const existingContainer = Object.values(state.entities).find(
            (c) =>
                c.containerType === payload.container.containerType &&
                c.scope.id === payload.container.scope.id &&
                c.scope.level === payload.container.scope.level
        );
        return adapter.addOne(payload.container, adapter.removeOne(existingContainer?.id, state));
    })
);
