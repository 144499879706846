import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Reminder } from './models';

export interface LoadingStatus {
    serverRequestInProgress: boolean;
    loaded: boolean;
}

export interface State extends EntityState<Reminder>, LoadingStatus {}

export const adapter: EntityAdapter<Reminder> = createEntityAdapter<Reminder>();

export const initialState: State = adapter.getInitialState({
    serverRequestInProgress: false,
    loaded: false,
});
