import { Model } from '../../shared/models/base.model';
import { FileNodeForbiddenAction, FileNodeSystemTag } from '../document.store';

export class FileNodeDto extends Model {
    id: string;
    siteId?: string;
    projectId?: string;
    programId?: string;
    parentFolderId?: string;
    originObjectId?: string;
    systemTag?: FileNodeSystemTag;
    level?: DocumentLevel;
    name: string;
    ctxType?: string;
    extension?: string;
    ownerSiteMemberId?: string;
    lastModified: Date;
    size?: number;
    ctxTextContent?: string;
    starred?: boolean;
    isReadonly?: boolean;
    isPrivate?: boolean;
    isTemplate?: boolean;
    isCompletixTemplate?: boolean;
    isPublished?: boolean;
    isInitialTemplate?: boolean;
    forbiddenActions?: FileNodeForbiddenAction;

    versions: FileNodeVersion[];

    constructor(init?: Partial<FileNode>) {
        super();
        Object.assign(this, init);
    }
}

export class FileNode extends FileNodeDto {
    ownerName?: string;

    constructor(init?: Partial<FileNode>) {
        super();
        Object.assign(this, init);
    }
}

export interface FileNodeVersion {
    id: string;
    date: Date;
    name: string;
    modifiedSiteMemberId: string;
    ctxType?: FileNodeType;
    ctxTextContent?: string;
}

export enum DocumentLevel {
    Site = 'Site',
    Project = 'Project',
    Program = 'Program',
    Completix = 'Completix',
}

export interface DocumentCreateOptions {
    id?: string;
    parentFolderId?: string;
    siteId?: string;
    projectId?: string;
    programId?: string;
    level: DocumentLevel;
    ownerSiteMemberId: string;
    originObjectId?: string;
    parentSystemTag?: FileNodeSystemTag;
    name?: string;
    ctxType?: string;
    ctxTextContent?: string;
    starred?: boolean;
    isReadonly?: boolean;
    isPrivate?: boolean;
    isTemplate?: boolean;
    document?: Blob;
    isCompletixTemplate?: boolean;
    templateDocumentId?: string;
}

export interface ProjectDocumentCreateOptions extends DocumentCreateOptions {
    projectId: string;
}

export interface SiteDocumentCreateOptions extends DocumentCreateOptions {
    siteId: string;
}

export interface DocumentCopyCutCreateOptions {
    documentId: string;
}

export enum FileNodeType {
    Folder = 'CtxFolder',
    Root = 'root',
    CtxDoc = 'CtxDoc',
    CtxXls = 'CtxXls',
    CtxMinute = 'CtxMin',
    Doc = '.doc',
    Docx = '.docx',
    Xls = '.xls',
    Xlsx = '.xlsx',
    Csv = '.csv',
    Png = '.png',
    Jpg = '.jpg',
    Jpeg = '.jpeg',
    Pdf = '.pdf',
    Ppt = '.ppt',
    Pptx = '.pptx',
    Txt = '.txt',
    Pst = '.pst',
    SimpleText = 'simple.text',
}

export interface DocumentCopyCutOperation {
    ids: string[];
    operationType: CopyCutOperationType;
}

export enum CopyCutOperationType {
    Copy = 'copy',
    Cut = 'cut',
}

export interface DocumentCopyCutEvent {
    nodeIds: string[];
    event: CopyCutOperationType;
}

export function getDocumentExtension(node: FileNode): string {
    switch (node.ctxType) {
        case FileNodeType.Folder:
            return '';

        case FileNodeType.CtxDoc:
            return `.${node.ctxType.toLowerCase()}`;

        case FileNodeType.CtxXls:
            return `.${node.ctxType.toLowerCase()}`;

        default:
            return node.extension;
    }
}

export const ctxDocumentExtensionsMap: Record<string, string> = {
    [FileNodeType.CtxDoc]: 'docx',
    [FileNodeType.CtxXls]: 'xlsx',
};

export const ctxDocumentTypes = [FileNodeType.CtxDoc, FileNodeType.CtxXls];

export const wordDocumentExtensions = [
    '.dotx',
    '.docx',
    '.docm',
    '.dotm',
    '.dot',
    '.doc',
    '.rtf',
    '.txt',
    '.xml',
];

export const xlsDocumentExtensions = ['.xlsx', '.xls', '.xlsm', '.xltm', '.xltx', '.csv'];

export function isDocumentConvertableToCtxFormat(document: FileNode): boolean {
    return [...wordDocumentExtensions, ...xlsDocumentExtensions].includes(document.extension);
}

export function getDocumentConvertCtxType(document: FileNode): FileNodeType {
    const isDocFile = wordDocumentExtensions.includes(document.extension);
    const isXlsFile = xlsDocumentExtensions.includes(document.extension);
    if (!isDocFile && !isXlsFile) {
        console.error('The extension of the document is not supported to convert');
        return;
    }
    const ctxType = isDocFile ? FileNodeType.CtxDoc : FileNodeType.CtxXls;
    return ctxType;
}
