import { Action, createAction, props } from '@ngrx/store';
import { ProgramPayloads } from '../program';
import { LoadByProjectIdPayload } from '../shared/models/load.payload';
import { ProjectPermissions } from '../shared/models/shared-permissions.model';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import { SitePayloads } from '../site';
import { Project, ProjectRaciTable, ProjectSettings } from './project.model';
import {
    AddProjectPayload,
    ImportMicrosoftProjectPayload,
    PatchProjectPayload,
    PatchProjectSettingsPayload,
    ProjectIdPayload,
    ToggleProjectPageLockPayload,
    UpdateProjectPayload,
    UpdateProjectSettingsPayload,
} from './project.payloads';

// Get projects by program actions

export const getProjectsByProgram = createAction(
    '[Projects] Get by program',
    props<{ payload: ProgramPayloads.ProgramIdPayload }>()
);

export const getProjectsByProgramSuccess = createAction(
    '[Projects] Get by program success',
    props<{ projects: Project[] }>()
);

export const getProjectsByProgramFail = createAction(
    '[Projects] Get by program fail',
    props<{ error: any }>()
);

// Get projects by site actions

export const getProjectsBySite = createAction(
    '[Projects] Get by site',
    props<{ payload: SitePayloads.SiteIdPayload }>()
);

export const getProjectsBySiteSuccess = createAction(
    '[Projects] Get by site success',
    props<{ projects: Project[] }>()
);

export const getProjectsBySiteFail = createAction(
    '[Projects] Get by site fail',
    props<{ error: any }>()
);

// Get project by di

export const getProject = createAction(
    '[Projects] Get project by id',
    props<{ payload: ProjectIdPayload }>()
);

export const getProjectSuccess = createAction(
    '[Projects] Get project by id success',
    props<{ project: Project }>()
);

export const getProjectFail = createAction(
    '[Projects] Get project by id fail',
    props<{ error: any }>()
);

// Get initial project

export const getInitialProject = createAction(
    '[Projects] Get initial project',
    props<{ payload: ProjectIdPayload }>()
);

export const getInitialProjectFail = createAction(
    '[Projects] Get initial project fail',
    props<{ error: any }>()
);

export const setInitialProjectLoaded = createAction(
    '[Site] Set initial project loaded',
    props<ProjectIdPayload>()
);

// Get project settings

export const getProjectSettings = createAction(
    '[Projects] Get settings',
    props<{ payload: ProjectIdPayload }>()
);

export const getProjectSettingsSuccess = createAction(
    '[Projects] Get settings success',
    props<{ settings: ProjectSettings }>()
);

export const getProjectSettingsFail = createAction(
    '[Projects] Get settings fail',
    props<{ error: any; projectId: string }>()
);

// Get project permissions

export const getProjectPermissions = createAction(
    '[Projects] Get project permissions',
    props<{ payload: ProjectIdPayload }>()
);

export const getProjectPermissionsSuccess = createAction(
    '[Projects] Get project permissions success',
    props<{ permissions: ProjectPermissions }>()
);

export const getProjectPermissionsFail = createAction(
    '[Projects] Get project permissions fail',
    props<{ error: any }>()
);

// Patch project settings

export const updateProjectSettings = createAction(
    '[Projects] Update settings',
    props<{ payload: UpdateProjectSettingsPayload; options?: OptimisticableActionOptions }>()
);

export const patchProjectSettings = createAction(
    '[Projects] Patch settings',
    props<{ payload: PatchProjectSettingsPayload; options?: OptimisticableActionOptions }>()
);

export const patchProjectSettingsSuccess = createAction(
    '[Projects] Patch settings success',
    props<PatchProjectSettingsPayload>()
);

export const patchProjectSettingsFail = createAction(
    '[Projects] Patch settings fail',
    props<{ error: any; originAction: Action }>()
);

export const toggleProjectPageLock = createAction(
    '[Projects] Toggle project page hide',
    props<{ payload: ToggleProjectPageLockPayload; options?: OptimisticableActionOptions }>()
);

// Add project actions

export const addProject = createAction('[Projects] Add', props<{ payload: AddProjectPayload }>());

export const addProjectSuccess = createAction(
    '[Projects] Add success',
    props<{ project: Project }>()
);

export const addProjectFail = createAction(
    '[Projects] Add from template fail',
    props<{ error: any }>()
);

// Update project actions

export const updateProject = createAction(
    '[Projects] Update',
    props<{ payload: UpdateProjectPayload; options?: OptimisticableActionOptions }>()
);

export const updateProjectSuccess = createAction(
    '[Projects] Update success',
    props<{ projectId: string; changes: Partial<Project> }>()
);

export const updateProjectFail = createAction(
    '[Projects] Update fail',
    props<{ error: any; originAction: Action }>()
);

// Patch project actions

export const patchProject = createAction(
    '[Project] Patch',
    props<{ payload: PatchProjectPayload; options?: OptimisticableActionOptions }>()
);

export const patchProjectSuccess = createAction(
    '[Project] Patch success',
    props<PatchProjectPayload>()
);

export const patchProjectFail = createAction(
    '[Project] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Archive project actions

export const archiveProject = createAction(
    '[Projects] Archive',
    props<{ payload: ProjectIdPayload }>()
);

export const archiveProjectSuccess = createAction(
    '[Projects] Archive success',
    props<{ project: Project }>()
);

export const archiveProjectFail = createAction('[Projects] Archive fail', props<{ error: any }>());

// Unarchive project actions

export const unarchiveProject = createAction(
    '[Projects] Unarchive',
    props<{ payload: ProjectIdPayload }>()
);

export const unarchiveProjectSuccess = createAction(
    '[Projects] Unarchive success',
    props<{ project: Project }>()
);

export const unarchiveProjectFail = createAction(
    '[Projects] Unarchive fail',
    props<{ error: any }>()
);

// Delete project actions

export const deleteProject = createAction(
    '[Projects] Delete',
    props<{ payload: ProjectIdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteProjectSuccess = createAction(
    '[Projects] Delete success',
    props<ProjectIdPayload>()
);

export const deleteProjectFail = createAction(
    '[Projects] Delete fail',
    props<{ error: any; originAction: Action }>()
);

// Current project actions

export const changeCurrentProjectId = createAction(
    '[Projects] Change current project ID',
    props<ProjectIdPayload>()
);

// Ok action

export const okEmptyAction = createAction('Ok');

// RACI actions

export const getRaciTableByProject = createAction(
    '[Projects] Get RACI by project',
    props<{ payload: LoadByProjectIdPayload }>()
);

export const getRaciTableByProjectSuccess = createAction(
    '[Projects] Get RACI by project success',
    props<{ table: ProjectRaciTable }>()
);

export const getRaciTableByProjectFail = createAction(
    '[Projects] Get RACI by project fail',
    props<{ error: any }>()
);

export const updateRaciTable = createAction(
    '[Projects] Update RACI table',
    props<{ payload: ProjectRaciTable; options?: OptimisticableActionOptions }>()
);

export const updateRaciTableSuccess = createAction(
    '[Projects] Update RACI table success',
    props<ProjectRaciTable>()
);

export const updateRaciTableFail = createAction(
    '[Projects] Update RACI table fail',
    props<{ error: any; originAction: Action }>()
);

// Import MS project

export const importMicrosoftProject = createAction(
    '[Projects] Import Microsoft project',
    props<{ payload: ImportMicrosoftProjectPayload }>()
);

export const importMicrosoftProjectFail = createAction(
    '[Projects] Import Microsoft project fail',
    props<{ error: any }>()
);
