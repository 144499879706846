import { createAction, props } from '@ngrx/store';
import { Subscription } from 'app/core/models/billing';
import { OptimisticableActionOptions } from 'app/core/store/shared/optimisticable-action';
import { AnyMxRecord } from 'dns';
import { SitePayloads } from '../site';
import {
    AddAdditionalSubscriptionsPayload,
    ChangeSubscriptionPayload,
    ChangeSubscriptionQuantityPayload,
    GetInvoicesPayload,
    PatchBillingDetailsPayload,
    RemoveAdditionalSubscriptionPayload,
} from './subscription.payloads';

// Get subscription by site id

export const getActiveSubscription = createAction(
    '[Subscription] Get active by site id',
    props<{ payload: SitePayloads.SiteIdPayload }>()
);

export const getActiveSubscriptionSuccess = createAction(
    '[Subscription] Get active by site id success',
    props<{ subscription: Subscription }>()
);

export const getActiveSubscriptionFail = createAction(
    '[Subscription] Get active by site id fail',
    props<{ error: AnyMxRecord }>()
);

// Change site subscription

export const changeSubscription = createAction(
    '[Subscription] Change',
    props<{ payload: ChangeSubscriptionPayload }>()
);

export const changeSubscriptionSuccess = createAction(
    '[Subscription] Change success',
    props<{ subscription: Subscription }>()
);

export const changeSubscriptionFail = createAction(
    '[Subscription] Change fail',
    props<{ error: any }>()
);

// Cancel subscription (unsubscribe)

export const cancelSubscription = createAction(
    '[Subscription] Cancel',
    props<{ payload: SitePayloads.SiteIdPayload }>()
);

export const cancelSubscriptionSuccess = createAction(
    '[Subscription] Cancel success',
    props<{ subscription: Subscription }>()
);

export const cancelSubscriptionFail = createAction(
    '[Subscription] Cancel fail',
    props<{ error: any }>()
);

// Patch billing details

export const patchBillingDetails = createAction(
    '[Subscription] Patch billing details',
    props<{ payload: PatchBillingDetailsPayload; options: OptimisticableActionOptions }>()
);

export const patchBillingDetailsSuccess = createAction(
    '[Subscription] Patch billing details success',
    props<{ subscription: Subscription }>()
);

export const patchBillingDetailsFail = createAction(
    '[Subscription] Patch billing details fail',
    props<{ error: any }>()
);

// Get quantities

export const getSubscriptionQuantities = createAction(
    '[Subscription] Get quantities',
    props<{ payload: SitePayloads.SiteIdPayload }>()
);

// Get invoices actions

export const getInvoices = createAction(
    '[Subscription] Get invoices',
    props<{ payload: GetInvoicesPayload }>()
);

export const getUpcomingInvoice = createAction(
    '[Subscription] Get upcoming invoice',
    props<{ payload: SitePayloads.SiteIdPayload }>()
);

// Is users limit reached

export const isUsersLimitReached = createAction(
    '[Subscription] Is users limit reached',
    props<{ payload: SitePayloads.SiteIdPayload }>()
);

// Finish subscription

export const finishSubscription = createAction('[Subscription] Finish subscription');

// Change site subscription quantity

export const changeSubscriptionQuantity = createAction(
    '[Subscription] Change quantity',
    props<{ payload: ChangeSubscriptionQuantityPayload }>()
);

export const changeSubscriptionQuantitySuccess = createAction(
    '[Subscription] Change quantity success',
    props<{ subscription: Subscription }>()
);

export const changeSubscriptionQuantityFail = createAction(
    '[Subscription] Change quantity fail',
    props<{ error: any }>()
);

// Add site additional subscriptions

export const addAdditionalSubscriptions = createAction(
    '[Subscription] Add site additional subscriptions',
    props<{ payload: AddAdditionalSubscriptionsPayload }>()
);

export const addAdditionalSubscriptionsSuccess = createAction(
    '[Subscription] Add site additional subscriptions success',
    props<{ subscription: Subscription }>()
);

export const addAdditionalSubscriptionsFail = createAction(
    '[Subscription] Add site additional subscriptions fail',
    props<{ error: any }>()
);

// Remove site additional subscriptions

export const removeAdditionalSubscription = createAction(
    '[Subscription] Remove site additional subscription',
    props<{ payload: RemoveAdditionalSubscriptionPayload }>()
);

export const removeAdditionalSubscriptionSuccess = createAction(
    '[Subscription] Remove site additional subscription success',
    props<{ subscription: Subscription }>()
);

export const removeAdditionalSubscriptionFail = createAction(
    '[Subscription] Remove site additional subscription fail',
    props<{ error: any }>()
);
