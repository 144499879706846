import {
    addDays,
    endOfMonth,
    format,
    Interval,
    isAfter,
    isBefore,
    isEqual,
    max,
    min,
    startOfMonth,
} from 'date-fns';
import addMonths from 'date-fns/addMonths';
import isSameDay from 'date-fns/isSameDay';
import isSameMonth from 'date-fns/isSameMonth';

export const ctxDefaultPersistDateFormat = 'yyyy-MM-dd';
export const ctxDefaultPersistDateTimeFormat = 'yyyy-MM-dd HH:mm:ss';
export const ctxDefaultVisualDateFormat = 'MM/dd/yyyy';

export function getFormattedDateString(date: Date): string {
    return format(date, ctxDefaultVisualDateFormat);
}

export function getEarlierDate(dates: Date[]): Date {
    return min(dates);
}

export function getLatestDate(dates: Date[]): Date {
    return max(dates);
}

export function ctxDateIntervalContainsInterval(
    outerIntervalStartDate: Date | string,
    outerIntervalEndDate: Date | string,
    innerIntervalStartDate: Date | string,
    innerIntervalEndDate: Date | string
): boolean {
    return (
        (isAfter(new Date(innerIntervalStartDate), new Date(outerIntervalStartDate)) ||
            isEqual(new Date(innerIntervalStartDate), new Date(outerIntervalStartDate))) &&
        (isBefore(new Date(innerIntervalEndDate), new Date(outerIntervalEndDate)) ||
            isEqual(new Date(innerIntervalEndDate), new Date(outerIntervalEndDate)))
    );
}

export function ctxDateIntevalContainsDate(date: Date | string, interval: Interval): boolean {
    const compareDate = new Date(date);
    return (
        (isAfter(compareDate, interval.start) || isEqual(compareDate, interval.start)) &&
        (isBefore(compareDate, interval.end) || isEqual(compareDate, interval.end))
    );
}

/**
 * Get an array of Date between 2 dates
 * @param start
 * @param end
 * @returns array of dates
 */
export function getDateArrayBetweenDates(
    start: Date,
    end: Date,
    includeStartEndDates: boolean = true
): Date[] {
    const array: Date[] = [];
    let startDate = new Date(start);
    const endDate = new Date(end);
    if (isBefore(endDate, startDate)) return array;
    let counter = 0;
    while (isBefore(startDate, endDate) || isEqual(startDate, endDate)) {
        if (counter > 5000) {
            console.error('The date range is too long (5000+)');
            return [];
        }
        array.push(startDate);
        startDate = addDays(startDate, 1);
        counter++;
    }
    return array.filter((date) =>
        includeStartEndDates ? true : !isSameDay(date, startDate) && !isSameDay(date, endDate)
    );
}

export function dateIsBetweenOrEqualDates(
    date: Date,
    from: string | Date,
    to: string | Date
): boolean {
    const fromDate = new Date(from);
    const toDate = new Date(to);
    return (
        isSameDay(date, fromDate) ||
        isSameDay(date, toDate) ||
        (isBefore(date, toDate) && isAfter(date, fromDate))
    );
}

export function dayIsAfter(date: Date, dateToCompare: Date) {
    return !isSameDay(date, dateToCompare) && isAfter(date, dateToCompare);
}

export function getMonthAllWeekStartDates(date: Date, weekStartDay: number = 1): Date[] {
    const monthStart = startOfMonth(date);
    const monthEnd = endOfMonth(date);

    const allMonthDates = getDateArrayBetweenDates(monthStart, monthEnd);
    const allWeekStartDates = allMonthDates.filter((date) => date.getDay() === weekStartDay);
    return allWeekStartDates;
}

export function getMonthsBetweenDates(start: Date, end: Date): Date[] {
    if (dayIsAfter(start, end)) {
        console.error('The start date connot be after the end date');
        return [];
    }

    const months: Date[] = [];
    for (
        let monthDate = startOfMonth(start);
        !isSameMonth(monthDate, end);
        monthDate = addMonths(monthDate, 1)
    ) {
        months.push(monthDate);
    }
    months.push(startOfMonth(end));

    return months;
}

export function getDateStringWithTime(dateString: string): string {
    if (dateString?.length !== 10) {
        console.error('Date string must contain only date value');
        return null;
    }

    return `${dateString}T00:00`;
}

export function getDateWithTime(dateString: string): Date {
    return new Date(getDateStringWithTime(dateString));
}
