import { Action, createAction, props } from '@ngrx/store';
import { LoadBySiteIdPayload } from '../shared/models/load.payload';
import { IdPayload } from '../shared/models/shared-payloads';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import { ProjectField } from './models';
import { PatchProjectFieldPayload, UpdateProjectFieldPayload } from './project-field.payloads';

// Load Project Fields actions

export const loadProjectFieldsBySiteId = createAction(
    '[Project Field] Load by site id',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadProjectFieldsBySiteIdSuccess = createAction(
    '[Project Field] Load by site id success',
    props<{ fields: ProjectField[] }>()
);

export const loadProjectFieldsFail = createAction(
    '[Project Field] Load fail',
    props<{ error: any }>()
);

// Load Project Fields by settings template actions

export const loadProjectFieldsByCompletixSettingsTemplate = createAction(
    '[Project Field] Load by completix settings template',
    props<{ payload: IdPayload }>()
);

export const loadProjectFieldsByCompletixSettingsTemplateSuccess = createAction(
    '[Project Field] Load by completix settings template success',
    props<{ fields: ProjectField[] }>()
);

export const loadProjectFieldsByCompletixSettingsTemplateFail = createAction(
    '[Project Field] Load by completix settings template fail',
    props<{ error: any; id: string }>()
);

// Update Project Field actions

export const updateProjectField = createAction(
    '[Project Field] Update',
    props<{ payload: UpdateProjectFieldPayload; options?: OptimisticableActionOptions }>()
);

export const patchProjectField = createAction(
    '[Project Field] Patch',
    props<{ payload: PatchProjectFieldPayload; options?: OptimisticableActionOptions }>()
);

export const patchProjectFieldSuccess = createAction(
    '[Project Field] Patch success',
    props<PatchProjectFieldPayload>()
);

export const patchProjectFielddFail = createAction(
    '[Project Field] Patch fail',
    props<{ error: any; originAction: Action }>()
);
