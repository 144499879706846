import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Subscription } from 'app/core/models/billing';

export interface State extends EntityState<Subscription> {
    subscriptionError: any;
    isSubscriptionSucceeded: boolean;
}

export const adapter: EntityAdapter<Subscription> = createEntityAdapter<Subscription>();

export const initialState: State = adapter.getInitialState({
    subscriptionError: null,
    isSubscriptionSucceeded: false,
});
