import { AsyncUpdateStatus } from '../shared/async-update-status.store';
import { LayoutSettings } from './models/layout.model';

export interface State extends AsyncUpdateStatus {
    pageName: string;
    layoutSettings: LayoutSettings;
    sidebarAnimationCompleted: { opened: boolean };
}

export const initialState: State = {
    layoutSettings: {
        ctxTextEditor: {},
        sidebar: {
            opened: true,
        },
        myDashboard: {},
        programsDashboard: {},
        projectsDashboard: {},
        portfoliosDashboard: {},
        managersDashboard: {},
        resourcesDashboard: {},
        programSummary: {},
        projectsLayoutSettings: [],
        programsLayoutSettings: [],
        myCalendar: {},
    },
    pageName: '',
    sidebarAnimationCompleted: null,
    loaded: null,
    serverRequestInProgress: null,
    loadingFailed: null,
    error: null,
};
