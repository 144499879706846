import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectSelectors } from '../project';
import { SiteSelectors } from '../site';
import { StateEntry } from '../state-entry';
import { Portfolio } from './models';
import { adapter, LoadingStatus, State } from './portfolio.store';

export const selectPortfolioEntry = createFeatureSelector<State>(StateEntry.Portfolio);

export const selectLoadingStatus = createSelector(
    selectPortfolioEntry,
    (state): LoadingStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
    })
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectPortfolioEntry);

export const selectCurrentSitePortfolios = createSelector(
    selectAll,
    SiteSelectors.selectCurrentSiteId,
    (portfolios: Portfolio[], siteId: string) => portfolios.filter((p) => p.siteId == siteId)
);

export const selectCurrentProjectPortfolios = createSelector(
    selectAll,
    ProjectSelectors.selectCurrentProjectId,
    (portfolios: Portfolio[], currentProjectId: string) =>
        portfolios.filter((p) => p.projectIds.some((id) => id === currentProjectId))
);
