import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProgramSelectors } from '../program';
import { ProjectSelectors } from '../project';
import { SiteSelectors } from '../site';
import { StateEntry } from '../state-entry';
import { UserLogLevel } from './models';
import { adapter, State } from './user-log.store';

export const selectUserLogEntry = createFeatureSelector<State>(StateEntry.UserLog);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectUserLogEntry);

export const selectLoadingStatus = createSelector(
    selectUserLogEntry,
    (state) => state.serverRequestInProgress
);

export const selectSortedByDateLogs = createSelector(selectAll, (logs) =>
    logs.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
);

export const selectCurrentSiteLogs = createSelector(
    selectSortedByDateLogs,
    SiteSelectors.selectCurrentSiteId,
    (logs, siteId) => logs.filter((l) => l.siteId === siteId)
);

export const selectCurrentProjectLogs = createSelector(
    selectSortedByDateLogs,
    ProjectSelectors.selectCurrentProjectId,
    (logs, projectId) =>
        logs.filter((l) => l.level === UserLogLevel.Project && l.originLevelObjectId === projectId)
);

export const selectCurrentProgramLogs = createSelector(
    selectSortedByDateLogs,
    ProgramSelectors.selectCurrentProgramId,
    (logs, programId) =>
        logs.filter((l) => l.level === UserLogLevel.Program && l.originLevelObjectId === programId)
);
