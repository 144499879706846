import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Calendar } from 'app/core/models/calendar';
import { getPatched } from 'app/utils/json-patch';
import * as CalendarActions from './calendar.actions';
import { adapter, initialState } from './calendar.store';

export const reducer = createReducer(
    initialState,

    on(CalendarActions.loadCompletixCalendarTemplates, (state) => ({
        ...state,
        completixCalendarTemplatesLoading: true,
    })),

    on(CalendarActions.loadCompletixCalendarTemplatesSuccess, (state, payload) => {
        const newState = adapter.addMany(
            payload.calendars,
            adapter.removeMany(
                payload.calendars.map((c) => c.id),
                state
            )
        );
        return { ...newState, completixCalendarTemplatesLoading: false };
    }),

    on(CalendarActions.loadCompletixCalendarTemplatesFail, (state) => ({
        ...state,
        completixCalendarTemplatesLoading: false,
    })),

    on(CalendarActions.loadCompletixCalendarTemplatesBySiteId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(CalendarActions.loadCalendarsBySiteId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(CalendarActions.loadCalendarsSuccess, (state, payload) => {
        const newState = adapter.addMany(
            payload.calendars,
            adapter.removeMany(
                payload.calendars.map((c) => c.id),
                state
            )
        );
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(CalendarActions.loadCalendarsFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(CalendarActions.loadCalendarByProjectId, (state) => ({
        ...state,
    })),

    on(CalendarActions.loadCalendarByProjectIdSuccess, (state, payload) => {
        const newState = adapter.addOne(
            payload.calendar,
            adapter.removeOne(payload.calendar.id, state)
        );
        return newState;
    }),

    on(CalendarActions.loadCalendarByProjectIdFail, (state) => ({
        ...state,
    })),

    on(CalendarActions.changeCalendarTemplateSuccess, (state, payload) => {
        const projectId = payload.calendar.projectId;
        const removedCalendar = Object.values(state.entities).find(
            (c) => c.projectId === projectId
        );
        const newState = removedCalendar ? adapter.removeOne(removedCalendar.id, state) : state;
        return adapter.addOne(payload.calendar, newState);
    }),

    on(CalendarActions.changeCalendarTemplateFail, (state) => state),

    on(CalendarActions.addCalendarSuccess, (state, payload) =>
        adapter.addOne(payload.calendar, state)
    ),

    on(CalendarActions.addCalendarFail, (state) => state),

    on(CalendarActions.patchCalendar, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patched = getPatched(state.entities[action.payload.id], action.payload.patch);
        const update: Update<Calendar> = {
            id: action.payload.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(CalendarActions.patchCalendarSuccess, (state, payload) => {
        const patched = getPatched(state.entities[payload.id], payload.patch);
        const update: Update<Calendar> = {
            id: payload.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(CalendarActions.patchCalendarFail, (state) => ({ ...state })),

    on(CalendarActions.deleteCalendar, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(CalendarActions.deleteCalendarSuccess, (state, payload) =>
        adapter.removeOne(payload.id, state)
    )
);
