import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { LoadingStatusByProjectId, LoadingStatusBySiteId } from 'app/core/models/shared-models';
import { KanbanBoardShape } from './models';

export interface State
    extends EntityState<KanbanBoardShape>,
        LoadingStatusByProjectId,
        LoadingStatusBySiteId {}

export const adapter: EntityAdapter<KanbanBoardShape> = createEntityAdapter<KanbanBoardShape>();

export const initialState: State = adapter.getInitialState({
    loadingStatusByProjectId: {},
    loadingStatusBySiteId: {},
});
