import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAccountSiteMembership } from '../shared/account-mebership.selectors';
import { getProjectRequestPermissionsMap } from '../shared/models/shared-permissions.model';
import { SiteSelectors } from '../site';
import { SiteRoleSelectors } from '../site-role';
import { StateEntry } from '../state-entry';
import { ProjectRequestStatus } from './models';
import { adapter, LoadingStatus, State } from './project-request.store';

export const selectProjectRequestEntry = createFeatureSelector<State>(StateEntry.ProjectRequest);

export const selectLoadingStatus = createSelector(
    selectProjectRequestEntry,
    (state): LoadingStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
    })
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectProjectRequestEntry);

export const selectCurrentSiteProjectRequests = createSelector(
    selectAll,
    SiteSelectors.selectCurrentSiteId,
    (requests, siteId) => requests.filter((r) => r.siteId === siteId)
);

export const selectCurrentSiteAccountProjectRequests = createSelector(
    selectCurrentSiteProjectRequests,
    selectAccountSiteMembership,
    (requests, siteMember) => requests.filter((r) => r.requesterSiteMemberId === siteMember.id)
);

export const selectExpandedIds = createSelector(
    selectProjectRequestEntry,
    (state) => state.expandedPrIds
);

export const selectProjectIntakes = createSelector(selectCurrentSiteProjectRequests, (requests) =>
    requests.filter((r) => r.submitted)
);

export const selectActiveProjectIntakes = createSelector(
    selectCurrentSiteProjectRequests,
    (requests) => requests.filter((r) => r.submitted && r.status !== ProjectRequestStatus.Rejected)
);

export const selectProjectrequestsPermissionsMap = createSelector(
    selectCurrentSiteProjectRequests,
    SiteRoleSelectors.selectCurrentSitePermissions,
    selectAccountSiteMembership,
    (requests, sitePermissions, siteMember) =>
        getProjectRequestPermissionsMap(requests, sitePermissions, siteMember)
);
