import { Action, createAction, props } from '@ngrx/store';
import { LoadBySiteIdPayload } from '../shared/models/load.payload';
import { IdPayload } from '../shared/models/shared-payloads';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import {
    AddBacklogTaskPayload,
    PatchBacklogTaskPayload,
    UpdateBacklogTaskPayload,
} from './backlog-task.payloads';
import { BacklogTask } from './models';

// Get risk actions

export const getBacklogTask = createAction(
    '[BacklogTask] Get BacklogTask',
    props<{ payload: IdPayload }>()
);

export const getBacklogTaskSuccess = createAction(
    '[BacklogTask] Get BacklogTask success',
    props<{ task: BacklogTask }>()
);

export const getBacklogTaskFail = createAction(
    '[BacklogTask] Get BacklogTask fail',
    props<{ error: any }>()
);

// Get backlogTasks by Site id actions

export const loadBacklogTasksByContainerId = createAction(
    '[BacklogTask] Load by container',
    props<{ payload: IdPayload }>()
);

export const loadBacklogTasksByContainerIdSuccess = createAction(
    '[BacklogTask] Load by container success',
    props<{ backlogTasks: BacklogTask[]; containerId: string }>()
);

export const loadBacklogTasksByContainerIdFail = createAction(
    '[BacklogTask] Load by container fail',
    props<{ error: any; containerId: string }>()
);

export const loadBacklogTasksBySiteId = createAction(
    '[BacklogTask] Load by site',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadBacklogTasksBySiteIdSuccess = createAction(
    '[BacklogTask] Load by site success',
    props<{ backlogTasks: BacklogTask[]; siteId: string; listContainerIds: string[] }>()
);

export const loadBacklogTasksBySiteIdFail = createAction(
    '[BacklogTask] Load by site fail',
    props<{ error: any; siteId: string }>()
);

// Add backlogTask actions

export const addBacklogTask = createAction(
    '[BacklogTask] Add',
    props<{ payload: AddBacklogTaskPayload; options?: OptimisticableActionOptions }>()
);

export const addBacklogTaskSuccess = createAction(
    '[BacklogTask] Add success',
    props<{ backlogTask: BacklogTask }>()
);

export const addBacklogTaskFail = createAction('[BacklogTask] Add fail', props<{ error: any }>());

// Update backlogTask actions

export const updateBacklogTask = createAction(
    '[BacklogTask] Update',
    props<{ payload: UpdateBacklogTaskPayload; options: OptimisticableActionOptions }>()
);

export const patchBacklogTask = createAction(
    '[BacklogTask] Patch',
    props<{ payload: PatchBacklogTaskPayload; options: OptimisticableActionOptions }>()
);

export const patchBacklogTaskSuccess = createAction(
    '[BacklogTask] Patch success',
    props<PatchBacklogTaskPayload>()
);

export const patchBacklogTaskFail = createAction(
    '[BacklogTask] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Delete backlogTask actions

export const deleteBacklogTask = createAction(
    '[BacklogTask] Delete',
    props<{ payload: IdPayload; options: OptimisticableActionOptions }>()
);

export const deleteBacklogTaskSuccess = createAction(
    '[BacklogTask] Delete success',
    props<IdPayload>()
);

export const deleteBacklogTaskFail = createAction(
    '[BacklogTask] Delete fail',
    props<{ error: any; originAction: Action }>()
);
