import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppStore } from 'app/core/store';
import { AppOperationActions } from 'app/core/store/app-operation';
import { selectAuthenticationRedirectUrl } from 'app/core/store/app-operation/app-operation.selectors';
import { RouteBrick } from 'app/route-brick';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, filter, first, take, withLatestFrom } from 'rxjs/operators';
import { environment } from './../../environments/environment';

const authenticationTokenKey = 'authentication_token';

@Injectable()
export class AuthenticationService {
    private errorMessage$ = new BehaviorSubject<string>(null);
    get errorMessageObservable$() {
        return this.errorMessage$.asObservable();
    }

    private serverRequestInProgress$ = new BehaviorSubject<boolean>(false);
    get serverRequestInProgressObservable$() {
        return this.serverRequestInProgress$.asObservable();
    }

    private passcodeSent$ = new BehaviorSubject<boolean>(false);
    get passcodeSentObservable$() {
        return this.passcodeSent$.asObservable();
    }

    formTitle$ = new BehaviorSubject<string>(null);
    processingEmail: string;
    googleOAuthLink = `${environment.stytchConfig.googleOAuthStartUrl}&login_redirect_url=${window.location.origin}/${RouteBrick.Authentication}/${RouteBrick.AuthenticateOAuth}`;
    microsoftOAuthLink = `${environment.stytchConfig.microsoftOAuthStartUrl}&login_redirect_url=${window.location.origin}/${RouteBrick.Authentication}/${RouteBrick.AuthenticateOAuth}`;

    private authenticationMethodId: string;

    constructor(
        private http: HttpClient,
        private router: Router,
        private store: Store<AppStore.AppState>
    ) {}

    resetAllCheckers() {
        this.resetServerRequestCheckers();
        this.resetPassCodeChecker();
        this.processingEmail = null;
    }

    logIn(email: string) {
        this.errorMessage$.next(null);
        this.serverRequestInProgress$.next(true);

        const request = this.http.post(environment.restApiConfig.baseUrl + '/accounts/logIn', {
            email,
        });

        request
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    this.errorMessage$.next(response.error.message);
                    this.serverRequestInProgress$.next(false);
                    return of(null);
                }),
                filter((res) => !!res)
            )
            .subscribe((result: string) => {
                this.authenticationMethodId = result;
                this.passcodeSent$.next(true);
                this.resetServerRequestCheckers();
                this.router.navigate([RouteBrick.Authentication, RouteBrick.AuthenticatePasscode]);
            });
    }

    logOut() {
        const request = this.http.post(
            environment.restApiConfig.baseUrl + '/accounts/revokeSession',
            null,
            { withCredentials: true }
        );
        request
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    this.serverRequestInProgress$.next(false);
                    return of(null);
                })
            )
            .subscribe(() => {
                this.clearAuthenticationToken();
                this.router.navigate([RouteBrick.Authentication]);
                this.router.events
                    .pipe(first((event) => event instanceof NavigationEnd))
                    .subscribe(() => this.store.dispatch(new AppOperationActions.LogOut()));
            });
    }

    signUp(email: string, fullName: string, termsAccepted: boolean) {
        this.errorMessage$.next(null);
        this.serverRequestInProgress$.next(true);

        const request = this.http.post(environment.restApiConfig.baseUrl + '/accounts/signUp', {
            email,
            fullName,
            termsAccepted,
        });
        request
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    this.errorMessage$.next(response.error.message);
                    this.serverRequestInProgress$.next(false);
                    return of(null);
                }),
                filter((res) => !!res)
            )
            .subscribe((result: string) => {
                this.authenticationMethodId = result;
                this.passcodeSent$.next(true);
                this.resetServerRequestCheckers();
                this.router.navigate([RouteBrick.Authentication, RouteBrick.AuthenticatePasscode]);
            });

        return request;
    }

    authenticatePasscode(code: string) {
        this.errorMessage$.next(null);
        this.serverRequestInProgress$.next(true);
        this.http
            .post(
                environment.restApiConfig.baseUrl + '/accounts/authenticatePasscode',
                {
                    methodId: this.authenticationMethodId,
                    code,
                },
                { withCredentials: true }
            )
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    this.errorMessage$.next(response.error.message);
                    this.serverRequestInProgress$.next(false);
                    return of(null);
                }),
                filter((res) => !!res),
                withLatestFrom(this.store.select(selectAuthenticationRedirectUrl))
            )
            .subscribe(([token, redirectUrl]) => {
                const routeSegments = redirectUrl.split('/').filter((s) => !!s);
                const defaultRoute = ['../'];
                this.setAuthenticationToken(token);
                this.router.navigate(routeSegments.length ? routeSegments : defaultRoute);
            });
    }

    authenticateOAuthToken(oAuthToken: string) {
        this.errorMessage$.next(null);
        this.serverRequestInProgress$.next(true);
        this.http
            .post(
                environment.restApiConfig.baseUrl + '/accounts/authenticateOAuthToken',
                {
                    token: oAuthToken,
                },
                { withCredentials: true }
            )
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    this.errorMessage$.next(response.error.message);
                    this.serverRequestInProgress$.next(false);
                    this.router.navigate([RouteBrick.Authentication, RouteBrick.LogIn]);
                    return of(null);
                }),
                filter((res) => !!res),
                withLatestFrom(this.store.select(selectAuthenticationRedirectUrl))
            )
            .subscribe(([jwt, redirectUrl]) => {
                const routeSegments = redirectUrl.split('/').filter((s) => !!s);
                const defaultRoute = ['../'];
                this.setAuthenticationToken(jwt);
                this.router.navigate(routeSegments.length ? routeSegments : defaultRoute);
            });
    }

    resendPasscode(email: string) {
        this.errorMessage$.next(null);
        this.http
            .post(environment.restApiConfig.baseUrl + '/accounts/resendPasscode', {
                email,
            })
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    this.errorMessage$.next(response.error.message);
                    return of(null);
                }),
                filter((res) => !!res),
                withLatestFrom(this.store.select(selectAuthenticationRedirectUrl))
            )
            .subscribe(() => {
                this.passcodeSent$.next(true);
                this.resetServerRequestCheckers();
            });
    }

    async sessionIsAuthenticated(): Promise<boolean> {
        const authenticated = (await this.http
            .post(environment.restApiConfig.baseUrl + '/accounts/authenticateSession', null, {
                withCredentials: true,
            })
            .pipe(take(1))
            .toPromise()) as boolean;

        return authenticated;
    }

    async authenticateCurrentSession(): Promise<boolean> {
        const authenticated = (await this.http
            .post(environment.restApiConfig.baseUrl + '/accounts/authenticateSession', null, {
                withCredentials: true,
            })
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    this.errorMessage$.next(response.error?.message);
                    return of(false);
                }),
                take(1)
            )
            .toPromise()) as boolean;

        if (!authenticated) {
            this.clearAuthenticationToken();
            this.router.navigate([RouteBrick.Authentication]);
            return false;
        }

        return true;
    }

    resetServerRequestCheckers() {
        this.errorMessage$.next(null);
        this.serverRequestInProgress$.next(false);
    }

    resetPassCodeChecker() {
        this.passcodeSent$.next(false);
    }

    getAuthenticationToken() {
        return localStorage.getItem(authenticationTokenKey);
    }

    private setAuthenticationToken(token: string) {
        localStorage.setItem(authenticationTokenKey, token);
    }

    private clearAuthenticationToken() {
        localStorage.removeItem(authenticationTokenKey);
    }
}
