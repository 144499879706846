import { ProjectPayloads, ProjectRaciTable } from 'app/core/store/project';
import { ImportMicrosoftProjectPayload } from 'app/core/store/project/project.payloads';
import { LoadByProjectIdPayload } from 'app/core/store/shared/models/load.payload';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import { FormDataPayload } from 'app/utils/network/commands/payloads/form-data.command.payload';

export const getProject = (
    payload: ProjectPayloads.ProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projects', value: payload.projectId }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const getInitialProject = (
    payload: ProjectPayloads.ProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projects', value: payload.projectId }, { name: 'initial' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const getProjectPermissions = (
    payload: ProjectPayloads.ProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projects', value: payload.projectId },
        { name: 'permissions' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addProject = (
    payload: ProjectPayloads.AddProjectPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projects' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const updateProject = (
    payload: ProjectPayloads.UpdateProjectPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projects', value: payload.projectId }]);
    baseCommand.payload.setData(payload.changes);
    baseCommand.method = RestfulMethod.PUT;
    return baseCommand;
};

export const patchProject = (
    payload: ProjectPayloads.PatchProjectPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projects', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const archiveProject = (
    payload: ProjectPayloads.ProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projects', value: payload.projectId }, { name: 'archive' }]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const unarchiveProject = (
    payload: ProjectPayloads.ProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projects', value: payload.projectId },
        { name: 'unarchive' },
    ]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const deleteProject = (
    payload: ProjectPayloads.ProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projects', value: payload.projectId }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};

export const getProjectSettings = (
    payload: ProjectPayloads.ProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projects', value: payload.projectId }, { name: 'settings' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const patchProjectSettings = (
    payload: ProjectPayloads.PatchProjectSettingsPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projects', value: payload.projectId }, { name: 'settings' }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const getProjectRaciTable = (
    payload: LoadByProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projects', value: payload.projectId }, { name: 'raci' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const updateProjectRaciTable = (payload: ProjectRaciTable, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projects', value: payload.projectId }, { name: 'raci' }]);
    baseCommand.payload.setData(payload.data);
    baseCommand.method = RestfulMethod.PUT;
    return baseCommand;
};

export const importMicrosoftProject = (
    payload: ImportMicrosoftProjectPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projects/importMsProject' }]);
    baseCommand.method = RestfulMethod.POST;
    const formData = new FormData();
    for (const prop in payload) {
        if (prop) {
            const value = payload[prop];
            if (value !== undefined) {
                formData.append(prop, value);
            }
        }
    }
    baseCommand.payload = new FormDataPayload();
    baseCommand.payload.setData(formData);
    return baseCommand;
};
