import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SnackBarService } from 'app/services/snack-bar/snack-bar.service';
import { getPatch } from 'app/utils/json-patch';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { ReminderSelectors } from '.';
import { RestApiService } from '../../services/rest-api';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import * as AppStore from '../store';
import { Reminder } from './models';
import * as ReminderActions from './reminder.actions';

@Injectable()
export class ReminderEffects {
    loadReminders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReminderActions.loadReminders),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        ReminderActions.loadRemindersSuccess({
                            reminders: response.payload.map((dto) =>
                                Model.createFromDto(Reminder, dto)
                            ),
                        })
                    ),
                    catchError((error) => of(ReminderActions.loadRemindersFail({ error })))
                )
            )
        )
    );

    addReminder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReminderActions.addReminder),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Adding a new Reminder...');
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBarService.hide()),
                    map((response: CommandResult) =>
                        ReminderActions.addReminderSuccess(
                            Model.createFromDto(Reminder, response.payload)
                        )
                    ),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(ReminderActions.addReminderFail({ error }));
                    })
                );
            })
        )
    );

    updateReminder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReminderActions.updateReminder),
            withLatestFrom(this.store$.select(ReminderSelectors.selectAll)),
            mergeMap(([action, reminders]) => {
                const reminder = reminders.find((r) => r.id === action.payload.id);
                const patch = getPatch(reminder, action.payload.changes);
                return of(
                    ReminderActions.patchReminder({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchReminder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReminderActions.patchReminder),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ReminderActions.patchReminderSuccess({
                            reminder: Model.createFromDto(Reminder, response.payload),
                        });
                    }),
                    catchError((error) =>
                        of(ReminderActions.patchReminderFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    deleteReminder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReminderActions.deleteReminder),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ReminderActions.deleteReminderSuccess({ id: action.payload.id });
                    }),
                    catchError((error) =>
                        of(ReminderActions.deleteReminderFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>,
        private snackBarService: SnackBarService
    ) {}
}
