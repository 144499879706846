import { ProgramPayloads } from 'app/core/store/program';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import { SitePayloads } from '../../../../store/site';

export const loadPrograms = (payload: SitePayloads.SiteIdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'programs' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const getProgramById = (
    payload: ProgramPayloads.ProgramIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'programs', value: payload.programId }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const getProgramPermissions = (
    payload: ProgramPayloads.ProgramIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'programs', value: payload.programId },
        { name: 'permissions' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const getInitialProgram = (
    payload: ProgramPayloads.ProgramIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'programs', value: payload.programId }, { name: 'initial' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addProgram = (
    payload: ProgramPayloads.AddProgramPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'programs' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchProgram = (
    payload: ProgramPayloads.PatchProgramPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'programs', value: payload.programId }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteProgram = (
    payload: ProgramPayloads.ProgramIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'programs', value: payload.programId }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};

export const getProjectsByProgram = (
    payload: ProgramPayloads.ProgramIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'programs', value: payload.programId }, { name: 'projects' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};
