import { LowMediumHighStatus } from './raid-shared.model';

export function calculateRiskScore(
    likelihood: LowMediumHighStatus,
    impact: LowMediumHighStatus
): LowMediumHighStatus {
    if (likelihood == null || impact == null) return null;

    const lkh = convertStatusToNumber(likelihood);
    const imp = convertStatusToNumber(impact);
    const product = lkh * imp;
    if (product === 0) return null;

    if (product <= 2) return LowMediumHighStatus.Low;
    if (product >= 6) return LowMediumHighStatus.High;

    return LowMediumHighStatus.Medium;
}

function convertStatusToNumber(status: LowMediumHighStatus): number {
    switch (status) {
        case LowMediumHighStatus.Low:
            return 1;
        case LowMediumHighStatus.Medium:
            return 2;
        case LowMediumHighStatus.High:
            return 3;
        default:
            return 0;
    }
}
