import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SignalRApiService } from 'app/core/services/signalr-api/signalr-api.service';
import { getPatch } from 'app/utils/json-patch';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { RestApiService } from '../../services/rest-api';
import { AccountResponses } from '../../services/rest-api/features/account';
import { okEmptyAction } from '../project/project.actions';
import { ImageModel } from '../shared/models';
import { Model } from '../shared/models/base.model';
import * as AccountActions from './account.actions';
import { AccountModel, SystemAdminData } from './account.model';
import * as AccountSelectors from './account.selectors';

@Injectable()
export class AccountsEffects {
    getMyAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActions.getMyAccount),
            withLatestFrom(this.store.select(AccountSelectors.selectLoadingStatus)),
            filter(([action, status]) => !status?.loaded || action.forceRefresh),
            switchMap(([action]) =>
                this.restApiService.process(action).pipe(
                    map((response: AccountResponses.GetAccountResponse) =>
                        Model.createFromDto(AccountModel, response.payload)
                    ),
                    map((account: AccountModel) => {
                        this.signalRApiService.listenAccount(account.id);
                        return AccountActions.getMyAccountSuccess({ account });
                    }),
                    catchError((error: any) => of(AccountActions.getMyAccountFail({ error })))
                )
            )
        )
    );

    getAccountImage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActions.getAccountImage),
            switchMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: AccountResponses.GetAccountImageResponse) => {
                        return response.payload.imageDataUrl != null
                            ? Model.createFromDto(ImageModel, response.payload)
                            : null;
                    }),
                    map((image: ImageModel) => AccountActions.getAccountImageSuccess({ image })),
                    catchError((error: any) => of(AccountActions.getAccountImageFail({ error })))
                )
            )
        )
    );

    getSystemAdminData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActions.getSystemAdminData),
            switchMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        AccountActions.getSystemAdminDataSuccess({
                            data: Model.createFromDto(SystemAdminData, response.payload),
                        })
                    ),
                    catchError((error: any) => of(AccountActions.getAccountImageFail({ error })))
                )
            )
        )
    );

    getUserAppMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActions.getUserAppMessage),
            switchMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        AccountActions.getUserAppMessageSuccess({
                            message: response.payload,
                        })
                    ),
                    catchError((error: any) => of(AccountActions.getUserAppMessageFail({ error })))
                )
            )
        )
    );

    updateSystemAdminData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActions.updateSystemAdminData),
            withLatestFrom(this.store.select(AccountSelectors.selectSystemAdminData)),
            switchMap(([action, adminData]) => {
                const patch = getPatch(adminData, action.payload.changes);
                return of(
                    AccountActions.patchSystemAdminData({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchSystemAdminData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActions.patchSystemAdminData),
            switchMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return AccountActions.patchSystemAdminDataSuccess({
                            data: Model.createFromDto(SystemAdminData, response.payload),
                        });
                    }),
                    catchError((error: any) =>
                        of(AccountActions.patchSystemAdminDataFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    updateAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActions.updateAccount),
            switchMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return AccountActions.updateAccountSuccess(action.payload);
                    }),
                    catchError((error: any) =>
                        of(AccountActions.updateAccountFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    updateAccountImage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActions.updateAccountImage),
            switchMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() =>
                        AccountActions.updateAccountImageSuccess({
                            image: action.payload.image,
                        })
                    ),
                    catchError((error: any) => of(AccountActions.updateAccountImageFail({ error })))
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store: Store,
        private signalRApiService: SignalRApiService
    ) {}
}
