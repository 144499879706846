import { CtxColor, CtxMember } from 'app/core/models/shared-models';
import { isBefore, isSameDay } from 'date-fns';
import { ReminderLink } from '../reminder';

export interface UserTask {
    id: string;
    parentLevelObjectId?: string;
    name: string;
    type: UserTaskType;
    projectName: string;
    projectColor: CtxColor;
    projectId: string;

    startDate?: Date;
    endDate: Date;

    hasCompletePercent: boolean;
    completePercent?: number;

    color?: CtxColor;
    members?: UserTaskMember[];
    status: UserTaskStatus;
    isClosed?: boolean;
    isOriginProjectClosed?: boolean;
    isUserProjectMemberClosed?: boolean;
    reminderDate?: Date;
    hideInCalendar?: boolean;
    readonly?: boolean;
}

export enum UserTaskType {
    Action = 'Action',
    Issue = 'Issue',
    Schedule = 'Schedule',
    Minute = 'Minute',
    SprintTask = 'SprintTask',
    BacklogTask = 'BacklogTask',
    Approval = 'Approval',
    Sprint = 'Sprint',
}

export const userTaskTypeNamesMap: Record<UserTaskType, string> = {
    [UserTaskType.Action]: UserTaskType.Action,
    [UserTaskType.Issue]: UserTaskType.Issue,
    [UserTaskType.Schedule]: UserTaskType.Schedule,
    [UserTaskType.Minute]: UserTaskType.Minute,
    [UserTaskType.SprintTask]: 'Sprint task',
    [UserTaskType.BacklogTask]: 'Backlog task',
    [UserTaskType.Approval]: UserTaskType.Approval,
    [UserTaskType.Sprint]: UserTaskType.Sprint,
};

export interface UserTaskMember extends CtxMember {
    siteMemberName?: string;
    imageDataUrl?: string;
}

export interface UserTaskStatus {
    name: string;
    color?: CtxColor;
}

export const userTaskTypeToReminderLinkMap: Record<UserTaskType, ReminderLink> = {
    [UserTaskType.Action]: ReminderLink.Actions,
    [UserTaskType.Issue]: ReminderLink.Issues,
    [UserTaskType.Minute]: ReminderLink.MinuteTopics,
    [UserTaskType.Schedule]: ReminderLink.Schedule,
    [UserTaskType.SprintTask]: ReminderLink.BacklogTasks,
    [UserTaskType.BacklogTask]: ReminderLink.BacklogTasks,
    [UserTaskType.Approval]: null,
    [UserTaskType.Sprint]: null,
};

function isDateOverdue(date: Date): boolean {
    return isBefore(date, new Date()) && !isSameDay(new Date(), new Date(date));
}

export function isUserTaskOverdue(task: UserTask): boolean {
    const isOverdue = isDateOverdue(task.endDate);
    const taskProgress = task.completePercent ?? 0;
    const taskUncompleted = task.hasCompletePercent ? taskProgress < 100 : !task.isClosed;
    return isOverdue && taskUncompleted && !!task.endDate;
}
