import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectTemplateEntry } from 'app/core/services/dialog/new-project-from-template/new-project-from-template.component';
import { BudgetTableSelectors } from '../budget-table';
import { CalendarSelectors } from '../calendar';
import { CommunicationSelectors } from '../communication';
import { ListContainerSelectors } from '../project-phase-container';
import { SiteSelectors } from '../site';
import { StateEntry } from '../state-entry';
import { adapter, LoadingStatus, State } from './project-template.store';

export const selectProjectTemplateEntry = createFeatureSelector<State>(StateEntry.ProjectTemplate);

export const selectLoadingStatus = createSelector(
    selectProjectTemplateEntry,
    (state): LoadingStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
    })
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectProjectTemplateEntry);

export const selectCurrentSiteProjectTemplates = createSelector(
    selectAll,
    SiteSelectors.selectCurrentSiteId,
    (templates, siteId) => templates.filter((t) => t.siteId === siteId)
);

export const selectCompletixProjectTemplates = createSelector(selectAll, (templates) =>
    templates.filter((t) => t.isCompletixTemplate)
);

export const selectCompletixProjectTemplatesLoadingStatus = createSelector(
    selectProjectTemplateEntry,
    (state) => state.completixTemplatesLoading
);

export const selectProjectTemplatePreview = (templateId: string) =>
    createSelector(
        selectAll,
        CalendarSelectors.selectAll,
        CommunicationSelectors.selectAll,
        ListContainerSelectors.selectAll,
        BudgetTableSelectors.selectAll,
        (projectTemplates, calendars, communications, listContainers, budgetTables) => {
            const template = projectTemplates.find((t) => t.id === templateId);
            const calendar = calendars.find((c) => c.id === template?.calendarTemplateId);
            const communication = communications.find(
                (c) => c.id === template?.communicationTemplateId
            );
            const gatingListContainer = listContainers.find(
                (c) => c.id === template?.gatingTemplateId
            );
            const scheduleListContainer = listContainers.find(
                (c) => c.id === template?.scheduleTemplateId
            );
            const budgetTable = budgetTables.find((t) => t.id === template?.budgetTemplateId);
            const templateEntry: ProjectTemplateEntry = {
                ...template,
                gatingName: gatingListContainer?.name || '-',
                budgetName: budgetTable?.name || '-',
                scheduleName: scheduleListContainer?.name || '-',
                calendarName: calendar?.name || '-',
                communicationPlanName: communication?.name || '-',
            };
            return templateEntry;
        }
    );
