import { Update } from '@ngrx/entity/src';
import { createReducer, on } from '@ngrx/store';
import { SiteMember } from 'app/core/store/resource';
import { getPatched } from 'app/utils/json-patch';
import {
    deleteSiteMember,
    deleteSiteMemberFail,
    deleteSiteMemberSuccess,
    inviteSiteMembersToSiteFail,
    inviteSiteMembersToSiteSuccess,
    loadMembersBySiteId,
    loadMySiteMember,
    loadMySiteMemberSuccess,
    loadSiteMembersFail,
    loadSiteMembersSuccess,
    patchSiteMember,
    patchSiteMemberSuccess,
} from './site-member.actions';
import { adapter, initialState } from './site-member.store';

export const reducer = createReducer(
    initialState,
    on(loadMembersBySiteId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(loadSiteMembersSuccess, (state, payload) => {
        const newState = adapter.addMany(payload.siteMembers, adapter.removeAll(state));
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(loadSiteMembersFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(loadMySiteMember, (state) => ({
        ...state,
        currentSiteMember: null,
    })),

    on(loadMySiteMemberSuccess, (state, payload) => ({
        ...state,
        currentSiteMember: payload.siteMember,
    })),

    on(inviteSiteMembersToSiteSuccess, (state, payload) => adapter.addMany(payload.members, state)),

    on(inviteSiteMembersToSiteFail, (state) => ({ ...state })),

    on(patchSiteMember, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patchedDocument = getPatched(state.entities[action.payload.id], action.payload.patch);
        const memberUpdate: Update<SiteMember> = {
            id: patchedDocument.id,
            changes: patchedDocument,
        };
        return adapter.updateOne(memberUpdate, state);
    }),

    on(patchSiteMemberSuccess, (state, payload) => {
        const member = state.entities[payload.id];
        if (!member) return state;
        const patchedDocument = getPatched(state.entities[payload.id], payload.patch);
        const memberUpdate: Update<SiteMember> = {
            id: patchedDocument.id,
            changes: patchedDocument,
        };
        return adapter.updateOne(memberUpdate, state);
    }),

    on(inviteSiteMembersToSiteFail, (state) => ({ ...state })),

    on(deleteSiteMember, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(deleteSiteMemberSuccess, (state, payload) => {
        return adapter.removeOne(payload.id, state);
        // if (payload.deleted) return adapter.removeOne(payload.id, state);
        const update: Update<SiteMember> = {
            id: payload.id,
            changes: { deleted: true },
        };
        return adapter.updateOne(update, state);
    }),

    on(deleteSiteMemberFail, (state) => ({ ...state }))
);
