import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import {
    AsyncUpdateStatusDefaultLoad,
    AsyncUpdateStatusDefaultLoadSuccess,
} from '../shared/async-update-status.store';
import { ProjectRole } from './models';
import * as ProjectRoleActions from './project-role.actions';
import { adapter, initialState } from './project-role.store';

export const reducer = createReducer(
    initialState,
    on(ProjectRoleActions.loadProjectRoleTemplates, (state) => ({
        ...state,
        templatesLoadingStatus: {
            ...state.templatesLoadingStatus,
            ...AsyncUpdateStatusDefaultLoad,
        },
    })),

    on(ProjectRoleActions.loadProjectRoleTemplatesSuccess, (state, payload) => {
        const newState = adapter.addMany(
            payload.roles,
            adapter.removeMany(
                Object.values(state.entities)
                    .filter((r) => r.isTemplate)
                    .map((r) => r.id),
                state
            )
        );
        return {
            ...newState,
            templatesLoadingStatus: {
                ...state.templatesLoadingStatus,
                ...AsyncUpdateStatusDefaultLoadSuccess,
            },
        };
    }),

    on(ProjectRoleActions.loadProjectRoleTemplatesFail, (state, payload) => ({
        ...state,
        templatesLoadingStatus: {
            ...state.templatesLoadingStatus,
            ...AsyncUpdateStatusDefaultLoad,
            error: payload.error,
        },
    })),

    on(ProjectRoleActions.loadProjectRolesBySite, (state) => ({
        ...state,
        currentSiteRolesLoadingStatus: {
            ...state.currentSiteRolesLoadingStatus,
            ...AsyncUpdateStatusDefaultLoad,
        },
    })),

    on(ProjectRoleActions.loadProjectRolesBySiteSuccess, (state, payload) => {
        const newState = adapter.addMany(
            payload.roles,
            adapter.removeMany(
                Object.values(state.entities)
                    .filter((r) => r.siteId === payload.roles[0]?.siteId)
                    .map((r) => r.id),
                state
            )
        );
        return {
            ...newState,
            currentSiteRolesLoadingStatus: {
                ...state.currentSiteRolesLoadingStatus,
                ...AsyncUpdateStatusDefaultLoadSuccess,
            },
        };
    }),

    on(ProjectRoleActions.loadProjectRolesBySiteFail, (state, payload) => ({
        ...state,
        currentSiteRolesLoadingStatus: {
            ...state.currentSiteRolesLoadingStatus,
            ...AsyncUpdateStatusDefaultLoad,
            error: payload.error,
        },
    })),

    on(ProjectRoleActions.addProjectRoleSuccess, (state, payload) =>
        adapter.addOne(payload.role, state)
    ),

    on(ProjectRoleActions.addProjectRoleFail, (state) => ({ ...state })),

    on(ProjectRoleActions.patchProjectRole, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patchedRole = getPatched(state.entities[action.payload.id], action.payload.patch);
        const roleUpdate: Update<ProjectRole> = {
            id: patchedRole.id,
            changes: patchedRole,
        };
        return adapter.updateOne(roleUpdate, state);
    }),

    on(ProjectRoleActions.patchProjectRoleSuccess, (state, payload) => {
        const patchedRole = getPatched(state.entities[payload.id], payload.patch);
        const roleUpdate: Update<ProjectRole> = {
            id: patchedRole.id,
            changes: patchedRole,
        };
        return adapter.updateOne(roleUpdate, state);
    }),

    on(ProjectRoleActions.patchProjectRoleFail, (state) => ({ ...state })),

    on(ProjectRoleActions.setProjectRoleTag, (state, action) => {
        if (!action.options?.optimistic) return state;
        const roleUpdate: Update<ProjectRole> = {
            id: action.payload.roleId,
            changes: { tag: action.payload.tag },
        };
        const existingRoleWithTag = Object.values(state.entities).find(
            (r) => r.tag === action.payload.tag
        );
        const existingRoleUpdate: Update<ProjectRole> = {
            id: existingRoleWithTag?.id,
            changes: { tag: null },
        };
        return adapter.updateMany([roleUpdate, existingRoleUpdate], state);
    }),

    on(ProjectRoleActions.setProjectRoleTagSuccess, (state, payload) => {
        const updates: Update<ProjectRole>[] = payload.projectRoles.map((role) => ({
            id: role.id,
            changes: role,
        }));
        return adapter.updateMany(updates, state);
    }),

    on(ProjectRoleActions.setProjectRoleTagFail, (state) => ({ ...state })),

    on(ProjectRoleActions.deleteProjectRole, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(ProjectRoleActions.deleteProjectRoleSuccess, (state, payload) =>
        adapter.removeOne(payload.id, state)
    ),

    on(ProjectRoleActions.deleteProjectRoleFail, (state) => ({ ...state }))
);
