import { OverlayContainer } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { SharedService } from './services/shared.service';
import { Theme } from './theme';

@Component({
    selector: 'ctx-app',
    styleUrls: ['./app.component.scss'],
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    constructor(
        private renderer: Renderer2,
        private overlayContainer: OverlayContainer,
        private sharedService: SharedService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    ngOnInit() {
        this.disableOverlayAnimations();

        this.sharedService.theme$.subscribe((theme) => {
            const themeList = Object.keys(Theme).map((t) => Theme[t]);
            this.document.body.classList.remove(...themeList);
            this.document.body.classList.add(theme);
        });
    }

    private disableOverlayAnimations() {
        const disableAnimations = true;
        const overlayContainerElement: HTMLElement = this.overlayContainer.getContainerElement();
        this.renderer.setProperty(overlayContainerElement, '@.disabled', disableAnimations);
    }
}
