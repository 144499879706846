import { JsonProperty } from 'app/utils/json-mapper';

export class SubscriptionQuantity {
    @JsonProperty('activeProjects')
    activeProjects: number = undefined;

    @JsonProperty('activeUsers')
    activeUsers: number = undefined;

    constructor(init?: Partial<SubscriptionQuantity>) {
        Object.assign(this, init);
    }
}
