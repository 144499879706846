import { Action, createAction, props } from '@ngrx/store';
import { OptimisticableActionOptions } from 'app/core/store/shared/optimisticable-action';
import { LoadBySiteIdPayload } from '../shared/models/load.payload';
import { ProgramRole } from './models';
import {
    AddProgramRolePayload,
    PatchProgramRolePayload,
    ProgramRoleIdPayload,
    SetProgramRoleTagPayload,
    UpdateProgramRolePayload,
} from './program-role.payloads';

// Load ProgramRoles actions

export const loadProgramRoleTemplates = createAction('[ProgramRole] Load templates');

export const loadProgramRoleTemplatesSuccess = createAction(
    '[ProgramRole] Load templates success',
    props<{ roles: ProgramRole[] }>()
);

export const loadProgramRoleTemplatesFail = createAction(
    '[ProgramRole] Load templates fail',
    props<{ error: any }>()
);

export const loadProgramRolesBySite = createAction(
    '[ProgramRole] Load by Site',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadProgramRolesBySiteSuccess = createAction(
    '[ProgramRole] Load by Site success',
    props<{ roles: ProgramRole[] }>()
);

export const loadProgramRolesBySiteFail = createAction(
    '[ProgramRole] Load by Site fail',
    props<{ error: any }>()
);

// Add ProgramRole actions

export const addProgramRole = createAction(
    '[ProgramRole] Add',
    props<{ payload: AddProgramRolePayload }>()
);

export const addProgramRoleSuccess = createAction(
    '[ProgramRole] Add success',
    props<{ role: ProgramRole }>()
);

export const addProgramRoleFail = createAction('[ProgramRole] Add fail', props<{ error: any }>());

// Update ProgramRole actions

export const updateProgramRole = createAction(
    '[ProgramRole] Update',
    props<{ payload: UpdateProgramRolePayload; options: OptimisticableActionOptions }>()
);

export const patchProgramRole = createAction(
    '[ProgramRole] Patch',
    props<{ payload: PatchProgramRolePayload; options: OptimisticableActionOptions }>()
);

export const patchProgramRoleSuccess = createAction(
    '[ProgramRole] Patch success',
    props<PatchProgramRolePayload>()
);

export const patchProgramRoleFail = createAction(
    '[ProgramRole] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Set role tag actions

export const setProgramRoleTag = createAction(
    '[ProgramRole] Set tag',
    props<{ payload: SetProgramRoleTagPayload; options: OptimisticableActionOptions }>()
);

export const setProgramRoleTagSuccess = createAction(
    '[ProgramRole] Set tag success',
    props<{ programRoles: ProgramRole[] }>()
);

export const setProgramRoleTagFail = createAction(
    '[ProgramRole] Set tag fail',
    props<{ error: any; originAction: Action }>()
);

// Delete ProgramRole actions

export const deleteProgramRole = createAction(
    '[ProgramRole] Delete',
    props<{ payload: ProgramRoleIdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteProgramRoleSuccess = createAction(
    '[ProgramRole] Delete success',
    props<ProgramRoleIdPayload>()
);

export const deleteProgramRoleFail = createAction(
    '[ProgramRole] Delete fail',
    props<{ error: any; originAction: Action }>()
);
