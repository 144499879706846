import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Model } from 'app/core/store/shared/models/base.model';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import { RestApiService } from '../../services/rest-api';
import { UserLog } from './models/user-log.model';
import * as UserLogActions from './user-log.actions';

@Injectable()
export class UserLogEffects {
    loadUserLogBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserLogActions.loadUserLogsBySiteId),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        UserLogActions.loadUserLogsBySiteIdSuccess({
                            logs: response.payload.map((dto) => Model.createFromDto(UserLog, dto)),
                        })
                    ),
                    catchError((error) => of(UserLogActions.loadUserLogsBySiteIdFail({ error })))
                )
            )
        )
    );

    loadUserLogByProgramId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserLogActions.loadUserLogsByProgramId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        UserLogActions.loadUserLogsByProgramIdSuccess({
                            logs: response.payload.map((dto) => Model.createFromDto(UserLog, dto)),
                        })
                    ),
                    catchError((error) => of(UserLogActions.loadUserLogsByProgramIdFail({ error })))
                )
            )
        )
    );

    loadUserLogByProjectId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserLogActions.loadUserLogsByProjectId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        UserLogActions.loadUserLogsByProjectIdSuccess({
                            logs: response.payload.map((dto) => Model.createFromDto(UserLog, dto)),
                        })
                    ),
                    catchError((error) => of(UserLogActions.loadUserLogsByProjectIdFail({ error })))
                )
            )
        )
    );

    constructor(private actions$: Actions, private restApiService: RestApiService) {}
}
