import { createAction, props } from '@ngrx/store';
import { LoadBySiteIdPayload } from '../shared/models/load.payload';
import { IdPayload } from '../shared/models/shared-payloads';
import { UpdateExecutiveDashboardTaskProgressPayload } from './executive-dashboard.payloads';
import { ExecutiveDashboard } from './models';

// Load ExecutiveDashboards actions

export const loadExecutiveDashboardBySiteId = createAction(
    '[ExecutiveDashboard] Load by Site id',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadExecutiveDashboardBySiteIdSuccess = createAction(
    '[ExecutiveDashboard] Load by Site id success',
    props<{ executiveDashboard: ExecutiveDashboard }>()
);

export const loadExecutiveDashboardBySiteIdFail = createAction(
    '[ExecutiveDashboard] Load by Site id fail',
    props<{ error: any }>()
);

// Update Task action

export const updateExecutiveDashboardTaskProgress = createAction(
    '[ExecutiveDashboard] Update task',
    props<{ payload: UpdateExecutiveDashboardTaskProgressPayload }>()
);

// Delete Minute task action

export const deleteExecutiveDashboardMinuteTask = createAction(
    '[ExecutiveDashboard] Delete minute task',
    props<{ payload: IdPayload }>()
);
