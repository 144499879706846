import { CtxColor } from 'app/core/models/shared-models';
import { JsonProperty } from 'app/utils/json-mapper';
import { Model } from '../../shared/models/base.model';

export enum ProjectRequestStatus {
    Open = 'Open',
    Pending = 'Pending',
    // Approved = 'approved',
    Rejected = 'Rejected',
    NeedMoreInfo = 'NeedMoreInfo',
}

export class ProjectRequest extends Model {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('siteId')
    siteId: string = undefined;

    @JsonProperty('name')
    name: string = undefined;

    @JsonProperty('customerName')
    customerName?: string;

    @JsonProperty('requesterSiteMemberId')
    requesterSiteMemberId?: string;

    @JsonProperty('projectManagerSiteMemberId')
    projectManagerSiteMemberId?: string;

    @JsonProperty('projectTemplateId')
    projectTemplateId?: string;

    @JsonProperty('sponsors')
    sponsors: ProjectSponsor[];

    @JsonProperty('color')
    color?: CtxColor;

    @JsonProperty('description')
    description?: string;

    @JsonProperty('priority')
    priorityFieldId?: string;

    @JsonProperty('approvedBudget')
    approvedBudget?: number;

    @JsonProperty('approvedCurrentFY')
    approvedCurrentFY?: number;

    @JsonProperty('budgetCode')
    budgetCode?: string;

    @JsonProperty('estimatedStart')
    estimatedStart?: Date;

    @JsonProperty('estimatedEnd')
    estimatedEnd?: Date;

    @JsonProperty('creationDate')
    creationDate: Date = undefined;

    @JsonProperty('submitted')
    submitted?: boolean;

    @JsonProperty('status')
    status: ProjectRequestStatus = ProjectRequestStatus.Open;

    @JsonProperty('deletedFromMyRequests')
    deletedFromMyRequests?: boolean;

    @JsonProperty('deletedFromAllprojects')
    deletedFromAllProjects?: boolean;

    constructor(init?: Partial<ProjectRequest>) {
        super();
        Object.assign(this, init);
    }
}

export interface ProjectRequestCreateOptions {
    siteId: string;
    name: string;
    customerName?: string;
    approvedBudget?: number;
    approvedCurrentFY?: number;
    budgetCode?: string;
    estimatedStart?: Date;
    estimatedEnd?: Date;
    background?: string;
    sponsors?: ProjectSponsor[];
    description?: string;
    target?: string;
    priorityFieldId?: string;
    submitted?: boolean;
    status?: ProjectRequestStatus;
}

export const projectRequestStatusNameDict: Record<ProjectRequestStatus, string> = {
    [ProjectRequestStatus.NeedMoreInfo]: 'Need more info',
    [ProjectRequestStatus.Open]: 'Open',
    [ProjectRequestStatus.Pending]: 'Submitted',
    [ProjectRequestStatus.Rejected]: 'Rejected',
};

export interface ProjectSponsor {
    siteMemberId?: string;
    externalName?: string;
}
