import { SitePermissions } from 'app/core/store/shared/models/shared-permissions.model';
import { JsonProperty } from 'app/utils/json-mapper';
import { SubscriptionBaseModel } from './subscription-base.model';
import { SubscriptionQuantity } from './subscription-quantity.model';

export class SubscriptionPlan extends SubscriptionBaseModel {
    @JsonProperty('description')
    description: string = undefined;

    @JsonProperty('isEnabled')
    isEnabled: boolean = undefined;

    @JsonProperty('expectedAmount')
    expectedAmount?: number = undefined;

    @JsonProperty('paymentMethodsAllowed')
    paymentMethodsAllowed: PaymentMethod[] = undefined;

    @JsonProperty('linkedAdditionalPlanIds')
    linkedAdditionalPlanIds?: string[];

    @JsonProperty('connectedSubscriptionPlanId')
    connectedSubscriptionPlanId?: string;

    @JsonProperty('freeGuests')
    freeGuests?: boolean;

    @JsonProperty('permissions')
    permissions: SitePermissions = undefined;

    @JsonProperty('notes')
    notes: string = undefined;

    @JsonProperty('proration')
    proration: SubscriptionPlanProration = undefined;

    constructor(init?: Partial<SubscriptionPlan>) {
        super();
        Object.assign(this, init);
    }

    // Only single payment method is currently supported
    getSinglePaymentMethodAllowed(): PaymentMethod {
        return (this.paymentMethodsAllowed || [])[0];
    }

    getTotalAmountCents(quantity: SubscriptionQuantity): number {
        if (!this.amount || !this.currency) return 0;
        return this.amount * this.getPaidUnits(quantity);
    }

    getPaidUnits(quantity: SubscriptionQuantity): number {
        if (this.isPerProject()) {
            return Math.max(quantity.activeProjects, this.getMinPaidUnits());
        }
        if (this.isPerUser()) {
            return Math.max(quantity.activeUsers, this.getMinPaidUnits());
        }
        if (this.isFixedCost()) return 1;

        return 0;
    }

    getPaidQuantity(usersQuantity: number, projectsQuantity: number): number {
        switch (this.planType) {
            case SubscriptionPlanType.PerProject:
                return projectsQuantity;
            case SubscriptionPlanType.PerUser:
                return usersQuantity;
            case SubscriptionPlanType.FixedPrice:
                return 1;
            default:
                return 0;
        }
    }
}

export interface SubscriptionPlanProration {
    prorationOnAddQuantity?: boolean;
    prorationOnRemoveQuantity?: boolean;
    prorationInvoiceImmediately?: boolean;
}

export interface SubscriptionPlanFeature {
    title: string;
    tooltip?: string;
    highlighted?: boolean;
}

export interface SubscriptionPlanCreateOptions {
    provider: SubscriptionProvider;
    planType: SubscriptionPlanType;
    isCustomPlan: boolean;
    isEnabled: boolean;
    name: string;
    amount: number;
    currency: string;
    interval: SubscriptionPlanInterval;
    intervalCount: number;
    userLimit?: number;
    projectLimit?: number;
    userMin?: number;
    projectMin?: number;
    paymentMethodsAllowed: PaymentMethod[];
    isAdditional?: boolean;
    stripePriceId?: string;
    freeGuests?: boolean;
}

export enum SubscriptionProvider {
    Completix = 'completix',
    Stripe = 'stripe',
}

export enum SubscriptionPlanType {
    Trial = 'trial',
    PerProject = 'perProject',
    PerUser = 'perUser',
    FixedPrice = 'fixedPrice',
    Grace = 'grace',
}

export enum SubscriptionPlanInterval {
    Day = 'day',
    Week = 'week',
    Month = 'month',
    Year = 'year',
}

export enum PaymentMethod {
    CreditCard = 'creditCard',
    WireTransfer = 'wireTransfer',
    ACH = 'ach',
    Cheque = 'cheque',
    Cash = 'cash',
    Other = 'other',
}
