export enum SiteEntityState {
    Open = 'Open',
    Complete = 'Complete',
    Cancelled = 'Cancelled',
    OnHold = 'OnHold',
}

export const siteEntityStateNamesMap: Record<SiteEntityState, string> = {
    [SiteEntityState.Open]: SiteEntityState.Open,
    [SiteEntityState.Complete]: SiteEntityState.Complete,
    [SiteEntityState.Cancelled]: SiteEntityState.Cancelled,
    [SiteEntityState.OnHold]: 'On Hold',
};

export function isClosedSiteEntityState(state: SiteEntityState): boolean {
    return state === SiteEntityState.Complete || state === SiteEntityState.Cancelled;
}
