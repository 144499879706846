import { LoadBySiteIdPayload } from 'app/core/store/shared/models/load.payload';
import { IdPayload } from 'app/core/store/shared/models/shared-payloads';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import { SiteMemberPayloads } from '../../../../store/resource';
import {
    InviteSiteMemberPayload,
    LoadMembersBySiteIdPayload,
    SiteMemberIdPayload,
} from '../../../../store/resource/site-member.payloads';

export const loadMembersBySiteId = (
    payload: LoadMembersBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'members' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadMySiteMember = (payload: LoadBySiteIdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'mySiteMember' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const inviteSiteMember = (payload: InviteSiteMemberPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'invites' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchSiteMember = (
    payload: SiteMemberPayloads.PatchSiteMemberPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'siteMembers', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteSiteMember = (payload: SiteMemberIdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'siteMembers', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};

export const resendInvite = (payload: IdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'siteMembers', value: payload.id }, { name: 'resendInvite' }]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};
