import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ProjectField } from './models';

export interface LoadingStatus {
    serverRequestInProgress: boolean;
    loaded: boolean;
}

export interface State extends EntityState<ProjectField>, LoadingStatus {
    loadingStatusBySettingsTemplateId: Record<string, LoadingStatus>;
}

export const adapter: EntityAdapter<ProjectField> = createEntityAdapter<ProjectField>();

export const initialState: State = adapter.getInitialState({
    serverRequestInProgress: false,
    loaded: false,
    loadingStatusBySettingsTemplateId: {},
});
