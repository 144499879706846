import { JsonProperty } from 'app/utils/json-mapper';
import { generateUUID } from '../../id-generator';

export class Communication {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('siteId')
    siteId?: string = undefined;

    @JsonProperty('projectId')
    projectId?: string = undefined;

    @JsonProperty('name')
    name?: string = undefined;

    @JsonProperty('rows')
    records: CommunicationRecord[];

    @JsonProperty('isTemplate')
    isTemplate?: boolean;

    @JsonProperty('isDefault')
    isDefault?: boolean;

    @JsonProperty('isCompletixTemplate')
    isCompletixTemplate?: boolean;

    @JsonProperty('isPublished')
    isPublished?: boolean;

    @JsonProperty('isInitialTemplate')
    isInitialTemplate?: boolean;

    constructor(init?: Partial<Communication>) {
        Object.assign(this, init);
    }
}

export class CommunicationRecord {
    id: string;
    communication: string;
    format: string;
    frenquency: string;
    distribution: string;

    constructor(init?: Partial<CommunicationRecord>) {
        if (!init) {
            this.id = generateUUID();
            this.communication = '';
            this.format = '';
            this.frenquency = '';
            this.distribution = '';
        } else {
            Object.assign(this, init);
        }
    }
}

export enum CommunicationFrequency {
    Weekly = 'Weekly',
    BiWeekly = 'Bi-weekly',
    Monthly = 'Monthly',
    Quarterly = 'Quarterly',
}
