import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EntityPatchSuccessResponse } from 'app/core/services/rest-api/features/patch.response';
import {
    ChangeProjectGatingTemplateResponse,
    GetProjectPhaseByIdResponse,
    LoadProjectPhasesResponse,
} from 'app/core/services/rest-api/features/project-phase/project-phase.responses';
import { getPatch } from 'app/utils/json-patch';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ProjectPhaseActions, ProjectPhaseSelectors } from '.';
import { AppStore } from '..';
import { RestApiService } from '../../services/rest-api';
import { ApprovalRequestActions } from '../approval-request';
import { DocumentActions, FileNode } from '../document';
import { ListContainer, ListContainerActions } from '../project-phase-container';
import { okEmptyAction } from '../project/project.actions';
import { selectAccountSiteMembership } from '../shared/account-mebership.selectors';
import { Model } from '../shared/models/base.model';
import { SnackBarService } from './../../../services/snack-bar/snack-bar.service';
import { ProjectPhase } from './models';

@Injectable()
export class ProjectPhaseEffects {
    loadPhasesByContainerId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectPhaseActions.loadProjectPhasesByContainerId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        ProjectPhaseActions.loadProjectPhasesByContainerIdSuccess({
                            phases: response.payload.map((dto) =>
                                Model.createFromDto(ProjectPhase, dto)
                            ),
                            containerId: action.payload.containerId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            ProjectPhaseActions.loadProjectPhaseByContainerIdFail({
                                error,
                                containerId: action.payload.containerId,
                            })
                        )
                    )
                )
            )
        )
    );

    loadProjectPhasesBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectPhaseActions.loadProjectPhasesBySiteId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: LoadProjectPhasesResponse) =>
                        ProjectPhaseActions.loadProjectPhasesBySiteIdSuccess({
                            phases: response.payload.phases.map((dto) =>
                                Model.createFromDto(ProjectPhase, dto)
                            ),
                            siteId: action.payload.siteId,
                            listContainerIds: response.payload.listContainerIds,
                        })
                    ),
                    catchError((error) =>
                        of(
                            ProjectPhaseActions.loadProjectPhasesBySiteIdFail({
                                error,
                                siteId: action.payload.siteId,
                            })
                        )
                    )
                )
            )
        )
    );

    getProjectPhaseById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectPhaseActions.getProjectPhaseById),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    switchMap((response: GetProjectPhaseByIdResponse) => [
                        ProjectPhaseActions.getProjectPhaseByIdSuccess({
                            projectPhase: Model.createFromDto(ProjectPhase, response.payload.phase),
                        }),
                        ListContainerActions.getListContainerByIdSuccess({
                            listContainer: Model.createFromDto(
                                ListContainer,
                                response.payload.listContainer
                            ),
                        }),
                        DocumentActions.getManyDocumentsSuccess({
                            documents: response.payload.documents.map((d) =>
                                Model.createFromDto(FileNode, d)
                            ),
                        }),
                    ]),
                    catchError((error) =>
                        of(
                            ProjectPhaseActions.getProjectPhaseByIdFail({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    addProjectPhase$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectPhaseActions.addProjectPhase),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Creating a new phase...');
                return this.restApiService.process(action).pipe(
                    map((response) => {
                        this.snackBarService.hide();
                        return ProjectPhaseActions.addProjectPhaseSuccess({
                            phase: Model.createFromDto(ProjectPhase, response.payload),
                        });
                    }),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(ProjectPhaseActions.addProjectPhaseFail({ error }));
                    })
                );
            })
        )
    );

    updateProjectPhase$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectPhaseActions.updateProjectPhase),
            withLatestFrom(this.store$.select(ProjectPhaseSelectors.selectAll)),
            mergeMap(([action, phases]) => {
                const phase = phases.find((p) => p.id === action.payload.id);
                const patch = getPatch(phase, action.payload.changes);
                return of(
                    ProjectPhaseActions.patchProjectPhase({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchProjectPhase$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectPhaseActions.patchProjectPhase),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: EntityPatchSuccessResponse) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ProjectPhaseActions.patchProjectPhaseSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) =>
                        of(
                            ProjectPhaseActions.patchProjectPhaseFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    manageProjectPhaseApproval$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectPhaseActions.manageProjectPhaseApproval),
            withLatestFrom(this.store$.select(selectAccountSiteMembership)),
            mergeMap(([action, accountSiteMember]) => {
                this.snackBarService.showInfiniteBar('Processing an Approval...');
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBarService.hide()),
                    map((response: EntityPatchSuccessResponse) => {
                        this.store$.dispatch(
                            ApprovalRequestActions.updateApprovalState({
                                id: action.payload.approvalId,
                                siteMemberId: accountSiteMember.id,
                                state: action.payload.approvalState,
                            })
                        );
                        return ProjectPhaseActions.patchProjectPhaseSuccess({
                            id: action.payload.phaseId,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(
                            ProjectPhaseActions.manageProjectPhaseApprovalFail({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    advanceProjectPhase$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectPhaseActions.advanceProjectPhase),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Processsing...');
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBarService.hide()),
                    map(
                        (response: EntityPatchSuccessResponse) =>
                            ProjectPhaseActions.patchProjectPhaseSuccess({
                                id: action.payload.id,
                                patch: response.payload,
                            }),
                        catchError((error) => {
                            this.snackBarService.hide();
                            return of(ProjectPhaseActions.advanceProjectPhaseFail({ error }));
                        })
                    )
                );
            })
        )
    );

    submitProjectPhase$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectPhaseActions.submitProjectPhase),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Processsing...');
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBarService.hide()),
                    map(
                        (response: EntityPatchSuccessResponse) =>
                            ProjectPhaseActions.patchProjectPhaseSuccess({
                                id: action.payload.id,
                                patch: response.payload,
                            }),
                        catchError((error) => {
                            this.snackBarService.hide();
                            return of(ProjectPhaseActions.submitProjectPhaseFail({ error }));
                        })
                    )
                );
            })
        )
    );

    unsubmitProjectPhase$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectPhaseActions.unsubmitProjectPhase),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Processsing...');
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBarService.hide()),
                    map(
                        (response: EntityPatchSuccessResponse) =>
                            ProjectPhaseActions.patchProjectPhaseSuccess({
                                id: action.payload.id,
                                patch: response.payload,
                            }),
                        catchError((error) => {
                            this.snackBarService.hide();
                            return of(ProjectPhaseActions.unsubmitProjectPhaseFail({ error }));
                        })
                    )
                );
            })
        )
    );

    deleteProjectPhases$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectPhaseActions.deleteProjectPhase),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(
                        () => ProjectPhaseActions.deleteProjectPhaseSuccess(action.payload),
                        catchError((error) =>
                            of(ProjectPhaseActions.deleteProjectPhaseFail({ error }))
                        )
                    )
                )
            )
        )
    );

    changeGatingTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectPhaseActions.changeGatingTemplate),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Changing the template...');
                return this.restApiService.process(action).pipe(
                    map(
                        (response: ChangeProjectGatingTemplateResponse) => {
                            this.snackBarService.hide();
                            this.store$.dispatch(
                                ListContainerActions.replaceListContainer({
                                    container: Model.createFromDto(
                                        ListContainer,
                                        response.payload.phaseContainer
                                    ),
                                })
                            );
                            this.store$.dispatch(
                                DocumentActions.addManyDocumentsSuccess({
                                    documents: response.payload.documents.map((dto) =>
                                        Model.createFromDto(FileNode, dto)
                                    ),
                                })
                            );
                            return ProjectPhaseActions.changeGatingTemplateSuccess({
                                phases: response.payload.phases.map((dto) =>
                                    Model.createFromDto(ProjectPhase, dto)
                                ),
                                containerId: response.payload.phaseContainer.id,
                            });
                        },
                        catchError((error) => {
                            this.snackBarService.hide();
                            return of(ProjectPhaseActions.changeGatingTemplateFail({ error }));
                        })
                    )
                );
            })
        )
    );

    addProjectPhaseDeliverable$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectPhaseActions.addProjectPhaseDeliverable),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(
                        () => {
                            if (action.options?.optimistic) return okEmptyAction();
                            return ProjectPhaseActions.addProjectPhaseDeliverableSuccess(
                                action.payload
                            );
                        },
                        catchError((error) =>
                            of(
                                ProjectPhaseActions.addProjectPhaseDeliverableFail({
                                    error,
                                    originAction: action,
                                })
                            )
                        )
                    )
                )
            )
        )
    );

    updateProjectPhaseDeliverable$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectPhaseActions.updateProjectPhaseDeliverable),
            withLatestFrom(this.store$.select(ProjectPhaseSelectors.selectAll)),
            mergeMap(([action, phases]) => {
                const phase = phases.find((p) =>
                    p.deliverables?.some((d) => d.id === action.payload.deliverableId)
                );
                const deliverable = phase.deliverables.find(
                    (d) => d.id === action.payload.deliverableId
                );
                const patch = getPatch(deliverable, action.payload.changes);
                return of(
                    ProjectPhaseActions.patchProjectPhaseDeliverable({
                        payload: { phaseId: phase.id, deliverableId: deliverable.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchProjectPhaseDeliverable$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectPhaseActions.patchProjectPhaseDeliverable),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(
                        () => {
                            if (action.options?.optimistic) return okEmptyAction();
                            return ProjectPhaseActions.patchProjectPhaseDeliverableSuccess(
                                action.payload
                            );
                        },
                        catchError((error) =>
                            of(
                                ProjectPhaseActions.patchProjectPhaseDeliverableFail({
                                    error,
                                    originAction: action,
                                })
                            )
                        )
                    )
                )
            )
        )
    );

    deleteProjectPhaseDeliverable$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectPhaseActions.deleteProjectPhaseDeliverable),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(
                        () => {
                            if (action.options?.optimistic) return okEmptyAction();
                            return ProjectPhaseActions.deleteProjectPhaseDeliverableSuccess(
                                action.payload
                            );
                        },
                        catchError((error) =>
                            of(
                                ProjectPhaseActions.deleteProjectPhaseDeliverableFail({
                                    error,
                                    originAction: action,
                                })
                            )
                        )
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>,
        private snackBarService: SnackBarService
    ) {}
}
