import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ListContainerSelectors, ListContainerType } from '../project-phase-container';
import { StateEntry } from '../state-entry';
import { adapter, State } from './gl-table-settings.store';

export const selectSettingsEntry = createFeatureSelector<State>(StateEntry.GlTableSettings);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectSettingsEntry);

export const selectGlTableSettingsByContainerId = (listContainerId: string) =>
    createSelector(selectAll, (settings) =>
        settings.find((s) => s.listContainerId === listContainerId)
    );

export const selectCurrentProjectGlTableSettingsByContainerType = (type: ListContainerType) =>
    createSelector(
        selectAll,
        ListContainerSelectors.selectCurrentProjectListContainer(type),
        (allSettings, container) => {
            const tableSettings = allSettings.find((s) => s.listContainerId === container?.id);
            return tableSettings;
        }
    );

export const selectLoadingStatusesPerId = createSelector(
    selectSettingsEntry,
    (state) => state.loadingStatusById
);
