import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AsyncUpdateStatus } from '../shared/async-update-status.store';
import { CtxNotification } from './models';

export interface State extends EntityState<CtxNotification>, AsyncUpdateStatus {}

export const adapter: EntityAdapter<CtxNotification> = createEntityAdapter<CtxNotification>();

export const initialState: State = adapter.getInitialState({
    serverRequestInProgress: false,
    loaded: false,
    loadingFailed: false,
    error: null,
});
