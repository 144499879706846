import { createCtxEntityAdapter, CtxEntityAdapter, CtxEntityState } from '../store';
import { IssueRecord } from './models';

export interface LoadingStatus {
    serverRequestInProgress: boolean;
    loaded: boolean;
}

export interface State extends CtxEntityState<IssueRecord>, LoadingStatus {}

export const adapter: CtxEntityAdapter<IssueRecord> = createCtxEntityAdapter<IssueRecord>();

export const initialState: State = adapter.getCtxInitialState({
    serverRequestInProgress: false,
    loaded: false,
});
