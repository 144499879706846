import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { DocumentCopyCutOperation, FileNode } from './models';

export interface State extends EntityState<FileNode>, LoadingStatus {
    currentOpenedNodeId: string;
    fileAutoSaveEnabled: boolean;
    copyCutOperation: DocumentCopyCutOperation;
    previewBlobMap: Record<string, Blob>;
    previewBlobLoadingStatusMap: Record<string, boolean>;
    previewSectionIsOpened: boolean;
    completixDocumentTempalatesLoading: boolean;
    projectDocumentsLoadingStatusMap: Record<string, LoadingStatus>;
    programDocumentsLoadingStatusMap: Record<string, LoadingStatus>;
    changeRequestFormLoadingStatus: DocumentChangeRequestFormLoadingStatus;
}

export interface LoadingStatus {
    serverRequestInProgress: boolean;
    loaded: boolean;
}

export interface DocumentChangeRequestFormLoadingStatus {
    loadingFormBySiteId?: boolean;
    loadingCompletixForm?: boolean;
    loadingCompletixFormBySiteId?: boolean;
}

export enum FileNodeSystemTag {
    Templates = 'Templates',
    Linked = 'Linked',
    GatingLinked = 'GatingLinked',
    ChangeRequestsLinked = 'ChangeRequestsLinked',
    ProjectTeamLinked = 'ProjectTeamLinked',
    ActionsLinked = 'ActionsLinked',
    IssuesLinked = 'IssuesLinked',
    RisksLinked = 'RisksLinked',
    DecisionsLinked = 'DecisionsLinked',
    HomePageLinked = 'HomePageLinked',
    MinutesLinked = 'MinutesLinked',
    ChangeRequestForm = 'ChangeRequestForm',
    SprintsLinked = 'SprintsLinked',
}

export const fileNodeSystemTagComponentNameMap: Record<FileNodeSystemTag, string> = {
    [FileNodeSystemTag.Templates]: 'Templates',
    [FileNodeSystemTag.Linked]: 'Linked',
    [FileNodeSystemTag.GatingLinked]: 'Gating',
    [FileNodeSystemTag.ChangeRequestsLinked]: 'Change Request',
    [FileNodeSystemTag.ProjectTeamLinked]: 'Project Team',
    [FileNodeSystemTag.ActionsLinked]: 'Action',
    [FileNodeSystemTag.IssuesLinked]: 'Issue',
    [FileNodeSystemTag.RisksLinked]: 'Risk',
    [FileNodeSystemTag.DecisionsLinked]: 'Decision',
    [FileNodeSystemTag.HomePageLinked]: 'Home page',
    [FileNodeSystemTag.MinutesLinked]: 'Minute',
    [FileNodeSystemTag.ChangeRequestForm]: 'ChangeRequestForm',
    [FileNodeSystemTag.SprintsLinked]: 'Sprint',
};

export interface FileNodeForbiddenAction {
    remove?: boolean;
    rename?: boolean;
    create?: boolean;
    move?: boolean;
    edit?: boolean;
}

export const adapter: EntityAdapter<FileNode> = createEntityAdapter<FileNode>();

export const initialState: State = adapter.getInitialState({
    serverRequestInProgress: false,
    loaded: false,
    currentOpenedNodeId: null,
    fileAutoSaveEnabled: true,
    copyCutOperation: null,
    previewBlobMap: {},
    previewBlobLoadingStatusMap: {},
    previewSectionIsOpened: false,
    completixDocumentTempalatesLoading: false,
    projectDocumentsLoadingStatusMap: {},
    programDocumentsLoadingStatusMap: {},
    changeRequestFormLoadingStatus: {},
});
