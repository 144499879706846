import { createFeatureSelector, createSelector } from '@ngrx/store';
import { minuteTreeRootFolderId } from 'app/features/project/minutes-page/minutes-source';
import { ProjectSelectors } from '../project';
import { ProjectRoleSelectors } from '../project-role';
import { selectAccountSiteMembership } from '../shared/account-mebership.selectors';
import { RecordPermissionsMap } from '../shared/models/shared-permissions.model';
import { StateEntry } from '../state-entry';
import { adapter, State } from './minute.store';
import { MinuteTopicType } from './models';
// import {LoadingStatus} from './minute.store';

export const selectMinuteEntry = createFeatureSelector<State>(StateEntry.Minute);

export const selectLoadingStatus = createSelector(selectMinuteEntry, (state) => ({
    serverRequestInProgress: state.serverRequestInProgress,
    loaded: state.loaded,
}));

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectMinuteEntry);

export const selectCurrentProjectMinutes = createSelector(
    selectAll,
    ProjectSelectors.selectCurrentProjectId,
    (minutes, projectId) => minutes.filter((m) => m.projectId === projectId)
);

export const selectAccountMinuteTopics = createSelector(
    selectAll,
    selectAccountSiteMembership,
    (minutes, siteMember) =>
        minutes.flatMap(
            (min) =>
                min.topics?.filter(
                    (t) =>
                        t.type === MinuteTopicType.Task &&
                        t.owner?.siteMemberId === siteMember.id &&
                        !t.deletedFromUserDashboard
                ) || []
        )
);

export const selectCurrentProjectMinuteFolderExpandedIds = createSelector(
    selectMinuteEntry,
    ProjectSelectors.selectCurrentProjectId,
    (entry, projectId) =>
        entry.expandedFolderIdsMap[projectId]?.length
            ? entry.expandedFolderIdsMap[projectId]
            : [minuteTreeRootFolderId]
);

export const selectCurrentProjectMinuteFolderOpenedId = createSelector(
    selectMinuteEntry,
    ProjectSelectors.selectCurrentProjectId,
    (entry, projectId) =>
        entry.openedFolderIdMap[projectId]
            ? entry.openedFolderIdMap[projectId]
            : minuteTreeRootFolderId
);

export const selectMinutesPermissionsMap = createSelector(
    selectAll,
    ProjectRoleSelectors.selectAccountProjectRolesMap,
    selectAccountSiteMembership,
    (minutes, rolesMap, accountSiteMembership) => {
        const dict: RecordPermissionsMap = {};
        const accountSiteMemberId = accountSiteMembership?.id;
        if (!accountSiteMemberId) return {};
        minutes.forEach((min) => {
            const minutePermissions = rolesMap[min.projectId]?.permissions?.minutes;
            const isOwnMinute = min.ownerSiteMemberId === accountSiteMemberId;
            dict[min.id] = {
                create: minutePermissions?.create,
                read: minutePermissions?.read || (minutePermissions?.assignedRead && isOwnMinute),
                update:
                    minutePermissions?.update || (minutePermissions?.assignedUpdate && isOwnMinute),
                delete:
                    minutePermissions?.delete || (minutePermissions?.assignedDelete && isOwnMinute),
            };
        });
        return dict;
    }
);
