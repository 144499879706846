import { getRandomBrightColor } from 'app/core/models/shared-models';
import { Model } from '../shared/models/base.model';

export class AccountModel extends Model {
    // All initialized fields are required
    id = '';
    email = '';

    // Not initialized fields are optional
    fullName: string;
    phoneNumber: string;
    company: string;
    quote: string;
    defaultSiteId: string;
    useSplashScreen: boolean;
    lastOpenedSiteId: string;
    avatarColor: string = getRandomBrightColor();
    preferences: AccountPreferences;
    adminRole: AccountAdminRole;
    receiveEmails?: boolean;
    processedAppMessageIds?: string[];

    constructor(init?: Partial<AccountModel>) {
        super();
        Object.assign(this, init);
    }

    getFullNameOrPlaceholder(): string {
        if (this.isNullOrWhitespace(this.fullName)) return 'My name';

        return this.fullName;
    }

    private isNullOrWhitespace(str: string): boolean {
        return str == null || str.trim().length === 0;
    }
}

export const defaultAvatarUrl = '/assets/img/default-picture.png';

export interface AccountPreferences {
    theme?: string;
    reminderSnoozeDuration?: number;
}

export enum AccountAdminRole {
    FullAccess = 'FullAccess',
    ReadOnly = 'ReadOnly',
    NoAccess = 'NoAccess',
}

export class SystemAdminData {
    id: string;
    appMessages: AdminAppMessage[];

    constructor(init?: Partial<SystemAdminData>) {
        Object.assign(this, init);
    }
}

export interface AdminAppMessage {
    id: string;
    enabled?: boolean;
    startDate?: Date;
    endDate?: Date;
    title: string;
    text: string;
    buttonName: string;
}

export interface UserAppMessage {
    id: string;
    title: string;
    text: string;
    buttonName: string;
}
