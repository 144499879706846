import { LoadingStatusBySiteId } from 'app/core/models/shared-models';
import { AsyncUpdateStatus } from '../shared/async-update-status.store';
import { createCtxEntityAdapter, CtxEntityAdapter, CtxEntityState } from '../store';
import { ScheduleTask } from './models/schedule-task.model';

export interface LoadingStatusByContainerId {
    loadingStatusByContainerId: Record<string, AsyncUpdateStatus>;
}

export interface State
    extends CtxEntityState<ScheduleTask>,
        LoadingStatusByContainerId,
        LoadingStatusBySiteId {}

export const adapter: CtxEntityAdapter<ScheduleTask> = createCtxEntityAdapter<ScheduleTask>();

export const initialState: State = adapter.getCtxInitialState({
    loadingStatusByContainerId: {},
    loadingStatusBySiteId: {},
});
