import { ProjectBudgetBase, ProjectBudgetEntityBase } from 'app/core/models/shared-models';
import { JsonProperty } from 'app/utils/json-mapper';
import { CellProperties } from 'handsontable/settings';
import { Model } from '../../shared/models/base.model';

export class BudgetAssigned implements ProjectBudgetBase {
    plannedCost: BudgetAssignedValue;
    actualToDate: BudgetAssignedValue;
    currentFYForecast: BudgetAssignedValue;
    currentFYActuals: BudgetAssignedValue;
}

export class ProjectBudgetTable extends Model {
    @JsonProperty('id')
    id: string;

    @JsonProperty('siteId')
    siteId?: string;

    @JsonProperty('projectId')
    projectId?: string;

    @JsonProperty('name')
    name: string;

    @JsonProperty('isTemplate')
    isTemplate?: boolean;

    @JsonProperty('isDefaultTemplate')
    isDefaultTemplate?: boolean;

    @JsonProperty('dataSource')
    dataSource?: string;

    @JsonProperty('cellsMeta')
    cellsMeta?: CellProperties[];

    @JsonProperty('assignedValues')
    assignedValues?: BudgetAssigned;

    @JsonProperty('assignedMonthlyItems')
    assignedMonthlyItems?: MonthlyBudgetAssignedItem[];

    @JsonProperty('linkedValues')
    linkedValues: BudgetLinkedValue[] = [];

    @JsonProperty('isCompletixTemplate')
    isCompletixTemplate?: boolean;

    @JsonProperty('isPublished')
    isPublished?: boolean;

    @JsonProperty('isInitialTemplate')
    isInitialTemplate?: boolean;

    constructor(init?: Partial<ProjectBudgetTable>) {
        super();
        Object.assign(this, init);
    }
}

export interface BudgetTableCoords {
    row: number;
    col: number;
    sheet: number;
}

export interface BudgetAssignedItem {
    coords?: BudgetTableCoords;
    calculateAutomatically?: boolean;
}

export interface MonthlyBudgetAssignedItem {
    id: string;
    forecast?: BudgetAssignedValue;
    actuals?: BudgetAssignedValue;
}

export type MonthlyBudgetAssignedItemKey = 'forecast' | 'actuals';

export const monthlyBudgetAssginedItemKeys: MonthlyBudgetAssignedItemKey[] = [
    'forecast',
    'actuals',
];

export class BudgetAssignedValue implements ProjectBudgetEntityBase {
    total: BudgetAssignedItem;
    capex: BudgetAssignedItem;
    opex: BudgetAssignedItem;
}

const defaultBudgetAssignedValue: Required<BudgetAssignedValue> = {
    total: {},
    capex: {},
    opex: {},
};

export const defaultBudgetAssignedValues: Required<BudgetAssigned> = {
    plannedCost: defaultBudgetAssignedValue,
    actualToDate: defaultBudgetAssignedValue,
    currentFYForecast: defaultBudgetAssignedValue,
    currentFYActuals: defaultBudgetAssignedValue,
};

export class BudgetLinkedValue {
    linkedCoords: BudgetTableLinkedCoords[];
    link: BudgetLink;
    originObjectId?: string;
    linkedEntity: BudgetLinkedEntity;

    constructor(init?: Partial<BudgetLinkedValue>) {
        Object.assign(this, init);
    }
}

export enum BudgetLinkedEntity {
    Actual = 'Actual',
    Estimate = 'Estimate',
}

export interface BudgetTableLinkedCoords extends BudgetTableCoords {
    monthDate?: Date;
}

export enum BudgetLink {
    TotalApproved = 'Total Approved',
    TotalCrApproved = 'Total CR Approved',
    Approved = 'Approved',
    Resource = 'Resource',
    TotalResources = 'Resources total',
    Expense = 'Expense',
    TotalVendors = 'Vendors',
}

export interface BudgetTableCreateOptions {
    name: string;
    isTemplate?: boolean;
    projectId?: string;
    data?: any;
}
