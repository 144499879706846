import { JsonProperty } from 'app/utils/json-mapper';

export class SubscriptionRule {
    @JsonProperty('isEnabled')
    isEnabled: boolean = undefined;

    @JsonProperty('maxValue')
    maxValue?: number = undefined;

    @JsonProperty('addExtraItemAction')
    addExtraItemAction?: SubscriptionRuleAction = undefined;

    constructor(init?: Partial<SubscriptionRule>) {
        Object.assign(this, init);
    }
}

export interface SubscriptionRuleCreateOptions {
    isEnabled: boolean;
    maxValue?: number;
    addExtraItemAction?: SubscriptionRuleAction;
}

export interface SubscriptionRuleUpdateOptions {
    isEnabled?: boolean;
    maxValue?: number;
    addExtraItemAction?: SubscriptionRuleAction;
}

export enum SubscriptionRuleAction {
    None = 'none',
    Notify = 'notify',
    RequireApproval = 'requireApproval',
    Deny = 'deny',
}
