import { WorkWeekType } from 'app/core/models/calendar';
import { CtxColor } from 'app/core/models/shared-models';
import { ProjectListTableColumn } from 'app/shared-ui/dumb-components/project-list-table/project-list-table.data';
import { deepClone } from 'fast-json-patch';
import { Model } from '../../shared/models/base.model';

export class SiteSettings extends Model {
    id: string;
    siteId: string;
    options: SiteSettingOptions;
    statusColouring: SiteStatusColouring;
    allocationColouring: SiteAllocationColouring;
    customFields: SiteCustomField[];
    successRatingOptions: SuccessRatingOptions;
    name?: string;
    isCompletixTemplate?: boolean;
    isDefaultTemplate?: boolean;

    constructor(init?: Partial<SiteSettings>) {
        super();
        Object.assign(this, init);
    }
}

export interface SiteSettingOptions {
    capexOpexEnabled?: boolean;
    gatingEnabled?: boolean;
    changeRequestsEnabled?: boolean;
    vendorsEnabled?: boolean;
    effortEnabled?: boolean;
    allocationEnabled?: boolean;
    projectIntakeEnabled?: boolean;
    timesheetsEnabled?: boolean;
    allowUsersUpdateName?: boolean;
    currency: Currency;
    typicalHoursPerWeek: number;
    budgetCodeEnabled?: boolean;
    budgetFiscalsEnabled?: boolean;
    financialsEnabled?: boolean;
    dashboardTiles?: DashboardTilesOptions;
    executiveDashboardHiddenColumns?: ProjectListTableColumn[];
    typicalWorkingWeek?: WorkWeekType;
    currentFYStart?: Date;
    currentFYBudget?: number;
}

export interface DashboardTilesOptions {
    openProjectsProgramsEnables?: boolean;
    statusEnabled?: boolean;
    issuesEnabled?: boolean;
    overdueTasksEnabled?: boolean;
    openRisksEnabled?: boolean;
    totalTasksEnabled?: boolean;
    budgetForecastEnabled?: boolean;
    currentFYForecastEnabled?: boolean;
    scheduleVarianceEnabled?: boolean;
    projectRatingEnabled?: boolean;
}

export interface SiteSettingOption {
    name: SiteOptionName;
    enabled: boolean;
}

export enum Currency {
    Usd = 'USD',
    Eur = 'EUR',
    Jpy = 'JPY',
    Gbp = 'GBP',
    Aud = 'AUD',
    Cad = 'CAD',
    Chf = 'CHF',
    Cny = 'CNY',
}

export enum SiteOptionName {
    FullPlan = 'Cheap/Full',
}

export interface SiteStatusColouring {
    scheduleCriticalLevel: ScheduleColouringOptions;
    scheduleWarnLevel: ScheduleColouringOptions;
    taskCriticalLevel: TaskColouringOptions;
    taskWarnLevel: TaskColouringOptions;
}

export interface ScheduleColouringOptions {
    completePercent: number;
    durationPercent: number;
    enabled?: boolean;
}

export interface TaskColouringOptions {
    completePercent: number;
    durationDays: number;
    enabled?: boolean;
}

export interface SiteCustomField {
    id: string;
    enabled?: boolean;
    name: string;
    isRequired?: boolean;
}

export interface SiteAllocationColouring {
    zero: AllocationColouringOption;
    low: AllocationColouringOption;
    medium: AllocationColouringOption;
    high: AllocationColouringOption;
}

export interface AllocationColouringOption {
    value: number;
    color: CtxColor;
}

export interface SuccessRatingOptions {
    overdueTasks: SuccessRatingParameter;
    overBudget: SuccessRatingParameter;
    underBudget: SuccessRatingParameter;
    redStatus: SuccessRatingParameter;
    yellowStatus: SuccessRatingParameter;
    scheduleDelay: SuccessRatingParameter;
    averageIssueResolution: SuccessRatingParameter;
}

export interface SuccessRatingParameter {
    enabled?: boolean;
    value: number;
    weight: number;
}

export const defaultSuccessRatingOptions: Required<SuccessRatingOptions> = {
    overdueTasks: { enabled: true, value: 90, weight: 14 },
    overBudget: { enabled: true, value: 90, weight: 14 },
    underBudget: { enabled: true, value: 90, weight: 14 },
    redStatus: { enabled: true, value: 90, weight: 14 },
    yellowStatus: { enabled: true, value: 90, weight: 14 },
    scheduleDelay: { enabled: true, value: 90, weight: 14 },
    averageIssueResolution: { enabled: true, value: 90, weight: 14 },
};

export function getMutableSiteSettings(settings: SiteSettings): SiteSettings {
    const modifiedSiteSettings = deepClone({
        ...settings,
        options: {
            ...(settings?.options ?? {}),
            dashboardTiles: settings?.options?.dashboardTiles || {},
        },
        allocationColouring: {
            zero: settings.allocationColouring?.zero ?? {
                value: 0,
                color: CtxColor.Default,
            },
            low: settings.allocationColouring?.low ?? {
                value: 1,
                color: CtxColor.Green,
            },
            medium: settings.allocationColouring?.medium ?? {
                value: 80,
                color: CtxColor.Yellow,
            },
            high: settings.allocationColouring?.high ?? {
                value: 100,
                color: CtxColor.Red,
            },
        },
        successRatingOptions: settings.successRatingOptions ?? defaultSuccessRatingOptions,
    } as SiteSettings);
    return modifiedSiteSettings;
}
