import { JsonProperty } from 'app/utils/json-mapper';

export class InvoiceItem {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('description')
    description: string = undefined;

    @JsonProperty('quantity')
    quantity: string = undefined;

    @JsonProperty('unitPriceCents')
    unitPriceCents: string = undefined;

    @JsonProperty('discountRate')
    discountRate: string = undefined;

    @JsonProperty('tax1Name')
    tax1Name: string = undefined;

    @JsonProperty('tax1Rate')
    tax1Rate: string = undefined;

    @JsonProperty('tax2Name')
    tax2Name: string = undefined;

    @JsonProperty('tax2Rate')
    tax2Rate: string = undefined;

    @JsonProperty('subtotalCents')
    subtotalCents: string = undefined;

    @JsonProperty('discountCents')
    discountCents: string = undefined;

    @JsonProperty('grossAmountCents')
    grossAmountCents: string = undefined;
}
