import { JsonProperty } from 'app/utils/json-mapper';
import { Card } from './card.model';
import { PaymentMethod } from './subscription-plan.model';

export class PaymentMethodDetails {
    @JsonProperty('paymentMethod')
    paymentMethod: PaymentMethod = undefined;

    @JsonProperty('notes')
    notes: string = undefined;

    @JsonProperty({ name: 'card', clazz: Card })
    card: Card = undefined;
}
