import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EntityPatchSuccessResponse } from 'app/core/services/rest-api/features/patch.response';
import { getPatch } from 'app/utils/json-patch';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { KanbanBoardSelectors } from '.';
import { AppStore } from '..';
import { RestApiService } from '../../services/rest-api';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import { OptimisticDefaults } from '../shared/optimisticable-action';
import { LoadKanbanBoardsResponse } from './../../services/rest-api/features/kanban-board/kanban-board.responses';
import * as KanbanBoardActions from './kanban.actions';
import { KanbanBoardShape, KanbanColumnShape } from './models';

@Injectable()
export class KanbanBoardEffects {
    loadKanbanBoardsByProjectId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(KanbanBoardActions.loadKanbanBoardsByProjectId),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: LoadKanbanBoardsResponse) =>
                        KanbanBoardActions.loadKanbanBoardsByProjectIdSuccess({
                            boards: response.payload.map((dto) =>
                                Model.createFromDto(KanbanBoardShape, dto)
                            ),
                            projectId: action.payload.projectId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            KanbanBoardActions.loadKanbanBoardsByProjectIdFail({
                                error,
                                projectId: action.payload.projectId,
                            })
                        )
                    )
                )
            )
        )
    );

    loadKanbanBoardsBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(KanbanBoardActions.loadKanbanBoardsBySiteId),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: LoadKanbanBoardsResponse) =>
                        KanbanBoardActions.loadKanbanBoardsBySiteIdSuccess({
                            boards: response.payload.map((dto) =>
                                Model.createFromDto(KanbanBoardShape, dto)
                            ),
                            siteId: action.payload.siteId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            KanbanBoardActions.loadKanbanBoardsBySiteIdFail({
                                error,
                                siteId: action.payload.siteId,
                            })
                        )
                    )
                )
            )
        )
    );

    updateKanbanBoard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(KanbanBoardActions.updateKanbanBoard),
            withLatestFrom(this.store$.select(KanbanBoardSelectors.selectAll)),
            mergeMap(([action, boards]) => {
                const board = boards.find((b) => b.id === action.payload.id);
                const patch = getPatch(board, action.payload.changes);
                return of(
                    KanbanBoardActions.patchKanbanBoard({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchKanbanBoard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(KanbanBoardActions.patchKanbanBoard),
            filter((action) => !!action.payload.patch?.length),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: EntityPatchSuccessResponse) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return KanbanBoardActions.patchKanbanBoardSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) =>
                        of(KanbanBoardActions.patchKanbanBoardFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    updateKanbanColumn$ = createEffect(() =>
        this.actions$.pipe(
            ofType(KanbanBoardActions.updateKanbanColumn),
            withLatestFrom(this.store$.select(KanbanBoardSelectors.selectAll)),
            mergeMap(([{ payload }, boards]) => {
                const board = boards.find((b) => b.id === payload.boardId);
                const column = board.columnShapes.find((col) => col.id === payload.columnId);
                const columnIdx = board.columnShapes.findIndex(
                    (col) => col.id === payload.columnId
                );
                const updatedColumn = new KanbanColumnShape({ ...column, ...payload.changes });
                const boardChanges: Partial<KanbanBoardShape> = {
                    columnShapes: [
                        ...board.columnShapes.slice(0, columnIdx),
                        updatedColumn,
                        ...board.columnShapes.slice(columnIdx + 1),
                    ],
                };
                return of(
                    KanbanBoardActions.updateKanbanBoard({
                        payload: {
                            id: payload.boardId,
                            changes: boardChanges,
                        },
                        options: OptimisticDefaults,
                    })
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>
    ) {}
}
