import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    GetDecisionByIdResponse,
    LoadDecisionsResponse,
} from 'app/core/services/rest-api/features/decision/decision.responses';
import { EntityPatchSuccessResponse } from 'app/core/services/rest-api/features/patch.response';
import { ExtendedDecision } from 'app/features/project/raid-page/decisions-raid/decisions-raid-data';
import { getPatch } from 'app/utils/json-patch';
import { of } from 'rxjs';
import {
    catchError,
    concatMap,
    first,
    map,
    mergeMap,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { AppStore } from '..';
import { SnackBarService } from '../../../services/snack-bar/snack-bar.service';
import { CommandResult } from '../../../utils/network/commands/base.command';
import { RestApiService } from '../../services/rest-api';
import { ApprovalRequestActions } from '../approval-request';
import { DocumentActions, FileNode } from '../document';
import { okEmptyAction } from '../project/project.actions';
import { selectAccountSiteMembership } from '../shared/account-mebership.selectors';
import { Model } from '../shared/models/base.model';
import * as DecisionActions from './decision.actions';
import * as DecisionSelectors from './decision.selectors';
import { DecisionRecord } from './models/decision.model';

@Injectable()
export class DecisionEffects {
    loadDecisionBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DecisionActions.loadDecisionsBySiteId),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: LoadDecisionsResponse) =>
                        DecisionActions.loadDecisionsBySiteIdSuccess({
                            decisions: response.payload.map((dto) => this.getDecisionFromDto(dto)),
                        })
                    ),
                    catchError((error) => of(DecisionActions.loadDecisionsBySiteIdFail({ error })))
                )
            )
        )
    );

    loadDecisionByProjectId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DecisionActions.loadDecisionsByProjectId),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: LoadDecisionsResponse) =>
                        DecisionActions.loadDecisionsByProjectIdSuccess({
                            decisions: response.payload.map((dto) => this.getDecisionFromDto(dto)),
                        })
                    ),
                    catchError((error) =>
                        of(DecisionActions.loadDecisionsByProjectIdFail({ error }))
                    )
                )
            )
        )
    );

    getDecisionById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DecisionActions.getDecisionById),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    switchMap((response: GetDecisionByIdResponse) => [
                        DecisionActions.getDecisionByIdSuccess({
                            decision: Model.createFromDto(DecisionRecord, response.payload),
                        }),
                        DocumentActions.getManyDocumentsSuccess({
                            documents: response.payload.documents.map((d) =>
                                Model.createFromDto(FileNode, d)
                            ),
                        }),
                    ]),
                    catchError((error) => of(DecisionActions.getDecisionByIdFail({ error })))
                )
            )
        )
    );

    addDecision$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DecisionActions.addDecision),
            concatMap((action) => {
                if (!action.options?.optimistic) {
                    this.snackBar.showInfiniteBar('Adding a new Decision...');
                }
                return this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        if (action.options?.optimistic) {
                            this.snackBar.hide();
                        }
                        return DecisionActions.addDecisionSuccess({
                            decision: this.getDecisionFromDto(response.payload),
                            options: action.options,
                        });
                    }),
                    catchError((error) => {
                        this.snackBar.hide();
                        return of(
                            DecisionActions.addDecisionFail({
                                error,
                                originAction: action,
                                decisionId: action.payload.id,
                            })
                        );
                    })
                );
            })
        )
    );

    updateDecision$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DecisionActions.updateDecision),
            withLatestFrom(this.store$.select(DecisionSelectors.selectAll)),
            mergeMap(([action, decisions]) => {
                const timesheet = decisions.find((r) => r.id === action.payload.id);
                const notUpdatableProps: (keyof ExtendedDecision)[] = ['creationDate'];
                const patch = getPatch(timesheet, action.payload.changes).filter(
                    (op) => !notUpdatableProps.some((prop) => op.path.includes(prop))
                );
                return of(
                    DecisionActions.patchDecision({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchDecision$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DecisionActions.patchDecision),
            mergeMap((action) =>
                this.store$
                    .select(DecisionSelectors.selectEntityStoredInDb(action.payload.id))
                    .pipe(
                        first((stored) => stored),
                        switchMap(() =>
                            this.restApiService.process(action).pipe(
                                map((response: EntityPatchSuccessResponse) => {
                                    if (action.options?.optimistic) {
                                        return DecisionActions.patchDecisionOptimisticSuccess({
                                            id: action.payload.id,
                                        });
                                    }
                                    return DecisionActions.patchDecisionSuccess({
                                        id: action.payload.id,
                                        patch: response.payload,
                                    });
                                }),
                                catchError((error) =>
                                    of(
                                        DecisionActions.patchDecisionFail({
                                            error,
                                            originAction: action,
                                            decisionId: action.payload.id,
                                        })
                                    )
                                )
                            )
                        )
                    )
            )
        )
    );

    deleteDecision$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DecisionActions.deleteDecision),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return DecisionActions.deleteDecisionSuccess({ id: action.payload.id });
                    }),
                    catchError((error) =>
                        of(DecisionActions.deleteDecisionFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    submitDecision$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DecisionActions.submitDecision),
            mergeMap((action) => {
                this.snackBar.showInfiniteBar('Submitting the Decision...');
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBar.hide()),
                    map((response: EntityPatchSuccessResponse) =>
                        DecisionActions.patchDecisionSuccess({
                            id: action.payload.decisionId,
                            patch: response.payload,
                        })
                    ),
                    catchError((error) => {
                        this.snackBar.hide();
                        return of(
                            DecisionActions.patchDecisionFail({
                                error,
                                originAction: action,
                                decisionId: action.payload.decisionId,
                            })
                        );
                    })
                );
            })
        )
    );

    manageDecisionApproval$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DecisionActions.manageDecisionApproval),
            withLatestFrom(
                this.store$.select(selectAccountSiteMembership),
                this.store$.select(DecisionSelectors.selectAll)
            ),
            mergeMap(([action, accountSiteMember, decisions]) => {
                this.snackBar.showInfiniteBar('Processing an Approval...');
                const decision = decisions.find((d) =>
                    d.assignedMembers?.some((m) => m.id === action.payload.decisionApproverId)
                );
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBar.hide()),
                    switchMap((response: EntityPatchSuccessResponse) => {
                        return [
                            DecisionActions.patchDecisionSuccess({
                                id: decision.id,
                                patch: response.payload,
                            }),
                            ApprovalRequestActions.updateApprovalState({
                                id: action.payload.approvalId,
                                siteMemberId: accountSiteMember.id,
                                state: action.payload.approvalState,
                            }),
                        ];
                    }),
                    catchError((error) => {
                        this.snackBar.hide();
                        return of(
                            DecisionActions.patchDecisionFail({
                                error,
                                originAction: action,
                                decisionId: decision.id,
                            })
                        );
                    })
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>,
        private snackBar: SnackBarService
    ) {}

    getDecisionFromDto(dto: any): DecisionRecord {
        return {
            ...Model.createFromDto(DecisionRecord, dto),
            date: dto.date ? new Date(dto.date) : undefined,
        };
    }
}
