import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectSelectors } from '../project';
import { SiteMemberSelectors } from '../resource';
import { selectAccountSiteMembership } from '../shared/account-mebership.selectors';
import { SiteSelectors } from '../site';
import { StateEntry } from '../state-entry';
import { adapter, LoadingStatus, State } from './approval-request.store';
import { ApprovalState, ApprovalType } from './models';

export const selectApprovalRequestEntry = createFeatureSelector<State>(StateEntry.ApprovalRequest);

export const selectLoadingStatus = createSelector(
    selectApprovalRequestEntry,
    (state): LoadingStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
    })
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectApprovalRequestEntry);

export const selectExtendedApprovals = createSelector(
    selectAll,
    ProjectSelectors.selectAll,
    SiteMemberSelectors.selectAll,
    selectAccountSiteMembership,
    (approvals, projects, siteMembers, currentAccountSiteMember) =>
        approvals
            .filter(
                (a) =>
                    a.type !== ApprovalType.SiteOwnerChange ||
                    a.approverSiteMemberIds.includes(currentAccountSiteMember?.id)
            )
            .map((approval) => {
                const siteMember = siteMembers.find((m) => m.id === approval.authorSiteMemberId);
                const approverNames = siteMembers
                    .filter((sm) => approval.approverSiteMemberIds?.includes(sm.id))
                    .map((sm) => sm.name ?? sm.email);
                return {
                    ...approval,
                    authorSiteMemberName: siteMember?.name ?? approval.authorSiteMemberName,
                    authorSiteMemberEmail: siteMember?.email ?? approval.authorSiteMemberEmail,
                    authorImageDataUrl: siteMember?.imageDataUrl ?? approval.authorImageDataUrl,
                    authorAvatarColor: siteMember?.avatarColor ?? approval.authorAvatarColor,
                    projectName:
                        projects.find((p) => p.id === approval.projectId)?.name ??
                        approval.projectName,
                    approverSiteMemberNames: approverNames?.length
                        ? approverNames
                        : approval.approverSiteMemberNames,
                };
            })
);

export const selectCurrentSiteApprovals = createSelector(
    selectExtendedApprovals,
    SiteSelectors.selectCurrentSiteId,
    (approvals, siteId) => approvals.filter((a) => a.siteId === siteId)
);

export const selectCurrentSiteMyApprovals = createSelector(
    selectCurrentSiteApprovals,
    selectAccountSiteMembership,
    (approvals, siteMember) =>
        approvals.filter((a) => a.approverSiteMemberIds?.includes(siteMember?.id))
);

export const selectMyApprovalsPendingCount = createSelector(
    selectCurrentSiteMyApprovals,
    (approvals) => approvals.filter((a) => a.state === ApprovalState.Pending).length
);
