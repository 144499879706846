import { Router } from '@angular/router';
import { HubConnection } from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { Subscription } from 'app/core/models/billing';
import { Calendar } from 'app/core/models/calendar';
import { SiteResponses } from 'app/core/services/rest-api/features/site';
import { AppStore } from 'app/core/store';
import { ActionActions, ActionRecord } from 'app/core/store/actions';
import { AllocationActions, ExtendedProjectMemberAllocation } from 'app/core/store/allocation';
import { ApprovalRequest, ApprovalRequestActions } from 'app/core/store/approval-request';
import { BacklogTask, BacklogTaskActions } from 'app/core/store/backlog-task';
import { BudgetTableActions, ProjectBudgetTable } from 'app/core/store/budget-table';
import { CalendarActions } from 'app/core/store/calendar';
import { ChangeRequest, ChangeRequestActions } from 'app/core/store/change-request';
import { CommentActions, CtxComment } from 'app/core/store/comment';
import { Communication, CommunicationActions } from 'app/core/store/communication';
import { DecisionActions, DecisionRecord } from 'app/core/store/decision';
import { DocumentActions, FileNode } from 'app/core/store/document';
import { Folder, FolderActions } from 'app/core/store/folder';
import { GlTableSettings, GlTableSettingsActions } from 'app/core/store/gl-table-settings';
import { IssueActions, IssueRecord } from 'app/core/store/issue';
import { KanbanBoardActions, KanbanBoardShape } from 'app/core/store/kanban';
import { Minute, MinuteActions } from 'app/core/store/minute';
import { Program, ProgramActions } from 'app/core/store/program';
import { ProgramMember, ProgramMemberActions } from 'app/core/store/program-member';
import { ProgramRoleActions } from 'app/core/store/program-role';
import { ProgramStatusActions } from 'app/core/store/program-status-report';
import { ProgramStatusReport } from 'app/core/store/program-status-report/models/program-status-report.model';
import {
    Project,
    ProjectActions,
    ProjectRaciTable,
    ProjectSelectors,
    ProjectSettings,
} from 'app/core/store/project';
import { ProjectFieldActions } from 'app/core/store/project-field';
import { ProjectMember, ProjectMemberActions } from 'app/core/store/project-member';
import { ProjectPhase, ProjectPhaseActions } from 'app/core/store/project-phase';
import { ListContainer, ListContainerActions } from 'app/core/store/project-phase-container';
import { ProjectRequest, ProjectRequestActions } from 'app/core/store/project-request';
import { ProjectRoleActions } from 'app/core/store/project-role';
import { ProjectStatusActions, ProjectStatusReport } from 'app/core/store/project-status-report';
import {
    ImportCompletixProjectTemplateOptions,
    ProjectTemplate,
    ProjectTemplateActions,
} from 'app/core/store/project-template';
import { SiteMember, SiteMemberActions } from 'app/core/store/resource';
import { RiskActions, RiskRecord } from 'app/core/store/risks';
import { ScheduleTask, ScheduleTaskActions } from 'app/core/store/schedule-template';
import { PatchScheduleTaskPayload } from 'app/core/store/schedule-template/schedule-task.payloads';
import { Model } from 'app/core/store/shared/models/base.model';
import { getDefaultLogo, SiteActions } from 'app/core/store/site';
import { SiteRole, SiteRoleActions } from 'app/core/store/site-role';
import { SiteSettings, SiteSettingsActions } from 'app/core/store/site-settings';
import { Sprint, SprintActions } from 'app/core/store/sprint';
import { SubscriptionActions } from 'app/core/store/subscription';
import { TimesheetActions, TimesheetSelectors } from 'app/core/store/timesheet';
import { UserLog, UserLogActions } from 'app/core/store/user-log';
import { RouteBrick } from 'app/route-brick';
import { SignalRCommand, SignalRGateway, SignalRPayload } from 'app/utils/network';
import { Operation } from 'fast-json-patch';
import { filter, first, take } from 'rxjs/operators';
import { ImportListContainerResponse } from '../rest-api/features/list-container/list-container.responses';
import { ImportMicrosoftProjectResponsePayload } from '../rest-api/features/project/project.responses';
import { AddSprintResponsePayload } from '../rest-api/features/sprint/sprint.responses';

export interface SignalrEntityPatchResponse {
    id: string;
    patch: Operation[];
}

export class SiteHubClient {
    private readonly endpoint = '/site';
    private readonly reconnectTimeout = 2000;

    private connection: HubConnection;
    private connected = false;
    private reconnectTimer: NodeJS.Timer = null;

    private listeningSiteId: string;

    constructor(
        private signalRGateway: SignalRGateway,
        private store: Store<AppStore.AppState>,
        private router: Router
    ) {}

    connect() {
        if (this.connection || this.reconnectTimer) return;

        this.connection = this.signalRGateway.buildConnection(this.endpoint);
        this.registerHandlers(this.connection);

        this.connection.onclose((error?: Error) => {
            console.log('SignalR: connection closed', error);

            const disconnectedUnexpectedly = this.connection != null;
            if (disconnectedUnexpectedly) {
                this.clearCurrentConnection();
                this.scheduleConnect();
            }
        });

        this.connection
            .start()
            .then(() => {
                console.log('SignalR: connected');
                this.connected = true;
                this.onConnected();
            })
            .catch((error) => {
                this.clearCurrentConnection();
                this.scheduleConnect();
            });
    }

    disconnect(): Promise<void> {
        if (!this.connection) return;
        const currentConnection = this.connection;
        this.clearCurrentConnection();
        return currentConnection.stop();
    }

    registerHandlers(connection: HubConnection) {
        connection.on('SiteUpdated', (data) => {
            // TODO: refactor this method
            const site = new SiteResponses.GetSiteByIdResponse({ command: null, payload: data })
                .site;
            site.logo = getDefaultLogo();
            console.log('SignalR message received: SiteUpdated', data, site);
            this.store.dispatch(SiteActions.getSiteByIdSuccess({ site }));
        });

        connection.on('SitePatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: Sitepatched', data);
            this.store.dispatch(
                SiteActions.patchSiteSuccess({ siteId: data.id, patch: data.patch })
            );
        });

        connection.on('SubscriptionUpdated', (data) => {
            const subscription = Model.createFromDto(Subscription, data);
            console.log('SignalR message received: SubscriptionUpdated', data, subscription);
            this.store.dispatch(SubscriptionActions.getActiveSubscriptionSuccess({ subscription }));
        });

        connection.on('SiteSettingsPatched', (data: SignalrEntityPatchResponse) => {
            const settings = Model.createFromDto(SiteSettings, data);
            console.log('SignalR message received: SiteSettingsPatched', data, settings);
            this.store.dispatch(
                SiteSettingsActions.patchSiteSettingsSuccess({ id: settings.id, patch: data.patch })
            );
        });

        connection.on('SiteOwnerChanged', (siteMemberPatches) => {
            console.log('SignalR message received: SiteOwnerChanged', siteMemberPatches);
            siteMemberPatches.forEach((patchRecord) =>
                this.store.dispatch(
                    SiteMemberActions.patchSiteMemberSuccess({
                        id: patchRecord.id,
                        patch: patchRecord.patch,
                    })
                )
            );
        });

        // Project handlers
        connection.on('ProjectUpdated', (data) => {
            const project = Model.createFromDto(Project, data);
            console.log('SignalR message received: ProjectUpdated', data, project);
            this.store.dispatch(ProjectActions.getProjectSuccess({ project }));
        });

        connection.on('ProjectPatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: ProjectPatched', data);
            this.store.dispatch(
                ProjectActions.patchProjectSuccess({
                    id: data.id,
                    patch: data.patch,
                })
            );
        });

        connection.on('ProjectCreated', (data) => {
            const project = Model.createFromDto(Project, data);
            console.log('SignalR message received: ProjectCreated', data, project);
            this.store.dispatch(ProjectActions.getProjectSuccess({ project }));
        });

        connection.on('ProjectDeleted', async (projectId: string) => {
            console.log('SignalR message received: ProjectDeleted', projectId);
            if (this.router.url.includes(projectId)) {
                // wait until the route will be changed before the project delete
                await this.router.navigate([RouteBrick.MyWork]).finally();
            }
            this.store.dispatch(ProjectActions.deleteProjectSuccess({ projectId }));
        });

        connection.on('ProjectSettingsPatched', (data: SignalrEntityPatchResponse) => {
            const settings = Model.createFromDto(ProjectSettings, data);
            console.log('SignalR message received: ProjectSettingsPatched', data);
            this.store.dispatch(
                ProjectActions.patchProjectSettingsSuccess({
                    projectId: data.id,
                    patch: data.patch,
                })
            );
        });

        // Program handlers
        connection.on('ProgramPatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: ProgramPatched', data);
            this.store.dispatch(
                ProgramActions.patchProgramSuccess({ programId: data.id, patch: data.patch })
            );
        });

        connection.on('ProgramCreated', (data) => {
            const program = Model.createFromDto(Program, data);
            console.log('SignalR message received: ProgramCreated', data, program);
            this.store.dispatch(ProgramActions.addProgramSuccess({ program }));
        });

        connection.on('ProgramDeleted', (programId: string) => {
            console.log('SignalR message received: ProgramDeleted', programId);
            this.store.dispatch(ProgramActions.deleteProgramSuccess({ payload: { programId } }));
        });

        // Site member handlers
        connection.on('SiteMemberPatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: SiteMemberPatched', data);
            this.store.dispatch(
                SiteMemberActions.patchSiteMemberSuccess({ id: data.id, patch: data.patch })
            );
        });

        connection.on('SiteMembersInvited', (data) => {
            const members = data.map((m) => Model.createFromDto(SiteMember, m));
            console.log('SignalR message received: SiteMembersInvited', data, members);
            this.store.dispatch(SiteMemberActions.inviteSiteMembersToSiteSuccess({ members }));
        });

        connection.on('SiteMemberDeleted', (data) => {
            const memberId: string = data;
            console.log('SignalR message received: SiteMemberDeleted', data, memberId);
            this.store.dispatch(SiteMemberActions.deleteSiteMemberSuccess({ id: memberId }));
        });

        // Project member handlers
        connection.on('ProjectMemberPatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: ProjectMemberPatched', data.patch);
            this.store.dispatch(
                ProjectMemberActions.patchProjectMemberSuccess({
                    id: data.id,
                    patch: data.patch,
                })
            );
        });

        connection.on('ProjectMemberCreated', (data) => {
            const member = Model.createFromDto(ProjectMember, data);
            console.log('SignalR message received: ProjectMemberCreated', data, member);
            this.store.dispatch(ProjectMemberActions.inviteProjectMemberSuccess({ member }));
        });

        connection.on('ProjectMemberDeleted', (memberId: string) => {
            console.log('SignalR message received: ProjectMemberDeleted', memberId);
            this.store.dispatch(
                ProjectMemberActions.deleteProjectMemberSuccess({ id: memberId, deleted: true })
            );
        });

        connection.on('ProjectMemberReOpened', (data) => {
            const member = Model.createFromDto(ProjectMember, data);
            console.log('SignalR message received: ProjectMemberReOpened', data, member);
            this.store.dispatch(ProjectMemberActions.getProjectMemberSuccess({ member }));
            this.store
                .select(ProjectSelectors.selectCurrentSiteProjects)
                .pipe(
                    take(1),
                    filter((projects) => !projects?.some((p) => p.id === member.projectId))
                )
                .subscribe(() =>
                    this.store.dispatch(
                        ProjectActions.getProject({ payload: { projectId: member.projectId } })
                    )
                );
        });

        // Project RACI handlers
        connection.on('ProjectRaciUpdated', (table: ProjectRaciTable) => {
            console.log('SignalR message received: ProjectRaciUpdated', table);
            this.store.dispatch(
                ProjectActions.getRaciTableByProjectSuccess({
                    table,
                })
            );
        });

        // Allocation handlers
        connection.on('AllocationPatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: AllocationPatched', data.patch);
            this.store.dispatch(
                AllocationActions.patchAllocationSuccess({
                    id: data.id,
                    patch: data.patch,
                })
            );
        });

        connection.on('AllocationCreated', (data) => {
            const allocation = Model.createFromDto(ExtendedProjectMemberAllocation, data);
            console.log('SignalR message received: AllocationCreated', data, allocation);
            this.store.dispatch(AllocationActions.addAllocationSuccess({ allocation }));
        });

        connection.on('AllocationDeleted', (allocationId: string) => {
            console.log('SignalR message received: AllocationDeleted', allocationId);
            this.store.dispatch(AllocationActions.deleteAllocationSuccess({ id: allocationId }));
        });

        // Program member handlers
        connection.on('ProgramMemberUpdated', (data) => {
            const member = Model.createFromDto(ProgramMember, data);
            console.log('SignalR message received: ProgramMemberUpdated', data, member);
            this.store.dispatch(ProgramMemberActions.patchProgramMemberSuccess({ member }));
        });

        connection.on('ProgramMemberCreated', (data) => {
            const member = Model.createFromDto(ProgramMember, data);
            console.log('SignalR message received: ProgramMemberCreated', data, member);
            this.store.dispatch(ProgramMemberActions.inviteProgramMemberSuccess({ member }));
        });

        connection.on('ProgramMemberDeleted', (memberId: string) => {
            console.log('SignalR message received: ProgramMemberDeleted', memberId);
            this.store.dispatch(ProgramMemberActions.deleteProgramMemberSuccess({ id: memberId }));
        });

        // Program status report handlers
        connection.on('ProgramStatusReportCreated', (data) => {
            const report = Model.createFromDto(ProgramStatusReport, data);
            console.log('SignalR message received: ProgramStatusReportCreated', data, report);
            this.store.dispatch(ProgramStatusActions.addProgramStatusSuccess({ report }));
        });

        connection.on('ProgramStatusReportPatched', (patchResponse: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: ProgramStatusReportPatched', patchResponse);
            this.store.dispatch(
                ProgramStatusActions.patchProgramStatusSuccess({
                    id: patchResponse.id,
                    patch: patchResponse.patch,
                })
            );
        });

        connection.on('ProgramStatusReportDeleted', (reportId: string) => {
            console.log('SignalR message received: ProgramStatusReportDeleted', reportId);
            this.store.dispatch(ProgramStatusActions.deleteProgramStatusSuccess({ id: reportId }));
        });

        // Approvals handler
        connection.on('ApprovalsListUpdated', (data: any[]) => {
            const approvals = data.map((dto) => Model.createFromDto(ApprovalRequest, dto));
            console.log('SignalR message received: ApprovalsListUpdated', data, approvals);
            this.store.dispatch(
                ApprovalRequestActions.loadApprovalRequestsSuccess({ requests: approvals })
            );
        });

        connection.on('ApprovalPendingCreated', (data) => {
            const approval = Model.createFromDto(ApprovalRequest, data);
            console.log('SignalR message received: ApprovalPendingCreated', data, approval);
            this.store.dispatch(ApprovalRequestActions.addPendingApproval({ approval }));
        });

        connection.on('ApprovalCreated', (data) => {
            const approval = Model.createFromDto(ApprovalRequest, data);
            console.log('SignalR message received: ApprovalCreated', data, approval);
            this.store.dispatch(ApprovalRequestActions.addApproval({ approval }));
        });

        connection.on('ApprovalPendingDeleted', (approvalOriginObjectId: string) => {
            console.log('SignalR message received: ApprovalPendingDeleted', approvalOriginObjectId);
            this.store.dispatch(
                ApprovalRequestActions.deletePendingApproval({ id: approvalOriginObjectId })
            );
        });

        connection.on('ApprovalByProjectDeleted', (projectId: string) => {
            console.log('SignalR message received: ApprovalByProjectDeleted', projectId);
            this.store.dispatch(
                ApprovalRequestActions.deleteApprovalByProjectId({ id: projectId })
            );
        });

        // ProjectFields handlers
        connection.on('ProjectFieldPatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: ProjectFieldPatched', data);
            this.store.dispatch(
                ProjectFieldActions.patchProjectFieldSuccess({ id: data.id, patch: data.patch })
            );
        });

        // Document handlers
        connection.on('DocumentPatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: DocumentPatched', data);
            this.store.dispatch(
                DocumentActions.patchDocumentSuccess({ id: data.id, patch: data.patch })
            );
        });

        connection.on('DocumentCreated', (data) => {
            const document = Model.createFromDto(FileNode, data);
            console.log('SignalR message received: DocumentCreated', data, document);
            this.store.dispatch(DocumentActions.addDocumentSuccess({ document }));
        });

        connection.on('DocumentDeleted', (documentId: string) => {
            console.log('SignalR message received: DocumentDeleted', documentId);
            this.store.dispatch(DocumentActions.deleteDocumentSuccess({ ids: [documentId] }));
        });

        connection.on('DocumentsManyDeleted', (documentIds: string[]) => {
            console.log('SignalR message received: DocumentsManyDeleted', documentIds);
            documentIds.forEach((id) =>
                this.store.dispatch(DocumentActions.deleteDocumentSuccess({ ids: [id] }))
            );
        });

        // Project request handlers
        connection.on('ProjectRequestPatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: ProjectRequestPatched', data);
            this.store.dispatch(
                ProjectRequestActions.patchProjectRequestSuccess({ id: data.id, patch: data.patch })
            );
        });

        connection.on('ProjectRequestCreated', (data) => {
            const request = Model.createFromDto(ProjectRequest, data);
            console.log('SignalR message received: ProjectRequestCreated', data, request);
            this.store.dispatch(ProjectRequestActions.addProjectRequestSuccess({ request }));
        });

        connection.on('ProjectRequestDeleted', (requestId: string) => {
            console.log('SignalR message received: ProjectRequestDeleted', requestId);
            this.store.dispatch(
                ProjectRequestActions.deleteProjectRequestSuccess({ id: requestId })
            );
        });

        // Backlog task handlers
        connection.on('BacklogTaskPatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: BacklogTaskPatched', data);
            this.store.dispatch(
                BacklogTaskActions.patchBacklogTaskSuccess({
                    id: data.id,
                    patch: data.patch,
                })
            );
        });

        connection.on('BacklogTaskCreated', (data) => {
            const backlogTask = Model.createFromDto(BacklogTask, data);
            console.log('SignalR message received: BacklogTaskCreated', data, backlogTask);
            this.store.dispatch(BacklogTaskActions.addBacklogTaskSuccess({ backlogTask }));
        });

        connection.on('BacklogTaskDeleted', (taskId: string) => {
            console.log('SignalR message received: BacklogTaskDeleted', taskId);
            this.store.dispatch(BacklogTaskActions.deleteBacklogTaskSuccess({ id: taskId }));
        });

        // Sprint handlers
        connection.on('SprintPatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: SprintPatched', data);
            this.store.dispatch(
                SprintActions.patchSprintSuccess({ id: data.id, patch: data.patch })
            );
        });

        connection.on('SprintCreated', (data: AddSprintResponsePayload) => {
            const sprint = Model.createFromDto(Sprint, data.sprint);
            const kanbanBoard = Model.createFromDto(KanbanBoardShape, data.kanbanBoard);
            console.log('SignalR message received: SprintCreated', data, sprint, kanbanBoard);
            this.store.dispatch(SprintActions.addSprintSuccess({ sprint }));
            this.store.dispatch(KanbanBoardActions.addKanbanBoard({ board: kanbanBoard }));
        });

        connection.on('SprintDeleted', (sprintId: string) => {
            console.log('SignalR message received: SprintDeleted', sprintId);
            this.store.dispatch(SprintActions.deleteSprintSuccess({ id: sprintId }));
        });

        // Kanban board handlers
        connection.on('KanbanBoardPatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: KanbanBoardPatched', data);
            this.store.dispatch(
                KanbanBoardActions.patchKanbanBoardSuccess({
                    id: data.id,
                    patch: data.patch,
                })
            );
        });

        connection.on('KanbanBoardDeleted', (boardId: string) => {
            console.log('SignalR message received: KanbanBoardDeleted', boardId);
            this.store.dispatch(KanbanBoardActions.deleteKanbanBoard({ boardId }));
        });

        // ProjectStatusReport handlers
        connection.on('ProjectStatusReportPatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: ProjectStatusReportPatched', data);
            this.store.dispatch(
                ProjectStatusActions.patchProjectStatusSuccess({
                    id: data.id,
                    patch: data.patch,
                })
            );
        });

        connection.on('ProjectStatusReportCreated', (data) => {
            const report = Model.createFromDto(ProjectStatusReport, data);
            console.log('SignalR message received: ProjectStatusReportCreated', data, report);
            this.store.dispatch(ProjectStatusActions.addProjectStatusSuccess({ report }));
        });

        connection.on('ProjectStatusReportDeleted', (reportId: string) => {
            console.log('SignalR message received: ProjectStatusReportDeleted', reportId);
            this.store.dispatch(ProjectStatusActions.deleteProjectStatusSuccess({ id: reportId }));
        });

        // Risk handlers
        connection.on('ProjectRiskPatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: ProjectRiskPatched', data);
            this.store.dispatch(RiskActions.patchRiskSuccess({ id: data.id, patch: data.patch }));
        });

        connection.on('ProjectRiskCreated', (data) => {
            const risk = Model.createFromDto(RiskRecord, data);
            console.log('SignalR message received: ProjectRiskCreated', data, risk);
            this.store.dispatch(RiskActions.addRiskSuccess({ risk }));
        });

        connection.on('ProjectRiskDeleted', (riskId: string) => {
            console.log('SignalR message received: ProjectRiskDeleted', riskId);
            this.store.dispatch(RiskActions.deleteRiskSuccess({ id: riskId }));
        });

        // Action handlers
        connection.on('ProjectActionPatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: ProjectActionPatched', data);
            this.store.dispatch(
                ActionActions.patchActionSuccess({ id: data.id, patch: data.patch })
            );
        });

        connection.on('ProjectActionCreated', (data) => {
            const action = Model.createFromDto(ActionRecord, data);
            console.log('SignalR message received: ProjectActionCreated', data, action);
            this.store.dispatch(ActionActions.addActionSuccess({ action }));
        });

        connection.on('ProjectActionDeleted', (riskId: string) => {
            console.log('SignalR message received: ProjectActionDeleted', riskId);
            this.store.dispatch(ActionActions.deleteActionSuccess({ id: riskId }));
        });

        // Issue handlers
        connection.on('ProjectIssuePatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: ProjectIssuePatched', data);
            this.store.dispatch(IssueActions.patchIssueSuccess({ id: data.id, patch: data.patch }));
        });

        connection.on('ProjectIssueCreated', (data) => {
            const issue = Model.createFromDto(IssueRecord, data);
            console.log('SignalR message received: ProjectIssueCreated', data, issue);
            this.store.dispatch(IssueActions.addIssueSuccess({ issue }));
        });

        connection.on('ProjectIssueDeleted', (issueId: string) => {
            console.log('SignalR message received: ProjectIssueDeleted', issueId);
            this.store.dispatch(IssueActions.deleteIssueSuccess({ id: issueId }));
        });

        // Decision handlers
        connection.on('ProjectDecisionPatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: ProjectDecisionPatched', data);
            this.store.dispatch(
                DecisionActions.patchDecisionSuccess({ id: data.id, patch: data.patch })
            );
        });

        connection.on('ProjectDecisionCreated', (data) => {
            const decision = Model.createFromDto(DecisionRecord, data);
            console.log('SignalR message received: ProjectDecisionCreated', data, decision);
            this.store.dispatch(DecisionActions.addDecisionSuccess({ decision }));
        });

        connection.on('ProjectDecisionDeleted', (decisionId: string) => {
            console.log('SignalR message received: ProjectDecisionDeleted', decisionId);
            this.store.dispatch(DecisionActions.deleteDecisionSuccess({ id: decisionId }));
        });

        // Change request handlers
        connection.on('ChangeRequestPatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: ChangeRequestPatched', data);
            this.store.dispatch(
                ChangeRequestActions.patchChangeRequestSuccess({
                    id: data.id,
                    patch: data.patch,
                })
            );
        });

        connection.on('ChangeRequestCreated', (data) => {
            const changeRequest = Model.createFromDto(ChangeRequest, data);
            console.log('SignalR message received: ChangeRequestCreated', data, changeRequest);
            this.store.dispatch(ChangeRequestActions.addChangeRequestSuccess({ changeRequest }));
        });

        connection.on('ChangeRequestDeleted', (requestId: string) => {
            console.log('SignalR message received: ChangeRequestDeleted', requestId);
            this.store.dispatch(ChangeRequestActions.deleteChangeRequestSuccess({ id: requestId }));
        });

        // Minute handlers
        connection.on('MeetingMinutePatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: MeetingMinutePatched', data);
            this.store.dispatch(
                MinuteActions.patchMinuteSuccess({ id: data.id, patch: data.patch })
            );
        });

        connection.on('MeetingMinuteCreated', (data) => {
            const minute = Model.createFromDto(Minute, data);
            console.log('SignalR message received: MeetingMinuteCreated', data, minute);
            this.store.dispatch(MinuteActions.addMinuteSuccess({ minute }));
        });

        connection.on('MeetingMinuteDeleted', (minuteId: string) => {
            console.log('SignalR message received: MeetingMinuteDeleted', minuteId);
            this.store.dispatch(MinuteActions.deleteMinuteSuccess({ id: minuteId }));
        });

        // Folder handlers
        connection.on('FolderPatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: FolderPatched', data);
            this.store.dispatch(
                FolderActions.patchFolderSuccess({ id: data.id, patch: data.patch })
            );
        });

        connection.on('FolderCreated', (data) => {
            const folder = Model.createFromDto(Folder, data);
            console.log('SignalR message received: FolderCreated', data, folder);
            this.store.dispatch(FolderActions.addFolderSuccess({ folder }));
        });

        connection.on('FolderDeleted', (folderId: string) => {
            console.log('SignalR message received: FolderDeleted', folderId);
            this.store.dispatch(FolderActions.deleteFolderSuccess({ id: folderId }));
        });

        // Schedule handlers

        connection.on('ScheduleTasksPatched', (data: PatchScheduleTaskPayload[]) => {
            console.log('SignalR message received: ScheduleTasksPatched', data);
            this.store.dispatch(ScheduleTaskActions.patchScheduleTasksSuccess({ payload: data }));
        });

        connection.on('ScheduleTaskCreated', (data) => {
            const task = Model.createFromDto(ScheduleTask, data);
            console.log('SignalR message received: ScheduleTaskCreated', data, task);
            this.store.dispatch(ScheduleTaskActions.addScheduleTaskSuccess({ task }));
        });

        connection.on('ScheduleTaskDeleted', (taskId: string) => {
            console.log('SignalR message received: ScheduleTaskDeleted', taskId);
            this.store.dispatch(ScheduleTaskActions.deleteScheduleTaskSuccess({ id: taskId }));
        });

        // BudgetTable handlers
        connection.on('ProjectBudgetTablePatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: ProjectBudgetTablePatched', data);
            this.store.dispatch(
                BudgetTableActions.patchBudgetTableSuccess({
                    id: data.id,
                    patch: data.patch,
                })
            );
        });

        connection.on('ProjectBudgetTableCreated', (data) => {
            const table = Model.createFromDto(ProjectBudgetTable, data);
            console.log('SignalR message received: ProjectBudgetTableCreated', data, table);
            this.store.dispatch(BudgetTableActions.addBudgetTableSuccess({ table }));
        });

        connection.on('ProjectBudgetTableTemplateChanged', (data) => {
            const table = Model.createFromDto(ProjectBudgetTable, data);
            console.log('SignalR message received: ProjectBudgetTableTemplateChanged', data, table);
            this.store.dispatch(BudgetTableActions.changeBudgetTableTemplateSuccess({ table }));
        });

        connection.on('ProjectBudgetTableDeleted', (tableId: string) => {
            console.log('SignalR message received: ProjectBudgetTableDeleted', tableId);
            this.store.dispatch(
                BudgetTableActions.deleteBudgetTableSuccess({ payload: { id: tableId } })
            );
        });

        // ProjectTemplate handlers
        connection.on('ProjectTemplatePatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: ProjectTemplatePatched', data);
            this.store.dispatch(
                ProjectTemplateActions.patchProjectTemplateSuccess({
                    id: data.id,
                    patch: data.patch,
                })
            );
        });

        connection.on('ProjectTemplateCreated', (data) => {
            const template = Model.createFromDto(ProjectTemplate, data);
            console.log('SignalR message received: ProjectBudgetTableCreated', data, template);
            this.store.dispatch(ProjectTemplateActions.addProjectTemplateSuccess({ template }));
        });

        connection.on('ProjectTemplateImported', (data: ImportCompletixProjectTemplateOptions) => {
            const projectTemplate = Model.createFromDto(ProjectTemplate, data.projectTemplate);
            const scheduleTemplate = Model.createFromDto(ListContainer, data.scheduleTemplate);
            const projectCalendarTemplate = Model.createFromDto(
                Calendar,
                data.projectCalendarTemplate
            );
            const projectBudgetTableTemplate = Model.createFromDto(
                ProjectBudgetTable,
                data.projectBudgetTableTemplate
            );
            const projectPhaseContainerTemplate = Model.createFromDto(
                ListContainer,
                data.gatingTemplate
            );
            const communicationTemplate = Model.createFromDto(
                Communication,
                data.communicationTemplate
            );
            console.log('SignalR message received: ProjectTemplateImported', data, projectTemplate);
            this.store.dispatch(
                ProjectTemplateActions.addProjectTemplateSuccess({ template: projectTemplate })
            );
            this.store.dispatch(
                ListContainerActions.addListContainerSuccess({ container: scheduleTemplate })
            );
            this.store.dispatch(
                CalendarActions.addCalendarSuccess({ calendar: projectCalendarTemplate })
            );
            this.store.dispatch(
                BudgetTableActions.addBudgetTableSuccess({ table: projectBudgetTableTemplate })
            );
            this.store.dispatch(
                ListContainerActions.addListContainerSuccess({
                    container: projectPhaseContainerTemplate,
                })
            );
            this.store.dispatch(
                CommunicationActions.addCommunicationSuccess({
                    communication: communicationTemplate,
                })
            );
        });

        connection.on('ProjectTemplateDeleted', (templateId: string) => {
            console.log('SignalR message received: ProjectBudgetTableDeleted', templateId);
            this.store.dispatch(
                ProjectTemplateActions.deleteProjectTemplateSuccess({ id: templateId })
            );
        });

        // ProjectCalendar handlers
        connection.on('ProjectCalendarPatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: ProjectCalendarPatched', data);
            this.store.dispatch(
                CalendarActions.patchCalendarSuccess({ id: data.id, patch: data.patch })
            );
        });

        connection.on('ProjectCalendarCreated', (data) => {
            const calendar = Model.createFromDto(Calendar, data);
            console.log('SignalR message received: ProjectCalendarCreated', data, calendar);
            this.store.dispatch(CalendarActions.addCalendarSuccess({ calendar }));
        });

        connection.on('ProjectCalendarDeleted', (calendarId: string) => {
            console.log('SignalR message received: ProjectCalendarDeleted', calendarId);
            this.store.dispatch(CalendarActions.deleteCalendarSuccess({ id: calendarId }));
        });

        // ListContainer handlers
        connection.on('ListContainerPatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: ListContainerPatched', data);
            this.store.dispatch(
                ListContainerActions.patchListContainerSuccess({
                    id: data.id,
                    patch: data.patch,
                })
            );
        });

        connection.on('ListContainerCreated', (data) => {
            const container = Model.createFromDto(ListContainer, data);
            console.log('SignalR message received: ListContainerCreated', data, container);
            this.store.dispatch(ListContainerActions.addListContainerSuccess({ container }));
            const glTableSettings = Model.createFromDto(GlTableSettings, data.tableView);
            this.store.dispatch(
                GlTableSettingsActions.loadGlTableSettingsByIdSuccess({ glTableSettings })
            );
        });

        connection.on('ListContainerTemplateChanged', (data) => {
            const container = Model.createFromDto(ListContainer, data.container);
            const phases: ProjectPhase[] = data.phases.forEach((dto) =>
                Model.createFromDto(ProjectPhase, dto)
            );
            console.log('SignalR message received: ListContainerTemplateChanged', data, container);
            this.store.dispatch(
                ListContainerActions.replaceListContainer({
                    container: container,
                })
            );
            this.store.dispatch(
                ProjectPhaseActions.changeGatingTemplateSuccess({
                    phases,
                    containerId: container.id,
                })
            );
        });

        connection.on('ListContainerImported', (data: ImportListContainerResponse) => {
            const container = Model.createFromDto(ListContainer, data.listContainer);
            const documents = data.documents.map((dto) => Model.createFromDto(FileNode, dto));
            console.log('SignalR message received: ListContainerImported', data, container);
            this.store.dispatch(ListContainerActions.addListContainerSuccess({ container }));
            this.store.dispatch(DocumentActions.addManyDocumentsSuccess({ documents }));
        });

        connection.on('ListContainerDeleted', (containerId: string) => {
            console.log('SignalR message received: ListContainerDeleted', containerId);
            this.store.dispatch(
                ListContainerActions.deleteListContainerSuccess({ id: containerId })
            );
        });

        connection.on('ListContainerTemplateChanged', (data: ListContainer) => {
            const container = Model.createFromDto(ListContainer, data);
            console.log('SignalR message received: ListContainerTemplateChanged', data, container);
            this.store.dispatch(ListContainerActions.replaceListContainer({ container }));
        });

        connection.on('MicrosoftProjectImported', (data: ImportMicrosoftProjectResponsePayload) => {
            console.log('SignalR message received: MicrosoftProjectImported', data);
            this.store.dispatch(
                ScheduleTaskActions.importMicrosoftProjectTasks({
                    listContainerId: data.listContainerPatch.id,
                    tasks: data.tasks.map((t) => Model.createFromDto(ScheduleTask, t)),
                })
            );
            this.store.dispatch(
                GlTableSettingsActions.patchGlTableSettingsSuccess({
                    id: data.glTableSettingsPatch.id,
                    patch: data.glTableSettingsPatch.patch,
                })
            );
            this.store.dispatch(
                ListContainerActions.patchListContainerSuccess(data.listContainerPatch)
            );
        });

        // GlTableSettings handlers
        connection.on('GlTableSettingsPatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: GlTableSettingsPatched', data);
            this.store.dispatch(
                GlTableSettingsActions.patchGlTableSettingsSuccess({
                    id: data.id,
                    patch: data.patch,
                })
            );
        });

        // ProjectPhase handlers
        connection.on('ProjectPhasePatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: ProjectPhasePatched', data);
            this.store.dispatch(
                ProjectPhaseActions.patchProjectPhaseSuccess({ id: data.id, patch: data.patch })
            );
        });

        connection.on('ProjectPhaseCreated', (data) => {
            const phase = Model.createFromDto(ProjectPhase, data);
            console.log('SignalR message received: ProjectPhaseCreated', data, phase);
            this.store.dispatch(ProjectPhaseActions.addProjectPhaseSuccess({ phase }));
        });

        connection.on('ProjectPhaseDeleted', (phaseId: string) => {
            console.log('SignalR message received: ProjectPhaseDeleted', phaseId);
            this.store.dispatch(ProjectPhaseActions.deleteProjectPhaseSuccess({ id: phaseId }));
        });

        // Communication handlers
        connection.on('CommunicationPatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: CommunicationPatched', data);
            this.store.dispatch(
                CommunicationActions.patchCommunicationSuccess({ id: data.id, patch: data.patch })
            );
        });

        connection.on('CommunicationCreated', (data) => {
            const communication = Model.createFromDto(Communication, data);
            console.log('SignalR message received: CommunicationCreated', data, communication);
            this.store.dispatch(CommunicationActions.addCommunicationSuccess({ communication }));
        });

        connection.on('CommunicationTemplateChanged', (data) => {
            const communication = Model.createFromDto(Communication, data);
            console.log(
                'SignalR message received: CommunicationTemplateChanged',
                data,
                communication
            );
            this.store.dispatch(
                CommunicationActions.changeProjectCommunicationTemplateSuccess({ communication })
            );
        });

        connection.on('CommunicationDeleted', (communicationId: string) => {
            console.log('SignalR message received: CommunicationDeleted', communicationId);
            this.store.dispatch(
                CommunicationActions.deleteCommunicationSuccess({ id: communicationId })
            );
        });

        // Comment handlers
        connection.on('CommentUpdated', (data) => {
            const comment = Model.createFromDto(CtxComment, data);
            console.log('SignalR message received: CommentUpdated', data, comment);
            this.store.dispatch(CommentActions.patchCommentSuccess({ comment }));
        });

        connection.on('CommentCreated', (data) => {
            const comment = Model.createFromDto(CtxComment, data);
            console.log('SignalR message received: CommentCreated', data, comment);
            this.store.dispatch(CommentActions.addCommentSuccess({ comment }));
        });

        connection.on('CommentDeleted', (commentId: string) => {
            console.log('SignalR message received: CommentDeleted', commentId);
            this.store.dispatch(CommentActions.deleteCommentSuccess({ id: commentId }));
        });

        // SiteRole handlers
        connection.on('SiteRolePatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: SiteRolePatched', data);
            this.store.dispatch(
                SiteRoleActions.patchSiteRoleSuccess({ id: data.id, patch: data.patch })
            );
        });

        connection.on('SiteRoleCreated', (data) => {
            const role = Model.createFromDto(SiteRole, data);
            console.log('SignalR message received: SiteRoleCreated', data, role);
            this.store.dispatch(SiteRoleActions.addSiteRoleSuccess({ role }));
        });

        connection.on('SiteRoleDeleted', (roleId: string) => {
            console.log('SignalR message received: SiteRoleDeleted', roleId);
            this.store.dispatch(SiteRoleActions.deleteSiteRoleSuccess({ id: roleId }));
        });

        // ProgramRole handlers
        connection.on('ProgramRolePatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: ProgramRolePatched', data);
            this.store.dispatch(
                ProgramRoleActions.patchProgramRoleSuccess({ id: data.id, patch: data.patch })
            );
        });

        // ProjectRole handlers
        connection.on('ProjectRolePatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: ProjectRolePatched', data);
            this.store.dispatch(
                ProjectRoleActions.patchProjectRoleSuccess({ id: data.id, patch: data.patch })
            );
        });

        // Log handlers
        connection.on('LogCreated', (data) => {
            console.log('SignalR message received: LogCreated', data);
            const log = Model.createFromDto(UserLog, data);
            this.store.dispatch(UserLogActions.addUserLog({ log }));
        });

        // Timesheet handlers
        connection.on('TimesheetPatched', (data: SignalrEntityPatchResponse) => {
            console.log('SignalR message received: TimesheetPatched', data);
            this.store
                .select(TimesheetSelectors.selectTimesheetById(data.id))
                .pipe(first((t) => !!t))
                .subscribe(() =>
                    this.store.dispatch(
                        TimesheetActions.patchTimesheetSuccess({ id: data.id, patch: data.patch })
                    )
                );
        });
    }

    listenSite(siteId: string) {
        this.listeningSiteId = siteId;
        if (!this.connected) return;

        console.log('SignalR: start listening site ' + siteId);

        const payload = new SignalRPayload();
        payload.setData(siteId);
        const command = new SignalRCommand(payload);
        command.method = 'Join';
        this.signalRGateway.send(this.connection, command);
    }

    private onConnected() {
        if (this.listeningSiteId) this.listenSite(this.listeningSiteId);
        this.connection
            .invoke('GetConnectionId')
            .then((connectionId) =>
                this.store.dispatch(SiteActions.setSignalrConnectionId({ id: connectionId }))
            );
    }

    private scheduleConnect() {
        console.log('SignalR: schedule connection after ' + this.reconnectTimeout + 'ms');
        this.reconnectTimer = setTimeout(() => {
            this.clearReconnectTimer();
            this.connect();
        }, this.reconnectTimeout);
    }

    private clearCurrentConnection() {
        this.connection = null;
        this.connected = false;
        this.clearReconnectTimer();
    }

    private clearReconnectTimer() {
        if (this.reconnectTimer == null) return;
        clearTimeout(this.reconnectTimer);
        this.reconnectTimer = null;
    }
}
