import { Action, createAction, props } from '@ngrx/store';
import { LoadByProjectIdPayload } from '../shared/models/load.payload';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import { IdPayload } from './../shared/models/shared-payloads';
import { Sprint } from './models/sprint.model';
import { AddSprintPayload, PatchSprintPayload, UpdateSprintPayload } from './sprint.payloads';

// Load Sprint actions

export const loadSprintsByProjectId = createAction(
    '[Sprint] Load Sprints',
    props<{ payload: LoadByProjectIdPayload }>()
);

export const loadSprintsByProjectIdSuccess = createAction(
    '[Sprint] Load Sprints success',
    props<{ sprints: Sprint[]; projectId: string }>()
);

export const loadSprintsByProjectIdFail = createAction(
    '[Sprint] Load Sprints fail',
    props<{ error: any; projectId: string }>()
);

// Add Sprint actions

export const addSprint = createAction('[Sprint] Add', props<{ payload: AddSprintPayload }>());

export const addSprintSuccess = createAction('[Sprint] Add success', props<{ sprint: Sprint }>());

export const addSprintFail = createAction('[Sprint] Add fail', props<{ error: any }>());

// Update Sprint actions

export const updateSprint = createAction(
    '[Sprint] Update',
    props<{ payload: UpdateSprintPayload; options?: OptimisticableActionOptions }>()
);

export const patchSprint = createAction(
    '[Sprint] Patch',
    props<{ payload: PatchSprintPayload; options?: OptimisticableActionOptions }>()
);

export const patchSprintSuccess = createAction(
    '[Sprint] Patch success',
    props<PatchSprintPayload>()
);

export const patchSprintFail = createAction(
    '[Sprint] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Delete Sprint actions

export const deleteSprint = createAction(
    '[Sprint] Delete',
    props<{ payload: IdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteSprintSuccess = createAction('[Sprint] Delete success', props<IdPayload>());

export const deleteSprintFail = createAction(
    '[Sprint] Delete fail',
    props<{ error: any; originAction: Action }>()
);
