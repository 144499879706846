import { CtxMember } from 'app/core/models/shared-models';
import { JsonProperty } from 'app/utils/json-mapper';
import { SiteMember } from '../../resource';
import { Model } from '../../shared/models/base.model';

export class Minute extends Model {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('projectId')
    projectId: string = undefined;

    @JsonProperty('parentFolderId')
    parentFolderId?: string = undefined;

    @JsonProperty('name')
    name?: string = undefined;

    @JsonProperty('attendees')
    attendees?: CtxMember[] = undefined;

    @JsonProperty('regrets')
    regrets?: CtxMember[] = undefined;

    @JsonProperty('description')
    description?: string = undefined;

    @JsonProperty('date')
    date: Date = undefined;

    @JsonProperty('filed')
    filed?: boolean = undefined;

    @JsonProperty('fileDate')
    fileDate?: Date = undefined;

    @JsonProperty('topics')
    topics?: MinuteTopic[] = undefined;

    @JsonProperty('topics')
    isDocument?: boolean = undefined;

    ownerSiteMemberId: string;

    locked?: boolean;

    constructor(init?: Partial<Minute>) {
        super();
        Object.assign(this, init);
    }
}

export class MinuteTopic {
    id: string;
    type: MinuteTopicType;
    note?: string;
    owner?: MinuteTopicOwner;
    dueDate?: Date;
    addToRaidOnFile?: boolean;
    deletedFromUserDashboard?: boolean;
    completed?: boolean;

    constructor(init?: Partial<MinuteTopic>) {
        Object.assign(this, init);
    }
}

export interface MinuteTopicOwner {
    siteMemberId?: string;
    externalName?: string;
}

export interface MinuteTopicCreateOptions {
    minuteId: string;
    options: MinuteTopicCreateOptionsList;
}

export interface MinuteTopicCreateOptionsList {
    name?: string;
    type?: MinuteTopicType;
    note?: string;
    owner?: MinuteTopicOwner;
    dueDate?: Date;
}

export enum MinuteTopicType {
    Info = 'Info',
    Risk = 'Risk',
    Action = 'Action',
    Issue = 'Issue',
    Decision = 'Decision',
    Task = 'Task',
}

export function getMinuteMemberTitles(members: CtxMember[], siteMembers: SiteMember[]): string[] {
    return (
        members?.map((m) => {
            const member = siteMembers.find((sm) => sm.id === m.siteMemberId);
            return member?.name || member?.email || m.externalName;
        }) || []
    );
}
