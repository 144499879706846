import { ValueDiff } from './schedule.types';

export class CompoundValue<T> {
    private initial?: T;
    private changed?: T;
    private calculated?: T;

    get hasInitial(): boolean {
        return this.initial !== void 0;
    }

    get hasChanged(): boolean {
        return this.changed !== void 0;
    }

    get hasCalculated(): boolean {
        return this.calculated !== void 0;
    }

    get hasValue(): boolean {
        return this.hasInitial || this.hasChanged || this.hasCalculated;
    }

    get isEmpty(): boolean {
        return !this.hasValue || this.getValue() === null;
    }

    get isModified(): boolean {
        return this.hasChanged || this.hasCalculated;
    }

    getValue(): T {
        if (this.hasCalculated) return this.getCalculated();
        if (this.hasChanged) return this.getChanged();
        return this.getInitial();
    }

    getInitial(): T {
        return this.initial;
    }

    setInitial(value: T) {
        if (value === void 0) return;
        this.initial = value;
        this.clearChanged();
        this.clearCalculated();
    }

    clearInitial() {
        delete this.initial;
    }

    getChanged(): T {
        return this.changed;
    }

    setChanged(value: T) {
        if (value === void 0) return;
        this.changed = value;
        this.clearCalculated();
    }

    clearChanged() {
        delete this.changed;
    }

    getCalculated(): T {
        return this.calculated;
    }

    setCalculated(value: T) {
        if (value === void 0) return;
        this.calculated = value;
    }

    clearCalculated() {
        delete this.calculated;
    }

    getDiff(): ValueDiff<T> | null {
        if (!this.isModified) return null;

        return {
            old: this.getInitial(),
            new: this.getValue(),
        };
    }
}
