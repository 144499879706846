import { Model } from '../../shared/models/base.model';

export class ExecutiveDashboard extends Model {
    id: string;
    siteId: string;
    projectsOverview: ProjectOverview[];
    programsOverview: ProgramOverview[];

    constructor(init?: Partial<ExecutiveDashboard>) {
        super();
        Object.assign(this, init);
    }
}

export interface ProjectOverview {
    projectId: string;
    primaryProjectManagerName: string;
    projectManagerNames: string[];
    phaseName: string;
    openIssuesCount: number;
    highRisksCount: number;
    totalApprovedBudget: number;
    totalApprovedCurrentFY: number;
    actualsFYBudget: number;
    forecastFYBudget: number;
}

export interface ProgramOverview {
    programId: string;
    projectsCount: number;
    greenProjectsCount: number;
    yellowProjectsCount: number;
    redProjectsCount: number;
    totalBudget: number;
    actualBudget: number;
    plannedBudget: number;
    openIssuesCount: number;
    highRisksCount: number;
    approvedFYBudget: number;
    actualsFYBudget: number;
    forecastFYBudget: number;
    rating: number;
}
