import { CtxColor } from 'app/core/models/shared-models';
import { generateUUID } from '../../id-generator';
export class ProjectField {
    id: string;
    siteId?: string;
    name: string;
    type: ProjectFieldType;
    items: ProjectFieldItem[];
    isReadonly?: boolean;
    completixSettingsTemplateId?: string;

    constructor(init?: Partial<ProjectField>) {
        Object.assign(this, init);
    }
}

export class ProjectFieldItem {
    id: string;
    name: string;
    color?: CtxColor;
    ctxItemType?: CtxFieldItemType;

    constructor(init?: Partial<ProjectFieldItem>) {
        Object.assign(this, init);
        if (this.id !== undefined) return;
        this.id = generateUUID();
    }
}

export enum DecisionStatusName {
    Approved = 'Approved',
    Rejected = 'Rejected',
    Pending = 'Pending',
}

export enum CtxFieldItemType {
    Open = 'Open',
    Closed = 'Closed',
    Pending = 'Pending',
    Approved = 'Approved',
    Rejected = 'Rejected',
    ProjectManager = 'ProjectManager',
    Sponsor = 'Sponsor',
}

export enum ProjectFieldType {
    Priority = 'Priority',
    Departments = 'Departments',
    Titles = 'Titles',
    Sponsorship = 'Sponsorship',
    Roles = 'Roles',
    RisksStatus = 'RisksStatus',
    IssuesStatus = 'IssuesStatus',
    DecisionStatus = 'DecisionStatus',
    ActionStatus = 'ActionStatus',
    ProjectManagerTitle = 'ProjectManagerTitle',
    Locations = 'Locations',
}

export interface ProjectFieldCreateOptions {
    name: string;
    tooltip?: string;
}

export const projectFieldTooltipMap: Record<ProjectFieldType, string> = {
    [ProjectFieldType.Priority]: 'Priority is used to identify the project urgency',
    [ProjectFieldType.Departments]: 'To identify where a user belong within the Organization',
    [ProjectFieldType.Titles]: 'Titles',
    [ProjectFieldType.Sponsorship]: 'Sponsorship',
    [ProjectFieldType.Roles]: 'Project Roles',
    [ProjectFieldType.RisksStatus]:
        'The open/close choice is to identity ongoing vs closed in the dashboards and reports',
    [ProjectFieldType.IssuesStatus]:
        'The open/close choice is to identity ongoing vs closed in the dashboards and reports',
    [ProjectFieldType.DecisionStatus]: 'You can change colors to suit you preference',
    [ProjectFieldType.ActionStatus]: 'You can change colors to suit you preference',
    [ProjectFieldType.ProjectManagerTitle]: 'PM Role',
    [ProjectFieldType.Locations]: 'Locations',
};
