import { Action, createAction, props } from '@ngrx/store';
import { ImportSystemTemplatePayload } from 'app/core/models/shared-models';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import { LoadByProjectIdPayload, LoadBySiteIdPayload } from './../shared/models/load.payload';
import {
    AddBudgetTablePayload,
    BudgetTableIdPayload,
    ChangeBudgetTableTemplatePayload,
    ImportBudgetTableDocumentPayload,
    PatchBudgetTablePayload,
    UpdateBudgetTablePayload,
} from './budget-table.payloads';
import { ProjectBudgetTable } from './models';

// Load Budget Tables actions

export const loadCompletixBudgetTableTemplates = createAction(
    '[Budget Table] Load Completix Budget Table templates'
);

export const loadCompletixBudgetTableTemplatesSuccess = createAction(
    '[Budget Table] Load Completix Budget Table templates success',
    props<{ tables: ProjectBudgetTable[] }>()
);

export const loadCompletixBudgetTableTemplatesFail = createAction(
    '[Budget Table] Load Completix Budget Table templates fail',
    props<{ error: any }>()
);

export const loadCompletixBudgetTableTemplatesBySiteId = createAction(
    '[Budget Table] Load Completix Budget Table templates by Site Id',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadBudgetTablesBySiteId = createAction(
    '[Budget Table] Load Budget Tables by Site Id',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadBudgetTablesBySiteIdSuccess = createAction(
    '[Budget Table] Load Budget Tables by Site Id success',
    props<{ tables: ProjectBudgetTable[] }>()
);

export const loadBudgetTablesBySiteIdFail = createAction(
    '[Budget Table] Load Budget Tables by Site Id fail',
    props<{ error: any }>()
);

export const loadBudgetTableByProjectId = createAction(
    '[Budget Table] Load Budget Table by Project Id',
    props<{ payload: LoadByProjectIdPayload }>()
);

export const loadBudgetTableByProjectIdSuccess = createAction(
    '[Budget Table] Load Budget Table by Project Id success',
    props<{ table: ProjectBudgetTable }>()
);

export const loadBudgetTableByProjectIdFail = createAction(
    '[Budget Table] Load Budget Table by Project Id fail',
    props<{ error: any }>()
);

// Add Budget Table actions

export const importCompletixBudgetTable = createAction(
    '[Budget Table] Import Completix template',
    props<{ payload: ImportSystemTemplatePayload }>()
);

export const addBudgetTable = createAction(
    '[Budget Table] Add',
    props<{ payload: AddBudgetTablePayload }>()
);

export const addBudgetTableSuccess = createAction(
    '[Budget Table] Add success',
    props<{ table: ProjectBudgetTable }>()
);

export const addBudgetTableFail = createAction('[Budget Table] Add fail', props<{ error: any }>());

// Update Budget Table actions

export const updateBudgetTable = createAction(
    '[Budget Table] Update',
    props<{ payload: UpdateBudgetTablePayload; options?: OptimisticableActionOptions }>()
);

export const patchBudgetTable = createAction(
    '[Budget Table] Patch',
    props<{ payload: PatchBudgetTablePayload; options?: OptimisticableActionOptions }>()
);

export const patchBudgetTableSuccess = createAction(
    '[Budget Table] Patch success',
    props<PatchBudgetTablePayload>()
);

export const patchBudgetTableFail = createAction(
    '[Budget Table] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Delete Budget Table actions

export const deleteBudgetTable = createAction(
    '[Budget Table] Delete',
    props<{ payload: BudgetTableIdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteBudgetTableSuccess = createAction(
    '[Budget Table] Delete success',
    props<{ payload: BudgetTableIdPayload }>()
);

export const deleteBudgetTableFail = createAction(
    '[Budget Table] Delete fail',
    props<{ error: any; originAction: Action }>()
);

// Change budget template
export const changeBudgetTableTemplate = createAction(
    '[Budget Table] Change template',
    props<{ payload: ChangeBudgetTableTemplatePayload }>()
);

export const changeBudgetTableTemplateSuccess = createAction(
    '[Budget Table] Change template success',
    props<{ table: ProjectBudgetTable }>()
);

export const changeBudgetTableTemplateFail = createAction(
    '[Budget Table] Change template fail',
    props<{ error: any }>()
);

// Download
export const downloadBudgetTable = createAction(
    '[Budget Table] Download',
    props<{ payload: { tableId: string } }>()
);

// Import document
export const importBudgetTableDocument = createAction(
    '[Budget Table] Import document',
    props<{ payload: ImportBudgetTableDocumentPayload }>()
);

export const importBudgetTableDocumentFail = createAction(
    '[Budget Table] Import document fail',
    props<{ error: any }>()
);

export const downloadBudgetTableSuccess = createAction('[Budget Table] Download success');

export const downloadBudgetTableFail = createAction('[Budget Table] Download fail');
