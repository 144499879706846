import { createReducer, on } from '@ngrx/store';
import {
    loadExecutiveDashboardBySiteId,
    loadExecutiveDashboardBySiteIdFail,
    loadExecutiveDashboardBySiteIdSuccess,
} from './executive-dashboard.actions';
import { adapter, initialState } from './executive-dashboard.store';

export const reducer = createReducer(
    initialState,

    on(loadExecutiveDashboardBySiteId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(loadExecutiveDashboardBySiteIdSuccess, (state, payload) => {
        const existingDashboard = Object.values(state.entities).find(
            (d) => d.siteId === payload.executiveDashboard.siteId
        );
        const newState = adapter.addOne(
            payload.executiveDashboard,
            adapter.removeOne(existingDashboard?.id, state)
        );
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(loadExecutiveDashboardBySiteIdFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    }))
);
