import { JsonProperty } from 'app/utils/json-mapper';
import { BillingDetails } from './billing-details.model';
import { PaymentMethodDetails } from './payment-method-details.model';

export class PaymentInfo {
    @JsonProperty('customerId')
    customerId: string = undefined;

    @JsonProperty({ name: 'billingDetails', clazz: BillingDetails })
    billingDetails: BillingDetails = undefined;

    @JsonProperty({ name: 'paymentDetails', clazz: PaymentMethodDetails })
    paymentDetails: PaymentMethodDetails = undefined;

    constructor(init?: Partial<PaymentInfo>) {
        Object.assign(this, init);
    }
}
