import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import { Portfolio } from './models';
import * as PortfolioActions from './portfolio.actions';
import { adapter, initialState } from './portfolio.store';

export const reducer = createReducer(
    initialState,
    on(PortfolioActions.loadPortfolios, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(PortfolioActions.loadPortfoliosSuccess, (state, payload) => {
        const newState = adapter.addMany(payload.portfolios, adapter.removeAll(state));
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(PortfolioActions.loadPortfoliosFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(PortfolioActions.addPortfolioSuccess, (state, payload) =>
        adapter.addOne(payload.portfolio, state)
    ),

    on(PortfolioActions.addProjectToPortfolio, (state, action) => {
        if (!action.options?.optimistic) return state;
        const update: Update<Portfolio> = {
            id: action.payload.portfolioId,
            changes: {
                projectIds: [
                    ...(state.entities[action.payload.portfolioId].projectIds || []),
                    action.payload.projectId,
                ],
            },
        };
        return adapter.updateOne(update, state);
    }),

    on(PortfolioActions.removeProjectFromPortfolio, (state, action) => {
        if (!action.options?.optimistic) return state;
        const update: Update<Portfolio> = {
            id: action.payload.portfolioId,
            changes: {
                projectIds: state.entities[action.payload.portfolioId].projectIds.filter(
                    (id) => id !== action.payload.projectId
                ),
            },
        };
        return adapter.updateOne(update, state);
    }),

    on(PortfolioActions.patchPortfolio, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patched = getPatched(state.entities[action.payload.id], action.payload.patch);
        const update: Update<Portfolio> = {
            id: patched.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(PortfolioActions.patchPortfolioSuccess, (state, payload) => {
        const update: Update<Portfolio> = {
            id: payload.portfolio.id,
            changes: payload.portfolio,
        };
        return adapter.updateOne(update, state);
    }),

    on(PortfolioActions.patchPortfolioFail, (state) => state),

    on(PortfolioActions.deletePortfolio, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(PortfolioActions.deletePortfolioSuccess, (state, payload) =>
        adapter.removeOne(payload.id, state)
    )
);
