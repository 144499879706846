import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppStore } from 'app/core/store';
import { SignalRGateway } from 'app/utils/network';
import { AccountHubClient } from './account-hub-client';
import { SiteHubClient } from './site-hub-client';

@Injectable()
export class SignalRApiService {
    private siteHubClient: SiteHubClient;
    private accountHubClient: AccountHubClient;

    constructor(
        private signalRGateway: SignalRGateway,
        private store: Store<AppStore.AppState>,
        private router: Router
    ) {
        this.siteHubClient = new SiteHubClient(signalRGateway, store, router);
        this.accountHubClient = new AccountHubClient(signalRGateway, store);
    }

    connectSiteHub() {
        this.siteHubClient.connect();
    }

    connectAccountHub() {
        this.accountHubClient.connect();
    }

    listenSite(siteId: string) {
        this.siteHubClient.listenSite(siteId);
    }

    listenAccount(accountId: string) {
        this.accountHubClient.listenAccount(accountId);
    }
}
