import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AsyncUpdateStatus, AsyncUpdateStatusDefault } from '../shared/async-update-status.store';
import { ProjectRole } from './models';

export interface State extends EntityState<ProjectRole> {
    templatesLoadingStatus: AsyncUpdateStatus;
    currentSiteRolesLoadingStatus: AsyncUpdateStatus;
}

export const adapter: EntityAdapter<ProjectRole> = createEntityAdapter<ProjectRole>();

export const initialState: State = adapter.getInitialState({
    templatesLoadingStatus: AsyncUpdateStatusDefault,
    currentSiteRolesLoadingStatus: AsyncUpdateStatusDefault,
});
