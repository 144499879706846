import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { NotificationActions } from '.';
import { RestApiService } from '../../services/rest-api';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import { CtxNotification } from './models/notification.model';

@Injectable()
export class NotificationEffects {
    loadAccountLevelNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationActions.loadAccountLevelNotifications),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        NotificationActions.loadAccountLevelNotificationsSuccess({
                            notifications: response.payload.map((dto) =>
                                Model.createFromDto(CtxNotification, dto)
                            ),
                        })
                    ),
                    catchError((error) =>
                        of(NotificationActions.loadAccountLevelNotificationsFail({ error }))
                    )
                )
            )
        )
    );

    loadSiteLevelNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationActions.loadSiteLevelNotifications),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        NotificationActions.loadSiteLevelNotificationsSuccess({
                            notifications: response.payload.map((dto) =>
                                Model.createFromDto(CtxNotification, dto)
                            ),
                        })
                    ),
                    catchError((error) =>
                        of(NotificationActions.loadSiteLevelNotificationsFail({ error }))
                    )
                )
            )
        )
    );

    markNotificationRead$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationActions.markNotificationRead),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return NotificationActions.markNotificationReadSuccess({
                            notification: Model.createFromDto(CtxNotification, response.payload),
                        });
                    }),
                    catchError((error) =>
                        of(
                            NotificationActions.markNotificationReadFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteNotification$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationActions.deleteNotification),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return NotificationActions.deleteNotificationSuccess({
                            id: action.payload.id,
                        });
                    }),
                    catchError((error) =>
                        of(
                            NotificationActions.deleteNotificationFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    sendNotification$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationActions.sendNotification),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(map(() => okEmptyAction()))
            )
        )
    );

    constructor(private actions$: Actions, private restApiService: RestApiService) {}
}
