import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap } from 'rxjs/operators';
import { RestApiService } from '../../services/rest-api';
import { Model } from '../shared/models/base.model';
import { okEmptyAction } from './../project/project.actions';
import { SiteSettings } from './models';
import * as SiteSettingsActions from './site-settings.actions';

@Injectable()
export class SiteSettingsEffects {
    loadSiteSettingsCompletixTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteSettingsActions.loadSiteSettingsCompletixTemplates),
            switchMap((action) =>
                this.restApiService.process(action).pipe(
                    map((result: CommandResult) =>
                        result.payload.map((dto) => Model.createFromDto(SiteSettings, dto))
                    ),
                    map((settings) =>
                        SiteSettingsActions.loadSiteSettingsCompletixTemplatesSuccess({ settings })
                    ),
                    catchError((error: any) => {
                        return of(
                            SiteSettingsActions.loadSiteSettingsCompletixTemplatesFail({ error })
                        );
                    })
                )
            )
        )
    );

    loadSiteSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteSettingsActions.loadSiteSettings),
            concatMap((action) => {
                return this.restApiService.process(action).pipe(
                    map((response) =>
                        SiteSettingsActions.loadSiteSettingsSuccess({
                            settings: Model.createFromDto(SiteSettings, response.payload),
                        })
                    ),
                    catchError((error: any) => {
                        return of(SiteSettingsActions.loadSiteSettingsFail({ error }));
                    })
                );
            })
        )
    );

    addSiteSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteSettingsActions.addSiteSettings),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((result: CommandResult) =>
                        SiteSettingsActions.addSiteSettingsSuccess({
                            settings: Model.createFromDto(SiteSettings, result.payload),
                        })
                    ),
                    catchError((error: any) =>
                        of(SiteSettingsActions.addSiteSettingsFail({ error }))
                    )
                )
            )
        )
    );

    patchSiteSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteSettingsActions.patchSiteSettings),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return SiteSettingsActions.patchSiteSettingsSuccess({ ...action.payload });
                    }),
                    catchError((error: any) =>
                        of(
                            SiteSettingsActions.patchSiteSettingsFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteSiteSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteSettingsActions.deleteSiteSettings),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return SiteSettingsActions.deleteSiteSettingsSuccess({
                            id: action.payload.id,
                        });
                    }),
                    catchError((error: any) =>
                        of(
                            SiteSettingsActions.deleteSiteSettingsFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    constructor(private actions$: Actions, private restApiService: RestApiService) {}
}
