import { Observable, Observer } from 'rxjs';
import { share } from 'rxjs/operators';
import { Command } from '../commands/base.command';

export abstract class Gateway {
    dataStream: Observable<any>;
    connectionEvents: Observable<boolean>;
    protected emitter: Observer<any>;
    protected connectionEventsEmitter: Observer<boolean>;

    constructor() {
        this.dataStream = new Observable<any>((emitter: Observer<any>) => {
            this.emitter = emitter;
        }).pipe(share());
        this.connectionEvents = new Observable<boolean>((obs: Observer<boolean>) => {
            this.connectionEventsEmitter = obs;
        }).pipe(share());
    }

    abstract send(command: Command): Observable<any>;
}
