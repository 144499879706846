import { RaidRecord } from 'app/core/store/shared/models/raid-shared.model';
import { deepClone } from 'fast-json-patch';
import { FileNode } from '../../document';
import { ProjectRequest } from '../../project-request';
import { ProjectRole } from '../../project-role';
import { SiteMember } from '../../resource';

export enum SiteRoleTag {
    Owner = 'Owner',
    TeamMember = 'TeamMember',
    Guest = 'Guest',
}

export enum ProjectRoleTag {
    Manager = 'Manager',
    Resource = 'Resource',
    Guest = 'Guest',
}

export enum ProgramRoleTag {
    Manager = 'Manager',
}

export type RecordPermissionsMap = Record<string, CrudPermissions>;

export interface CrudPermissions {
    create?: boolean;
    read?: boolean;
    update?: boolean;
    delete?: boolean;
}

export interface ReadUpdatePermissions {
    read?: boolean;
    update?: boolean;
}

export interface CreateDeletePermissions {
    create?: boolean;
    delete?: boolean;
}

export interface TimesheetPermissions extends ReadUpdatePermissions {
    submit?: boolean;
    reOpen?: boolean;
}

// Site
export interface SitePermissions {
    read?: boolean;
    update?: boolean;
    delete?: boolean;
    createProjects?: boolean;
    createPrograms?: boolean;
    readSiteLogs?: boolean;
    portfolios?: PortfolioPermissions;
    projects?: ProjectPermissions;
    programs?: ProgramPermissions;
    projectRequests?: ProjectRequestPermissions;
    billing?: ReadUpdatePermissions;
    settings?: ReadUpdatePermissions;
    roles?: CrudPermissions;
    members?: SiteMemberPermissions;
    templates?: TemplatePermissions;
    executive?: ExecutivePermissions;
    approvals?: ReadUpdatePermissions;
    changeSubscriptionType?: boolean;
    transferProjectToProgram?: boolean;
    myTimesheet?: TimesheetPermissions;
    overviewPageRead?: boolean;
    approvedFyBudgetUpdate?: boolean;
    financials?: ReadUpdatePermissions;
}

export interface PortfolioPermissions extends CrudPermissions {
    projects?: CreateDeletePermissions;
    updateApprovedFyBudget?: boolean;
}

export interface TemplatePagePermissions extends CrudPermissions {
    showPage?: boolean;
}

export interface TemplatePermissions {
    schedules?: TemplatePagePermissions;
    documents?: TemplatePagePermissions;
    budgets?: TemplatePagePermissions;
    projects?: TemplatePagePermissions;
    calendars?: TemplatePagePermissions;
    gating?: TemplatePagePermissions;
    communication?: TemplatePagePermissions;
}

export interface ExecutivePermissions {
    readPortfolios?: boolean;
    readPrograms?: boolean;
    readProjects?: boolean;
    readResources?: boolean;
    readRequests?: boolean;
    readApprovals?: boolean;
    readSiteMetrics?: boolean;
    readClosedProjectsConclusion?: boolean;
    readFinancials?: boolean;
}

export interface ProjectRequestPermissions extends CrudPermissions {
    readOwn?: boolean;
    updateOwn?: boolean;
    deleteOwn?: boolean;
}

export interface SiteMemberPermissions extends Omit<CrudPermissions, 'update'> {
    readSiteMembersPage?: boolean;
    name?: ReadUpdatePermissions;
    siteRole?: ReadUpdatePermissions;
    siteRoleTitle?: ReadUpdatePermissions;
    additionalPermissions?: ReadUpdatePermissions;
    subscription?: ReadUpdatePermissions;
    teamDept?: ReadUpdatePermissions;
    siteRate?: ReadUpdatePermissions;
    timesheets?: TimesheetPermissions;
    reportsTo?: ReadUpdatePermissions;
    location?: ReadUpdatePermissions;
    manualAllocation?: CrudPermissions;
}

export interface ProjectRaciPermissions extends Omit<CrudPermissions, 'delete'> {}

export interface ProjectMemberPermisions extends Omit<CrudPermissions, 'update'> {
    name?: ReadUpdatePermissions;
    status?: ReadUpdatePermissions;
    additionalSponsorTitle?: ReadUpdatePermissions;
    additionalManagerTitle?: ReadUpdatePermissions;
    role?: ReadUpdatePermissions;
    roleTitle?: ReadUpdatePermissions;
    rate?: ReadUpdatePermissions;
    allocation?: ProjectMemberWorkPermissions;
    effort?: ProjectMemberWorkPermissions;
    readLocation?: boolean;
    inviteOutsideUser?: boolean;
}

export interface ProjectMemberWorkPermissions extends ReadUpdatePermissions {
    readDetails?: boolean;
}

export interface GatingPermissions extends CrudPermissions {
    submit?: boolean;
    approve?: boolean;
    advance?: boolean;
    changeTemplate?: boolean;
    deliverables?: CrudPermissions;
}

export interface DecisionPermissions extends CrudAssignedPermissions {
    deleteApprovedRejected?: boolean;
}

export interface RaidPermissions {
    risks?: CrudAssignedPermissions;
    actions?: CrudAssignedPermissions;
    issues?: CrudAssignedPermissions;
    decisions?: DecisionPermissions;
}

export interface AgilePermissions {
    backlogTasks?: CrudAssignedPermissions;
    sprintTasks?: CrudAssignedPermissions;
    sprints?: CrudPermissions;
}

export interface ProjectPageLockPermissions {
    home?: boolean;
    team?: boolean;
    status?: boolean;
    schedule?: boolean;
    backlog?: boolean;
    sprints?: boolean;
    budget?: boolean;
    risks?: boolean;
    actions?: boolean;
    issues?: boolean;
    decisions?: boolean;
    gating?: boolean;
    changeRequests?: boolean;
    vendors?: boolean;
    minutes?: boolean;
    documents?: boolean;
    settings?: boolean;
}

export interface OwnDocumentPermissions extends Omit<CrudPermissions, 'create'> {}

export interface ProjectCommunicationPermissions extends ReadUpdatePermissions {
    changeTemplate?: boolean;
}

export interface ProjectBudgetPermissions extends ReadUpdatePermissions {
    capexOpexEnabled?: boolean;
}

export interface ProjectStatusPermissions extends CrudPermissions {
    lockBudget?: boolean;
    readLockedBudget?: boolean;
    lockTimeline?: boolean;
    readLockedTimeline?: boolean;
}

export interface ProjectSchedulePermissions extends ReadUpdatePermissions {
    changeTemplate?: boolean;
    tasks?: CrudAssignedPermissions;
}

export interface CrudAssignedPermissions extends CrudPermissions {
    assignedRead?: boolean;
    assignedUpdate?: boolean;
    assignedDelete?: boolean;
}

export interface ChangeRequestPermissions extends CrudPermissions {
    approve?: boolean;
    submitApproval?: boolean;
    reOpen?: boolean;
    budgetImpactRead?: boolean;
}

export interface HomePagePermissions {
    showPage?: boolean;
    readPortfolios?: boolean;
}

export interface MinutePermissions extends CrudAssignedPermissions {
    lockMinute?: boolean;
    readLockedMinutes?: boolean;
    allowAddTopicToRaid?: boolean;
}

export interface DocumentPermissions extends CrudPermissions {
    lockDocuments?: boolean;
    readLockedDocuments?: boolean;
}

// Project
export interface ProjectPermissions {
    read?: boolean;
    update?: boolean;
    delete?: boolean;
    archive?: boolean;
    unArchive?: boolean;
    assignManager?: boolean;
    lockHomePageBudget?: boolean;
    readLockedHomePageBudget?: boolean;
    readProjectLog?: boolean;
    home?: HomePagePermissions;
    approveTimesheets?: boolean;
    priority?: ReadUpdatePermissions;
    approvedBudget?: ReadUpdatePermissions;
    startEndDates?: ReadUpdatePermissions;
    schedule?: ProjectSchedulePermissions;
    agile?: AgilePermissions;
    roles?: ReadUpdatePermissions;
    status?: ProjectStatusPermissions;
    budget?: ProjectBudgetPermissions;
    raid?: RaidPermissions;
    members?: ProjectMemberPermisions;
    raci?: ProjectRaciPermissions;
    changeRequests?: ChangeRequestPermissions;
    vendors?: CrudPermissions;
    gating?: GatingPermissions;
    minutes?: MinutePermissions;
    documents?: DocumentPermissions;
    ownDocuments?: OwnDocumentPermissions;
    settings?: ReadUpdatePermissions;
    communication?: ProjectCommunicationPermissions;
    tabLockToggle?: ProjectPageLockPermissions;
    readLockedPage?: ProjectPageLockPermissions;
    updateState?: boolean;
    readSummaryPage?: boolean;
}

// Program
export interface ProgramPermissions {
    read?: boolean;
    update?: boolean;
    delete?: boolean;
    archive?: boolean;
    unArchive?: boolean;
    assignManager?: boolean;
    unassignManager?: boolean;
    readProgramTeam?: boolean;
    readSummaryPage?: boolean;
    createProjects?: boolean;
    readProgramLogs?: boolean;
    documents?: DocumentPermissions;
    ownDocuments?: OwnDocumentPermissions;
    projects?: ProjectPermissions;
    statusReport?: CrudPermissions;
}

export function getRaidRecordPermissionsMap(
    records: RaidRecord[],
    projectRolesMap: Record<string, ProjectRole>,
    permissionsKey: keyof RaidPermissions,
    accountAssignedPredicate: (record: RaidRecord) => boolean,
    additionalDeletePredicate: (record: RaidRecord) => boolean = () => true,
    additionalUpdatePredicate: (record: RaidRecord) => boolean = () => true
): RecordPermissionsMap {
    const dict: RecordPermissionsMap = {};
    records.forEach((record) => {
        const recordPermissions =
            projectRolesMap[record.projectId]?.permissions?.raid[permissionsKey];
        dict[record.id] = {
            create: recordPermissions?.create,
            read:
                recordPermissions?.read ||
                (recordPermissions?.assignedRead && accountAssignedPredicate(record)),
            update:
                (recordPermissions?.update ||
                    (recordPermissions?.assignedUpdate && accountAssignedPredicate(record))) &&
                additionalUpdatePredicate(record),
            delete:
                (recordPermissions?.delete ||
                    (recordPermissions?.assignedDelete && accountAssignedPredicate(record))) &&
                additionalDeletePredicate(record),
        };
    });
    return dict;
}

export function getProjectRequestPermissionsMap(
    requests: ProjectRequest[],
    sitePermissions: SitePermissions,
    accountSiteMembership: SiteMember
): RecordPermissionsMap {
    const dict: RecordPermissionsMap = {};
    requests.forEach((request) => {
        dict[request.id] = {
            create: sitePermissions.projectRequests?.create,
            read:
                sitePermissions.projectRequests?.read ||
                (sitePermissions.projectRequests?.readOwn &&
                    accountSiteMembership.id === request.requesterSiteMemberId),
            update:
                sitePermissions.projectRequests?.update ||
                (sitePermissions.projectRequests?.updateOwn &&
                    accountSiteMembership.id === request.requesterSiteMemberId),
            delete:
                sitePermissions.projectRequests?.delete ||
                (sitePermissions.projectRequests?.deleteOwn &&
                    accountSiteMembership.id === request.requesterSiteMemberId),
        };
    });
    return dict;
}

export function getDocumentPermissionsMap(
    documents: FileNode[],
    accountSiteMemberId: string,
    documentPermissions: CrudPermissions,
    ownDocumentPermissions: OwnDocumentPermissions
): RecordPermissionsMap {
    const dict: RecordPermissionsMap = {};
    documents.forEach((doc) => {
        dict[doc.id] = {
            create: documentPermissions?.create,
            read:
                documentPermissions?.read ||
                (ownDocumentPermissions?.read && doc.ownerSiteMemberId === accountSiteMemberId),
            update:
                documentPermissions?.update ||
                (ownDocumentPermissions?.update && doc.ownerSiteMemberId === accountSiteMemberId),
            delete:
                documentPermissions?.delete ||
                (ownDocumentPermissions?.delete && doc.ownerSiteMemberId === accountSiteMemberId),
        };
    });
    return dict;
}

export function mutatePermissions(
    value: boolean,
    mutablePermissions: object,
    defaultPermissions: object
) {
    for (let key in defaultPermissions) {
        if (defaultPermissions[key] == null) {
            mutablePermissions[key] = value;
            continue;
        }
        if (!mutablePermissions[key]) {
            mutablePermissions[key] = deepClone(defaultPermissions[key]);
        }
        mutatePermissions(value, mutablePermissions[key], defaultPermissions[key]);
    }
}

export const mergeWithSubscriptionPermissionsPredicate = (
    key: string,
    userPermission: boolean,
    subscriptionPermission: boolean
) => (!subscriptionPermission ? subscriptionPermission : userPermission);

export type UsingRolesMemberNamesMap = { [roleId: string]: string[] };

export const fullCrudPermissions: CrudPermissions = {
    create: true,
    read: true,
    update: true,
    delete: true,
};
