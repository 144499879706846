import { PatchGlTableSettingsPayload } from 'app/core/store/gl-table-settings/gl-table-settings.payloads';
import { IdPayload } from 'app/core/store/shared/models/shared-payloads';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';

export const loadGlTableSettingsByIdCommand = (payload: IdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'glTableSettings', value: payload.id }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const patchGlTableSettingsCommand = (
    payload: PatchGlTableSettingsPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'glTableSettings', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};
