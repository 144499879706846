import { LoadBySiteIdPayload } from 'app/core/store/shared/models/load.payload';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import {
    AddProgramRolePayload,
    PatchProgramRolePayload,
    ProgramRoleIdPayload,
    SetProgramRoleTagPayload,
} from './../../../../store/program-role/program-role.payloads';

export const loadProgramRoles = (payload: undefined, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'programs' }, { name: 'roles' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadProgramRolesBySite = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'programs/sites', value: payload.siteId }, { name: 'roles' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addProgramRole = (payload: AddProgramRolePayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'programs' }, { name: 'roles' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchProgramRole = (payload: PatchProgramRolePayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'programs' }, { name: 'roles', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const setProgramRoleTag = (
    payload: SetProgramRoleTagPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'programs' },
        { name: 'roles', value: payload.roleId },
        { name: 'setTag' },
    ]);
    baseCommand.payload.setData(payload.tag);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const deleteProgramRole = (payload: ProgramRoleIdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'programs' }, { name: 'roles', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};
