import { Action, createAction, props } from '@ngrx/store';
import { LoadByProjectIdPayload, LoadBySiteIdPayload } from '../shared/models/load.payload';
import { IdPayload } from '../shared/models/shared-payloads';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import { RiskRecord } from './models/risk.model';
import { AddRiskPayload, PatchRiskPayload, UpdateRiskPayload } from './risk.payloads';

// Get risk actions

export const getRisk = createAction('[Risk] Get Risk', props<{ payload: IdPayload }>());

export const getRiskSuccess = createAction(
    '[Risk] Get Risk success',
    props<{ risk: RiskRecord }>()
);

export const getRiskFail = createAction('[Risk] Get Risk fail', props<{ error: any }>());

// Load Risks by Site Id actions

export const loadRisksBySiteId = createAction(
    '[Raid] Load Risks by Site Id',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadRisksBySiteIdSuccess = createAction(
    '[Raid] Load Risks by Site Id success',
    props<{ risks: RiskRecord[] }>()
);

export const loadRisksBySiteIdFail = createAction(
    '[Raid] Load Risks by Site Id fail',
    props<{ error: any }>()
);

// Load Risks by Project Id actions

export const loadRisksByProjectId = createAction(
    '[Raid] Load Risks by Project Id',
    props<{ payload: LoadByProjectIdPayload }>()
);

export const loadRisksByProjectIdSuccess = createAction(
    '[Raid] Load Risks by Project Id success',
    props<{ risks: RiskRecord[] }>()
);

export const loadRisksByProjectIdFail = createAction(
    '[Raid] Load Risks by Project Id fail',
    props<{ error: any }>()
);

// Add Risk actions

export const addRisk = createAction(
    '[Raid] Add',
    props<{ payload: AddRiskPayload; options?: OptimisticableActionOptions }>()
);

export const addRiskSuccess = createAction(
    '[Raid] Add success',
    props<{ risk: RiskRecord; options?: OptimisticableActionOptions }>()
);

export const addRiskFail = createAction(
    '[Raid] Add fail',
    props<{ error: any; originAction: Action; riskId: string }>()
);

// Update Risk actions

export const updateRisk = createAction(
    '[Risk] Update',
    props<{ payload: UpdateRiskPayload; options?: OptimisticableActionOptions }>()
);

export const patchRisk = createAction(
    '[Risk] Patch',
    props<{ payload: PatchRiskPayload; options?: OptimisticableActionOptions }>()
);

export const patchRiskSuccess = createAction('[Risk] Patch success', props<PatchRiskPayload>());

export const patchRiskFail = createAction(
    '[Risk] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Delete Risk actions

export const deleteRisk = createAction(
    '[Risk] Delete',
    props<{ payload: IdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteRiskSuccess = createAction('[Risk] Delete success', props<IdPayload>());

export const deleteRiskFail = createAction(
    '[Risk] Delete fail',
    props<{ error: any; originAction: Action }>()
);
