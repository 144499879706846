import { Action, createAction, props } from '@ngrx/store';
import { LoadByProjectIdPayload, LoadBySiteIdPayload } from '../shared/models/load.payload';
import { IdPayload } from '../shared/models/shared-payloads';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import { AddIssuePayload, PatchIssuePayload, UpdateIssuePayload } from './issue.payloads';
import { IssueRecord } from './models/issue.model';

// Get issue actions

export const getIssue = createAction('[Issue] Get Issue', props<{ payload: IdPayload }>());

export const getIssueSuccess = createAction(
    '[AcIssuetion] Get Issue success',
    props<{ issue: IssueRecord }>()
);

export const getIssueFail = createAction('[Issue] Get Issue fail', props<{ error: any }>());

// Load Issues by Site Id issues

export const loadIssuesBySiteId = createAction(
    '[Issue] Load Issues by Site Id',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadIssuesBySiteIdSuccess = createAction(
    '[Issue] Load Issues by Site Id success',
    props<{ issues: IssueRecord[] }>()
);

export const loadIssuesBySiteIdFail = createAction(
    '[Issue] Load Issues by Site Id fail',
    props<{ error: any }>()
);

// Load Issues by Project Id issues

export const loadIssuesByProjectId = createAction(
    '[Issue] Load Issues by Project Id',
    props<{ payload: LoadByProjectIdPayload }>()
);

export const loadIssuesByProjectIdSuccess = createAction(
    '[Issue] Load Issues by Project Id success',
    props<{ issues: IssueRecord[] }>()
);

export const loadIssuesByProjectIdFail = createAction(
    '[Issue] Load Issues by Project Id fail',
    props<{ error: any }>()
);

// Add Issue issues

export const addIssue = createAction(
    '[Issue] Add',
    props<{ payload: AddIssuePayload; options?: OptimisticableActionOptions }>()
);

export const addIssueSuccess = createAction(
    '[Issue] Add success',
    props<{ issue: IssueRecord; options?: OptimisticableActionOptions }>()
);

export const addIssueFail = createAction(
    '[Issue] Add fail',
    props<{ error: any; originAction: Action; issueId: string }>()
);

// Update Issue issues

export const updateIssue = createAction(
    '[Issue] Update',
    props<{ payload: UpdateIssuePayload; options?: OptimisticableActionOptions }>()
);

export const patchIssue = createAction(
    '[Issue] Patch',
    props<{ payload: PatchIssuePayload; options?: OptimisticableActionOptions }>()
);

export const patchIssueSuccess = createAction('[Issue] Patch success', props<PatchIssuePayload>());

export const patchIssueFail = createAction(
    '[Issue] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Delete Issue issues

export const deleteIssue = createAction(
    '[Issue] Delete',
    props<{ payload: IdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteIssueSuccess = createAction('[Issue] Delete success', props<IdPayload>());

export const deleteIssueFail = createAction(
    '[Issue] Delete fail',
    props<{ error: any; originAction: Action }>()
);
