import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { LoadingStatusByProjectId, LoadingStatusBySiteId } from 'app/core/models/shared-models';
import { ProjectStatusReport } from './models/project-status-report.model';

export interface State
    extends EntityState<ProjectStatusReport>,
        LoadingStatusByProjectId,
        LoadingStatusBySiteId {}

export const adapter: EntityAdapter<ProjectStatusReport> =
    createEntityAdapter<ProjectStatusReport>();

export const initialState: State = adapter.getInitialState({
    loadingStatusByProjectId: {},
    loadingStatusBySiteId: {},
});
