import { CommandResult, JsonResponse } from 'app/utils/network';
import { ExtendedProjectMemberAllocation } from '../../../../store/allocation';

export interface GetAllocationsResponse extends CommandResult {
    payload: ExtendedProjectMemberAllocation[];
}

export class GetAllocationsResponse extends JsonResponse {
    allocations: ExtendedProjectMemberAllocation[];

    constructor(commandResult: CommandResult) {
        super(commandResult);
        this.allocations = this.deserializeModels(ExtendedProjectMemberAllocation);
    }
}

export class GetAllocationResponse extends JsonResponse {
    allocation: ExtendedProjectMemberAllocation;

    constructor(commandResult: CommandResult) {
        super(commandResult);
        this.allocation = this.deserializeModel(ExtendedProjectMemberAllocation);
    }
}
