import { JsonProperty } from 'app/utils/json-mapper';

export class BillingDetails {
    @JsonProperty('vatNumber')
    vatNumber?: string = undefined;

    @JsonProperty('companyName')
    companyName: string = undefined;

    @JsonProperty('contactPerson')
    contactPerson: string = undefined;

    @JsonProperty('address')
    address: string = undefined;

    @JsonProperty('city')
    city: string = undefined;

    @JsonProperty('region')
    region: string = undefined;

    @JsonProperty('postalCode')
    postalCode: string = undefined;

    @JsonProperty('country')
    country: string = undefined;

    @JsonProperty('language')
    language: string = undefined;

    constructor(init?: Partial<BillingDetails>) {
        Object.assign(this, init);
    }
}
