import {
    AddAdditionalSubscriptionsPayload,
    ChangeSubscriptionQuantityPayload,
    RemoveAdditionalSubscriptionPayload,
} from 'app/core/store/subscription/subscription.payloads';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import { SitePayloads } from '../../../../store/site';
import { SubscriptionPayloads } from '../../../../store/subscription';

export const getActibeSubscription = (
    payload: SitePayloads.SiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'subscriptions' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const createSubscription = (
    payload: SubscriptionPayloads.CreateSubscriptionPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'subscriptions' }]);
    baseCommand.method = RestfulMethod.POST;
    baseCommand.payload.setData(payload.subscription);
    return baseCommand;
};

export const patchBillingDetails = (
    payload: SubscriptionPayloads.PatchBillingDetailsPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'subscriptions' },
        { name: 'billingDetails' },
    ]);
    baseCommand.method = RestfulMethod.PATCH;
    baseCommand.payload.setData(payload.patch);
    return baseCommand;
};

export const cancelSubscription = (
    payload: SitePayloads.SiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'subscriptions' }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};

export const getSubscriptionQuantities = (
    payload: SitePayloads.SiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'subscriptions' },
        { name: 'quantities' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const getInvoices = (
    payload: SubscriptionPayloads.GetInvoicesPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'subscriptions' },
        { name: 'invoices', value: payload.maxCount ? '' + payload.maxCount : undefined },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const getUpcomingInvoice = (
    payload: SitePayloads.SiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'subscriptions' },
        { name: 'invoices' },
        { name: 'upcoming' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const changeSubscriptionQuantity = (
    payload: ChangeSubscriptionQuantityPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'subscriptions/quantities' },
    ]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const addAdditionalSubscriptions = (
    payload: AddAdditionalSubscriptionsPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'subscriptions/additional/add' },
    ]);
    baseCommand.payload.setData(payload.planIds);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const removeAdditionalSubscription = (
    payload: RemoveAdditionalSubscriptionPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'subscriptions/additional', value: payload.subscriptionId },
        { name: 'remove' },
    ]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};
