import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getPatch } from 'app/utils/json-patch';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { RestApiService } from '../../services/rest-api';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import * as AppStore from '../store';
import * as CommentActions from './comment.actions';
import * as CommentSelectors from './comment.selectors';
import { CtxComment } from './models';

@Injectable()
export class CommentEffects {
    loadCommentsBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommentActions.loadCommentsBySiteId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        CommentActions.loadCommentsBySiteIdSuccess({
                            comments: response.payload.map((dto) => this.getCommentFromDto(dto)),
                        })
                    ),
                    catchError((error) => of(CommentActions.loadCommentsBySiteIdFail({ error })))
                )
            )
        )
    );

    addComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommentActions.addComment),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        CommentActions.addCommentSuccess({
                            comment: this.getCommentFromDto(response.payload),
                        })
                    ),
                    catchError((error) => of(CommentActions.addCommentFail({ error })))
                )
            )
        )
    );

    updateComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommentActions.updateComment),
            withLatestFrom(this.store$.select(CommentSelectors.selectAll)),
            mergeMap(([action, comments]) => {
                const comment = comments.find((c) => c.id === action.payload.id);
                const patch = getPatch(comment, action.payload.changes);
                return of(
                    CommentActions.patchComment({
                        payload: {
                            id: action.payload.id,
                            patch: patch.filter((op) => !op.path.includes('date')),
                        },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommentActions.patchComment),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return CommentActions.patchCommentSuccess({
                            comment: this.getCommentFromDto(response.payload),
                        });
                    }),
                    catchError((error) =>
                        of(CommentActions.patchCommentFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    deleteComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommentActions.deleteComment),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return CommentActions.deleteCommentSuccess(action.payload);
                    }),
                    catchError((error) =>
                        of(CommentActions.deleteCommentFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>
    ) {}

    getCommentFromDto(dto: any): CtxComment {
        return { ...Model.createFromDto(CtxComment, dto), date: new Date(dto.date) };
    }
}
