import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[ctxAutofocus]',
})
export class AutofocusDirective implements AfterViewInit {
    @Input()
    set ctxAutofocus(condition: boolean | string) {
        this.autofocus = condition !== false;
    }

    private autofocus;
    constructor(private el: ElementRef) {}

    ngAfterViewInit() {
        if (this.autofocus || typeof this.autofocus === 'undefined') {
            setTimeout(() => {
                this.el.nativeElement.focus();
            }, 0);
        }
    }
}
