import { Action, createAction, props } from '@ngrx/store';
import { LoadBySiteIdPayload } from '../shared/models/load.payload';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import { ProjectRequest } from './models';
import {
    AddProjectRequestPayload,
    ExpandedProjectRequestsPayload,
    PatchProjectRequestPayload,
    ProjectRequestIdPayload
} from './project-request.payloads';

// Load Project requests actions

export const loadProjectRequests = createAction(
    '[Project request] Load Project requests',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadProjectRequestsSuccess = createAction(
    '[Project request] Load Project requests success',
    props<{ projectRequests: ProjectRequest[] }>()
);

export const loadProjectRequestsFail = createAction(
    '[Project request] Load Project requests fail',
    props<{ error: any }>()
);

// Add Project request actions

export const addProjectRequest = createAction(
    '[Project request] Add',
    props<{ payload: AddProjectRequestPayload }>()
);

export const addProjectRequestSuccess = createAction(
    '[Project request] Add success',
    props<{ request: ProjectRequest }>()
);

export const addProjectRequestFail = createAction(
    '[Project request] Add fail',
    props<{ error: any }>()
);

// Update Project request actions

export const patchProjectRequest = createAction(
    '[Project request] Patch',
    props<{ payload: PatchProjectRequestPayload; options?: OptimisticableActionOptions }>()
);

export const patchProjectRequestSuccess = createAction(
    '[Project request] Patch success',
    props<PatchProjectRequestPayload>()
);

export const patchProjectRequestFail = createAction(
    '[Project request] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Delete Project request actions

export const deleteProjectRequest = createAction(
    '[Project request] Delete',
    props<{ payload: ProjectRequestIdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteProjectRequestSuccess = createAction(
    '[Project request] Delete success',
    props<ProjectRequestIdPayload>()
);

export const deleteProjectRequestFail = createAction(
    '[Project request] Delete fail',
    props<{ error: any; originAction: Action }>()
);

// Update expanded Project requests

export const updateExpandedProjectRequestIds = createAction(
    '[Project request] Update expanded',
    props<ExpandedProjectRequestsPayload>()
);
