import { Action, createAction, props } from '@ngrx/store';
import { LoadBySiteIdPayload } from '../shared/models/load.payload';
import { OptimisticableActionOptions } from './../shared/optimisticable-action';
import {
    AddDocumentPayload,
    CopyDocumentsPayload,
    DeleteManyDocumentsPayload,
    DocumentIdPayload,
    DownloadDocumentPayload,
    DownloadDocumentVersionPayload,
    GetDocumentPreviewSuccessPayload,
    ImportDocumentPayload,
    LoadDocumentsByProgramPayload,
    LoadDocumentsByProjectPayload,
    LoadDocumentsBySitePayload,
    PatchDocumentPayload,
    UpdateDocumentPayload,
} from './document.payloads';
import { CopyCutOperationType, FileNode } from './models';

// Load documents actions

export const loadCompletixDocumentTemplates = createAction(
    '[Document] Load Completix document templates'
);

export const loadCompletixDocumentTemplatesSuccess = createAction(
    '[Document] Load Completix document templates success',
    props<{ documents: FileNode[] }>()
);

export const loadCompletixDocumentTemplatesFail = createAction(
    '[Document] Load Completix document templates fail',
    props<{ error: any }>()
);

export const loadCompletixDocumentTemplatesBySiteId = createAction(
    '[Document] Load Completix document templates by Site id',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadCompletixDocumentTemplatesBySiteIdSuccess = createAction(
    '[Document] Load Completix document templates by Site id success',
    props<{ documents: FileNode[] }>()
);

export const loadCompletixDocumentTemplatesBySiteIdFail = createAction(
    '[Document] Load Completix document templates by Site id fail',
    props<{ error: any }>()
);

export const loadDocumentsByProject = createAction(
    '[Document] Load by Project',
    props<{ payload: LoadDocumentsByProjectPayload }>()
);

export const loadDocumentsByProjectSuccess = createAction(
    '[Document] Load by Project success',
    props<{ documents: FileNode[]; projectId: string }>()
);

export const loadDocumentsByProjectFail = createAction(
    '[Document] Load by Project fail',
    props<{ error: any; projectId: string }>()
);

export const loadDocumentsByProgram = createAction(
    '[Document] Load by Program',
    props<{ payload: LoadDocumentsByProgramPayload }>()
);

export const loadDocumentsByProgramSuccess = createAction(
    '[Document] Load by Program success',
    props<{ documents: FileNode[]; programId: string }>()
);

export const loadDocumentsByProgramFail = createAction(
    '[Document] Load by Program fail',
    props<{ error: any; programId: string }>()
);

export const loadDocumentsBySite = createAction(
    '[Document] Load by Site',
    props<{ payload: LoadDocumentsBySitePayload }>()
);

export const loadDocumentsBySiteSuccess = createAction(
    '[Document] Load by Site success',
    props<{ documents: FileNode[]; siteId: string }>()
);

export const loadDocumentsFail = createAction('[Document] Load fail', props<{ error: any }>());

// Load Completix Change Request Form

export const loadCompletixDocumentChangeRequestForm = createAction(
    '[Document] Load Completix Document Change Request Form'
);

export const loadCompletixDocumentChangeRequestFormSuccess = createAction(
    '[Document] Load Completix Document Change Request Form success',
    props<{ document: FileNode }>()
);

export const loadCompletixDocumentChangeRequestFormFail = createAction(
    '[Document] Load Completix Document Change Request Form fail',
    props<{ error: any }>()
);

// Load Completix Change Request Form by Site

export const loadCompletixDocumentChangeRequestFormBySiteId = createAction(
    '[Document] Load Completix Document Change Request Form by Site',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadCompletixDocumentChangeRequestFormBySiteIdSuccess = createAction(
    '[Document] Load Completix Document Change Request Form by Site success',
    props<{ document: FileNode }>()
);

export const loadCompletixDocumentChangeRequestFormBySiteIdFail = createAction(
    '[Document] Load Completix Document Change Request Form by Site fail',
    props<{ error: any }>()
);

// Load Site Change Request Form

export const loadDocumentChangeRequestFormBySiteId = createAction(
    '[Document] Load Site Document Change Request Form',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadDocumentChangeRequestFormBySiteIdSuccess = createAction(
    '[Document] Load Site Completix Document Change Request Form success',
    props<{ document: FileNode }>()
);

export const loadDocumentChangeRequestFormBySiteIdFail = createAction(
    '[Document] Load Site Site Document Change Request Form fail',
    props<{ error: any }>()
);

// Get document by id

export const getDocumentById = createAction('[Document] Get by id', props<DocumentIdPayload>());

export const getDocumentByIdSuccess = createAction(
    '[Document] Get by id success',
    props<{ document: FileNode }>()
);

export const getDocumentByIdFail = createAction(
    '[Document] Get by id fail',
    props<{ error: any }>()
);

export const getManyDocumentsSuccess = createAction(
    '[Document] Get many success',
    props<{ documents: FileNode[] }>()
);

// Add document actions

export const addDocument = createAction('[Document] Add', props<{ payload: AddDocumentPayload }>());

export const addDocumentSuccess = createAction(
    '[Document] Add success',
    props<{ document: FileNode }>()
);

export const addDocumentFail = createAction('[Document] Add fail', props<{ error: any }>());

export const addManyDocumentsSuccess = createAction(
    '[Document] Add many success',
    props<{ documents: FileNode[] }>()
);

export const addManyDocumentsFail = createAction(
    '[Document] Add many fail',
    props<{ error: any }>()
);

export const importDocument = createAction(
    '[Document] Import',
    props<{ payload: ImportDocumentPayload }>()
);

// Update document actions

export const updateDocument = createAction(
    '[Document] Update',
    props<{ payload: UpdateDocumentPayload; options?: OptimisticableActionOptions }>()
);

// Patch document actions

export const patchDocument = createAction(
    '[Document] Patch',
    props<{ payload: PatchDocumentPayload; options?: OptimisticableActionOptions }>()
);

export const patchDocumentSuccess = createAction(
    '[Document] Patch success',
    props<PatchDocumentPayload>()
);

export const patchDocumentFail = createAction(
    '[Document] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Add document version actions

export const addDocumentVersion = createAction(
    '[Document] Add version',
    props<{ payload: DocumentIdPayload }>()
);

// Delete document actions

export const deleteDocument = createAction(
    '[Document] Delete',
    props<{ payload: DocumentIdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteDocumentSuccess = createAction(
    '[Document] Delete success',
    props<DeleteManyDocumentsPayload>()
);

export const deleteDocumentFail = createAction(
    '[Document] Delete fail',
    props<{ error: any; originAction: Action }>()
);

// Delete document actions

export const deleteManyDocuments = createAction(
    '[Document] Delete many',
    props<{ payload: DeleteManyDocumentsPayload; options?: OptimisticableActionOptions }>()
);

// Copy document actions

export const copyDocuments = createAction(
    '[Document] Copy documents',
    props<{ payload: CopyDocumentsPayload }>()
);

export const copyDocumentsSuccess = createAction(
    '[Document] Copy documents success',
    props<{ documents: FileNode[] }>()
);

export const copyDocumentsFail = createAction(
    '[Document] Copy document fail',
    props<{ error: any }>()
);

// Copy/cut document

export const setCopyCutBuffer = createAction(
    '[Document] Add copy/cut',
    props<{ ids: string[]; operationType: CopyCutOperationType }>()
);

export const clearCopyCutBuffer = createAction('[Document] Clear copy/cut');

// Change current opened ID

export const changeCurrentOpenedNodeId = createAction(
    '[Document] Change current opened id',
    props<DocumentIdPayload>()
);

// Toggle auto save

export const toggleAutoSave = createAction(
    '[Document] Toggle auto save',
    props<{ status: boolean }>()
);

// Get documents preview

export const getDocumentPreview = createAction(
    '[Document] Get preview',
    props<{ payload: DocumentIdPayload }>()
);

export const getDocumentPreviewSuccess = createAction(
    '[Document] Get preview success',
    props<GetDocumentPreviewSuccessPayload>()
);

export const getDocumentPreviewFail = createAction(
    '[Document] Get preview fail',
    props<{ error: any; documentId: string }>()
);

export const downloadDocument = createAction(
    '[Document] Download',
    props<{ payload: DownloadDocumentPayload }>()
);

export const downloadDocumentVersion = createAction(
    '[Document] Download version',
    props<{ payload: DownloadDocumentVersionPayload }>()
);
