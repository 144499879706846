import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import * as CommunicationActions from './communication.actions';
import { adapter, initialState } from './communication.store';
import { Communication } from './models';

export const reducer = createReducer(
    initialState,

    on(CommunicationActions.loadCommunicationByProjectId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(CommunicationActions.loadCommunicationByProjectIdSuccess, (state, payload) => {
        const communicationExists = state.entities[payload.communication.id];
        const configuredState = communicationExists
            ? adapter.removeOne(payload.communication.id, state)
            : state;
        const newState = adapter.addOne(payload.communication, configuredState);
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(CommunicationActions.loadCommunicationByProjectIdFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(CommunicationActions.loadCommunicationBySiteId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(CommunicationActions.loadCommunicationBySiteIdSuccess, (state, payload) => {
        const newState = adapter.addMany(
            payload.communications,
            adapter.removeMany(
                payload.communications.map((c) => c.id),
                state
            )
        );
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(CommunicationActions.loadCommunicationBySiteIdFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(CommunicationActions.loadCompletixCommunicationTemplatesBySiteId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(CommunicationActions.loadCompletixCommunicationTemplates, (state) => ({
        ...state,
        completixCommunicationsLoading: true,
    })),

    on(CommunicationActions.loadCompletixCommunicationTemplatesSuccess, (state, payload) => {
        const newState = adapter.addMany(
            payload.communications,
            adapter.removeMany(
                payload.communications.map((c) => c.id),
                state
            )
        );
        return { ...newState, completixCommunicationsLoading: false };
    }),

    on(CommunicationActions.loadCompletixCommunicationTemplatesFail, (state) => ({
        ...state,
        completixCommunicationsLoading: false,
    })),

    on(CommunicationActions.addCommunicationSuccess, (state, payload) =>
        adapter.addOne(payload.communication, state)
    ),

    on(CommunicationActions.patchCommunication, (state, action) => {
        if (!action.options?.optimistic) return state;
        const communication = state.entities[action.payload.id];
        const patched = getPatched(communication, action.payload.patch);
        const update: Update<Communication> = {
            id: patched.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(CommunicationActions.patchCommunicationSuccess, (state, payload) => {
        const communication = state.entities[payload.id];
        const patched = getPatched(communication, payload.patch);
        const update: Update<Communication> = {
            id: patched.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(CommunicationActions.patchCommunicationFail, (state) => ({ ...state })),

    on(CommunicationActions.deleteCommunication, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(CommunicationActions.deleteCommunicationSuccess, (state, payload) =>
        adapter.removeOne(payload.id, state)
    ),

    on(CommunicationActions.changeProjectCommunicationTemplateSuccess, (state, payload) => {
        const currentCommunication = Object.values(state.entities).find(
            (c) => c.projectId === payload.communication.projectId
        );
        return adapter.addOne(
            payload.communication,
            adapter.removeOne(currentCommunication.id, state)
        );
    })
);
