import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Observable, Observer, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RestfulCommand, RestfulMethod } from '../commands/restful.command';
import { Gateway } from './base.gateway';

export const RestApiBaseUrl = new InjectionToken('REST_API_BASE_URL');

@Injectable()
export class RestfulGateway extends Gateway {
    constructor(
        private http: HttpClient,
        @Optional()
        @Inject(RestApiBaseUrl)
        private baseUrl?: string
    ) {
        super();
        this.baseUrl = baseUrl || '';
    }

    send(command: RestfulCommand): Observable<any> {
        const siteHubConnectionIdHeader = command.siteHubConnectionId
            ? { siteHubConnectionId: command.siteHubConnectionId }
            : {};
        const defaultHeaders = {
            'Content-Type': command.mimeType,
            ...siteHubConnectionIdHeader,
        };
        const tenantIdHeaders = { tenantId: command.siteId };
        const headersWithTenantId = {
            ...defaultHeaders,
            ...tenantIdHeaders,
        };

        const options = command.mimeType
            ? {
                  headers: command.siteId ? headersWithTenantId : defaultHeaders,
              }
            : { headers: tenantIdHeaders };
        switch (command.method) {
            case RestfulMethod.GET:
                return this.http
                    .get(this.baseUrl + command.resource + command.query, options)
                    .pipe(catchError(this.handleError));

            case RestfulMethod.POST:
                return this.http
                    .post(this.baseUrl + command.resource, command.serialize(), options)
                    .pipe(catchError(this.handleError));

            case RestfulMethod.PUT:
                return this.http
                    .put(this.baseUrl + command.resource, command.serialize(), options)
                    .pipe(catchError(this.handleError));

            case RestfulMethod.PATCH:
                return this.http
                    .patch(this.baseUrl + command.resource, command.serialize(), options)
                    .pipe(catchError(this.handleError));

            case RestfulMethod.DELETE:
                return this.http
                    .delete(this.baseUrl + command.resource, options)
                    .pipe(catchError(this.handleError));

            default:
                console.error(`Wrong restful method provided ${command.method}`);
                return Observable.create((obs: Observer<any>) => obs.complete());
        }
    }

    private handleError(error: any): Observable<string> {
        if (error.error) {
            console.error('Server error', error.error);
        } else {
            const errMsg: string = error.message
                ? error.message
                : error.status
                ? `${error.status} - ${error.statusText}`
                : 'No connection to server';
            console.error(errMsg);
        }
        return observableThrowError(error);
    }
}
