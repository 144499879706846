import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SiteMember } from './models';

export interface LoadingStatus {
    serverRequestInProgress: boolean;
    loaded: boolean;
}

export interface State extends EntityState<SiteMember>, LoadingStatus {
    currentSiteMember: SiteMember;
}

export const adapter: EntityAdapter<SiteMember> = createEntityAdapter<SiteMember>();

export const initialState: State = adapter.getInitialState({
    serverRequestInProgress: false,
    loaded: false,
    currentSiteMember: null,
});
