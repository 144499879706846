import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import {
    AsyncUpdateStatusDefault,
    AsyncUpdateStatusDefaultLoad,
    AsyncUpdateStatusDefaultLoadSuccess,
} from './../shared/async-update-status.store';
import * as GlTableSettingsActions from './gl-table-settings.actions';
import { adapter, initialState } from './gl-table-settings.store';

export const reducer = createReducer(
    initialState,

    on(GlTableSettingsActions.loadGlTableSettingsById, (state, action) => {
        const loadingStatusMap = state.loadingStatusById;
        const id = action.payload.id;

        return {
            ...state,
            loadingStatusById: {
                ...loadingStatusMap,
                [id]: { ...(loadingStatusMap[id] ?? {}), ...AsyncUpdateStatusDefaultLoad },
            },
        };
    }),

    on(GlTableSettingsActions.loadGlTableSettingsByIdSuccess, (state, payload) => {
        const currentSettings = Object.values(state.entities).find(
            (s) => s.listContainerId === payload.glTableSettings.listContainerId
        );
        const newState = adapter.addOne(
            payload.glTableSettings,
            adapter.removeOne(currentSettings?.id, state)
        );
        const loadingStatusMap = state.loadingStatusById;
        const id = payload.glTableSettings.id;

        return {
            ...newState,
            loadingStatusById: {
                ...loadingStatusMap,
                [id]: { ...(loadingStatusMap[id] ?? {}), ...AsyncUpdateStatusDefaultLoadSuccess },
            },
        };
    }),

    on(GlTableSettingsActions.loadGlTableSettingsByIdFail, (state, payload) => {
        const loadingStatusMap = state.loadingStatusById;
        const id = payload.id;

        return {
            ...state,
            loadingStatusById: {
                ...loadingStatusMap,
                [id]: {
                    ...(loadingStatusMap[id] ?? {}),
                    ...AsyncUpdateStatusDefault,
                    loadingFailed: true,
                    error: payload.error,
                },
            },
        };
    }),

    on(GlTableSettingsActions.patchGlTableSettings, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patched = getPatched(state.entities[action.payload.id], action.payload.patch);
        return adapter.addOne(patched, adapter.removeOne(patched.id, state));
    }),

    on(GlTableSettingsActions.patchGlTableSettingsSuccess, (state, payload) => {
        const patched = getPatched(state.entities[payload.id], payload.patch);
        return adapter.addOne(patched, adapter.removeOne(patched.id, state));
    })
);
