import { createReducer, on } from '@ngrx/store';
import {
    AsyncUpdateStatusDefault,
    AsyncUpdateStatusDefaultLoadSuccess,
} from '../shared/async-update-status.store';
import * as UserLogActions from './user-log.actions';
import { adapter, initialState } from './user-log.store';

export const reducer = createReducer(
    initialState,

    on(UserLogActions.loadUserLogsBySiteId, (state) => ({
        ...state,
        ...AsyncUpdateStatusDefault,
        serverRequestInProgress: true,
    })),

    on(UserLogActions.loadUserLogsBySiteIdSuccess, (state, payload) => {
        const newState = adapter.addMany(
            payload.logs,
            adapter.removeMany(
                payload.logs.map((l) => l.id),
                state
            )
        );
        return {
            ...newState,
            ...AsyncUpdateStatusDefaultLoadSuccess,
            serverRequestInProgress: false,
        };
    }),

    on(UserLogActions.loadUserLogsBySiteIdFail, (state) => ({
        ...state,
        ...AsyncUpdateStatusDefault,
        serverRequestInProgress: false,
    })),

    on(UserLogActions.loadUserLogsByProgramId, (state) => ({
        ...state,
        ...AsyncUpdateStatusDefault,
        serverRequestInProgress: true,
    })),

    on(UserLogActions.loadUserLogsByProgramIdSuccess, (state, payload) => {
        const newState = adapter.addMany(
            payload.logs,
            adapter.removeMany(
                payload.logs.map((l) => l.id),
                state
            )
        );
        return {
            ...newState,
            ...AsyncUpdateStatusDefaultLoadSuccess,
            serverRequestInProgress: false,
        };
    }),

    on(UserLogActions.loadUserLogsByProgramIdFail, (state) => ({
        ...state,
        ...AsyncUpdateStatusDefault,
        serverRequestInProgress: false,
    })),

    on(UserLogActions.loadUserLogsByProjectId, (state) => ({
        ...state,
        ...AsyncUpdateStatusDefault,
        serverRequestInProgress: true,
    })),

    on(UserLogActions.loadUserLogsByProjectIdSuccess, (state, payload) => {
        const newState = adapter.addMany(
            payload.logs,
            adapter.removeMany(
                payload.logs.map((l) => l.id),
                state
            )
        );
        return {
            ...newState,
            ...AsyncUpdateStatusDefaultLoadSuccess,
            serverRequestInProgress: false,
        };
    }),

    on(UserLogActions.loadUserLogsByProjectIdFail, (state) => ({
        ...state,
        ...AsyncUpdateStatusDefault,
    })),

    on(UserLogActions.addUserLog, (state, payload) => adapter.addOne(payload.log, state))
);
