import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EntityPatchSuccessResponse } from 'app/core/services/rest-api/features/patch.response';
import { SnackBarService } from 'app/services/snack-bar/snack-bar.service';
import { getPatch } from 'app/utils/json-patch';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { CommunicationSelectors } from '.';
import { RestApiService } from '../../services/rest-api';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import { OptimisticDefaults } from '../shared/optimisticable-action';
import * as AppStore from '../store';
import {
    LoadCommunicationsByProjectIdResponse,
    LoadCommunicationsBySiteIdResponse,
} from './../../services/rest-api/features/communication/communication.responses';
import * as CommunicationActions from './communication.actions';
import { Communication, CommunicationRecord } from './models';

@Injectable()
export class CommunicationEffects {
    loadCommunicationByProjectId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommunicationActions.loadCommunicationByProjectId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: LoadCommunicationsByProjectIdResponse) =>
                        CommunicationActions.loadCommunicationByProjectIdSuccess({
                            communication: Model.createFromDto(Communication, response.payload),
                        })
                    ),
                    catchError((error) =>
                        of(CommunicationActions.loadCommunicationByProjectIdFail({ error }))
                    )
                )
            )
        )
    );

    loadCommunicationBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommunicationActions.loadCommunicationBySiteId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: LoadCommunicationsBySiteIdResponse) =>
                        CommunicationActions.loadCommunicationBySiteIdSuccess({
                            communications: response.payload.map((dto) =>
                                Model.createFromDto(Communication, dto)
                            ),
                        })
                    ),
                    catchError((error) =>
                        of(CommunicationActions.loadCommunicationBySiteIdFail({ error }))
                    )
                )
            )
        )
    );

    loadCompletixCommunicationBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommunicationActions.loadCompletixCommunicationTemplatesBySiteId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: LoadCommunicationsBySiteIdResponse) =>
                        CommunicationActions.loadCommunicationBySiteIdSuccess({
                            communications: response.payload.map((dto) =>
                                Model.createFromDto(Communication, dto)
                            ),
                        })
                    ),
                    catchError((error) =>
                        of(CommunicationActions.loadCommunicationBySiteIdFail({ error }))
                    )
                )
            )
        )
    );

    loadCompletixCommunicationTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommunicationActions.loadCompletixCommunicationTemplates),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: LoadCommunicationsBySiteIdResponse) =>
                        CommunicationActions.loadCompletixCommunicationTemplatesSuccess({
                            communications: response.payload.map((dto) =>
                                Model.createFromDto(Communication, dto)
                            ),
                        })
                    ),
                    catchError((error) =>
                        of(CommunicationActions.loadCompletixCommunicationTemplatesFail({ error }))
                    )
                )
            )
        )
    );

    importCompletixCommunicationTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommunicationActions.importCompletixCommunicationTemplate),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Importing a template...');
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBarService.hide()),
                    map((response) =>
                        CommunicationActions.addCommunicationSuccess({
                            communication: Model.createFromDto(Communication, response.payload),
                        })
                    ),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(CommunicationActions.addCommunicationFail({ error }));
                    })
                );
            })
        )
    );

    addCommunication$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommunicationActions.addCommunication),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) =>
                        CommunicationActions.addCommunicationSuccess({
                            communication: Model.createFromDto(Communication, response.payload),
                        })
                    ),
                    catchError((error) => of(CommunicationActions.addCommunicationFail({ error })))
                )
            )
        )
    );

    addCommunicationRecord$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommunicationActions.addCommunicationRecord),
            withLatestFrom(this.store$.select(CommunicationSelectors.selectAll)),
            mergeMap(([payload, communications]) => {
                const communication = communications.find((c) => c.id === payload.id);
                return of(
                    CommunicationActions.updateCommunication({
                        payload: {
                            id: payload.id,
                            changes: {
                                records: [
                                    ...(communication.records || []),
                                    new CommunicationRecord(),
                                ],
                            },
                        },
                        options: OptimisticDefaults,
                    })
                );
            })
        )
    );

    updateCommunication$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommunicationActions.updateCommunication),
            withLatestFrom(this.store$.select(CommunicationSelectors.selectAll)),
            mergeMap(([action, communications]) => {
                const updatedCommunication = communications.find((c) => c.id === action.payload.id);
                const patch = getPatch(updatedCommunication, action.payload.changes);
                return of(
                    CommunicationActions.patchCommunication({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchCommunication$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommunicationActions.patchCommunication),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: EntityPatchSuccessResponse) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return CommunicationActions.patchCommunicationSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) =>
                        of(
                            CommunicationActions.patchCommunicationFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteCommunication$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommunicationActions.deleteCommunication),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return CommunicationActions.deleteCommunicationSuccess({
                            id: action.payload.id,
                        });
                    }),
                    catchError((error) =>
                        of(
                            CommunicationActions.deleteCommunicationFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    changeCommunicationTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommunicationActions.changeProjectCommunicationTemplate),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) =>
                        CommunicationActions.changeProjectCommunicationTemplateSuccess({
                            communication: Model.createFromDto(Communication, response.payload),
                        })
                    ),
                    catchError((error) =>
                        of(CommunicationActions.changeProjectCommunicationTemplateFail({ error }))
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>,
        private snackBarService: SnackBarService
    ) {}
}
