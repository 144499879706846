import { ApprovalState } from 'app/core/store/approval-request';
import {
    LoadByProjectIdPayload,
    LoadBySiteIdPayload,
} from 'app/core/store/shared/models/load.payload';
import { IdPayload } from 'app/core/store/shared/models/shared-payloads';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import {
    AddChangeRequestApprovalPayload,
    AddChangeRequestPayload,
    ChangeRequestIdPayload,
    DeleteChangeRequestApprovalPayload,
    ManageChangeRequestApprovalPayload,
    ManageChangeRequestApprovalSubmissionPayload,
    PatchChangeRequestApprovalPayload,
    PatchChangeRequestPayload,
} from './../../../../store/change-request/change-request.payloads';

export const loadChangeRequestsByProjectIdCommand = (
    payload: LoadByProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projects', value: payload.projectId },
        { name: 'changeRequests' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadChangeRequestsBySiteIdCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'changeRequests/sites', value: payload.siteId }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const getChangeRequestByIdCommand = (payload: IdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'changeRequests', value: payload.id }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addChangeRequestCommand = (
    payload: AddChangeRequestPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'changeRequests' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchChangeRequestCommand = (
    payload: PatchChangeRequestPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'changeRequests', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteChangeRequestCommand = (
    payload: ChangeRequestIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'changeRequests', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};

export const addChangeRequestApprovalCommand = (
    payload: AddChangeRequestApprovalPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'changeRequests', value: payload.requestId },
        { name: 'approvals' },
    ]);
    baseCommand.payload.setData(payload.options);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const deleteChangeRequestApprovalCommand = (
    payload: DeleteChangeRequestApprovalPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'changeRequests', value: payload.requestId },
        { name: 'approvals', value: payload.approvalId },
    ]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};

export const manageChangeRequestApprovalSubmissionCommand = (
    payload: ManageChangeRequestApprovalSubmissionPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'changeRequests', value: payload.requestId },
        { name: 'approvals', value: payload.approvalId },
        { name: 'submission', value: payload.submitted ? 'submit' : 'unsubmit' },
    ]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const manageChangeRequestApprovalCommand = (
    payload: ManageChangeRequestApprovalPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'changeRequests' },
        { name: 'approvals', value: payload.changeRequestApprovalId },
        {
            name: 'manageApproval',
            value:
                payload.approvalState === ApprovalState.Approved
                    ? 'approve'
                    : payload.approvalState === ApprovalState.Rejected
                    ? 'reject'
                    : '',
        },
    ]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchChangeRequestApprovalCommand = (
    payload: PatchChangeRequestApprovalPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'changeRequests', value: payload.changeRequestId },
        { name: 'approvals', value: payload.approvalId },
    ]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const loadChangeRequestApproversByProjectIdCommand = (
    payload: LoadByProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projects', value: payload.projectId },
        { name: 'changeRequests' },
        { name: 'approvers' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const reOpenChangeRequestCommand = (
    payload: ChangeRequestIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'changeRequests', value: payload.id }, { name: 'reOpen' }]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};
