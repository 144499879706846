import { Invoice, InvoiceGroup, Subscription, SubscriptionQuantity } from 'app/core/models/billing';
import { CommandResult, JsonResponse } from 'app/utils/network';

export class GetActiveSubscriptionResponse extends JsonResponse {
    subscription: Subscription;

    constructor(commandResult: CommandResult) {
        super(commandResult);
        this.subscription = this.deserializeModel(Subscription);
    }
}

export class CreateSubscriptionResponse extends JsonResponse {
    subscription: Subscription;

    constructor(commandResult: CommandResult) {
        super(commandResult);
        this.subscription = this.deserializeModel(Subscription);
    }
}

export class GetSubscriptionQuantitiesResponse extends JsonResponse {
    quantity: SubscriptionQuantity;

    constructor(commandResult: CommandResult) {
        super(commandResult);
        this.quantity = this.deserializeModel(SubscriptionQuantity);
    }
}

export class CancelSubscriptionResponse extends JsonResponse {
    subscription: Subscription;

    constructor(commandResult: CommandResult) {
        super(commandResult);
        this.subscription = this.deserializeModel(Subscription);
    }
}

export class GetInvoicesResponse extends JsonResponse {
    invoices: InvoiceGroup[];

    constructor(commandResult: CommandResult) {
        super(commandResult);
        this.invoices = this.deserializeModels(InvoiceGroup);
    }
}

export class GetUpcomingInvoiceResponse extends JsonResponse {
    invoice: Invoice;

    constructor(commandResult: CommandResult) {
        super(commandResult);
        this.invoice = this.deserializeModel(Invoice);
    }
}

export class PatchBillingDetailsResponse extends JsonResponse {
    subscription: Subscription;

    constructor(commandResult: CommandResult) {
        super(commandResult);
        this.subscription = this.deserializeModel(Subscription);
    }
}
