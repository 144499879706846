import { Action, createAction, props } from '@ngrx/store';
import { LoadBySiteIdPayload } from '../shared/models/load.payload';
import { IdPayload } from '../shared/models/shared-payloads';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import { ScheduleTask } from './models/schedule-task.model';
import {
    AddScheduleTaskPayload,
    PatchScheduleTaskPayload,
    UpdateScheduleTaskPayload,
    UpdateScheduleTaskWithCalculationPayload,
} from './schedule-task.payloads';

// Load ScheduleTasks by Site Id actions

export const loadScheduleTasksByContainerId = createAction(
    '[ScheduleTask] Load ScheduleTasks by Container Id',
    props<{ payload: { id: string; siteId?: string } }>()
);

export const loadScheduleTasksByContainerIdSuccess = createAction(
    '[ScheduleTask] Load ScheduleTasks by Container id success',
    props<{ tasks: ScheduleTask[]; containerId: string }>()
);

export const loadScheduleTasksByContainerIdFail = createAction(
    '[ScheduleTask] Load ScheduleTasks by Container id fail',
    props<{ error: any; containerId: string }>()
);

export const loadScheduleTasksBySiteId = createAction(
    '[ScheduleTask] Load ScheduleTasks by Site Id',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadScheduleTasksBySiteIdSuccess = createAction(
    '[ScheduleTask] Load ScheduleTasks by Site id success',
    props<{ tasks: ScheduleTask[]; siteId: string; listContainerIds: string[] }>()
);

export const loadScheduleTasksBySiteIdFail = createAction(
    '[ScheduleTask] Load ScheduleTasks by Site id fail',
    props<{ error: any; siteId: string }>()
);

// Add ScheduleTask actions

export const addScheduleTask = createAction(
    '[ScheduleTask] Add',
    props<{ payload: AddScheduleTaskPayload; options?: OptimisticableActionOptions }>()
);

export const addScheduleTaskSuccess = createAction(
    '[ScheduleTask] Add success',
    props<{ task: ScheduleTask; options?: OptimisticableActionOptions }>()
);

export const addScheduleTaskFail = createAction(
    '[ScheduleTask] Add fail',
    props<{ error: any; originAction: Action; taskId: string }>()
);

// Update ScheduleTask actions

export const updateScheduleTasks = createAction(
    '[ScheduleTask] Update',
    props<{ payload: UpdateScheduleTaskPayload[]; options?: OptimisticableActionOptions }>()
);

export const updateScheduleTaskWithCalculation = createAction(
    '[ScheduleTask] Update with calculation',
    props<{
        payload: UpdateScheduleTaskWithCalculationPayload;
        options?: OptimisticableActionOptions;
    }>()
);

export const patchScheduleTasks = createAction(
    '[ScheduleTask] Patch',
    props<{ payload: PatchScheduleTaskPayload[]; options?: OptimisticableActionOptions }>()
);

export const patchScheduleTasksSuccess = createAction(
    '[ScheduleTask] Patch success',
    props<{ payload: PatchScheduleTaskPayload[] }>()
);

export const patchScheduleTasksFail = createAction(
    '[ScheduleTask] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Delete ScheduleTask actions

export const deleteScheduleTask = createAction(
    '[ScheduleTask] Delete',
    props<{ payload: IdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteScheduleTaskSuccess = createAction(
    '[ScheduleTask] Delete success',
    props<IdPayload>()
);

export const deleteScheduleTaskFail = createAction(
    '[ScheduleTask] Delete fail',
    props<{ error: any; originAction: Action }>()
);

// Import MS project tasks
export const importMicrosoftProjectTasks = createAction(
    '[ScheduleTask] Import MS Project tasks',
    props<{ listContainerId: string; tasks: ScheduleTask[] }>()
);
