import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppStore } from 'app/core/store';
import { ActionActions } from 'app/core/store/actions';
import { AllocationActions } from 'app/core/store/allocation';
import { ApprovalRequestActions } from 'app/core/store/approval-request';
import { BacklogTaskActions } from 'app/core/store/backlog-task';
import { BudgetTableActions } from 'app/core/store/budget-table';
import { CalendarActions } from 'app/core/store/calendar';
import { ChangeRequestActions } from 'app/core/store/change-request';
import { CommentActions } from 'app/core/store/comment';
import { CommunicationActions } from 'app/core/store/communication';
import { DecisionActions } from 'app/core/store/decision';
import { DocumentActions } from 'app/core/store/document';
import { IssueActions } from 'app/core/store/issue';
import { KanbanBoardActions } from 'app/core/store/kanban';
import { LayoutActions } from 'app/core/store/layout';
import { MinuteActions } from 'app/core/store/minute';
import { NotificationActions } from 'app/core/store/notification';
import { PortfolioActions } from 'app/core/store/portfolio';
import { ProgramActions } from 'app/core/store/program';
import { ProgramMemberActions } from 'app/core/store/program-member';
import { ProgramStatusActions } from 'app/core/store/program-status-report';
import { ProjectActions } from 'app/core/store/project';
import { ProjectFieldActions } from 'app/core/store/project-field';
import { ProjectMemberActions } from 'app/core/store/project-member';
import { ProjectPhaseActions } from 'app/core/store/project-phase';
import { ListContainerActions } from 'app/core/store/project-phase-container';
import { ProjectRequestActions } from 'app/core/store/project-request';
import { ProjectStatusActions } from 'app/core/store/project-status-report';
import { ProjectTemplateActions } from 'app/core/store/project-template';
import { ReminderActions } from 'app/core/store/reminder';
import { SiteMemberActions } from 'app/core/store/resource';
import { RiskActions } from 'app/core/store/risks';
import { ScheduleTaskActions } from 'app/core/store/schedule-template';
import { SiteActions } from 'app/core/store/site';
import { SiteSettingsActions } from 'app/core/store/site-settings';
import { TimesheetActions } from 'app/core/store/timesheet';
import { UserLogActions } from 'app/core/store/user-log';
import { VendorActions } from 'app/core/store/vendor';

@Injectable()
export class BootstrapService {
    constructor(private store: Store<AppStore.AppState>) {}

    loadCurrentSiteData(siteId: string, initDataLoaded?: boolean) {
        this.loadBySiteId(siteId, initDataLoaded);
        console.log('LOAD SITE DATA', initDataLoaded);
    }

    private loadBySiteId(siteId: string, initDataLoaded?: boolean) {
        if (!initDataLoaded) {
            this.store.dispatch(SiteActions.loadRolesBySite({ payload: { siteId } }));
            this.store.dispatch(SiteMemberActions.loadMySiteMember({ payload: { siteId } }));
            this.store.dispatch(SiteSettingsActions.loadSiteSettings({ payload: { siteId } }));
            this.store.dispatch(LayoutActions.loadLayoutSettings({ payload: { siteId } }));
        }

        this.store.dispatch(SiteMemberActions.loadMembersBySiteId({ payload: { siteId } }));
        this.store.dispatch(ProjectActions.getProjectsBySite({ payload: { siteId } }));
        this.store.dispatch(
            ProjectMemberActions.loadProjectMembersBySiteId({ payload: { siteId } })
        );
        this.store.dispatch(ProgramActions.loadPrograms({ payload: { siteId } }));
        this.store.dispatch(
            ProgramMemberActions.loadProgramMembersBySiteId({ payload: { siteId } })
        );
        this.store.dispatch(
            ListContainerActions.loadListContainersBySiteId({ payload: { siteId } })
        );
        this.store.dispatch(TimesheetActions.loadTimesheetsBySiteId({ payload: { siteId } }));
        this.store.dispatch(ScheduleTaskActions.loadScheduleTasksBySiteId({ payload: { siteId } }));
        this.store.dispatch(BacklogTaskActions.loadBacklogTasksBySiteId({ payload: { siteId } }));
        this.store.dispatch(ProjectPhaseActions.loadProjectPhasesBySiteId({ payload: { siteId } }));
        this.store.dispatch(MinuteActions.loadMinutesBySiteId({ payload: { siteId } }));
        this.store.dispatch(ApprovalRequestActions.loadApprovalRequests({ payload: { siteId } }));
        this.store.dispatch(ProjectFieldActions.loadProjectFieldsBySiteId({ payload: { siteId } }));
        this.store.dispatch(
            ProjectStatusActions.loadProjectStatusesBySiteId({ payload: { siteId } })
        );
        this.store.dispatch(
            ProgramStatusActions.loadProgramStatusesBySiteId({ payload: { siteId } })
        );
        this.store.dispatch(PortfolioActions.loadPortfolios({ payload: { siteId } }));
        this.store.dispatch(CalendarActions.loadCalendarsBySiteId({ payload: { siteId } }));
        this.store.dispatch(AllocationActions.loadAllocationsBySite({ payload: { siteId } }));
        this.store.dispatch(
            CommunicationActions.loadCommunicationBySiteId({ payload: { siteId } })
        );
        this.store.dispatch(
            ChangeRequestActions.loadChangeRequestsBySiteId({ payload: { siteId } })
        );
        this.store.dispatch(VendorActions.loadVendorsBySiteId({ payload: { siteId } }));
        this.store.dispatch(CommentActions.loadCommentsBySiteId({ payload: { siteId } }));
        this.store.dispatch(ProjectTemplateActions.loadProjectTemplates({ payload: { siteId } }));
        this.store.dispatch(ProjectRequestActions.loadProjectRequests({ payload: { siteId } }));
        this.store.dispatch(BudgetTableActions.loadBudgetTablesBySiteId({ payload: { siteId } }));
        this.store.dispatch(
            DocumentActions.loadDocumentChangeRequestFormBySiteId({ payload: { siteId } })
        );
        this.store.dispatch(KanbanBoardActions.loadKanbanBoardsBySiteId({ payload: { siteId } }));

        // Load RAID
        this.store.dispatch(RiskActions.loadRisksBySiteId({ payload: { siteId } }));
        this.store.dispatch(ActionActions.loadActionsBySiteId({ payload: { siteId } }));
        this.store.dispatch(IssueActions.loadIssuesBySiteId({ payload: { siteId } }));
        this.store.dispatch(DecisionActions.loadDecisionsBySiteId({ payload: { siteId } }));

        // Users data
        this.store.dispatch(
            NotificationActions.loadSiteLevelNotifications({ payload: { siteId } })
        );
        this.store.dispatch(UserLogActions.loadUserLogsBySiteId({ payload: { siteId } }));
        this.store.dispatch(ReminderActions.loadReminders({ payload: { siteId } }));

        // Load System templates
        this.store.dispatch(
            CalendarActions.loadCompletixCalendarTemplatesBySiteId({ payload: { siteId } })
        );
        this.store.dispatch(
            ListContainerActions.loadSystemListContainersBySiteId({ payload: { siteId } })
        );
        this.store.dispatch(
            CommunicationActions.loadCompletixCommunicationTemplatesBySiteId({
                payload: { siteId },
            })
        );
        this.store.dispatch(
            ProjectTemplateActions.loadCompletixProjectTemplatesBySiteId({ payload: { siteId } })
        );
        this.store.dispatch(
            BudgetTableActions.loadCompletixBudgetTableTemplatesBySiteId({ payload: { siteId } })
        );
        this.store.dispatch(
            DocumentActions.loadCompletixDocumentTemplatesBySiteId({ payload: { siteId } })
        );
        this.store.dispatch(
            DocumentActions.loadCompletixDocumentChangeRequestFormBySiteId({ payload: { siteId } })
        );
    }
}
