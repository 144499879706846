import { Action, createAction, props } from '@ngrx/store';
import { LoadByProjectIdPayload } from '../shared/models/load.payload';
import { IdPayload } from '../shared/models/shared-payloads';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import { ProjectMember } from './models/project-member.model';
import {
    InviteProjectMemberPayload,
    LoadProjectMembersBySiteIdPayload,
    PatchProjectMemberPayload,
    ProjectMemberIdPayload,
    ProjectMemberMultiIdsPayload,
    UpdateProjectMemberPayload,
} from './project-member.payloads';

// Load ProjectMembers actions

export const loadProjectMembersBySiteId = createAction(
    '[ProjectMember] Load ProjectMembers by site id',
    props<{ payload: LoadProjectMembersBySiteIdPayload }>()
);

export const loadProjectMembersBySiteIdSuccess = createAction(
    '[ProjectMember] Load ProjectMembers by site id success',
    props<{ projectMembers: ProjectMember[] }>()
);

export const loadProjectMembersBySiteIdFail = createAction(
    '[ProjectMember] Load ProjectMembers by site id fail',
    props<{ error: any }>()
);

export const loadProjectMembersByProjectId = createAction(
    '[ProjectMember] Load ProjectMembers by project id',
    props<{ payload: LoadByProjectIdPayload }>()
);

export const loadProjectMembersByProjectIdSuccess = createAction(
    '[ProjectMember] Load ProjectMembers by project id success',
    props<{ projectMembers: ProjectMember[]; projectId: string }>()
);

export const loadProjectMembersByProjectIdFail = createAction(
    '[ProjectMember] Load ProjectMembers by project id fail',
    props<{ error: any }>()
);

export const getProjectMemberSuccess = createAction(
    '[ProjectMember] Get ProjectMember success',
    props<{ member: ProjectMember }>()
);

// Invite ProjectMember actions

export const inviteProjectMember = createAction(
    '[ProjectMember] Invite',
    props<{ payload: InviteProjectMemberPayload }>()
);

export const inviteProjectMemberSuccess = createAction(
    '[ProjectMember] Invite success',
    props<{ member: ProjectMember }>()
);

export const inviteProjectMemberFail = createAction(
    '[ProjectMember] Invite fail',
    props<{ error: any }>()
);

// Update ProjectMember actions

export const updateProjectMember = createAction(
    '[ProjectMember] Update',
    props<{
        payload: UpdateProjectMemberPayload;
        options?: OptimisticableActionOptions;
        snackBarMessage?: string;
    }>()
);

// Re Open ProjectMember actions

export const reOpenProjectMember = createAction(
    '[ProjectMember] Re-Open',
    props<{ payload: IdPayload; options?: OptimisticableActionOptions }>()
);

export const reOpenProjectMemberFail = createAction(
    '[ProjectMember] Re-Open fail',
    props<{ error: any }>()
);

// Patch ProjectMember actions

export const patchProjectMember = createAction(
    '[ProjectMember] Patch',
    props<{
        payload: PatchProjectMemberPayload;
        options?: OptimisticableActionOptions;
        snackBarMessage?: string;
    }>()
);

export const patchProjectMemberSuccess = createAction(
    '[ProjectMember] Patch success',
    props<PatchProjectMemberPayload>()
);

export const patchProjectMemberFail = createAction(
    '[ProjectMember] Patch fail',
    props<{ error: any; originAction: Action }>()
);

export const patchProjectMemberFromSignalR = createAction(
    '[ProjectMember] Patch from SignalR',
    props<{ payload: PatchProjectMemberPayload }>()
);

// Delete ProjectMember actions

export const deleteProjectMember = createAction(
    '[ProjectMember] Delete',
    props<{ payload: ProjectMemberIdPayload }>()
);

export const deleteProjectMemberSuccess = createAction(
    '[ProjectMember] Delete success',
    props<{ id: string; deleted: boolean }>()
);

export const deleteProjectMemberFail = createAction(
    '[ProjectMember] Delete fail',
    props<{ error: any }>()
);

// Delete ProjectMember actions

export const setPrimaryProjectManager = createAction(
    '[ProjectMember] Set Primary manager',
    props<{ payload: ProjectMemberIdPayload; options?: OptimisticableActionOptions }>()
);

export const setPrimaryProjectManagerSuccess = createAction(
    '[ProjectMember] Set Primary manager success',
    props<ProjectMemberIdPayload>()
);

export const setPrimaryProjectManagerFail = createAction(
    '[ProjectMember] Set Primary manager fail',
    props<{ error: any; originAction: Action }>()
);

// Delete many ProjectMembers actions

export const deleteManyProjectMembers = createAction(
    '[Many ProjectMembers] Delete',
    props<{ payload: ProjectMemberMultiIdsPayload; options?: OptimisticableActionOptions }>()
);

export const resendInvite = createAction(
    '[ProjectMembers] Resend Invite',
    props<{ payload: IdPayload }>()
);
