import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppStore } from '..';
import { RestApiService } from '../../services/rest-api';
import { ActionActions } from '../actions';
import { BacklogTaskActions } from '../backlog-task';
import { CalendarSelectors } from '../calendar';
import { Minute, MinuteActions, MinuteSelectors } from '../minute';
import { ScheduleTaskActions } from '../schedule-template';
import { Model } from '../shared/models/base.model';
import { OptimisticDefaults } from '../shared/optimisticable-action';
import { UserTaskType } from '../shared/user-task.model';
import {
    deleteExecutiveDashboardMinuteTask,
    loadExecutiveDashboardBySiteId,
    loadExecutiveDashboardBySiteIdFail,
    loadExecutiveDashboardBySiteIdSuccess,
    updateExecutiveDashboardTaskProgress,
} from './executive-dashboard.actions';
import { ExecutiveDashboard } from './models';

@Injectable()
export class ExecutiveDashboardEffects {
    loadBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadExecutiveDashboardBySiteId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        loadExecutiveDashboardBySiteIdSuccess({
                            executiveDashboard: Model.createFromDto(
                                ExecutiveDashboard,
                                response.payload
                            ),
                        })
                    ),
                    catchError((error) => of(loadExecutiveDashboardBySiteIdFail({ error })))
                )
            )
        )
    );

    updateActionProgress$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateExecutiveDashboardTaskProgress),
            filter((action) => action.payload.type === UserTaskType.Action),
            mergeMap((action) =>
                of(
                    ActionActions.updateAction({
                        payload: {
                            id: action.payload.id,
                            changes: { progress: action.payload.progress },
                        },
                        options: OptimisticDefaults,
                    })
                )
            )
        )
    );

    updateBacklogTaskProgress$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateExecutiveDashboardTaskProgress),
            filter((action) =>
                [UserTaskType.SprintTask, UserTaskType.BacklogTask].includes(action.payload.type)
            ),
            mergeMap((action) =>
                of(
                    BacklogTaskActions.updateBacklogTask({
                        payload: {
                            id: action.payload.id,
                            changes: { progress: action.payload.progress },
                        },
                        options: OptimisticDefaults,
                    })
                )
            )
        )
    );

    updateScheduleTaskProgress$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateExecutiveDashboardTaskProgress),
            filter((action) => action.payload.type === UserTaskType.Schedule),
            withLatestFrom(this.store.select(CalendarSelectors.selectAll)),
            mergeMap(([action, calendars]) => {
                const calendar = calendars.find((c) => c.projectId === action.payload.projectId);
                return of(
                    ScheduleTaskActions.updateScheduleTaskWithCalculation({
                        payload: {
                            taskPatch: {
                                taskId: action.payload.id,
                                changes: { progress: action.payload.progress },
                            },
                            calendar,
                        },
                        options: OptimisticDefaults,
                    })
                );
            })
        )
    );

    deleteMinuteTask$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteExecutiveDashboardMinuteTask),
            withLatestFrom(this.store.select(MinuteSelectors.selectAll)),
            mergeMap(([action, minutes]) => {
                const minute: Minute = minutes.find((m) =>
                    m.topics?.some((t) => t.id === action.payload.id)
                );
                const topic = minute.topics?.find((t) => t.id === action.payload.id);
                const topicIdx = minute.topics?.findIndex((t) => t.id === action.payload.id);
                return of(
                    MinuteActions.updateMinute({
                        payload: {
                            id: minute.id,
                            changes: {
                                topics: [
                                    ...minute.topics?.slice(0, topicIdx),
                                    { ...topic, deletedFromUserDashboard: true },
                                    ...minute.topics?.slice(topicIdx + 1),
                                ],
                            },
                        },
                        options: OptimisticDefaults,
                    })
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store: Store<AppStore.AppState>
    ) {}
}
