import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SiteSelectors } from '../site';
import { StateEntry } from '../state-entry';
import { NotificationLevel } from './models/notification.model';
import { adapter, State } from './notification.store';

export const selectNotificationEntry = createFeatureSelector<State>(StateEntry.Notification);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectNotificationEntry);

export const selectCurrentNotifications = createSelector(
    selectAll,
    SiteSelectors.selectCurrentSiteId,
    (notifications, siteId) =>
        notifications
            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
            .filter(
                (n) =>
                    n.level === NotificationLevel.Account ||
                    (n.level === NotificationLevel.Site && n.siteId === siteId)
            )
);

export const selectUnreadNotifications = createSelector(
    selectCurrentNotifications,
    (notifications) => notifications.filter((n) => !n.read)
);
