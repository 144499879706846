import { Action, createAction, props } from '@ngrx/store';
import { SubscriptionPlan, SubscriptionPlanCreateOptions } from 'app/core/models/billing';
import { IdPayload } from 'app/core/store/shared/models/shared-payloads';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import {
    CreatePayload,
    EntitiesPayload,
    EntityPayload,
    ErrorPayload,
    PatchPayload,
} from '../shared/payloads';

// Load subscription plans

export const loadSubscriptionPlans = createAction('[SubscriptionPlan] Load');

export const loadSubscriptionPlansSuccess = createAction(
    '[SubscriptionPlan] Load success',
    props<EntitiesPayload<SubscriptionPlan>>()
);

export const loadSubscriptionPlansFail = createAction(
    '[SubscriptionPlan] Load fail',
    props<ErrorPayload>()
);

// Add subscription plan

export const addSubscriptionPlan = createAction(
    '[SubscriptionPlan] Add',
    props<{ payload: CreatePayload<SubscriptionPlanCreateOptions> }>()
);

export const addSubscriptionPlanSuccess = createAction(
    '[SubscriptionPlan] Add success',
    props<EntityPayload<SubscriptionPlan>>()
);

export const addSubscriptionPlanFail = createAction(
    '[SubscriptionPlan] Add fail',
    props<ErrorPayload>()
);

// Update subscription plan

export const updateSubscriptionPlan = createAction(
    '[SubscriptionPlan] Update',
    props<{ payload: PatchPayload<SubscriptionPlan>; options?: OptimisticableActionOptions }>()
);

export const updateSubscriptionPlanSuccess = createAction(
    '[SubscriptionPlan] Update success',
    props<EntityPayload<SubscriptionPlan>>()
);

export const updateSubscriptionPlanFail = createAction(
    '[SubscriptionPlan] Update fail',
    props<ErrorPayload>()
);

// Delete subscription plan

export const deleteSubscriptionPlan = createAction(
    '[SubscriptionPlan] Delete',
    props<{ payload: IdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteSubscriptionPlanSuccess = createAction(
    '[SubscriptionPlan] Delete success',
    props<IdPayload>()
);

export const deleteSubscriptionPlanFail = createAction(
    '[SubscriptionPlan] Delete fail',
    props<{ error: ErrorPayload; originAction: Action }>()
);
