import { createAction, props } from '@ngrx/store';
import {
    HandsontableSettingsCreateOptions,
    HandsontableSettingsIdPayload,
    UpdateHandsontableSettingsPayload,
} from './handsontable-settings.payloads';
import { HandsontableSettings } from './models/handsontable-settings.model';

// Load HandsontableSettings actions

export const loadHandsontableSettings = createAction(
    '[HandsontableSettings] Load HandsontableSettings '
);

export const loadHandsontableSettingsSuccess = createAction(
    '[HandsontableSettings] Load HandsontableSettings success',
    props<{ settings: HandsontableSettings[] }>()
);

export const loadHandsontableSettingsFail = createAction(
    '[HandsontableSettings] Load HandsontableSettings fail',
    props<{ error: any }>()
);

// Add HandsontableSettings actions

export const addHandsontableSettings = createAction(
    '[HandsontableSettings] Add',
    props<HandsontableSettingsCreateOptions>()
);

export const addHandsontableSettingsSuccess = createAction(
    '[HandsontableSettings] Add success',
    props<HandsontableSettings>()
);

export const addHandsontableSettingsFail = createAction(
    '[HandsontableSettings] Add fail',
    props<{ error: any }>()
);

// Update HandsontableSettings actions

export const updateHandsontableSettings = createAction(
    '[HandsontableSettings] Update',
    props<UpdateHandsontableSettingsPayload>()
);

export const updateHandsontableSettingsSuccess = createAction(
    '[HandsontableSettings] Update success',
    props<UpdateHandsontableSettingsPayload>()
);

export const updateHandsontableSettingsFail = createAction(
    '[HandsontableSettings] Update fail',
    props<{ error: any }>()
);

// Delete HandsontableSettings actions

export const deleteHandsontableSettings = createAction(
    '[HandsontableSettings] Delete',
    props<HandsontableSettingsIdPayload>()
);

export const deleteHandsontableSettingsSuccess = createAction(
    '[HandsontableSettings] Delete success',
    props<HandsontableSettingsIdPayload>()
);

export const deleteHandsontableSettingsFail = createAction(
    '[HandsontableSettings] Delete fail',
    props<{ error: any }>()
);
