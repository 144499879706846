import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { CriticalErrorPageComponent } from './critical-error/critical-error-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

@NgModule({
    imports: [CommonModule, FlexLayoutModule, MatButtonModule],
    exports: [],
    declarations: [PageNotFoundComponent, CriticalErrorPageComponent],
})
export class GlobalPagesModule {}
