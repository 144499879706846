import { JsonProperty } from 'app/utils/json-mapper';
import { Invoice } from './invoice.model';

export class InvoiceGroup {
    @JsonProperty('customerId')
    customerId: string = undefined;

    @JsonProperty('start')
    start: Date = undefined;

    @JsonProperty('endedAt')
    endedAt: Date = undefined;

    @JsonProperty({ name: 'invoices', clazz: Invoice })
    invoices: Invoice[] = undefined;

    constructor(init?: Partial<InvoiceGroup>) {
        Object.assign(this, init);
    }

    get startDateString(): string {
        return (this.start && this.start.toLocaleDateString()) || '';
    }

    get endedAtDateString(): string {
        return (this.endedAt && this.endedAt.toLocaleDateString()) || '';
    }
}
