import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StateEntry } from 'app/core/store/state-entry';
import { deepClone } from 'fast-json-patch';
import { mergeDeepRight } from 'ramda';
import { SitePermissions } from '../shared/models/shared-permissions.model';
import { defaultSitePermissions } from './../shared/models/default-permissions';
import { adapter, State } from './subscription.store';

export const selectSubscriptionEntry = createFeatureSelector<State>(StateEntry.Subscription);

// Simplified object destructuring is consciously not used due to incorrect work of
// find usage feature (Shift+F12) - remove right hand side destructuring component
// (after semicolon) and try to find usages - it results to many unwanted entries found
// TODO: track related issue https://github.com/Microsoft/TypeScript/issues/15787
export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectSubscriptionEntry);

export const selectSubscriptionError = createSelector(
    selectSubscriptionEntry,
    (state) => state.subscriptionError
);

export const selectSubscriptionPermissionsMap = createSelector(selectAll, (subscriptions) => {
    const map: Record<string, SitePermissions> = {};
    subscriptions.forEach(
        (sub) =>
            (map[sub.siteId] = mergeDeepRight(
                deepClone(defaultSitePermissions),
                deepClone(sub.permissions)
            ))
    );

    return map;
});

export const selectSubscriptionSucceeded = createSelector(
    selectSubscriptionEntry,
    (state) => state.isSubscriptionSucceeded
);
