import { ApprovalState } from 'app/core/store/approval-request';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import {
    AddDecisionPayload,
    ManageDecisionApprovalPayload,
    PatchDecisionPayload,
} from '../../../../store/decision/decision.payloads';
import {
    LoadByProjectIdPayload,
    LoadBySiteIdPayload,
} from '../../../../store/shared/models/load.payload';
import { IdPayload } from '../../../../store/shared/models/shared-payloads';
import { SubmitDecisionPayload } from './../../../../store/decision/decision.payloads';

export const loadDecisionsByProjectIdCommand = (
    payload: LoadByProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projects', value: payload.projectId },
        { name: 'decisions' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadDecisionsBySiteIdCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'decisions' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const getAssignedDecisionByIdCommand = (payload: IdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projectDecisions', value: payload.id }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addDecisionCommand = (payload: AddDecisionPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projectDecisions' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchDecisionCommand = (
    payload: PatchDecisionPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectDecisions', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteDecisionCommand = (payload: IdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projectDecisions', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};

export const submitDecisionCommand = (
    payload: SubmitDecisionPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projectDecisions', value: payload.decisionId },
        {
            name: 'submission',
            value: payload.submitted ? 'submit' : !payload.submitted ? 'unsubmit' : '',
        },
    ]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const manageDecisionApprovalCommand = (
    payload: ManageDecisionApprovalPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        {
            name: 'projectDecisions/approval',
            value: payload.decisionApproverId,
        },
        {
            name: 'state',
            value:
                payload.approvalState === ApprovalState.Approved
                    ? 'approve'
                    : payload.approvalState === ApprovalState.Rejected
                    ? 'reject'
                    : '',
        },
    ]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};
