import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StateEntry } from '../state-entry';
import { AsyncUpdateStatus } from './../shared/async-update-status.store';
import { adapter, State } from './subscription-plan.store';

export const selectSubscriptionPlanEntry = createFeatureSelector<State>(
    StateEntry.SubscriptionPlan
);

// Simplified object destructuring is consciously not used due to incorrect work of
// find usage feature (Shift+F12) - remove right hand side destructuring component
// (after semicolon) and try to find usages - it results to many unwanted entries found
// TODO: track related issue https://github.com/Microsoft/TypeScript/issues/15787
export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectSubscriptionPlanEntry);

export const selectLoadingStatus = createSelector(
    selectSubscriptionPlanEntry,
    (state: AsyncUpdateStatus) => state
);
