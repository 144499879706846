import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProgramRole } from 'app/core/store/program-role';
import { SnackBarService } from 'app/services/snack-bar/snack-bar.service';
import { getPatch } from 'app/utils/json-patch';
import { of } from 'rxjs';
import {
    catchError,
    concatMap,
    map,
    mergeMap,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { SiteRoleSelectors } from '.';
import { AppStore } from '..';
import { RestApiService } from '../../services/rest-api';
import { ProgramRoleActions } from '../program-role';
import { ProjectRoleActions } from '../project-role';
import { Model } from '../shared/models/base.model';
import { CommandResult } from './../../../utils/network/commands/base.command';
import { LoadSiteRolesResponse } from './../../services/rest-api/features/site-role/site-role.responses';
import { ProjectRole } from './../project-role/models/project-role.model';
import { okEmptyAction } from './../project/project.actions';
import { SiteRole } from './models/site-role.model';
import * as SiteRoleActions from './site-role.actions';

@Injectable()
export class SiteRoleEffects {
    loadSiteRoleTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteRoleActions.loadSiteRoleTemplates),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        response.payload.map((dto) => Model.createFromDto(SiteRole, dto))
                    ),
                    map((roles) => SiteRoleActions.loadSiteRoleTemplatesSuccess({ roles })),
                    catchError((error) => of(SiteRoleActions.loadSiteRoleTemplatesFail({ error })))
                )
            )
        )
    );

    loadRolesBySite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteRoleActions.loadRolesBySite),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    switchMap((response: LoadSiteRolesResponse) => [
                        SiteRoleActions.loadRolesBySiteSuccess({
                            roles: response.payload.siteRoles.map((r) =>
                                Model.createFromDto(SiteRole, r)
                            ),
                            siteId: action.payload.siteId,
                        }),
                        ProjectRoleActions.loadProjectRolesBySiteSuccess({
                            roles: response.payload.projectRoles.map((r) =>
                                Model.createFromDto(ProjectRole, r)
                            ),
                        }),
                        ProgramRoleActions.loadProgramRolesBySiteSuccess({
                            roles: response.payload.programRoles.map((r) =>
                                Model.createFromDto(ProgramRole, r)
                            ),
                        }),
                    ]),
                    catchError((error) => of(SiteRoleActions.loadRolesBySiteFail({ error })))
                )
            )
        )
    );

    addSiteRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteRoleActions.addSiteRole),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Creating a new role...');
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBarService.hide()),
                    map((response) =>
                        SiteRoleActions.addSiteRoleSuccess({
                            role: Model.createFromDto(SiteRole, response.payload),
                        })
                    ),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(SiteRoleActions.addSiteRoleFail({ error }));
                    })
                );
            })
        )
    );

    updateSiteRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteRoleActions.updateSiteRole),
            withLatestFrom(this.store$.select(SiteRoleSelectors.selectAll)),
            mergeMap(([action, roles]) => {
                const roleToPatch = roles.find((role) => role.id === action.payload.id);
                const patch = getPatch(roleToPatch, action.payload.changes);
                return of(
                    SiteRoleActions.patchSiteRole({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchSiteRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteRoleActions.patchSiteRole),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return SiteRoleActions.patchSiteRoleSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) =>
                        of(SiteRoleActions.patchSiteRoleFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    setSiteRoleTag$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteRoleActions.setSiteRoleTag),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return SiteRoleActions.setSiteRoleTagSuccess({
                            siteRoles: response.payload.map((dto) =>
                                Model.createFromDto(SiteRole, dto)
                            ),
                        });
                    }),
                    catchError((error) =>
                        of(SiteRoleActions.setSiteRoleTagFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    deleteSiteRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteRoleActions.deleteSiteRole),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return SiteRoleActions.deleteSiteRoleSuccess({ id: action.payload.id });
                    }),
                    catchError((error) =>
                        of(SiteRoleActions.deleteSiteRoleFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>,
        private snackBarService: SnackBarService
    ) {}
}
