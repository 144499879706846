import { Action, createAction, props } from '@ngrx/store';
import { LoadBySiteIdPayload } from '../shared/models/load.payload';
import { IdPayload } from '../shared/models/shared-payloads';
import { OptimisticableActionOptions } from './../shared/optimisticable-action';
import { ProjectPhase } from './models';
import {
    AddProjectPhaseDeliverablePayload,
    AddProjectPhasePayload,
    ChangeGatingTemplatePayload,
    DeleteProjectPhaseDeliverablePayload,
    LoadPhasesByContainerIdPayload,
    ManageProjectPhaseApprovalPayload,
    PatchProjectPhaseDeliverablePayload,
    PatchProjectPhasePayload,
    ProjectPhaseIdPayload,
    UpdateProjectPhaseDeliverablePayload,
    UpdateProjectPhasePayload,
} from './project-phase.payloads';

// Load project phases

export const loadProjectPhasesByContainerId = createAction(
    '[Project phase] Load phases by Container Id',
    props<{ payload: LoadPhasesByContainerIdPayload }>()
);

export const loadProjectPhasesByContainerIdSuccess = createAction(
    '[Project phase] Load phases by Container Id success',
    props<{ phases: ProjectPhase[]; containerId: string }>()
);

export const loadProjectPhaseByContainerIdFail = createAction(
    '[Project phase] Load phases by Container Id fail',
    props<{ error: any; containerId: string }>()
);

export const loadProjectPhasesBySiteId = createAction(
    '[Project phase] Load phases by Site Id',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadProjectPhasesBySiteIdSuccess = createAction(
    '[Project phase] Load phases by Site Id success',
    props<{ phases: ProjectPhase[]; siteId: string; listContainerIds: string[] }>()
);

export const loadProjectPhasesBySiteIdFail = createAction(
    '[Project phase] Load phases by Site Id fail',
    props<{ error: any; siteId: string }>()
);

// Get phase by id

export const getProjectPhaseById = createAction(
    '[Project phase] Get project phase by id',
    props<{ payload: IdPayload }>()
);

export const getProjectPhaseByIdSuccess = createAction(
    '[Project phase] Get project phase by id success',
    props<{ projectPhase: ProjectPhase }>()
);

export const getProjectPhaseByIdFail = createAction(
    '[Project phase] Get project phase by id fail',
    props<{ error: any }>()
);

// Add project phase

export const addProjectPhase = createAction(
    '[Project phase] Add phase',
    props<{ payload: AddProjectPhasePayload }>()
);

export const addProjectPhaseSuccess = createAction(
    '[Project phase] Add phase success',
    props<{ phase: ProjectPhase }>()
);

export const addProjectPhaseFail = createAction(
    '[Project phase] Add phase fail',
    props<{ error: any }>()
);

// Update project phase

export const updateProjectPhase = createAction(
    '[Project phase] Update phase',
    props<{ payload: UpdateProjectPhasePayload; options?: OptimisticableActionOptions }>()
);

export const patchProjectPhase = createAction(
    '[Project phase] Patch phase',
    props<{ payload: PatchProjectPhasePayload; options?: OptimisticableActionOptions }>()
);

export const patchProjectPhaseSuccess = createAction(
    '[Project phase] Patch phase success',
    props<PatchProjectPhasePayload>()
);

export const patchProjectPhaseFail = createAction(
    '[Project phase] Patch phase fail',
    props<{ error: any; originAction: Action }>()
);

// Advance phase actions

export const advanceProjectPhase = createAction(
    '[Project phase] Advance phase',
    props<{ payload: ProjectPhaseIdPayload }>()
);

export const advanceProjectPhaseFail = createAction(
    '[Project phase] Advance phase fail',
    props<{ error: any }>()
);

// Submit phase actions

export const submitProjectPhase = createAction(
    '[Project phase] Submit phase',
    props<{ payload: ProjectPhaseIdPayload }>()
);

export const submitProjectPhaseFail = createAction(
    '[Project phase] Submit phase fail',
    props<{ error: any }>()
);

// UnSubmit phase actions

export const unsubmitProjectPhase = createAction(
    '[Project phase] UnSubmit phase',
    props<{ payload: ProjectPhaseIdPayload }>()
);

export const unsubmitProjectPhaseFail = createAction(
    '[Project phase] UnSubmit phase fail',
    props<{ error: any }>()
);

// Manage Project Phase Approval
export const manageProjectPhaseApproval = createAction(
    '[Timesheet] Manage Project Phase Approval',
    props<{ payload: ManageProjectPhaseApprovalPayload }>()
);

export const manageProjectPhaseApprovalFail = createAction(
    '[Timesheet] Manage Project Phase Approval fail',
    props<{ error: any }>()
);

// Delete project phase

export const deleteProjectPhase = createAction(
    '[Project phase] Delete phase',
    props<{ payload: ProjectPhaseIdPayload }>()
);

export const deleteProjectPhaseSuccess = createAction(
    '[Project phase] Delete phase success',
    props<ProjectPhaseIdPayload>()
);

export const deleteProjectPhaseFail = createAction(
    '[Project phase] Delete phase fail',
    props<{ error: any }>()
);

// Change gating template

export const changeGatingTemplate = createAction(
    '[Project phase] Change gating template',
    props<{ payload: ChangeGatingTemplatePayload }>()
);

export const changeGatingTemplateSuccess = createAction(
    '[Project phase] Change gating template success',
    props<{ phases: ProjectPhase[]; containerId: string }>()
);

export const changeGatingTemplateFail = createAction(
    '[Project phase] Change gating template fail',
    props<{ error: any }>()
);

// Add project phase deliverable

export const addProjectPhaseDeliverable = createAction(
    '[Project phase] Add phase deliverable',
    props<{ payload: AddProjectPhaseDeliverablePayload; options?: OptimisticableActionOptions }>()
);

export const addProjectPhaseDeliverableSuccess = createAction(
    '[Project phase] Add phase success deliverable',
    props<AddProjectPhaseDeliverablePayload>()
);

export const addProjectPhaseDeliverableFail = createAction(
    '[Project phase] Add phase fail deliverable',
    props<{ error: any; originAction: Action }>()
);

// Patch project phase deliverable

export const updateProjectPhaseDeliverable = createAction(
    '[Project phase] Update phase deliverable',
    props<{
        payload: UpdateProjectPhaseDeliverablePayload;
        options?: OptimisticableActionOptions;
    }>()
);

export const patchProjectPhaseDeliverable = createAction(
    '[Project phase] Patch phase deliverable',
    props<{
        payload: PatchProjectPhaseDeliverablePayload;
        options?: OptimisticableActionOptions;
    }>()
);

export const patchProjectPhaseDeliverableSuccess = createAction(
    '[Project phase] Patch phase success deliverable',
    props<PatchProjectPhaseDeliverablePayload>()
);

export const patchProjectPhaseDeliverableFail = createAction(
    '[Project phase] Patch phase fail deliverable',
    props<{ error: any; originAction: Action }>()
);

// Delete project phase deliverable

export const deleteProjectPhaseDeliverable = createAction(
    '[Project phase] Delete phase deliverable',
    props<{
        payload: DeleteProjectPhaseDeliverablePayload;
        options?: OptimisticableActionOptions;
    }>()
);

export const deleteProjectPhaseDeliverableSuccess = createAction(
    '[Project phase] Delete phase success deliverable',
    props<DeleteProjectPhaseDeliverablePayload>()
);

export const deleteProjectPhaseDeliverableFail = createAction(
    '[Project phase] Delete phase fail deliverable',
    props<{ error: any; originAction: Action }>()
);
