import { JsonProperty } from 'app/utils/json-mapper';
import { Model } from '../../shared/models/base.model';
import { LowMediumHighStatus, RaidRecord } from '../../shared/models/raid-shared.model';
import { CtxColor, CtxMember } from './../../../models/shared-models';

export class RiskRecord extends Model implements RaidRecord {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('projectId')
    projectId: string = undefined;

    @JsonProperty('textId')
    textId?: string;

    @JsonProperty('dateRaised')
    dateRaised?: Date = undefined;

    @JsonProperty('creationDate')
    creationDate: string = undefined;

    @JsonProperty('closingDate')
    closingDate: Date;

    @JsonProperty('description')
    description?: string;

    @JsonProperty('probability')
    probability: LowMediumHighStatus = undefined;

    @JsonProperty('impact')
    impact: LowMediumHighStatus = undefined;

    @JsonProperty('score')
    score: LowMediumHighStatus = undefined;

    @JsonProperty('statusId')
    statusId: string = undefined;

    @JsonProperty('attachedDocumentIds')
    attachedDocumentIds?: string[];

    @JsonProperty('assignedMember')
    assignedMember?: CtxMember;

    @JsonProperty('mitigationPlan')
    mitigationPlan?: string;

    @JsonProperty('isTopFive')
    isTopFive?: boolean;

    @JsonProperty('color')
    color?: CtxColor;

    @JsonProperty('originObjectId')
    originObjectId?: string;

    constructor(init?: Partial<RiskRecord>) {
        super();
        Object.assign(this, init);
    }
}
