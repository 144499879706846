import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import { getRecordNewTextId } from '../shared/models/raid-shared.model';
import * as ActionActions from './action.actions';
import { adapter, initialState } from './action.store';
import { ActionRecord } from './models';

export const reducer = createReducer(
    initialState,

    on(ActionActions.getActionSuccess, (state, payload) =>
        adapter.addOne(payload.action, adapter.removeOne(payload.action.id, state))
    ),

    on(ActionActions.loadActionsBySiteId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(ActionActions.loadActionsBySiteIdSuccess, (state, payload) => {
        const newState = adapter.addMany(payload.actions, adapter.removeAll(state));
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(ActionActions.loadActionsBySiteIdFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(ActionActions.loadActionsByProjectId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(ActionActions.loadActionsByProjectIdSuccess, (state, payload) => {
        const newState = adapter.addMany(
            payload.actions,
            adapter.removeMany(
                payload.actions.map((r) => r.id),
                state
            )
        );
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(ActionActions.loadActionsByProjectIdFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(ActionActions.addAction, (state, action) => {
        if (action.options?.optimistic) {
            const actions = Object.values(state.entities).filter(
                (a) => action.payload.projectId === a.projectId
            );
            return {
                ...adapter.addOneOptimistic(
                    new ActionRecord({
                        ...action.payload,
                        creationDate: new Date().toString(),
                        textId: getRecordNewTextId(
                            actions.map((a) => a.textId),
                            'A'
                        ),
                    }),
                    state
                ),
            };
        }
        return state;
    }),

    on(ActionActions.addActionSuccess, (state, payload) => {
        if (payload.options?.optimistic) {
            return adapter.addOneOptimisticSuccess(payload.action, state);
        }
        return adapter.addOne(payload.action, state);
    }),

    on(ActionActions.addActionFail, (state, { actionId }) => ({
        ...adapter.addOneOptimisticFail(actionId, state),
    })),

    on(ActionActions.patchAction, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patched = getPatched(state.entities[action.payload.id], action.payload.patch);
        const update: Update<ActionRecord> = { id: patched.id, changes: patched };
        return adapter.updateOne(update, state);
    }),

    on(ActionActions.patchActionSuccess, (state, payload) => {
        const patched = getPatched(state.entities[payload.id], payload.patch);
        const update: Update<ActionRecord> = { id: patched.id, changes: patched };
        return adapter.updateOne(update, state);
    }),

    on(ActionActions.patchActionFail, (state) => state),

    on(ActionActions.deleteAction, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(ActionActions.deleteActionSuccess, (state, payload) => adapter.removeOne(payload.id, state)),

    on(ActionActions.deleteActionFail, (state) => state)
);
