import { JsonProperty } from 'app/utils/json-mapper';
import { Model } from '../../shared/models/base.model';

export class Sprint extends Model {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('projectId')
    projectId: string;

    @JsonProperty('name')
    name: string;

    @JsonProperty('startDate')
    startDate: Date;

    @JsonProperty('endDate')
    endDate: Date;

    @JsonProperty('isStarted')
    isStarted: boolean;

    @JsonProperty('isCompleted')
    isCompleted: boolean;

    @JsonProperty('completedDate')
    completedDate?: Date;

    constructor(init?: Partial<Sprint>) {
        super();
        Object.assign(this, init);
    }
}

export interface SprintTasksCountLog {
    date: Date;
    uncompletedTasksCount: number;
    totalTasksCount: number;
}
