import { CtxColor } from 'app/core/models/shared-models';
import { SiteEntityState, siteEntityStateNamesMap } from 'app/core/models/site-entity.model';
import { ProjectPermissions } from 'app/core/store/shared/models/shared-permissions.model';
import { commonText } from './common-text';
import { ContextMenuItem } from './dumb-components/context-menu/context-menu.directive';

export enum SharedMenuItemType {
    Add = 'add item',
    Open = 'open item',
    Remove = 'remove item',
    Rename = 'rename item',
    ExpandAll = 'expand item',
    CollapseAll = 'collapse item',
    Sort = 'sort items',
    OpenInNewTab = 'OpenInNewTab',
    CopyLink = 'copy link',
    Copy = 'copy',
    Color = 'color item',
    Archive = 'archive item',
    SetAsDefault = 'set as default',
    Divider = 'divider',
    AddReminder = 'add reminder',
    RemoveReminder = 'remove reminder',
    SetSiteEntityState = 'set site entity state',
    Share = 'share',
    Filter = 'filter',
}

export const sharedMenuItems = {
    open: {
        name: commonText.buttons.open,
        type: SharedMenuItemType.Open,
        iconName: 'redo',
    },
    add: {
        name: commonText.buttons.add,
        type: SharedMenuItemType.Add,
        iconName: 'add',
    },
    remove: {
        name: commonText.buttons.remove,
        type: SharedMenuItemType.Remove,
        iconName: 'close',
    },
    rename: {
        name: commonText.buttons.rename,
        type: SharedMenuItemType.Rename,
        iconName: 'edit',
    },
    expandAll: {
        name: 'Expand all',
        type: SharedMenuItemType.ExpandAll,
        iconName: 'expand_more',
    },
    collapseAll: {
        name: 'Collapse all',
        type: SharedMenuItemType.CollapseAll,
        iconName: 'expand_less',
    },
    sort: {
        name: 'Sort',
        type: SharedMenuItemType.Sort,
        faIconName: 'fa fa-sort',
    },
    openInNewTab: {
        name: 'Open in new tab',
        type: SharedMenuItemType.OpenInNewTab,
        iconName: 'launch',
    },
    copyLink: {
        name: 'Copy link',
        type: SharedMenuItemType.CopyLink,
        iconName: 'link',
    },
    copy: {
        name: 'Copy',
        type: SharedMenuItemType.Copy,
        iconName: 'content_copy',
    },
    divider: {
        type: SharedMenuItemType.Divider,
    },
    color: {
        name: 'Color',
        type: SharedMenuItemType.Color,
        iconName: 'color_lens',
        childMenuItems: getColorMenuItems(Object.values(CtxColor)),
    },
    archive: {
        name: 'Archive',
        type: SharedMenuItemType.Archive,
        iconName: 'archive',
    },
    setAsDefault: {
        name: 'Set as default',
        type: SharedMenuItemType.SetAsDefault,
        iconName: 'toggle_on',
    },
    addReminder: {
        name: 'Add reminder',
        type: SharedMenuItemType.AddReminder,
        iconName: 'notifications',
    },
    removeReminder: {
        name: commonText.buttons.remove + ' reminder',
        type: SharedMenuItemType.RemoveReminder,
        iconName: 'notifications_off',
    },
    siteEntityState: {
        name: 'Set state',
        type: SharedMenuItemType.SetSiteEntityState,
        iconName: 'bar_chart',
        childMenuItems: Object.values(SiteEntityState).map((state) => ({
            name: siteEntityStateNamesMap[state].toUpperCase(),
            type: state,
        })),
    },
    share: {
        name: 'Share',
        type: SharedMenuItemType.Share,
        iconName: 'share',
    },
    filter: {
        name: 'Filter',
        type: SharedMenuItemType.Filter,
        iconName: 'search',
    },
};

export function getColorMenuItems(colors: CtxColor[]): ContextMenuItem[] {
    return colors.map((color) => ({
        name: color.toUpperCase(),
        type: color,
        faIconName: color !== CtxColor.Default ? 'fa fa-circle' : null,
        iconClass: color,
    }));
}

export const sharedProjectMenuItems = [
    sharedMenuItems.siteEntityState,
    sharedMenuItems.color,
    sharedMenuItems.rename,
    sharedMenuItems.archive,
    sharedMenuItems.remove,
];

export const sharedProjectMenuItemsAccessMap = (permissions: ProjectPermissions) => {
    const filterMap: Record<string, boolean> = {
        [SharedMenuItemType.SetSiteEntityState]: permissions?.update && permissions?.updateState,
        [SharedMenuItemType.Color]: permissions?.update,
        [SharedMenuItemType.Rename]: permissions?.update,
        [SharedMenuItemType.Archive]: permissions?.archive,
        [SharedMenuItemType.Remove]: permissions?.delete,
    };
    return filterMap;
};
