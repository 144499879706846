import { JsonProperty } from 'app/utils/json-mapper';
import { Role } from '../../shared/models/role.base.model';
import { ProjectPermissions } from '../../shared/models/shared-permissions.model';
import { ProjectRoleTag } from './../../shared/models/shared-permissions.model';

export class ProjectRole extends Role {
    @JsonProperty('tag')
    tag?: ProjectRoleTag;

    @JsonProperty('permissions')
    permissions: ProjectPermissions;

    constructor(init?: Partial<ProjectRole>) {
        super();
        Object.assign(this, init);
    }
}
