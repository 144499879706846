import { createFeatureSelector, createSelector } from '@ngrx/store';
import { KanbanBoardSelectors, KanbanBoardShapeLink } from '../kanban';
import { ProjectSelectors } from '../project';
import { ProjectFieldSelectors, ProjectFieldType } from '../project-field';
import { ProjectRoleSelectors } from '../project-role';
import { selectCurrentProjectId } from '../project/project.selectors';
import { selectAccountSiteMembership } from '../shared/account-mebership.selectors';
import { getRaidStatusListByProjectMap } from '../shared/models/raid-shared.model';
import { getRaidRecordPermissionsMap } from '../shared/models/shared-permissions.model';
import { StateEntry } from '../state-entry';
import { RiskRecord } from './models';
import { adapter, LoadingStatus, State } from './risk.store';

export const selectRiskEntry = createFeatureSelector<State>(StateEntry.Risk);

export const selectLoadingStatus = createSelector(
    selectRiskEntry,
    (state): LoadingStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
    })
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
    selectEntityStoredInDb: selectEntityStoredInDb,
} = adapter.getCtxSelectors(selectRiskEntry);

export const selectCurrentSiteRisks = createSelector(
    selectAll,
    ProjectSelectors.selectCurrentSiteProjects,
    (risks, projects) => risks.filter((r) => projects.some((p) => p.id === r.projectId))
);

export const selectCurrentProjectRisks = createSelector(
    selectAll,
    ProjectSelectors.selectCurrentProjectId,
    (risks, projectId) => risks.filter((r) => r.projectId === projectId)
);

export const selectRiskPermissionsMap = createSelector(
    selectAll,
    ProjectRoleSelectors.selectAccountProjectRolesMap,
    selectAccountSiteMembership,
    (risks, projectRolesMap, accountSiteMembership) =>
        getRaidRecordPermissionsMap(
            risks,
            projectRolesMap,
            'risks',
            (risk: RiskRecord) => risk.assignedMember?.siteMemberId === accountSiteMembership.id
        )
);

export const selectCurrentSiteRisksStatusListMap = createSelector(
    ProjectSelectors.selectCurrentSiteProjects,
    KanbanBoardSelectors.selectAll,
    ProjectFieldSelectors.selectCurrentSiteProjectFieldByType(ProjectFieldType.RisksStatus),
    (projects, kanbanBoards, risksProjectField) =>
        getRaidStatusListByProjectMap(
            projects,
            kanbanBoards,
            KanbanBoardShapeLink.Risks,
            risksProjectField
        )
);

export const selectCurrentProjectRiskStatuses = createSelector(
    selectCurrentSiteRisksStatusListMap,
    selectCurrentProjectId,
    (map, currentProjectId) => map[currentProjectId]
);
