import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import { deepClone } from 'fast-json-patch';
import { ApprovalState } from '../approval-request';
import {
    AsyncUpdateStatusDefault,
    AsyncUpdateStatusDefaultLoad,
    AsyncUpdateStatusDefaultLoadSuccess,
} from '../shared/async-update-status.store';
import * as ChangeRequestActions from './change-request.actions';
import { adapter, initialState } from './change-request.store';
import { ChangeRequest, ChangeRequestApproval } from './models';

export const reducer = createReducer(
    initialState,
    on(ChangeRequestActions.loadChangeRequestsByProjectId, (state, action) => {
        const loadingStatusMap = state.loadingStatusByProjectId;
        const projectId = action.payload.projectId;

        return {
            ...state,
            loadingStatusByProjectId: {
                ...loadingStatusMap,
                [projectId]: {
                    ...(loadingStatusMap[projectId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoad,
                },
            },
        };
    }),

    on(ChangeRequestActions.loadChangeRequestsByProjectIdSuccess, (state, payload) => {
        const projectRequestIds = Object.values(state.entities)
            .filter((cr) => cr.projectId === payload.projectId)
            .map((cr) => cr.id);
        const newState = adapter.addMany(
            payload.changeRequests,
            adapter.removeMany(projectRequestIds, state)
        );
        const loadingStatusMap = state.loadingStatusByProjectId;
        const projectId = payload.projectId;

        return {
            ...newState,
            loadingStatusByProjectId: {
                ...loadingStatusMap,
                [projectId]: {
                    ...(loadingStatusMap[projectId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                },
            },
        };
    }),

    on(ChangeRequestActions.loadChangeRequestsByProjectIdFail, (state, payload) => {
        const loadingStatusMap = state.loadingStatusByProjectId;
        const projectId = payload.projectId;

        return {
            ...state,
            loadingStatusByProjectId: {
                ...loadingStatusMap,
                [projectId]: {
                    ...(loadingStatusMap[projectId] ?? {}),
                    ...AsyncUpdateStatusDefault,
                    error: payload.error,
                    loadingFailed: true,
                },
            },
        };
    }),

    on(ChangeRequestActions.loadChangeRequestsBySiteId, (state, action) => {
        const loadingStatusMap = state.loadingStatusByProjectId;
        const siteId = action.payload.siteId;

        return {
            ...state,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoad,
                },
            },
        };
    }),

    on(ChangeRequestActions.loadChangeRequestsBySiteIdSuccess, (state, payload) => {
        const projectIds = payload.projectIds;
        const projectRequestIds = Object.values(state.entities)
            .filter((cr) => projectIds.includes(cr.projectId))
            .map((cr) => cr.id);
        const newState = adapter.addMany(
            payload.changeRequests,
            adapter.removeMany(projectRequestIds, state)
        );
        const loadingStatusMap = state.loadingStatusBySiteId;
        const siteId = payload.siteId;
        const loadingByProjectIdMap = deepClone(state.loadingStatusByProjectId);
        projectIds.forEach(
            (id) =>
                (loadingByProjectIdMap[id] = {
                    ...(loadingByProjectIdMap[id] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                })
        );

        return {
            ...newState,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                },
            },
            loadingStatusByProjectId: loadingByProjectIdMap,
        };
    }),

    on(ChangeRequestActions.loadChangeRequestsBySiteIdFail, (state, payload) => {
        const loadingStatusMap = state.loadingStatusByProjectId;
        const siteId = payload.siteId;

        return {
            ...state,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefault,
                    error: payload.error,
                    loadingFailed: true,
                },
            },
        };
    }),

    on(ChangeRequestActions.loadChangeRequestApproversSuccess, (state, payload) => {
        return { ...state, approvers: payload.approvers };
    }),

    on(ChangeRequestActions.getChangeRequestByIdSuccess, (state, payload) => {
        return adapter.addOne(
            payload.changeRequest,
            adapter.removeOne(payload.changeRequest.id, state)
        );
    }),

    on(ChangeRequestActions.addChangeRequestSuccess, (state, payload) => ({
        ...adapter.addOne(payload.changeRequest, state),
        expandedCrIds: [...state.expandedCrIds, payload.changeRequest.id],
    })),

    on(ChangeRequestActions.addChangeRequestFail, (state) => ({ ...state })),

    on(ChangeRequestActions.patchChangeRequest, (state, action) => {
        if (!action.options?.optimistic) return;
        const request = state.entities[action.payload.id];
        const patchedRequest = getPatched(request, action.payload.patch);
        const update: Update<ChangeRequest> = {
            id: patchedRequest.id,
            changes: patchedRequest,
        };
        return adapter.updateOne(update, state);
    }),

    on(ChangeRequestActions.patchChangeRequestSuccess, (state, payload) => {
        const request = state.entities[payload.id];
        const patchedRequest = getPatched(request, payload.patch);
        const update: Update<ChangeRequest> = {
            id: patchedRequest.id,
            changes: patchedRequest,
        };
        return adapter.updateOne(update, state);
    }),

    on(ChangeRequestActions.patchChangeRequestFail, (state) => ({ ...state })),

    on(ChangeRequestActions.updateExpandedChangeRequestIds, (state, payload) => ({
        ...state,
        expandedCrIds: payload.expandedIds,
    })),

    on(ChangeRequestActions.deleteChangeRequest, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(ChangeRequestActions.deleteChangeRequestSuccess, (state, payload) =>
        adapter.removeOne(payload.id, state)
    ),

    on(ChangeRequestActions.deleteChangeRequestFail, (state) => ({ ...state })),

    on(ChangeRequestActions.deleteChangeRequestApproval, (state, action) => {
        if (!action.options?.optimistic) return state;
        const changeRequest = state.entities[action.payload.requestId];
        const update: Update<ChangeRequest> = {
            id: changeRequest.id,
            changes: {
                approvals: changeRequest.approvals.filter(
                    (a) => a.id !== action.payload.approvalId
                ),
            },
        };
        return adapter.updateOne(update, state);
    }),

    on(ChangeRequestActions.manageChangeRequestApprovalSubmission, (state, action) => {
        if (!action.options?.optimistic) return state;
        const changeRequest = state.entities[action.payload.requestId];
        const approval = changeRequest.approvals.find((a) => a.id === action.payload.approvalId);
        const approvalIdx = changeRequest.approvals.findIndex(
            (a) => a.id === action.payload.approvalId
        );
        const updatedApproval: ChangeRequestApproval = {
            ...approval,
            submission: { submitted: action.payload.submitted, date: new Date() },
            status: action.payload.submitted ? ApprovalState.Pending : null,
        };
        const update: Update<ChangeRequest> = {
            id: changeRequest.id,
            changes: {
                approvals: [
                    ...changeRequest.approvals.slice(0, approvalIdx),
                    updatedApproval,
                    ...changeRequest.approvals.slice(approvalIdx + 1),
                ],
            },
        };
        return adapter.updateOne(update, state);
    }),

    on(ChangeRequestActions.patchChangeRequestApproval, (state, action) => {
        if (!action.options?.optimistic) return state;
        const changeRequest = state.entities[action.payload.changeRequestId];
        const approval = changeRequest.approvals.find((a) => a.id === action.payload.approvalId);
        const approvalIdx = changeRequest.approvals.findIndex(
            (a) => a.id === action.payload.approvalId
        );
        const updatedApproval = getPatched(approval, action.payload.patch);
        const update: Update<ChangeRequest> = {
            id: changeRequest.id,
            changes: {
                approvals: [
                    ...changeRequest.approvals.slice(0, approvalIdx),
                    updatedApproval,
                    ...changeRequest.approvals.slice(approvalIdx + 1),
                ],
            },
        };
        return adapter.updateOne(update, state);
    })
);
