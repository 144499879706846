import { Action, createAction, props } from '@ngrx/store';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import { ProgramMember } from './models';
import {
    InviteProgramMemberPayload,
    LoadProgramMembersByProgramIdPayload,
    LoadProgramMembersBySiteIdPayload,
    PatchProgramMemberPayload,
    ProgramMemberIdPayload,
    ProgramMemberMultiIdsPayload,
    UpdateProgramMemberPayload
} from './program-member.payloads';

// Load ProgramMembers actions

export const loadProgramMembersByProgramId = createAction(
    '[ProgramMember] Load ProgramMembers by program id',
    props<{ payload: LoadProgramMembersByProgramIdPayload }>()
);

export const loadProgramMembersBySiteId = createAction(
    '[ProgramMember] Load ProgramMembers by site id',
    props<{ payload: LoadProgramMembersBySiteIdPayload }>()
);

export const loadProgramMembersSuccess = createAction(
    '[ProgramMember] Load ProgramMembers success',
    props<{ programMembers: ProgramMember[] }>()
);

export const loadProgramMembersFail = createAction(
    '[ProgramMember] Load ProgramMembers fail',
    props<{ error: any }>()
);

// Invite ProgramMember actions

export const inviteProgramMember = createAction(
    '[ProgramMember] Invite',
    props<{ payload: InviteProgramMemberPayload }>()
);

export const inviteProgramMemberSuccess = createAction(
    '[ProgramMember] Invite success',
    props<{ member: ProgramMember }>()
);

export const inviteProgramMemberFail = createAction(
    '[ProgramMember] Invite fail',
    props<{ error: any }>()
);

// Update ProgramMember actions

export const updateProgramMember = createAction(
    '[ProgramMember] Update',
    props<{ payload: UpdateProgramMemberPayload; options?: OptimisticableActionOptions }>()
);

// Patch ProgramMember actions

export const patchProgramMember = createAction(
    '[ProgramMember] Patch',
    props<{ payload: PatchProgramMemberPayload; options?: OptimisticableActionOptions }>()
);

export const patchProgramMemberSuccess = createAction(
    '[ProgramMember] Patch success',
    props<{ member: ProgramMember }>()
);

export const patchProgramMemberFail = createAction(
    '[ProgramMember] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Delete ProgramMember actions

export const deleteProgramMember = createAction(
    '[ProgramMember] Delete',
    props<{ payload: ProgramMemberIdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteProgramMemberSuccess = createAction(
    '[ProgramMember] Delete success',
    props<ProgramMemberIdPayload>()
);

export const deleteProgramMemberFail = createAction(
    '[ProgramMember] Delete fail',
    props<{ error: any; originAction: Action }>()
);

// Delete many ProgramMembers actions

export const deleteManyProgramMembers = createAction(
    '[Many ProgramMembers] Delete',
    props<ProgramMemberMultiIdsPayload>()
);
