import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import { getRecordNewTextId } from '../shared/models/raid-shared.model';
import * as DecisionActions from './decision.actions';
import { adapter, initialState } from './decision.store';
import { DecisionRecord } from './models';

export const reducer = createReducer(
    initialState,

    on(DecisionActions.loadDecisionsBySiteId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(DecisionActions.loadDecisionsBySiteIdSuccess, (state, payload) => {
        const newState = adapter.addMany(payload.decisions, adapter.removeAll(state));
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(DecisionActions.loadDecisionsBySiteIdFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(DecisionActions.loadDecisionsByProjectId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(DecisionActions.loadDecisionsByProjectIdSuccess, (state, payload) => {
        const newState = adapter.addMany(
            payload.decisions,
            adapter.removeMany(
                payload.decisions.map((d) => d.id),
                state
            )
        );
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(DecisionActions.loadDecisionsByProjectIdFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(DecisionActions.getDecisionByIdSuccess, (state, payload) => {
        const newState = adapter.addOne(
            payload.decision,
            adapter.removeOne(payload.decision.id, state)
        );
        return newState;
    }),

    on(DecisionActions.addDecision, (state, action) => {
        if (action.options?.optimistic) {
            const decisions = Object.values(state.entities).filter(
                (d) => action.payload.projectId === d.projectId
            );
            return adapter.addOneOptimistic(
                new DecisionRecord({
                    ...action.payload,
                    creationDate: new Date().toString(),
                    textId: getRecordNewTextId(
                        decisions.map((d) => d.textId),
                        'D'
                    ),
                }),
                state
            );
        }
        return state;
    }),

    on(DecisionActions.addDecisionSuccess, (state, payload) => {
        return payload.options?.optimistic
            ? adapter.addOneOptimisticSuccess(payload.decision, state)
            : adapter.addOne(payload.decision, state);
    }),

    on(DecisionActions.addDecisionFail, (state, { decisionId }) =>
        adapter.addOneOptimisticFail(decisionId, state)
    ),

    on(DecisionActions.patchDecision, (state, action) => {
        if (!action.options?.optimistic) return state;
        const originalDecision: DecisionRecord = state.entities[action.payload.id];
        let patched: DecisionRecord = getPatched(originalDecision, action.payload.patch);
        // If we removed the approver, the decision should lose his status
        if (originalDecision.assignedMembers?.length && !patched.assignedMembers?.length) {
            patched = { ...patched, statusId: undefined, submission: null };
        }
        const update: Update<DecisionRecord> = { id: patched.id, changes: patched };
        return {
            ...adapter.updateOne(update, state),
            updateServerRequstInProgressIds: [
                ...state.updateServerRequstInProgressIds,
                action.payload.id,
            ],
        };
    }),

    on(DecisionActions.patchDecisionOptimisticSuccess, (state, payload) => {
        return {
            ...state,
            updateServerRequstInProgressIds: state.updateServerRequstInProgressIds.filter(
                (id) => id !== payload.id
            ),
        };
    }),

    on(DecisionActions.patchDecisionSuccess, (state, payload) => {
        const originalDecision: DecisionRecord = state.entities[payload.id];
        if (!originalDecision) return state;
        let patched: DecisionRecord = getPatched(originalDecision, payload.patch);
        // If we removed the approver, the decision should lose his status
        if (originalDecision.assignedMembers?.length && !patched.assignedMembers?.length) {
            patched = { ...patched, statusId: undefined, submission: null };
        }
        const update: Update<DecisionRecord> = { id: patched.id, changes: patched };
        return adapter.updateOne(update, state);
    }),

    on(DecisionActions.patchDecisionFail, (state, action) => ({
        ...state,
        updateServerRequstInProgressIds: state.updateServerRequstInProgressIds.filter(
            (id) => id !== action.decisionId
        ),
    })),

    on(DecisionActions.deleteDecision, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(DecisionActions.deleteDecisionSuccess, (state, payload) =>
        adapter.removeOne(payload.id, state)
    ),

    on(DecisionActions.deleteDecisionFail, (state) => state)
);
