import { JsonProperty } from 'app/utils/json-mapper';

export class Reminder {
    @JsonProperty('id')
    id: string;

    @JsonProperty('siteMemberId')
    siteMemberId: string;

    @JsonProperty('originObjectId')
    originObjectId?: string;

    @JsonProperty('projectId')
    projectId?: string;

    @JsonProperty('date')
    date: Date;

    @JsonProperty('description')
    description?: string;

    @JsonProperty('link')
    link: ReminderLink;

    @JsonProperty('enabled')
    enabled?: boolean;

    constructor(init?: Partial<Reminder>) {
        Object.assign(this, init);
    }
}

export enum ReminderLink {
    Risks = 'Risks',
    Issues = 'Issues',
    Decisions = 'Decisions',
    Actions = 'Actions',
    Schedule = 'Schedule',
    MinuteTopics = 'MinuteTopics',
    Custom = 'Custom',
    BacklogTasks = 'BacklogTasks',
}

export const defaultReminderSnoozeDuration = 10;
