import {
    AddProgramStatusReportPayload,
    PatchProgramStatusReportPayload,
} from 'app/core/store/program-status-report/program-status-report.payloads';
import { IdPayload } from 'app/core/store/shared/models/shared-payloads';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import {
    LoadByProgramIdPayload,
    LoadBySiteIdPayload,
} from '../../../../store/shared/models/load.payload';

export const loadProgramStatusReportsByProgramIdCommand = (
    payload: LoadByProgramIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'programs', value: payload.programId },
        { name: 'statusReports' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadProgramStatusReportsBySiteIdCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'programStatusReports/sites', value: payload.siteId }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addProgramStatusReportCommand = (
    payload: AddProgramStatusReportPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'programStatusReports' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchProgramStatusReportCommand = (
    payload: PatchProgramStatusReportPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'programStatusReports', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteProgramStatusReportCommand = (
    payload: IdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'programStatusReports', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};
