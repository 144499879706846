import { createSelector } from '@ngrx/store';
import { EntityLevel } from 'app/core/models/shared-models';
import { ProjectRoleSelectors } from 'app/core/store/project-role';
import { ProjectTotalApprovedBudgetDict } from 'app/shared-ui/dumb-components/project-list-table/project-list-table.data';
import {
    ChangeRequestSelectors,
    ChangeRequestStatus,
    getChangeRequestStatus,
} from '../change-request';
import { ProjectOverview } from '../executive-dashboard';
import { IssueSelectors } from '../issue';
import { ProjectSelectors } from '../project';
import { CtxFieldItemType, ProjectFieldSelectors, ProjectFieldType } from '../project-field';
import { ProjectMemberSelectors } from '../project-member';
import { ProjectPhaseSelectors } from '../project-phase';
import { ListContainerSelectors, ListContainerType } from '../project-phase-container';
import { getCurrentPhase } from '../project-phase/project-phase.selectors';
import { RiskSelectors } from '../risks';
import { LowMediumHighStatus } from './models/raid-shared.model';
import { ProjectRoleTag } from './models/shared-permissions.model';

const selectProjectPhasesNameMap = createSelector(
    ProjectSelectors.selectCurrentSiteProjects,
    ListContainerSelectors.selectAllListContainersByType(ListContainerType.Gating),
    ProjectPhaseSelectors.selectAll,
    (projects, projectPhaseContainers, projectPhases) => {
        const map: Record<string, string> = {};
        projects.forEach((p) => {
            const phaseContainer = projectPhaseContainers.find(
                (c) => c.scope.id === p.id && c.scope.level === EntityLevel.Project
            );
            const containerPhases = projectPhases.filter(
                (p) => p.containerId === phaseContainer?.id
            );
            const currentPhase = getCurrentPhase(containerPhases);
            map[p.id] = currentPhase?.name;
        });
        return map;
    }
);

export const selectCurrentSiteProjectsOverview = createSelector(
    ProjectSelectors.selectCurrentSiteProjects,
    RiskSelectors.selectAll,
    IssueSelectors.selectAll,
    ProjectMemberSelectors.selectAll,
    ChangeRequestSelectors.selectAll,
    selectProjectPhasesNameMap,
    ProjectFieldSelectors.selectCurrentSiteProjectFields,
    ProjectRoleSelectors.selectCurrentSiteProjectRoles,
    (
        projects,
        risks,
        issues,
        projectMembers,
        changeRequests,
        projectPhaseNamesMap,
        projectFields,
        projectRoles
    ) => {
        const projectManagerRole = projectRoles.find((r) => r.tag === ProjectRoleTag.Manager);
        const closedIssueStatus = projectFields
            .find((f) => f.type === ProjectFieldType.IssuesStatus)
            ?.items?.find((i) => i.ctxItemType === CtxFieldItemType.Closed);
        const projectsOverview: ProjectOverview[] = projects.map((project) => {
            const projectManagers = projectMembers.filter(
                (m) => m.projectId === project.id && m.roleId === projectManagerRole?.id
            );
            const primaryProjectManager = projectManagers.find((m) => m.isPrimaryManager);
            const projectIssues = issues.filter((i) => i.projectId === project.id);
            const projectRisks = risks.filter((r) => r.projectId === project.id);
            const changeRequestsTotalBudget = changeRequests
                .filter(
                    (r) =>
                        r.projectId === project.id &&
                        getChangeRequestStatus(r) === ChangeRequestStatus.Approved &&
                        r.impact?.isBudget
                )
                .map((r) => r.impact?.budget || 0)
                .reduce((acc, curr) => acc + curr, 0);

            const overview: ProjectOverview = {
                projectId: project.id,
                primaryProjectManagerName:
                    primaryProjectManager?.name || primaryProjectManager?.email,
                projectManagerNames: projectManagers.map((m) => m.name || m.email),
                phaseName: projectPhaseNamesMap[project.id],
                openIssuesCount:
                    projectIssues.filter((i) => i.statusId !== closedIssueStatus?.id)?.length || 0,
                highRisksCount:
                    projectRisks.filter((r) => r.impact === LowMediumHighStatus.High)?.length || 0,
                totalApprovedBudget: project.liveBudget?.approvedBudget + changeRequestsTotalBudget,
                totalApprovedCurrentFY: project.liveBudget?.approvedCurrentFY,
                actualsFYBudget: project.liveBudget?.currentFYActuals?.total,
                forecastFYBudget: project.liveBudget?.currentFYForecast?.total,
            };
            return overview;
        });
        return projectsOverview;
    }
);

export const selectTotalApprovedBudgetMap = createSelector(
    selectCurrentSiteProjectsOverview,
    (projectOverviews) => {
        const map: ProjectTotalApprovedBudgetDict = {};
        projectOverviews.forEach((overview) => {
            map[overview.projectId] = overview.totalApprovedBudget;
        });
        return map;
    }
);
