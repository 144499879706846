import { ImportSystemTemplatePayload } from 'app/core/models/shared-models';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import { FormDataPayload } from 'app/utils/network/commands/payloads/form-data.command.payload';
import {
    LoadByProjectIdPayload,
    LoadBySiteIdPayload,
} from '../../../../store/shared/models/load.payload';
import {
    AddBudgetTablePayload,
    BudgetTableIdPayload,
    ChangeBudgetTableTemplatePayload,
    ImportBudgetTableDocumentPayload,
    PatchBudgetTablePayload,
} from './../../../../store/budget-table/budget-table.payloads';

export const loadProjectBudgetTableByProjectIdCommand = (
    payload: LoadByProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projects', value: payload.projectId },
        { name: 'budgetTable' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadCompletixProjectBudgetTableTempalatesCommand = (
    payload: any,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectBudgetTables' }, { name: 'completixTemplates' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadCompletixProjectBudgetTableTempalatesBySiteIdCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'budgetTableTemplates' },
        { name: 'completixTemplates' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadProjectBudgetTablesBySiteIdCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'budgetTableTemplates' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const importProjectBudgetTableCommand = (
    payload: ImportSystemTemplatePayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projectBudgetTables' },
        { name: 'completixTemplates' },
        { name: 'import' },
    ]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const addProjectBudgetTableCommand = (
    payload: AddBudgetTablePayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectBudgetTables' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchProjectBudgetTableCommand = (
    payload: PatchBudgetTablePayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectBudgetTables', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteProjectBudgetTableCommand = (
    payload: BudgetTableIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectBudgetTables', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};

export const changeProjectProjectBudgetTableTemplateCommand = (
    payload: ChangeBudgetTableTemplatePayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projects', value: payload.projectId },
        { name: 'budgetTable', value: payload.templateId },
    ]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const importBudgetTableDocument = (
    payload: ImportBudgetTableDocumentPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projectBudgetTables', value: payload.tableId },
        { name: 'importDocument' },
    ]);
    baseCommand.method = RestfulMethod.POST;
    const formData = new FormData();
    formData.append('document', payload.document);
    baseCommand.payload = new FormDataPayload();
    baseCommand.payload.setData(formData);
    return baseCommand;
};
