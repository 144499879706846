import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AddSprintResponse } from 'app/core/services/rest-api/features/sprint/sprint.responses';
import { SnackBarService } from 'app/services/snack-bar/snack-bar.service';
import { getPatch } from 'app/utils/json-patch';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { RestApiService } from '../../services/rest-api';
import { KanbanBoardActions } from '../kanban';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import * as AppStore from '../store';
import { Sprint } from './models/sprint.model';
import * as SprintActions from './sprint.actions';
import * as SprintSelectors from './sprint.selectors';

@Injectable()
export class SprintEffects {
    loadSprintsByProjectId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SprintActions.loadSprintsByProjectId),
            concatMap((action) => {
                return this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        SprintActions.loadSprintsByProjectIdSuccess({
                            sprints: response.payload.map((dto) => this.getSprintFromDto(dto)),
                            projectId: action.payload.projectId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            SprintActions.loadSprintsByProjectIdFail({
                                error,
                                projectId: action.payload.projectId,
                            })
                        )
                    )
                );
            })
        )
    );

    addSprint$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SprintActions.addSprint),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Adding a new Sprint...');
                return this.restApiService.process(action).pipe(
                    map((response: AddSprintResponse) => {
                        this.snackBarService.hide();
                        this.store$.dispatch(
                            KanbanBoardActions.addKanbanBoard({
                                board: response.payload.kanbanBoard,
                            })
                        );
                        return SprintActions.addSprintSuccess({
                            sprint: this.getSprintFromDto(response.payload.sprint),
                        });
                    }),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(SprintActions.addSprintFail({ error }));
                    })
                );
            })
        )
    );

    updateSprint$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SprintActions.updateSprint),
            withLatestFrom(this.store$.select(SprintSelectors.selectAll)),
            mergeMap(([action, sprints]) => {
                const sprint = sprints.find((c) => c.id === action.payload.id);
                const patch = getPatch(sprint, action.payload.changes);
                return of(
                    SprintActions.patchSprint({
                        payload: {
                            id: action.payload.id,
                            patch: patch,
                        },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchSprint$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SprintActions.patchSprint),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return SprintActions.patchSprintSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) =>
                        of(SprintActions.patchSprintFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    deleteSprint$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SprintActions.deleteSprint),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return SprintActions.deleteSprintSuccess(action.payload);
                    }),
                    catchError((error) =>
                        of(SprintActions.deleteSprintFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>,
        private snackBarService: SnackBarService
    ) {}

    getSprintFromDto(dto: any): Sprint {
        return new Sprint({
            ...Model.createFromDto(Sprint, dto),
            startDate: new Date(dto.startDate),
            endDate: new Date(dto.endDate),
        });
    }
}
