import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'ctx-alert-dialog',
    templateUrl: './alert-dialog.component.html',
})
export class AlertDialogComponent {
    title = '';
    message = '';
    confirm = 'OK';

    constructor(public dialogRef: MatDialogRef<AlertDialogComponent, boolean>) {}
}
