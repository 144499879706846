import { Action, createAction, props } from '@ngrx/store';
import { LoadBySiteIdPayload } from '../shared/models/load.payload';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import { Timesheet } from './models';
import {
    LoadTimesheetBySiteMemberIdPayload,
    ManageActivityApprovalPayload,
    PatchTimesheetPayload,
    ReSubmitTimesheetWeekActivityPayload,
    SubmitTimesheetWeekPayload,
    UpdateTimesheetPayload,
} from './timesheet.payloads';

// Load Timesheets actions

export const loadTimesheetBySiteMemberId = createAction(
    '[Timesheet] Load by Site Member id',
    props<{ payload: LoadTimesheetBySiteMemberIdPayload }>()
);

export const loadTimesheetBySiteMemberIdSuccess = createAction(
    '[Timesheet] Load by Site Member id success',
    props<{ timesheet: Timesheet }>()
);

export const loadTimesheetBySiteMemberIdFail = createAction(
    '[Timesheet] Load by Site Member id fail',
    props<{ error: any }>()
);

export const loadTimesheetsBySiteId = createAction(
    '[Timesheet] Load by site id',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadTimesheetsBySiteIdSuccess = createAction(
    '[Timesheet] Load by site id success',
    props<{ timesheets: Timesheet[] }>()
);

export const loadTimesheetsBySiteIdFail = createAction(
    '[Timesheet] Load by site id fail',
    props<{ error: any }>()
);

// Update Timesheet actions

export const updateTimesheet = createAction(
    '[Timesheet] Update',
    props<{ payload: UpdateTimesheetPayload; options?: OptimisticableActionOptions }>()
);

export const updateTimesheetSuccess = createAction(
    '[Timesheet] Update success',
    props<{ timesheet: Timesheet }>()
);

export const patchTimesheet = createAction(
    '[Timesheet] Patch',
    props<{ payload: PatchTimesheetPayload; options?: OptimisticableActionOptions }>()
);

export const patchTimesheetSuccess = createAction(
    '[Timesheet] Patch success',
    props<PatchTimesheetPayload>()
);

export const patchTimesheetFail = createAction(
    '[Timesheet] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Manage activity Approval
export const manageActivityApproval = createAction(
    '[Timesheet] Manage activity Approval',
    props<{ payload: ManageActivityApprovalPayload; options?: OptimisticableActionOptions }>()
);

export const manageActivityApprovalSuccess = createAction(
    '[Timesheet] Manage activity Approval success',
    props<{ timesheet: Timesheet }>()
);

export const manageActivityApprovalFail = createAction(
    '[Timesheet] Manage activity Approval fail',
    props<{ error: any; originAction: Action }>()
);

// Re-Open timesheet
export const reOpenTimesheetWeek = createAction(
    '[Timesheet] Re-Open Timesheet week',
    props<{ payload: SubmitTimesheetWeekPayload }>()
);

// Submit timesheet week
export const submitTimesheetWeek = createAction(
    '[Timesheet] Submit Timesheet week',
    props<{ payload: SubmitTimesheetWeekPayload }>()
);

// Re-Submit timesheet week activity
export const reSubmitTimesheetWeekActivity = createAction(
    '[Timesheet] Re-Submit timesheet week activity',
    props<{ payload: ReSubmitTimesheetWeekActivityPayload; options: OptimisticableActionOptions }>()
);
