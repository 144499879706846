import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { LoadingStatusByContainerId, LoadingStatusBySiteId } from 'app/core/models/shared-models';
import { BacklogTask } from './models';

export interface State
    extends EntityState<BacklogTask>,
        LoadingStatusByContainerId,
        LoadingStatusBySiteId {
    taskIsAdding: boolean;
}

export const adapter: EntityAdapter<BacklogTask> = createEntityAdapter<BacklogTask>();

export const initialState: State = adapter.getInitialState({
    loadingStatusByContainerId: {},
    loadingStatusBySiteId: {},
    taskIsAdding: false,
});
