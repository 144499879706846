import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as HandsontableSettingsActions from './handsontable-settings.actions';
import { adapter, initialState } from './handsontable-settings.store';
import { HandsontableSettings } from './models/handsontable-settings.model';

export const reducer = createReducer(
    initialState,
    on(HandsontableSettingsActions.loadHandsontableSettingsSuccess, (state, { settings }) => {
        const newState = adapter.addMany(settings, adapter.removeAll(state));
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(HandsontableSettingsActions.loadHandsontableSettingsFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(HandsontableSettingsActions.addHandsontableSettingsSuccess, (state, setting) => {
        return adapter.addOne(setting, state);
    }),

    on(HandsontableSettingsActions.addHandsontableSettingsFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(HandsontableSettingsActions.updateHandsontableSettingsSuccess, (state, payload) => {
        const settingsId = Object.values(state.entities).find(
            (s) =>
                s.originTableId === payload.originTableId ||
                (s.projectId === payload.projectId && s.originTableType === payload.originTableType)
        ).id;
        const update: Update<HandsontableSettings> = {
            id: settingsId,
            changes: payload.changes,
        };
        return adapter.updateOne(update, state);
    }),

    on(HandsontableSettingsActions.updateHandsontableSettingsFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(HandsontableSettingsActions.deleteHandsontableSettingsSuccess, (state, payload) => {
        return adapter.removeOne(payload.id, state);
    }),

    on(HandsontableSettingsActions.deleteHandsontableSettingsFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    }))
);
