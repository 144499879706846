import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityLevel } from 'app/core/models/shared-models';
import { selectAccountSystemAdminPermissions } from '../account/account.selectors';
import { CommentSelectors, CtxCommentLink } from '../comment';
import { DocumentSelectors } from '../document';
import { GlTableSettings, GlTableSettingsSelectors } from '../gl-table-settings';
import { ProgramSelectors } from '../program';
import { ProjectSelectors } from '../project';
import { ListContainerSelectors, ListContainerType } from '../project-phase-container';
import { selectAccountProjectRolesMap } from '../project-role/project-role.selectors';
import { ReminderLink, ReminderSelectors } from '../reminder';
import { selectAccountSiteMembership } from '../shared/account-mebership.selectors';
import { RecordPermissionsMap } from '../shared/models/shared-permissions.model';
import { SiteSelectors } from '../site';
import { selectCurrentSitePermissions } from '../site-role/site-role.selectors';
import { StateEntry } from '../state-entry';
import { extendScheduleTasks } from './adpters/schedule-task.adapter';
import { adapter, State } from './schedule-task.store';

export const selectScheduleEntry = createFeatureSelector<State>(StateEntry.ScheduleTask);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
    selectEntityStoredInDb: selectEntityStoredInDb,
    selectManyEntitiesStoredInDb: selectManyEntitiesStoredInDb,
} = adapter.getCtxSelectors(selectScheduleEntry);

export const selectLoadingStatusesPerContainerId = createSelector(
    selectScheduleEntry,
    (state) => state.loadingStatusByContainerId
);

export const selectLoadingStatusesPerSiteId = createSelector(
    selectScheduleEntry,
    (state) => state.loadingStatusBySiteId
);

export const selectLoadingStatusForCurrentSite = createSelector(
    selectLoadingStatusesPerSiteId,
    SiteSelectors.selectCurrentSiteId,
    (loadingMap, siteId) => loadingMap[siteId]
);

export const selectScheduleTasksExtended = createSelector(
    selectAll,
    ReminderSelectors.selectObjectIdToReminder(ReminderLink.Schedule),
    CommentSelectors.selectObjectIdsReferredByComments(CtxCommentLink.Schedule),
    DocumentSelectors.selectObjectIdsReferredByDocuments,
    (allTasks, idToReminderMap, idsRefferedByComments, idsReferredByDocs) =>
        extendScheduleTasks({ allTasks, idToReminderMap, idsRefferedByComments, idsReferredByDocs })
);

export const selectCurrentProjectScheduleTasksExtended = createSelector(
    selectScheduleTasksExtended,
    ListContainerSelectors.selectCurrentProjectListContainer(ListContainerType.Schedule),
    (tasks, listContainer) => tasks.filter((t) => t.listContainerId === listContainer?.id)
);

export const selectScheduleTasksExtendedByListContainerId = (listContainerId: string) =>
    createSelector(selectScheduleTasksExtended, (tasks) =>
        tasks.filter((t) => t.listContainerId === listContainerId)
    );

export const selectScheduleTasksByProjectId = (projectId: string) =>
    createSelector(
        selectScheduleTasksExtended,
        ListContainerSelectors.selectAllListContainersByType(ListContainerType.Schedule),
        (tasks, listContainers) => {
            const listContainer = listContainers.find(
                (c) => c.scope.level === EntityLevel.Project && c.scope.id === projectId
            );
            const projectTasks = tasks.filter((t) => t.listContainerId === listContainer?.id);
            return projectTasks;
        }
    );

export const selectCurrentProgramProjectsScheduleTasks = createSelector(
    ProgramSelectors.selectCurrentProgramId,
    ProjectSelectors.selectAll,
    selectAll,
    ListContainerSelectors.selectAll,
    (programId, allProjects, tasks, listContainers) => {
        const childProjectIds = allProjects
            .filter((p) => p.programId === programId)
            .map((p) => p.id);
        const projectsListContainerIds = listContainers
            .filter(
                (c) =>
                    c.scope.level === EntityLevel.Project &&
                    childProjectIds.includes(c.scope.id) &&
                    c.containerType === ListContainerType.Schedule
            )
            .map((c) => c.id);
        return tasks.filter((t) => projectsListContainerIds.includes(t.listContainerId));
    }
);

export const selectScheduleTasksByLevel = (level: EntityLevel) =>
    createSelector(selectAll, ListContainerSelectors.selectAll, (tasks, listContainers) =>
        tasks.filter((t) => {
            const listContainer = listContainers.find((c) => c.id === t.listContainerId);
            if (!listContainer) return false;
            return listContainer.scope.level === level;
        })
    );

export const selectProjectScheduleTaskPermissionsMap = createSelector(
    selectScheduleTasksByLevel(EntityLevel.Project),
    selectAccountSiteMembership,
    selectAccountProjectRolesMap,
    ListContainerSelectors.selectAllListContainersByType(ListContainerType.Schedule),
    (allScheduleTasks, accountSiteMembership, projectRolesMap, listContainers) => {
        const map: RecordPermissionsMap = {};
        allScheduleTasks.forEach((task) => {
            const listContainer = listContainers.find((c) => c.id === task.listContainerId);
            const taskIsAssigned = task.assignedMembers?.some(
                (m) => m.siteMemberId === accountSiteMembership?.id
            );
            const permissions =
                projectRolesMap[listContainer.scope.id]?.permissions?.schedule?.tasks;
            map[task.id] = {
                create: permissions?.create,
                read: permissions?.read || (taskIsAssigned && permissions?.assignedRead),
                update: permissions?.update || (taskIsAssigned && permissions?.assignedUpdate),
                delete: permissions?.delete || (taskIsAssigned && permissions?.assignedDelete),
            };
        });
        return map;
    }
);

export const selectSiteScheduleTaskPermissionsMap = createSelector(
    selectScheduleTasksByLevel(EntityLevel.Site),
    selectCurrentSitePermissions,
    (allScheduleTasks, sitePermissions) => {
        const map: RecordPermissionsMap = {};
        allScheduleTasks.forEach((task) => {
            const permissions = sitePermissions?.templates?.schedules;
            map[task.id] = permissions ?? {};
        });
        return map;
    }
);

export const selectSystemScheduleTaskPermissionsMap = createSelector(
    selectScheduleTasksByLevel(EntityLevel.System),
    selectAccountSystemAdminPermissions,
    (allScheduleTasks, permissions) => {
        const map: RecordPermissionsMap = {};
        allScheduleTasks.forEach((task) => (map[task.id] = permissions ?? {}));
        return map;
    }
);

export const selectCurrentProjectTaskOrderedIds = createSelector(
    GlTableSettingsSelectors.selectCurrentProjectGlTableSettingsByContainerType(
        ListContainerType.Schedule
    ),
    (tableSettings) => getScheduleTaskOrderedIds(tableSettings)
);

export function getScheduleTaskOrderedIds(glTableSettings: GlTableSettings): string[] {
    const orderedTaskIds = glTableSettings?.glOrderedRowIds
        ?.map((id) => glTableSettings.rows.find((r) => r.id === id))
        .filter((r) => r?.dataObjectKey)
        .map((r) => r.dataObjectKey);
    return orderedTaskIds;
}
