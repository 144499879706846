import { CalendarBase } from 'app/core/models/calendar';
import { CtxColor, CtxMember } from 'app/core/models/shared-models';
import { ctxDefaultPersistDateTimeFormat } from 'app/date-format';
import { TaskDependency, TaskDiff, TaskInit } from 'app/services/schedule';
import {
    ColumnShape,
    GlColumnType,
    TableShape,
} from 'app/shared-ui/smart-components/grid-lab/types';
import { getPatch } from 'app/utils/json-patch';
import { format } from 'date-fns';
import { GlTableSettings } from '../../gl-table-settings';
import { ProjectSettings } from '../../project';
import { Model } from '../../shared/models/base.model';
import { PatchScheduleTaskPayload } from '../schedule-task.payloads';
import { UserTaskType } from './../../shared/user-task.model';

export class ScheduleTask extends Model {
    id: string;
    listContainerId: string;
    parentId?: string;
    name?: string;
    isTimeline?: boolean;
    isMilestone?: boolean;
    progress?: number;
    startDate?: string;
    endDate?: string;
    duration?: number;
    dependencies?: TaskDependency[];
    assignedMembers?: CtxMember[];

    constructor(init?: Partial<ScheduleTask>) {
        super();
        Object.assign(this, init);
    }
}

export class ScheduleTaskExtended extends ScheduleTask {
    hasReminder: boolean;
    reminderDate?: Date;
    hasDocuments: boolean;
    hasComments: boolean;
    hasChildren: boolean;

    constructor(init?: Partial<ScheduleTaskExtended>) {
        super();
        Object.assign(this, init);
    }
}

const taskSample: Required<ScheduleTask> = {
    id: null,
    listContainerId: null,
    parentId: null,
    name: null,
    isTimeline: null,
    isMilestone: null,
    progress: null,
    startDate: null,
    endDate: null,
    duration: null,
    dependencies: null,
    assignedMembers: null,
};

export const scheduleTaskPatchableProperties = Object.keys(taskSample).filter(
    (k: keyof ScheduleTask) => !(['id', 'listContainerId'] as (keyof ScheduleTask)[]).includes(k)
);

export function getScheduleTableShape(
    glTableSettings: GlTableSettings,
    readonly: boolean,
    calendar: CalendarBase
): TableShape {
    return {
        columnShapes: glTableSettings?.columnShapes,
        glOrderedRowIds: glTableSettings?.glOrderedRowIds,
        glCollapsedRowIds: glTableSettings?.glCollapsedRowIds,
        glOrderedColumnIds: glTableSettings?.glOrderedColumnIds?.length
            ? glTableSettings.glOrderedColumnIds
            : [13, 11, 100, 70, 20, 30, 40, 50, 60, 120, 80, 130],
        glHiddenColumnIds: glTableSettings?.glHiddenColumnIds?.length
            ? glTableSettings.glHiddenColumnIds
            : [10, 15],
        glMinimumRowCount: glTableSettings?.glMinimumRowCount ?? 30,
        glAutoAddRowCount: glTableSettings?.glAutoAddRowCount ?? 5,
        glStyle: glTableSettings?.glStyle,

        glDataObjectKeyProperty: 'id',
        glTree: {
            enabled: true,
            columnRefs: {
                nodeId: 10,
                parentNodeId: 15,
                foldingId: 20,
            },
        },
        glSchedule: {
            enabled: true,
            columnRefs: {
                taskId: 10,
                parentTaskId: 15,
                startDate: 30,
                endDate: 40,
                duration: 50,
                dependencies: 60,
                progress: 120,
            },
            autoFillNewTask: true,
        },
        glCalendar: calendar,
        readOnly: readonly,
        filters: true,
        dropdownMenu: true,
    };
}

export enum ScheduleTaskButtonType {
    Comments = 'comments',
}

export const scheduleStartColumn: ColumnShape = {
    glId: 30,
    glSourceProperty: 'dataObject.startDate',
    glTitle: 'Start',
    width: 100,
};

export const scheduleEndColumn: ColumnShape = {
    glId: 40,
    glSourceProperty: 'dataObject.endDate',
    glTitle: 'Finish',
    width: 100,
};

export const scheduleProgressColumn: ColumnShape = {
    glId: 120,
    glSourceProperty: 'dataObject.progress',
    glType: GlColumnType.Number,
    glTitle: '% complete',
    width: 85,
};

export const scheduleReminderColumn: ColumnShape = {
    glId: 11,
    glSourceProperty: 'dataObject.hasReminder',
    glType: GlColumnType.Reminder,
    glMaxWidth: 25,
    glMinWidth: 25,
    width: 25,
    dropdownMenu: false,
    filters: false,
    glTitle: 'Reminder',
    editor: false,
};

export const scheduleCommentsColumn: ColumnShape = {
    glId: 13,
    glSourceProperty: 'dataObject.hasComments',
    glType: GlColumnType.Button,
    glMatIconName: 'chat',
    buttonType: ScheduleTaskButtonType.Comments,
    glMaxWidth: 25,
    glMinWidth: 25,
    width: 25,
    glTitle: 'Comments',
    editor: false,
};

export const scheduleTimelineColumn: ColumnShape = {
    glId: 70,
    glSourceProperty: 'dataObject.isTimeline',
    glType: GlColumnType.Checkbox,
    glMatIconName: 'timeline',
    glMaxWidth: 25,
    glMinWidth: 25,
    width: 25,
    glTitle: 'Timeline',
};

export const scheduleMilestoneColumn: ColumnShape = {
    glId: 100,
    glSourceProperty: 'dataObject.isMilestone',
    glType: GlColumnType.Checkbox,
    glMatIconName: 'stop',
    glMatIconStyleClass: 'material-icons',
    glMatIconCustomClass: 'milestone',
    glMaxWidth: 25,
    glMinWidth: 25,
    width: 25,
    glTitle: 'Milestone',
};

export const scheduleAssignedMembersColumn: ColumnShape = {
    glId: 130,
    glSourceProperty: 'dataObject.assignedMembers',
    glType: GlColumnType.MultipleMembers,
    glTitle: 'Resources',
    width: 120,
};

export function getScheduleColumns(): ColumnShape[] {
    return [
        {
            glId: 10,
            glSourceProperty: 'dataObject.id',
            glType: GlColumnType.TextNumber,
            glMaxWidth: 35,
            glMinWidth: 35,
            glTitle: 'Id',
            readOnly: true,
            width: 35,
        },
        scheduleReminderColumn,
        scheduleCommentsColumn,
        {
            glId: 15,
            glSourceProperty: 'dataObject.parentId',
            glType: GlColumnType.TextNumber,
            glMaxWidth: 50,
            glMinWidth: 50,
            glTitle: 'Parent',
            width: 50,
        },
        {
            glId: 20,
            glSourceProperty: 'dataObject.name',
            glType: GlColumnType.TextNumber,
            glTitle: 'Task Name',
            width: 400,
        },
        scheduleStartColumn,
        scheduleEndColumn,
        {
            glId: 50,
            glSourceProperty: 'dataObject.duration',
            glTitle: 'Duration',
            width: 70,
        },
        {
            glId: 60,
            glSourceProperty: 'dataObject.dependencies',
            glTitle: 'Dependency',
            width: 90,
        },
        scheduleTimelineColumn,
        scheduleMilestoneColumn,
        scheduleProgressColumn,
        scheduleAssignedMembersColumn,
    ];
}

export const scheduleTemplateHiddenColumnIds: number[] = [
    scheduleStartColumn,
    scheduleEndColumn,
    scheduleReminderColumn,
    scheduleProgressColumn,
].map((c) => c.glId);

export const scheduleExportHiddenColumnIds: number[] = [
    scheduleReminderColumn,
    scheduleTimelineColumn,
    scheduleMilestoneColumn,
    scheduleCommentsColumn,
].map((c) => c.glId);

export function getTaskInit(task: ScheduleTask): TaskInit {
    return {
        taskId: task.id,
        start: task.startDate ? new Date(task.startDate) : null,
        end: task.endDate ? new Date(task.endDate) : null,
        duration: task.duration,
        dependencies: task.dependencies,
        parentTaskId: task.parentId,
        progress: task.progress,
    };
}

export function getScheduleTaskPatchedFromDiffs(
    scheduleTasks: ScheduleTask[],
    diffs: TaskDiff[]
): PatchScheduleTaskPayload[] {
    const patchPayloads: PatchScheduleTaskPayload[] = [];
    diffs.forEach((diff) => {
        const changes: Partial<ScheduleTask> = {};
        if (diff.dependenciesDiff) changes.dependencies = diff.dependenciesDiff.new;
        if (diff.durationDiff) changes.duration = diff.durationDiff.new;
        if (diff.parentTaskIdDiff) changes.parentId = diff.parentTaskIdDiff.new;
        if (diff.startDiff) {
            changes.startDate = format(diff.startDiff.new, ctxDefaultPersistDateTimeFormat);
        }
        if (diff.endDiff) {
            changes.endDate = format(diff.endDiff.new, ctxDefaultPersistDateTimeFormat);
        }
        if (diff.progressDiff) changes.progress = diff.progressDiff.new;
        const task = scheduleTasks.find((t) => t.id === diff.taskId);
        const patch = getPatch(task, changes);
        patchPayloads.push({ id: task.id, patch });
    });

    return patchPayloads;
}

export function getProjectScheduleTaskColor(
    projectSettings: ProjectSettings,
    projectColor: CtxColor
): CtxColor {
    const scheduleTasksColor = projectSettings.taskTypeColorsInCalendar?.find(
        (t) => t.type === UserTaskType.Schedule
    )?.color;
    const tasksColorIsDefault = !scheduleTasksColor || scheduleTasksColor === CtxColor.Default;
    const color = tasksColorIsDefault ? projectColor : scheduleTasksColor;
    return color;
}
