import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { LoadingStatusBySiteId } from 'app/core/models/shared-models';
import { AsyncUpdateStatus } from '../shared/async-update-status.store';
import { ProjectPhase } from './models';

export interface State extends EntityState<ProjectPhase>, LoadingStatusBySiteId {
    loadingStatusByContainerId: Record<string, AsyncUpdateStatus>;
}

export const adapter = createEntityAdapter<ProjectPhase>();

export const initialState: State = adapter.getInitialState({
    loadingStatusByContainerId: {},
    loadingStatusBySiteId: {},
});
