import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectSelectors } from '../project';
import { selectCurrentProjectId } from '../project/project.selectors';
import { SiteMemberSelectors } from '../resource';
import { selectCurrentSiteId } from '../site/site.selectors';
import { StateEntry } from '../state-entry';
import { adapter, State } from './change-request.store';
import {
    ChangeRequestApprover,
    ChangeRequestStatus,
    getChangeRequestStatus,
} from './models/change-request.model';

export const selectChangeRequestEntry = createFeatureSelector<State>(StateEntry.ChangeRequest);

export const selectLoadingStatusesPerSiteId = createSelector(
    selectChangeRequestEntry,
    (state) => state.loadingStatusBySiteId
);

export const selectLoadingStatusForCurrentSite = createSelector(
    selectLoadingStatusesPerSiteId,
    selectCurrentSiteId,
    (stastusMap, siteId) => stastusMap[siteId]
);

export const selectLoadingStatusesPerProjectId = createSelector(
    selectChangeRequestEntry,
    (state) => state.loadingStatusByProjectId
);

export const selectLoadingStatusForCurrentProject = createSelector(
    selectLoadingStatusesPerProjectId,
    selectCurrentProjectId,
    (statusMap, projectId) => statusMap[projectId]
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectChangeRequestEntry);

export const selectExpandedIds = createSelector(
    selectChangeRequestEntry,
    (state) => state.expandedCrIds
);

export const selectCurrentProjectChangeRequests = createSelector(
    selectAll,
    ProjectSelectors.selectCurrentProjectId,
    (requests, projectId) => requests.filter((r) => r.projectId === projectId)
);

export const selectCurrentProjectCrApprovedBudget = createSelector(
    selectCurrentProjectChangeRequests,
    (requests) =>
        requests
            .filter(
                (cr) =>
                    getChangeRequestStatus(cr) === ChangeRequestStatus.Approved &&
                    cr.impact?.isBudget
            )
            ?.map((cr) => +cr.impact.budget || 0)
            ?.reduce((acc, curr) => acc + curr, 0)
);

export const selectChangeRequestApprovers = createSelector(
    selectChangeRequestEntry,
    SiteMemberSelectors.selectAll,
    (entry, siteMembers): ChangeRequestApprover[] =>
        entry.approvers?.map((approver) => {
            const siteMember = siteMembers.find((sm) => sm.id === approver.siteMemberId);
            return {
                ...approver,
                name: siteMember?.name || siteMember?.email || approver.name,
                avatarColor: siteMember?.avatarColor || approver.avatarColor,
                imageDataUrl: siteMember?.imageDataUrl || approver.imageDataUrl,
            };
        })
);

export const selectChangeRequestById = (requestId: string) =>
    createSelector(selectAll, (changeRequests) => changeRequests.find((r) => r.id === requestId));
