import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import { LoadBySiteIdPayload } from '../../../../store/shared/models/load.payload';
import {
    AddProjectRequestPayload,
    PatchProjectRequestPayload,
    ProjectRequestIdPayload,
} from './../../../../store/project-request/project-request.payloads';

export const loadProjectRequestsCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'projectRequests' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addProjectRequestCommand = (
    payload: AddProjectRequestPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectRequests' }]);
    baseCommand.payload.setData(payload.options);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchProjectRequestCommand = (
    payload: PatchProjectRequestPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectRequests', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteProjectRequestCommand = (
    payload: ProjectRequestIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectRequests', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};
