import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import * as BudgetTableActions from './budget-table.actions';
import { adapter, initialState } from './budget-table.store';
import { ProjectBudgetTable } from './models';

export const reducer = createReducer(
    initialState,
    on(BudgetTableActions.loadCompletixBudgetTableTemplates, (state) => ({
        ...state,
        completixTemplatesLoading: true,
    })),

    on(BudgetTableActions.loadCompletixBudgetTableTemplatesSuccess, (state, payload) => ({
        ...adapter.addMany(
            payload.tables,
            adapter.removeMany(
                payload.tables.map((t) => t.id),
                state
            )
        ),
        completixTemplatesLoading: false,
    })),

    on(BudgetTableActions.loadCompletixBudgetTableTemplatesFail, (state) => ({
        ...state,
        completixTemplatesLoading: false,
    })),

    on(BudgetTableActions.loadCompletixBudgetTableTemplatesBySiteId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(BudgetTableActions.loadBudgetTablesBySiteId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(BudgetTableActions.loadBudgetTablesBySiteIdSuccess, (state, { tables }) => {
        const newState = adapter.addMany(
            tables,
            adapter.removeMany(
                tables.map((t) => t.id),
                state
            )
        );
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(BudgetTableActions.loadBudgetTablesBySiteIdFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(BudgetTableActions.loadBudgetTableByProjectId, (state) => ({
        ...state,
        projectBudgetLoading: true,
    })),

    on(BudgetTableActions.loadBudgetTableByProjectIdSuccess, (state, payload) => {
        const tableExists = !!state.entities[payload.table.id];
        const configuredState = tableExists ? adapter.removeOne(payload.table.id, state) : state;
        const newState = adapter.addOne(payload.table, configuredState);
        return { ...newState, projectBudgetLoading: false, loaded: true };
    }),

    on(BudgetTableActions.loadBudgetTableByProjectIdFail, (state) => ({
        ...state,
        projectBudgetLoading: false,
    })),

    on(BudgetTableActions.addBudgetTableSuccess, (state, { table }) =>
        adapter.addOne(table, state)
    ),

    on(BudgetTableActions.addBudgetTableFail, (state) => ({
        ...state,
    })),

    on(BudgetTableActions.patchBudgetTable, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patched = getPatched(state.entities[action.payload.id], action.payload.patch);
        const update: Update<ProjectBudgetTable> = {
            id: patched.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(BudgetTableActions.patchBudgetTableSuccess, (state, payload) => {
        const patched = getPatched(state.entities[payload.id], payload.patch);
        const update: Update<ProjectBudgetTable> = {
            id: patched.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(BudgetTableActions.patchBudgetTableFail, (state) => ({
        ...state,
    })),

    on(BudgetTableActions.deleteBudgetTable, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(BudgetTableActions.deleteBudgetTableSuccess, (state, { payload }) =>
        adapter.removeOne(payload.id, state)
    ),

    on(BudgetTableActions.deleteBudgetTableFail, (state) => ({
        ...state,
    })),

    on(BudgetTableActions.changeBudgetTableTemplateSuccess, (state, payload) => {
        const currentTable = Object.values(state.entities).find(
            (t) => t.projectId === payload.table.projectId
        );
        const configuredState = currentTable ? adapter.removeOne(currentTable.id, state) : state;
        return adapter.addOne(payload.table, configuredState);
    })
);
