import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import {
    AsyncUpdateStatusDefault,
    AsyncUpdateStatusDefaultLoad,
    AsyncUpdateStatusDefaultLoadSuccess,
} from '../shared/async-update-status.store';
import { Sprint } from './models';
import * as SprintActions from './sprint.actions';
import { adapter, initialState } from './sprint.store';

export const reducer = createReducer(
    initialState,

    on(SprintActions.loadSprintsByProjectId, (state, action) => {
        const loadingStatusMap = state.loadingStatusByProjectId;
        const projectId = action.payload.projectId;

        return {
            ...state,
            loadingStatusByProjectId: {
                ...loadingStatusMap,
                [projectId]: {
                    ...(loadingStatusMap[projectId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoad,
                },
            },
        };
    }),

    on(SprintActions.loadSprintsByProjectIdSuccess, (state, payload) => {
        const removeIds = Object.values(state.entities)
            .filter((s) => s.projectId === payload.projectId)
            .map((s) => s.id);
        const newState = adapter.addMany(payload.sprints, adapter.removeMany(removeIds, state));
        const loadingStatusMap = state.loadingStatusByProjectId;
        const projectId = payload.projectId;
        return {
            ...newState,
            loadingStatusByProjectId: {
                ...loadingStatusMap,
                [projectId]: {
                    ...(loadingStatusMap[projectId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                },
            },
        };
    }),

    on(SprintActions.loadSprintsByProjectIdFail, (state, payload) => {
        const loadingStatusMap = state.loadingStatusByProjectId;
        const projectId = payload.projectId;

        return {
            ...state,
            loadingStatusByProjectId: {
                ...loadingStatusMap,
                [projectId]: {
                    ...(loadingStatusMap[projectId] ?? {}),
                    ...AsyncUpdateStatusDefault,
                    error: payload.error,
                    loadingFailed: true,
                },
            },
        };
    }),

    on(SprintActions.addSprintSuccess, (state, payload) => {
        return adapter.addOne(payload.sprint, state);
    }),

    on(SprintActions.patchSprint, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patched = getPatched(state.entities[action.payload.id], action.payload.patch);
        const update: Update<Sprint> = {
            id: action.payload.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(SprintActions.patchSprintSuccess, (state, payload) => {
        const patched = getPatched(state.entities[payload.id], payload.patch);
        const update: Update<Sprint> = {
            id: payload.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(SprintActions.deleteSprint, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(SprintActions.deleteSprintSuccess, (state, { id }) => {
        return adapter.removeOne(id, state);
    })
);
