import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import * as CommentActions from './comment.actions';
import { adapter, initialState } from './comment.store';

export const reducer = createReducer(
    initialState,

    on(CommentActions.loadCommentsBySiteId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(CommentActions.loadCommentsBySiteIdSuccess, (state, { comments }) => {
        const newState = adapter.addMany(comments, adapter.removeAll(state));
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(CommentActions.loadCommentsBySiteIdFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(CommentActions.addCommentSuccess, (state, payload) => {
        return adapter.addOne(payload.comment, state);
    }),

    on(CommentActions.patchComment, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patched = getPatched(state.entities[action.payload.id], action.payload.patch);
        return adapter.addOne(patched, adapter.removeOne(patched.id, state));
    }),

    on(CommentActions.patchCommentSuccess, (state, payload) =>
        adapter.addOne(payload.comment, adapter.removeOne(payload.comment.id, state))
    ),

    on(CommentActions.deleteComment, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(CommentActions.deleteCommentSuccess, (state, { id }) => {
        return adapter.removeOne(id, state);
    })
);
