import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SubscriptionPlan } from 'app/core/models/billing';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { SnackBarService } from '../../../services/snack-bar/snack-bar.service';
import { RestApiService } from '../../services/rest-api';
import { Model } from '../shared/models/base.model';
import { okEmptyAction } from './../project/project.actions';
import * as SubscriptionPlanActions from './subscription-plan.actions';

@Injectable()
export class SubscriptionPlanEffects {
    loadSubscriptionPlans$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SubscriptionPlanActions.loadSubscriptionPlans),
            switchMap((action) =>
                this.restApiService.process(action).pipe(
                    map((result: CommandResult) =>
                        result.payload.map(
                            (dto) =>
                                new SubscriptionPlan(Model.createFromDto(SubscriptionPlan, dto))
                        )
                    ),
                    map((plans) =>
                        SubscriptionPlanActions.loadSubscriptionPlansSuccess({
                            entities: plans,
                        })
                    ),
                    catchError((error: any) => {
                        return of(SubscriptionPlanActions.loadSubscriptionPlansFail({ error }));
                    })
                )
            )
        )
    );

    addSubscriptionPlan$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SubscriptionPlanActions.addSubscriptionPlan),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(
                        (result: CommandResult) =>
                            new SubscriptionPlan(
                                Model.createFromDto(SubscriptionPlan, result.payload)
                            )
                    ),
                    map((plan) => {
                        this.snackBarService.show('Subscription plan successfully created');
                        return SubscriptionPlanActions.addSubscriptionPlanSuccess({
                            entity: plan,
                        });
                    }),
                    catchError((errorResponse: any) => {
                        this.snackBarService.show(
                            'Cannot save subscription plan. ' + errorResponse.error.description,
                            3500
                        );
                        return of(
                            SubscriptionPlanActions.addSubscriptionPlanFail({
                                error: errorResponse,
                            })
                        );
                    })
                )
            )
        )
    );

    updateSubscriptionPlan$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SubscriptionPlanActions.updateSubscriptionPlan),
            // do not use switchMap with optimistic updates
            // since it may skip undoing action on fail
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((result: CommandResult) =>
                        Model.createFromDto(SubscriptionPlan, result.payload)
                    ),
                    map((plan) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return SubscriptionPlanActions.updateSubscriptionPlanSuccess({
                            entity: plan,
                        });
                    }),
                    catchError((error: any) => {
                        this.snackBarService.show('Cannot update subscription plan');
                        return of(SubscriptionPlanActions.updateSubscriptionPlanFail({ error }));
                    })
                )
            )
        )
    );

    deleteSubscriptionPlan$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SubscriptionPlanActions.deleteSubscriptionPlan),
            // do not use switchMap with optimistic updates
            // since it may skip undoing action on fail
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return SubscriptionPlanActions.deleteSubscriptionPlanSuccess({
                            id: action.payload.id,
                        });
                    }),
                    catchError((error: any) => {
                        this.snackBarService.show('Cannot delete subscription plan');
                        return of(
                            SubscriptionPlanActions.deleteSubscriptionPlanFail({
                                error,
                                originAction: action,
                            })
                        );
                    })
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private snackBarService: SnackBarService
    ) {}
}
