import { CtxColor } from 'app/core/models/shared-models';
import { KanbanBoardShape, KanbanBoardShapeLink } from '../../kanban';
import { Project } from '../../project';
import { ProjectField, ProjectFieldItem } from '../../project-field';

export enum LowMediumHighStatus {
    Low = 'Low',
    Medium = 'Medium',
    High = 'High',
}

export const lowMediumHighStatusColorMap: Record<LowMediumHighStatus, CtxColor> = {
    [LowMediumHighStatus.Low]: CtxColor.Green,
    [LowMediumHighStatus.Medium]: CtxColor.Yellow,
    [LowMediumHighStatus.High]: CtxColor.Red,
};

export const lowMediumHighStatusFullValues: Record<LowMediumHighStatus, string> = {
    [LowMediumHighStatus.Low]: 'Low',
    [LowMediumHighStatus.Medium]: 'Medium',
    [LowMediumHighStatus.High]: 'High',
};

export interface RaidRowsOrdering {
    risks: RaidItemOrdering[];
    issues: RaidItemOrdering[];
    decisions: RaidItemOrdering[];
    actions: RaidItemOrdering[];
}

export interface RaidItemOrdering {
    projectId: string;
    orderedIdx: number[];
}

export interface RaidRecord {
    id: string;
    textId?: string;
    projectId: string;
    description?: string;
    creationDate: string;
    statusId: string;
    attachedDocumentIds?: string[];
    color?: CtxColor;
    originObjectId?: string;
}

export const raidDefaultUserAvatarColor = '#1e88e5';

export type RaidStatusListByProjectMap = Record<string, ProjectFieldItem[]>;

export function getRaidStatusListByProjectMap(
    projects: Project[],
    kanbanBoards: KanbanBoardShape[],
    link: KanbanBoardShapeLink,
    projectField: ProjectField
): RaidStatusListByProjectMap {
    const map: RaidStatusListByProjectMap = {};
    projects.forEach((prj) => {
        const board = kanbanBoards.find((b) => b.link === link && b.projectId === prj.id);
        const boardExtraStatusItems = board?.columnShapes?.filter(
            (c) => !projectField?.items?.some((i) => i.id === c.criterionValue)
        );
        const extraStatusItems: ProjectFieldItem[] = boardExtraStatusItems?.map((i) => ({
            id: i.criterionValue ?? i.id,
            name: i.title,
            color: i.color,
        }));
        const configuredProjectFieldItems = (projectField?.items || []).map((item) => {
            const kanbanColumn = board?.columnShapes?.find((c) => c.criterionValue === item.id);
            if (!kanbanColumn) return item;
            return { ...item, name: kanbanColumn.title, color: kanbanColumn.color };
        });
        map[prj.id] = [...configuredProjectFieldItems, ...(extraStatusItems || [])];
    });
    return map;
}

export function getRecordNewTextId(textIds: string[], prefixSymbol: string): string {
    const maxTextIdSuffix = Math.max(
        ...textIds.map((textId) => +textId.substring(textId.indexOf('-') + 1)),
        0
    );
    return `${prefixSymbol.toUpperCase()}-${maxTextIdSuffix + 1}`;
}
