import { JsonProperty } from 'app/utils/json-mapper';
import { Model } from './base.model';

export class ImageModel extends Model {
    @JsonProperty('imageDataUrl')
    imageDataUrl: string = undefined;

    @JsonProperty('aspectRatio')
    aspectRatio: number = undefined;

    @JsonProperty('shape')
    shape?: ImageShape = undefined;

    @JsonProperty('backgroundColor')
    backgroundColor?: string = undefined;

    isDefault?: boolean;

    constructor(init?: Partial<ImageModel>) {
        super();
        Object.assign(this, init);
    }

    getImageDataUrl(): string {
        return this.imageDataUrl;
    }
}

export enum ImageShape {
    CIRCLE = 'circle',
    RECTANGLE = 'rectangle',
}
