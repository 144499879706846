import { ApprovalState } from 'app/core/store/approval-request';
import { LoadBySiteIdPayload } from 'app/core/store/shared/models/load.payload';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import {
    LoadTimesheetBySiteMemberIdPayload,
    ManageActivityApprovalPayload,
    PatchTimesheetPayload,
    ReSubmitTimesheetWeekActivityPayload,
    SubmitTimesheetWeekPayload,
} from '../../../../store/timesheet/timesheet.payloads';

export const loadTimesheetsBySiteMemberIdCommand = (
    payload: LoadTimesheetBySiteMemberIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'siteMembers', value: payload.siteMemberId },
        { name: 'timesheet' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadTimesheetsBySiteId = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'siteMembers/sites', value: payload.siteId },
        { name: 'timesheets' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const patchTimesheetCommand = (
    payload: PatchTimesheetPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'timesheets', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const manageActivityApprovalCommand = (
    payload: ManageActivityApprovalPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'timesheets' },
        { name: 'projectActivities', value: payload.activityId },
        { name: 'approval' },
        { name: payload.approvalState === ApprovalState.Approved ? 'approve' : 'reject' },
    ]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const reOpenTimesheetWeekCommand = (
    payload: SubmitTimesheetWeekPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'timesheets', value: payload.timesheetId },
        { name: 'reOpen' },
    ]);
    baseCommand.payload.setData(payload.weekStartDate);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const submitTimesheetWeekCommand = (
    payload: SubmitTimesheetWeekPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'timesheets', value: payload.timesheetId },
        { name: 'submit' },
    ]);
    baseCommand.payload.setData(payload.weekStartDate);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const reSubmitTimesheetWeekActivityCommand = (
    payload: ReSubmitTimesheetWeekActivityPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'timesheets', value: payload.timesheetId },
        { name: 'projectActivities', value: payload.activityId },
    ]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};
