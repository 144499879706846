import { OverlayContainer } from '@angular/cdk/overlay';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { MinimalRouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { FileDownloadService } from './core/services/file-download/file-download.service';
import { RestApiModule } from './core/services/rest-api';
import { SignalRApiModule } from './core/services/signalr-api/signalr-api.module';
import { SignalRApiService } from './core/services/signalr-api/signalr-api.service';
import { AppStore, effects, metaReducers, rootReducerMap } from './core/store';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthenticationPageGuardService } from './services/authentication-page-guard.service';
import { AuthenticationService } from './services/authentication.service';
import { BootstrapService } from './services/bootstrap.service';
import { SharedService } from './services/shared.service';
import { SnackBarModule } from './services/snack-bar/snack-bar.module';
import { SvgDefinitionsComponent } from './shared-ui/dumb-components/svg/svg-definitions/svg-definitions.component';
import { GlobalPagesModule } from './shared-ui/pages/global-pages.module';
import { RestApiBaseUrl, SignalRBaseUrl } from './utils/network';

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent, SvgDefinitionsComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        // NoopAnimationsModule, // This module disables animations if replaces BrowserAnimationsModule,
        HttpClientModule,
        // AuthModule.forRoot({
        //     domain: environment.authConfig.domain,
        //     clientId: environment.authConfig.clientID,
        //     redirectUri: environment.authConfig.redirectUri,
        //     useRefreshTokens: true,
        //     scope: 'openid email profile',
        //     audience: environment.authConfig.audience,
        //     errorPath: '/' + RouteBrick.Error,
        //     httpInterceptor: {
        //         allowedList: [environment.restApiConfig.baseUrl + '/*'],
        //     },
        // }),
        FlexLayoutModule,
        GlobalPagesModule,
        AppRoutingModule,
        RestApiModule.forRoot(),
        SignalRApiModule,

        /**
         * StoreModule.forRoot is imported once in the root module, accepting a reducer
         * function or object map of reducer functions. If passed an object of
         * reducers, combineReducers will be run creating your application
         * meta-reducer. This returns all providers for an @ngrx/store
         * based application.
         */
        StoreModule.forRoot(rootReducerMap, {
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
            },
            metaReducers: !environment.production ? metaReducers : [],
            initialState: AppStore.getInitialState,
        }),
        EffectsModule.forRoot(effects),
        SnackBarModule,
        MatDialogModule,
        // Note that you must instrument after importing StoreModule
        StoreDevtoolsModule.instrument({
            maxAge: 500, //  Retains last 50 states
            name: 'Completix ' + environment.name.toUpperCase(),
        }),
        // Connects RouterModule with StoreModule
        StoreRouterConnectingModule.forRoot({ serializer: MinimalRouterStateSerializer }),
    ],
    providers: [
        OverlayContainer,
        // JwtHelperService,
        // { provide: JWT_OPTIONS, useValue: null },
        {
            provide: RestApiBaseUrl,
            useFactory: () => environment.restApiConfig.baseUrl,
        },
        {
            provide: SignalRBaseUrl,
            useFactory: () => environment.signalRApiConfig.baseUrl,
        },
        AuthenticationService,
        AuthGuardService,
        AuthenticationPageGuardService,
        BootstrapService,
        FileDownloadService,
        SharedService,
        SignalRApiService,
    ],
})
export class AppModule {}
