const maxDays = 365 * 5;
const maxHours = maxDays * 8;
const maxMinutes = maxHours * 60;

export function parseTaskDuration(input: string, options: TaskDurationOptions): number | null {
    input = input.trim();
    if (!input) return null;

    options ??= defaultTaskDurationOptions;

    // Day only values might be without unit, otherwise the day unit is required
    // 5 = 5 days
    // 5d = 5 days
    // 5d 4h = 5 days 4 hours
    // 5 4h - error
    // Days are float numbers, units supported: d, day, days
    // Hours are integers, units supported: h, hr, hrs, hour, hours
    // Minutes are integers, units supported: m, min, mins, minute, minutes
    const regexp =
        /^([+-]?)\s*(?:([\d|.]+)\s*(?:d|day|days|$))?\s*(?:(\d+)\s*(?:h|hr|hrs|hour|hours))?\s*(?:(\d+)\s*(?:m|min|mins|minute|minutes))?$/i;

    const groups = input.match(regexp);
    if (!groups) return null;

    const [, signStr, dayStr, hourStr, minuteStr] = groups;

    const sign = signStr === '-' ? -1 : 1;

    const days = dayStr ? parseFloat(dayStr) : 0;
    if (isNaN(days) || days > maxDays) return null;

    const hours = hourStr ? parseInt(hourStr, 10) : 0;
    if (isNaN(hours) || hours > maxHours) return null;

    const minutes = minuteStr ? parseInt(minuteStr, 10) : 0;
    if (isNaN(minutes) || minutes > maxMinutes) return null;

    const durationSeconds = Math.round(
        (days * options.workingHoursPerDay + hours) * 3600 + minutes * 60
    );
    return sign * durationSeconds;
}

export function formatTaskDuration(duration: number, options: TaskDurationOptions): string {
    if (duration === 0) return '0';

    options ??= defaultTaskDurationOptions;

    const sign = duration < 0 ? '-' : '';

    let durationToDistribute = Math.abs(duration);
    const dd = Math.trunc(durationToDistribute / 3600 / options.workingHoursPerDay);
    const days = formatUnits(dd, 'day');

    durationToDistribute = durationToDistribute - dd * options.workingHoursPerDay * 3600;
    const hh = Math.trunc(durationToDistribute / 3600);
    const hours = formatUnits(hh, 'hr');

    durationToDistribute = durationToDistribute - hh * 3600;
    const mm = Math.trunc(durationToDistribute / 60);
    const minutes = formatUnits(mm, 'min');

    return sign + [days, hours, minutes].filter((v) => !!v).join(' ');
}

function formatUnits(amount: number, singularUnit: 'day' | 'hr' | 'min'): string {
    if (amount === 0) return '';
    const pluralizedUnit = amount === 1 ? singularUnit : singularUnit + 's';
    return amount + pluralizedUnit;
}

export interface TaskDurationOptions {
    workingHoursPerDay: number;
}

export const defaultTaskDurationOptions = {
    workingHoursPerDay: 24,
};
