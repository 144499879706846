import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { HandsontableSettings } from './models/handsontable-settings.model';

export interface LoadingStatus {
    serverRequestInProgress: boolean;
    loaded: boolean;
}

export interface State extends EntityState<HandsontableSettings>, LoadingStatus {}

export const adapter: EntityAdapter<HandsontableSettings> =
    createEntityAdapter<HandsontableSettings>();

export const initialState: State = adapter.getInitialState({
    serverRequestInProgress: false,
    loaded: false,
});
