import { IdPayload } from 'app/core/store/shared/models/shared-payloads';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import { LoadBySiteIdPayload } from './../../../../store/shared/models/load.payload';

export const loadExecutiveDashboardBySiteId = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'siteOverview', value: payload.siteId }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const deleteExecutiveDashboardMinuteTask = (
    payload: IdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'siteOverview' },
        { name: 'minuteTopics', value: payload.id },
    ]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};
