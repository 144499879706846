import { CtxColor, ProjectBudgetBase } from 'app/core/models/shared-models';
import { SiteEntityState } from 'app/core/models/site-entity.model';
import { MemberNamesDict } from 'app/shared-ui/dumb-components/project-list-table/project-list-table.data';
import { ProjectOverview } from '../../executive-dashboard';
import { OverallStatus } from '../../program';
import { ProjectPhase } from '../../project-phase';
import {
    ProjectHealthReport,
    ProjectMajorMilestone,
    ProjectStatusReport,
    ProjectStatusReportBudget,
    ProjectStatusTopRaidRecord,
} from '../../project-status-report';
import {
    Project,
    ProjectBudget,
    ProjectBudgetEntity,
    ProjectDelivery,
} from '../../project/project.model';
import { Model } from '../../shared/models/base.model';
import { LowMediumHighStatus } from '../../shared/models/raid-shared.model';

export class ProgramStatusReport extends Model {
    id: string;
    programId: string;
    name: string;
    health: ProjectHealthReport;
    budget: ProjectStatusReportBudget;
    totalApprovedBudget: number;
    totalApprovedCurrentFY: number;
    majorMilestones: ProjectMajorMilestone[];
    topRisks: ProjectStatusTopRaidRecord[];
    topIssues: ProjectStatusTopRaidRecord[];
    projectStatuses: ProgramStatusReportProject[];
    projectManagerNamesMap: MemberNamesDict;
    date: Date;
    isLive?: boolean;

    constructor(init?: Partial<ProgramStatusReport>) {
        super();
        Object.assign(this, init);
    }
}

export interface ProgramStatusReportProject extends ProjectBaseInfo {
    state: SiteEntityState;
    overallStatus: OverallStatus;
    progress: number;
    phaseName: string;
    budget: ProjectBudget;
    startDate: Date;
    endDate: Date;
    scheduledStartDate: Date;
    scheduledEndDate: Date;
    delivery: ProjectDelivery;
    totalApprovedBudget: number;
    totalApprovedCurrentFY: number;
    summary?: string;
    planned?: string;
}

export interface ProjectBaseInfo {
    projectId: string;
    projectName: string;
    projectNumber: string;
    projectColor: CtxColor;
}

function getBudgetSum(
    reports: ProjectStatusReport[],
    firstKey: keyof ProjectBudgetBase,
    secondKey: keyof ProjectBudgetEntity
): number {
    return reports
        .map((r) => r.budget?.[firstKey]?.value?.[secondKey] || 0)
        .reduce((acc, curr) => acc + curr, 0);
}

export function getLiveProgramStatusReport(
    programId: string,
    allProjectLiveReports: ProjectStatusReport[],
    projects: Project[],
    projectsCurrentPhaseMap: Record<string, ProjectPhase>,
    projectManagerNamesMap: MemberNamesDict,
    projectOverviews: ProjectOverview[]
): ProgramStatusReport {
    const emptyBudgetBase: Required<ProjectBudgetBase> = {
        plannedCost: { value: {} },
        actualToDate: { value: {} },
        currentFYActuals: { value: {} },
        currentFYForecast: { value: {} },
    };
    const budgetEntityKeys: (keyof ProjectBudgetEntity)[] = ['total', 'capex', 'opex'];
    const programProjectsLiveReports = allProjectLiveReports.filter((r) =>
        projects.some((p) => p.id === r.projectId)
    );
    const budget: ProjectStatusReportBudget = {
        approvedBudget: programProjectsLiveReports.reduce(
            (acc, curr) => acc + (curr.budget?.approvedBudget ?? 0),
            0
        ),
        approvedCurrentFY: programProjectsLiveReports.reduce(
            (acc, curr) => acc + (curr.budget?.approvedCurrentFY ?? 0),
            0
        ),
        ...emptyBudgetBase,
    };
    Object.keys(emptyBudgetBase).forEach((budgetKey: keyof ProjectBudgetBase) =>
        budgetEntityKeys.forEach(
            (entityKey) =>
                (budget[budgetKey].value[entityKey] = getBudgetSum(
                    programProjectsLiveReports,
                    budgetKey,
                    entityKey
                ))
        )
    );
    return new ProgramStatusReport({
        id: null,
        programId,
        name: 'Live Report',
        health: {
            overall: OverallStatus.Green,
            scope: OverallStatus.Green,
            schedule: OverallStatus.Green,
            budget: OverallStatus.Green,
            stepsToGreen: '',
        },
        budget,
        topRisks: programProjectsLiveReports
            .map(
                (report) =>
                    report.topRisks?.filter((r) => r.impact === LowMediumHighStatus.High) || []
            )
            .flat(),
        topIssues: programProjectsLiveReports
            .map(
                (report) =>
                    report.topIssues?.filter((i) => i.impact === LowMediumHighStatus.High) || []
            )
            .flat(),
        majorMilestones: programProjectsLiveReports
            .map((report) => {
                const project = projects.find((p) => p.id === report.projectId);
                return report.majorMilestones.map(
                    (m) =>
                        ({
                            ...m,
                            projectId: report.projectId,
                            projectName: project?.name,
                            projectColor: project?.color,
                        } as ProjectMajorMilestone)
                );
            })
            .flat(),
        projectStatuses: programProjectsLiveReports
            .map((report) => {
                const project = projects.find((p) => p.id === report.projectId);
                if (!report) return null;
                const overview = projectOverviews.find((o) => o.projectId === project.id);
                const projectStatus: ProgramStatusReportProject = {
                    projectId: report.projectId,
                    projectName: project.name,
                    projectNumber: project.number,
                    projectColor: project.color,
                    budget: project.budget,
                    startDate: project.startDate,
                    endDate: project.endDate,
                    scheduledStartDate: project.scheduledStartDate,
                    scheduledEndDate: project.scheduledEndDate,
                    progress: project.progressPercent ?? 0,
                    overallStatus: project?.overallStatus,
                    state: project.state,
                    phaseName: projectsCurrentPhaseMap[project.id]?.name,
                    totalApprovedBudget: overview?.totalApprovedBudget,
                    totalApprovedCurrentFY: overview?.totalApprovedCurrentFY,
                    delivery: project.delivery,
                    summary: report.summary,
                    planned: report.planned,
                };
                return projectStatus;
            })
            .filter((s) => !!s),
        projectManagerNamesMap: projectManagerNamesMap,
        totalApprovedBudget: projectOverviews.reduce(
            (acc, curr) => acc + curr.totalApprovedBudget,
            0
        ),
        totalApprovedCurrentFY: projectOverviews.reduce(
            (acc, curr) => acc + curr.totalApprovedCurrentFY,
            0
        ),
        isLive: true,
    });
}

export interface OverridableReportValue<T> {
    overrided?: boolean;
    value: T;
}
