import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ExtendedProjectMemberAllocation } from './models';

export interface State extends EntityState<ExtendedProjectMemberAllocation>, LoadingStatus {}

export interface LoadingStatus {
    serverRequestInProgress: boolean;
    loaded: boolean;
}

export const adapter: EntityAdapter<ExtendedProjectMemberAllocation> =
    createEntityAdapter<ExtendedProjectMemberAllocation>();

export const initialState: State = adapter.getInitialState({
    serverRequestInProgress: false,
    loaded: false,
});
