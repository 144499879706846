import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getAsyncUpdateStatus } from '../shared/async-update-status.store';
import { fullCrudPermissions } from '../shared/models/shared-permissions.model';
import { StateEntry } from '../state-entry';
import { AccountAdminRole } from './account.model';
import { State } from './account.store';

const selectAccountEntry = createFeatureSelector<State>(StateEntry.Account);

export const selectUserAccount = createSelector(
    selectAccountEntry,
    (state: State) => state.userAccount
);

export const selectUserAvatar = createSelector(selectAccountEntry, (state: State) => state.avatar);

export const selectLoadingStatus = createSelector(selectAccountEntry, getAsyncUpdateStatus);

export const selectSystemAdminData = createSelector(
    selectAccountEntry,
    (state: State) => state.systemAdminData
);

export const selectSystemAdminDataLoadingStatus = createSelector(
    selectAccountEntry,
    (state) => state.systemAdminDataLoadingStatus
);

export const selectUserCurrentAppMessage = createSelector(
    selectAccountEntry,
    selectUserAccount,
    (state, account) =>
        !account?.processedAppMessageIds?.includes(state.appMessage?.id) ? state.appMessage : null
);

export const selectAccountSystemAdminPermissions = createSelector(selectUserAccount, (account) => {
    switch (account.adminRole) {
        case AccountAdminRole.FullAccess:
            return fullCrudPermissions;

        case AccountAdminRole.ReadOnly:
            return { read: true };

        case AccountAdminRole.NoAccess:
            return {};
    }
});
