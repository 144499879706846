import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityLevel } from 'app/core/models/shared-models';
import { GlTableSettingsSelectors } from '../gl-table-settings';
import { IssueSelectors } from '../issue';
import { ProjectSelectors } from '../project';
import { ProjectPhaseSelectors } from '../project-phase';
import { ListContainerSelectors } from '../project-phase-container';
import { getCurrentPhase, getOrderedPhases } from '../project-phase/project-phase.selectors';
import { RiskSelectors } from '../risks';
import { ScheduleTaskSelectors } from '../schedule-template';
import { selectCurrentSiteId } from '../site/site.selectors';
import { SprintSelectors } from '../sprint';
import { StateEntry } from '../state-entry';
import { ListContainerType } from './../project-phase-container/models/list-container.model';
import { ProjectBudgetEntity } from './../project/project.model';
import { getLiveProjectStatusReport } from './models';
import {
    OverridableValue,
    ProjectStatusReport,
    ProjectStatusReportBudget,
} from './models/project-status-report.model';
import { adapter, State } from './project-status-report.store';

export const selectStatusReportEntry = createFeatureSelector<State>(StateEntry.ProjectStatusReport);

export const selectLoadingStatusesPerSiteId = createSelector(
    selectStatusReportEntry,
    (state) => state.loadingStatusBySiteId
);

export const selectLoadingStatusForCurrentSite = createSelector(
    selectLoadingStatusesPerSiteId,
    selectCurrentSiteId,
    (statusMap, siteId) => statusMap[siteId]
);

export const selectLoadingStatusByProjectIdMap = createSelector(
    selectStatusReportEntry,
    (state) => state.loadingStatusByProjectId
);

export const selectLoadingStatusForCurrentProject = createSelector(
    selectLoadingStatusByProjectIdMap,
    ProjectSelectors.selectCurrentProjectId,
    (statusMap, projectId) => statusMap[projectId]
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectStatusReportEntry);

const risksIssuesCombinedSelector = createSelector(
    RiskSelectors.selectAll,
    IssueSelectors.selectAll,
    (risks, issues) => ({ risks, issues })
);

const risksIssuesStatusListMapCombinedSelector = createSelector(
    RiskSelectors.selectCurrentSiteRisksStatusListMap,
    IssueSelectors.selectCurrentSiteIssuesStatusListMap,
    (riskStatusesMap, issueStatusesMap) => ({ riskStatusesMap, issueStatusesMap })
);

const selectPojectsAndSettings = createSelector(
    ProjectSelectors.selectAll,
    ProjectSelectors.selectProjectSettingsMap,
    (projects, settingsMap) => ({ projects, settingsMap })
);

export const selectAllProjectsLiveTimeReports = createSelector(
    selectPojectsAndSettings,
    ProjectPhaseSelectors.selectAll,
    ListContainerSelectors.selectAll,
    SprintSelectors.selectAllActiveSprints,
    risksIssuesCombinedSelector,
    risksIssuesStatusListMapCombinedSelector,
    ScheduleTaskSelectors.selectAll,
    GlTableSettingsSelectors.selectAll,
    (
        { projects, settingsMap },
        phases,
        listContainers,
        sprints,
        { risks, issues },
        { riskStatusesMap, issueStatusesMap },
        scheduleTasks,
        allGlTableSettings
    ) =>
        projects.map((project) => {
            const phasesContainer = listContainers.find(
                (c) =>
                    c.scope.id === project.id &&
                    c.scope.level === EntityLevel.Project &&
                    ListContainerType.Gating
            );
            const projectPhases = phases.filter((p) => p.containerId === phasesContainer?.id);
            const orderedPhases = getOrderedPhases(phasesContainer, projectPhases);
            const currentPhase = getCurrentPhase(orderedPhases);
            const projectSprints = sprints.filter((s) => s.projectId === project.id);
            const scheduleContainer = listContainers.find(
                (c) =>
                    c.scope.id === project.id &&
                    c.scope.level === EntityLevel.Project &&
                    c.containerType === ListContainerType.Schedule
            );
            const projectScheduleTasks = scheduleTasks.filter(
                (t) => t.listContainerId === scheduleContainer?.id
            );
            const scheduleTableSettings = allGlTableSettings.find(
                (s) => s.listContainerId === scheduleContainer?.id
            );
            const liveTimeReport = getLiveProjectStatusReport(
                project,
                settingsMap[project.id],
                currentPhase?.name,
                projectSprints.map((s) => s.name),
                risks,
                riskStatusesMap[project.id],
                issues,
                issueStatusesMap[project.id],
                projectScheduleTasks,
                scheduleTableSettings
            );
            return liveTimeReport;
        })
);

export const selectCurrentProjectLiveTimeReport = createSelector(
    selectAllProjectsLiveTimeReports,
    ProjectSelectors.selectCurrentProjectId,
    (projectsLiveReports, currentProjectId) => {
        return projectsLiveReports.find((r) => r.projectId === currentProjectId);
    }
);

export const selectAllProjectsConfiguredLiveReports = createSelector(
    selectAll,
    selectAllProjectsLiveTimeReports,
    (storedReports, liveTimeReports) => {
        return liveTimeReports.map((liveTimeReport) => {
            const storedLiveReport = storedReports.find(
                (r) => r.projectId === liveTimeReport.projectId && r.isLive
            );
            return getProjectLiveReport(storedLiveReport, liveTimeReport);
        });
    }
);

export const selectLiveTimeReportByProjectId = (projectId: string) =>
    createSelector(selectAllProjectsConfiguredLiveReports, (reports) =>
        reports.find((r) => r.projectId === projectId)
    );

export const selectSavedStatusReportsByProjectId = (projectId: string) =>
    createSelector(selectAll, (reports) =>
        reports.filter((r) => r.projectId === projectId && !r.isLive)
    );

export const selectCurrentProjectSavedStatusReports = createSelector(
    ProjectSelectors.selectCurrentProjectId,
    selectAll,
    (projectId, reports) => reports.filter((r) => r.projectId === projectId && !r.isLive)
);

export const selectCurrentProjectSavedLiveReport = createSelector(
    selectAll,
    ProjectSelectors.selectCurrentProjectId,
    (reports, projectId) => reports.find((r) => r.projectId === projectId && r.isLive)
);

export const selectCurrentProjectConfiguredLiveReport = createSelector(
    selectAllProjectsConfiguredLiveReports,
    ProjectSelectors.selectCurrentProjectId,
    (reports, projectId) => reports.find((r) => r.projectId === projectId && r.isLive)
);

function getProjectLiveReport(
    storedReport: ProjectStatusReport,
    liveTimeReport: ProjectStatusReport
): ProjectStatusReport {
    if (!storedReport) return liveTimeReport;
    const modifiedStoredReport: ProjectStatusReport = {
        ...storedReport,
        schedule: {
            ...storedReport.schedule,
            progress: storedReport.schedule?.progress?.overrided
                ? storedReport.schedule.progress
                : liveTimeReport.schedule.progress,
            currentGate: liveTimeReport.schedule.currentGate,
            activeSprints: liveTimeReport.schedule.activeSprints,
            scheduledEndDate: storedReport.schedule?.scheduledEndDate?.overrided
                ? storedReport.schedule?.scheduledEndDate
                : liveTimeReport.schedule?.scheduledEndDate,
            plannedEndDate: storedReport.schedule?.plannedEndDate?.overrided
                ? storedReport.schedule?.plannedEndDate
                : liveTimeReport.schedule?.plannedEndDate,
        },
        budget: {
            ...storedReport.budget,
            hidden: false,
            approvedBudget: liveTimeReport.budget?.approvedBudget,
            approvedCurrentFY: liveTimeReport.budget?.approvedCurrentFY,
            plannedCost: {
                ...storedReport.budget.plannedCost,
                value: getCurrentProjectStatusReportBudgetValue(
                    storedReport.budget.plannedCost,
                    liveTimeReport.budget.plannedCost
                ),
            },
            actualToDate: {
                ...storedReport.budget.actualToDate,
                value: getCurrentProjectStatusReportBudgetValue(
                    storedReport.budget.actualToDate,
                    liveTimeReport.budget.actualToDate
                ),
            },
            currentFYActuals: {
                ...storedReport.budget.currentFYActuals,
                value: getCurrentProjectStatusReportBudgetValue(
                    storedReport.budget.currentFYActuals,
                    liveTimeReport.budget.currentFYActuals
                ),
            },
            currentFYForecast: {
                ...storedReport.budget.currentFYForecast,
                value: getCurrentProjectStatusReportBudgetValue(
                    storedReport.budget.currentFYForecast,
                    liveTimeReport.budget.currentFYForecast
                ),
            },
        } as Required<ProjectStatusReportBudget>,
        topIssues: liveTimeReport.topIssues,
        topRisks: liveTimeReport.topRisks,
        timeline: { hidden: storedReport.timeline?.hidden, tasks: liveTimeReport.timeline?.tasks },
    };
    return modifiedStoredReport;
}

export function getCurrentProjectStatusReportBudgetValue(
    storedValue: OverridableValue<ProjectBudgetEntity>,
    liveValue: OverridableValue<ProjectBudgetEntity>
): ProjectBudgetEntity {
    if (storedValue?.overrided) return storedValue.value;
    return liveValue.value;
}
