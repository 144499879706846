import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import { deepClone } from 'fast-json-patch';
import {
    AsyncUpdateStatusDefault,
    AsyncUpdateStatusDefaultLoad,
    AsyncUpdateStatusDefaultLoadSuccess,
} from '../shared/async-update-status.store';
import * as KanbanBoardActions from './kanban.actions';
import { adapter, initialState } from './kanban.store';
import { KanbanBoardShape } from './models';

export const reducer = createReducer(
    initialState,

    on(KanbanBoardActions.loadKanbanBoardsByProjectId, (state, action) => {
        const loadingStatusMap = state.loadingStatusByProjectId;
        const projectId = action.payload.projectId;

        return {
            ...state,
            loadingStatusByProjectId: {
                ...loadingStatusMap,
                [projectId]: {
                    ...(loadingStatusMap[projectId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoad,
                },
            },
        };
    }),

    on(KanbanBoardActions.loadKanbanBoardsByProjectIdSuccess, (state, payload) => {
        const removeIds = Object.values(state.entities)
            .filter((b) => b.projectId === payload.projectId)
            .map((b) => b.id);
        const newState = adapter.addMany(payload.boards, adapter.removeMany(removeIds, state));
        const loadingStatusMap = state.loadingStatusByProjectId;
        const projectId = payload.projectId;

        return {
            ...newState,
            loadingStatusByProjectId: {
                ...loadingStatusMap,
                [projectId]: {
                    ...(loadingStatusMap[projectId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                },
            },
        };
    }),

    on(KanbanBoardActions.loadKanbanBoardsByProjectIdFail, (state, payload) => {
        const loadingStatusMap = state.loadingStatusByProjectId;
        const projectId = payload.projectId;

        return {
            ...state,
            loadingStatusByProjectId: {
                ...loadingStatusMap,
                [projectId]: {
                    ...(loadingStatusMap[projectId] ?? {}),
                    ...AsyncUpdateStatusDefault,
                    error: payload.error,
                    loadingFailed: true,
                },
            },
        };
    }),

    on(KanbanBoardActions.loadKanbanBoardsBySiteId, (state, action) => {
        const loadingStatusMap = state.loadingStatusBySiteId;
        const siteId = action.payload.siteId;

        return {
            ...state,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoad,
                },
            },
        };
    }),

    on(KanbanBoardActions.loadKanbanBoardsBySiteIdSuccess, (state, payload) => {
        const projectIds = payload.boards.map((b) => b.projectId);
        const removeIds = Object.values(state.entities)
            .filter((b) => projectIds.includes(b.projectId))
            .map((b) => b.id);
        const newState = adapter.addMany(payload.boards, adapter.removeMany(removeIds, state));
        const loadingStatusMap = state.loadingStatusBySiteId;
        const siteId = payload.siteId;
        const loadingByProjectIdMap = deepClone(state.loadingStatusByProjectId);
        projectIds.forEach(
            (id) =>
                (loadingByProjectIdMap[id] = {
                    ...(loadingByProjectIdMap[id] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                })
        );

        return {
            ...newState,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                },
            },
            loadingStatusByProjectId: loadingByProjectIdMap,
        };
    }),

    on(KanbanBoardActions.loadKanbanBoardsBySiteIdFail, (state, payload) => {
        const loadingStatusMap = state.loadingStatusBySiteId;
        const siteId = payload.siteId;

        return {
            ...state,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefault,
                    error: payload.error,
                    loadingFailed: true,
                },
            },
        };
    }),

    on(KanbanBoardActions.patchKanbanBoard, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patched = getPatched(state.entities[action.payload.id], action.payload.patch);
        const update: Update<KanbanBoardShape> = {
            id: patched.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(KanbanBoardActions.patchKanbanBoardSuccess, (state, payload) => {
        const patched = getPatched(state.entities[payload.id], payload.patch);
        const update: Update<KanbanBoardShape> = {
            id: patched.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(KanbanBoardActions.addKanbanBoard, (state, payload) => adapter.addOne(payload.board, state))
);
