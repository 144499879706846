import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import {
    AddCommentPayload,
    CommentIdPayload,
    PatchCommentPayload,
} from '../../../../store/comment/comment.payloads';
import { LoadBySiteIdPayload } from '../../../../store/shared/models/load.payload';

export const loadCommentsBySiteIdCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'comments' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addCommentCommand = (payload: AddCommentPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'comments' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchCommentCommand = (payload: PatchCommentPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'comments', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteCommentCommand = (payload: CommentIdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'comments', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};
