import {
    CtxColor,
    ProjectBudgetBase,
    ProjectBudgetEntityBase,
} from 'app/core/models/shared-models';
import { SiteEntityState } from 'app/core/models/site-entity.model';
import { OverallStatus } from '../program';
import { Model } from '../shared/models/base.model';
import { UserTaskType } from '../shared/user-task.model';

export class Project extends Model {
    // All initialized fields are required
    id = '';
    siteId = '';
    programId: string;
    name = '';
    overallStatus = OverallStatus.Green;
    overallStatusHistoricalValues: OverallStatusHistoricalValue[];
    scheduleStatus = OverallStatus.Green;
    budgetStatus = OverallStatus.Green;
    state = SiteEntityState.Open;
    progressPercent = 0;
    archived = false;
    deleted = false;
    tripleConstraint: TripleConstraintCoords = undefined;
    delivery: ProjectDelivery = ProjectDelivery.Traditional;
    customFieldValuesMap: Record<string, string> = {};

    // Budget values
    budgetCode: string;
    budget: ProjectBudget;
    liveBudget: ProjectBudget;

    description: string;
    scopeDescription: ScopeDescriptions;
    assumptionsDescription: AssumptionsDescriptions;
    closure: ClosureDescriptions;
    dateArchived: Date;
    dateDeleted: Date;
    creationDate: Date;
    objective: string;
    color: CtxColor;
    startDate?: Date;
    endDate?: Date;
    plannedEndDate?: Date;
    scheduledEndDate?: Date;
    calculatedScheduledEndDate?: Date;
    scheduledStartDate?: Date;
    number: string;
    priorityItemId: string;
    year: number;

    settings: ProjectSettings;

    constructor(init?: Partial<Project>) {
        super();
        Object.assign(this, init);
    }
}

export interface OverallStatusHistoricalValue {
    date: Date;
    status: OverallStatus;
}

export const projectOverallStatusColorMap: Record<OverallStatus, CtxColor> = {
    [OverallStatus.Green]: CtxColor.Green,
    [OverallStatus.Yellow]: CtxColor.Yellow,
    [OverallStatus.Red]: CtxColor.Red,
};

export interface TripleConstraintCoords {
    x?: number;
    y?: number;
}

export interface ScopeDescriptions {
    inScope: string;
    outOfScope: string;
}

export interface AssumptionsDescriptions {
    assumptions: string;
    constraints: string;
}

export interface ClosureDescriptions {
    wentWell: string;
    needsImprovement: string;
    rating: number;
}

export class ProjectBudget implements ProjectBudgetBase {
    hidden?: boolean;
    approvedBudget: number;
    approvedCurrentFY: number;
    plannedCost: ProjectBudgetEntity;
    actualToDate: ProjectBudgetEntity;
    currentFYForecast: ProjectBudgetEntity;
    currentFYActuals: ProjectBudgetEntity;
    monthlyBudgets?: MonthlyBudget[];
}

export class MonthlyBudget {
    budgetAssignedItemId: string;
    month: number;
    year: number;
    forecast: ProjectBudgetEntity;
    actuals: ProjectBudgetEntity;
}

export class ProjectBudgetEntity implements ProjectBudgetEntityBase {
    total: number;
    capex: number;
    opex: number;
}

export const projectBudgetEntityKeys: (keyof ProjectBudgetEntity)[] = ['total', 'capex', 'opex'];

export interface ProjectSettingOptions {
    hideCapexOpex?: boolean;
    allowManualOverrideStatusData?: boolean;
}

export class ProjectSettings {
    id: string;
    projectId: string;
    hiddenPages: ProjectHiddenPage;
    options: ProjectSettingOptions;
    lockedPages: ProjectLockedPage;
    visibleInCalendarTasks: UserTaskType[];
    taskTypeColorsInCalendar?: UserTaskTypeColor[];

    constructor(init?: Partial<ProjectSettings>) {
        Object.assign(this, init);
    }
}

export interface UserTaskTypeColor {
    type: UserTaskType;
    color: CtxColor;
}

export interface ProjectPageBase {
    members?: boolean;
    status?: boolean;
    schedule?: boolean;
    budget?: boolean;
    gating?: boolean;
    changeRequests?: boolean;
    vendors?: boolean;
    minutes?: boolean;
    documents?: boolean;
    summary?: boolean;
    risks?: boolean;
    actions?: boolean;
    issues?: boolean;
    decisions?: boolean;
}

export interface ProjectLockedPage extends ProjectPageBase {
    home?: boolean;
    backlog?: boolean;
    sprints?: boolean;
    // risks?: boolean;
    // actions?: boolean;
    // issues?: boolean;
    // decisions?: boolean;
    settings?: boolean;
}

export interface ProjectHiddenPage extends ProjectPageBase {
    agile?: boolean;
    // raid?: boolean;
}

export enum ProjectDelivery {
    Traditional = 'Traditional',
    Agile = 'Agile',
}

export interface ProjectRaciTable {
    projectId: string;
    data: string;
}

// ProjectLockedPage default values

export const defaultProjectSettingOptions: Required<ProjectSettingOptions> = {
    hideCapexOpex: false,
    allowManualOverrideStatusData: false,
};

export const defaultProjectLockedPages: Required<ProjectLockedPage> = {
    home: false,
    members: false,
    status: false,
    schedule: false,
    backlog: false,
    sprints: false,
    budget: false,
    risks: false,
    actions: false,
    issues: false,
    decisions: false,
    gating: false,
    changeRequests: false,
    vendors: false,
    minutes: false,
    documents: false,
    settings: false,
    summary: false,
};

export const defaultProjectHiddenPages: Required<ProjectHiddenPage> = {
    members: false,
    status: false,
    schedule: false,
    agile: false,
    budget: false,
    // raid: false,
    risks: false,
    actions: false,
    issues: false,
    decisions: false,
    gating: false,
    changeRequests: false,
    vendors: false,
    minutes: false,
    documents: false,
    summary: false,
};
