import { Action, createAction, props } from '@ngrx/store';
import { LoadBySiteIdPayload } from '../shared/models/load.payload';
import { IdPayload } from '../shared/models/shared-payloads';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import { SiteMember } from './models';
import {
    InviteSiteMemberPayload,
    LoadMembersBySiteIdPayload,
    PatchSiteMemberPayload,
    SiteMemberIdPayload,
    SiteMemberMultiIdsPayload,
    UpdateSiteMemberPayload,
} from './site-member.payloads';

// Load SiteMembers actions

export const loadMembersBySiteId = createAction(
    '[SiteMember] Load SiteMembers by site id',
    props<{ payload: LoadMembersBySiteIdPayload }>()
);

export const loadSiteMembersSuccess = createAction(
    '[SiteMember] Load SiteMembers success',
    props<{ siteMembers: SiteMember[] }>()
);

export const loadSiteMembersFail = createAction(
    '[SiteMember] Load SiteMembers fail',
    props<{ error: any }>()
);

// Load My SiteMember actions

export const loadMySiteMember = createAction(
    '[SiteMember] Load My SiteMember',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadMySiteMemberSuccess = createAction(
    '[SiteMember] Load My SiteMember success',
    props<{ siteMember: SiteMember }>()
);

export const loadMySiteMemberFail = createAction(
    '[SiteMember] Load My SiteMember fail',
    props<{ error: any }>()
);

// Add SiteMember actions

export const inviteSiteMembersToSite = createAction(
    '[SiteMembers] Invite',
    props<{ payload: InviteSiteMemberPayload }>()
);

export const inviteSiteMembersToSiteSuccess = createAction(
    '[SiteMembers] Invite success',
    props<{ members: SiteMember[] }>()
);

export const inviteSiteMembersToSiteFail = createAction(
    '[SiteMembers] Invite fail',
    props<{ error: any }>()
);

// Update SiteMember actions

export const updateSiteMember = createAction(
    '[SiteMember] Update',
    props<{ payload: UpdateSiteMemberPayload; options?: OptimisticableActionOptions }>()
);

// Patch SiteMember actions

export const patchSiteMember = createAction(
    '[SiteMember] Patch',
    props<{ payload: PatchSiteMemberPayload; options?: OptimisticableActionOptions }>()
);

export const patchSiteMemberSuccess = createAction(
    '[SiteMember] Patch success',
    props<PatchSiteMemberPayload>()
);

export const patchSiteMemberFail = createAction(
    '[SiteMember] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Delete SiteMember actions

export const deleteSiteMember = createAction(
    '[SiteMember] Delete',
    props<{ payload: SiteMemberIdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteSiteMemberSuccess = createAction(
    '[SiteMember] Delete success',
    props<IdPayload>()
);

export const deleteSiteMemberFail = createAction(
    '[SiteMember] Delete fail',
    props<{ error: any; originAction: Action }>()
);

// Delete many SiteMembers actions

export const deleteManySiteMembers = createAction(
    '[Many SiteMembers] Delete',
    props<{ payload: SiteMemberMultiIdsPayload; options?: OptimisticableActionOptions }>()
);

export const resendInvite = createAction(
    '[SiteMembers] Resend Invite',
    props<{ payload: IdPayload }>()
);
