import { Model } from 'app/core/store/shared/models/base.model';
import { Amount } from 'app/utils/amount';
import { JsonProperty } from 'app/utils/json-mapper';
import { BillingDetails } from './billing-details.model';
import { Card } from './card.model';
import { PaymentInfo } from './payment-info.model';
import { SubscriptionBaseModel, SubscriptionPlanTag } from './subscription-base.model';
import {
    PaymentMethod,
    SubscriptionPlanProration,
    SubscriptionPlanType,
} from './subscription-plan.model';

export class Subscription extends SubscriptionBaseModel {
    @JsonProperty('siteId')
    siteId: string = undefined;

    @JsonProperty('subscriptionPlanId')
    subscriptionPlanId: string = undefined;

    @JsonProperty('subscriptionBasePlanId')
    subscriptionBasePlanId: string = undefined;

    @JsonProperty('status')
    status: SubscriptionStatus = undefined;

    @JsonProperty('currentPeriodStart')
    currentPeriodStart: Date = undefined;

    @JsonProperty('currentPeriodEnd')
    currentPeriodEnd: Date = undefined;

    @JsonProperty({ name: 'paymentInfo', clazz: PaymentInfo })
    paymentInfo: PaymentInfo = undefined;

    @JsonProperty('taxPercent')
    taxPercent?: number = undefined;

    @JsonProperty('start')
    start?: Date = undefined;

    @JsonProperty('quantity')
    quantity?: number = undefined;

    @JsonProperty('endedAt')
    endedAt?: Date = undefined;

    @JsonProperty('daysUntilDue')
    daysUntilDue?: number = undefined;

    @JsonProperty('created')
    created?: Date = undefined;

    @JsonProperty('canceledAt')
    canceledAt?: Date = undefined;

    @JsonProperty('cancelAtPeriodEnd')
    cancelAtPeriodEnd?: boolean = undefined;

    @JsonProperty('additionalSubscriptions')
    additionalSubscriptions?: Subscription[] = undefined;

    @JsonProperty('proration')
    proration?: SubscriptionPlanProration = undefined;

    constructor(init?: Partial<Subscription>) {
        super();
        Object.assign(this, init);
    }

    isExpired(): boolean {
        return new Date().getTime() > new Date(this.currentPeriodEnd).getTime();
    }

    isTrialing(): boolean {
        return this.status === SubscriptionStatus.Trialing;
    }

    isGrace(): boolean {
        return this.planType === SubscriptionPlanType.Grace;
    }

    getDaysToExpiration(): number {
        const getDateWithoutTime = (date: Date) => {
            const validDate = new Date(date);
            return new Date(validDate.getFullYear(), validDate.getMonth(), validDate.getDate());
        };

        const today = getDateWithoutTime(new Date());
        const expDay = getDateWithoutTime(this.currentPeriodEnd);
        const daysBetween = Math.round((expDay.getTime() - today.getTime()) / (1000 * 3600 * 24));
        return Math.max(0, daysBetween);
    }

    getPaymentMethod(): PaymentMethod {
        return (
            this.paymentInfo &&
            this.paymentInfo.paymentDetails &&
            this.paymentInfo.paymentDetails.paymentMethod
        );
    }

    getCardText(card: Card): string {
        if (!card) return '';

        const brand = card.brand;
        const number = `••••${card.last4}`;
        const expiration = (card.expMonth + '').padStart(2, '0') + '/' + card.expYear;
        return `${brand}   ${number}   ${expiration}`;
    }

    getPaymentMethodText(): string {
        switch (this.getPaymentMethod()) {
            case PaymentMethod.ACH:
                return 'ACH';
            case PaymentMethod.Cash:
                return 'Cach';
            case PaymentMethod.Cheque:
                return 'Cheque';
            case PaymentMethod.CreditCard:
                return this.getCardText(this.paymentInfo.paymentDetails.card);
            case PaymentMethod.WireTransfer:
                return 'Wire transfer';
            case PaymentMethod.Other:
            default:
                return 'Payment method: Other';
        }
    }

    getTotalAmount(): string {
        if (!this.amount || !this.currency) return '0';
        const amount = new Amount(this.getTotalAmountCents(), this.currency);
        return amount.toString();
    }

    getPaidQuantity(): number {
        switch (this.planType) {
            case SubscriptionPlanType.PerProject:
            case SubscriptionPlanType.PerUser:
                return this.quantity;
            case SubscriptionPlanType.FixedPrice:
                return 1;
            default:
                return 0;
        }
    }

    getTotalAmountCents(): number {
        return this.amount * this.getPaidQuantity();
    }

    hasExtendedSupport(): boolean {
        return this.additionalSubscriptions?.some((s) =>
            s.tags?.includes(SubscriptionPlanTag.ExtendedSupport)
        );
    }
}

export interface SubscriptionCreateOptions extends BillingDetails {
    subscriptionPlanId: string;
    additionalSubscriptionPlanIds: string[];
    totalAmount: number;
    ccToken?: string;
    paymentMethod?: PaymentMethod;
    orderedUsersQuantity?: number;
    orderedProjectsQuantity?: number;
}

export class SubscriptionUpdateOptions extends BillingDetails {
    ccToken: string;
}

export enum SubscriptionStatus {
    Trialing = 'trialing',
    Active = 'active',
    PastDue = 'pastDue',
    Canceled = 'canceled',
    Unpaid = 'unpaid',
}

export function getSubscriptionFromDto(subscriptionDto: any): Subscription {
    if (subscriptionDto instanceof Subscription) return subscriptionDto;
    const subscription = Model.createFromDto(Subscription, subscriptionDto);
    const additionalSubscriptions = subscriptionDto.additionalSubscriptions?.map(
        (dto) => new Subscription(Model.createFromDto(Subscription, dto))
    );
    return new Subscription({ ...subscription, additionalSubscriptions });
}
