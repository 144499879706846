import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectSelectors } from 'app/core/store/project';
import { AsyncUpdateStatus } from '../shared/async-update-status.store';
import { StateEntry } from '../state-entry';
import { adapter, State } from './folder.store';
import { FolderTag } from './models';

export const selectProjectRoleEntry = createFeatureSelector<State>(StateEntry.Folder);

export const selectLoadingStatus = createSelector(
    selectProjectRoleEntry,
    (state): AsyncUpdateStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
        loadingFailed: state.loadingFailed,
        error: state.error,
    })
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectProjectRoleEntry);

export const selectCurrentProjectFoldersByTag = (tag: FolderTag) =>
    createSelector(selectAll, ProjectSelectors.selectCurrentProjectId, (folders, projectId) =>
        folders.filter((f) => f.tag === tag && f.projectId === projectId)
    );
