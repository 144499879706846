import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EntityPatchSuccessResponse } from 'app/core/services/rest-api/features/patch.response';
import { ProjectFieldSelectors, ProjectFieldType } from 'app/core/store/project-field';
import { SnackBarService } from 'app/services/snack-bar/snack-bar.service';
import { getPatch } from 'app/utils/json-patch';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    first,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { AppStore } from '..';
import { RestApiService } from '../../services/rest-api';
import { CtxFieldItemType } from '../project-field';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import * as ActionActions from './action.actions';
import * as ActionSelectors from './action.selectors';
import { ActionRecord } from './models/action.model';

@Injectable()
export class ActionEffects {
    getAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionActions.getAction),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        ActionActions.getActionSuccess({
                            action: this.getActionFromDto(response.payload),
                        })
                    ),
                    catchError((error) => of(ActionActions.getActionFail({ error })))
                )
            )
        )
    );

    loadActionBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionActions.loadActionsBySiteId),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        ActionActions.loadActionsBySiteIdSuccess({
                            actions: response.payload.map((dto) => this.getActionFromDto(dto)),
                        })
                    ),
                    catchError((error) => of(ActionActions.loadActionsBySiteIdFail({ error })))
                )
            )
        )
    );

    loadActionByProjectId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionActions.loadActionsByProjectId),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        ActionActions.loadActionsByProjectIdSuccess({
                            actions: response.payload.map((dto) => this.getActionFromDto(dto)),
                        })
                    ),
                    catchError((error) => of(ActionActions.loadActionsByProjectIdFail({ error })))
                )
            )
        )
    );

    addAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionActions.addAction),
            concatMap((action) => {
                if (!action.options?.optimistic) {
                    this.snackBar.showInfiniteBar('Adding a new Action...');
                }
                return this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        if (!action.options?.optimistic) this.snackBar.hide();

                        return ActionActions.addActionSuccess({
                            action: this.getActionFromDto(response.payload),
                            options: action.options,
                        });
                    }),
                    catchError((error) => {
                        this.snackBar.hide();
                        return of(
                            ActionActions.addActionFail({
                                error,
                                originAction: action,
                                actionId: action.payload.id,
                            })
                        );
                    })
                );
            })
        )
    );

    updateAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionActions.updateAction),
            withLatestFrom(
                this.store$.select(ActionSelectors.selectAll),
                this.store$.select(
                    ProjectFieldSelectors.selectCurrentSiteProjectFieldByType(
                        ProjectFieldType.ActionStatus
                    )
                )
            ),
            mergeMap(([action, actions, actionProjectField]) => {
                const changes: Partial<ActionRecord> = { ...action.payload.changes };
                const actionRecord = actions.find((r) => r.id === action.payload.id);
                const updatedActionRecord = { ...actionRecord, ...changes };
                const progressChangedToCompleted = action.payload.changes.progress === 100;
                const completedStatus = actionProjectField.items.find(
                    (s) => s.ctxItemType === CtxFieldItemType.Closed
                );
                if (
                    updatedActionRecord.statusId === completedStatus.id &&
                    changes.closingDate === null
                ) {
                    delete changes.closingDate;
                }
                if (action.payload.changes.closingDate) {
                    changes.statusId = completedStatus.id;
                    changes.progress = 100;
                }
                if (progressChangedToCompleted) {
                    changes.statusId = completedStatus.id;
                    changes.closingDate = new Date();
                } else if (
                    !isNaN(action.payload.changes.progress) &&
                    actionRecord.statusId === completedStatus.id
                ) {
                    const openStatus = actionProjectField.items.find(
                        (s) => s.ctxItemType === CtxFieldItemType.Open
                    );
                    changes.statusId = openStatus.id;
                    changes.closingDate = null;
                }
                if (
                    action.payload.changes.statusId &&
                    action.payload.changes.statusId !== completedStatus.id &&
                    actionRecord.statusId === completedStatus.id
                ) {
                    changes.closingDate = null;
                }
                if (action.payload.changes.statusId === completedStatus.id) {
                    changes.closingDate = new Date();
                    changes.progress = 100;
                }
                const patch = getPatch(actionRecord, changes);
                return of(
                    ActionActions.patchAction({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionActions.patchAction),
            filter((action) => !!action.payload.patch?.length),
            mergeMap((action) =>
                this.store$
                    .select(ActionSelectors.selectAddingInProgresIds(action.payload.id))
                    .pipe(
                        first((stored) => stored),
                        switchMap(() =>
                            this.restApiService.process(action).pipe(
                                map((response: EntityPatchSuccessResponse) => {
                                    if (action.options?.optimistic) return okEmptyAction();
                                    return ActionActions.patchActionSuccess({
                                        id: action.payload.id,
                                        patch: response.payload,
                                    });
                                }),
                                catchError((error) =>
                                    of(
                                        ActionActions.patchActionFail({
                                            error,
                                            originAction: action,
                                        })
                                    )
                                )
                            )
                        )
                    )
            )
        )
    );

    deleteAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionActions.deleteAction),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ActionActions.deleteActionSuccess({ id: action.payload.id });
                    }),
                    catchError((error) =>
                        of(ActionActions.deleteActionFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>,
        private snackBar: SnackBarService
    ) {}

    getActionFromDto(dto: any): ActionRecord {
        const model = Model.createFromDto(ActionRecord, dto);
        return {
            ...model,
            dueDate: model.dueDate ? new Date(model.dueDate) : undefined,
        };
    }
}
