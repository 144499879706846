import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProgramSelectors } from '../program';
import { ProjectSelectors } from '../project';
import { StateEntry } from '../state-entry';
import { State } from './layout.store';

export const selectLayoutEntry = createFeatureSelector<State>(StateEntry.Layout);

export const selectLoading = createSelector(
    selectLayoutEntry,
    (state) => state.serverRequestInProgress
);

export const selectCurrentLayoutSettings = createSelector(
    selectLayoutEntry,
    (state) => state.layoutSettings
);

export const selectLoadedLayoutSettings = createSelector(selectLayoutEntry, (state) =>
    state.loaded ? state.layoutSettings : null
);

export const selectSidebarOpened = createSelector(
    selectLayoutEntry,
    (state) => state.layoutSettings.sidebar?.opened
);

export const selectSidebarAnimationCompleted = createSelector(
    selectLayoutEntry,
    (state) => state.sidebarAnimationCompleted
);

export const selectPageName = createSelector(selectLayoutEntry, (state) => state.pageName);

export const selectProjectTreeLayout = createSelector(
    selectLayoutEntry,
    (state) => state.layoutSettings.sidebar?.projectTree
);

export const selectCtxEditorSettings = createSelector(
    selectLayoutEntry,
    (state) => state.layoutSettings.ctxTextEditor
);

export const selectLastOpenedRoute = createSelector(
    selectLayoutEntry,
    (state) => state.layoutSettings.lastOpenedRoute
);

export const selectCurrentProjectLayoutSettings = createSelector(
    selectLayoutEntry,
    ProjectSelectors.selectCurrentProjectId,
    (state, projectId) =>
        state.layoutSettings.projectsLayoutSettings?.find((s) => s.projectId === projectId)
);

export const selectCurrentProjectRaidSettings = createSelector(
    selectCurrentProjectLayoutSettings,
    (settings) => settings?.projectRaid
);

export const selectCurrentProgramLayoutSettings = createSelector(
    selectLayoutEntry,
    ProgramSelectors.selectCurrentProgramId,
    (state, programId) =>
        state.layoutSettings.programsLayoutSettings?.find((s) => s.programId === programId)
);

export const selectDashboardHiddenColumns = createSelector(
    selectCurrentLayoutSettings,
    (settings) => settings?.dashboardHiddenColumns
);
