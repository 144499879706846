import { Amount } from 'app/utils/amount';
import { JsonProperty } from 'app/utils/json-mapper';
import { InvoiceItem } from './invoice-item.model';
import { InvoicePayment } from './invoice-payment.model';
import { InvoiceTax } from './invoice-tax.model';

export class Invoice {
    @JsonProperty('id')
    id: number = undefined;

    @JsonProperty('number')
    number: string = undefined;

    @JsonProperty('issueDate')
    issueDate: Date = undefined;

    @JsonProperty('contactId')
    contactId: number = undefined;

    @JsonProperty('contactFullName')
    contactFullName: string = undefined;

    @JsonProperty('country')
    country: string = undefined;

    @JsonProperty('streetLine1')
    streetLine1: string = undefined;

    @JsonProperty('streetLine2')
    streetLine2: string = undefined;

    @JsonProperty('city')
    city: string = undefined;

    @JsonProperty('region')
    region: string = undefined;

    @JsonProperty('postalCode')
    postalCode: string = undefined;

    @JsonProperty('dueDate')
    dueDate: Date = undefined;

    @JsonProperty('currency')
    currency: string = undefined;

    @JsonProperty({ name: 'items', clazz: InvoiceItem })
    items: InvoiceItem[] = undefined;

    @JsonProperty('subtotalCents')
    subtotalCents: string = undefined;

    @JsonProperty('discountCents')
    discountCents: string = undefined;

    @JsonProperty({ name: 'taxes', clazz: InvoiceTax })
    taxes: InvoiceTax[] = undefined;

    @JsonProperty('totalCents')
    totalCents: number = undefined;

    @JsonProperty({ name: 'payments', clazz: InvoicePayment })
    payments: InvoicePayment[] = undefined;

    @JsonProperty('paymentDetails')
    paymentDetails: string = undefined;

    @JsonProperty('notes')
    notes: string = undefined;

    @JsonProperty('state')
    state: string = undefined;

    @JsonProperty('permalink')
    permaLink: string = undefined;

    @JsonProperty('pdf')
    pdfLink: string = undefined;

    constructor(init?: Partial<Invoice>) {
        Object.assign(this, init);
    }

    get issueDateString(): string {
        return this.issueDate.toLocaleDateString();
    }

    get totalAmountString(): string {
        const amount = new Amount(this.totalCents, this.currency);
        return amount.toString();
    }
}
