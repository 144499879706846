import { Action, createAction, props } from '@ngrx/store';
import { SubscriptionPlan } from 'app/core/models/billing';
import { IdPayload } from 'app/core/store/shared/models/shared-payloads';
import { LoadBySiteIdPayload } from '../shared/models/load.payload';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import { ErrorPayload } from '../shared/payloads';
import { Site, SiteMetrics } from './models';
import {
    AddSitePayload,
    ChangeSiteOwnerPayload,
    GetSubscriptionPlanByIdPayload,
    ManageSiteOwnerChangeApprovalPayload,
    PatchSitePayload,
    SiteIdPayload,
} from './site.payloads';

// Get sites actions

export const loadSites = createAction('[Site] Load');

export const loadSitesSuccess = createAction('[Site] Load success', props<{ sites: Site[] }>());

export const loadSitesFail = createAction('[Site] Load fail', props<{ error: any }>());

// Get current site

export const loadInitialSite = createAction('[Site] Load Initial site');

export const loadInitialSiteSuccess = createAction(
    '[Site] Load Initial site success',
    props<{ site: Site }>()
);

export const loadInitialSiteFail = createAction(
    '[Site] Load Initial site fail',
    props<{ error: any }>()
);

export const setCurrentSiteLoaded = createAction(
    '[Site] Set current site loaded',
    props<SiteIdPayload>()
);

// Get site roles

export const loadRolesBySite = createAction(
    '[Site] Load roles by site',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadRolesBySiteFail = createAction(
    '[Site] Load roles by site fail',
    props<{ error: any }>()
);

// Get site metrics

export const loadSiteMetrics = createAction(
    '[Site] Load Site Metrics',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadSiteMetricsSuccess = createAction(
    '[Site] Load Site Metrics success',
    props<{ siteMetrics: SiteMetrics }>()
);

export const loadSiteMetricsFail = createAction(
    '[Site] Load Site Metrics fail',
    props<{ error: any }>()
);

// Get site by Id

export const getSiteById = createAction('[Site] Get by id', props<{ payload: SiteIdPayload }>());

export const getSiteByIdSuccess = createAction('[Site] Get by id success', props<{ site: Site }>());

export const getSiteByIdFail = createAction('[Site] Get by id fail', props<{ error: any }>());

// Add site actions

export const addSite = createAction('[Site] Add', props<{ payload: AddSitePayload }>());

export const addSiteSuccess = createAction('[Site] Add success', props<{ site: Site }>());

export const addSiteFail = createAction('[Site] Add fail', props<{ error: any }>());

// Patch site actions

export const patchSite = createAction('[Site] Patch', props<{ payload: PatchSitePayload }>());

export const patchSiteSuccess = createAction('[Site] Patch success', props<PatchSitePayload>());

export const patchSiteFail = createAction('[Site] Patch fail', props<ErrorPayload>());

// Delete site actions

export const deleteSite = createAction('[Site] Delete', props<{ payload: SiteIdPayload }>());

export const deleteSiteSuccess = createAction('[Site] Delete success', props<SiteIdPayload>());

export const deleteSiteFail = createAction('[Site] Delete fail', props<ErrorPayload>());

// Get site subscription plans actions

export const getSubscriptionPlans = createAction(
    '[Site] Get subscription plans',
    props<{ payload: SiteIdPayload }>()
);

export const getSubscriptionPlansSuccess = createAction(
    '[Site] Get subscription plans success',
    props<{ plans: SubscriptionPlan[] }>()
);

export const getSubscriptionPlansFail = createAction(
    '[Site] Get subscription plans fail',
    props<ErrorPayload>()
);

// Get subscription plan by ID actions

export const getSubscriptionPlanById = createAction(
    '[Site] Get subscription plan by id',
    props<{ payload: GetSubscriptionPlanByIdPayload }>()
);

// Current site actions

export const changeCurrentSiteId = createAction(
    '[Site] Change current site ID',
    props<{ payload: SiteIdPayload }>()
);

// Change site owner actions

export const requestChangeSiteOwner = createAction(
    '[Site] Request Change Owner',
    props<{ payload: ChangeSiteOwnerPayload; options?: OptimisticableActionOptions }>()
);

export const requestChangeSiteOwnerFail = createAction(
    '[Site] Request Change Owner fail',
    props<{ error: any; originAction: Action }>()
);

export const cancelRequestChangeSiteOwner = createAction(
    '[Site] Cancel Request Change Owner',
    props<{ payload: SiteIdPayload; options?: OptimisticableActionOptions }>()
);

export const cancelRequestChangeSiteOwnerFail = createAction(
    '[Site] Cancel Request Change Owner fail',
    props<{ error: any; originAction: Action }>()
);

export const acceptRequestChangeSiteOwner = createAction(
    '[Site] Accept Request Change Owner',
    props<{ payload: SiteIdPayload }>()
);

export const acceptRequestChangeSiteOwnerSuccess = createAction(
    '[Site] Accept Request Change Owner success',
    props<SiteIdPayload>()
);

export const acceptRequestChangeSiteOwnerFail = createAction(
    '[Site] Accept Request Change Owner fail',
    props<{ error: any }>()
);

export const manageSiteOwnerChangeApproval = createAction(
    '[Decisions] Manage Site owner change Approval',
    props<{ payload: ManageSiteOwnerChangeApprovalPayload }>()
);

// Set signalr connection id

export const setSignalrConnectionId = createAction(
    '[Site] Set signalr connection id',
    props<IdPayload>()
);
