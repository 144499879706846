import { Action, createAction, props } from '@ngrx/store';
import { ImportSystemTemplatePayload } from 'app/core/models/shared-models';
import { IdPayload } from 'app/core/store/shared/models/shared-payloads';
import { OptimisticableActionOptions } from 'app/core/store/shared/optimisticable-action';
import { LoadByProjectIdPayload, LoadBySiteIdPayload } from './../shared/models/load.payload';
import {
    AddListContainerPayload,
    ChangeListContainerTemplatePayload,
    PatchListContainerPayload,
    UpdateListContainerPayload,
} from './list-container.payloads';
import { ListContainer } from './models';

// Load ListContainers by site actions

export const loadSystemListContainers = createAction('[ListContainer] Load System templates');

export const loadSystemListContainersSuccess = createAction(
    '[ListContainer] Load System templates success',
    props<{ containers: ListContainer[] }>()
);

export const loadSystemListContainersFail = createAction(
    '[ListContainer] Load System templates fail',
    props<{ error: any }>()
);

export const loadSystemListContainersBySiteId = createAction(
    '[ListContainer] Load System templates by site Id',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadSystemListContainersBySiteIdSuccess = createAction(
    '[ListContainer] Load System templates by site Id success',
    props<{ containers: ListContainer[]; siteId: string }>()
);

export const loadListContainersBySiteId = createAction(
    '[ListContainer] Load by site id',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadListContainersBySiteIdSuccess = createAction(
    '[ListContainer] Load by site id success',
    props<{ containers: ListContainer[]; siteId: string }>()
);

export const loadListContainersBySiteIdFail = createAction(
    '[ListContainer] Load by site id fail',
    props<{ error: any; siteId: string }>()
);

// Load ListContainers by project actions

export const loadListContainersByProjectId = createAction(
    '[ListContainer] Load by project id',
    props<{ payload: LoadByProjectIdPayload }>()
);

export const loadListContainersByProjectIdSuccess = createAction(
    '[ListContainer] Load by project id success',
    props<{ containers: ListContainer[]; projectId: string }>()
);

export const loadListContainersByProjectIdFail = createAction(
    '[ListContainer] Load by project id fail',
    props<{ error: any; projectId: string }>()
);

export const getListContainerByIdSuccess = createAction(
    '[ListContainer] Get list container by id success',
    props<{ listContainer: ListContainer }>()
);

// Add ListContainer actions

export const importSystemListContainer = createAction(
    '[ListContainer] Import System template',
    props<{ payload: ImportSystemTemplatePayload }>()
);

export const addListContainer = createAction(
    '[ListContainer] Add',
    props<{ payload: AddListContainerPayload }>()
);

export const addListContainerSuccess = createAction(
    '[ListContainer] Add success',
    props<{ container: ListContainer }>()
);

export const addListContainerFail = createAction(
    '[ListContainer] Add fail',
    props<{ error: any }>()
);

// Update ListContainer actions

export const updateListContainer = createAction(
    '[ListContainer] Update',
    props<{ payload: UpdateListContainerPayload; options?: OptimisticableActionOptions }>()
);

export const patchListContainer = createAction(
    '[ListContainer] Patch',
    props<{ payload: PatchListContainerPayload; options?: OptimisticableActionOptions }>()
);

export const patchListContainerSuccess = createAction(
    '[ListContainer] Patch success',
    props<PatchListContainerPayload>()
);

export const patchListContainerFail = createAction(
    '[ListContainer] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Delete ListContainer actions

export const deleteListContainer = createAction(
    '[ListContainer] Delete',
    props<{ payload: IdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteListContainerSuccess = createAction(
    '[ListContainer] Delete success',
    props<IdPayload>()
);

export const deleteListContainerFail = createAction(
    '[ListContainer] Delete fail',
    props<{ error: any; originAction: Action }>()
);

export const replaceListContainer = createAction(
    '[ListContainer] Replace ',
    props<{ container: ListContainer }>()
);

export const changeListContainerTemplate = createAction(
    '[ListContainer] Change template',
    props<{ payload: ChangeListContainerTemplatePayload }>()
);

export const changeListContainerTemplateFail = createAction(
    '[ListContainer] Change template fail',
    props<{ error: any }>()
);

export const setListContainerAsDefault = createAction(
    '[ListContainer] Set as default ',
    props<{ containerId: string }>()
);
