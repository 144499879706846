import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    GetAllocationResponse,
    GetAllocationsResponse,
} from 'app/core/services/rest-api/features/allocation/allocation.responses';
import { EntityPatchSuccessResponse } from 'app/core/services/rest-api/features/patch.response';
import { getPatch } from 'app/utils/json-patch';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AllocationSelectors } from '.';
import { AppStore } from '..';
import { RestApiService } from '../../services/rest-api';
import { okEmptyAction } from '../project/project.actions';
import { SnackBarService } from './../../../services/snack-bar/snack-bar.service';
import * as AllocationActions from './allocation.actions';

@Injectable()
export class AllocationEffects {
    loadBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AllocationActions.loadAllocationsBySite),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) => new GetAllocationsResponse(response).allocations),
                    map((allocations) => AllocationActions.loadAllocationsSuccess({ allocations })),
                    catchError((error) => of(AllocationActions.loadAllocationsFail({ error })))
                )
            )
        )
    );

    loadByProjectId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AllocationActions.loadAllocationsByProject),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) => new GetAllocationsResponse(response).allocations),
                    map((allocations) => AllocationActions.loadAllocationsSuccess({ allocations })),
                    catchError((error) => of(AllocationActions.loadAllocationsFail({ error })))
                )
            )
        )
    );

    addAllocation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AllocationActions.addAllocation, AllocationActions.addManualAllocation),
            mergeMap((action) => {
                return this.restApiService.process(action).pipe(
                    map((response) => {
                        return AllocationActions.addAllocationSuccess({
                            allocation: new GetAllocationResponse(response).allocation,
                        });
                    }),
                    catchError((error) => {
                        return of(AllocationActions.addAllocationFail({ error }));
                    })
                );
            })
        )
    );

    updateAllocation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AllocationActions.updateAllocation),
            withLatestFrom(this.store$.select(AllocationSelectors.selectAll)),
            mergeMap(([action, allocations]) => {
                const updatedAllocation = allocations.find((a) => a.id === action.payload.id);
                const patch = getPatch(updatedAllocation, action.payload.changes);
                return of(
                    AllocationActions.patchAllocation({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchAllocation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AllocationActions.patchAllocation),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: EntityPatchSuccessResponse) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return AllocationActions.patchAllocationSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) =>
                        of(AllocationActions.patchAllocationFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    deleteAllocation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AllocationActions.deleteAllocation),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return AllocationActions.deleteAllocationSuccess(action.payload);
                    }),
                    catchError((error) =>
                        of(AllocationActions.deleteAllocationFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>,
        private snackBarService: SnackBarService
    ) {}
}
