import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import * as FolderActions from './folder.actions';
import { adapter, initialState } from './folder.store';
import { Folder } from './models';

export const reducer = createReducer(
    initialState,
    on(FolderActions.loadFoldersByTag, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(FolderActions.loadFoldersSuccess, (state, payload) => {
        const newState = adapter.addMany(payload.folders, adapter.removeAll(state));
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(FolderActions.loadFoldersFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(FolderActions.addFolderSuccess, (state, payload) => adapter.addOne(payload.folder, state)),

    on(FolderActions.addFolderFail, (state) => ({ ...state })),

    on(FolderActions.patchFolder, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patchedRole = getPatched(state.entities[action.payload.id], action.payload.patch);
        const folderUpdate: Update<Folder> = {
            id: patchedRole.id,
            changes: patchedRole,
        };
        return adapter.updateOne(folderUpdate, state);
    }),

    on(FolderActions.patchFolderSuccess, (state, payload) => {
        const patchedRole = getPatched(state.entities[payload.id], payload.patch);
        const folderUpdate: Update<Folder> = {
            id: patchedRole.id,
            changes: patchedRole,
        };
        return adapter.updateOne(folderUpdate, state);
    }),

    on(FolderActions.patchFolderFail, (state) => ({ ...state })),

    on(FolderActions.deleteFolder, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(FolderActions.deleteFolderSuccess, (state, payload) => adapter.removeOne(payload.id, state)),

    on(FolderActions.deleteFolderFail, (state) => ({ ...state }))
);
