import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import {
    AsyncUpdateStatusDefaultLoad,
    AsyncUpdateStatusDefaultLoadSuccess,
} from '../shared/async-update-status.store';
import { ProgramRole } from './models';
import * as ProgramRoleActions from './program-role.actions';
import { adapter, initialState } from './program-role.store';

export const reducer = createReducer(
    initialState,
    on(ProgramRoleActions.loadProgramRoleTemplates, (state) => ({
        ...state,
        templatesLoadingStatus: {
            ...state.templatesLoadingStatus,
            ...AsyncUpdateStatusDefaultLoad,
        },
    })),

    on(ProgramRoleActions.loadProgramRoleTemplatesSuccess, (state, payload) => {
        const newState = adapter.addMany(
            payload.roles,
            adapter.removeMany(
                Object.values(state.entities)
                    .filter((r) => r.isTemplate)
                    .map((r) => r.id),
                state
            )
        );
        return {
            ...newState,
            serverRequestInProgress: false,
            loaded: true,
            templatesLoadingStatus: AsyncUpdateStatusDefaultLoadSuccess,
        };
    }),

    on(ProgramRoleActions.loadProgramRoleTemplatesFail, (state, payload) => ({
        ...state,
        templatesLoadingStatus: {
            ...state.templatesLoadingStatus,
            ...AsyncUpdateStatusDefaultLoad,
            error: payload.error,
        },
    })),

    on(ProgramRoleActions.loadProgramRolesBySite, (state) => ({
        ...state,
        currentSiteRolesLoadingStatus: {
            ...state.currentSiteRolesLoadingStatus,
            ...AsyncUpdateStatusDefaultLoad,
        },
    })),

    on(ProgramRoleActions.loadProgramRolesBySiteSuccess, (state, payload) => {
        const newState = adapter.addMany(
            payload.roles,
            adapter.removeMany(
                Object.values(state.entities)
                    .filter((r) => r.siteId === payload.roles[0]?.siteId)
                    .map((r) => r.id),
                state
            )
        );
        return {
            ...newState,
            currentSiteRolesLoadingStatus: {
                ...state.currentSiteRolesLoadingStatus,
                ...AsyncUpdateStatusDefaultLoadSuccess,
            },
        };
    }),

    on(ProgramRoleActions.loadProgramRolesBySiteFail, (state, payload) => ({
        ...state,
        currentSiteRolesLoadingStatus: {
            ...state.currentSiteRolesLoadingStatus,
            ...AsyncUpdateStatusDefaultLoad,
            error: payload.error,
        },
    })),

    on(ProgramRoleActions.addProgramRoleSuccess, (state, payload) =>
        adapter.addOne(payload.role, state)
    ),

    on(ProgramRoleActions.addProgramRoleFail, (state) => ({ ...state })),

    on(ProgramRoleActions.patchProgramRole, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patchedRole = getPatched(state.entities[action.payload.id], action.payload.patch);
        const roleUpdate: Update<ProgramRole> = {
            id: patchedRole.id,
            changes: patchedRole,
        };
        return adapter.updateOne(roleUpdate, state);
    }),

    on(ProgramRoleActions.patchProgramRoleSuccess, (state, payload) => {
        const patchedRole = getPatched(state.entities[payload.id], payload.patch);
        const roleUpdate: Update<ProgramRole> = {
            id: patchedRole.id,
            changes: patchedRole,
        };
        return adapter.updateOne(roleUpdate, state);
    }),

    on(ProgramRoleActions.patchProgramRoleFail, (state) => ({ ...state })),

    on(ProgramRoleActions.setProgramRoleTag, (state, action) => {
        if (!action.options?.optimistic) return state;
        const roleUpdate: Update<ProgramRole> = {
            id: action.payload.roleId,
            changes: { tag: action.payload.tag },
        };
        const existingRoleWithTag = Object.values(state.entities).find(
            (r) => r.tag === action.payload.tag
        );
        const existingRoleUpdate: Update<ProgramRole> = {
            id: existingRoleWithTag?.id,
            changes: { tag: null },
        };
        return adapter.updateMany([roleUpdate, existingRoleUpdate], state);
    }),

    on(ProgramRoleActions.setProgramRoleTagSuccess, (state, payload) => {
        const updates: Update<ProgramRole>[] = payload.programRoles.map((role) => ({
            id: role.id,
            changes: role,
        }));
        return adapter.updateMany(updates, state);
    }),

    on(ProgramRoleActions.setProgramRoleTagFail, (state) => ({ ...state })),

    on(ProgramRoleActions.deleteProgramRole, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(ProgramRoleActions.deleteProgramRoleSuccess, (state, payload) =>
        adapter.removeOne(payload.id, state)
    ),

    on(ProgramRoleActions.deleteProgramRoleFail, (state) => ({ ...state }))
);
