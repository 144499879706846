import { getCurrencySymbol } from '@angular/common';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SiteSelectors } from '../site';
import { StateEntry } from '../state-entry';
import { SuccessRatingOptions } from './models';
import { adapter, State } from './site-settings.store';

export const selectSiteEntry = createFeatureSelector<State>(StateEntry.SiteSettings);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectSiteEntry);

export const selectCurrentSiteSettings = createSelector(
    selectAll,
    SiteSelectors.selectCurrentSiteId,
    (settings, currentSiteId) => settings.find((s) => s.siteId === currentSiteId)
);

export const selectSiteSettingsCompletixTemplates = createSelector(selectAll, (settings) =>
    settings.filter((s) => s.isCompletixTemplate)
);

export const selectCurrentSiteRequiredCustomField = createSelector(
    selectCurrentSiteSettings,
    (settings) => settings?.customFields?.find((f) => f.isRequired)
);

export const selectCurrentSiteCurrencyCode = createSelector(
    selectCurrentSiteSettings,
    (settings) => settings?.options?.currency
);

export const selectCurrentSiteCurrencySymbol = createSelector(
    selectCurrentSiteCurrencyCode,
    (code) => getCurrencySymbol(code?.toUpperCase(), 'narrow')
);

export const selectCurrentSiteFiscalYearStart = createSelector(
    selectCurrentSiteSettings,
    (settings) => {
        const today = new Date();
        const fyStart = new Date(settings.options.currentFYStart ?? today);
        return fyStart;
    }
);

export const selectCurrentSiteTypicalWorkWeek = createSelector(
    selectCurrentSiteSettings,
    (settings) => settings?.options?.typicalWorkingWeek
);

export const selectCurrentSiteFYBudget = createSelector(
    selectCurrentSiteSettings,
    (settings) => settings?.options?.currentFYBudget
);

export const selectCompletixTemplatesLoadingStatus = createSelector(
    selectSiteEntry,
    (store) => store.serverRequestInProgress
);

export const selectCurrentSiteAllocationColouring = createSelector(
    selectCurrentSiteSettings,
    (settings) => settings?.allocationColouring
);

export const selectCurrentSiteProjectRatingVisible = createSelector(
    selectCurrentSiteSettings,
    (settings) =>
        settings?.successRatingOptions &&
        Object.keys(settings.successRatingOptions).some(
            (key: keyof SuccessRatingOptions) => settings.successRatingOptions[key]?.enabled
        )
);

export const selectCurrentSiteHiddenExecutiveDashboardColumns = createSelector(
    selectCurrentSiteSettings,
    (siteSettings) => siteSettings?.options?.executiveDashboardHiddenColumns ?? []
);
