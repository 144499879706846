import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ctx-page-not-found',
    template: `<h1>Page not found</h1>`,
})
export class PageNotFoundComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
