import { Action, createAction, props } from '@ngrx/store';
import { LoadByProjectIdPayload, LoadBySiteIdPayload } from '../shared/models/load.payload';
import { IdPayload } from '../shared/models/shared-payloads';
import { OptimisticableActionOptions } from './../shared/optimisticable-action';
import {
    AddMinutePayload,
    DeleteTopicPayload,
    MinuteIdPayload,
    PatchMinutePayload,
    SendMinutePreviewPayload,
    UpdateMinuteFolderExpandedIdsPayload,
    UpdateMinuteFolderOpenedIdPayload,
    UpdateMinutePayload,
    UpdateMinuteTopicPayload,
} from './minute.payloads';
import { Minute, MinuteTopicCreateOptions } from './models';

// Load minute by Project Id

export const loadMinutesByProjectId = createAction(
    '[Minute] Load minutes by Project id',
    props<{ payload: LoadByProjectIdPayload }>()
);

export const loadMinutesByProjectIdSuccess = createAction(
    '[Minute] Load minutes by Project id success',
    props<{ minutes: Minute[] }>()
);

export const loadMinutesByProjectIdFail = createAction(
    '[Minute] Load minutes by Project id fail',
    props<{ error: any }>()
);

// Load minutes by Site Id

export const loadMinutesBySiteId = createAction(
    '[Minute] Load minutes by Site id',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadMinutesBySiteIdSuccess = createAction(
    '[Minute] Load minutes by Site id success',
    props<{ minutes: Minute[] }>()
);

export const loadMinutesBySiteIdFail = createAction(
    '[Minute] Load minutes by Site id fail',
    props<{ error: any }>()
);

// Add minute

export const addMinute = createAction(
    '[Minute] Add minute',
    props<{ payload: AddMinutePayload }>()
);

export const addMinuteSuccess = createAction(
    '[Minute] Add minute success',
    props<{ minute: Minute }>()
);

export const addMinuteFail = createAction('[Minute] Add minute fail', props<{ error: any }>());

// Clone minute

export const cloneMinute = createAction('[Minute] Clone minute', props<{ payload: IdPayload }>());

// Add topic

export const addTopic = createAction(
    '[Minute] Add topic',
    props<{ createOptions: MinuteTopicCreateOptions }>()
);

// Delete topic

export const deleteTopic = createAction('[Minute] Delete topic', props<DeleteTopicPayload>());

// Update topic

export const updateTopic = createAction('[Minute] Update topic', props<UpdateMinuteTopicPayload>());

// Update minute

export const updateMinute = createAction(
    '[Minute] Update minute',
    props<{ payload: UpdateMinutePayload; options?: OptimisticableActionOptions }>()
);

export const patchMinute = createAction(
    '[Minute] Patch minute',
    props<{ payload: PatchMinutePayload; options?: OptimisticableActionOptions }>()
);

export const patchMinuteSuccess = createAction(
    '[Minute] Patch minute success',
    props<PatchMinutePayload>()
);

export const patchMinuteFail = createAction(
    '[Minute] Patch minute fail',
    props<{ error: any; originAction: Action }>()
);

// Delete minute

export const deleteMinute = createAction(
    '[Minute] Delete minute',
    props<{ payload: MinuteIdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteMinuteSuccess = createAction(
    '[Minute] Delete minute success',
    props<MinuteIdPayload>()
);

export const deleteMinuteFail = createAction(
    '[Minute] Delete minute fail',
    props<{ error: any; originAction: Action }>()
);

// Update expanded ids action

export const updateMinuteFolderExpandedIds = createAction(
    '[Minute] Update minute folder expanded ids',
    props<{ payload: UpdateMinuteFolderExpandedIdsPayload }>()
);

// Update opened folder id action

export const updateMinuteFolderOpenedId = createAction(
    '[Minute] Update minute folder opened id',
    props<{ payload: UpdateMinuteFolderOpenedIdPayload }>()
);

// Send minute preview

export const sendMinutePreview = createAction(
    '[Minute] Send preview',
    props<{ payload: SendMinutePreviewPayload }>()
);
