import { createSelector } from '@ngrx/store';
import { Calendar } from 'app/core/models/calendar';
import { AllocationSelectors, isCurrentAllocation } from '../allocation';
import { CalendarSelectors } from '../calendar';
import { LayoutSelectors } from '../layout';
import { ProgramSelectors } from '../program';
import { ProjectSelectors } from '../project';
import { ProjectMemberSelectors } from '../project-member';
import {
    AllocationTotalsDictionary,
    CurrentAllocationTotalDictionary,
    getAllocationTotal,
    SiteMemberSelectors,
} from '../resource';
import { SiteRoleSelectors } from '../site-role';
import { selectCurrentSiteMembers } from './../resource/site-member.selectors';
import { SiteRoleTag } from './models/shared-permissions.model';

export const selectSiteMembersCountTowardSubscription = createSelector(
    selectCurrentSiteMembers,
    SiteRoleSelectors.selectSiteRoleByTag(SiteRoleTag.Guest),
    (members, guestRole) => members.filter((m) => m.mainRoleId !== guestRole?.id)
);

export const selectCurrentAllocationTotalDictionary = createSelector(
    ProjectMemberSelectors.selectAll,
    AllocationSelectors.selectExtendedAllocations,
    CalendarSelectors.selectAll,
    (projectMembers, allocations, calendars) => {
        const calendarsDict: { [id: string]: Calendar } = {};
        calendars.forEach((c) => (calendarsDict[c.projectId] = c));
        const totalsDictionary: CurrentAllocationTotalDictionary = {};
        projectMembers.forEach((member) => {
            const memberCurrentAllocation = allocations.find(
                (a) =>
                    isCurrentAllocation(a, calendarsDict[a.projectId]) &&
                    a.projectMemberId === member.id
            );
            if (!memberCurrentAllocation) return;
            const memberRate = member.hourlyRate?.value || 0;
            totalsDictionary[member.id] = getAllocationTotal(
                memberCurrentAllocation,
                memberRate,
                calendarsDict[memberCurrentAllocation.projectId]
            );
        });
        return totalsDictionary;
    }
);

export const selectAllocationTotalsDictionary = createSelector(
    ProjectMemberSelectors.selectAll,
    AllocationSelectors.selectExtendedAllocations,
    CalendarSelectors.selectAll,
    (projectMembers, allocations, calendars) => {
        const calendarsDict: { [id: string]: Calendar } = {};
        calendars.forEach((c) => (calendarsDict[c.projectId] = c));
        const totalsDictionary: AllocationTotalsDictionary = {};
        allocations.forEach((allocation) => {
            const projectMember = projectMembers.find((m) => m.id === allocation.projectMemberId);
            const rate = projectMember?.hourlyRate?.value || 0;
            totalsDictionary[allocation.id] = getAllocationTotal(
                allocation,
                rate,
                calendarsDict[allocation.projectId]
            );
        });
        return totalsDictionary;
    }
);

export const selectAllProjectMembers = createSelector(
    ProjectMemberSelectors.selectAll,
    SiteMemberSelectors.selectAll,
    (members, siteMembers) =>
        members.map((m) => {
            const originSiteMember = siteMembers.find((sm) => sm.id === m.siteMemberId);
            return {
                ...m,
                name: originSiteMember?.name ?? m.name,
                imageDataUrl: originSiteMember?.imageDataUrl ?? m.imageDataUrl,
            };
        })
);

export const selectCurrentProjectMembers = createSelector(
    selectAllProjectMembers,
    ProjectSelectors.selectCurrentProjectId,
    (members, projectId) => members.filter((m) => m.projectId === projectId)
);

export const selectCurrentProgramProjects = createSelector(
    ProgramSelectors.selectCurrentProgramId,
    ProjectSelectors.selectNotArchivedProjects,
    LayoutSelectors.selectProjectTreeLayout,
    (programId, projects) => {
        const filteredProjects = projects.filter((p) => p.programId === programId);
        return filteredProjects;
    }
);
