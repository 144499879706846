import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as ApprovalRequestActions from './approval-request.actions';
import { adapter, initialState } from './approval-request.store';
import { ApprovalRequest, ApprovalState } from './models';

export const reducer = createReducer(
    initialState,
    on(ApprovalRequestActions.loadApprovalRequests, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(ApprovalRequestActions.loadApprovalRequestsSuccess, (state, payload) => {
        const newState = adapter.addMany(payload.requests, adapter.removeAll(state));
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(ApprovalRequestActions.loadApprovalRequestsFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(ApprovalRequestActions.updateApprovalState, (state, payload) => {
        const update: Update<ApprovalRequest> = {
            id: payload.id,
            changes: {
                state: payload.state,
                closingDate: new Date(),
                approverSiteMemberIds: [payload.siteMemberId],
            },
        };
        return adapter.updateOne(update, state);
    }),

    on(ApprovalRequestActions.addPendingApproval, (state, payload) => {
        const existingApproval = Object.values(state.entities).find(
            (a) =>
                a.originObjectId === payload.approval.originObjectId &&
                a.state === ApprovalState.Pending
        );
        return adapter.addOne(payload.approval, adapter.removeOne(existingApproval?.id, state));
    }),

    on(ApprovalRequestActions.addApproval, (state, payload) => {
        return adapter.addOne(payload.approval, state);
    }),

    on(ApprovalRequestActions.deletePendingApproval, (state, payload) => {
        const approval = Object.values(state.entities).find(
            (a) => a.originObjectId === payload.id && a.state === ApprovalState.Pending
        );
        return adapter.removeOne(approval?.id, state);
    }),

    on(ApprovalRequestActions.deleteApprovalByProjectId, (state, payload) => {
        const approvals = Object.values(state.entities).filter(
            (a) => a.projectId === payload.id && a.state === ApprovalState.Pending
        );
        return adapter.removeMany(
            approvals.map((a) => a.id),
            state
        );
    })
);
