import { createSelector } from '@ngrx/store';
import { ProgramStatusSelectors } from 'app/core/store/program-status-report';
import { ProgramOverview } from '../executive-dashboard';
import { OverallStatus, ProgramSelectors } from '../program';
import { ProjectSelectors } from '../project';
import { selectCurrentSiteProjectsOverview } from './project-overview.selectors';

export const selectCurrentSiteProgramsOverview = createSelector(
    ProgramSelectors.selectCurrentSitePrograms,
    ProjectSelectors.selectCurrentSiteProjects,
    selectCurrentSiteProjectsOverview,
    ProgramStatusSelectors.selectAllProgramsLiveReport,
    (programs, projects, projectsOverivew, programsLiveReports) => {
        const programsOverview: ProgramOverview[] = programs.map((program) => {
            const childProjects = projects.filter(
                (p) => p.programId === program.id && !p.archived && !p.deleted
            );
            const childProjectsOverview = projectsOverivew.filter((o) =>
                childProjects.some((p) => p.id === o.projectId)
            );
            const liveReport = programsLiveReports.find((r) => r.programId === program.id);
            const rating =
                childProjects
                    .map((p) => p.closure?.rating ?? 5)
                    .reduce((acc, curr) => acc + curr, 0) / (childProjects.length || 1);
            const overview: ProgramOverview = {
                programId: program.id,
                projectsCount: childProjects.length,
                greenProjectsCount: childProjects.filter(
                    (p) => p.overallStatus === OverallStatus.Green
                ).length,
                yellowProjectsCount: childProjects.filter(
                    (p) => p.overallStatus === OverallStatus.Yellow
                ).length,
                redProjectsCount: childProjects.filter((p) => p.overallStatus === OverallStatus.Red)
                    .length,
                totalBudget: childProjectsOverview
                    .map((o) => o.totalApprovedBudget)
                    .reduce((acc, curr) => acc + curr, 0),
                actualBudget: liveReport.budget.actualToDate.value?.total,
                plannedBudget: liveReport.budget.plannedCost.value?.total,
                openIssuesCount: childProjectsOverview
                    .map((o) => o.openIssuesCount)
                    .reduce((acc, curr) => acc + curr, 0),
                highRisksCount: childProjectsOverview
                    .map((o) => o.highRisksCount)
                    .reduce((acc, curr) => acc + curr, 0),
                rating,
                approvedFYBudget: childProjectsOverview
                    .map((o) => o.totalApprovedCurrentFY)
                    .reduce((acc, curr) => acc + curr, 0),
                actualsFYBudget: childProjectsOverview
                    .map((o) => o.actualsFYBudget)
                    .reduce((acc, curr) => acc + curr, 0),
                forecastFYBudget: childProjectsOverview
                    .map((o) => o.forecastFYBudget)
                    .reduce((acc, curr) => acc + curr, 0),
            };

            return overview;
        });
        return programsOverview;
    }
);
