import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getRandomBrightColor } from 'app/core/models/shared-models';
import { GetChangeRequestByIdResponse } from 'app/core/services/rest-api/features/change-request/change-request.responses';
import { FileNode } from 'app/core/store/document';
import { SnackBarService } from 'app/services/snack-bar/snack-bar.service';
import { getPatch } from 'app/utils/json-patch';
import { of } from 'rxjs';
import {
    catchError,
    concatMap,
    map,
    mergeMap,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { ChangeRequestSelectors } from '.';
import { RestApiService } from '../../services/rest-api';
import { ApprovalRequestActions } from '../approval-request';
import { DocumentActions } from '../document';
import { ProjectSelectors } from '../project';
import { okEmptyAction } from '../project/project.actions';
import { selectAccountSiteMembership } from '../shared/account-mebership.selectors';
import { Model } from '../shared/models/base.model';
import * as AppStore from '../store';
import { EntityPatchSuccessResponse } from './../../services/rest-api/features/patch.response';
import * as ChangeRequestActions from './change-request.actions';
import { ChangeRequest, ChangeRequestApprover } from './models/change-request.model';

@Injectable()
export class ChangeRequestEffects {
    loadChangeRequestsByProjectId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangeRequestActions.loadChangeRequestsByProjectId),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) =>
                        ChangeRequestActions.loadChangeRequestsByProjectIdSuccess({
                            changeRequests: response.payload.map((dto) =>
                                Model.createFromDto(ChangeRequest, dto)
                            ),
                            projectId: action.payload.projectId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            ChangeRequestActions.loadChangeRequestsByProjectIdFail({
                                error,
                                projectId: action.payload.projectId,
                            })
                        )
                    )
                )
            )
        )
    );

    loadChangeRequestsBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangeRequestActions.loadChangeRequestsBySiteId),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    withLatestFrom(this.store$.select(ProjectSelectors.selectCurrentSiteProjects)),
                    map(([response, projects]) =>
                        ChangeRequestActions.loadChangeRequestsBySiteIdSuccess({
                            changeRequests: response.payload.map((dto) =>
                                Model.createFromDto(ChangeRequest, dto)
                            ),
                            siteId: action.payload.siteId,
                            projectIds: projects.map((prj) => prj.id),
                        })
                    ),
                    catchError((error) =>
                        of(
                            ChangeRequestActions.loadChangeRequestsBySiteIdFail({
                                error,
                                siteId: action.payload.siteId,
                            })
                        )
                    )
                )
            )
        )
    );

    loadChangeRequestApproversByProjectId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangeRequestActions.loadChangeRequestApprovers),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) =>
                        ChangeRequestActions.loadChangeRequestApproversSuccess({
                            approvers: response.payload
                                .map((dto) => Model.createFromDto(ChangeRequestApprover, dto))
                                .map((approver) => ({
                                    ...approver,
                                    avatarColor: getRandomBrightColor(),
                                })),
                        })
                    ),
                    catchError((error) =>
                        of(ChangeRequestActions.loadChangeRequestApproversFail({ error }))
                    )
                )
            )
        )
    );

    getChangeRequestById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangeRequestActions.getChangeRequestById),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    switchMap((response: GetChangeRequestByIdResponse) => [
                        ChangeRequestActions.getChangeRequestByIdSuccess({
                            changeRequest: Model.createFromDto(ChangeRequest, response.payload),
                        }),
                        DocumentActions.getManyDocumentsSuccess({
                            documents: response.payload.documents.map((d) =>
                                Model.createFromDto(FileNode, d)
                            ),
                        }),
                    ]),
                    catchError((error) =>
                        of(ChangeRequestActions.getChangeRequestByIdFail({ error }))
                    )
                )
            )
        )
    );

    addChangeRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangeRequestActions.addChangeRequest),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Creating a new Change Request...');
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBarService.hide()),
                    map((response) =>
                        ChangeRequestActions.addChangeRequestSuccess({
                            changeRequest: Model.createFromDto(ChangeRequest, response.payload),
                        })
                    ),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(ChangeRequestActions.addChangeRequestFail({ error }));
                    })
                );
            })
        )
    );

    updateChangeRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangeRequestActions.updateChangeRequest),
            withLatestFrom(this.store$.select(ChangeRequestSelectors.selectAll)),
            mergeMap(([action, changeRequests]) => {
                const request = changeRequests.find((cr) => cr.id === action.payload.id);
                const patch = getPatch(request, action.payload.changes);
                return of(
                    ChangeRequestActions.patchChangeRequest({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchChangeRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangeRequestActions.patchChangeRequest),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: EntityPatchSuccessResponse) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ChangeRequestActions.patchChangeRequestSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) =>
                        of(
                            ChangeRequestActions.patchChangeRequestFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteChangeRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangeRequestActions.deleteChangeRequest),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() =>
                        ChangeRequestActions.deleteChangeRequestSuccess({
                            id: action.payload.id,
                        })
                    ),
                    catchError((error) =>
                        of(
                            ChangeRequestActions.deleteChangeRequestFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    addChangeRequestApproval$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangeRequestActions.addChangeRequestApproval),
            concatMap((action) => {
                this.snackBarService.showInfiniteBar('Adding a new Change Request Approval...');
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBarService.hide()),
                    map((response: EntityPatchSuccessResponse) => {
                        return ChangeRequestActions.patchChangeRequestSuccess({
                            id: action.payload.requestId,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(
                            ChangeRequestActions.addChangeRequestApprovalFail({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    deleteChangeRequestApproval$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangeRequestActions.deleteChangeRequestApproval),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: EntityPatchSuccessResponse) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ChangeRequestActions.patchChangeRequestSuccess({
                            id: action.payload.requestId,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) =>
                        of(
                            ChangeRequestActions.deleteChangeRequestApprovalFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    manageChangeRequestApprovalSubmission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangeRequestActions.manageChangeRequestApprovalSubmission),
            concatMap((action) => {
                if (!action.options?.optimistic) {
                    this.snackBarService.showInfiniteBar('Processing...');
                }
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBarService.hide()),
                    map((response: EntityPatchSuccessResponse) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ChangeRequestActions.patchChangeRequestSuccess({
                            id: action.payload.requestId,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(
                            ChangeRequestActions.patchChangeRequestApprovalFail({
                                error,
                                originAction: action,
                            })
                        );
                    })
                );
            })
        )
    );

    manageChangeRequestApproval$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangeRequestActions.manageChangeRequestApproval),
            withLatestFrom(
                this.store$.select(selectAccountSiteMembership),
                this.store$.select(ChangeRequestSelectors.selectAll)
            ),
            concatMap(([action, accountSiteMember, changeRequests]) => {
                this.snackBarService.showInfiniteBar('Processing an Approval...');
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBarService.hide()),
                    map((response: EntityPatchSuccessResponse) => {
                        if (action.payload.approvalId) {
                            this.store$.dispatch(
                                ApprovalRequestActions.updateApprovalState({
                                    id: action.payload.approvalId,
                                    siteMemberId: accountSiteMember.id,
                                    state: action.payload.approvalState,
                                })
                            );
                        }
                        const changeRequest = changeRequests.find((cr) =>
                            cr.approvals?.some(
                                (a) => a.id === action.payload.changeRequestApprovalId
                            )
                        );
                        return ChangeRequestActions.patchChangeRequestSuccess({
                            id: changeRequest.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(
                            ChangeRequestActions.patchChangeRequestFail({
                                error,
                                originAction: action,
                            })
                        );
                    })
                );
            })
        )
    );

    updateChangeRequestApproval$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangeRequestActions.updateChangeRequestApproval),
            withLatestFrom(this.store$.select(ChangeRequestSelectors.selectAll)),
            mergeMap(([action, requests]) => {
                const request = requests.find((r) => r.id === action.payload.changeRequestId);
                const approval = request.approvals.find((a) => a.id === action.payload.approvalId);
                const patch = getPatch(approval, action.payload.changes);
                return of(
                    ChangeRequestActions.patchChangeRequestApproval({
                        payload: { approvalId: approval.id, changeRequestId: request.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchChangeRequestApproval$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangeRequestActions.patchChangeRequestApproval),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: EntityPatchSuccessResponse) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ChangeRequestActions.patchChangeRequestSuccess({
                            id: action.payload.changeRequestId,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) =>
                        of(
                            ChangeRequestActions.patchChangeRequestApprovalFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    reOpenChangeRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangeRequestActions.reOpenChangeRequest),
            concatMap((action) => {
                this.snackBarService.showInfiniteBar('Re-Opening the Change Request...');
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBarService.hide()),
                    map((response: EntityPatchSuccessResponse) =>
                        ChangeRequestActions.patchChangeRequestSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        })
                    ),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(
                            ChangeRequestActions.patchChangeRequestFail({
                                error,
                                originAction: action,
                            })
                        );
                    })
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>,
        private snackBarService: SnackBarService
    ) {}
}
