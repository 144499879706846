import { LoadByProjectIdPayload } from 'app/core/store/shared/models/load.payload';
import { IdPayload } from 'app/core/store/shared/models/shared-payloads';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import {
    InviteProjectMemberPayload,
    LoadProjectMembersBySiteIdPayload,
    PatchProjectMemberPayload,
    ProjectMemberIdPayload,
} from '../../../../store/project-member/project-member.payloads';

export const loadMembersBySiteId = (
    payload: LoadProjectMembersBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'projects' },
        { name: 'members' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadMembersByProjectId = (
    payload: LoadByProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projects', value: payload.projectId }, { name: 'members' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const inviteProjectMember = (
    payload: InviteProjectMemberPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projects', value: payload.projectId },
        { name: 'members' },
        { name: 'invite' },
    ]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchProjectMember = (
    payload: PatchProjectMemberPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectMembers', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteProjectMember = (
    payload: ProjectMemberIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectMembers', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};

export const setPrimaryProjectManager = (
    payload: ProjectMemberIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projectMembers', value: payload.id },
        { name: 'setPrimary' },
    ]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const resendInvite = (payload: IdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([
        { name: 'projectMembers', value: payload.id },
        { name: 'resendInvite' },
    ]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const reOpenProjectMember = (payload: IdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projectMembers', value: payload.id }, { name: 'reOpen' }]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};
