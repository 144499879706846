import { JsonProperty } from 'app/utils/json-mapper';
import { Role } from '../../shared/models/role.base.model';
import { SitePermissions } from '../../shared/models/shared-permissions.model';
import { SiteRoleTag } from './../../shared/models/shared-permissions.model';

export class SiteRole extends Role {
    @JsonProperty('tag')
    tag?: SiteRoleTag;

    @JsonProperty('standalone')
    standalone?: boolean;

    @JsonProperty('permissions')
    permissions: SitePermissions;

    constructor(init?: Partial<SiteRole>) {
        super();
        Object.assign(this, init);
    }
}
