import { Action, createAction, props } from '@ngrx/store';
import { LoadByProjectIdPayload, LoadBySiteIdPayload } from '../shared/models/load.payload';
import { IdPayload } from '../shared/models/shared-payloads';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import {
    AddDecisionPayload,
    ManageDecisionApprovalPayload,
    PatchDecisionPayload,
    SubmitDecisionPayload,
    UpdateDecisionPayload,
} from './decision.payloads';
import { DecisionRecord } from './models/decision.model';

// Load Decisions by Site Id decisions

export const loadDecisionsBySiteId = createAction(
    '[Decisions] Load Decisions by Site Id',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadDecisionsBySiteIdSuccess = createAction(
    '[Decisions] Load Decisions by Site Id success',
    props<{ decisions: DecisionRecord[] }>()
);

export const loadDecisionsBySiteIdFail = createAction(
    '[Decisions] Load Decisions by Site Id fail',
    props<{ error: any }>()
);

// Load Decisions by Project Id decisions

export const loadDecisionsByProjectId = createAction(
    '[Decisions] Load Decisions by Project Id',
    props<{ payload: LoadByProjectIdPayload }>()
);

export const loadDecisionsByProjectIdSuccess = createAction(
    '[Decisions] Load Decisions by Project Id success',
    props<{ decisions: DecisionRecord[] }>()
);

export const loadDecisionsByProjectIdFail = createAction(
    '[Decisions] Load Decisions by Project Id fail',
    props<{ error: any }>()
);

// Get assigned Decision by Id

export const getDecisionById = createAction(
    '[Decisions] Get Decision by Id',
    props<{ payload: IdPayload }>()
);

export const getDecisionByIdSuccess = createAction(
    '[Decisions] Get Decision by Id success',
    props<{ decision: DecisionRecord }>()
);

export const getDecisionByIdFail = createAction(
    '[Decisions] Get Decision by Id fail',
    props<{ error: any }>()
);

// Add Decision decisions

export const addDecision = createAction(
    '[Decisions] Add',
    props<{ payload: AddDecisionPayload; options?: OptimisticableActionOptions }>()
);

export const addDecisionSuccess = createAction(
    '[Decisions] Add success',
    props<{ decision: DecisionRecord; options?: OptimisticableActionOptions }>()
);

export const addDecisionFail = createAction(
    '[Decisions] Add fail',
    props<{ error: any; originAction: Action; decisionId: string }>()
);

// Update Decision decisions

export const updateDecision = createAction(
    '[Decisions] Update',
    props<{ payload: UpdateDecisionPayload; options?: OptimisticableActionOptions }>()
);

export const patchDecision = createAction(
    '[Decisions] Patch',
    props<{ payload: PatchDecisionPayload; options?: OptimisticableActionOptions }>()
);

export const patchDecisionOptimisticSuccess = createAction(
    '[Decisions] Patch optimistic success',
    props<IdPayload>()
);

export const patchDecisionSuccess = createAction(
    '[Decisions] Patch success',
    props<PatchDecisionPayload>()
);

export const patchDecisionFail = createAction(
    '[Decisions] Patch fail',
    props<{ error: any; originAction: Action; decisionId: string }>()
);

// Delete Decision decisions

export const deleteDecision = createAction(
    '[Decisions] Delete',
    props<{ payload: IdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteDecisionSuccess = createAction('[Decisions] Delete success', props<IdPayload>());

export const deleteDecisionFail = createAction(
    '[Decisions] Delete fail',
    props<{ error: any; originAction: Action }>()
);

// Submit Decision action

export const submitDecision = createAction(
    '[Decisions] Submit',
    props<{ payload: SubmitDecisionPayload }>()
);

// Manage Decision approval action

export const manageDecisionApproval = createAction(
    '[Decisions] Manage Approval',
    props<{ payload: ManageDecisionApprovalPayload }>()
);
