import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppOperationActions, AppOperationStore } from '../../../store/app-operation';

@Injectable()
export class RestResponseInterceptor implements HttpInterceptor {
    constructor(private store: Store<AppOperationStore.State>) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        // do stuff with response if you want
                    }
                },
                (error: any) => {
                    if (error instanceof HttpErrorResponse) {
                        if (this.userUnathorized(error)) {
                            this.store.dispatch(
                                new AppOperationActions.UserNotAuthorizedResponseReceived()
                            );
                        } else {
                            // TODO: process correctly all the errors
                            // this.store.dispatch(new appOperationActions.SetApiServerError({ error }));
                        }
                    }
                }
            )
        );
    }

    private userUnathorized(errorResponse: HttpErrorResponse): boolean {
        return errorResponse.status === 401;
    }
}
