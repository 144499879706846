import {
    Comparer,
    createEntityAdapter,
    EntityAdapter,
    EntityState,
    IdSelector,
} from '@ngrx/entity';
import { EntitySelectors } from '@ngrx/entity/src/models';
import * as routerStore from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AccountStore } from './account';
import { ActionStore } from './actions';
import { AllocationStore } from './allocation';
import { AppOperationStore } from './app-operation';
import { ApprovalRequestStore } from './approval-request';
import { BacklogTaskStore } from './backlog-task';
import { BudgetTableStore } from './budget-table';
import { CalendarStore } from './calendar';
import { ChangeRequestSelectors, ChangeRequestStore } from './change-request';
import { CommentStore } from './comment';
import { CommunicationStore } from './communication';
import { DecisionStore } from './decision';
import { DocumentSelectors, DocumentStore, FileNode } from './document';
import { ExecutiveDashboardStore } from './executive-dashboard';
import { FolderStore } from './folder';
import { GlTableSettingsStore } from './gl-table-settings';
import { HandsontableSettingsStore } from './handsontable-settings';
import { IssueStore } from './issue';
import { KanbanBoardStore } from './kanban';
import { LayoutStore } from './layout';
import { MinuteStore } from './minute';
import { NotificationStore } from './notification';
import { PortfolioStore } from './portfolio';
import { ProgramSelectors, ProgramStore } from './program';
import { ProgramMemberStore } from './program-member';
import { ProgramRoleStore } from './program-role';
import { ProgramStatusStore } from './program-status-report';
import { ProjectSelectors, ProjectStore } from './project';
import { ProjectFieldStore } from './project-field';
import { ProjectMemberStore } from './project-member';
import { ProjectPhaseStore } from './project-phase';
import { ListContainerStore } from './project-phase-container';
import { ProjectRequestStore } from './project-request';
import { ProjectRoleStore } from './project-role';
import { ProjectStatusStore } from './project-status-report';
import { ProjectTemplateStore } from './project-template';
import { ReminderStore } from './reminder';
import { SiteMemberStore } from './resource';
import { RiskStore } from './risks';
import { ScheduleTaskStore } from './schedule-template';
import { RouterStateUrl } from './shared/router.helper';
import { SiteSelectors, SiteStore } from './site';
import { SiteRoleStore } from './site-role';
import { SiteSettingsStore } from './site-settings';
import { SprintStore } from './sprint';
import { StateEntry } from './state-entry';
import { SubscriptionStore } from './subscription';
import { SubscriptionPlanStore } from './subscription-plan';
import { TimesheetStore } from './timesheet';
import { UserLogStore } from './user-log';
import { VendorStore } from './vendor';

// Top level state interface is just a map of keys to inner state types

export interface AppState {
    router: routerStore.RouterReducerState<RouterStateUrl>;
    account: AccountStore.State;
    layout: LayoutStore.State;
    site: SiteStore.State;
    program: ProgramStore.State;
    project: ProjectStore.State;
    document: DocumentStore.State;
    appOperation: AppOperationStore.State;
    subscriptionPlan: SubscriptionPlanStore.State;
    subscription: SubscriptionStore.State;
    changeRequest: ChangeRequestStore.State;
    projectPhase: ProjectPhaseStore.State;
    calendar: CalendarStore.State;
    minute: MinuteStore.State;
    projectStatusReport: ProjectStatusStore.State;
    portfolio: PortfolioStore.State;
    projectTemplate: ProjectTemplateStore.State;
    communicationTable: CommunicationStore.State;
    projectRequest: ProjectRequestStore.State;
    siteMember: SiteMemberStore.State;
    allocation: AllocationStore.State;
    scheduleTask: ScheduleTaskStore.State;
    budgetTemplate: BudgetTableStore.State;
    projectField: ProjectFieldStore.State;
    approvalRequest: ApprovalRequestStore.State;
    reminder: ReminderStore.State;
    timesheet: TimesheetStore.State;
    kanban: KanbanBoardStore.State;
    comment: CommentStore.State;
    handsontableSettings: HandsontableSettingsStore.State;
    siteRole: SiteRoleStore.State;
    projectRole: ProjectRoleStore.State;
    programRole: ProgramRoleStore.State;
    projectMember: ProjectMemberStore.State;
    programMember: ProgramMemberStore.State;
    listContainer: ListContainerStore.State;
    risk: RiskStore.State;
    projectAction: ActionStore.State;
    issue: IssueStore.State;
    decision: DecisionStore.State;
    executiveDashboard: ExecutiveDashboardStore.State;
    folder: FolderStore.State;
    backlogTask: BacklogTaskStore.State;
    sprint: SprintStore.State;
    notification: NotificationStore.State;
    userLog: UserLogStore.State;
    siteSettings: SiteSettingsStore.State;
    programStatusReport: ProgramStatusStore.State;
    glTableSettings: GlTableSettingsStore.State;
    vendor: VendorStore.State;
}

export function getInitialState(): AppState {
    return {
        router: undefined,
        account: AccountStore.initialState,
        layout: LayoutStore.initialState,
        site: SiteStore.initialState,
        program: ProgramStore.initialState,
        project: ProjectStore.initialState,
        document: DocumentStore.initialState,
        appOperation: AppOperationStore.initialState,
        subscriptionPlan: SubscriptionPlanStore.initialState,
        subscription: SubscriptionStore.initialState,
        changeRequest: ChangeRequestStore.initialState,
        projectPhase: ProjectPhaseStore.initialState,
        calendar: CalendarStore.initialState,
        minute: MinuteStore.initialState,
        projectStatusReport: ProjectStatusStore.initialState,
        portfolio: PortfolioStore.initialState,
        projectTemplate: ProjectTemplateStore.initialState,
        communicationTable: CommunicationStore.initialState,
        projectRequest: ProjectRequestStore.initialState,
        siteMember: SiteMemberStore.initialState,
        allocation: AllocationStore.initialState,
        scheduleTask: ScheduleTaskStore.initialState,
        budgetTemplate: BudgetTableStore.initialState,
        projectField: ProjectFieldStore.initialState,
        approvalRequest: ApprovalRequestStore.initialState,
        reminder: ReminderStore.initialState,
        timesheet: TimesheetStore.initialState,
        kanban: KanbanBoardStore.initialState,
        comment: CommentStore.initialState,
        handsontableSettings: HandsontableSettingsStore.initialState,
        siteRole: SiteRoleStore.initialState,
        projectRole: ProjectRoleStore.initialState,
        programRole: ProgramRoleStore.initialState,
        projectMember: ProjectMemberStore.initialState,
        programMember: ProgramMemberStore.initialState,
        listContainer: ListContainerStore.initialState,
        risk: RiskStore.initialState,
        projectAction: ActionStore.initialState,
        issue: IssueStore.initialState,
        decision: DecisionStore.initialState,
        executiveDashboard: ExecutiveDashboardStore.initialState,
        folder: FolderStore.initialState,
        backlogTask: BacklogTaskStore.initialState,
        sprint: SprintStore.initialState,
        notification: NotificationStore.initialState,
        userLog: UserLogStore.initialState,
        siteSettings: SiteSettingsStore.initialState,
        programStatusReport: ProgramStatusStore.initialState,
        glTableSettings: GlTableSettingsStore.initialState,
        vendor: VendorStore.initialState,
    };
}

export const selectRouterState = createFeatureSelector<
    routerStore.RouterReducerState<RouterStateUrl>
>(StateEntry.Router);

// Selectors that depend on more than one State entry

export const selectPrograms = createSelector(
    ProgramSelectors.selectNotArchivedPrograms,
    SiteSelectors.selectCurrentSiteId,
    (programs, siteId) => programs.filter((program) => program.siteId === siteId)
);

export const selectProjects = createSelector(
    ProjectSelectors.selectNotArchivedProjects,
    SiteSelectors.selectCurrentSiteId,
    (projects, siteId) => projects.filter((project) => project.siteId === siteId)
);

export const selectDocumentsByChangeRequestId = createSelector(
    ChangeRequestSelectors.selectAll,
    DocumentSelectors.selectCurrentProjectDocuments,
    (changeRequests, documents) => {
        const changeRequestDocumentsDictionary: { [id: string]: FileNode[] } = {};
        changeRequests.forEach((cr) => {
            const crDocs = documents.filter((doc) => doc.originObjectId === cr.id);
            changeRequestDocumentsDictionary[cr.id] = crDocs;
        });
        return changeRequestDocumentsDictionary;
    }
);

export interface CtxEntityState<T> extends EntityState<T> {
    addingInProgressEntityIds: string[];
}

export interface CtxEntitySelectors<T, S> extends EntitySelectors<T, S> {
    selectEntityStoredInDb: (entityId: string) => (state: S) => boolean;
    selectManyEntitiesStoredInDb: (entityIds: string[]) => (state: S) => boolean;
}

export interface CtxEntityAdapter<T> extends EntityAdapter<T> {
    addOneOptimistic<S extends CtxEntityState<T>>(entity: T, state: S): S;
    addOneOptimisticSuccess<S extends CtxEntityState<T>>(entity: T, state: S): S;
    addOneOptimisticFail<S extends CtxEntityState<T>>(entityId: string, state: S): S;
    getCtxInitialState<S extends object>(state: S): CtxEntityState<T> & S;
    getCtxSelectors<V>(selectState: (state: V) => CtxEntityState<T>): CtxEntitySelectors<T, V>;
}

export function createCtxEntityAdapter<T>(
    options: {
        selectId?: IdSelector<T>;
        sortComparer?: false | Comparer<T>;
    } = {}
): CtxEntityAdapter<T> {
    const entityAdapter = createEntityAdapter(options);

    function addOneOptimistic<S extends CtxEntityState<T>>(entity: T, state: S): S {
        return {
            ...entityAdapter.addOne(entity, state),
            addingInProgressEntityIds: [...state.addingInProgressEntityIds, entity['id']],
        };
    }

    function addOneOptimisticSuccess<S extends CtxEntityState<T>>(entity: T, state: S): S {
        return {
            ...state,
            addingInProgressEntityIds: state.addingInProgressEntityIds.filter(
                (id) => id !== entity['id']
            ),
        };
    }

    function addOneOptimisticFail<S extends CtxEntityState<T>>(entityId: string, state: S): S {
        return {
            ...state,
            addingInProgressEntityIds: state.addingInProgressEntityIds.filter(
                (id) => id !== entityId
            ),
        };
    }

    function getCtxInitialState<S extends object>(state: S): CtxEntityState<T> & S {
        return { ...entityAdapter.getInitialState(state), addingInProgressEntityIds: [] };
    }

    function getCtxSelectors<V>(
        selectState: (state: V) => CtxEntityState<T>
    ): CtxEntitySelectors<T, V> {
        const selectEntityStoredInDb = (entityId: string) =>
            createSelector(
                selectState,
                (state) => !state.addingInProgressEntityIds.includes(entityId)
            );

        const selectManyEntitiesStoredInDb = (entityIds: string[]) =>
            createSelector(
                selectState,
                (state) => !state.addingInProgressEntityIds.some((id) => entityIds.includes(id))
            );
        return {
            ...entityAdapter.getSelectors<V>(selectState),
            selectEntityStoredInDb: selectEntityStoredInDb,
            selectManyEntitiesStoredInDb: selectManyEntitiesStoredInDb,
        };
    }

    return {
        ...entityAdapter,
        addOneOptimistic: addOneOptimistic,
        getCtxInitialState: getCtxInitialState,
        addOneOptimisticSuccess: addOneOptimisticSuccess,
        addOneOptimisticFail: addOneOptimisticFail,
        getCtxSelectors: getCtxSelectors,
    };
}
