export interface AsyncUpdateStatus {
    // If true, the data is already being loaded and stored
    loaded?: boolean;
    /**
     * We use such a specific name for the AppState serializing purposes. The value of this property
     * should be replaced on false when saving the AppState to local storage
     * If true, the new request for full data has been sent
     */
    serverRequestInProgress?: boolean;
    // If true, the last performed request failed
    loadingFailed?: boolean;
    // May contain an error if loadingFailed = true
    error?: string;
}

export const AsyncUpdateStatusDefault: AsyncUpdateStatus = {
    serverRequestInProgress: false,
    loadingFailed: false,
    error: null,
};

export const AsyncUpdateStatusDefaultLoad: AsyncUpdateStatus = {
    serverRequestInProgress: true,
    loadingFailed: false,
    error: null,
};

export const AsyncUpdateStatusDefaultLoadSuccess: AsyncUpdateStatus = {
    loaded: true,
    serverRequestInProgress: false,
};

export const getAsyncUpdateStatus = (state: AsyncUpdateStatus): AsyncUpdateStatus => {
    return {
        loaded: state.loaded,
        serverRequestInProgress: state.serverRequestInProgress,
        loadingFailed: state.loadingFailed,
        error: state.error,
    };
};
