import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import {
    LoadByProjectIdPayload,
    LoadBySiteIdPayload,
} from '../../../../store/shared/models/load.payload';
import { IdPayload } from '../../../../store/shared/models/shared-payloads';
import { AddIssuePayload, PatchIssuePayload } from './../../../../store/issue/issue.payloads';

export const getIssueCommand = (payload: IdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projectIssues', value: payload.id }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadIssuesByProjectIdCommand = (
    payload: LoadByProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projects', value: payload.projectId }, { name: 'issues' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadIssuesBySiteIdCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'issues' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addIssueCommand = (payload: AddIssuePayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projectIssues' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchIssueCommand = (payload: PatchIssuePayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projectIssues', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteIssueCommand = (payload: IdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projectIssues', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};
