export interface State {
    isReady: boolean;
    apiServerError: any;
    userNotAuthorizedResponseReceived: boolean;
    authenticationRedirectUrl: string;
}

export const initialState: State = {
    isReady: false,
    apiServerError: null,
    userNotAuthorizedResponseReceived: false,
    authenticationRedirectUrl: '',
};
