export enum StateEntry {
    Router = 'router',
    Auth = 'auth',
    Account = 'account',
    Layout = 'layout',
    Site = 'site',
    Program = 'program',
    Project = 'project',
    Folder = 'folder',
    Document = 'document',
    AppOperation = 'appOperation',
    SubscriptionPlan = 'subscriptionPlan',
    Subscription = 'subscription',
    ChangeRequest = 'changeRequest',
    ProjectPhase = 'projectPhase',
    ListContainer = 'listContainer',
    Calendar = 'calendar',
    Minute = 'minute',
    ProjectBoard = 'projectBoard',
    ProjectStatusReport = 'projectStatusReport',
    ProgramStatusReport = 'programStatusReport',
    Portfolio = 'portfolio',
    ProjectTemplate = 'projectTemplate',
    Communication = 'communicationTable',
    ProjectRequest = 'projectRequest',
    SiteMember = 'siteMember',
    ProjectMember = 'projectMember',
    ProgramMember = 'programMember',
    Allocation = 'allocation',
    ScheduleTask = 'scheduleTask',
    BudgetTemplate = 'budgetTemplate',
    ProjectField = 'projectField',
    ApprovalRequest = 'approvalRequest',
    Reminder = 'reminder',
    Timesheet = 'timesheet',
    Kanban = 'kanban',
    Comment = 'comment',
    HandsontableSettings = 'handsontableSettings',
    SiteRole = 'siteRole',
    ProjectRole = 'projectRole',
    ProgramRole = 'programRole',
    Risk = 'risk',
    Action = 'projectAction',
    Issue = 'issue',
    Decision = 'decision',
    ExecutiveDashboard = 'executiveDashboard',
    BacklogTask = 'backlogTask',
    Sprint = 'sprint',
    Notification = 'notification',
    UserLog = 'userLog',
    SiteSettings = 'siteSettings',
    GlTableSettings = 'glTableSettings',
    Vendor = 'vendor',
}
