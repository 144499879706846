import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EntityPatchSuccessResponse } from 'app/core/services/rest-api/features/patch.response';
import { SnackBarService } from 'app/services/snack-bar/snack-bar.service';
import { getPatch } from 'app/utils/json-patch';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    first,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { RiskActions, RiskSelectors } from '.';
import { AppStore } from '..';
import { RestApiService } from '../../services/rest-api';
import { CtxFieldItemType, ProjectFieldSelectors, ProjectFieldType } from '../project-field';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import { RiskRecord } from './models/risk.model';

@Injectable()
export class RiskEffects {
    getRisk$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RiskActions.getRisk),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        RiskActions.getRiskSuccess({
                            risk: this.getRiskFromDto(response.payload),
                        })
                    ),
                    catchError((error) => of(RiskActions.getRiskFail({ error })))
                )
            )
        )
    );

    loadRiskBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RiskActions.loadRisksBySiteId),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        RiskActions.loadRisksBySiteIdSuccess({
                            risks: response.payload.map((dto) => this.getRiskFromDto(dto)),
                        })
                    ),
                    catchError((error) => of(RiskActions.loadRisksBySiteIdFail({ error })))
                )
            )
        )
    );

    loadRiskByProjectId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RiskActions.loadRisksByProjectId),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        RiskActions.loadRisksByProjectIdSuccess({
                            risks: response.payload.map((dto) => this.getRiskFromDto(dto)),
                        })
                    ),
                    catchError((error) => of(RiskActions.loadRisksByProjectIdFail({ error })))
                )
            )
        )
    );

    addRisk$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RiskActions.addRisk),
            mergeMap((action) => {
                if (!action.options?.optimistic) {
                    this.snackBar.showInfiniteBar('Adding a new Risk...');
                }
                return this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        if (action.options?.optimistic) this.snackBar.hide();
                        return RiskActions.addRiskSuccess({
                            risk: this.getRiskFromDto(response.payload),
                            options: action.options,
                        });
                    }),
                    catchError((error) => {
                        this.snackBar.hide();
                        return of(
                            RiskActions.addRiskFail({
                                error,
                                originAction: action,
                                riskId: action.payload.id,
                            })
                        );
                    })
                );
            })
        )
    );

    updateRisk$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RiskActions.updateRisk),
            withLatestFrom(
                this.store$.select(RiskSelectors.selectAll),
                this.store$.select(
                    ProjectFieldSelectors.selectCurrentSiteProjectFieldByType(
                        ProjectFieldType.RisksStatus
                    )
                )
            ),
            mergeMap(([action, risks, riskStatusField]) => {
                const timesheet = risks.find((r) => r.id === action.payload.id);
                const closedStatus = riskStatusField.items.find(
                    (i) => i.ctxItemType === CtxFieldItemType.Closed
                );
                const risk = risks.find((r) => r.id === action.payload.id);
                let changes: Partial<RiskRecord> = { ...action.payload.changes };
                const updatedRisk = { ...risk, ...changes };
                if (changes.statusId === closedStatus.id) {
                    changes = { ...changes, closingDate: new Date() };
                }
                if (changes.closingDate) {
                    changes = { ...changes, statusId: closedStatus.id };
                }
                if (updatedRisk.statusId === closedStatus.id && changes.closingDate === null) {
                    delete changes.closingDate;
                }
                if (changes.statusId && changes.statusId !== closedStatus.id) {
                    changes = { ...changes, closingDate: null };
                }
                if (changes.dateRaised === null) {
                    delete changes.dateRaised;
                }

                const creationDateFieldName: keyof RiskRecord = 'creationDate';
                const patch = getPatch(timesheet, changes).filter(
                    (op) => !op.path.includes(creationDateFieldName)
                );
                return of(
                    RiskActions.patchRisk({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchRisk$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RiskActions.patchRisk),
            filter((action) => !!action.payload.patch?.length),
            mergeMap((action) =>
                this.store$.select(RiskSelectors.selectEntityStoredInDb(action.payload.id)).pipe(
                    first((stored) => stored),
                    switchMap(() =>
                        this.restApiService.process(action).pipe(
                            map((response: EntityPatchSuccessResponse) => {
                                if (action.options?.optimistic) return okEmptyAction();
                                return RiskActions.patchRiskSuccess({
                                    id: action.payload.id,
                                    patch: response.payload,
                                });
                            }),
                            catchError((error) =>
                                of(RiskActions.patchRiskFail({ error, originAction: action }))
                            )
                        )
                    )
                )
            )
        )
    );

    deleteRisk$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RiskActions.deleteRisk),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return RiskActions.deleteRiskSuccess({ id: action.payload.id });
                    }),
                    catchError((error) =>
                        of(RiskActions.deleteRiskFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>,
        private snackBar: SnackBarService
    ) {}

    getRiskFromDto(dto: any): RiskRecord {
        return {
            ...Model.createFromDto(RiskRecord, dto),
            dateRaised: dto.dateRaised ? new Date(dto.dateRaised) : undefined,
        };
    }
}
