import { ImportSystemTemplatePayload } from 'app/core/models/shared-models';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import {
    AddCalendarPayload,
    CalendarIdPayload,
    ChangeProjectCalendarTemplatePayload,
    LoadCalendarByProjectIdPayload,
    LoadCalendarsBySiteIdPayload,
    PatchCalendarPayload,
} from './../../../../store/calendar/calendar.payloads';

export const loadCompletixCalendarTemplates = (payload: any, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projectCalendars/completixTemplates' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadCompletixCalendarTemplatesBySiteId = (
    payload: LoadCalendarsBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'calendars/completixTemplates' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadCalendarsBySiteId = (
    payload: LoadCalendarsBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'calendars' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadCalendarByProjectId = (
    payload: LoadCalendarByProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projects', value: payload.projectId }, { name: 'calendar' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const importCalendar = (
    payload: ImportSystemTemplatePayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectCalendars/completixTemplates/import' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const addCalendar = (payload: AddCalendarPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projectCalendars' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const cloneCalendar = (payload: CalendarIdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projectCalendars' }, { name: 'clone', value: payload.id }]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const changeProjectCalendarTemplate = (
    payload: ChangeProjectCalendarTemplatePayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projects', value: payload.projectId },
        { name: 'calendar', value: payload.calendarTemplateId },
    ]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchCalendar = (payload: PatchCalendarPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projectCalendars', value: payload.id }]);
    baseCommand.payload.setData({
        patch: payload.patch,
        scheduleTaskPatches: payload.scheduleTaskPatches,
        listContainer: payload.listContainer,
    });
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteCalendar = (payload: CalendarIdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projectCalendars', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};
