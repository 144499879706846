import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StateEntry } from '../state-entry';
import { adapter, LoadingStatus, State } from './comment.store';
import { CtxCommentLink } from './models';

export const selectCommentEntry = createFeatureSelector<State>(StateEntry.Comment);

export const selectLoadingStatus = createSelector(
    selectCommentEntry,
    (state): LoadingStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
    })
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectCommentEntry);

const selectSortedComments = createSelector(selectAll, (comments) => comments.reverse());

export const selectComments = (link: CtxCommentLink, attachedDataId: string) =>
    createSelector(selectSortedComments, (comments) =>
        comments.filter((c) => c.link === link && c.originObjectId === attachedDataId)
    );

export const selectCommentsByLink = (link: CtxCommentLink) =>
    createSelector(selectSortedComments, (comments) => comments.filter((c) => c.link === link));

export const selectObjectIdsReferredByComments = (link: CtxCommentLink) =>
    createSelector(selectCommentsByLink(link), (comments) => {
        const objectIds = comments.map((c) => c.originObjectId).filter((id) => !!id);
        return new Set(objectIds);
    });
