import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectSelectors } from '../project';
import { selectCurrentProjectId } from '../project/project.selectors';
import { selectCurrentSiteId } from '../site/site.selectors';
import { StateEntry } from '../state-entry';
import { adapter, State } from './vendor.store';

export const selectVendorEntry = createFeatureSelector<State>(StateEntry.Vendor);

export const selectLoadingStatusesPerSiteId = createSelector(
    selectVendorEntry,
    (state) => state.loadingStatusBySiteId
);

export const selectLoadingStatusForCurrentSite = createSelector(
    selectLoadingStatusesPerSiteId,
    selectCurrentSiteId,
    (stastusMap, siteId) => stastusMap[siteId]
);

export const selectLoadingStatusesPerProjectId = createSelector(
    selectVendorEntry,
    (state) => state.loadingStatusByProjectId
);

export const selectLoadingStatusForCurrentProject = createSelector(
    selectLoadingStatusesPerProjectId,
    selectCurrentProjectId,
    (statusMap, projectId) => statusMap[projectId]
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectVendorEntry);

export const selectCurrentProjectVendors = createSelector(
    selectAll,
    ProjectSelectors.selectCurrentProjectId,
    (vendors, projectId) => vendors.filter((r) => r.projectId === projectId).reverse()
);
