import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Calendar } from 'app/core/models/calendar';
import { EntityPatchSuccessResponse } from 'app/core/services/rest-api/features/patch.response';
import { ImportMicrosoftProjectResponse } from 'app/core/services/rest-api/features/project/project.responses';
import { ProjectActions, ProjectSelectors } from 'app/core/store/project';
import { SnackBarService } from 'app/services/snack-bar/snack-bar.service';
import { getPatch } from 'app/utils/json-patch';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { AppStore } from '..';
import { RestApiService } from '../../services/rest-api';
import { ProjectResponses } from '../../services/rest-api/features/project';
import { CalendarActions } from '../calendar';
import { GlTableSettingsActions } from '../gl-table-settings';
import { ListContainerActions } from '../project-phase-container';
import { ScheduleTask, ScheduleTaskActions } from '../schedule-template';
import { Model } from '../shared/models/base.model';
import { ProjectPermissions } from './../shared/models/shared-permissions.model';
import { okEmptyAction } from './project.actions';
import { Project, ProjectSettings } from './project.model';

@Injectable()
export class ProjectsEffects {
    getProjectsByProgram$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.getProjectsByProgram),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        response.payload.map((dto) => {
                            const model = Model.createFromDto(Project, dto);
                            return model;
                        })
                    ),
                    map((projects: Project[]) =>
                        ProjectActions.getProjectsByProgramSuccess({ projects })
                    ),
                    catchError((error: any) =>
                        of(ProjectActions.getProjectsByProgramFail({ error }))
                    )
                )
            )
        )
    );

    getProjectsBySite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.getProjectsBySite),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    switchMap((response: ProjectResponses.GetProjectsResponse) => {
                        return [
                            ...response.payload.settings.map((s) =>
                                ProjectActions.getProjectSettingsSuccess({
                                    settings: Model.createFromDto(ProjectSettings, s),
                                })
                            ),
                            ...response.payload.settings
                                .filter((s) => s.calendar)
                                .map((s) =>
                                    CalendarActions.loadCalendarByProjectIdSuccess({
                                        calendar: Model.createFromDto(Calendar, s.calendar),
                                    })
                                ),
                            ProjectActions.getProjectsBySiteSuccess({
                                projects: response.payload.projects.map((dto) => {
                                    const model = Model.createFromDto(Project, dto);
                                    return model;
                                }),
                            }),
                        ];
                    }),
                    catchError((error: any) => of(ProjectActions.getProjectsBySiteFail({ error })))
                )
            )
        )
    );

    getProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.getProject),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        ProjectActions.getProjectSuccess({
                            project: Model.createFromDto(Project, response.payload),
                        })
                    ),
                    catchError((error: any) => of(ProjectActions.getProjectFail({ error })))
                )
            )
        )
    );

    getInitialProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.getInitialProject),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    switchMap((response: ProjectResponses.GetInitialProjectResponse) => {
                        if (!response.payload) {
                            return [ProjectActions.setInitialProjectLoaded({ projectId: null })];
                        }

                        return [
                            ProjectActions.getProjectSuccess({
                                project: Model.createFromDto(Project, response.payload.project),
                            }),
                            ProjectActions.getProjectPermissionsSuccess({
                                permissions: response.payload.permissions as ProjectPermissions,
                            }),
                            ProjectActions.getProjectSettingsSuccess({
                                settings: Model.createFromDto(
                                    ProjectSettings,
                                    response.payload.settings
                                ),
                            }),
                            CalendarActions.loadCalendarByProjectIdSuccess({
                                calendar: Model.createFromDto(
                                    Calendar,
                                    response.payload.settings.calendar
                                ),
                            }),
                            ProjectActions.setInitialProjectLoaded({
                                projectId: response.payload.project.id,
                            }),
                        ];
                    }),
                    catchError((error: any) => of(ProjectActions.getInitialProjectFail({ error })))
                )
            )
        )
    );

    getProjectPermissions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.getProjectPermissions),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        ProjectActions.getProjectPermissionsSuccess({
                            permissions: response.payload as ProjectPermissions,
                        })
                    ),
                    catchError((error: any) =>
                        of(ProjectActions.getProjectPermissionsFail({ error }))
                    )
                )
            )
        )
    );

    getProjectSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.getProjectSettings),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    switchMap((response: ProjectResponses.GetProjectSettingsResponse) => {
                        return [
                            ProjectActions.getProjectSettingsSuccess({
                                settings: Model.createFromDto(ProjectSettings, response.payload),
                            }),
                            CalendarActions.loadCalendarByProjectIdSuccess({
                                calendar: Model.createFromDto(Calendar, response.payload.calendar),
                            }),
                        ];
                    }),
                    catchError((error: any) =>
                        of(
                            ProjectActions.getProjectSettingsFail({
                                error,
                                projectId: action.payload.projectId,
                            })
                        )
                    )
                )
            )
        )
    );

    updateProjectSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.updateProjectSettings),
            withLatestFrom(this.store$.select(ProjectSelectors.selectCurrentProjectSettings)),
            mergeMap(([action, settings]) => {
                const patch = getPatch(settings, action.payload.changes);
                return of(
                    ProjectActions.patchProjectSettings({
                        payload: { projectId: action.payload.projectId, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchProjectSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.patchProjectSettings),
            filter((action) => !!action.payload.patch?.length),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: EntityPatchSuccessResponse) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ProjectActions.patchProjectSettingsSuccess({
                            projectId: action.payload.projectId,
                            patch: response.payload,
                        });
                    }),
                    catchError((error: any) =>
                        of(ProjectActions.patchProjectSettingsFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    toggleProjectPageLock$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.toggleProjectPageLock),
            withLatestFrom(
                this.store$.select(ProjectSelectors.selectCurrentProjectSettings),
                this.store$.select(ProjectSelectors.selectCurrentProjectId)
            ),
            mergeMap(([action, settings, projectId]) => {
                const changes: Partial<ProjectSettings> = {
                    lockedPages: {
                        ...settings.lockedPages,
                        [action.payload.pageKey]: !settings.lockedPages[action.payload.pageKey],
                    },
                };
                return of(
                    ProjectActions.updateProjectSettings({
                        payload: { changes, projectId },
                        options: action.options,
                    })
                );
            })
        )
    );

    addProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.addProject),
            mergeMap((action) => {
                this.snackBar.showInfiniteBar('Creating a new Project...');

                return this.restApiService.process(action).pipe(
                    map((response) => {
                        this.snackBar.hide();
                        const project = Model.createFromDto(Project, {
                            ...response.payload,
                        });
                        return ProjectActions.addProjectSuccess({ project });
                    }),
                    catchError((error: any) => {
                        this.snackBar.hide();
                        return of(ProjectActions.addProjectFail({ error }));
                    })
                );
            })
        )
    );

    updateProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.updateProject),
            withLatestFrom(this.store$.select(ProjectSelectors.selectAll)),
            concatMap(([action, projects]) => {
                const updatingProject = projects.find((p) => p.id === action.payload.projectId);
                const patch = getPatch(updatingProject, action.payload.changes);
                return of(
                    ProjectActions.patchProject({
                        payload: { id: action.payload.projectId, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.patchProject),
            filter((action) => !!action.payload.patch?.length),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: EntityPatchSuccessResponse) => {
                        if (action.options?.optimistic) return ProjectActions.okEmptyAction();
                        return ProjectActions.patchProjectSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error: any) => {
                        this.snackBar.show('Change failed', 1500);
                        return of(ProjectActions.patchProjectFail({ error, originAction: action }));
                    })
                )
            )
        )
    );

    archiveProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.archiveProject),
            mergeMap((action) => {
                this.snackBar.show('Archiving...', 2000);
                return this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        ProjectActions.archiveProjectSuccess({
                            project: Model.createFromDto(Project, response.payload),
                        })
                    ),
                    catchError((error: any) => of(ProjectActions.archiveProjectFail({ error })))
                );
            })
        )
    );

    unarchiveProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.unarchiveProject),
            mergeMap((action) => {
                this.snackBar.show('Unarchiving...', 2000);
                return this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        ProjectActions.unarchiveProjectSuccess({
                            project: Model.createFromDto(Project, response.payload),
                        })
                    ),
                    catchError((error: any) => of(ProjectActions.unarchiveProjectFail({ error })))
                );
            })
        )
    );

    deleteProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.deleteProject),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => ProjectActions.deleteProjectSuccess(action.payload)),
                    catchError((error: any) =>
                        of(ProjectActions.deleteProjectFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    getRaciTableByProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.getRaciTableByProject),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        ProjectActions.getRaciTableByProjectSuccess({ table: response.payload })
                    ),
                    catchError((error: any) =>
                        of(ProjectActions.getRaciTableByProjectFail({ error }))
                    )
                )
            )
        )
    );

    updateRaciTable$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.updateRaciTable),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ProjectActions.updateRaciTableSuccess(action.payload);
                    }),
                    catchError((error: any) =>
                        of(ProjectActions.updateRaciTableFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    importMicrosoftProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.importMicrosoftProject),
            mergeMap((action) => {
                this.snackBar.showInfiniteBar('Importing Microsoft project...');
                return this.restApiService.process(action).pipe(
                    switchMap((response: ImportMicrosoftProjectResponse) => {
                        this.snackBar.hide();
                        return [
                            ScheduleTaskActions.importMicrosoftProjectTasks({
                                listContainerId: response.payload.listContainerPatch.id,
                                tasks: response.payload.tasks.map((t) =>
                                    Model.createFromDto(ScheduleTask, t)
                                ),
                            }),
                            GlTableSettingsActions.patchGlTableSettingsSuccess({
                                id: response.payload.glTableSettingsPatch.id,
                                patch: response.payload.glTableSettingsPatch.patch,
                            }),
                            ListContainerActions.patchListContainerSuccess(
                                response.payload.listContainerPatch
                            ),
                        ];
                    }),
                    catchError((error: any) => {
                        this.snackBar.hide();
                        return of(ProjectActions.importMicrosoftProjectFail({ error }));
                    })
                );
            })
        )
    );

    constructor(
        private store$: Store<AppStore.AppState>,
        private actions$: Actions,
        private restApiService: RestApiService,
        private snackBar: SnackBarService
    ) {}
}
