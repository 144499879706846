import { Model } from 'app/core/store/shared/models/base.model';
import { applyPatch, generate, observe, Operation } from 'fast-json-patch';
import { deepClone } from 'fast-json-patch/module/core';

export function getPatch<T>(initialModel: T, changes: Partial<T>): Operation[] {
    const initialClone = deepClone(initialModel);
    const observer = observe(initialClone);
    Object.assign(initialClone, changes);
    return generate(observer);
}

export function getPatched<T>(document: T, patch: Operation[]): T {
    const patchedDoc = applyPatch(document, patch, false, false).newDocument;
    // Recreate the document in order to restore its prototype
    // and Date values that get wiped out after patch
    const rebuiltDoc = Model.createFromDto(
        Object.getPrototypeOf(document).constructor as new () => T,
        patchedDoc
    );
    return rebuiltDoc;
}
