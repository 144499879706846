import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SubscriptionPlan } from 'app/core/models/billing';
import { AsyncUpdateStatus } from '../shared/async-update-status.store';

export interface State extends EntityState<SubscriptionPlan>, AsyncUpdateStatus {
    // additional entities state properties
}

export const adapter: EntityAdapter<SubscriptionPlan> = createEntityAdapter<SubscriptionPlan>();

export const initialState: State = adapter.getInitialState({
    // additional entity state properties
    serverRequestInProgress: false,
    loadingFailed: false,
    loaded: false,
    error: null,
});
