import { Action, createAction, props } from '@ngrx/store';
import { OptimisticableActionOptions } from 'app/core/store/shared/optimisticable-action';
import { IdPayload } from '../shared/models/shared-payloads';
import {
    AddFolderPayload,
    LoadFoldersPayload,
    PatchFolderPayload,
    UpdateFolderPayload
} from './folder.payloads';
import { Folder } from './models';

// Load Folders actions

export const loadFoldersByTag = createAction(
    '[Folder] Load',
    props<{ payload: LoadFoldersPayload }>()
);

export const loadFoldersSuccess = createAction(
    '[Folder] Load success',
    props<{ folders: Folder[] }>()
);

export const loadFoldersFail = createAction('[Folder] Load fail', props<{ error: any }>());

// Add Folder actions

export const addFolder = createAction('[Folder] Add', props<{ payload: AddFolderPayload }>());

export const addFolderSuccess = createAction('[Folder] Add success', props<{ folder: Folder }>());

export const addFolderFail = createAction('[Folder] Add fail', props<{ error: any }>());

// Update Folder actions

export const updateFolder = createAction(
    '[Folder] Update',
    props<{ payload: UpdateFolderPayload; options?: OptimisticableActionOptions }>()
);

export const patchFolder = createAction(
    '[Folder] Patch',
    props<{ payload: PatchFolderPayload; options?: OptimisticableActionOptions }>()
);

export const patchFolderSuccess = createAction(
    '[Folder] Patch success',
    props<PatchFolderPayload>()
);

export const patchFolderFail = createAction(
    '[Folder] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Delete Folder actions

export const deleteFolder = createAction(
    '[Folder] Delete',
    props<{ payload: IdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteFolderSuccess = createAction('[Folder] Delete success', props<IdPayload>());

export const deleteFolderFail = createAction(
    '[Folder] Delete fail',
    props<{ error: any; originAction: Action }>()
);
