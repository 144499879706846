import { Action, createAction, props } from '@ngrx/store';
import { ImportSystemTemplatePayload } from 'app/core/models/shared-models';
import { OptimisticableActionOptions } from 'app/core/store/shared/optimisticable-action';
import { Communication } from '.';
import { LoadByProjectIdPayload, LoadBySiteIdPayload } from './../shared/models/load.payload';
import {
    AddCommunicationPayload,
    ChangeProjectCommunicationTemplatePayload,
    CommunicationIdPayload,
    PatchCommunicationPayload,
    UpdateCommunicationPayload,
} from './communication.payloads';

// Get Communication by project actions

export const loadCommunicationByProjectId = createAction(
    '[Communication] Load by project id',
    props<{ payload: LoadByProjectIdPayload }>()
);

export const loadCommunicationByProjectIdSuccess = createAction(
    '[Communication] Load by project id success',
    props<{ communication: Communication }>()
);

export const loadCommunicationByProjectIdFail = createAction(
    '[Communication] Load by project idfail',
    props<{ error: any }>()
);

// Load Completix Communication templates

export const loadCompletixCommunicationTemplates = createAction(
    '[Communication] Load Completix templates'
);

export const loadCompletixCommunicationTemplatesSuccess = createAction(
    '[Communication] Load Completix templates success',
    props<{ communications: Communication[] }>()
);

export const loadCompletixCommunicationTemplatesFail = createAction(
    '[Communication] Load Completix templates fail',
    props<{ error: any }>()
);

// Load Communication by site

export const loadCompletixCommunicationTemplatesBySiteId = createAction(
    '[Communication] Load Completix templates by site id',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadCommunicationBySiteId = createAction(
    '[Communication] Load by site id',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadCommunicationBySiteIdSuccess = createAction(
    '[Communication] Load by site id success',
    props<{ communications: Communication[] }>()
);

export const loadCommunicationBySiteIdFail = createAction(
    '[Communication] Load by site id fail',
    props<{ error: any }>()
);

// Add Communication actions

export const importCompletixCommunicationTemplate = createAction(
    '[Communication] Import Completix Communication template',
    props<{ payload: ImportSystemTemplatePayload }>()
);

export const addCommunication = createAction(
    '[Communication] Add',
    props<{ payload: AddCommunicationPayload }>()
);

export const addCommunicationSuccess = createAction(
    '[Communication] Add success',
    props<{ communication: Communication }>()
);

export const addCommunicationFail = createAction(
    '[Communication] Add fail',
    props<{ error: any }>()
);

// Add Communication record actions

export const addCommunicationRecord = createAction(
    '[Communication Row] Add',
    props<CommunicationIdPayload>()
);

// Update Communication actions

export const updateCommunication = createAction(
    '[Communication] Update',
    props<{ payload: UpdateCommunicationPayload; options?: OptimisticableActionOptions }>()
);

export const patchCommunication = createAction(
    '[Communication] Patch',
    props<{ payload: PatchCommunicationPayload; options?: OptimisticableActionOptions }>()
);

export const patchCommunicationSuccess = createAction(
    '[Communication] Patch success',
    props<PatchCommunicationPayload>()
);

export const patchCommunicationFail = createAction(
    '[Communication] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Delete Communication actions

export const deleteCommunication = createAction(
    '[Communication] Delete',
    props<{ payload: CommunicationIdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteCommunicationSuccess = createAction(
    '[Communication] Delete success',
    props<CommunicationIdPayload>()
);

export const deleteCommunicationFail = createAction(
    '[Communication] Delete fail',
    props<{ error: any; originAction: Action }>()
);

// Change project communication
export const changeProjectCommunicationTemplate = createAction(
    '[Communication] Change template',
    props<{ payload: ChangeProjectCommunicationTemplatePayload }>()
);

export const changeProjectCommunicationTemplateSuccess = createAction(
    '[Communication] Change template success',
    props<{ communication: Communication }>()
);

export const changeProjectCommunicationTemplateFail = createAction(
    '[Communication] Change template fail',
    props<{ error: any }>()
);
