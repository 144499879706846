import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PortfolioSelectors } from 'app/core/store/portfolio';
import { getPatch } from 'app/utils/json-patch';
import { CommandResult } from 'app/utils/network/commands';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppStore } from '..';
import { RestApiService } from '../../services/rest-api';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import { LoadPortfoliosResponse } from './../../services/rest-api/features/portfolio/portfolio.responses';
import { Portfolio } from './models';
import * as PortfolioActions from './portfolio.actions';

@Injectable()
export class PortfolioEffects {
    loadPorfolios$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PortfolioActions.loadPortfolios),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: LoadPortfoliosResponse) =>
                        PortfolioActions.loadPortfoliosSuccess({
                            portfolios: response.payload.map((dto) =>
                                Model.createFromDto(Portfolio, dto)
                            ),
                        })
                    ),
                    catchError((error) => of(PortfolioActions.loadPortfoliosFail({ error })))
                )
            )
        )
    );

    addPortfolio$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PortfolioActions.addPortfolio),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        PortfolioActions.addPortfolioSuccess({
                            portfolio: Model.createFromDto(Portfolio, response.payload),
                        })
                    ),
                    catchError((error) => of(PortfolioActions.addPortfolioFail({ error })))
                )
            )
        )
    );

    updatePortfolio$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PortfolioActions.updatePortfolio),
            withLatestFrom(this.store$.select(PortfolioSelectors.selectAll)),
            mergeMap(([action, portfolios]) => {
                const portfolio = portfolios.find((p) => p.id === action.payload.id);
                const patch = getPatch(portfolio, action.payload.changes);
                return of(
                    PortfolioActions.patchPortfolio({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchPortfolio$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PortfolioActions.patchPortfolio),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return PortfolioActions.patchPortfolioSuccess({
                            portfolio: Model.createFromDto(Portfolio, response.payload),
                        });
                    }),
                    catchError((error) =>
                        of(PortfolioActions.patchPortfolioFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    addProjectToPortfolio$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PortfolioActions.addProjectToPortfolio),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return PortfolioActions.patchPortfolioSuccess({
                            portfolio: Model.createFromDto(Portfolio, response.payload),
                        });
                    }),
                    catchError((error) =>
                        of(PortfolioActions.patchPortfolioFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    removeProjectFromPortfolio$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PortfolioActions.removeProjectFromPortfolio),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return PortfolioActions.patchPortfolioSuccess({
                            portfolio: Model.createFromDto(Portfolio, response.payload),
                        });
                    }),
                    catchError((error) =>
                        of(PortfolioActions.patchPortfolioFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    deletePortfolio$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PortfolioActions.deletePortfolio),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return PortfolioActions.deletePortfolioSuccess({
                            ...action.payload,
                        });
                    }),
                    catchError((error) =>
                        of(PortfolioActions.deletePortfolioFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>
    ) {}
}
