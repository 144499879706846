import { Action, createAction, props } from '@ngrx/store';
import { LoadByProjectIdPayload, LoadBySiteIdPayload } from '../shared/models/load.payload';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import {
    PatchKanbanBoardPayload,
    UpdateKanbanBoardPayload,
    UpdateKanbanColumnPayload,
} from './kanban.payloads';
import { KanbanBoardShape, KanbanColumnShape } from './models';

// Load Kanban Board actions

export const loadKanbanBoardsByProjectId = createAction(
    '[Kanban Board] Load KanbanBoards by Project Id',
    props<{ payload: LoadByProjectIdPayload }>()
);

export const loadKanbanBoardsByProjectIdSuccess = createAction(
    '[Kanban Board] Load KanbanBoards by Project Id success',
    props<{ boards: KanbanBoardShape[]; projectId: string }>()
);

export const loadKanbanBoardsByProjectIdFail = createAction(
    '[Kanban Board] Load KanbanBoards by Project Id fail',
    props<{ error: any; projectId: string }>()
);

export const loadKanbanBoardsBySiteId = createAction(
    '[Kanban Board] Load KanbanBoards by Site Id',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadKanbanBoardsBySiteIdSuccess = createAction(
    '[Kanban Board] Load KanbanBoards by Site Id success',
    props<{ boards: KanbanBoardShape[]; siteId: string }>()
);

export const loadKanbanBoardsBySiteIdFail = createAction(
    '[Kanban Board] Load KanbanBoards by Site Id fail',
    props<{ error: any; siteId: string }>()
);

// Add Kanban Board action
export const addKanbanBoard = createAction(
    '[Kanban Board] Add',
    props<{ board: KanbanBoardShape }>()
);

// Update Kanban Board actions

export const updateKanbanBoard = createAction(
    '[Kanban Board] Update',
    props<{ payload: UpdateKanbanBoardPayload; options?: OptimisticableActionOptions }>()
);

export const patchKanbanBoard = createAction(
    '[Kanban Board] Patch',
    props<{ payload: PatchKanbanBoardPayload; options?: OptimisticableActionOptions }>()
);

export const patchKanbanBoardSuccess = createAction(
    '[Kanban Board] Patch success',
    props<PatchKanbanBoardPayload>()
);

export const patchKanbanBoardFail = createAction(
    '[Kanban Board] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Update Kanban Column actions

export const updateKanbanColumn = createAction(
    '[Kanban Board] Update column',
    props<{ payload: UpdateKanbanColumnPayload }>()
);

export const updateKanbanColumnSuccess = createAction(
    '[Kanban Board] Update column success',
    props<KanbanColumnShape>()
);

export const updateKanbanColumnFail = createAction(
    '[Kanban Board] Update column fail',
    props<{ error: any }>()
);

// Delete Kanban board action

export const deleteKanbanBoard = createAction(
    '[Kanban Board] Delete',
    props<{ boardId: string }>()
);
