import { createSelector } from '@ngrx/store';
import { ProjectRoleSelectors } from 'app/core/store/project-role';
import { MemberNamesDict } from 'app/shared-ui/dumb-components/project-list-table/project-list-table.data';
import { ProgramSelectors } from '../program';
import { ProgramMemberSelectors } from '../program-member';
import { ProgramRoleSelectors } from '../program-role';
import { ProjectSelectors } from '../project';
import { SiteMemberSelectors } from '../resource';
import { selectAllProjectMembers } from './members-shared-.selectors';
import { ProgramRoleTag, ProjectRoleTag } from './models/shared-permissions.model';

export const selectProjectPrimaryManagerNamesMap = createSelector(
    ProjectSelectors.selectAll,
    SiteMemberSelectors.selectCurrentSiteMembers,
    ProjectRoleSelectors.selectCurrentSiteProjectRoleByTag(ProjectRoleTag.Manager),
    selectAllProjectMembers,
    (projects, siteMembers, managerRole, allProjectMembers) => {
        const dict: MemberNamesDict = {};
        projects.forEach((prj) => {
            const projectMembers = allProjectMembers.filter((m) => m.projectId === prj.id);
            const managers = projectMembers.filter((m) => m.roleId === managerRole?.id);
            const primaryManager = managers.find((m) => m.isPrimaryManager);
            if (primaryManager) {
                dict[prj.id] = [primaryManager.name ?? primaryManager.email];
            } else if (managers.length) {
                dict[prj.id] = [managers[0].name ?? managers[0].email];
            }
        });
        return dict;
    }
);

export const selectProgramManagerNamesMap = createSelector(
    ProgramSelectors.selectAll,
    ProgramMemberSelectors.selectAll,
    SiteMemberSelectors.selectAll,
    ProgramRoleSelectors.selectCurrentSiteProgramRoleByTag(ProgramRoleTag.Manager),
    (programs, programMembers, siteMembers, managerRole) => {
        const dict: MemberNamesDict = {};
        programs.forEach((program) => {
            const managerMembers = programMembers
                .filter(
                    (member) => member.programId === program.id && member.roleId === managerRole.id
                )
                .map((member) => siteMembers.find((sm) => sm.id === member.id));
            dict[program.id] = managerMembers.map((member) => member.name || member.email);
        });
        return dict;
    }
);
