import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Calendar } from 'app/core/models/calendar';
import { CalendarCalculator } from 'app/core/models/calendar/calendar-calculator';
import { EntityLevel } from 'app/core/models/shared-models';
import { EntityPatchSuccessResponse } from 'app/core/services/rest-api/features/patch.response';
import { Schedule, TaskInit } from 'app/services/schedule';
import { SnackBarService } from 'app/services/snack-bar/snack-bar.service';
import { getPatch, getPatched } from 'app/utils/json-patch';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { CalendarActions, CalendarSelectors } from '.';
import { AppStore } from '..';
import { RestApiService } from '../../services/rest-api';
import {
    ListContainer,
    ListContainerSelectors,
    ListContainerType,
} from '../project-phase-container';
import {
    getScheduleTaskPatchedFromDiffs,
    getTaskInit,
    ScheduleTask,
    ScheduleTaskSelectors,
} from '../schedule-template';
import { PatchScheduleTaskPayload } from '../schedule-template/schedule-task.payloads';
import { Model } from '../shared/models/base.model';
import { LoadProjectCalendarsResponse } from './../../services/rest-api/features/project-calendar/project-calendar.responses';
import { okEmptyAction } from './../project/project.actions';
import {
    addCalendarFail,
    addCalendarSuccess,
    changeCalendarTemplateFail,
    changeCalendarTemplateSuccess,
    deleteCalendarFail,
    deleteCalendarSuccess,
    loadCalendarByProjectIdFail,
    loadCalendarByProjectIdSuccess,
    loadCalendarsFail,
    loadCalendarsSuccess,
    loadCompletixCalendarTemplatesFail,
    loadCompletixCalendarTemplatesSuccess,
    patchCalendarFail,
    patchCalendarSuccess,
} from './calendar.actions';
import { PatchCalendarPayload } from './calendar.payloads';

@Injectable()
export class CalendarEffects {
    loadCompletixCalendarTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.loadCompletixCalendarTemplates),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: LoadProjectCalendarsResponse) =>
                        loadCompletixCalendarTemplatesSuccess({
                            calendars: response.payload.map((dto) =>
                                Model.createFromDto(Calendar, dto)
                            ),
                        })
                    ),
                    catchError((error) => of(loadCompletixCalendarTemplatesFail({ error })))
                )
            )
        )
    );

    loadCompletixCalendarTemplatesBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.loadCompletixCalendarTemplatesBySiteId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: LoadProjectCalendarsResponse) =>
                        loadCalendarsSuccess({
                            calendars: response.payload.map((dto) =>
                                Model.createFromDto(Calendar, dto)
                            ),
                        })
                    ),
                    catchError((error) => of(loadCalendarsFail({ error })))
                )
            )
        )
    );

    loadCalendarsBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.loadCalendarsBySiteId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: LoadProjectCalendarsResponse) =>
                        loadCalendarsSuccess({
                            calendars: response.payload.map((dto) =>
                                Model.createFromDto(Calendar, dto)
                            ),
                        })
                    ),
                    catchError((error) => of(loadCalendarsFail({ error })))
                )
            )
        )
    );

    loadCalendarByProjectId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.loadCalendarByProjectId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) =>
                        loadCalendarByProjectIdSuccess({
                            calendar: Model.createFromDto(Calendar, response.payload),
                        })
                    ),
                    catchError((error) => of(loadCalendarByProjectIdFail({ error })))
                )
            )
        )
    );

    importCalendar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.importCompletixCalendarTemplate),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Importing a template...');
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBarService.hide()),
                    map((response) =>
                        addCalendarSuccess({
                            calendar: Model.createFromDto(Calendar, response.payload),
                        })
                    ),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(addCalendarFail({ error }));
                    })
                );
            })
        )
    );

    addCalendar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.addCalendar),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) =>
                        addCalendarSuccess({
                            calendar: Model.createFromDto(Calendar, response.payload),
                        })
                    ),
                    catchError((error) => of(addCalendarFail({ error })))
                )
            )
        )
    );

    updateCalendar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.updateCalendar),
            withLatestFrom(this.store$.select(CalendarSelectors.selectAll)),
            mergeMap(([action, calendars]) => {
                const calendar = calendars.find((c) => c.id === action.payload.id);
                const patch = getPatch(calendar, action.payload.changes);
                return of(
                    CalendarActions.patchCalendar({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchCalendar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.patchCalendar),
            withLatestFrom(
                this.store$.select(CalendarSelectors.selectAll),
                this.store$.select(ScheduleTaskSelectors.selectAll),
                this.store$.select(
                    ListContainerSelectors.selectAllListContainersByType(ListContainerType.Schedule)
                )
            ),
            mergeMap(([action, calendars, scheduleTasks, listContainers]) => {
                const scheduleTaskPatches = this.getScheduleTaskPatches(
                    calendars,
                    listContainers,
                    scheduleTasks,
                    action.payload
                );
                const modifiedAction: Action = scheduleTaskPatches
                    ? {
                          ...action,
                          payload: {
                              ...action.payload,
                              scheduleTaskPatches: scheduleTaskPatches.patches,
                              listContainer: scheduleTaskPatches.listContainer,
                          },
                      }
                    : action;
                return this.restApiService.process(modifiedAction).pipe(
                    map((response: EntityPatchSuccessResponse) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return patchCalendarSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) => of(patchCalendarFail({ error, originAction: action })))
                );
            })
        )
    );

    cloneCalendar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.cloneCalendar),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) =>
                        addCalendarSuccess({
                            calendar: Model.createFromDto(Calendar, response.payload),
                        })
                    ),
                    catchError((error) => of(addCalendarFail({ error })))
                )
            )
        )
    );

    deleteCalendar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.deleteCalendar),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return deleteCalendarSuccess({
                            id: action.payload.id,
                        });
                    }),
                    catchError((error) => of(deleteCalendarFail({ error, originAction: action })))
                )
            )
        )
    );

    changeCalendarTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.changeCalendarTemplate),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Processing...');
                return this.restApiService.process(action).pipe(
                    map(
                        (response) => {
                            this.snackBarService.hide();
                            return changeCalendarTemplateSuccess({
                                calendar: Model.createFromDto(Calendar, response.payload),
                            });
                        },
                        catchError((error) => {
                            this.snackBarService.hide();
                            return of(changeCalendarTemplateFail({ error }));
                        })
                    )
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>,
        private snackBarService: SnackBarService
    ) {}

    private getScheduleTaskPatches(
        calendars: Calendar[],
        listContainers: ListContainer[],
        scheduleTasks: ScheduleTask[],
        payload: PatchCalendarPayload
    ): { patches: PatchScheduleTaskPayload[]; listContainer: ListContainer } {
        const calendar = calendars.find((c) => c.id === payload.id);
        let tasks: ScheduleTask[] = [];
        let listContainer: ListContainer;
        switch (true) {
            case calendar.isCompletixTemplate && calendar.isDefault:
                listContainer = listContainers.find(
                    (c) => c.scope.level === EntityLevel.System && c.isDefault
                );
                tasks = scheduleTasks.filter((t) => t.listContainerId === listContainer?.id);
                break;

            case !!calendar.projectId:
                listContainer = listContainers.find(
                    (c) =>
                        c.scope.level === EntityLevel.Project && c.scope.id === calendar.projectId
                );
                tasks = scheduleTasks.filter((t) => t.listContainerId === listContainer?.id);
                break;

            case !!calendar.siteId && calendar.isDefault:
                listContainer = listContainers.find(
                    (c) =>
                        c.scope.level === EntityLevel.Site &&
                        c.scope.id === calendar.siteId &&
                        c.isDefault
                );
                tasks = scheduleTasks.filter((t) => t.listContainerId === listContainer?.id);
                break;

            default:
                return null;
        }

        const taskInits: TaskInit[] = tasks.map((task) => getTaskInit(task));
        const calendarCalculator = new CalendarCalculator(calendar);
        const schedule = new Schedule(taskInits, calendarCalculator);
        const patchedCalendar = getPatched(calendar, payload.patch);
        schedule.updateCalendar(patchedCalendar);
        const diffs = schedule.getChanges();
        return { patches: getScheduleTaskPatchedFromDiffs(scheduleTasks, diffs), listContainer };
    }
}
