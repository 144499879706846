import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityLevel } from 'app/core/models/shared-models';
import { Project } from 'app/core/store/project';
import { ProjectSelectors } from '../project';
import {
    ListContainer,
    ListContainerSelectors,
    ListContainerType,
} from '../project-phase-container';
import { selectCurrentSiteId } from '../site/site.selectors';
import { StateEntry } from '../state-entry';
import { ProjectPhase } from './models';
import { adapter, State } from './project-phase.store';

export const selectProjectPhaseEntry = createFeatureSelector<State>(StateEntry.ProjectPhase);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectProjectPhaseEntry);

export const selectLoadingStatusesPerSiteId = createSelector(
    selectProjectPhaseEntry,
    (state) => state.loadingStatusBySiteId
);

export const selectLoadingStatusForCurrentSite = createSelector(
    selectLoadingStatusesPerSiteId,
    selectCurrentSiteId,
    (statusMap, siteId) => statusMap?.[siteId]
);

export const selectLoadingStatusesPerContainerId = createSelector(
    selectProjectPhaseEntry,
    (state) => state.loadingStatusByContainerId
);

export const selectLoadingStatusByContainerId = (containerId: string) =>
    createSelector(
        selectProjectPhaseEntry,
        (state) => state.loadingStatusByContainerId?.[containerId]
    );

export const selectCurrentProjectOrderedPhases = createSelector(
    ListContainerSelectors.selectCurrentProjectListContainer(ListContainerType.Gating),
    selectAll,
    (container: ListContainer, allPhases: ProjectPhase[]) => {
        if (!container) return [];
        const containerPhases = allPhases.filter((phase) => phase.containerId === container.id);
        const orderedPhases =
            container.orderedIds
                ?.map((id) => containerPhases.find((phase) => phase.id === id))
                ?.filter((p) => !!p) || [];
        const notOrderedPhases = containerPhases.filter(
            (phase) => !orderedPhases.some((p) => p.id === phase.id)
        );
        return [...orderedPhases, ...notOrderedPhases];
    }
);

export const selectPhaseContainerById = (id: string) =>
    createSelector(ListContainerSelectors.selectAll, (containers) =>
        containers.find((container) => container.id === id)
    );

export const selectProjectPhaseById = (id: string) =>
    createSelector(selectAll, (phases) => phases.find((p) => p.id === id));

export const selectPhasesByProjectId = (projectId: string) =>
    createSelector(
        ListContainerSelectors.selectAll,
        selectAll,
        (containers, phases: ProjectPhase[]) => {
            const container = containers.find(
                (c) => c.scope.id === projectId && c.scope.level === EntityLevel.Project
            );
            if (!container) return [];
            const containerPhases = phases.filter((phase) => phase.containerId === container.id);
            const orderedPhases =
                container.orderedIds
                    ?.map((id) => containerPhases.find((phase) => phase.id === id))
                    ?.filter((p) => !!p) || [];
            const notOrderedPhases = containerPhases.filter(
                (phase) => !orderedPhases.some((p) => p.id === phase.id)
            );
            return [...orderedPhases, ...notOrderedPhases];
        }
    );

export const selectPhaseContainerByProjectId = (projectId: string) =>
    createSelector(ListContainerSelectors.selectAll, (containers) =>
        containers.find(
            (container) =>
                container.scope.id === projectId && container.scope.level === EntityLevel.Project
        )
    );

export const selectOrderedPhasesByContainerId = (containerId: string) =>
    createSelector(
        selectPhaseContainerById(containerId),
        selectAll,
        (container: ListContainer, phases: ProjectPhase[]) => getOrderedPhases(container, phases)
    );

export const selectCurrentProjectCurrentPhase = createSelector(
    selectCurrentProjectOrderedPhases,
    (phases: ProjectPhase[]) => getCurrentPhase(phases)
);

export const selectCurrentProjectPhaseStepperItems = createSelector(
    selectCurrentProjectOrderedPhases,
    (phases: ProjectPhase[]) =>
        phases.map((phase) => ({
            title: phase.name,
            completed: !!phase.completion?.completed,
        }))
);

export const selectPhaseStepperItemsByContainerId = (containerId: string) =>
    createSelector(selectOrderedPhasesByContainerId(containerId), (phases: ProjectPhase[]) =>
        phases.map((phase) => ({
            title: phase.name,
            completed: !!phase.completion?.completed,
        }))
    );

export const selectProjectsCurrentPhasesMap = createSelector(
    ProjectSelectors.selectAll,
    ListContainerSelectors.selectAll,
    selectAll,
    (projects: Project[], phaseContainers: ListContainer[], phases: ProjectPhase[]) => {
        const map: Record<string, ProjectPhase> = {};
        projects.forEach((prj) => {
            const container = phaseContainers.find(
                (c) => c.scope.id === prj.id && c.scope.level === EntityLevel.Project
            );
            if (!container) return;
            const containerPhases = phases.filter((p) => p.containerId === container.id);
            const orderedPhases = getOrderedPhases(container, containerPhases);
            map[prj.id] = getCurrentPhase(orderedPhases);
        });
        return map;
    }
);

export function getCurrentPhase(phases: ProjectPhase[]): ProjectPhase {
    return phases.find((phase) => !phase.completion?.completed);
}

export function getOrderedPhases(container: ListContainer, phases: ProjectPhase[]): ProjectPhase[] {
    if (!container) return [];
    const containerPhases = phases.filter((phase) => phase.containerId === container.id);
    const orderedPhases =
        container.orderedIds
            ?.map((id) => containerPhases.find((phase) => phase.id === id))
            ?.filter((p) => !!p) || [];
    const notOrderedPhases = containerPhases.filter(
        (phase) => !orderedPhases.some((p) => p.id === phase.id)
    );
    return [...orderedPhases, ...notOrderedPhases];
}
