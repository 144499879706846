import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectSelectors } from 'app/core/store/project';
import { SiteSelectors } from '../site';
import { StateEntry } from '../state-entry';
import { adapter, LoadingStatus, State } from './communication.store';

export const selectCommunicationEntry = createFeatureSelector<State>(StateEntry.Communication);

export const selectLoadingStatus = createSelector(
    selectCommunicationEntry,
    (state): LoadingStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
    })
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectCommunicationEntry);

export const selectCurrentSiteCommunicationTemplates = createSelector(
    selectAll,
    SiteSelectors.selectCurrentSiteId,
    (communications, siteId) => communications.filter((c) => c.isTemplate && c.siteId === siteId)
);

export const selectCompletixCommunicationTemplates = createSelector(selectAll, (communications) =>
    communications.filter((c) => c.isTemplate && c.isCompletixTemplate)
);

export const selectCurrentProjectCommunication = createSelector(
    selectAll,
    ProjectSelectors.selectCurrentProjectId,
    (communications, projectId) => communications.find((c) => c.projectId === projectId)
);

export const selectCompletixCommunicationTemplatesLoading = createSelector(
    selectCommunicationEntry,
    (state) => state.completixCommunicationsLoading
);
