import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EntityPatchSuccessResponse } from 'app/core/services/rest-api/features/patch.response';
import { ImportCompletixProjectTemplateResponse } from 'app/core/services/rest-api/features/project-template/project-template.responses';
import { ProjectTemplate, ProjectTemplateSelectors } from 'app/core/store/project-template';
import { OptimisticDefaults } from 'app/core/store/shared/optimisticable-action';
import { SnackBarService } from 'app/services/snack-bar/snack-bar.service';
import { getPatch } from 'app/utils/json-patch';
import { CommandResult } from 'app/utils/network/commands';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { ProjectTemplateActions } from '.';
import { AppStore } from '..';
import { RestApiService } from '../../services/rest-api';
import { BudgetTableActions } from '../budget-table';
import { CalendarActions } from '../calendar';
import { CommunicationActions } from '../communication';
import { generateUUID } from '../id-generator';
import { ListContainerActions } from '../project-phase-container';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import { ProjectTemplateCreateOptions } from './models';

@Injectable()
export class ProjectTemplateEffects {
    loadProjectTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectTemplateActions.loadProjectTemplates),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        ProjectTemplateActions.loadProjectTemplatesSuccess({
                            templates: response.payload.map((dto) =>
                                Model.createFromDto(ProjectTemplate, dto)
                            ),
                        })
                    ),
                    catchError((error) =>
                        of(ProjectTemplateActions.loadProjectTemplatesFail({ error }))
                    )
                )
            )
        )
    );

    loadCompletixProjectTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectTemplateActions.loadCompletixProjectTemplates),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        ProjectTemplateActions.loadCompletixProjectTemplatesSuccess({
                            templates: response.payload.map((dto) =>
                                Model.createFromDto(ProjectTemplate, dto)
                            ),
                        })
                    ),
                    catchError((error) =>
                        of(ProjectTemplateActions.loadCompletixProjectTemplatesFail({ error }))
                    )
                )
            )
        )
    );

    loadCompletixProjectTemplatesBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectTemplateActions.loadCompletixProjectTemplatesBySiteId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        ProjectTemplateActions.loadProjectTemplatesSuccess({
                            templates: response.payload.map((dto) =>
                                Model.createFromDto(ProjectTemplate, dto)
                            ),
                        })
                    ),
                    catchError((error) =>
                        of(ProjectTemplateActions.loadProjectTemplatesFail({ error }))
                    )
                )
            )
        )
    );

    addProjectTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectTemplateActions.addProjectTemplate),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        ProjectTemplateActions.addProjectTemplateSuccess({
                            template: Model.createFromDto(ProjectTemplate, response.payload),
                        })
                    ),
                    catchError((error) =>
                        of(ProjectTemplateActions.addProjectTemplateFail({ error }))
                    )
                )
            )
        )
    );

    importCompletixProjectTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectTemplateActions.importCompletixProjectTemplate),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Importing a template...');
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBarService.hide()),
                    map((response: ImportCompletixProjectTemplateResponse) => {
                        if (response.payload.communicationTemplate) {
                            this.store$.dispatch(
                                CommunicationActions.addCommunicationSuccess({
                                    communication: response.payload.communicationTemplate,
                                })
                            );
                        }
                        if (response.payload.projectCalendarTemplate) {
                            this.store$.dispatch(
                                CalendarActions.addCalendarSuccess({
                                    calendar: response.payload.projectCalendarTemplate,
                                })
                            );
                        }
                        if (response.payload.gatingTemplate) {
                            this.store$.dispatch(
                                ListContainerActions.addListContainerSuccess({
                                    container: response.payload.gatingTemplate,
                                })
                            );
                        }
                        if (response.payload.scheduleTemplate) {
                            this.store$.dispatch(
                                ListContainerActions.addListContainerSuccess({
                                    container: response.payload.scheduleTemplate,
                                })
                            );
                        }
                        if (response.payload.projectBudgetTableTemplate) {
                            this.store$.dispatch(
                                BudgetTableActions.addBudgetTableSuccess({
                                    table: response.payload.projectBudgetTableTemplate,
                                })
                            );
                        }

                        return ProjectTemplateActions.addProjectTemplateSuccess({
                            template: Model.createFromDto(
                                ProjectTemplate,
                                response.payload.projectTemplate
                            ),
                        });
                    }),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(ProjectTemplateActions.addProjectTemplateFail({ error }));
                    })
                );
            })
        )
    );

    updateProjectTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectTemplateActions.updateProjectTemplate),
            withLatestFrom(this.store$.select(ProjectTemplateSelectors.selectAll)),
            mergeMap(([action, templates]) => {
                const template = templates.find((t) => t.id === action.payload.id);
                const patch = getPatch(template, action.payload.changes);
                return of(
                    ProjectTemplateActions.patchProjectTemplate({
                        payload: { id: action.payload.id, patch },
                        options: OptimisticDefaults,
                    })
                );
            })
        )
    );

    patchProjectTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectTemplateActions.patchProjectTemplate),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: EntityPatchSuccessResponse) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ProjectTemplateActions.patchProjectTemplateSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) =>
                        of(
                            ProjectTemplateActions.patchProjectTemplateFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    setDefaultProjectTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectTemplateActions.setDefaultProjectTemplate),
            withLatestFrom(this.store$.select(ProjectTemplateSelectors.selectAll)),
            mergeMap(([payload, allTemplates]) => {
                const template = allTemplates.find((t) => t.id === payload.id);
                const templatesFilterPredicate: (t: ProjectTemplate) => boolean =
                    payload.isCompletixTemplate
                        ? (t) => t.isCompletixTemplate
                        : (t) => t.siteId === template.siteId;
                const filteredTemplates = allTemplates.filter((t) => templatesFilterPredicate(t));

                if (template.isDefault) return of(okEmptyAction());

                const currentDefault = filteredTemplates.find((t) => t.isDefault);
                const newDefaultChanges: Partial<ProjectTemplate> = {
                    isDefault: true,
                    isInitialTemplate: true,
                };
                const currentDefaultChanges: Partial<ProjectTemplate> = {
                    isDefault: false,
                };
                return of(
                    ProjectTemplateActions.updateProjectTemplate({
                        payload: {
                            id: payload.id,
                            changes: newDefaultChanges,
                        },
                        options: OptimisticDefaults,
                    }),
                    ProjectTemplateActions.updateProjectTemplate({
                        payload: {
                            id: currentDefault?.id,
                            changes: currentDefaultChanges,
                        },
                        options: OptimisticDefaults,
                    })
                );
            })
        )
    );

    deleteProjectTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectTemplateActions.deleteProjectTemplate),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ProjectTemplateActions.deleteProjectTemplateSuccess({
                            id: action.payload.id,
                        });
                    }),
                    catchError((error) =>
                        of(
                            ProjectTemplateActions.deleteProjectTemplateFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>,
        private snackBarService: SnackBarService
    ) {}

    getNewProjectTemplate(options: ProjectTemplateCreateOptions): ProjectTemplate {
        const newProjectTemplate = new ProjectTemplate({
            id: generateUUID(),
            name: options.name,
        });
        return newProjectTemplate;
    }
}
