import { LoadBySiteIdPayload } from 'app/core/store/shared/models/load.payload';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import { IdPayload } from '../../../../store/shared/models/shared-payloads';
import {
    AddBacklogTaskPayload,
    PatchBacklogTaskPayload,
} from './../../../../store/backlog-task/backlog-task.payloads';

export const getBacklogTaskCommand = (payload: IdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'backlogTasks', value: payload.id }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadBacklogTasksByContainerIdCommand = (
    payload: IdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'backlogTasks/listContainers', value: payload.id }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadBacklogTasksBySiteIdCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'backlogTasks/sites', value: payload.siteId }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addBacklogTaskCommand = (
    payload: AddBacklogTaskPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'backlogTasks' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchBacklogTaskCommand = (
    payload: PatchBacklogTaskPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'backlogTasks', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteBacklogTaskCommand = (payload: IdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'backlogTasks', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};
