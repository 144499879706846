import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import { AddActionPayload, PatchActionPayload } from '../../../../store/actions/action.payloads';
import {
    LoadByProjectIdPayload,
    LoadBySiteIdPayload,
} from '../../../../store/shared/models/load.payload';
import { IdPayload } from './../../../../store/shared/models/shared-payloads';

export const getActionCommand = (payload: IdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projectActions', value: payload.id }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadActionsByProjectIdCommand = (
    payload: LoadByProjectIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projects', value: payload.projectId }, { name: 'actions' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadActionsBySiteIdCommand = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'actions' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addActionCommand = (payload: AddActionPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projectActions' }]);
    baseCommand.payload.setData(payload);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const patchActionCommand = (payload: PatchActionPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projectActions', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};

export const deleteActionCommand = (payload: IdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'projectActions', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};
