import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AsyncUpdateStatus } from '../shared/async-update-status.store';
import { ListContainer } from './models';

export interface ListContainersLoadingStatus {
    loadingStatusBySiteId: Record<string, AsyncUpdateStatus>;
    loadingStatusByProjectId: Record<string, AsyncUpdateStatus>;
    systemTemplatesLoadingStatus: AsyncUpdateStatus;
}

export interface State extends EntityState<ListContainer>, ListContainersLoadingStatus {}

export const adapter: EntityAdapter<ListContainer> = createEntityAdapter<ListContainer>();

export const initialState: State = adapter.getInitialState({
    systemTemplatesLoadingStatus: {},
    loadingStatusBySiteId: {},
    loadingStatusByProjectId: {},
});
