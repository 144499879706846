import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectSelectors } from '../project';
import { SiteSelectors } from '../site';
import { StateEntry } from '../state-entry';
import { adapter, State } from './kanban.store';
import { KanbanBoardShape } from './models';

export const selectKanbanBoardEntry = createFeatureSelector<State>(StateEntry.Kanban);

export const selectLoadingStatusesPerSiteId = createSelector(
    selectKanbanBoardEntry,
    (state) => state.loadingStatusBySiteId
);

export const selectLoadingStatusForCurrentSite = createSelector(
    selectLoadingStatusesPerSiteId,
    SiteSelectors.selectCurrentSiteId,
    (loadingMap, siteId) => loadingMap[siteId]
);

export const selectLoadingStatusesPerProjectId = createSelector(
    selectKanbanBoardEntry,
    (state) => state.loadingStatusByProjectId
);

export const selectLoadingStatusForCurrentProject = createSelector(
    selectKanbanBoardEntry,
    ProjectSelectors.selectCurrentProjectId,
    (state, projectId) => state.loadingStatusByProjectId[projectId]
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectKanbanBoardEntry);

export const selectCurrentProjectKanbanByLink = (link: string) =>
    createSelector(
        ProjectSelectors.selectCurrentProjectId,
        selectAll,
        (projectId: string, boards: KanbanBoardShape[]) =>
            boards.find((board) => board.projectId === projectId && board.link === link)
    );
