import { Model } from 'app/core/store/shared/models/base.model';
import { Amount } from 'app/utils/amount';
import { JsonProperty } from 'app/utils/json-mapper';
import { SitePermissions } from './../../store/shared/models/shared-permissions.model';
import {
    SubscriptionPlanFeature,
    SubscriptionPlanInterval,
    SubscriptionPlanType,
    SubscriptionProvider,
} from './subscription-plan.model';

export class SubscriptionBaseModel extends Model {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('stripePriceId')
    stripePriceId: string = undefined;

    @JsonProperty('provider')
    provider: SubscriptionProvider = undefined;

    @JsonProperty('planType')
    planType: SubscriptionPlanType = undefined;

    @JsonProperty('isCustomPlan')
    isCustomPlan?: boolean = undefined;

    @JsonProperty('name')
    name: string = undefined;

    @JsonProperty('description')
    description: string = undefined;

    @JsonProperty('amount')
    amount: number = undefined;

    @JsonProperty('currency')
    currency?: string = undefined;

    @JsonProperty('interval')
    interval: SubscriptionPlanInterval = undefined;

    @JsonProperty('intervalCount')
    intervalCount: number = undefined;

    @JsonProperty('userLimit')
    userLimit?: number = undefined;

    @JsonProperty('projectLimit')
    projectLimit?: number = undefined;

    @JsonProperty('userMin')
    userMin?: number = undefined;

    @JsonProperty('projectMin')
    projectMin?: number = undefined;

    @JsonProperty('isAdditional')
    isAdditional?: boolean;

    @JsonProperty('features')
    features?: SubscriptionPlanFeature[];

    @JsonProperty('tags')
    tags?: SubscriptionPlanTag[];

    @JsonProperty('freeGuests')
    freeGuests?: boolean;

    @JsonProperty('permissions')
    permissions: SitePermissions = undefined;

    constructor(init?: Partial<SubscriptionBaseModel>) {
        super();
        Object.assign(this, init);
    }

    isTrial(): boolean {
        return this.planType === SubscriptionPlanType.Trial;
    }

    isGrace(): boolean {
        return this.planType === SubscriptionPlanType.Grace;
    }

    isPerProject(): boolean {
        return this.planType === SubscriptionPlanType.PerProject;
    }

    isPerUser(): boolean {
        return this.planType === SubscriptionPlanType.PerUser;
    }

    isFixedCost(): boolean {
        return this.planType === SubscriptionPlanType.FixedPrice;
    }

    isPaid(): boolean {
        return !this.isTrial() && !this.isGrace();
    }

    isAmountEditable(): boolean {
        return (
            this.isCustomPlan && this.isPaid() && this.provider === SubscriptionProvider.Completix
        );
    }

    getName(): string {
        if (
            !this.isCustomPlan &&
            this.planType === SubscriptionPlanType.FixedPrice &&
            this.userLimit === 3
        ) {
            return '3 Users';
        }

        switch (this.planType) {
            case SubscriptionPlanType.Grace:
                return 'Grace';
            case SubscriptionPlanType.Trial:
                return 'Trial';
            case SubscriptionPlanType.PerProject:
                return this.isCustomPlan ? 'Your Per Project Plan' : 'Per Project';
            case SubscriptionPlanType.PerUser:
                return this.isCustomPlan ? 'Your Per User Plan' : 'Per User';
            case SubscriptionPlanType.FixedPrice:
                return this.isCustomPlan ? 'Your Fixed Price Plan' : 'Fixed Price';
            default:
                return '';
        }
    }

    getPaidUnitName(): string {
        if (this.isPerProject()) return 'Project';
        if (this.isPerUser()) return 'User';
        return '';
    }

    getAmountPerItem(currency?: string): string {
        if (!this.amount || !this.currency) return '0';
        const amount = new Amount(this.amount, currency || this.currency);
        return amount.toString();
    }

    getTotalAmountText(totalAmountCents: number): string {
        if (!this.amount || !this.currency) return '0';
        const amount = new Amount(totalAmountCents, this.currency);
        return amount.toString();
    }

    getAmountLabel(): string {
        switch (this.planType) {
            case SubscriptionPlanType.PerProject:
                return 'Cost per project';
            case SubscriptionPlanType.PerUser:
                return 'Cost per user';
            case SubscriptionPlanType.FixedPrice:
                return 'Cost';
            default:
                return '';
        }
    }

    getIntervalText(): string {
        if (this.intervalCount === 0 || this.interval == null) {
            return null;
        }

        if (this.intervalCount === 1) {
            if (this.interval === SubscriptionPlanInterval.Month) return 'Month';
            else if (this.interval === SubscriptionPlanInterval.Year) return 'Annually';
            else if (this.interval === SubscriptionPlanInterval.Week) return 'Weekly';
            else if (this.interval === SubscriptionPlanInterval.Day) return 'Daily';
            else return null;
        }

        return `Every ${this.intervalCount} ${this.interval}s`;
    }

    getCurrencyPrefix(): string {
        return Amount.getCurrencyPrefix(this.currency);
    }

    getCurrencySuffix(): string {
        return Amount.getCurrencySuffix(this.currency);
    }

    getFaIconName(): string {
        if (this.isTrial()) return 'fa-rocket';
        if (this.isCustomPlan) return 'fa-cogs';
        if (this.isPerProject()) return 'fa-folder-open';
        if (this.isPerUser()) return 'fa-user-plus';
        if (this.isFixedCost()) return 'fa-bars';
        return 'fa-cogs';
    }

    getMinPaidUnits(): number {
        if (this.isPerProject()) return this.projectMin || 1;
        if (this.isPerUser()) return this.userMin || 1;
        if (this.isFixedCost()) return 1;
        return 0;
    }

    getPriceWithoutCents(): number {
        return Math.floor(this.amount / 100);
    }

    getPriceCents(): string {
        const value = (this.amount / 100 - this.getPriceWithoutCents()) * 100;
        const stringValue = value.toString();
        return stringValue.length < 2 ? '0' + stringValue : stringValue;
    }
}

export enum SubscriptionPlanTag {
    ExtendedSupport = 'ExtendedSupport',
}
