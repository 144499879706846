import { CtxColor } from 'app/core/models/shared-models';
import { JsonProperty } from 'app/utils/json-mapper';
import { Model } from '../../shared/models/base.model';

export class KanbanBoardShape extends Model {
    @JsonProperty('id')
    id: string;

    @JsonProperty('projectId')
    projectId: string;

    @JsonProperty('link')
    link: string;

    @JsonProperty('originObjectId')
    originObjectId: string;

    @JsonProperty('dataObjectKeyProperty')
    dataObjectKeyProperty: string;

    @JsonProperty('dataObjectNameProperty')
    dataObjectNameProperty: string;

    @JsonProperty('columnCriterionProperty')
    columnCriterionProperty: string;

    @JsonProperty('columnOrderedIds')
    columnOrderedIds: string[];

    @JsonProperty('allowColumnAdding')
    allowColumnAdding?: boolean;

    @JsonProperty('disableCardDragDrop')
    disableCardDragDrop?: boolean;

    @JsonProperty('columnShapes')
    columnShapes: KanbanColumnShape[];

    constructor(init?: Partial<KanbanBoardShape>) {
        super();
        Object.assign(this, init);
    }
}

export class KanbanColumnShape {
    id: string;
    title: string;
    color: CtxColor;
    objectOrderedIds: string[];
    allowColumnMove?: boolean;
    allowDropToColumn?: boolean;
    allowDragFromColumn?: boolean;
    allowCardAdding?: boolean;
    allowColumnRemove?: boolean;
    allowColumnNameChange?: boolean;
    allowColumnColorChange?: boolean;
    criterionValue: any;

    constructor(init?: Partial<KanbanColumnShape>) {
        Object.assign(this, init);
    }
}

export enum KanbanBoardShapeLink {
    Risks = 'Risks',
    Actions = 'Actions',
    Issues = 'Issues',
    Decisions = 'Decisions',
    Sprints = 'Sprints',
}
