import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import { getRecordNewTextId } from '../shared/models/raid-shared.model';
import * as IssueActions from './issue.actions';
import { adapter, initialState } from './issue.store';
import { IssueRecord } from './models';

export const reducer = createReducer(
    initialState,

    on(IssueActions.getIssueSuccess, (state, payload) =>
        adapter.addOne(payload.issue, adapter.removeOne(payload.issue.id, state))
    ),

    on(IssueActions.loadIssuesBySiteId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(IssueActions.loadIssuesBySiteIdSuccess, (state, payload) => {
        const newState = adapter.addMany(payload.issues, adapter.removeAll(state));
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(IssueActions.loadIssuesBySiteIdFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(IssueActions.loadIssuesByProjectId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(IssueActions.loadIssuesByProjectIdSuccess, (state, payload) => {
        const newState = adapter.addMany(
            payload.issues,
            adapter.removeMany(
                payload.issues.map((r) => r.id),
                state
            )
        );
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(IssueActions.loadIssuesByProjectIdFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(IssueActions.addIssue, (state, action) => {
        if (action.options?.optimistic) {
            const issues = Object.values(state.entities).filter(
                (i) => action.payload.projectId === i.projectId
            );
            return adapter.addOneOptimistic(
                new IssueRecord({
                    ...action.payload,
                    creationDate: new Date().toString(),
                    dateRaised: new Date(),
                    textId: getRecordNewTextId(
                        issues.map((i) => i.textId),
                        'I'
                    ),
                }),
                state
            );
        }
        return state;
    }),

    on(IssueActions.addIssueSuccess, (state, payload) => {
        return payload.options?.optimistic
            ? adapter.addOneOptimisticSuccess(payload.issue, state)
            : adapter.addOne(payload.issue, state);
    }),

    on(IssueActions.addIssueFail, (state, { issueId }) =>
        adapter.addOneOptimisticFail(issueId, state)
    ),

    on(IssueActions.patchIssue, (state, action) => {
        if (!action.options?.optimistic) return;
        const patched = getPatched(state.entities[action.payload.id], action.payload.patch);
        const update: Update<IssueRecord> = { id: patched.id, changes: patched };
        return adapter.updateOne(update, state);
    }),

    on(IssueActions.patchIssueSuccess, (state, payload) => {
        const patched = getPatched(state.entities[payload.id], payload.patch);
        const update: Update<IssueRecord> = { id: patched.id, changes: patched };
        return adapter.updateOne(update, state);
    }),

    on(IssueActions.patchIssueFail, (state) => state),

    on(IssueActions.deleteIssue, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(IssueActions.deleteIssueSuccess, (state, payload) => adapter.removeOne(payload.id, state)),

    on(IssueActions.deleteIssueFail, (state) => state)
);
