import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProgramSelectors } from '../program';
import { Project, ProjectSelectors } from '../project';
import { ProjectPhaseSelectors } from '../project-phase';
import { ProjectStatusReportBudget, ProjectStatusSelectors } from '../project-status-report';
import { getCurrentProjectStatusReportBudgetValue } from '../project-status-report/project-status-report.selectors';
import { selectProjectPrimaryManagerNamesMap } from '../shared/entity-manager.selectors';
import { selectCurrentProgramProjects } from '../shared/members-shared-.selectors';
import { selectCurrentSiteProjectsOverview } from '../shared/project-overview.selectors';
import { selectCurrentSiteId } from '../site/site.selectors';
import { StateEntry } from '../state-entry';
import {
    getLiveProgramStatusReport,
    ProgramStatusReport,
} from './models/program-status-report.model';
import { adapter, State } from './program-status-report.store';

export const selectStatusReportEntry = createFeatureSelector<State>(StateEntry.ProgramStatusReport);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectStatusReportEntry);

export const selectLoadingStatusesPerSiteId = createSelector(
    selectStatusReportEntry,
    (state) => state.loadingStatusBySiteId
);

export const selectLoadingStatusForCurrentSite = createSelector(
    selectLoadingStatusesPerSiteId,
    selectCurrentSiteId,
    (statusMap, siteId) => statusMap[siteId]
);

export const selectLoadingStatusByProgramIdMap = createSelector(
    selectStatusReportEntry,
    (state) => state.loadingStatusByProgramId
);

export const selectCurrentProgramStatusReportsLoadingStatus = createSelector(
    selectStatusReportEntry,
    ProgramSelectors.selectCurrentProgramId,
    (state, programId) => state.loadingStatusByProgramId[programId]
);

export const selectAllProgramsLiveTimeStatusReports = createSelector(
    ProjectStatusSelectors.selectAllProjectsConfiguredLiveReports,
    ProgramSelectors.selectAll,
    ProjectSelectors.selectAll,
    ProjectPhaseSelectors.selectProjectsCurrentPhasesMap,
    selectProjectPrimaryManagerNamesMap,
    selectCurrentSiteProjectsOverview,
    (
        projectsLiveReports,
        allPrograms,
        allProjects,
        currentPhasesMap,
        managerNamesMap,
        projectOverviews
    ) => {
        const reports: ProgramStatusReport[] = [];
        allPrograms.forEach((program) => {
            const programProjects = allProjects.filter((prj) => prj.programId === program.id);
            const liveTimeReport = getLiveProgramStatusReport(
                program.id,
                projectsLiveReports,
                programProjects,
                currentPhasesMap,
                managerNamesMap,
                projectOverviews
            );
            reports.push(liveTimeReport);
        });
        return reports;
    }
);

export const selectCurrentProgramLiveTimeReport = createSelector(
    ProjectStatusSelectors.selectAllProjectsConfiguredLiveReports,
    ProgramSelectors.selectCurrentProgramId,
    selectCurrentProgramProjects,
    ProjectPhaseSelectors.selectProjectsCurrentPhasesMap,
    selectProjectPrimaryManagerNamesMap,
    selectCurrentSiteProjectsOverview,
    (
        projectsLiveReports,
        programId,
        projects,
        currentPhasesMap,
        managerNamesMap,
        projectOverviews
    ) =>
        getLiveProgramStatusReport(
            programId,
            projectsLiveReports,
            projects,
            currentPhasesMap,
            managerNamesMap,
            projectOverviews
        )
);

export const selectCurrentProgramStoredLiveReport = createSelector(
    ProgramSelectors.selectCurrentProgramId,
    selectAll,
    (programId, allReports) => allReports.find((r) => r.programId === programId && r.isLive)
);

export const selectAllProgramsLiveReport = createSelector(
    selectAllProgramsLiveTimeStatusReports,
    selectAll,
    ProjectSelectors.selectAll,
    (liveTimeReports, storedReports, projects) =>
        liveTimeReports.map((liveTimeReport) =>
            getProgramLiveReport(
                storedReports.find((r) => r.programId === liveTimeReport.programId),
                liveTimeReport,
                projects
            )
        )
);

export const selectCurrentProgramLiveReport = createSelector(
    selectCurrentProgramLiveTimeReport,
    ProgramSelectors.selectCurrentProgramId,
    selectAll,
    ProjectSelectors.selectAll,
    (liveTimeReport, programId, allReports, projects) => {
        const storedLiveReport = allReports.find((r) => r.programId === programId && r.isLive);
        return getProgramLiveReport(storedLiveReport, liveTimeReport, projects);
    }
);

export const selectCurrentProgramSavedStatusReports = createSelector(
    ProgramSelectors.selectCurrentProgramId,
    selectAll,
    (programId, reports) => reports.filter((r) => r.programId === programId && !r.isLive)
);

function getProgramLiveReport(
    storedLiveReport: ProgramStatusReport,
    liveTimeReport: ProgramStatusReport,
    projects: Project[]
): ProgramStatusReport {
    if (!storedLiveReport) return liveTimeReport;
    const modifiedStoredReport: ProgramStatusReport = {
        ...storedLiveReport,
        projectStatuses: liveTimeReport.projectStatuses.map((liveStatus) => {
            const storedStatus = storedLiveReport.projectStatuses.find(
                (s) => s.projectId === liveStatus.projectId
            );
            if (!storedStatus) return liveStatus;
            return { ...liveStatus, summary: storedStatus.summary, planned: storedStatus.planned };
        }),
        budget: {
            approvedBudget: liveTimeReport.budget?.approvedBudget,
            approvedCurrentFY: liveTimeReport.budget?.approvedCurrentFY,
            plannedCost: {
                ...storedLiveReport.budget.plannedCost,
                value: getCurrentProjectStatusReportBudgetValue(
                    storedLiveReport.budget.plannedCost,
                    liveTimeReport.budget.plannedCost
                ),
            },
            actualToDate: {
                ...storedLiveReport.budget.actualToDate,
                value: getCurrentProjectStatusReportBudgetValue(
                    storedLiveReport.budget.actualToDate,
                    liveTimeReport.budget.actualToDate
                ),
            },
            currentFYActuals: {
                ...storedLiveReport.budget.currentFYActuals,
                value: getCurrentProjectStatusReportBudgetValue(
                    storedLiveReport.budget.currentFYActuals,
                    liveTimeReport.budget.currentFYActuals
                ),
            },
            currentFYForecast: {
                ...storedLiveReport.budget.currentFYForecast,
                value: getCurrentProjectStatusReportBudgetValue(
                    storedLiveReport.budget.currentFYForecast,
                    liveTimeReport.budget.currentFYForecast
                ),
            },
        } as Required<ProjectStatusReportBudget>,
        projectManagerNamesMap: liveTimeReport.projectManagerNamesMap,
        topIssues: liveTimeReport.topIssues,
        topRisks: liveTimeReport.topRisks,
        majorMilestones: storedLiveReport.majorMilestones.map((milestone) => {
            const project = projects.find((p) => p.id === milestone.projectId);
            return { ...milestone, projectName: project?.name, projectColor: project?.color };
        }),
    };
    return modifiedStoredReport;
}
