import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LoadIssuesResponse } from 'app/core/services/rest-api/features/issue/issue.responses';
import { EntityPatchSuccessResponse } from 'app/core/services/rest-api/features/patch.response';
import { getFormattedDateString } from 'app/date-format';
import { getPatch } from 'app/utils/json-patch';
import { of } from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    first,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { AppStore } from '..';
import { SnackBarService } from '../../../services/snack-bar/snack-bar.service';
import { CommandResult } from '../../../utils/network/commands/base.command';
import { RestApiService } from '../../services/rest-api';
import { CtxFieldItemType, ProjectFieldSelectors, ProjectFieldType } from '../project-field';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import * as IssueActions from './issue.actions';
import * as IssueSelectors from './issue.selectors';
import { IssueRecord } from './models/issue.model';

@Injectable()
export class IssueEffects {
    getIssue$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IssueActions.getIssue),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        IssueActions.getIssueSuccess({
                            issue: this.getIssueFromDto(response.payload),
                        })
                    ),
                    catchError((error) => of(IssueActions.getIssueFail({ error })))
                )
            )
        )
    );

    loadIssueBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IssueActions.loadIssuesBySiteId),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: LoadIssuesResponse) =>
                        IssueActions.loadIssuesBySiteIdSuccess({
                            issues: response.payload.map((dto) => this.getIssueFromDto(dto)),
                        })
                    ),
                    catchError((error) => of(IssueActions.loadIssuesBySiteIdFail({ error })))
                )
            )
        )
    );

    loadIssueByProjectId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IssueActions.loadIssuesByProjectId),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: LoadIssuesResponse) =>
                        IssueActions.loadIssuesByProjectIdSuccess({
                            issues: response.payload.map((dto) => this.getIssueFromDto(dto)),
                        })
                    ),
                    catchError((error) => of(IssueActions.loadIssuesByProjectIdFail({ error })))
                )
            )
        )
    );

    addIssue$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IssueActions.addIssue),
            concatMap((action) => {
                if (!action.options?.optimistic) {
                    this.snackBar.showInfiniteBar('Adding a new Issue...');
                }
                return this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        if (action.options?.optimistic) {
                            this.snackBar.hide();
                        }
                        return IssueActions.addIssueSuccess({
                            issue: this.getIssueFromDto(response.payload),
                            options: action.options,
                        });
                    }),
                    catchError((error) => {
                        this.snackBar.hide();
                        return of(
                            IssueActions.addIssueFail({
                                error,
                                originAction: action,
                                issueId: action.payload.id,
                            })
                        );
                    })
                );
            })
        )
    );

    updateIssue$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IssueActions.updateIssue),
            withLatestFrom(
                this.store$.select(IssueSelectors.selectAll),
                this.store$.select(
                    ProjectFieldSelectors.selectCurrentSiteProjectFieldByType(
                        ProjectFieldType.IssuesStatus
                    )
                )
            ),
            mergeMap(([action, issues, issueStatusField]) => {
                const issue = issues.find((i) => i.id === action.payload.id);
                const closedStatus = issueStatusField.items.find(
                    (i) => i.ctxItemType === CtxFieldItemType.Closed
                );
                let changes: Partial<IssueRecord> = action.payload.changes;
                const updatedIssue = { ...issue, ...changes };
                if (changes.statusId === closedStatus.id) {
                    changes = { ...changes, closingDate: getFormattedDateString(new Date()) };
                }
                if (changes.closingDate) {
                    changes = { ...changes, statusId: closedStatus.id };
                }
                if (updatedIssue.statusId === closedStatus.id && changes.closingDate === null) {
                    delete changes.closingDate;
                }
                if (changes.statusId && changes.statusId !== closedStatus.id) {
                    changes = { ...changes, closingDate: null };
                }
                if (changes.dateRaised === null) {
                    delete changes.dateRaised;
                }

                const patch = getPatch(issue, changes);
                return of(
                    IssueActions.patchIssue({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchIssue$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IssueActions.patchIssue),
            filter((action) => !!action.payload.patch?.length),
            map((action) => {
                const creationDateProp: keyof IssueRecord = 'creationDate';
                return {
                    ...action,
                    payload: {
                        ...action.payload,
                        patch: action.payload.patch.filter(
                            (operation) => !operation.path.includes(creationDateProp)
                        ),
                    },
                };
            }),
            mergeMap((action) =>
                this.store$.select(IssueSelectors.selectEntityStoredInDb(action.payload.id)).pipe(
                    first((stored) => stored),
                    switchMap(() =>
                        this.restApiService.process(action).pipe(
                            map((response: EntityPatchSuccessResponse) => {
                                if (action.options?.optimistic) return okEmptyAction();
                                return IssueActions.patchIssueSuccess({
                                    id: action.payload.id,
                                    patch: response.payload,
                                });
                            }),
                            catchError((error) =>
                                of(IssueActions.patchIssueFail({ error, originAction: action }))
                            )
                        )
                    )
                )
            )
        )
    );

    deleteIssue$ = createEffect(() =>
        this.actions$.pipe(
            ofType(IssueActions.deleteIssue),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return IssueActions.deleteIssueSuccess({ id: action.payload.id });
                    }),
                    catchError((error) =>
                        of(IssueActions.deleteIssueFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>,
        private snackBar: SnackBarService
    ) {}

    getIssueFromDto(dto: any): IssueRecord {
        return {
            ...Model.createFromDto(IssueRecord, dto),
            dateRaised: dto.dateRaised ? new Date(dto.dateRaised) : undefined,
            dueDate: dto.dueDate ? new Date(dto.dueDate) : undefined,
        };
    }
}
