import { WeekDay } from '@angular/common';
import { JsonProperty } from 'app/utils/json-mapper';
import { Day, getDay, isSameDay } from 'date-fns';

export class Calendar implements CalendarBase {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('siteId')
    siteId?: string = undefined;

    @JsonProperty('projectId')
    projectId?: string = undefined;

    @JsonProperty('name')
    name: string = undefined;

    @JsonProperty('workingDaysOfWeek')
    workingDaysOfWeek: Day[];

    @JsonProperty('workingHoursPerDay')
    workingHoursPerDay: number;

    @JsonProperty('workingDayStartTime')
    workingDayStartTime: WorkDayStartTime;

    @JsonProperty('holidays')
    holidays: SpecialDay[];

    @JsonProperty('workingDays')
    workingDays: SpecialDay[];

    @JsonProperty('isTemplate')
    isTemplate?: boolean;

    @JsonProperty('isDefault')
    isDefault?: boolean;

    @JsonProperty('isCompletixTemplate')
    isCompletixTemplate?: boolean;

    @JsonProperty('isPublished')
    isPublished?: boolean;

    @JsonProperty('isInitialTemplate')
    isInitialTemplate?: boolean;

    constructor(init?: Partial<Calendar>) {
        Object.assign(this, init);
    }
}

export interface CalendarBase {
    workingDaysOfWeek: Day[];
    holidays: SpecialDay[];
    workingDays: SpecialDay[];
    workingHoursPerDay: number;
    workingDayStartTime: WorkDayStartTime;
}

export interface SpecialDay {
    name: string;
    /**
     * Zone agnostic date.
     * To properly show it in the user's current time zone
     * we have to shift the date with the time zone offset.
     */
    date: Date;
}

export interface WorkDayStartTime {
    hour: number;
    minute: number;
}

export const allWeekDays: WeekDay[] = [0, 1, 2, 3, 4, 5, 6];

export const weekDayNamesMap: Record<WeekDay, string> = {
    [WeekDay.Monday]: 'Mon',
    [WeekDay.Tuesday]: 'Tue',
    [WeekDay.Wednesday]: 'Wed',
    [WeekDay.Thursday]: 'Thu',
    [WeekDay.Friday]: 'Fri',
    [WeekDay.Saturday]: 'Sat',
    [WeekDay.Sunday]: 'Sun',
};

export enum WorkWeekType {
    MonFri = 'MonFri',
    SunThu = 'SunThu',
}

export const workWeekTypeToName: Record<WorkWeekType, string> = {
    [WorkWeekType.MonFri]: 'Mon-Fri',
    [WorkWeekType.SunThu]: 'Sun-Thu',
};

export const typicalWorkWeekWorkingDaysMap: Record<WorkWeekType, WeekDay[]> = {
    [WorkWeekType.MonFri]: [1, 2, 3, 4, 5],
    [WorkWeekType.SunThu]: [0, 1, 2, 3, 4],
};

export const typicalWorkWeekDaysMap: Record<WorkWeekType, WeekDay[]> = {
    [WorkWeekType.MonFri]: [1, 2, 3, 4, 5, 6, 0],
    [WorkWeekType.SunThu]: [0, 1, 2, 3, 4, 5, 6],
};

export const typicalWorkWeekWorkingDayNamesMap: Record<WorkWeekType, string[]> = {
    [WorkWeekType.MonFri]: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    [WorkWeekType.SunThu]: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
};

export function isWorkingDate(calendar: Calendar, date: Date): boolean {
    if (!calendar) return false;
    if (!calendar.workingDaysOfWeek?.length) return true;
    return (
        (calendar.workingDaysOfWeek?.includes(getDay(date)) ||
            calendar.workingDays?.some((day) => isSameDay(new Date(day.date), date))) &&
        !calendar.holidays?.some((holiday) => isSameDay(new Date(holiday.date), date))
    );
}
