import { Action, createAction, props } from '@ngrx/store';
import { OptimisticableActionOptions } from 'app/core/store/shared/optimisticable-action';
import { LoadByProjectIdPayload, LoadBySiteIdPayload } from '../shared/models/load.payload';
import { IdPayload } from '../shared/models/shared-payloads';
import {
    AddChangeRequestApprovalPayload,
    AddChangeRequestPayload,
    ChangeRequestIdPayload,
    DeleteChangeRequestApprovalPayload,
    ExpandedChangeRequestsPayload,
    ManageChangeRequestApprovalPayload,
    ManageChangeRequestApprovalSubmissionPayload,
    PatchChangeRequestApprovalPayload,
    PatchChangeRequestPayload,
    UpdateChangeRequestApprovalPayload,
    UpdateChangeRequestPayload,
} from './change-request.payloads';
import { ChangeRequest, ChangeRequestApprover } from './models';

// Load change requests actions
export const loadChangeRequestsByProjectId = createAction(
    '[Change request] Load change requests by Project Id',
    props<{ payload: LoadByProjectIdPayload }>()
);

export const loadChangeRequestsByProjectIdSuccess = createAction(
    '[Change request] Load change requests by Project success',
    props<{ changeRequests: ChangeRequest[]; projectId: string }>()
);

export const loadChangeRequestsByProjectIdFail = createAction(
    '[Change request] Load change requests by Project fail',
    props<{ error: any; projectId: string }>()
);

export const loadChangeRequestsBySiteId = createAction(
    '[Change request] Load change requests by Site Id',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadChangeRequestsBySiteIdSuccess = createAction(
    '[Change request] Load change requests by Site success',
    props<{ changeRequests: ChangeRequest[]; siteId: string; projectIds: string[] }>()
);

export const loadChangeRequestsBySiteIdFail = createAction(
    '[Change request] Load change requests by Site fail',
    props<{ error: any; siteId: string }>()
);

// Load change requests approvers actions
export const loadChangeRequestApprovers = createAction(
    '[Change request] Load change request Approvers by Project Id',
    props<{ payload: LoadByProjectIdPayload }>()
);

export const loadChangeRequestApproversSuccess = createAction(
    '[Change request] Load change request Approvers by Project Id success',
    props<{ approvers: ChangeRequestApprover[] }>()
);

export const loadChangeRequestApproversFail = createAction(
    '[Change request] Load change request Approvers by Project Id fail',
    props<{ error: any }>()
);

// Get change request by id
export const getChangeRequestById = createAction(
    '[Change request] Get change request by id',
    props<{ payload: IdPayload }>()
);

export const getChangeRequestByIdSuccess = createAction(
    '[Change request] Get change request by id success',
    props<{ changeRequest: ChangeRequest }>()
);

export const getChangeRequestByIdFail = createAction(
    '[Change request] Get change request by id fail',
    props<{ error: any }>()
);

// Add change request actions

export const addChangeRequest = createAction(
    '[Changer request] Add',
    props<{ payload: AddChangeRequestPayload }>()
);

export const addChangeRequestSuccess = createAction(
    '[Changer request] Add success',
    props<{ changeRequest: ChangeRequest }>()
);

export const addChangeRequestFail = createAction(
    '[Changer request] Add fail',
    props<{ error: any }>()
);

// Update change request actions

export const updateChangeRequest = createAction(
    '[Changer request] Update',
    props<{ payload: UpdateChangeRequestPayload; options?: OptimisticableActionOptions }>()
);
export const patchChangeRequest = createAction(
    '[Changer request] Patch',
    props<{ payload: PatchChangeRequestPayload; options?: OptimisticableActionOptions }>()
);

export const patchChangeRequestSuccess = createAction(
    '[Changer request] Patch success',
    props<PatchChangeRequestPayload>()
);

export const patchChangeRequestFail = createAction(
    '[Changer request] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Delete change request actions

export const deleteChangeRequest = createAction(
    '[Changer request] Delete',
    props<{ payload: ChangeRequestIdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteChangeRequestSuccess = createAction(
    '[Changer request] Delete success',
    props<ChangeRequestIdPayload>()
);

export const deleteChangeRequestFail = createAction(
    '[Changer request] Delete fail',
    props<{ error: any; originAction: Action }>()
);

// Add approval actions

export const addChangeRequestApproval = createAction(
    '[Changer request] Add approval',
    props<{ payload: AddChangeRequestApprovalPayload }>()
);

export const addChangeRequestApprovalFail = createAction(
    '[Changer request] Add approval fail',
    props<{ error: any }>()
);

// Delete approval actions

export const deleteChangeRequestApproval = createAction(
    '[Changer request] Delete approval',
    props<{ payload: DeleteChangeRequestApprovalPayload; options?: OptimisticableActionOptions }>()
);

export const deleteChangeRequestApprovalFail = createAction(
    '[Changer request] Delete approval fail',
    props<{ error: any; originAction: Action }>()
);

// Manage approval submission actions

export const manageChangeRequestApprovalSubmission = createAction(
    '[Changer request] Manage approval Submission',
    props<{
        payload: ManageChangeRequestApprovalSubmissionPayload;
        options?: OptimisticableActionOptions;
    }>()
);

// Manage approval actions

export const manageChangeRequestApproval = createAction(
    '[Changer request] Manage approval',
    props<{
        payload: ManageChangeRequestApprovalPayload;
    }>()
);

// Update approval actions

export const updateChangeRequestApproval = createAction(
    '[Changer request] Update approval',
    props<{
        payload: UpdateChangeRequestApprovalPayload;
        options?: OptimisticableActionOptions;
    }>()
);

export const patchChangeRequestApproval = createAction(
    '[Changer request] Patch approval',
    props<{
        payload: PatchChangeRequestApprovalPayload;
        options?: OptimisticableActionOptions;
    }>()
);

export const patchChangeRequestApprovalFail = createAction(
    '[Changer request] Patch approval fail',
    props<{ error: any; originAction: Action }>()
);

// Re-Open change request actions

export const reOpenChangeRequest = createAction(
    '[Changer request] Re-Open change request',
    props<{ payload: ChangeRequestIdPayload }>()
);

// Update expanded change requests

export const updateExpandedChangeRequestIds = createAction(
    '[Changer request] Update expanded',
    props<ExpandedChangeRequestsPayload>()
);
