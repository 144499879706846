import { Action } from '@ngrx/store';
import { isOptimisticableAction } from './optimisticable-action';

export interface FailAction extends Action {
    originAction: Action;
}

export function isFailAction(action: Action): action is FailAction {
    return (action as FailAction).originAction != null;
}

export function triggersUndo(failAction: FailAction): boolean {
    if (!isOptimisticableAction(failAction.originAction)) return false;
    const options = failAction.originAction.options;
    return options.optimistic && options.cancelOnFail;
}
