import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getPatch } from 'app/utils/json-patch';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { LayoutSelectors } from '.';
import { AppStore } from '..';
import { RestApiService } from '../../services/rest-api';
import { ProgramSelectors } from '../program';
import { ProjectSelectors } from '../project';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import { OptimisticDefaults } from '../shared/optimisticable-action';
import { SiteSelectors } from '../site';
import {
    changeCurrentProjectRaidRecordViewMode,
    closeSidebar,
    loadLayoutSettings,
    loadLayoutSettingsFail,
    loadLayoutSettingsSuccess,
    openSidebar,
    patchLayoutSettings,
    patchLayoutSettingsFail,
    patchLayoutSettingsSuccess,
    toggleCtxEditorAutoSave,
    updateCurrentProgramLayoutSettings,
    updateCurrentProjectLayoutSettings,
    updateLayoutSettings,
    updateMinutesPageSplitAreaSize,
    updateProjectTreeLayout,
    updateStatusPageSplitAreaSize,
} from './layout.actions';
import {
    LayoutSettings,
    ProgramLayoutSettings,
    ProjectLayoutSettings,
    RaidRecordLayoutSettings,
} from './models';

@Injectable()
export class LayoutEffects {
    loadLayoutSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadLayoutSettings),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) => {
                        const model = Model.createFromDto(LayoutSettings, response.payload);
                        return model;
                    }),
                    map((settings: LayoutSettings) => loadLayoutSettingsSuccess(settings)),
                    catchError((error: any) => of(loadLayoutSettingsFail({ error })))
                )
            )
        )
    );

    patchLayoutSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(patchLayoutSettings),
            filter((action) => !!action.payload.patch.length),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return patchLayoutSettingsSuccess(
                            Model.createFromDto(LayoutSettings, response.payload)
                        );
                    }),
                    catchError((error: any) =>
                        of(patchLayoutSettingsFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    updateLayoutSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateLayoutSettings),
            withLatestFrom(
                this.store$.select(LayoutSelectors.selectLayoutEntry),
                this.store$.select(SiteSelectors.selectCurrentSiteId)
            ),
            mergeMap(([action, settingsEntry, siteId]) => {
                const patch = getPatch(settingsEntry.layoutSettings, action.payload.changes);
                if (!patch?.length) return of(okEmptyAction());
                return of(
                    patchLayoutSettings({
                        payload: { siteId, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    openSidebar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(openSidebar),
            withLatestFrom(
                this.store$.select(LayoutSelectors.selectLayoutEntry),
                this.store$.select(SiteSelectors.selectCurrentSiteId)
            ),
            mergeMap(([action, settingsEntry, siteId]) => {
                const changes: Partial<LayoutSettings> = {
                    ...settingsEntry.layoutSettings,
                    sidebar: { ...(settingsEntry.layoutSettings.sidebar || {}), opened: true },
                };
                const patch = getPatch(settingsEntry.layoutSettings, changes);
                return of(
                    patchLayoutSettings({
                        payload: { siteId, patch },
                        options: OptimisticDefaults,
                    })
                );
            })
        )
    );

    closeSidebar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(closeSidebar),
            withLatestFrom(
                this.store$.select(LayoutSelectors.selectLayoutEntry),
                this.store$.select(SiteSelectors.selectCurrentSiteId)
            ),
            mergeMap(([action, settingsEntry, siteId]) => {
                const changes: Partial<LayoutSettings> = {
                    ...settingsEntry.layoutSettings,
                    sidebar: { ...(settingsEntry.layoutSettings.sidebar || {}), opened: false },
                };
                const patch = getPatch(settingsEntry.layoutSettings, changes);
                return of(
                    patchLayoutSettings({
                        payload: { siteId, patch },
                        options: OptimisticDefaults,
                    })
                );
            })
        )
    );

    toggleCtxEditorAutosave$ = createEffect(() =>
        this.actions$.pipe(
            ofType(toggleCtxEditorAutoSave),
            withLatestFrom(
                this.store$.select(LayoutSelectors.selectLayoutEntry),
                this.store$.select(SiteSelectors.selectCurrentSiteId)
            ),
            mergeMap(([action, settingsEntry, siteId]) => {
                const changes: Partial<LayoutSettings> = {
                    ...settingsEntry.layoutSettings,
                    ctxTextEditor: {
                        ...(settingsEntry.layoutSettings.ctxTextEditor || {}),
                        autoSaveEnabled:
                            !settingsEntry.layoutSettings.ctxTextEditor?.autoSaveEnabled,
                    },
                };
                const patch = getPatch(settingsEntry.layoutSettings, changes);
                return of(
                    patchLayoutSettings({
                        payload: { siteId, patch },
                        options: OptimisticDefaults,
                    })
                );
            })
        )
    );

    updateProjectTreeLayout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateProjectTreeLayout),
            withLatestFrom(
                this.store$.select(LayoutSelectors.selectLayoutEntry),
                this.store$.select(SiteSelectors.selectCurrentSiteId)
            ),
            mergeMap(([action, settingsEntry, siteId]) => {
                const changes: Partial<LayoutSettings> = {
                    ...settingsEntry.layoutSettings,
                    sidebar: {
                        ...(settingsEntry.layoutSettings.sidebar || {}),
                        projectTree: {
                            ...(settingsEntry.layoutSettings.sidebar?.projectTree || {}),
                            ...action.payload,
                        },
                    },
                };
                const patch = getPatch(settingsEntry.layoutSettings, changes);
                return of(
                    patchLayoutSettings({
                        payload: { siteId, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    updateCurrentProjectLayoutSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateCurrentProjectLayoutSettings),
            withLatestFrom(
                this.store$.select(LayoutSelectors.selectLayoutEntry),
                this.store$.select(SiteSelectors.selectCurrentSiteId),
                this.store$.select(ProjectSelectors.selectCurrentProjectId)
            ),
            mergeMap(([action, settingsEntry, siteId, projectId]) => {
                const currentProjectLayoutSettings =
                    settingsEntry.layoutSettings.projectsLayoutSettings?.find(
                        (s) => s.projectId === projectId
                    ) || ({ projectId } as ProjectLayoutSettings);
                const updatedSettings: ProjectLayoutSettings = {
                    ...currentProjectLayoutSettings,
                    ...action.payload,
                };
                const changes: Partial<LayoutSettings> = {
                    ...settingsEntry.layoutSettings,
                    projectsLayoutSettings: [
                        ...(settingsEntry.layoutSettings.projectsLayoutSettings || []).filter(
                            (s) => s.projectId !== projectId
                        ),
                        updatedSettings,
                    ],
                };
                const patch = getPatch(settingsEntry.layoutSettings, changes);
                return of(
                    patchLayoutSettings({
                        payload: { siteId, patch },
                        options: OptimisticDefaults,
                    })
                );
            })
        )
    );

    changeCurrentProjectRaidRecordViewMode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(changeCurrentProjectRaidRecordViewMode),
            withLatestFrom(
                this.store$.select(LayoutSelectors.selectCurrentProjectLayoutSettings),
                this.store$.select(ProjectSelectors.selectCurrentProjectId)
            ),
            mergeMap(([action, projectLayoutSettings, projectId]) => {
                const currentProjectLayoutSettings =
                    projectLayoutSettings || ({ projectId } as ProjectLayoutSettings);

                const changes: Partial<ProjectLayoutSettings> = {
                    projectRaid: {
                        ...(currentProjectLayoutSettings.projectRaid || {}),
                        [action.payload.recordKey]: {
                            ...((currentProjectLayoutSettings.projectRaid?.[
                                action.payload.recordKey
                            ] ?? {}) as RaidRecordLayoutSettings),
                            viewMode: action.payload.mode,
                        } as RaidRecordLayoutSettings,
                    },
                };

                return of(updateCurrentProjectLayoutSettings({ payload: changes }));
            })
        )
    );

    updateMinutesPageSplitAreaSize$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateMinutesPageSplitAreaSize),
            withLatestFrom(
                this.store$.select(LayoutSelectors.selectCurrentProjectLayoutSettings),
                this.store$.select(ProjectSelectors.selectCurrentProjectId)
            ),
            mergeMap(([action, projectLayoutSettings, projectId]) => {
                const currentProjectLayoutSettings =
                    projectLayoutSettings || ({ projectId } as ProjectLayoutSettings);

                const changes: Partial<ProjectLayoutSettings> = {
                    minutesPage: {
                        ...(currentProjectLayoutSettings.minutesPage || {}),
                        splitAreaSize: action.size,
                    },
                };

                return of(updateCurrentProjectLayoutSettings({ payload: changes }));
            })
        )
    );

    updateStatusPageSplitAreaSize$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateStatusPageSplitAreaSize),
            withLatestFrom(
                this.store$.select(LayoutSelectors.selectCurrentProjectLayoutSettings),
                this.store$.select(ProjectSelectors.selectCurrentProjectId)
            ),
            mergeMap(([action, projectLayoutSettings, projectId]) => {
                const currentProjectLayoutSettings =
                    projectLayoutSettings || ({ projectId } as ProjectLayoutSettings);

                const changes: Partial<ProjectLayoutSettings> = {
                    statusPage: {
                        ...(currentProjectLayoutSettings.statusPage || {}),
                        splitAreaSize: action.size,
                    },
                };

                return of(updateCurrentProjectLayoutSettings({ payload: changes }));
            })
        )
    );

    updateCurrentProgramLayoutSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateCurrentProgramLayoutSettings),
            withLatestFrom(
                this.store$.select(LayoutSelectors.selectLayoutEntry),
                this.store$.select(SiteSelectors.selectCurrentSiteId),
                this.store$.select(ProgramSelectors.selectCurrentProgramId)
            ),
            mergeMap(([action, settingsEntry, siteId, programId]) => {
                const currentProgramLayoutSettings =
                    settingsEntry.layoutSettings.programsLayoutSettings?.find(
                        (s) => s.programId === programId
                    ) || ({ programId } as ProgramLayoutSettings);
                const updatedSettings: ProgramLayoutSettings = {
                    ...currentProgramLayoutSettings,
                    ...action.payload,
                };
                const changes: Partial<LayoutSettings> = {
                    ...settingsEntry.layoutSettings,
                    programsLayoutSettings: [
                        ...(settingsEntry.layoutSettings.programsLayoutSettings || []).filter(
                            (s) => s.programId !== programId
                        ),
                        updatedSettings,
                    ],
                };
                const patch = getPatch(settingsEntry.layoutSettings, changes);
                return of(
                    patchLayoutSettings({
                        payload: { siteId, patch },
                        options: OptimisticDefaults,
                    })
                );
            })
        )
    );

    constructor(
        private store$: Store<AppStore.AppState>,
        private actions$: Actions,
        private restApiService: RestApiService
    ) {}
}
