import { Command } from './base.command';

export interface RestfulResource {
    name: string;
    value?: string;
}

export enum RestfulMethod {
    GET,
    POST,
    PUT,
    PATCH,
    DELETE,
}

export class RestfulCommand extends Command {
    private _resource: RestfulResource[] = [];

    // TODO: improve query string implementation or remove it
    private queryString: string;

    get query(): string {
        return this.queryString ? '?' + this.queryString : '';
    }

    set query(value: string) {
        this.queryString = value;
    }

    get method(): RestfulMethod {
        return this._method;
    }

    set method(value: RestfulMethod) {
        this._method = value;
    }

    setResource(resource: RestfulResource[]) {
        this._resource = resource;
    }

    get resource(): string {
        return this._resource.reduce((p, c) => {
            const value = c.value ? `/${c.value}` : '';
            return `${p}/${c.name}${value}`;
        }, '');
    }
}
