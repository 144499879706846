import { createFeatureSelector, createSelector } from '@ngrx/store';
import { defaultSitePermissions } from 'app/core/store/shared/models/default-permissions';
import {
    mergeWithSubscriptionPermissionsPredicate,
    SitePermissions,
    SiteRoleTag,
} from 'app/core/store/shared/models/shared-permissions.model';
import { deepClone } from 'fast-json-patch';
import { mergeDeepRight, mergeDeepWithKey } from 'ramda';
import { selectAccountSiteMembership } from '../shared/account-mebership.selectors';
import { SiteSelectors } from '../site';
import { StateEntry } from '../state-entry';
import { selectSubscriptionPermissionsMap } from '../subscription/subscription.selectors';
import { adapter, State } from './site-role.store';

export const selectSiteRoleEntry = createFeatureSelector<State>(StateEntry.SiteRole);

export const selectTemplatesLoadingStatus = createSelector(
    selectSiteRoleEntry,
    (state) => state.templatesLoadingStatus
);

export const selectCurrentSiteRolesLoadingStatus = createSelector(
    selectSiteRoleEntry,
    (state) => state.currentSiteRolesLoadingStatus
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectSiteRoleEntry);

export const selectSiteRoles = createSelector(
    selectAll,
    SiteSelectors.selectCurrentSiteId,
    (roles, siteId) => roles.filter((r) => r.siteId === siteId)
);

export const selectSiteRoleTemplates = createSelector(selectAll, (roles) =>
    roles.filter((r) => r.isTemplate)
);

export const selectAllLoadedTemplates = createSelector(
    selectSiteRoleTemplates,
    selectTemplatesLoadingStatus,
    (all, status) => (status?.loaded ? all : null)
);

export const selectAllLoadedSiteRoles = createSelector(
    selectSiteRoles,
    selectCurrentSiteRolesLoadingStatus,
    (all, status) => (status?.loaded ? all : null)
);

export const selectStandaloneRoleTemplates = createSelector(selectSiteRoleTemplates, (roles) =>
    roles.filter((role) => role.standalone)
);

export const selectAdditionalSiteRoleTemplates = createSelector(selectSiteRoleTemplates, (roles) =>
    roles.filter((role) => !role.standalone)
);

export const selectAdditionalSiteRoles = createSelector(selectSiteRoles, (roles) =>
    roles.filter((role) => !role.standalone)
);

export const selectAccountCurrentSiteRole = createSelector(
    selectSiteRoles,
    selectAccountSiteMembership,
    (roles, userSiteMembership) => {
        if (!userSiteMembership) return;
        const role = roles.find((r) => r.id === userSiteMembership.mainRoleId);
        const additionalRoles = userSiteMembership.additionalRoleIds?.map((id) =>
            roles.find((r) => r.id === id)
        );
        let mergedPermissions = role?.permissions || {};
        additionalRoles?.forEach((additionalRole) => {
            mergedPermissions = mergeDeepWithKey(
                (k, l, r) => l || r,
                mergedPermissions,
                deepClone(additionalRole?.permissions || {})
            );
        });
        return { ...(role || {}), permissions: mergedPermissions };
    }
);

export const selectCurrentSiteSubscriptionPermissions = createSelector(
    selectSubscriptionPermissionsMap,
    SiteSelectors.selectCurrentSiteId,
    (subscriptionPermissionsMap, siteId) => subscriptionPermissionsMap[siteId] || {}
);

export const selectCurrentSitePermissions = createSelector(
    selectAccountCurrentSiteRole,
    selectCurrentSiteSubscriptionPermissions,
    (role, subscriptionPermissions) => {
        const roleFullPermissions = mergeDeepRight(
            deepClone(defaultSitePermissions),
            deepClone(role?.permissions || {})
        );
        const mergedPermissions: SitePermissions = mergeDeepWithKey(
            mergeWithSubscriptionPermissionsPredicate,
            roleFullPermissions,
            deepClone(subscriptionPermissions) || {}
        );
        return mergedPermissions;
    }
);

export const selectInvitationRoles = createSelector(selectSiteRoles, (roles) =>
    roles.filter((r) => r.standalone && r.tag !== SiteRoleTag.Owner && r.tag !== SiteRoleTag.Guest)
);

export const selectCurrentSiteRoleByTag = (tag: SiteRoleTag) =>
    createSelector(selectSiteRoles, SiteSelectors.selectCurrentSiteId, (roles, siteId) =>
        roles.find((r) => r.siteId === siteId && r.tag === tag)
    );

export const selectSiteRoleByTag = (tag: SiteRoleTag) =>
    createSelector(selectSiteRoles, (roles) => roles.find((r) => r.tag === tag));
