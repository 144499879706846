import { Update } from '@ngrx/entity/src';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import { ProgramMember } from './models';
import {
    deleteProgramMember,
    deleteProgramMemberFail,
    deleteProgramMemberSuccess,
    inviteProgramMemberFail,
    inviteProgramMemberSuccess,
    loadProgramMembersByProgramId,
    loadProgramMembersFail,
    loadProgramMembersSuccess,
    patchProgramMember,
    patchProgramMemberFail,
    patchProgramMemberSuccess,
} from './program-member.actions';
import { adapter, initialState } from './program-member.store';

export const reducer = createReducer(
    initialState,
    on(loadProgramMembersByProgramId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(loadProgramMembersSuccess, (state, payload) => {
        const newState = adapter.addMany(payload.programMembers, adapter.removeAll(state));
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(loadProgramMembersFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(inviteProgramMemberSuccess, (state, payload) => adapter.addOne(payload.member, state)),

    on(inviteProgramMemberFail, (state) => ({ ...state })),

    on(patchProgramMember, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patchedDocument = getPatched(state.entities[action.payload.id], action.payload.patch);
        const memberUpdate: Update<ProgramMember> = {
            id: patchedDocument.id,
            changes: patchedDocument,
        };
        return adapter.updateOne(memberUpdate, state);
    }),

    on(patchProgramMemberSuccess, (state, payload) => {
        const memberUpdate: Update<ProgramMember> = {
            id: payload.member.id,
            changes: payload.member,
        };
        return adapter.updateOne(memberUpdate, state);
    }),

    on(patchProgramMemberFail, (state) => ({ ...state })),

    on(deleteProgramMember, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(deleteProgramMemberSuccess, (state, payload) => adapter.removeOne(payload.id, state)),

    on(deleteProgramMemberFail, (state) => ({ ...state }))
);
