export abstract class Model {
    static createFromDto<T>(modelClass: new () => T, dto: any): T {
        const resultModel = Object.assign(new modelClass(), dto);
        for (const prop in dto) {
            if (!dto.hasOwnProperty(prop)) continue;

            const value = dto[prop];

            // There's an idea to keep all the incoming dates as strings
            // and parse them only when needed, storing in temporary variables.
            // Remove the following code if the idea looks sustainable.
            // if (typeof value === 'string') {
            //     const date = parseJSON(value);
            //     if (!isNaN(date.getDate())) {
            //         resultModel[prop] = date;
            //         continue;
            //     }
            // }
            if (isObject(value)) {
                resultModel[prop] = this.createFromDto(Object, value);
                continue;
            }
            if (isArray(value)) {
                const array = value as any[];
                resultModel[prop] = array.map((val) =>
                    isObject(val) ? this.createFromDto(Object, val) : val
                );
            }
        }
        return resultModel;
    }
}

function isArray(value: any): boolean {
    return Array.isArray(value);
}

function isObject(value: any): boolean {
    return !isArray(value) && typeof value === 'object' && value !== null;
}
