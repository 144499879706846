import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import { ProjectRequest } from './models';
import * as ProjectRequestActions from './project-request.actions';
import { adapter, initialState } from './project-request.store';

export const reducer = createReducer(
    initialState,

    on(ProjectRequestActions.loadProjectRequests, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(ProjectRequestActions.loadProjectRequestsSuccess, (state, payload) => {
        const newState = adapter.addMany(payload.projectRequests, adapter.removeAll(state));
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(ProjectRequestActions.loadProjectRequestsFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(ProjectRequestActions.addProjectRequestSuccess, (state, payload) => ({
        ...adapter.addOne(payload.request, state),
        expandedPrIds: [...state.expandedPrIds, payload.request.id],
    })),

    on(ProjectRequestActions.addProjectRequestFail, (state) => state),

    on(ProjectRequestActions.patchProjectRequest, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patched = getPatched(state.entities[action.payload.id], action.payload.patch);
        const update: Update<ProjectRequest> = {
            id: patched.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(ProjectRequestActions.patchProjectRequestSuccess, (state, payload) => {
        const patched = getPatched(state.entities[payload.id], payload.patch);
        const update: Update<ProjectRequest> = {
            id: patched.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(ProjectRequestActions.patchProjectRequestFail, (state) => state),

    on(ProjectRequestActions.deleteProjectRequestSuccess, (state, payload) =>
        adapter.removeOne(payload.id, state)
    ),

    on(ProjectRequestActions.deleteProjectRequestFail, (state) => state),

    on(ProjectRequestActions.updateExpandedProjectRequestIds, (state, payload) => ({
        ...state,
        expandedPrIds: payload.expandedIds,
    }))
);
