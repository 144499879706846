import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import { Reminder } from './models';
import {
    addReminderFail,
    addReminderSuccess,
    deleteReminder,
    deleteReminderFail,
    deleteReminderSuccess,
    loadReminders,
    loadRemindersFail,
    loadRemindersSuccess,
    patchReminder,
    patchReminderFail,
    patchReminderSuccess,
} from './reminder.actions';
import { adapter, initialState } from './reminder.store';

export const reducer = createReducer(
    initialState,

    on(loadReminders, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(loadRemindersSuccess, (state, { reminders }) => {
        const newState = adapter.addMany(reminders, adapter.removeAll(state));
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(loadRemindersFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(addReminderSuccess, (state, reminders) => {
        return adapter.addOne(reminders, state);
    }),

    on(addReminderFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(patchReminder, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patched = getPatched(state.entities[action.payload.id], action.payload.patch);
        const update: Update<Reminder> = {
            id: patched.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(patchReminderSuccess, (state, payload) => {
        const update: Update<Reminder> = {
            id: payload.reminder.id,
            changes: payload.reminder,
        };
        return adapter.updateOne(update, state);
    }),

    on(patchReminderFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(deleteReminder, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(deleteReminderSuccess, (state, payload) => {
        return adapter.removeOne(payload.id, state);
    }),

    on(deleteReminderFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    }))
);
