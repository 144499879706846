import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { HandsontableSettingsSelectors } from '.';
import { AppStore } from '..';
import { RestApiService } from '../../services/rest-api';
import { generateUUID } from '../id-generator';
import * as HandsontableSettingsActions from './handsontable-settings.actions';
import { HandsontableSettings } from './models/handsontable-settings.model';

@Injectable()
export class HandsontableSettingsEffects {
    addHandsontableSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HandsontableSettingsActions.addHandsontableSettings),
            mergeMap((payload) => {
                // TODO: Add HandsontableSettings at BE
                const handsontableSettings = new HandsontableSettings({
                    id: generateUUID(),
                    projectId: payload.projectId,
                    originTableType: payload.originTableType,
                    originTableId: payload.originTableId,
                    cellsMeta: payload.cellsMeta,
                });
                return of(
                    HandsontableSettingsActions.addHandsontableSettingsSuccess(handsontableSettings)
                );
            })
        )
    );

    updateHandsontableSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HandsontableSettingsActions.updateHandsontableSettings),
            withLatestFrom(this.store$.select(HandsontableSettingsSelectors.selectAll)),
            mergeMap(([payload, settingsList]) => {
                // TODO: update HandsontableSettings at BE
                const settingsExist = settingsList.some(
                    (s) =>
                        s.originTableId === payload.originTableId ||
                        (s.projectId === payload.projectId &&
                            s.originTableType === payload.originTableType)
                );
                if (settingsExist) {
                    return of(
                        HandsontableSettingsActions.updateHandsontableSettingsSuccess(payload)
                    );
                } else {
                    return of(
                        HandsontableSettingsActions.addHandsontableSettings({
                            ...payload,
                            ...payload.changes,
                        })
                    );
                }
            })
        )
    );

    deleteHandsontableSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HandsontableSettingsActions.deleteHandsontableSettings),
            mergeMap((payload) =>
                // TODO: delete HandsontableSettings at BE
                of(HandsontableSettingsActions.deleteHandsontableSettingsSuccess(payload))
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>
    ) {}
}
