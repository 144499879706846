import { Action, createAction, props } from '@ngrx/store';
import { OptimisticableActionOptions } from './../shared/optimisticable-action';
import {
    AddAllocationPayload,
    AddManualAllocationPayload,
    AllocationIdPayload,
    LoadAllocationsByProjectId,
    LoadAllocationsBySiteId,
    PatchAllocationPayload,
    UpdateAllocationPayload,
} from './allocation.payloads';
import { ExtendedProjectMemberAllocation } from './models';

// Get Allocations actions

export const loadAllocationsBySite = createAction(
    '[Allocation] Load by Site id',
    props<{ payload: LoadAllocationsBySiteId }>()
);

export const loadAllocationsByProject = createAction(
    '[Allocation] Load by Project id',
    props<{ payload: LoadAllocationsByProjectId }>()
);

export const loadAllocationsSuccess = createAction(
    '[Allocation] Load success',
    props<{ allocations: ExtendedProjectMemberAllocation[] }>()
);

export const loadAllocationsFail = createAction('[Allocation] Load fail', props<{ error: any }>());

// Update Allocation actions

export const changeAllocation = createAction('[Allocation] Change', props<AddAllocationPayload>());

// Add Allocation actions

export const addAllocation = createAction(
    '[Allocation] Add',
    props<{ payload: AddAllocationPayload }>()
);

export const addManualAllocation = createAction(
    '[Allocation] Add manual',
    props<{ payload: AddManualAllocationPayload }>()
);

export const addAllocationSuccess = createAction(
    '[Allocation] Add success',
    props<{ allocation: ExtendedProjectMemberAllocation }>()
);

export const addAllocationFail = createAction('[Allocation] Add fail', props<{ error: any }>());

// Update Allocation actions

export const updateAllocation = createAction(
    '[Allocation] Update',
    props<{ payload: UpdateAllocationPayload; options?: OptimisticableActionOptions }>()
);

export const patchAllocation = createAction(
    '[Allocation] Patch',
    props<{ payload: PatchAllocationPayload; options?: OptimisticableActionOptions }>()
);

export const patchAllocationSuccess = createAction(
    '[Allocation] Patch success',
    props<PatchAllocationPayload>()
);

export const patchAllocationFail = createAction(
    '[Allocation] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Delete Allocation actions

export const deleteAllocation = createAction(
    '[Allocation] Delete',
    props<{ payload: AllocationIdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteAllocationSuccess = createAction(
    '[Allocation] Delete success',
    props<AllocationIdPayload>()
);

export const deleteAllocationFail = createAction(
    '[Allocation] Delete fail',
    props<{ error: any; originAction: Action }>()
);
