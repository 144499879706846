import { createFeatureSelector, createSelector } from '@ngrx/store';
import { deepClone } from 'fast-json-patch';
import { mergeDeepWithKey } from 'ramda';
import { ProgramSelectors } from '../program';
import { ProgramMemberSelectors } from '../program-member';
import { selectAccountSiteMembership } from '../shared/account-mebership.selectors';
import {
    mergeWithSubscriptionPermissionsPredicate,
    ProgramPermissions,
} from '../shared/models/shared-permissions.model';
import { SiteSelectors } from '../site';
import { SiteRoleSelectors } from '../site-role';
import { StateEntry } from '../state-entry';
import { ProgramRoleTag } from './../shared/models/shared-permissions.model';
import { ProgramRole } from './models';
import { adapter, State } from './program-role.store';

export const selectProgramRoleEntry = createFeatureSelector<State>(StateEntry.ProgramRole);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectProgramRoleEntry);

export const selectProgramRoleTemplatesLoadingStatus = createSelector(
    selectProgramRoleEntry,
    (state) => state.templatesLoadingStatus
);

export const selectCurrentSiteProgramRolesLoadingStatus = createSelector(
    selectProgramRoleEntry,
    (state) => state.currentSiteRolesLoadingStatus
);

export const selectCurrentSiteProgramRoles = createSelector(
    selectAll,
    SiteSelectors.selectCurrentSiteId,
    (roles, siteId) => roles.filter((r) => r.siteId === siteId)
);

export const selectProgramRoleTemplates = createSelector(selectAll, (roles) =>
    roles.filter((r) => r.isTemplate)
);

export const selectAllLoadedTemplates = createSelector(
    selectProgramRoleTemplates,
    selectProgramRoleTemplatesLoadingStatus,
    (all, status) => (status?.loaded ? all : null)
);

export const selectAllLoadedCurrentSiteProgramRoles = createSelector(
    selectCurrentSiteProgramRoles,
    selectCurrentSiteProgramRolesLoadingStatus,
    (all, status) => (status?.loaded ? all : null)
);

export const selectProgramRolesMap = createSelector(
    selectCurrentSiteProgramRoles,
    SiteRoleSelectors.selectCurrentSitePermissions,
    selectAccountSiteMembership,
    ProgramMemberSelectors.selectAll,
    ProgramSelectors.selectAll,
    SiteRoleSelectors.selectCurrentSiteSubscriptionPermissions,
    (
        programRoles,
        sitePermissions,
        userSiteMembership,
        programMembers,
        programs,
        subscriptionPermissions
    ) => {
        const map: { [programId: string]: ProgramRole } = {};
        if (!sitePermissions) return map;
        const siteLevelProgramPermissions: ProgramPermissions = sitePermissions?.programs || {};
        programs.forEach((program) => {
            const programMembership = programMembers.find(
                (pm) => pm.programId === program.id && pm.siteMemberId === userSiteMembership?.id
            );
            const programRole = programRoles.find((role) => role.id === programMembership?.roleId);
            const programPermissions = programRole?.permissions || {};
            const mergedPermissions = mergeDeepWithKey(
                (k, l, r) => l || r,
                deepClone(siteLevelProgramPermissions),
                deepClone(programPermissions)
            );

            map[program.id] = {
                ...programRole,
                permissions: mergeDeepWithKey(
                    mergeWithSubscriptionPermissionsPredicate,
                    mergedPermissions,
                    deepClone(subscriptionPermissions.programs) || {}
                ),
            };
        });
        return map;
    }
);

export const selectCurrentProgramPermissions = createSelector(
    ProgramSelectors.selectCurrentProgramId,
    selectProgramRolesMap,
    ProgramSelectors.selectProgramEntry,
    (programId, rolesMap, programEntry) =>
        rolesMap[programId]?.permissions ?? programEntry.currentProgramPermissions
);

export const selectCurrentSiteProgramRoleByTag = (tag: ProgramRoleTag) =>
    createSelector(selectCurrentSiteProgramRoles, (roles) => roles.find((r) => r.tag === tag));
