import { Action, createAction, props } from '@ngrx/store';
import { IdPayload } from 'app/core/store/shared/models/shared-payloads';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import { SiteIdPayload } from '../site/site.payloads';
import { CtxNotification, SendNotificationPayload } from './models';

// Load Notifications actions

export const loadAccountLevelNotifications = createAction(
    '[Notification] Load account level notifications'
);

export const loadAccountLevelNotificationsSuccess = createAction(
    '[Notification] Load account level notifications success',
    props<{ notifications: CtxNotification[] }>()
);

export const loadAccountLevelNotificationsFail = createAction(
    '[Notification] Load account level notifications fail',
    props<{ error: any }>()
);

export const loadSiteLevelNotifications = createAction(
    '[Notification] Load site level notifications',
    props<{ payload: SiteIdPayload }>()
);

export const loadSiteLevelNotificationsSuccess = createAction(
    '[Notification] Load site level notifications success',
    props<{ notifications: CtxNotification[] }>()
);

export const loadSiteLevelNotificationsFail = createAction(
    '[Notification] Load site level notifications fail',
    props<{ error: any }>()
);

// Update Notification actions

export const markNotificationRead = createAction(
    '[Notification] Mark read',
    props<{ payload: IdPayload; options?: OptimisticableActionOptions }>()
);

export const markNotificationReadSuccess = createAction(
    '[Notification] Mark read success',
    props<{ notification: CtxNotification }>()
);

export const markNotificationReadFail = createAction(
    '[Notification] Mark read fail',
    props<{ error: any; originAction: Action }>()
);

// Notification received action

export const addNotification = createAction(
    '[Notification] Add',
    props<{ notification: CtxNotification }>()
);

// Delete Notification actions

export const deleteNotification = createAction(
    '[Notification] Delete',
    props<{ payload: IdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteNotificationSuccess = createAction(
    '[Notification] Delete success',
    props<IdPayload>()
);

export const deleteNotificationFail = createAction(
    '[Notification] Delete fail',
    props<{ error: any; originAction: Action }>()
);

// Send notification actions

export const sendNotification = createAction(
    '[Notification] Send',
    props<{ payload: SendNotificationPayload }>()
);
