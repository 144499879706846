export enum RouteBrick {
    // App router
    Login = 'login',
    Dev = 'dev',
    Error = 'error',
    Theme = 'theme',

    // MainApp router
    Welcome = 'welcome',
    Account = 'account',
    Dashboard = 'dashboard',
    Reminders = 'reminders',
    Site = 'site',
    Admin = 'admin',
    ProjectList = 'project-list',
    TimeTracker = 'time-tracker',

    // Account router
    Preferences = 'preferences',

    Schedule = 'schedule',
    Agile = 'agile',
    Backlog = 'backlog',
    Sprints = 'sprints',
    Tasks = 'tasks',
    ProjectSettings = 'settings',
    Spreadsheet = 'spreadsheet',
    RootFolder = 'root',

    Home = 'home',
    Documents = 'documents',
    ChangeRequests = 'change-requests',
    Team = 'team',
    Raci = 'raci',
    Gating = 'gating',
    Minutes = 'minutes',
    BlankMinute = 'blank',
    Summary = 'summary',
    Resources = 'resources',
    Raid = 'raid',
    Budget = 'budget',
    Notes = 'notes',
    Status = 'status',
    Calendar = 'calendar',
    Vendors = 'vendors',

    // Site router
    Subscribe = 'subscribe',
    SettingsAndBilling = 'settings-and-billing',
    Billing = 'billing',
    Settings = 'settings',
    Users = 'users',
    Roles = 'roles',
    RoleTemplates = 'role-templates',
    Profiles = 'profiles',
    Approvals = 'approvals',
    MyWork = 'my',

    Overview = 'overview',
    Archive = 'archive',
    Programs = 'programs',
    Projects = 'projects',
    Additional = 'additional',
    ExecutiveView = 'executive',
    Portfolios = 'portfolios',
    Financials = 'financials',
    LegalNotice = 'legal-notice',
    SiteLogs = 'logs',
    Requests = 'requests',
    ApprovalsLog = 'approvals-log',
    OperationsLog = 'operations-log',
    History = 'history',
    SiteMetrics = 'metrics',
    ProjectManagerDashboard = 'manager-dashboard',
    LessonLearned = 'lesson-learned',

    // Templates
    Templates = 'templates',
    Timesheet = 'timesheet',
    DocumentTemplate = 'document',
    ChangeRequestTemplate = 'change-request',
    Calendars = 'calendars',
    Communication = 'communication',

    // Project
    Risks = 'risks',
    Actions = 'actions',
    Issues = 'issues',
    Decisions = 'decisions',

    // Admin
    Plans = 'plans',
    Sites = 'sites',
    AppMessage = 'app-message',

    // Authentication
    Authentication = 'authentication',
    LogIn = 'login',
    SignUp = 'signup',
    AuthenticatePasscode = 'passcode',
    AuthenticateOAuth = 'oauth',
}
