import { createCtxEntityAdapter, CtxEntityAdapter, CtxEntityState } from '../store';
import { RiskRecord } from './models';

export interface LoadingStatus {
    serverRequestInProgress: boolean;
    loaded: boolean;
}

export interface State extends CtxEntityState<RiskRecord>, LoadingStatus {}

export const adapter: CtxEntityAdapter<RiskRecord> = createCtxEntityAdapter<RiskRecord>();

export const initialState: State = adapter.getCtxInitialState({
    serverRequestInProgress: false,
    loaded: false,
});
