import { createFeatureSelector, createSelector } from '@ngrx/store';
import { dateIsBetweenOrEqualDates } from 'app/date-format';
import { ProjectSelectors } from '../project';
import { StateEntry } from '../state-entry';
import { adapter, State } from './sprint.store';

export const selectSprintEntry = createFeatureSelector<State>(StateEntry.Sprint);

export const selectLoadingStatusesPerProjectId = createSelector(
    selectSprintEntry,
    (state) => state.loadingStatusByProjectId
);

export const selectLoadingStatusForCurrentProject = createSelector(
    selectSprintEntry,
    ProjectSelectors.selectCurrentProjectId,
    (state, projectId) => state.loadingStatusByProjectId[projectId]
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectSprintEntry);

export const selectCurrentProjectSprints = createSelector(
    selectAll,
    ProjectSelectors.selectCurrentProjectId,
    (sprints, projectId) => sprints.filter((s) => s.projectId === projectId)
);

export const selectAllActiveSprints = createSelector(selectAll, (sprints) =>
    sprints.filter(
        (s) =>
            !s.isCompleted &&
            s.isStarted &&
            dateIsBetweenOrEqualDates(new Date(), new Date(s.startDate), new Date(s.endDate))
    )
);

export const selectCurrentProjectActiveSprints = createSelector(
    selectAllActiveSprints,
    ProjectSelectors.selectCurrentProjectId,
    (sprints, projectId) => sprints.filter((s) => s.projectId === projectId)
);
