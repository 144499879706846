import { createFeatureSelector, createSelector } from '@ngrx/store';
import { KanbanBoardSelectors, KanbanBoardShapeLink } from '../kanban';
import { ProjectSelectors } from '../project';
import { ProjectFieldSelectors, ProjectFieldType } from '../project-field';
import { ProjectRoleSelectors } from '../project-role';
import { selectCurrentProjectId } from '../project/project.selectors';
import { selectAccountSiteMembership } from '../shared/account-mebership.selectors';
import { getRaidStatusListByProjectMap } from '../shared/models/raid-shared.model';
import { getRaidRecordPermissionsMap } from '../shared/models/shared-permissions.model';
import { StateEntry } from '../state-entry';
import { adapter, LoadingStatus, State } from './action.store';
import { ActionRecord } from './models';

export const selectActionEntry = createFeatureSelector<State>(StateEntry.Action);

export const selectLoadingStatus = createSelector(
    selectActionEntry,
    (state): LoadingStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
    })
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
    selectEntityStoredInDb: selectAddingInProgresIds,
} = adapter.getCtxSelectors(selectActionEntry);

export const selectCurrentProjectActions = createSelector(
    selectAll,
    ProjectSelectors.selectCurrentProjectId,
    (actions, projectId) => actions.filter((a) => a.projectId === projectId)
);

export const selectActionPermissionsMap = createSelector(
    selectAll,
    ProjectRoleSelectors.selectAccountProjectRolesMap,
    selectAccountSiteMembership,
    (actions, projectRolesMap, accountSiteMembership) =>
        getRaidRecordPermissionsMap(actions, projectRolesMap, 'actions', (action: ActionRecord) =>
            action.members?.some((m) => m.siteMemberId === accountSiteMembership.id)
        )
);

export const selectCurrentSiteActionsStatusListMap = createSelector(
    ProjectSelectors.selectCurrentSiteProjects,
    KanbanBoardSelectors.selectAll,
    ProjectFieldSelectors.selectCurrentSiteProjectFieldByType(ProjectFieldType.ActionStatus),
    (projects, kanbanBoards, risksProjectField) =>
        getRaidStatusListByProjectMap(
            projects,
            kanbanBoards,
            KanbanBoardShapeLink.Actions,
            risksProjectField
        )
);

export const selectCurrentProjectActionStatuses = createSelector(
    selectCurrentSiteActionsStatusListMap,
    selectCurrentProjectId,
    (map, currentProjectId) => {
        return map[currentProjectId];
    }
);

export const selectActionStoredInDb = (actionId: string) =>
    createSelector(selectActionEntry, (state) => {
        return !state.addingInProgressEntityIds.includes(actionId);
    });
