import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import { Site } from './models';
import * as SiteActions from './site.actions';
import { adapter, initialState } from './site.store';

export const reducer = createReducer(
    initialState,
    on(SiteActions.loadSites, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(SiteActions.loadSitesSuccess, (state, payload) => {
        const newState = adapter.addMany(payload.sites, adapter.removeAll(state));
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(SiteActions.loadSitesFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(SiteActions.loadInitialSiteSuccess, (state, payload) => {
        const newState = adapter.addOne(payload.site, adapter.removeOne(payload.site.id, state));
        return newState;
    }),

    on(SiteActions.setCurrentSiteLoaded, (state, payload) => ({
        ...state,
        initialSiteId: payload.siteId,
    })),

    on(SiteActions.loadInitialSiteFail, (state) => ({
        ...state,
        initialSiteId: null,
    })),

    on(SiteActions.loadSiteMetrics, (state) => ({
        ...state,
        siteMetricsLoading: true,
    })),

    on(SiteActions.loadSiteMetricsSuccess, (state, payload) => ({
        ...state,
        currentSiteMetrics: payload.siteMetrics,
        siteMetricsLoading: false,
    })),

    on(SiteActions.loadSiteMetricsFail, (state) => ({
        ...state,
        siteMetricsLoading: false,
    })),

    on(SiteActions.getSiteByIdSuccess, (state, payload) =>
        adapter.addOne(payload.site, adapter.removeOne(payload.site.id, state))
    ),

    on(SiteActions.addSiteSuccess, (state, payload) => adapter.addOne(payload.site, state)),

    on(SiteActions.patchSiteSuccess, (state, payload) => {
        const site = state.entities[payload.siteId];
        const patchedSite = getPatched(site, payload.patch);
        return adapter.addOne(patchedSite, adapter.removeOne(patchedSite.id, state));
    }),

    on(SiteActions.requestChangeSiteOwner, (state, action) => {
        if (!action.options?.optimistic) return state;
        const update: Update<Site> = {
            id: action.payload.siteId,
            changes: {
                requestedSiteOwner: {
                    requestDate: new Date(),
                    siteMemberId: action.payload.newOwnerSiteMemberId,
                },
            },
        };
        return adapter.updateOne(update, state);
    }),

    on(SiteActions.cancelRequestChangeSiteOwner, (state, action) => {
        if (!action.options?.optimistic) return state;
        const update: Update<Site> = {
            id: action.payload.siteId,
            changes: { requestedSiteOwner: null },
        };
        return adapter.updateOne(update, state);
    }),

    on(SiteActions.acceptRequestChangeSiteOwnerSuccess, (state, payload) => {
        const update: Update<Site> = {
            id: payload.siteId,
            changes: { requestedSiteOwner: null },
        };
        return adapter.updateOne(update, state);
    }),

    on(SiteActions.deleteSiteSuccess, (state, payload) => adapter.removeOne(payload.siteId, state)),

    on(SiteActions.changeCurrentSiteId, (state, action) => ({
        ...state,
        currentSiteId: action.payload.siteId,
    })),

    on(SiteActions.setSignalrConnectionId, (state, payload) => ({
        ...state,
        signalrConnectionId: payload.id,
    }))
);
