import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EntityPatchSuccessResponse } from 'app/core/services/rest-api/features/patch.response';
import { getPatch } from 'app/utils/json-patch';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { ProjectMemberSelectors } from '.';
import { AppStore } from '..';
import { SnackBarService } from '../../../services/snack-bar/snack-bar.service';
import { RestApiService } from '../../services/rest-api';
import { ProjectRoleSelectors } from '../project-role';
import { Model } from '../shared/models/base.model';
import { ProjectRoleTag } from '../shared/models/shared-permissions.model';
import { OptimisticDefaults } from '../shared/optimisticable-action';
import { okEmptyAction } from './../project/project.actions';
import { ProjectMember, ProjectMemberStatus } from './models';
import * as ProjectMemberActions from './project-member.actions';

@Injectable()
export class ProjectMemberEffects {
    loadMembersBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectMemberActions.loadProjectMembersBySiteId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) =>
                        response.payload.map((member) => Model.createFromDto(ProjectMember, member))
                    ),
                    map((members: ProjectMember[]) =>
                        ProjectMemberActions.loadProjectMembersBySiteIdSuccess({
                            projectMembers: members,
                        })
                    ),
                    catchError((error) =>
                        of(ProjectMemberActions.loadProjectMembersBySiteIdFail({ error }))
                    )
                )
            )
        )
    );

    loadMembersByProjectId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectMemberActions.loadProjectMembersByProjectId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) =>
                        response.payload.map((member) => Model.createFromDto(ProjectMember, member))
                    ),
                    map((members: ProjectMember[]) =>
                        ProjectMemberActions.loadProjectMembersByProjectIdSuccess({
                            projectMembers: members,
                            projectId: action.payload.projectId,
                        })
                    ),
                    catchError((error) =>
                        of(ProjectMemberActions.loadProjectMembersByProjectIdFail({ error }))
                    )
                )
            )
        )
    );

    inviteProjectMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectMemberActions.inviteProjectMember),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Inviting member to the Project...');
                return this.restApiService.process(action).pipe(
                    map((response) => {
                        this.snackBarService.hide();
                        return ProjectMemberActions.inviteProjectMemberSuccess({
                            member: Model.createFromDto(ProjectMember, response.payload),
                        });
                    }),
                    catchError((errorResponse) => {
                        this.snackBarService.hide();
                        return of(
                            ProjectMemberActions.inviteProjectMemberFail({ error: errorResponse })
                        );
                    })
                );
            })
        )
    );

    updateProjectMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectMemberActions.updateProjectMember),
            withLatestFrom(this.store$.select(ProjectMemberSelectors.selectAll)),
            mergeMap(([action, projectMembers]) => {
                const memberToPatch = projectMembers.find(
                    (member) => member.id === action.payload.id
                );
                const patch = getPatch(memberToPatch, action.payload.changes);
                return of(
                    ProjectMemberActions.patchProjectMember({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                        snackBarMessage: action.snackBarMessage,
                    })
                );
            })
        )
    );

    patchProjectMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectMemberActions.patchProjectMember),
            mergeMap((action) => {
                if (!action.options?.optimistic) {
                    this.snackBarService.showInfiniteBar(
                        action.snackBarMessage || 'Updating Project Member...'
                    );
                }
                return this.restApiService.process(action).pipe(
                    map((response: EntityPatchSuccessResponse) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        this.snackBarService.hide();
                        return ProjectMemberActions.patchProjectMemberSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) => {
                        this.snackBarService.show('Change failed');
                        return of(
                            ProjectMemberActions.patchProjectMemberFail({
                                error,
                                originAction: action,
                            })
                        );
                    })
                );
            })
        )
    );

    deleteManyProjectMembers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectMemberActions.deleteManyProjectMembers),
            mergeMap((action) => {
                const actions = action.payload.ids.map((id) =>
                    ProjectMemberActions.deleteProjectMember({
                        payload: { id },
                    })
                );
                return actions;
            })
        )
    );

    deleteProjectMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectMemberActions.deleteProjectMember),
            withLatestFrom(
                this.store$.select(ProjectMemberSelectors.selectAll),
                this.store$.select(
                    ProjectRoleSelectors.selectCurrentSiteProjectRoleByTag(ProjectRoleTag.Manager)
                )
            ),
            switchMap(([action, allProjectMembers, managerRole]) => {
                const member = allProjectMembers.find((m) => m.id === action.payload.id);
                const anotherManager = allProjectMembers.find(
                    (m) =>
                        m.projectId === member.projectId &&
                        m.roleId === managerRole.id &&
                        m.id !== member.id &&
                        m.status === ProjectMemberStatus.Active
                );
                let setPrimaryManagerAction = null;
                if (member.roleId === managerRole.id && member.isPrimaryManager && anotherManager) {
                    setPrimaryManagerAction = ProjectMemberActions.setPrimaryProjectManager({
                        payload: { id: anotherManager.id },
                        options: OptimisticDefaults,
                    });
                }
                this.snackBarService.showInfiniteBar('Processing...');
                return this.restApiService.process(action).pipe(
                    switchMap((response: CommandResult) => {
                        this.snackBarService.hide();
                        return [
                            ProjectMemberActions.deleteProjectMemberSuccess({
                                id: action.payload.id,
                                deleted: response.payload,
                            }),
                            setPrimaryManagerAction,
                        ].filter((a) => !!a);
                    }),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(ProjectMemberActions.deleteProjectMemberFail({ error }));
                    })
                );
            })
        )
    );

    setPrimaryProjectManager$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectMemberActions.setPrimaryProjectManager),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ProjectMemberActions.setPrimaryProjectManagerSuccess({
                            id: action.payload.id,
                        });
                    }),
                    catchError((error) =>
                        of(
                            ProjectMemberActions.setPrimaryProjectManagerFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    reOpenProjectMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectMemberActions.reOpenProjectMember),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ProjectMemberActions.patchProjectMemberSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) =>
                        of(ProjectMemberActions.reOpenProjectMemberFail({ error }))
                    )
                )
            )
        )
    );

    resendInvite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectMemberActions.resendInvite),
            mergeMap((action) => this.restApiService.process(action))
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>,
        private snackBarService: SnackBarService
    ) {}
}
