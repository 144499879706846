import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import {
    AsyncUpdateStatusDefaultLoad,
    AsyncUpdateStatusDefaultLoadSuccess,
} from './../shared/async-update-status.store';
import { SiteRole } from './models';
import * as SiteRoleActions from './site-role.actions';
import { adapter, initialState } from './site-role.store';

export const reducer = createReducer(
    initialState,
    on(SiteRoleActions.loadSiteRoleTemplates, (state) => ({
        ...state,
        templatesLoadingStatus: {
            ...state.templatesLoadingStatus,
            ...AsyncUpdateStatusDefaultLoad,
        },
    })),

    on(SiteRoleActions.loadSiteRoleTemplatesSuccess, (state, payload) => {
        const newState = adapter.addMany(
            payload.roles,
            adapter.removeMany(
                Object.values(state.entities)
                    .filter((r) => r.isTemplate)
                    .map((r) => r.id),
                state
            )
        );
        return {
            ...newState,
            templatesLoadingStatus: {
                ...state.templatesLoadingStatus,
                ...AsyncUpdateStatusDefaultLoadSuccess,
            },
        };
    }),

    on(SiteRoleActions.loadSiteRoleTemplatesFail, (state, payload) => ({
        ...state,
        templatesLoadingStatus: {
            ...state.templatesLoadingStatus,
            ...AsyncUpdateStatusDefaultLoad,
            error: payload.error,
        },
    })),

    on(SiteRoleActions.loadRolesBySite, (state) => ({
        ...state,
        currentSiteRolesLoadingStatus: {
            ...state.currentSiteRolesLoadingStatus,
            ...AsyncUpdateStatusDefaultLoad,
        },
    })),

    on(SiteRoleActions.loadRolesBySiteSuccess, (state, payload) => {
        const newState = adapter.addMany(
            payload.roles,
            adapter.removeMany(
                Object.values(state.entities)
                    .filter((r) => r.siteId === payload.siteId)
                    .map((r) => r.id),
                state
            )
        );
        return {
            ...newState,
            currentSiteRolesLoadingStatus: {
                ...state.currentSiteRolesLoadingStatus,
                ...AsyncUpdateStatusDefaultLoadSuccess,
            },
        };
    }),

    on(SiteRoleActions.loadRolesBySiteFail, (state, payload) => ({
        ...state,
        currentSiteRolesLoadingStatus: {
            ...state.currentSiteRolesLoadingStatus,
            ...AsyncUpdateStatusDefaultLoad,
            error: payload.error,
        },
    })),

    on(SiteRoleActions.addSiteRoleSuccess, (state, payload) => adapter.addOne(payload.role, state)),

    on(SiteRoleActions.addSiteRoleFail, (state) => ({ ...state })),

    on(SiteRoleActions.patchSiteRole, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patchedRole = getPatched(state.entities[action.payload.id], action.payload.patch);
        const roleUpdate: Update<SiteRole> = {
            id: patchedRole.id,
            changes: patchedRole,
        };
        return adapter.updateOne(roleUpdate, state);
    }),

    on(SiteRoleActions.patchSiteRoleSuccess, (state, payload) => {
        const patchedRole = getPatched(state.entities[payload.id], payload.patch);
        const roleUpdate: Update<SiteRole> = {
            id: patchedRole.id,
            changes: patchedRole,
        };
        return adapter.updateOne(roleUpdate, state);
    }),

    on(SiteRoleActions.patchSiteRoleFail, (state) => ({ ...state })),

    on(SiteRoleActions.setSiteRoleTag, (state, action) => {
        if (!action.options?.optimistic) return state;
        const roleUpdate: Update<SiteRole> = {
            id: action.payload.roleId,
            changes: { tag: action.payload.tag },
        };
        const existingRoleWithTag = Object.values(state.entities).find(
            (r) => r.tag === action.payload.tag
        );
        const existingRoleUpdate: Update<SiteRole> = {
            id: existingRoleWithTag?.id,
            changes: { tag: null },
        };
        return adapter.updateMany([roleUpdate, existingRoleUpdate], state);
    }),

    on(SiteRoleActions.setSiteRoleTagSuccess, (state, payload) => {
        const updates: Update<SiteRole>[] = payload.siteRoles.map((role) => ({
            id: role.id,
            changes: role,
        }));
        return adapter.updateMany(updates, state);
    }),

    on(SiteRoleActions.setSiteRoleTagFail, (state) => ({ ...state })),

    on(SiteRoleActions.deleteSiteRole, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(SiteRoleActions.deleteSiteRoleSuccess, (state, payload) =>
        adapter.removeOne(payload.id, state)
    ),

    on(SiteRoleActions.deleteSiteRoleFail, (state) => ({ ...state }))
);
