import { createReducer, on } from '@ngrx/store';
import { SubscriptionPlan } from 'app/core/models/billing';
import { getPatched } from 'app/utils/json-patch';
import {
    AsyncUpdateStatusDefault,
    AsyncUpdateStatusDefaultLoad,
    AsyncUpdateStatusDefaultLoadSuccess,
} from '../shared/async-update-status.store';
import * as SubscriptionPlanActions from './subscription-plan.actions';
import { adapter, initialState } from './subscription-plan.store';

export const reducer = createReducer(
    initialState,

    on(SubscriptionPlanActions.loadSubscriptionPlans, (state) => ({
        ...state,
        ...AsyncUpdateStatusDefaultLoad,
    })),

    on(SubscriptionPlanActions.loadSubscriptionPlansSuccess, (state, payload) => ({
        ...adapter.addMany(
            payload.entities,
            adapter.removeMany(
                payload.entities.map((e) => e.id),
                state
            )
        ),
        ...AsyncUpdateStatusDefaultLoadSuccess,
    })),

    on(SubscriptionPlanActions.loadSubscriptionPlansFail, (state, payload) => ({
        ...state,
        ...AsyncUpdateStatusDefault,
        loadingFailed: true,
        error: payload.error,
    })),

    on(SubscriptionPlanActions.addSubscriptionPlanSuccess, (state, payload) =>
        adapter.addOne(payload.entity, state)
    ),

    on(SubscriptionPlanActions.updateSubscriptionPlan, (state, action) => {
        if (!action.options?.optimistic) return state;
        const currentPlan = state.entities[action.payload.id];
        const updatedPlan = new SubscriptionPlan(getPatched(currentPlan, action.payload.patch));
        return adapter.addOne(updatedPlan, adapter.removeOne(updatedPlan.id, state));
    }),

    on(SubscriptionPlanActions.updateSubscriptionPlanSuccess, (state, payload) => {
        return adapter.addOne(payload.entity, adapter.removeOne(payload.entity.id, state));
    }),

    on(SubscriptionPlanActions.deleteSubscriptionPlan, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(SubscriptionPlanActions.deleteSubscriptionPlanSuccess, (state, payload) =>
        adapter.removeOne(payload.id, state)
    )
);
