import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { LoadingStatusByProjectId, LoadingStatusBySiteId } from 'app/core/models/shared-models';
import { Vendor } from './models';

export interface State
    extends EntityState<Vendor>,
        LoadingStatusByProjectId,
        LoadingStatusBySiteId {}

export const adapter: EntityAdapter<Vendor> = createEntityAdapter<Vendor>();

export const initialState: State = adapter.getInitialState({
    loadingStatusByProjectId: {},
    loadingStatusBySiteId: {},
});
