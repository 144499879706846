import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityLevel } from 'app/core/models/shared-models';
import { BacklogTask } from 'app/core/store/backlog-task';
import { ListContainerSelectors, ListContainerType } from '../project-phase-container';
import { selectAccountProjectRolesMap } from '../project-role/project-role.selectors';
import { selectAccountSiteMembership } from '../shared/account-mebership.selectors';
import { AgilePermissions, CrudPermissions } from '../shared/models/shared-permissions.model';
import { selectCurrentSiteId } from '../site/site.selectors';
import { StateEntry } from '../state-entry';
import { adapter, State } from './backlog-task.store';
import { BacklogTaskStatus } from './models';

export const selectBacklogTaskEntry = createFeatureSelector<State>(StateEntry.BacklogTask);

export const selectLoadingStatusesPerContainerId = createSelector(
    selectBacklogTaskEntry,
    (state) => state.loadingStatusByContainerId
);

export const selectLoadingStatusesPerSiteId = createSelector(
    selectBacklogTaskEntry,
    (state) => state.loadingStatusBySiteId
);

export const selectLoadingStatusForCurrentSite = createSelector(
    selectLoadingStatusesPerSiteId,
    selectCurrentSiteId,
    (loadingMap, siteId) => loadingMap?.[siteId]
);

export const selectLoadingStatusForCurrentProject = createSelector(
    selectLoadingStatusesPerContainerId,
    ListContainerSelectors.selectCurrentProjectListContainer(ListContainerType.Backlog),
    (map, container) => !map[container?.id]?.loaded
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectBacklogTaskEntry);

export const selectCurrentProjectBacklogTasks = createSelector(
    selectAll,
    ListContainerSelectors.selectCurrentProjectListContainer(ListContainerType.Backlog),
    (backlogTasks, listContainer) =>
        backlogTasks.filter((c) => c.listContainerId === listContainer?.id)
);

export const selectBacklogTaskIsAdding = createSelector(
    selectBacklogTaskEntry,
    (state) => state.taskIsAdding
);

export const selectBacklogTaskPermissionsMap = createSelector(
    selectAll,
    selectAccountSiteMembership,
    selectAccountProjectRolesMap,
    ListContainerSelectors.selectAll,
    (tasks, userSiteMembership, projectRolesMap, listContainers) => {
        const map: Record<string, CrudPermissions> = {};
        const taskIsAssigned = (task: BacklogTask) =>
            task.members?.some((m) => m.siteMemberId === userSiteMembership?.id);
        tasks.forEach((task) => {
            const taskContainer = listContainers.find((c) => c.id === task.listContainerId);
            if (taskContainer?.scope?.level !== EntityLevel.Project) return;
            map[task.id] =
                task.status === BacklogTaskStatus.Backlog
                    ? getBacklogTaskPermissions(
                          projectRolesMap[taskContainer.scope.id].permissions?.agile,
                          taskIsAssigned(task)
                      )
                    : getSprintTaskPermissions(
                          projectRolesMap[taskContainer.scope.id].permissions?.agile,
                          taskIsAssigned(task)
                      );
        });
        return map;
    }
);

function getBacklogTaskPermissions(
    agilePermissions: AgilePermissions,
    taskIsAssigned: boolean
): CrudPermissions {
    return {
        create: agilePermissions?.backlogTasks?.create,
        read:
            agilePermissions?.backlogTasks?.read ||
            (taskIsAssigned && agilePermissions?.backlogTasks?.assignedRead),
        update:
            agilePermissions?.backlogTasks?.update ||
            (taskIsAssigned && agilePermissions?.backlogTasks?.assignedUpdate),
        delete:
            agilePermissions?.backlogTasks?.delete ||
            (taskIsAssigned && agilePermissions?.backlogTasks?.assignedDelete),
    };
}

function getSprintTaskPermissions(
    agilePermissions: AgilePermissions,
    taskIsAssigned: boolean
): CrudPermissions {
    return {
        create: false,
        read:
            agilePermissions?.sprintTasks?.read ||
            (taskIsAssigned && agilePermissions?.sprintTasks?.assignedRead),
        update:
            agilePermissions?.sprintTasks?.update ||
            (taskIsAssigned && agilePermissions?.sprintTasks?.assignedUpdate),
        delete:
            agilePermissions?.sprintTasks?.delete ||
            (taskIsAssigned && agilePermissions?.sprintTasks?.assignedDelete),
    };
}
