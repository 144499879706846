import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import { ApprovalState } from '../approval-request';
import { Timesheet } from './models';
import * as TimesheetActions from './timesheet.actions';
import { adapter, initialState } from './timesheet.store';

export const reducer = createReducer(
    initialState,

    on(TimesheetActions.loadTimesheetBySiteMemberId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(TimesheetActions.loadTimesheetBySiteMemberIdSuccess, (state, payload) => {
        const newState = adapter.addOne(
            payload.timesheet,
            adapter.removeOne(payload.timesheet.id, state)
        );
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(TimesheetActions.patchTimesheet, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patched = getPatched(state.entities[action.payload.id], action.payload.patch);
        const modifiedPatched: Timesheet = {
            ...patched,
            projectActivities: patched.projectActivities.map((a) => ({
                ...a,
                weekStartDate: new Date(a.weekStartDate),
            })),
            externalActivities: patched.externalActivities.map((a) => ({
                ...a,
                weekStartDate: new Date(a.weekStartDate),
            })),
        };
        return adapter.addOne(modifiedPatched, adapter.removeOne(modifiedPatched.id, state));
    }),

    on(TimesheetActions.loadTimesheetsBySiteId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(TimesheetActions.loadTimesheetsBySiteIdSuccess, (state, payload) => {
        const newState = adapter.addMany(
            payload.timesheets,
            adapter.removeMany(
                payload.timesheets.map((ts) => ts.id),
                state
            )
        );
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(TimesheetActions.patchTimesheetSuccess, (state, payload) => {
        const patched = getPatched(state.entities[payload.id], payload.patch);
        const modifiedPatched: Timesheet = {
            ...patched,
            projectActivities: patched.projectActivities.map((a) => ({
                ...a,
                weekStartDate: new Date(a.weekStartDate),
            })),
            externalActivities: patched.externalActivities.map((a) => ({
                ...a,
                weekStartDate: new Date(a.weekStartDate),
            })),
        };
        return adapter.addOne(modifiedPatched, adapter.removeOne(modifiedPatched.id, state));
    }),

    on(TimesheetActions.patchTimesheetFail, (state) => state),

    on(TimesheetActions.updateTimesheetSuccess, (state, payload) => {
        return adapter.addOne(payload.timesheet, adapter.removeOne(payload.timesheet.id, state));
    }),

    on(TimesheetActions.manageActivityApproval, (state, action) => {
        if (!action.options?.optimistic) return state;
        const timesheet = Object.values(state.entities).find((ts) =>
            ts.projectActivities?.some((a) => a.id === action.payload.activityId)
        );
        if (!timesheet) return state;
        const activityIdx = timesheet.projectActivities.findIndex(
            (a) => a.id == action.payload.activityId
        );
        const activity = timesheet.projectActivities.find((a) => a.id == action.payload.activityId);
        const update: Update<Timesheet> = {
            id: timesheet.id,
            changes: {
                projectActivities: [
                    ...timesheet.projectActivities.slice(0, activityIdx),
                    {
                        ...activity,
                        approvalState: action.payload.approvalState,
                    },
                    ...timesheet.projectActivities.slice(activityIdx + 1),
                ],
            },
        };
        return adapter.updateOne(update, state);
    }),

    on(TimesheetActions.manageActivityApprovalSuccess, (state, payload) =>
        adapter.addOne(payload.timesheet, adapter.removeOne(payload.timesheet.id, state))
    ),

    on(TimesheetActions.reSubmitTimesheetWeekActivity, (state, action) => {
        if (!action.options?.optimistic) return state;
        const timesheet = state.entities[action.payload.timesheetId];
        if (!timesheet) return state;
        const activityIdx = timesheet.projectActivities.findIndex(
            (a) => a.id == action.payload.activityId
        );
        const activity = timesheet.projectActivities.find((a) => a.id == action.payload.activityId);
        const update: Update<Timesheet> = {
            id: timesheet.id,
            changes: {
                projectActivities: [
                    ...timesheet.projectActivities.slice(0, activityIdx),
                    {
                        ...activity,
                        approvalState: ApprovalState.Pending,
                    },
                    ...timesheet.projectActivities.slice(activityIdx + 1),
                ],
            },
        };
        return adapter.updateOne(update, state);
    })
);
