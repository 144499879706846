import { NgModule } from '@angular/core';
import { SignalRGateway } from 'app/utils/network';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [SignalRGateway],
})
export class SignalRApiModule {}
