import { CalendarBase } from 'app/core/models/calendar';
import { CalendarCalculator } from 'app/core/models/calendar/calendar-calculator';
import { isAfter, isBefore, isEqual, isValid } from 'date-fns';
import {
    ScheduleChangeResult,
    TaskDiff,
    TaskId,
    TaskIdSet,
    TaskInit,
    TaskInitInternal,
    TaskPatch,
} from './schedule.types';
import { Task } from './task';
import { TaskDependency, TaskDependencyType } from './task-dependency';

export class Schedule {
    private tasks: Task[];
    private taskLookup: ScheduleTaskLookup;
    private calendarCalculator: CalendarCalculator;

    constructor(taskInits: TaskInit[], calendar: CalendarCalculator) {
        const tasks = this.buildTasks(taskInits);
        this.setTasks(tasks);
        this.calendarCalculator = calendar;
    }

    private buildTasks(taskInits: TaskInit[]): Task[] {
        const fullInits: TaskInitInternal[] = taskInits.map((init) => ({ ...init }));
        const idToInit: Record<TaskId, TaskInitInternal> = {};
        fullInits.forEach((init) => (idToInit[init.taskId] = init));
        fullInits.forEach((init) => {
            if (!init.parentTaskId) return;
            const parentTask = idToInit[init.parentTaskId];
            if (!parentTask) {
                init.parentTaskId = null;
                return;
            }
            parentTask.childTaskIds ??= [];
            parentTask.childTaskIds.push(init.taskId);
        });
        return fullInits.map((init) => new Task(init));
    }

    private setTasks(tasks: Task[]) {
        this.tasks = tasks;
        this.taskLookup = {};
        this.tasks.forEach((t) => (this.taskLookup[t.taskId] = t));
    }

    updateCalendar(calendar: CalendarBase) {
        this.calendarCalculator = new CalendarCalculator(calendar);
        this.calculate();
    }

    /**
     * Applies given list of changes to the tasks, validates and fixes dependencies,
     * then recalculates the schedule
     * @param patches List of changes that should be applied
     */
    change(patches: TaskPatch[]): ScheduleChangeResult {
        // Apply changes to each task
        patches.forEach((patch) => {
            const task = this.getTask(patch.taskId);
            // TODO: consider introducing a dedicated method in GlTask for changing
            const { start, end, duration, parentTaskId, progress } = patch.changes;

            const leafDescendants = this.getLeafDescendants(patch.taskId);

            const startJustifiedToWorkDayStart = start
                ? this.calendarCalculator.startOfWorkDay(
                      this.calendarCalculator.closestWorkDayRight(start)
                  )
                : start;

            if (startJustifiedToWorkDayStart !== void 0) {
                if (!task.isGroup) {
                    task.startDate.setChanged(startJustifiedToWorkDayStart);
                } else if (startJustifiedToWorkDayStart !== null) {
                    // Changing the start date of a group task.
                    // Clearing the start date is not allowed, so that
                    // startJustifiedToWorkDayStart cannot be null
                    if (
                        !isValid(startJustifiedToWorkDayStart) ||
                        !isValid(task.startDate.getValue())
                    ) {
                        // Cannot calculate delta, use startJustifiedToWorkDayStart to update
                        // descendants
                        leafDescendants.forEach((descendant) => {
                            descendant.startDate.setChanged(startJustifiedToWorkDayStart);
                        });
                    } else {
                        // shift all the descendants by delta
                        const workSecondsDelta = this.calendarCalculator.workSecondsBetween(
                            task.startDate.getValue(),
                            startJustifiedToWorkDayStart
                        );
                        if (workSecondsDelta != 0) {
                            leafDescendants.forEach((descendant) => {
                                const curStart = descendant.startDate.getValue();
                                const newStart =
                                    this.calendarCalculator.alignNextWorkDayIfWorkTimeEnd(
                                        this.calendarCalculator.shiftWorkSeconds(
                                            curStart,
                                            workSecondsDelta
                                        )
                                    );
                                descendant.startDate.setChanged(newStart);
                            });
                        }
                    }
                }
            }

            let endJustified: Date;
            const inputStartEndDatesAreNotEmptyAndEqual = !!start && !!end && isEqual(start, end);
            if (inputStartEndDatesAreNotEmptyAndEqual) {
                // This is the case when the task is expected to have 0-duration
                // after the change. Make sure the justified end date equals to start date.
                endJustified = startJustifiedToWorkDayStart;
            } else {
                const endJustifiedToWorkDayEnd = end
                    ? this.calendarCalculator.endOfWorkDay(
                          this.calendarCalculator.closestWorkDayRight(end)
                      )
                    : end;
                endJustified = endJustifiedToWorkDayEnd;
            }

            if (endJustified !== void 0 && !task.isGroup) {
                task.endDate.setChanged(endJustified);
            }

            if (duration !== void 0) task.duration.setChanged(duration);

            if (parentTaskId !== void 0) {
                const previousParentTaskId = task.parentTaskId.getValue();
                // remove from children of the previous parent
                if (previousParentTaskId && previousParentTaskId !== parentTaskId) {
                    const previousParentTask = this.getTask(previousParentTaskId);
                    const newChildIds = previousParentTask.childTaskIds
                        .getValue()
                        .filter((id) => id !== patch.taskId);
                    previousParentTask.childTaskIds.setChanged(newChildIds);
                }

                task.parentTaskId.setChanged(parentTaskId);

                // insert into children of the new parent
                if (parentTaskId) {
                    const newParentTask = this.getTask(parentTaskId);
                    const childIds = newParentTask.childTaskIds.getValue();
                    if (!childIds?.includes(patch.taskId)) {
                        const newChildIds = (childIds ?? []).concat(patch.taskId);
                        newParentTask.childTaskIds.setChanged(newChildIds);
                    }
                }
            }

            if (progress !== void 0) task.progress.setChanged(progress);
        });

        const result: ScheduleChangeResult = {};

        // Since dependencies rely on the tree structure, we should process them after
        // the tree structure (parent/child values) have been updated
        // Here we patch dependencies with provided values
        const depsRelatedPatches = patches.filter(
            ({ changes }) => changes.dependencies || changes.parentTaskId
        );

        depsRelatedPatches.forEach(({ taskId, changes: { dependencies, parentTaskId } }) => {
            const task = this.getTask(taskId);

            if (dependencies) {
                task.dependencies.setChanged(dependencies);
            } else {
                dependencies = task.dependencies.getValue();
            }

            const distinctDeps = this.filterDuplicateDependencies(dependencies);
            result.duplicateDependencyFound = dependencies?.length !== distinctDeps?.length;

            const validDeps = this.filterInvalidDependencies(taskId, distinctDeps);
            result.invalidDependencyFound = distinctDeps?.length !== validDeps?.length;

            if (!result.invalidDependencyFound) {
                task.dependencies.setChanged(validDeps);
            }
        });

        // Due to possible change of tree structure, we need to validate and fix dependencies
        // that might be involved
        // TODO: Probably we can optimize here by validating only a subset of tasks, but we should
        // do it carefully with unit tests. One of the tricky test cases is:
        // Initial state: Root has A and D. A has childs B, C. D has child E. B depends on C.
        // D depends on B.
        // Action: move C as a child of E.
        // Result: a circular dependency should be detected and fixed
        const depsRelatedTaskIds = depsRelatedPatches.map((p) => p.taskId);

        const validationAffectedTaskIds = this.validateAndFixDependencies(depsRelatedTaskIds);
        if (validationAffectedTaskIds.length) result.invalidDependencyFound = true;

        this.calculate();

        return result;
    }

    /**
     * Removes the given tasks along with their descendants, validates and fixes dependencies,
     * then recalculates the schedule
     * @param taskIds List of task physical rows to remove
     */
    removeTasks(taskIds: TaskId[]): string[] {
        const taskIdsToRemove: TaskIdSet = {};
        taskIds.forEach((taskId) => {
            taskIdsToRemove[taskId] = true;
            const descendantIds = this.getDescendantTaskIds(taskId);
            descendantIds.forEach((id) => (taskIdsToRemove[id] = true));
        });

        const updatedTaskList = this.tasks.filter(({ taskId }) => !taskIdsToRemove[taskId]);

        // FIXME: not sure this is a correct way of fixing child lists
        updatedTaskList.forEach((task) => {
            const currentChildTaskIds = task.childTaskIds.getValue();
            if (!currentChildTaskIds?.length) {
                return;
            }
            const newChildTaskIds = currentChildTaskIds.filter((id) => !taskIdsToRemove[id]);
            const childListChanged = currentChildTaskIds.length !== newChildTaskIds.length;
            if (childListChanged) {
                task.childTaskIds.setChanged(newChildTaskIds);
            }
        });

        this.setTasks(updatedTaskList);

        // The method might return the affected task IDs whose dependencies
        // were fixed (modified). We ignore the return value since having
        // the fixed task dependencies is OK in our case due to the removed rows.
        this.validateAndFixDependencies();

        this.calculate();

        const removedTaskIdsIncludingDescendants = Object.keys(taskIdsToRemove);
        return removedTaskIdsIncludingDescendants;
    }

    // TODO: cover with unit tests
    moveTasks(taskIds: TaskId[], targetParentId: TaskId | null): ScheduleChangeResult {
        // ignore descendants in the selection because they follow their parents
        const taskIdsWithoutDescendants = this.excludeDescendants(taskIds);

        const targetParentTask = targetParentId != null ? this.getTask(targetParentId) : null;

        const targetParentTaskAncestorIds = targetParentId
            ? this.getAncestorTaskIds(targetParentId)
            : null;

        for (const taskId of taskIdsWithoutDescendants) {
            const task = this.getTask(taskId);
            const currentParentTaskId = task.parentTaskId.hasValue
                ? task.parentTaskId.getValue()
                : null;

            const alreadyBelongsToTargetParent =
                (currentParentTaskId == null && targetParentId == null) ||
                currentParentTaskId === targetParentId;
            if (alreadyBelongsToTargetParent) {
                continue;
            }

            const movingTaskIntoItself = taskId === targetParentId;
            const movingTaskIntoItsOwnDescendants = targetParentTaskAncestorIds?.includes(taskId);
            if (movingTaskIntoItself || movingTaskIntoItsOwnDescendants) {
                return { invalidDependencyFound: true };
            }

            // Remove the task from its current parent's children
            const currentParentTask = currentParentTaskId
                ? this.getTask(currentParentTaskId)
                : null;
            if (currentParentTask) {
                currentParentTask.childTaskIds.setChanged(
                    currentParentTask.childTaskIds.getValue()?.filter((id) => id !== taskId)
                );
            }

            // Add the task to its target parent's children
            if (targetParentTask) {
                targetParentTask.childTaskIds.setChanged([
                    ...(targetParentTask.childTaskIds.getValue() ?? []),
                    taskId,
                ]);
            }

            // Update task's parent link
            task.parentTaskId.setChanged(targetParentId);
        }

        this.setTasks(this.tasks);

        const result: ScheduleChangeResult = {};

        const affectedTaskIds = this.validateAndFixDependencies();
        if (affectedTaskIds.length) {
            result.invalidDependencyFound = true;
        }

        this.calculate();

        return result;
    }

    // TODO: cover with unit tests
    addTasks(taskInitList: TaskInit[], targetParentId: TaskId | null): ScheduleChangeResult {
        // Added tasks should have all properties to be marked as changed,
        // so that the diff is extractable when getting schedule changes.
        const tasks = taskInitList.map((init) => this.createTaskWithAllPropsAsChanged(init));
        const targetParentTask = targetParentId != null ? this.getTask(targetParentId) : null;

        // Add tasks to their target parent's children
        if (targetParentTask) {
            targetParentTask.childTaskIds.setChanged([
                ...(targetParentTask.childTaskIds.getValue() ?? []),
                ...tasks.map((t) => t.taskId),
            ]);
        }

        tasks.forEach((t) => t.parentTaskId.setChanged(targetParentId));

        const newTaskList = [...this.tasks, ...tasks];
        this.setTasks(newTaskList);

        const result: ScheduleChangeResult = {};

        const affectedTaskIds = this.validateAndFixDependencies();
        if (affectedTaskIds.length) {
            result.invalidDependencyFound = true;
        }

        this.calculate();

        return result;
    }

    private createTaskWithAllPropsAsChanged(init: TaskInitInternal): Task {
        const { taskId, ...taskPropsWithoutTaskId } = init;
        const task = new Task({ taskId });
        task.change(taskPropsWithoutTaskId);
        return task;
    }

    calculate() {
        if (!this.tasks || !this.tasks.length) return;

        this.tasks.forEach((task) => {
            task.clearCalculations();
            task.clearTargetDates();
        });

        const calculatedTasks: ScheduleTaskLookup = {};
        this.tasks.forEach((task) => this.calculateTaskRecursively(task.taskId, calculatedTasks));
    }

    getChanges(): TaskDiff[] {
        return this.tasks.map((task) => task.getDiff()).filter((diff) => diff != null);
    }

    getTask(taskId: string): Task {
        return this.taskLookup[taskId];
    }

    getAllTasks(): Task[] {
        return [...this.tasks];
    }

    getChildTasks(parentTaskId: TaskId | null): Task[] {
        if (parentTaskId == null) {
            return this.tasks.filter((t) => t.parentTaskId.getValue() == null);
        }
        return this.tasks.filter((t) => t.parentTaskId.getValue() === parentTaskId);
    }

    /**
     * Returns the set of physicalRows of tasks that are on the critical path
     *
     * @param task Task inside of which the critical path should be calculated. If omitted
     * the critical path will be calculated fot the whole schedule
     */
    getCriticalPath(task?: Task, processedIds = new Set<string>()): TaskIdSet {
        if (task && !task.isGroup) return {};

        if (task) {
            if (processedIds.has(task.taskId)) return {};
            processedIds.add(task.taskId);
        }

        const isRootLevel = !task;
        const childTasks = isRootLevel
            ? this.tasks.filter((task) => !task.parentTaskId.getValue())
            : task.childTaskIds.getValue()?.map((id) => this.getTask(id));

        const childTasksWithEndDate = childTasks.filter((child) => !child.endDate.isEmpty);

        let deadline: Date;
        childTasksWithEndDate
            .map((child) => child.endDate.getValue())
            .forEach((endDate) => {
                if (!deadline || isAfter(endDate, deadline)) {
                    deadline = endDate;
                }
            });

        if (!deadline || !childTasksWithEndDate?.length) return {};

        const criticalPath: TaskIdSet = {};
        const childTasksEndingOnDeadline = childTasksWithEndDate.filter((child) =>
            isEqual(child.endDate.getValue(), deadline)
        );
        const criticalPathDepTypes = [
            TaskDependencyType.FS,
            TaskDependencyType.SS,
            TaskDependencyType.FF,
        ];

        childTasksEndingOnDeadline.forEach((child) => {
            if (child.isLeaf) {
                criticalPath[child.taskId] = true;
            } else {
                const subPath = this.getCriticalPath(child, processedIds);
                Object.assign(criticalPath, subPath);
            }
            const deps = this.getAllDependencies(child);
            const criticalPathDeps = deps?.filter((dep) => criticalPathDepTypes.includes(dep.type));

            criticalPathDeps?.forEach((dep) => {
                const referredTask = this.getTask(dep.taskId);
                if (referredTask.isLeaf) {
                    criticalPath[dep.taskId] = true;
                } else {
                    const subPath = this.getCriticalPath(referredTask, processedIds);
                    Object.assign(criticalPath, subPath);
                }
            });
        });

        return criticalPath;
    }

    /**
     * Returns two sets of taskIds that break project baseline.
     * `breaksStart` - task IDs breasing the start date
     * `breaksEnd` - task IDs breading the end date
     */
    getBaselineBreakingTaskIds(baseline: { startDate: Date; endDate: Date }): {
        startBreakers: TaskId[];
        endBreakers: TaskId[];
    } {
        const startBreakers: TaskId[] = [];
        const endBreakers: TaskId[] = [];

        this.tasks.forEach((task) => {
            const breaksBaselineStart =
                baseline.startDate &&
                !task.startDate.isEmpty &&
                isBefore(task.startDate.getValue(), baseline.startDate);

            if (breaksBaselineStart) {
                startBreakers.push(task.taskId);
            }

            const breaksBaselineEnd =
                baseline.endDate &&
                !task.endDate.isEmpty &&
                isAfter(task.endDate.getValue(), baseline.endDate);

            if (breaksBaselineEnd) {
                endBreakers.push(task.taskId);
            }
        });

        return { startBreakers, endBreakers };
    }

    /**
     * Validates and removes invalid dependencies for the given tasks. If no physical rows provided,
     * it validates all the tasks
     * @param rowsToValidate List of physical row which dependencies should be validated
     * @returns Affected physical rows which dependencies were invalid
     */
    private validateAndFixDependencies(taskIds?: Array<string>): Array<string> {
        const taskIdsToValidate = taskIds || this.tasks.map((t) => t.taskId);
        const affectedTaskIds = [];

        taskIdsToValidate.forEach((taskId) => {
            const task = this.getTask(taskId);
            if (!task.dependencies.getValue()?.length) return;

            const currentDeps = task.dependencies.getValue();
            const validDeps = this.filterInvalidDependencies(taskId, currentDeps);
            const invalidFound = currentDeps?.length !== validDeps?.length;

            if (invalidFound) {
                affectedTaskIds.push(taskId);
                task.dependencies.setChanged(validDeps);
            }
        });

        return affectedTaskIds;
    }

    private filterDuplicateDependencies(
        dependencies: TaskDependency[] | null
    ): TaskDependency[] | null {
        return dependencies?.filter(
            (d1, index) => dependencies.findIndex((d2) => d2.taskId === d1.taskId) === index
        );
    }

    private filterInvalidDependencies(
        taskId: string,
        dependencies: TaskDependency[] | null
    ): TaskDependency[] | null {
        return dependencies?.filter((dep) => this.isDependencyValid(taskId, dep));
    }

    private isDependencyValid(taskId: string, dependency: TaskDependency): boolean {
        // terms: if A depends on B, A is dependent task, B is reference task

        const refersToItself = dependency.taskId === taskId;
        if (refersToItself) return false;

        const refersToNonExistingTask = this.getTask(dependency.taskId) == null;
        if (refersToNonExistingTask) return false;

        const descendants = this.getDescendantTaskIds(taskId);
        const refersToDescendant = descendants.includes(dependency.taskId);
        if (refersToDescendant) return false;

        const hasCycle = this.hasDependencyCycle(dependency.taskId, { [taskId]: true });
        if (hasCycle) return false;

        return true;
    }

    private hasDependencyCycle(taskId: string, dependants: TaskIdSet): boolean {
        const cycleFound = dependants[taskId];
        if (cycleFound) return true;

        const task = this.getTask(taskId);
        if (!task) return false;

        const explicitDependencies = this.getOwnAndAncestorsDependencies(task).map(
            (dep) => dep.taskId
        );
        const implicitDependencies = this.getDescendantTaskIds(taskId);
        const allDependencies = [...explicitDependencies, ...implicitDependencies];
        return allDependencies.some((dep) =>
            this.hasDependencyCycle(dep, { ...dependants, [task.taskId]: true })
        );
    }

    private calculateTaskRecursively(
        taskId: TaskId,
        calculated: ScheduleTaskLookup,
        dependants: ScheduleTaskLookup = {}
    ) {
        const task = this.getTask(taskId);

        const taskDependsOnItself = !!dependants[task.taskId];
        if (taskDependsOnItself) {
            console.warn('Schedule circular dependency found', task.taskId, dependants);
            calculated[task.taskId] = task;
            return;
        }

        const taskHasAlreadyBeenCalculated = !!calculated[task.taskId];
        if (taskHasAlreadyBeenCalculated) return;

        // calculate group task
        if (task.isGroup) {
            task.childTaskIds.getValue().forEach((childTaskId) =>
                this.calculateTaskRecursively(childTaskId, calculated, {
                    ...dependants,
                    [task.taskId]: task,
                })
            );
            const startDates = task.childTaskIds
                .getValue()
                .map((childId) => this.getTask(childId).startDate.getValue())
                .filter((date) => date);
            const minStartDate = startDates.reduce(
                (min, cur) => (!min || isBefore(cur, min) ? cur : min),
                null
            );
            task.setTargetStart(minStartDate);

            const endDates = task.childTaskIds
                .getValue()
                .map((childId) => this.getTask(childId).endDate.getValue())
                .filter((date) => date);
            const maxEndDate = endDates.reduce(
                (max, cur) => (!max || isAfter(cur, max) ? cur : max),
                null
            );
            task.setTargetEnd(maxEndDate);

            const leafDescendants = this.getLeafDescendants(task.taskId);
            let totalDuration = 0;
            let totalDurationDone = 0;
            let incompleteTaskExists = false;
            leafDescendants.forEach((descendant) => {
                const duration = descendant.duration.getValue() ?? 0;
                totalDuration += duration;
                const progress = descendant.progress.getValue() ?? 0;
                totalDurationDone += (duration * progress) / 100;
                incompleteTaskExists ||= duration > 0 && progress < 100;
            });
            const roundedProgress =
                totalDuration === 0 ? 0 : Math.round((totalDurationDone / totalDuration) * 100);
            const progress = incompleteTaskExists && roundedProgress === 100 ? 99 : roundedProgress;
            if (task.progress.getValue() !== progress) {
                task.progress.setChanged(progress);
            }
        } else {
            // calculate leaf task
            const explicitDependencies = this.getOwnAndAncestorsDependencies(task);
            if (explicitDependencies.length) {
                // calculate own and parent's predecessors
                explicitDependencies.forEach((dep) =>
                    this.calculateTaskRecursively(dep.taskId, calculated, {
                        ...dependants,
                        [task.taskId]: task,
                    })
                );

                // calculate target dates based on predecessors
                let targetStartDate: Date;
                let targetEndDate: Date;

                for (const dep of explicitDependencies) {
                    const predecessor = this.getTask(dep.taskId);
                    const lag = dep.lag != null ? +dep.lag : 0;
                    let startCandidate: Date;
                    let endCandidate: Date;

                    const startToStartEnabled = dep.type === TaskDependencyType.SS;
                    const finishToStartEnabled = dep.type === TaskDependencyType.FS;
                    const startToFinishEnabled = dep.type === TaskDependencyType.SF;
                    const finishToFinishEnabled = dep.type === TaskDependencyType.FF;

                    const requiredStartDateIsMissing =
                        (startToStartEnabled || startToFinishEnabled) &&
                        predecessor.startDate.isEmpty;
                    const requiredEndDateIsMissing =
                        (finishToStartEnabled || finishToFinishEnabled) &&
                        predecessor.endDate.isEmpty;

                    if (requiredStartDateIsMissing || requiredEndDateIsMissing) {
                        targetStartDate = null;
                        targetEndDate = null;
                        break;
                    }

                    if (startToStartEnabled) {
                        startCandidate = this.calendarCalculator.alignNextWorkDayIfWorkTimeEnd(
                            this.calendarCalculator.shiftWorkSeconds(
                                predecessor.startDate.getValue(),
                                lag
                            )
                        );
                    } else if (finishToStartEnabled) {
                        startCandidate = this.calendarCalculator.alignNextWorkDayIfWorkTimeEnd(
                            this.calendarCalculator.shiftWorkSeconds(
                                predecessor.endDate.getValue(),
                                lag
                            )
                        );
                    } else if (startToFinishEnabled) {
                        endCandidate = this.calendarCalculator.alignPrevWorkDayIfWorkTimeStart(
                            this.calendarCalculator.shiftWorkSeconds(
                                predecessor.startDate.getValue(),
                                lag
                            )
                        );
                    } else if (finishToFinishEnabled) {
                        endCandidate = this.calendarCalculator.alignPrevWorkDayIfWorkTimeStart(
                            this.calendarCalculator.shiftWorkSeconds(
                                predecessor.endDate.getValue(),
                                lag
                            )
                        );
                    }

                    if (!targetStartDate || isBefore(targetStartDate, startCandidate)) {
                        targetStartDate = startCandidate;
                    }

                    if (!targetEndDate || isAfter(targetEndDate, endCandidate)) {
                        targetEndDate = endCandidate;
                    }
                }

                if (targetStartDate !== void 0) task.setTargetStart(targetStartDate);
                if (targetEndDate !== void 0) task.setTargetEnd(targetEndDate);
            }
        }

        task.calculate(this.calendarCalculator);
        calculated[task.taskId] = task;
    }

    /**
     * Returns dependencies that include explicit dependencies of the task and explicit dependencies
     * of all its ancestors. The result list do not include implicit dependencies (parent dependency
     * on its children). The result may have duplicates.
     */
    private getOwnAndAncestorsDependencies(task: Task, depthLevel = 0): TaskDependency[] {
        if (depthLevel > this.tasks.length) {
            throw new Error('Parent-child dependency loop is detected.');
        }
        const parentTaskId = task.parentTaskId.getValue();
        if (parentTaskId == null) return [...(task.dependencies.getValue() ?? [])];
        const parentTask = this.getTask(parentTaskId);
        return [
            ...(task.dependencies.getValue() ?? []),
            ...this.getOwnAndAncestorsDependencies(parentTask, depthLevel + 1),
        ];
    }

    /**
     * Returns task's explicit dependencies and recursively all dependencies of dependencies.
     * The result may have duplicates.
     */
    private getAllDependencies(task: Task): TaskDependency[] {
        const allDependencies: TaskDependency[] = [];
        const ownAndAncestorDependencies = this.getOwnAndAncestorsDependencies(task);
        allDependencies.push(...ownAndAncestorDependencies);
        ownAndAncestorDependencies.forEach((dep) => {
            const depTask = this.getTask(dep.taskId);
            allDependencies.push(...this.getAllDependencies(depTask));
        });
        return allDependencies;
    }

    /**
     * Exclude all the task IDs whose ancestor is already in the list
     */
    private excludeDescendants(taskIds: TaskId[]): TaskId[] {
        return taskIds.filter((taskId) => {
            const ancestorTaskIds = this.getAncestorTaskIds(taskId);
            const ancestorIncluded = ancestorTaskIds.some((ancestorId) =>
                taskIds.includes(ancestorId)
            );
            return !ancestorIncluded;
        });
    }

    /**
     * Get all the descendant task IDs for a given task
     */
    private getDescendantTaskIds(taskId: TaskId): TaskId[] {
        const task = this.getTask(taskId);
        if (!task) return [];
        const childTaskIds = task.childTaskIds.getValue() ?? [];
        return [
            ...childTaskIds,
            ...[].concat(...childTaskIds.map((id) => this.getDescendantTaskIds(id))),
        ];
    }

    /**
     * Get all descendant tasks that are not group tasks
     */
    private getLeafDescendants(taskId: TaskId): Task[] {
        return this.getDescendantTaskIds(taskId)
            .map((id) => this.getTask(id))
            .filter((t) => !t.isGroup);
    }

    /**
     * Extend the task ID list with their descentant Task IDs,
     * if they were not included yet
     */
    extendWithDescendants(taskIds: TaskId[]): Set<TaskId> {
        const result = new Set<TaskId>();
        taskIds.forEach((taskId) => {
            result.add(taskId);
            this.getDescendantTaskIds(taskId).forEach((id) => result.add(id));
        });
        return result;
    }

    getOrderedTaskIds(): TaskId[] {
        return this.traverseTasksInOrder((task) => task.taskId);
    }

    private traverseTasksInOrder<T>(
        callbackFn: (n: Task) => T,
        containerTask: Task | null = null
    ): T[] {
        const isRootLevel = containerTask == null;
        const result: T[] = [];
        if (!isRootLevel) {
            result.push(callbackFn(containerTask));
        }
        const childrenTasks = this.getChildTasks(containerTask?.taskId);
        childrenTasks.forEach((ch) => result.push(...this.traverseTasksInOrder(callbackFn, ch)));
        return result;
    }

    private getAncestorTaskIds(taskId: TaskId): TaskId[] {
        const ancestorTaskIds: TaskId[] = [];
        let currentTaskId = taskId;
        let parentTaskId = this.getTask(currentTaskId).parentTaskId.getValue();
        let errorDetected = false;

        while (parentTaskId != null && !errorDetected) {
            ancestorTaskIds.push(parentTaskId);

            currentTaskId = parentTaskId;
            parentTaskId = this.getTask(currentTaskId).parentTaskId.getValue();
            errorDetected = ancestorTaskIds.length >= this.tasks.length;
        }

        if (errorDetected) {
            throw new Error('Possible cyclic dependencies exist');
        }

        return ancestorTaskIds;
    }
}

interface ScheduleTaskLookup {
    [taskId: number]: Task;
}
