import { Calendar, isWorkingDate } from 'app/core/models/calendar';
import { CtxColor, ctxColorToHexMap } from 'app/core/models/shared-models';
import { dateIsBetweenOrEqualDates } from 'app/date-format';
import { JsonProperty } from 'app/utils/json-mapper';
import { Model } from '../../shared/models/base.model';
import { SiteAllocationColouring } from '../../site-settings';

export class ProjectMemberAllocation extends Model {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('projectMemberId')
    projectMemberId: string = undefined;

    @JsonProperty('start')
    start: Date = new Date();

    @JsonProperty('end')
    end: Date = new Date();

    @JsonProperty('percentValue')
    percentValue: number = undefined;

    @JsonProperty('siteMemberId')
    siteMemberId?: string = undefined;

    @JsonProperty('isManual')
    isManual?: boolean = undefined;

    constructor(init?: Partial<ProjectMemberAllocation>) {
        super();
        Object.assign(this, init);
    }
}

export function isCurrentAllocation(
    allocation: ProjectMemberAllocation,
    calendar: Calendar
): boolean {
    return (
        dateIsBetweenOrEqualDates(
            new Date(),
            new Date(allocation.start),
            new Date(allocation.end)
        ) && isWorkingDate(calendar, new Date())
    );
}

export class ExtendedProjectMemberAllocation extends ProjectMemberAllocation {
    projectId: string;

    constructor(init?: Partial<ExtendedProjectMemberAllocation>) {
        super();
        Object.assign(this, init);
    }
}

export function getAllocationColor(
    percent: number,
    allocationColouring: SiteAllocationColouring
): string {
    if (!allocationColouring) return 'white';
    let ctxColor: CtxColor = allocationColouring.low.color;
    const configuredValue = percent ?? 0;

    if (configuredValue == 0) {
        ctxColor = allocationColouring?.zero?.color ?? CtxColor.Default;
    }

    if (
        configuredValue > allocationColouring.medium.value &&
        configuredValue <= allocationColouring.high.value
    ) {
        ctxColor = allocationColouring.medium.color;
    }

    if (configuredValue > allocationColouring.high.value) {
        ctxColor = allocationColouring.high.color;
    }

    return ctxColorToHexMap[ctxColor];
}
