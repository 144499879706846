import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppStore } from 'app/core/store';
import { AccountSelectors } from 'app/core/store/account';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Theme } from '../theme';

@Injectable()
export class SharedService {
    CurrentRouteDescription: ReplaySubject<string> = new ReplaySubject(1);
    theme$ = this.store
        .select(AccountSelectors.selectUserAccount)
        .pipe(map((account) => (account?.preferences?.theme as Theme) || Theme.Default));

    constructor(private store: Store<AppStore.AppState>) {}
}
