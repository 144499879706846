import { IdPayload } from 'app/core/store/shared/models/shared-payloads';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import { PatchProjectFieldPayload } from '../../../../store/project-field/project-field.payloads';
import { LoadBySiteIdPayload } from './../../../../store/shared/models/load.payload';

export const loadProjectFieldsBySiteId = (
    payload: LoadBySiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'projectFields' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadProjectFieldsByCompletixSettingsTemplate = (
    payload: IdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'projectFields/completixSettingsTemplates', value: payload.id },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const patchProjectField = (
    payload: PatchProjectFieldPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'projectFields', value: payload.id }]);
    baseCommand.payload.setData(payload.patch);
    baseCommand.method = RestfulMethod.PATCH;
    return baseCommand;
};
