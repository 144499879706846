import { Reminder } from '../../reminder';
import { ScheduleTask, ScheduleTaskExtended } from '../models';

export function extendScheduleTasks({
    allTasks,
    idToReminderMap,
    idsRefferedByComments,
    idsReferredByDocs,
}: {
    allTasks: ScheduleTask[];
    idToReminderMap: Map<string, Reminder>;
    idsRefferedByComments: Set<string>;
    idsReferredByDocs: Set<string>;
}): ScheduleTaskExtended[] {
    const parentTaskIds = identifyParentTaskIds(allTasks);

    return allTasks.map((task) =>
        extendScheduleTask({
            task,
            idToReminderMap,
            idsRefferedByComments,
            idsReferredByDocs,
            parentTaskIds,
        })
    );
}

function identifyParentTaskIds(allTasks: ScheduleTask[]): Set<string> {
    const parentIds = new Set<string>();
    allTasks.forEach((task) => {
        if (task.parentId) parentIds.add(task.parentId);
    });
    return parentIds;
}

function extendScheduleTask({
    task,
    idToReminderMap,
    idsRefferedByComments,
    idsReferredByDocs,
    parentTaskIds,
}: {
    task: ScheduleTask;
    idToReminderMap: Map<string, Reminder>;
    idsRefferedByComments: Set<string>;
    idsReferredByDocs: Set<string>;
    parentTaskIds: Set<string>;
}): ScheduleTaskExtended {
    return {
        ...task,
        reminderDate: idToReminderMap.get(task.id)?.date,
        hasReminder: idToReminderMap.has(task.id),
        hasComments: idsRefferedByComments.has(task.id),
        hasDocuments: idsReferredByDocs.has(task.id),
        hasChildren: parentTaskIds.has(task.id),
    };
}
