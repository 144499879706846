import { LoadBySiteIdPayload } from 'app/core/store/shared/models/load.payload';
import { IdPayload } from 'app/core/store/shared/models/shared-payloads';
import { SiteSettingsPayloads } from 'app/core/store/site-settings';
import { SiteIdPayload } from 'app/core/store/site/site.payloads';
import { RestfulCommand, RestfulMethod } from 'app/utils/network';
import { SitePayloads } from '../../../../store/site';
import { ChangeSiteOwnerPayload } from './../../../../store/site/site.payloads';

export const loadSites = (payload: undefined, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'sites' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadInitialSite = (payload: undefined, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'Sites/InitialSite' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadRolesBySite = (payload: LoadBySiteIdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'roles' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadSiteMetrics = (payload: LoadBySiteIdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'siteMetrics' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const getSiteById = (payload: SitePayloads.SiteIdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const addSite = (payload: SitePayloads.AddSitePayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'sites' }]);
    baseCommand.method = RestfulMethod.POST;
    baseCommand.payload.setData(payload.createOptions);
    return baseCommand;
};

export const patchSite = (payload: SitePayloads.PatchSitePayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }]);
    baseCommand.method = RestfulMethod.PATCH;
    baseCommand.payload.setData(payload.patch);
    return baseCommand;
};

export const deleteSite = (payload: SitePayloads.SiteIdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }]);
    baseCommand.method = RestfulMethod.DELETE;
    return baseCommand;
};

export const getProjectsBySite = (
    payload: SitePayloads.SiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'projects' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const getSubscriptionPlans = (
    payload: SitePayloads.SiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'subscriptionPlans' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const getSubscriptionPlanById = (
    payload: SitePayloads.GetSubscriptionPlanByIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'subscriptionPlans', value: payload.subscriptionPlanId },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const requestChangeSiteOwner = (
    payload: ChangeSiteOwnerPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'RequestChangeOwner', value: payload.newOwnerSiteMemberId },
    ]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const cancelRequestChangeSiteOwner = (
    payload: SiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'cancelRequestChangeOwner' },
    ]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const acceptRequestChangeSiteOwner = (
    payload: SiteIdPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'AcceptRequestChangeOwner' },
    ]);
    baseCommand.method = RestfulMethod.POST;
    return baseCommand;
};

export const isUserLimitReached = (payload: SiteIdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([
        { name: 'sites', value: payload.siteId },
        { name: 'IsUserLimitReached' },
    ]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadSiteSettings = (payload: SiteIdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'sites', value: payload.siteId }, { name: 'settings' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const loadSiteSettingsCompletixTemplates = (payload: any, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'sites/settings/completixTemplates' }]);
    baseCommand.method = RestfulMethod.GET;
    return baseCommand;
};

export const patchSiteSettings = (
    payload: SiteSettingsPayloads.PatchSiteSettingsPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'sites' }, { name: 'settings', value: payload.id }]);
    baseCommand.method = RestfulMethod.PATCH;
    baseCommand.payload.setData(payload.patch);
    return baseCommand;
};

export const addSiteSettingsTemplate = (
    payload: SiteSettingsPayloads.AddSiteSettingPayload,
    baseCommand: RestfulCommand
) => {
    baseCommand.setResource([{ name: 'sites/settings/completixTemplates' }]);
    baseCommand.method = RestfulMethod.POST;
    baseCommand.payload.setData(payload);
    return baseCommand;
};

export const deleteSiteSettingsTemplate = (payload: IdPayload, baseCommand: RestfulCommand) => {
    baseCommand.setResource([{ name: 'sites/settings/completixTemplates', value: payload.id }]);
    baseCommand.method = RestfulMethod.DELETE;
    baseCommand.payload.setData(payload);
    return baseCommand;
};
