import { JsonProperty } from 'app/utils/json-mapper';

export class InvoiceTax {
    @JsonProperty('label')
    label: string = undefined;

    @JsonProperty('rate')
    rate: number = undefined;

    @JsonProperty('country')
    country: string = undefined;

    @JsonProperty('amountCents')
    amountCents: number = undefined;
}
