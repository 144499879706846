export enum Theme {
    Default = 'ctx-theme-default',
    Light = 'ctx-theme-light',
    Dark = 'ctx-theme-dark',
}

export const themeList = [
    { value: Theme.Default, label: 'Default' },
    { value: Theme.Light, label: 'Light' },
    { value: Theme.Dark, label: 'Dark' },
];
