import { CtxColor, ProjectBudgetBase } from 'app/core/models/shared-models';
import { ProjectSettings } from 'app/core/store/project';
import { ProjectBudgetReportProp } from 'app/features/project/status-page/project-status-budget/project-status-budget.component';
import { ProjectScheduleReportProp } from 'app/features/project/status-page/project-status-schedule/project-status-schedule.component';
import { TimelineTask } from 'app/shared-ui/dumb-components/timeline/timeline.component';
import { JsonProperty } from 'app/utils/json-mapper';
import { GlTableSettings } from '../../gl-table-settings';
import { IssueRecord } from '../../issue';
import { OverallStatus } from '../../program';
import { ProjectFieldItem } from '../../project-field';
import { Project, ProjectBudgetEntity } from '../../project/project.model';
import { RiskRecord } from '../../risks';
import { getProjectScheduleTaskColor, ScheduleTask } from '../../schedule-template';
import { getScheduleTaskOrderedIds } from '../../schedule-template/schedule-task.selectors';
import { Model } from '../../shared/models/base.model';
import { LowMediumHighStatus } from '../../shared/models/raid-shared.model';

export class ProjectStatusReportBudget implements ProjectBudgetBase {
    hidden?: boolean;
    approvedBudget: number;
    approvedCurrentFY: number;
    plannedCost: OverridableValue<ProjectBudgetEntity>;
    actualToDate: OverridableValue<ProjectBudgetEntity>;
    currentFYForecast: OverridableValue<ProjectBudgetEntity>;
    currentFYActuals: OverridableValue<ProjectBudgetEntity>;
}

export class ProjectStatusReport extends Model {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('projectId')
    projectId: string = undefined;

    @JsonProperty('name')
    name: string = undefined;

    @JsonProperty('summary')
    summary?: string = undefined;

    @JsonProperty('planned')
    planned?: string = undefined;

    @JsonProperty('health')
    health?: ProjectHealthReport = undefined;

    @JsonProperty('budget')
    budget?: ProjectStatusReportBudget = undefined;

    @JsonProperty('schedule')
    schedule?: ProjectScheduleReport = undefined;

    @JsonProperty('timeline')
    timeline?: ProjectTimelineReport = undefined;

    @JsonProperty('majorMilestones')
    majorMilestones: ProjectMajorMilestone[] = undefined;

    @JsonProperty('topRisks')
    topRisks: ProjectStatusTopRaidRecord[] = undefined;

    @JsonProperty('topIssues')
    topIssues: ProjectStatusTopRaidRecord[] = undefined;

    @JsonProperty('statusReportSides')
    statusReportSides: ProjectStatusReportSides = defaultStatusReportSides;

    @JsonProperty('hiddenEntity')
    hiddenEntity: ProjectStatusReportHiddenEntity = undefined;

    @JsonProperty('date')
    date: Date;

    @JsonProperty('isLive')
    isLive?: boolean;

    constructor(init?: Partial<ProjectStatusReport>) {
        super();
        Object.assign(this, init);
    }
}

export interface OverridableValue<T> {
    overrided?: boolean;
    value: T;
}

export interface ProjectStatusTopRaidRecord {
    projectId: string;
    projectName: string;
    projectColor: CtxColor;
    description: string;
    status: ProjectFieldItem;
    impact: LowMediumHighStatus;
}

export interface ProjectMajorMilestone {
    id: string;
    projectId?: string;
    projectName?: string;
    projectColor?: CtxColor;
    name: string;
    date: Date;
    status: OverallStatus;
    scheduleTaskId?: string;
    isManual?: boolean;
}

export interface ProjectHealthReport {
    overall: OverallStatus;
    scope: OverallStatus;
    schedule: OverallStatus;
    budget: OverallStatus;
    stepsToGreen: string;
}

export interface BudgetRow {
    total: number;
    capex: number;
    opex: number;
}

export interface ProjectScheduleReport {
    currentGate: string;
    activeSprints: string[];
    progress: OverridableValue<number>;
    scheduledEndDate: OverridableValue<Date>;
    plannedEndDate: OverridableValue<Date>;
}

export interface ProjectTimelineReport {
    hidden?: boolean;
    tasks?: TimelineTask[];
    taskOrderedIds?: string[];
}

export interface ProjectStatusReportHiddenEntity {
    hiddenEntities?: ProjectStatusReportEntity[];
    hiddenBudgetProps?: ProjectBudgetReportProp[];
    hiddenScheduleProps?: ProjectScheduleReportProp[];
}

export interface ProjectStatusReportSides {
    left: ProjectStatusReportEntity[];
    right: ProjectStatusReportEntity[];
}

export enum ProjectStatusReportEntity {
    StatusSummary = 'statusSummary',
    PlannedForNextPeriod = 'plannedForNextPeriod',
    Top5Issues = 'topIssues',
    Top5Risks = 'topRisks',
    Health = 'health',
    Schedule = 'schedule',
    Budget = 'budget',
    MajorMilestones = 'majorMilestones',
    Timeline = 'timeline',
}

export const defaultStatusReportSides: ProjectStatusReportSides = {
    left: [
        ProjectStatusReportEntity.StatusSummary,
        ProjectStatusReportEntity.PlannedForNextPeriod,
        ProjectStatusReportEntity.Top5Issues,
        ProjectStatusReportEntity.Top5Risks,
    ],
    right: [
        ProjectStatusReportEntity.Health,
        ProjectStatusReportEntity.Schedule,
        ProjectStatusReportEntity.Budget,
        ProjectStatusReportEntity.MajorMilestones,
    ],
};

export function getLiveProjectStatusReport(
    project: Project,
    projectSettings: ProjectSettings,
    currentGate: string,
    activeSprints: string[],
    risks: RiskRecord[],
    riskStatuses: ProjectFieldItem[],
    issues: IssueRecord[],
    issueStatuses: ProjectFieldItem[],
    scheduleTasks: ScheduleTask[],
    scheduleGlTableSettings: GlTableSettings
): ProjectStatusReport {
    const defaultBudgetEntity: ProjectBudgetEntity = { capex: 0, opex: 0, total: 0 };
    return new ProjectStatusReport({
        projectId: project.id,
        name: 'Live Report',
        summary: '',
        planned: '',
        health: {
            overall: OverallStatus.Green,
            scope: OverallStatus.Green,
            schedule: OverallStatus.Green,
            budget: OverallStatus.Green,
            stepsToGreen: '',
        },
        budget: {
            hidden: false,
            approvedBudget: project.budget?.approvedBudget,
            approvedCurrentFY: project.budget?.approvedCurrentFY,
            plannedCost: { value: project.budget?.plannedCost ?? defaultBudgetEntity },
            actualToDate: { value: project.budget?.actualToDate ?? defaultBudgetEntity },
            currentFYActuals: {
                value: project.budget?.currentFYActuals ?? defaultBudgetEntity,
            },
            currentFYForecast: {
                value: project.budget?.currentFYForecast ?? defaultBudgetEntity,
            },
        },
        schedule: {
            activeSprints,
            currentGate,
            progress: { value: getScheduleProgress(scheduleTasks) },
            scheduledEndDate: { value: project.calculatedScheduledEndDate },
            plannedEndDate: { value: project.endDate },
        },
        majorMilestones: [],
        timeline: {
            hidden: true,
            tasks: scheduleTasks
                .filter((t) => t.isTimeline && t.startDate && t.endDate)
                .map(
                    (t) =>
                        ({
                            id: t.id,
                            name: t.name,
                            progress: t.progress,
                            start: new Date(t.startDate),
                            end: new Date(t.endDate),
                            duration: t.duration,
                            dependencies: t.dependencies,
                            color: getProjectScheduleTaskColor(projectSettings, project.color),
                        } as TimelineTask)
                ),
            taskOrderedIds: getScheduleTaskOrderedIds(scheduleGlTableSettings),
        },
        topIssues: issues
            .filter((i) => i.projectId === project.id && i.isTopFive)
            .map((i) => ({
                projectId: project.id,
                projectName: project.name,
                projectColor: project.color,
                description: i.description,
                status: issueStatuses?.find((s) => s.id === i.statusId),
                impact: i.impact,
            })),
        topRisks: risks
            .filter((r) => r.projectId === project.id && r.isTopFive)
            .map((r) => ({
                projectId: project.id,
                projectName: project.name,
                projectColor: project.color,
                description: r.description,
                status: riskStatuses?.find((s) => s.id === r.statusId),
                impact: r.impact,
            })),
        isLive: true,
    });
}

function getScheduleProgress(tasks: ScheduleTask[]) {
    const topLevelTasks = tasks.filter((t) => !t.parentId);
    const progressPercents = topLevelTasks.map((t) => t.progress ?? 0);
    return Math.round(progressPercents.reduce((a, b) => a + b, 0) / progressPercents.length);
}
