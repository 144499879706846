import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FileDownloadService } from 'app/core/services/file-download/file-download.service';
import { EntityPatchSuccessResponse } from 'app/core/services/rest-api/features/patch.response';
import { SiteSelectors } from 'app/core/store/site';
import { SnackBarService } from 'app/services/snack-bar/snack-bar.service';
import { getPatch } from 'app/utils/json-patch';
import { CommandResult } from 'app/utils/network';
import { environment } from 'environments/environment';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { BudgetTableSelectors } from '.';
import { AppStore } from '..';
import { RestApiService } from '../../services/rest-api';
import { Model } from '../shared/models/base.model';
import { okEmptyAction } from './../project/project.actions';
import * as BudgetTableActions from './budget-table.actions';
import { ProjectBudgetTable } from './models';

@Injectable()
export class BudgetTableEffects {
    loadCompletixBudgetTableTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BudgetTableActions.loadCompletixBudgetTableTemplates),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        BudgetTableActions.loadCompletixBudgetTableTemplatesSuccess({
                            tables: response.payload.map((dto) =>
                                Model.createFromDto(ProjectBudgetTable, dto)
                            ),
                        })
                    ),
                    catchError((error) =>
                        of(BudgetTableActions.loadCompletixBudgetTableTemplatesFail({ error }))
                    )
                )
            )
        )
    );

    loadCompletixBudgetTableTemplatesBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BudgetTableActions.loadCompletixBudgetTableTemplatesBySiteId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        BudgetTableActions.loadBudgetTablesBySiteIdSuccess({
                            tables: response.payload.map((dto) =>
                                Model.createFromDto(ProjectBudgetTable, dto)
                            ),
                        })
                    ),
                    catchError((error) =>
                        of(BudgetTableActions.loadBudgetTablesBySiteIdFail({ error }))
                    )
                )
            )
        )
    );

    loadBudgetTableByProjectId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BudgetTableActions.loadBudgetTableByProjectId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        BudgetTableActions.loadBudgetTableByProjectIdSuccess({
                            table: Model.createFromDto(ProjectBudgetTable, response.payload),
                        })
                    ),
                    catchError((error) =>
                        of(BudgetTableActions.loadBudgetTableByProjectIdFail({ error }))
                    )
                )
            )
        )
    );

    loadBudgetTableBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BudgetTableActions.loadBudgetTablesBySiteId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        BudgetTableActions.loadBudgetTablesBySiteIdSuccess({
                            tables: response.payload.map((dto) =>
                                Model.createFromDto(ProjectBudgetTable, dto)
                            ),
                        })
                    ),
                    catchError((error) =>
                        of(BudgetTableActions.loadBudgetTablesBySiteIdFail({ error }))
                    )
                )
            )
        )
    );

    importCompletixBudgetTable$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BudgetTableActions.importCompletixBudgetTable),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Importing a template...');
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBarService.hide()),
                    map((response: CommandResult) =>
                        BudgetTableActions.addBudgetTableSuccess({
                            table: Model.createFromDto(ProjectBudgetTable, response.payload),
                        })
                    ),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(BudgetTableActions.addBudgetTableFail({ error }));
                    })
                );
            })
        )
    );

    addBudgetTable$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BudgetTableActions.addBudgetTable),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        BudgetTableActions.addBudgetTableSuccess({
                            table: Model.createFromDto(ProjectBudgetTable, response.payload),
                        })
                    ),
                    catchError((error) => of(BudgetTableActions.addBudgetTableFail({ error })))
                )
            )
        )
    );

    updateBudgetTable$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BudgetTableActions.updateBudgetTable),
            withLatestFrom(this.store$.select(BudgetTableSelectors.selectAll)),
            mergeMap(([action, tables]) => {
                const table = tables.find((t) => t.id === action.payload.id);
                const patch = getPatch(table, action.payload.changes);
                return of(
                    BudgetTableActions.patchBudgetTable({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchBudgetTable$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BudgetTableActions.patchBudgetTable),
            mergeMap((action) => {
                const modifiedAction = {
                    ...action,
                    payload: {
                        ...action.payload,
                        patch: action.payload.patch.filter((op) => !op.path.includes('cellsMeta')),
                    },
                };
                return this.restApiService.process(modifiedAction).pipe(
                    map((response: EntityPatchSuccessResponse) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return BudgetTableActions.patchBudgetTableSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) =>
                        of(BudgetTableActions.patchBudgetTableFail({ error, originAction: action }))
                    )
                );
            })
        )
    );

    deleteBudgetTable$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BudgetTableActions.deleteBudgetTable),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return BudgetTableActions.deleteBudgetTableSuccess({
                            payload: {
                                id: action.payload.id,
                            },
                        });
                    }),
                    catchError((error) =>
                        of(
                            BudgetTableActions.deleteBudgetTableFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    changeBudgetTableTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BudgetTableActions.changeBudgetTableTemplate),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        BudgetTableActions.changeBudgetTableTemplateSuccess({
                            table: Model.createFromDto(ProjectBudgetTable, response.payload),
                        })
                    ),
                    catchError((error) =>
                        of(BudgetTableActions.changeBudgetTableTemplateFail({ error }))
                    )
                )
            )
        )
    );

    downloadTable$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BudgetTableActions.downloadBudgetTable),
            withLatestFrom(
                this.store$.select(BudgetTableSelectors.selectAll),
                this.store$.select(SiteSelectors.selectCurrentSiteId)
            ),
            mergeMap(([action, tables, currentSiteId]) => {
                const table = tables.find((t) => t.id === action.payload.tableId);
                const mimeType =
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                return this.http
                    .get(
                        `${environment.restApiConfig.baseUrl}/projectBudgetTables/${action.payload.tableId}/download`,
                        {
                            headers: {
                                Accept: mimeType,
                                'Content-Type': 'application/json',
                                tenantId: currentSiteId,
                            },
                            responseType: 'blob',
                        }
                    )
                    .pipe(
                        map((response) => {
                            const blob = new Blob([response], { type: mimeType });
                            this.fileDownloadService.downloadFileByBlob(blob, table.name);
                            return BudgetTableActions.deleteBudgetTableFail({
                                error: '',
                                originAction: action,
                            });
                        }),
                        catchError((error: any) => {
                            return of(
                                BudgetTableActions.deleteBudgetTableFail({
                                    error,
                                    originAction: action,
                                })
                            );
                        })
                    );
            })
        )
    );

    importBudgetTableDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BudgetTableActions.importBudgetTableDocument),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        BudgetTableActions.patchBudgetTableSuccess({
                            id: action.payload.tableId,
                            patch: response.payload,
                        })
                    ),
                    catchError((error) =>
                        of(BudgetTableActions.importBudgetTableDocumentFail({ error }))
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>,
        private snackBarService: SnackBarService,
        private http: HttpClient,
        private fileDownloadService: FileDownloadService
    ) {}
}
