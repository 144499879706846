<h3 mat-dialog-title>{{ title }}</h3>

<mat-dialog-content>
    <p>{{ message }}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button ctxAutofocus color="primary" [mat-dialog-close]="true">
        {{ confirm | uppercase }}
    </button>
</mat-dialog-actions>
