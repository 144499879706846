import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import { deepClone } from 'fast-json-patch';
import {
    AsyncUpdateStatusDefault,
    AsyncUpdateStatusDefaultLoadSuccess,
} from '../shared/async-update-status.store';
import { ProjectPhase } from './models';
import * as ProjectPhaseActions from './project-phase.actions';
import { adapter, initialState } from './project-phase.store';

export const reducer = createReducer(
    initialState,

    on(ProjectPhaseActions.loadProjectPhasesByContainerId, (state, { payload }) => {
        const containerId = payload.containerId;
        const loadingStatusMap = state.loadingStatusByContainerId;

        return {
            ...state,
            loadingStatusByContainerId: {
                ...loadingStatusMap,
                [containerId]: {
                    ...(loadingStatusMap[containerId] ?? {}),
                    serverRequestInProgress: true,
                },
            },
        };
    }),

    on(ProjectPhaseActions.loadProjectPhasesByContainerIdSuccess, (state, payload) => {
        const existingPhases = Object.values(state.entities).filter(
            (p) => p.containerId === payload.containerId
        );
        const newState = adapter.addMany(
            payload.phases,
            adapter.removeMany(
                existingPhases.map((p) => p.id),
                state
            )
        );
        const loadingStatusMap = state.loadingStatusByContainerId;
        const containerId = payload.containerId;

        return {
            ...newState,
            loadingStatusByContainerId: {
                ...loadingStatusMap,
                [containerId]: {
                    ...(loadingStatusMap[containerId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                },
            },
        };
    }),

    on(ProjectPhaseActions.loadProjectPhaseByContainerIdFail, (state, payload) => {
        const loadingStatusMap = state.loadingStatusByContainerId;
        const containerId = payload.containerId;

        return {
            ...state,
            loadingStatusByContainerId: {
                ...loadingStatusMap,
                [containerId]: {
                    ...(loadingStatusMap[containerId] ?? {}),
                    ...AsyncUpdateStatusDefault,
                    error: payload.error,
                    loadingFailed: true,
                },
            },
        };
    }),

    on(ProjectPhaseActions.loadProjectPhasesBySiteId, (state, { payload }) => {
        const siteId = payload.siteId;
        const loadingStatusMap = state.loadingStatusBySiteId;

        return {
            ...state,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    serverRequestInProgress: true,
                },
            },
        };
    }),

    on(ProjectPhaseActions.loadProjectPhasesBySiteIdSuccess, (state, payload) => {
        const listContainerIds = payload.listContainerIds;
        const existingPhases = Object.values(state.entities).filter((p) =>
            listContainerIds.includes(p.containerId)
        );
        const newState = adapter.addMany(
            payload.phases,
            adapter.removeMany(
                existingPhases.map((p) => p.id),
                state
            )
        );
        const loadingStatusMap = state.loadingStatusBySiteId;
        const siteId = payload.siteId;
        const loadingByContainerIdMap = deepClone(state.loadingStatusByContainerId);
        listContainerIds.forEach(
            (id) =>
                (loadingByContainerIdMap[id] = {
                    ...(loadingByContainerIdMap[id] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                })
        );

        return {
            ...newState,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                },
            },
            loadingStatusByContainerId: loadingByContainerIdMap,
        };
    }),

    on(ProjectPhaseActions.loadProjectPhasesBySiteIdFail, (state, payload) => {
        const loadingStatusMap = state.loadingStatusBySiteId;
        const siteId = payload.siteId;

        return {
            ...state,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefault,
                    error: payload.error,
                    loadingFailed: true,
                },
            },
        };
    }),

    on(ProjectPhaseActions.changeGatingTemplateSuccess, (state, payload) => {
        const loadingStatusMap = state.loadingStatusByContainerId;
        const containerId = payload.containerId;
        return {
            ...adapter.addMany(payload.phases, state),
            loadingStatusByContainerId: {
                ...loadingStatusMap,
                [containerId]: {
                    ...(loadingStatusMap[containerId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                },
            },
        };
    }),

    on(ProjectPhaseActions.getProjectPhaseByIdSuccess, (state, payload) =>
        adapter.addOne(payload.projectPhase, adapter.removeOne(payload.projectPhase.id, state))
    ),

    on(ProjectPhaseActions.addProjectPhaseSuccess, (state, payload) =>
        adapter.addOne(payload.phase, state)
    ),

    on(ProjectPhaseActions.addProjectPhaseFail, (state, payload) => ({ ...state })),

    on(ProjectPhaseActions.patchProjectPhase, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patched = getPatched(state.entities[action.payload.id], action.payload.patch);
        const update: Update<ProjectPhase> = { id: patched.id, changes: patched };
        return adapter.updateOne(update, state);
    }),

    on(ProjectPhaseActions.patchProjectPhaseSuccess, (state, payload) => {
        const patched = getPatched(state.entities[payload.id], payload.patch);
        const update: Update<ProjectPhase> = { id: patched.id, changes: patched };
        return adapter.updateOne(update, state);
    }),

    on(ProjectPhaseActions.patchProjectPhaseFail, (state) => ({ ...state })),

    on(ProjectPhaseActions.deleteProjectPhaseSuccess, (state, payload) =>
        adapter.removeOne(payload.id, state)
    ),

    on(ProjectPhaseActions.deleteProjectPhaseFail, (state) => ({ ...state })),

    on(ProjectPhaseActions.addProjectPhaseDeliverable, (state, action) => {
        if (!action.options?.optimistic) return state;
        const phase = state.entities[action.payload.phaseId];
        const update: Update<ProjectPhase> = {
            id: action.payload.phaseId,
            changes: { deliverables: [...(phase.deliverables || []), action.payload.deliverable] },
        };
        return adapter.updateOne(update, state);
    }),

    on(ProjectPhaseActions.addProjectPhaseDeliverableSuccess, (state, payload) => {
        const phase = state.entities[payload.phaseId];
        const update: Update<ProjectPhase> = {
            id: payload.phaseId,
            changes: { deliverables: [...(phase.deliverables || []), payload.deliverable] },
        };
        return adapter.updateOne(update, state);
    }),

    on(ProjectPhaseActions.deleteProjectPhaseDeliverable, (state, action) => {
        if (!action.options?.optimistic) return state;
        const phase = state.entities[action.payload.phaseId];
        const update: Update<ProjectPhase> = {
            id: action.payload.phaseId,
            changes: {
                deliverables: phase.deliverables.filter(
                    (d) => d.id !== action.payload.deliverableId
                ),
            },
        };
        return adapter.updateOne(update, state);
    }),

    on(ProjectPhaseActions.deleteProjectPhaseDeliverableSuccess, (state, payload) => {
        const phase = state.entities[payload.phaseId];
        phase.deliverables = phase.deliverables.filter((d) => d.id !== payload.deliverableId);
        const update: Update<ProjectPhase> = {
            id: payload.phaseId,
            changes: {
                deliverables: phase.deliverables.filter((d) => d.id !== payload.deliverableId),
            },
        };
        return adapter.updateOne(update, state);
    }),

    on(ProjectPhaseActions.patchProjectPhaseDeliverable, (state, action) => {
        if (!action.options?.optimistic) return state;
        const phase = state.entities[action.payload.phaseId];
        const deliverableIdx = phase.deliverables.findIndex(
            (d) => d.id === action.payload.deliverableId
        );
        const deliverable = phase.deliverables[deliverableIdx];
        const updateDeliverable = getPatched(deliverable, action.payload.patch);
        const update: Update<ProjectPhase> = {
            id: action.payload.phaseId,
            changes: {
                deliverables: [
                    ...phase.deliverables.slice(0, deliverableIdx),
                    updateDeliverable,
                    ...phase.deliverables.slice(deliverableIdx + 1),
                ],
            },
        };
        return adapter.updateOne(update, state);
    }),

    on(ProjectPhaseActions.patchProjectPhaseDeliverableSuccess, (state, payload) => {
        const phase = state.entities[payload.phaseId];
        const deliverableIdx = phase.deliverables.findIndex((d) => d.id === payload.deliverableId);
        const deliverable = phase.deliverables[deliverableIdx];
        const updateDeliverable = getPatched(deliverable, payload.patch);
        const update: Update<ProjectPhase> = {
            id: payload.phaseId,
            changes: {
                deliverables: [
                    ...phase.deliverables.slice(0, deliverableIdx),
                    updateDeliverable,
                    ...phase.deliverables.slice(deliverableIdx + 1),
                ],
            },
        };
        return adapter.updateOne(update, state);
    })
);
