import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SnackBarService } from 'app/services/snack-bar/snack-bar.service';
import { getPatch } from 'app/utils/json-patch';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { RestApiService } from '../../services/rest-api';
import { EntityPatchSuccessResponse } from '../../services/rest-api/features/patch.response';
import { ProjectSelectors } from '../project';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import * as AppStore from '../store';
import { Vendor } from './models/vendor.model';
import * as VendorActions from './vendor.actions';
import * as VendorSelectors from './vendor.selectors';

@Injectable()
export class VendorEffects {
    loadVendorsByProjectId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VendorActions.loadVendorsByProjectId),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) =>
                        VendorActions.loadVendorsByProjectIdSuccess({
                            vendors: response.payload.map((dto) =>
                                Model.createFromDto(Vendor, dto)
                            ),
                            projectId: action.payload.projectId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            VendorActions.loadVendorsByProjectIdFail({
                                error,
                                projectId: action.payload.projectId,
                            })
                        )
                    )
                )
            )
        )
    );

    loadVendorsBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VendorActions.loadVendorsBySiteId),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    withLatestFrom(this.store$.select(ProjectSelectors.selectCurrentSiteProjects)),
                    map(([response, projects]) =>
                        VendorActions.loadVendorsBySiteIdSuccess({
                            vendors: response.payload.map((dto) =>
                                Model.createFromDto(Vendor, dto)
                            ),
                            siteId: action.payload.siteId,
                            projectIds: projects.map((prj) => prj.id),
                        })
                    ),
                    catchError((error) =>
                        of(
                            VendorActions.loadVendorsBySiteIdFail({
                                error,
                                siteId: action.payload.siteId,
                            })
                        )
                    )
                )
            )
        )
    );

    addVendor$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VendorActions.addVendor),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Creating a new Vendor...');
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBarService.hide()),
                    map((response) =>
                        VendorActions.addVendorSuccess({
                            vendor: Model.createFromDto(Vendor, response.payload),
                        })
                    ),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(VendorActions.addVendorFail({ error }));
                    })
                );
            })
        )
    );

    updateVendor$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VendorActions.updateVendor),
            withLatestFrom(this.store$.select(VendorSelectors.selectAll)),
            mergeMap(([action, vendors]) => {
                const vendor = vendors.find((cr) => cr.id === action.payload.id);
                const patch = getPatch(vendor, action.payload.changes);
                return of(
                    VendorActions.patchVendor({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchVendor$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VendorActions.patchVendor),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: EntityPatchSuccessResponse) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return VendorActions.patchVendorSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) =>
                        of(
                            VendorActions.patchVendorFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteVendor$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VendorActions.deleteVendor),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() =>
                        VendorActions.deleteVendorSuccess({
                            id: action.payload.id,
                        })
                    ),
                    catchError((error) =>
                        of(
                            VendorActions.deleteVendorFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>,
        private snackBarService: SnackBarService
    ) {}
}
