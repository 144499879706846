import { createCtxEntityAdapter, CtxEntityAdapter, CtxEntityState } from '../store';
import { DecisionRecord } from './models';

export interface LoadingStatus {
    serverRequestInProgress: boolean;
    loaded: boolean;
    updateServerRequstInProgressIds: string[];
}

export interface State extends CtxEntityState<DecisionRecord>, LoadingStatus {}

export const adapter: CtxEntityAdapter<DecisionRecord> = createCtxEntityAdapter<DecisionRecord>();

export const initialState: State = adapter.getCtxInitialState({
    serverRequestInProgress: false,
    loaded: false,
    updateServerRequstInProgressIds: [],
});
