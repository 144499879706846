import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AsyncUpdateStatus, AsyncUpdateStatusDefault } from '../shared/async-update-status.store';
import { ProgramRole } from './models/program-role.model';

export interface State extends EntityState<ProgramRole> {
    templatesLoadingStatus: AsyncUpdateStatus;
    currentSiteRolesLoadingStatus: AsyncUpdateStatus;
}

export const adapter: EntityAdapter<ProgramRole> = createEntityAdapter<ProgramRole>();

export const initialState: State = adapter.getInitialState({
    templatesLoadingStatus: AsyncUpdateStatusDefault,
    currentSiteRolesLoadingStatus: AsyncUpdateStatusDefault,
});
