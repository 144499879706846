import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { LoadingStatusByProgramId, LoadingStatusBySiteId } from 'app/core/models/shared-models';
import { ProgramStatusReport } from './models/program-status-report.model';

export interface State
    extends EntityState<ProgramStatusReport>,
        LoadingStatusBySiteId,
        LoadingStatusByProgramId {}

export const adapter: EntityAdapter<ProgramStatusReport> =
    createEntityAdapter<ProgramStatusReport>();

export const initialState: State = adapter.getInitialState({
    loadingStatusByProgramId: {},
    loadingStatusBySiteId: {},
});
