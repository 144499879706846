import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isClosedSiteEntityState } from 'app/core/models/site-entity.model';
import { AsyncUpdateStatus } from '../shared/async-update-status.store';
import { SiteSelectors } from '../site';
import { StateEntry } from '../state-entry';
import { adapter, State } from './project.store';

export const selectProjectEntry = createFeatureSelector<State>(StateEntry.Project);

// Pay attention: adapter selectors return projects for all sites
// TODO: do not store data for different sites at once, clear the store on site change instead

// Simplified object destructuring is consciously not used due to incorrect work of
// find usage feature (Shift+F12) - remove right hand side destructuring component
// (after semicolon) and try to find usages - it results to many unwanted entries found
// TODO: track related issue https://github.com/Microsoft/TypeScript/issues/15787
export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectProjectEntry);

export const selectLoadingStatus = createSelector(
    selectProjectEntry,
    (state): AsyncUpdateStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
        loadingFailed: state.loadingFailed,
        error: state.error,
    })
);

export const selectAllLoaded = createSelector(selectAll, selectLoadingStatus, (items, status) =>
    status?.loaded ? items : null
);

export const selectCurrentSiteProjects = createSelector(
    selectAll,
    SiteSelectors.selectCurrentSiteId,
    (projects, siteId) => projects.filter((prj) => prj.siteId === siteId)
);

export const selectCurrentProjectId = createSelector(
    selectProjectEntry,
    (state: State) => state.currentProjectId
);

export const selectCurrentProject = createSelector(
    selectEntities,
    selectCurrentProjectId,
    (projectEntities, projectId) => projectEntities[projectId]
);

export const selectInitialProjectId = createSelector(
    selectProjectEntry,
    (state) => state.initialProjectId
);

export const selectCurrentProjectIsClosed = createSelector(selectCurrentProject, (project) =>
    isClosedSiteEntityState(project?.state)
);

export const selectProjectClosedMap = createSelector(selectAll, (projects) => {
    const map: Record<string, boolean> = {};
    projects.forEach((prj) => (map[prj.id] = isClosedSiteEntityState(prj.state)));
    return map;
});

export const selectProjectById = (id: string) =>
    createSelector(selectEntities, (projectEntities) => projectEntities[id]);

export const selectArchivedProjects = createSelector(selectCurrentSiteProjects, (projects) =>
    projects.filter((prj) => prj.archived)
);

export const selectCurrentProjectSettings = createSelector(
    selectProjectEntry,
    selectCurrentProjectId,
    (entry, projectId) => entry.projectSettingsMap[projectId]
);

export const selectProjectSettings = (projectId: string) =>
    createSelector(selectProjectEntry, (entry) => entry.projectSettingsMap[projectId]);

export const selectProjectSettingsMap = createSelector(
    selectProjectEntry,
    (entry) => entry.projectSettingsMap
);

export const selectSettingsLoadingStatusMap = createSelector(
    selectProjectEntry,
    (entry) => entry.projectSettingsLoadingMap
);

export const selectProjectNamesDictionary = createSelector(selectAll, (projects) => {
    const dict: Record<string, string> = {};
    projects.forEach((prj) => (dict[prj.id] = prj.name));
    return dict;
});

export const selectNotArchivedProjects = createSelector(
    selectCurrentSiteProjects,
    selectProjectSettingsMap,
    (projects, settingsMap) => projects.filter((prj) => !prj.archived && settingsMap[prj.id])
);

export const selectActiveProjects = createSelector(selectNotArchivedProjects, (projects) =>
    projects.filter((prj) => !isClosedSiteEntityState(prj.state))
);

export const selectCurrentProjectRaciTable = createSelector(
    selectCurrentProjectId,
    selectProjectEntry,
    (currentProjectId, state) => state.raciTablesMap[currentProjectId]
);
