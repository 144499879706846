import { JsonProperty } from 'app/utils/json-mapper';
import { CtxColor, CtxMember, EntitySubmission } from '../../../models/shared-models';
import { ApprovalState } from '../../approval-request';
import { Model } from '../../shared/models/base.model';
import { RaidRecord } from '../../shared/models/raid-shared.model';

export class DecisionRecord extends Model implements RaidRecord {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('projectId')
    projectId: string = undefined;

    @JsonProperty('textId')
    textId?: string;

    @JsonProperty('description')
    description?: string;

    @JsonProperty('date')
    date?: Date;

    @JsonProperty('approvedDate')
    approvedDate?: Date;

    @JsonProperty('creationDate')
    creationDate: string = undefined;

    @JsonProperty('statusId')
    statusId: string = undefined;

    @JsonProperty('attachedDocumentIds')
    attachedDocumentIds?: string[];

    @JsonProperty('assignedMembers')
    assignedMembers?: DecisionMember[];

    @JsonProperty('color')
    color?: CtxColor;

    @JsonProperty('submission')
    submission?: EntitySubmission;

    @JsonProperty('originObjectId')
    originObjectId?: string;

    constructor(init?: Partial<DecisionRecord>) {
        super();
        Object.assign(this, init);
    }
}

export enum DecisionStatus {
    Approved = 'Approved',
    Rejected = 'Rejected',
    Pending = 'Pending',
}

export interface DecisionMember extends CtxMember {
    status?: ApprovalState;
    approvalDate?: Date;
}
