import { Action, createAction, props } from '@ngrx/store';
import { IdPayload } from 'app/core/store/shared/models/shared-payloads';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import { ErrorPayload } from '../shared/payloads';
import { SiteIdPayload } from '../site/site.payloads';
import { SiteSettings } from './models';
import { AddSiteSettingPayload, PatchSiteSettingsPayload } from './site-settings.payloads';

// Get current site settings actions

export const loadSiteSettings = createAction(
    '[SiteSettings] Load',
    props<{ payload: SiteIdPayload }>()
);

export const loadSiteSettingsSuccess = createAction(
    '[SiteSettings] Load success',
    props<{ settings: SiteSettings }>()
);

export const loadSiteSettingsFail = createAction(
    '[SiteSettings] Load fail',
    props<{ error: any }>()
);

// Load Site Settings Completix Templates actions

export const loadSiteSettingsCompletixTemplates = createAction(
    '[SiteSettings] Load SiteSettings Completix templates'
);

export const loadSiteSettingsCompletixTemplatesSuccess = createAction(
    '[SiteSettings] Load SiteSettings Completix templates success',
    props<{ settings: SiteSettings[] }>()
);

export const loadSiteSettingsCompletixTemplatesFail = createAction(
    '[SiteSettings] Load SiteSettings Completix templates fail',
    props<{ error: any }>()
);

// Patch SiteSetting actions

export const patchSiteSettings = createAction(
    '[SiteSetting] Patch settings',
    props<{ payload: PatchSiteSettingsPayload; options?: OptimisticableActionOptions }>()
);

export const patchSiteSettingsSuccess = createAction(
    '[SiteSetting] Patch settings success',
    props<PatchSiteSettingsPayload>()
);

export const patchSiteSettingsFail = createAction(
    '[SiteSetting] Patch settings fail',
    props<{ error: any; originAction: Action }>()
);

// Add SiteSettings actions

export const addSiteSettings = createAction(
    '[SiteSettings] Add',
    props<{ payload: AddSiteSettingPayload }>()
);

export const addSiteSettingsSuccess = createAction(
    '[SiteSettings] Add success',
    props<{ settings: SiteSettings }>()
);

export const addSiteSettingsFail = createAction('[SiteSettings] Add fail', props<{ error: any }>());

// Delete SiteSettings actions

export const deleteSiteSettings = createAction(
    '[SiteSettings] Delete',
    props<{ payload: IdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteSiteSettingsSuccess = createAction(
    '[SiteSettings] Delete success',
    props<IdPayload>()
);

export const deleteSiteSettingsFail = createAction(
    '[SiteSetting] Delete fail',
    props<{ error: ErrorPayload; originAction: Action }>()
);
