import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SnackBarService } from 'app/services/snack-bar/snack-bar.service';
import { getPatch } from 'app/utils/json-patch';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppStore } from '..';
import { RestApiService } from '../../services/rest-api';
import { EntityPatchSuccessResponse } from '../../services/rest-api/features/patch.response';
import { Program, ProgramSelectors } from '../program';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import { ProgramStatusReport } from './models';
import * as ProgramStatusActions from './program-status-report.actions';
import * as ProgramStatusSelectors from './program-status-report.selectors';

@Injectable()
export class ProgramStatusEffects {
    loadProgramStatusReports$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramStatusActions.loadProgramStatuses),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        ProgramStatusActions.loadProgramStatusesSuccess({
                            statuses: response.payload.map((dto) =>
                                Model.createFromDto(ProgramStatusReport, dto)
                            ),
                            programId: action.payload.programId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            ProgramStatusActions.loadProgramStatusesFail({
                                error,
                                programId: action.payload.programId,
                            })
                        )
                    )
                )
            )
        )
    );

    loadProgramStatusesBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramStatusActions.loadProgramStatusesBySiteId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    withLatestFrom(this.store$.select(ProgramSelectors.selectCurrentSitePrograms)),
                    map(([response, programs]: [CommandResult, Program[]]) =>
                        ProgramStatusActions.loadProgramStatusesBySiteIdSuccess({
                            statuses: response.payload.map((dto) =>
                                Model.createFromDto(ProgramStatusReport, dto)
                            ),
                            siteId: action.payload.siteId,
                            programIds: programs.map((p) => p.id),
                        })
                    ),
                    catchError((error) =>
                        of(
                            ProgramStatusActions.loadProgramStatusesBySiteIdFail({
                                error,
                                siteId: action.payload.siteId,
                            })
                        )
                    )
                )
            )
        )
    );

    addProgramStatusReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramStatusActions.addProgramStatus),
            mergeMap((action) => {
                if (!action.hideSnackbar) {
                    this.snackBarService.show('Creating a new Report...');
                }
                return this.restApiService.process(action).pipe(
                    tap(() => {
                        if (!action.hideSnackbar) this.snackBarService.hide();
                    }),
                    map((response: CommandResult) =>
                        ProgramStatusActions.addProgramStatusSuccess({
                            report: Model.createFromDto(ProgramStatusReport, response.payload),
                        })
                    ),
                    catchError((error) => {
                        if (!action.hideSnackbar) this.snackBarService.hide();
                        return of(ProgramStatusActions.addProgramStatusFail({ error }));
                    })
                );
            })
        )
    );

    updatepProgramStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramStatusActions.updateProgramStatus),
            withLatestFrom(this.store$.select(ProgramStatusSelectors.selectAll)),
            mergeMap(([action, reports]) => {
                const report = reports.find((r) => r.id === action.payload.id);
                const patch = getPatch(report, action.payload.changes);
                return of(
                    ProgramStatusActions.patchProgramStatus({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchProgramStatusReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramStatusActions.patchProgramStatus),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: EntityPatchSuccessResponse) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ProgramStatusActions.patchProgramStatusSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) =>
                        of(
                            ProgramStatusActions.patchProgramStatusFail({
                                payload: { error },
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteProgramStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgramStatusActions.deleteProgramStatus),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ProgramStatusActions.deleteProgramStatusSuccess({
                            id: action.payload.id,
                        });
                    }),
                    catchError((error) =>
                        of(
                            ProgramStatusActions.deleteProgramStatusFail({
                                payload: { error },
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>,
        private snackBarService: SnackBarService
    ) {}
}
