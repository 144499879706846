import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RestfulGateway } from 'app/utils/network';
import { RestResponseInterceptor } from './interceptors';
import { AuthInterceptor } from './interceptors/rest-request.interceptor';
import { RestApiService } from './rest-api.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
})
export class RestApiModule {
    static forRoot(): ModuleWithProviders<RestApiModule> {
        return {
            ngModule: RestApiModule,
            providers: [
                RestApiService,
                RestfulGateway,
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: AuthInterceptor,
                    multi: true,
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: RestResponseInterceptor,
                    multi: true,
                },
            ],
        };
    }
}
