import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FileDownloadService } from 'app/core/services/file-download/file-download.service';
import { DocumentResponses } from 'app/core/services/rest-api/features/document';
import { EntityPatchSuccessResponse } from 'app/core/services/rest-api/features/patch.response';
import { SiteSelectors } from 'app/core/store/site';
import { getPatch } from 'app/utils/json-patch';
import { CommandResult } from 'app/utils/network';
import { environment } from 'environments/environment';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { DocumentActions, DocumentSelectors } from '.';
import { RestApiService } from '../../services/rest-api';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import { AppState } from '../store';
import { SnackBarService } from './../../../services/snack-bar/snack-bar.service';
import { FileNode } from './models';
import { DocumentCreateOptions, FileNodeDto } from './models/document.model';

@Injectable()
export class DocumentEffects {
    loadCompletixDocumentTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentActions.loadCompletixDocumentTemplates),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: DocumentResponses.GetDocumentsResponse) => {
                        const documents = response.payload.map((dto) =>
                            Model.createFromDto(FileNode, dto)
                        );
                        return DocumentActions.loadCompletixDocumentTemplatesSuccess({
                            documents,
                        });
                    }),
                    catchError((error: any) =>
                        of(DocumentActions.loadCompletixDocumentTemplatesFail({ error }))
                    )
                )
            )
        )
    );

    loadDocumentsByProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentActions.loadDocumentsByProject),
            mergeMap((action) => {
                return this.restApiService.process(action).pipe(
                    map((response: DocumentResponses.GetDocumentsResponse) => {
                        const documents = response.payload.map((dto) =>
                            Model.createFromDto(FileNode, dto)
                        );
                        return DocumentActions.loadDocumentsByProjectSuccess({
                            documents,
                            projectId: action.payload.projectId,
                        });
                    }),
                    catchError((error: any) =>
                        of(
                            DocumentActions.loadDocumentsByProjectFail({
                                error,
                                projectId: action.payload.projectId,
                            })
                        )
                    )
                );
            })
        )
    );

    loadDocumentsByProgram$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentActions.loadDocumentsByProgram),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: DocumentResponses.GetDocumentsResponse) => {
                        const documents = response.payload.map((dto) =>
                            Model.createFromDto(FileNode, dto)
                        );
                        return DocumentActions.loadDocumentsByProgramSuccess({
                            documents,
                            programId: action.payload.programId,
                        });
                    }),
                    catchError((error: any) =>
                        of(
                            DocumentActions.loadDocumentsByProgramFail({
                                error,
                                programId: action.payload.programId,
                            })
                        )
                    )
                )
            )
        )
    );

    loadDocumentsBySite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentActions.loadDocumentsBySite),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: DocumentResponses.GetDocumentsResponse) => {
                        const documents = response.payload.map((dto) =>
                            Model.createFromDto(FileNode, dto)
                        );
                        return DocumentActions.loadDocumentsBySiteSuccess({
                            documents,
                            siteId: action.payload.siteId,
                        });
                    }),
                    catchError((error: any) => of(DocumentActions.loadDocumentsFail({ error })))
                )
            )
        )
    );

    loadDocumentChangeRequestFormBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentActions.loadDocumentChangeRequestFormBySiteId),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: DocumentResponses.GetDocumentsResponse) => {
                        return DocumentActions.loadDocumentChangeRequestFormBySiteIdSuccess({
                            document: Model.createFromDto(FileNode, response.payload),
                        });
                    }),
                    catchError((error: any) =>
                        of(DocumentActions.loadDocumentChangeRequestFormBySiteIdFail({ error }))
                    )
                )
            )
        )
    );

    loadCompletixDocumentChangeRequestForm$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentActions.loadCompletixDocumentChangeRequestForm),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: DocumentResponses.GetDocumentsResponse) => {
                        return DocumentActions.loadCompletixDocumentChangeRequestFormSuccess({
                            document: Model.createFromDto(FileNode, response.payload),
                        });
                    }),
                    catchError((error: any) =>
                        of(DocumentActions.loadCompletixDocumentChangeRequestFormFail({ error }))
                    )
                )
            )
        )
    );

    loadCompletixDocumentChangeRequestFormBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentActions.loadCompletixDocumentChangeRequestFormBySiteId),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: DocumentResponses.GetDocumentsResponse) => {
                        return DocumentActions.loadCompletixDocumentChangeRequestFormBySiteIdSuccess(
                            {
                                document: Model.createFromDto(FileNode, response.payload),
                            }
                        );
                    }),
                    catchError((error: any) =>
                        of(
                            DocumentActions.loadCompletixDocumentChangeRequestFormBySiteIdFail({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    loadCompletixDocumentTemplatesBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentActions.loadCompletixDocumentTemplatesBySiteId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: DocumentResponses.GetDocumentsResponse) => {
                        const documents = response.payload.map((dto) =>
                            Model.createFromDto(FileNode, dto)
                        );
                        return DocumentActions.loadCompletixDocumentTemplatesBySiteIdSuccess({
                            documents,
                        });
                    }),
                    catchError((error: any) =>
                        of(DocumentActions.loadCompletixDocumentTemplatesBySiteIdFail({ error }))
                    )
                )
            )
        )
    );

    addDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentActions.addDocument),
            withLatestFrom(
                this.store$
                    .select(DocumentSelectors.selectAll)
                    .pipe(map((documents) => documents.filter((doc) => !!doc.systemTag)))
            ),
            mergeMap(([action, documentsWithTag]) => {
                const modifiedCreateOptions: DocumentCreateOptions = {
                    ...action.payload.createOptions,
                };
                let modifiedAction = {
                    ...action,
                    payload: {
                        ...action.payload,
                        createOptions: modifiedCreateOptions,
                    },
                };
                const options = action.payload.createOptions;
                const filteredDocuments = documentsWithTag.filter(
                    (doc) =>
                        doc.level === options.level &&
                        ((options.projectId && doc.projectId === options.projectId) ||
                            (options.programId && doc.programId === options.programId) ||
                            (options.siteId && doc.siteId === options.siteId))
                );
                const systemTag = action.payload.createOptions.parentSystemTag;
                if (systemTag && !action.payload.createOptions.parentFolderId) {
                    const parentFolder = filteredDocuments.find(
                        (doc) => doc.systemTag === systemTag
                    );
                    modifiedAction = {
                        ...modifiedAction,
                        payload: {
                            ...modifiedAction.payload,
                            createOptions: {
                                ...modifiedAction.payload.createOptions,
                                parentFolderId: parentFolder.id,
                            },
                        },
                    };
                }
                this.snackbarService.showInfiniteBar('Processing...');
                return this.restApiService.process(modifiedAction).pipe(
                    map((response: DocumentResponses.AddDocumentResponse) => {
                        this.snackbarService.hide();
                        return Model.createFromDto(FileNodeDto, response.payload);
                    }),
                    map((doc: FileNode) => DocumentActions.addDocumentSuccess({ document: doc })),
                    catchError((error: any) => {
                        this.snackbarService.hide();
                        return of(DocumentActions.addDocumentFail({ error }));
                    })
                );
            })
        )
    );

    importDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentActions.importDocument),
            mergeMap((action) => {
                this.snackbarService.showInfiniteBar('Importing the template...');
                return this.restApiService.process(action).pipe(
                    map((response: CommandResult) => {
                        this.snackbarService.hide();
                        return DocumentActions.addDocumentSuccess({
                            document: Model.createFromDto(FileNode, response.payload),
                        });
                    }),
                    catchError((error: any) => {
                        this.snackbarService.show('Error importing the template');
                        return of(DocumentActions.addDocumentFail({ error }));
                    })
                );
            })
        )
    );

    updateDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentActions.updateDocument),
            withLatestFrom(this.store$.select(DocumentSelectors.selectAll)),
            mergeMap(([action, allDocuments]) => {
                const updatingDocument = allDocuments.find(
                    (doc) => doc.id === action.payload.documentId
                );
                const patch = getPatch(updatingDocument, action.payload.changes);
                return of(
                    DocumentActions.patchDocument({
                        payload: { id: updatingDocument.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentActions.patchDocument),
            filter((action) => !!action.payload.patch?.length),
            concatMap((action) => {
                if (!action.options?.optimistic) {
                    this.snackbarService.show('Processing...', 2000);
                }
                return this.restApiService.process(action).pipe(
                    filter(() => !action.options?.optimistic),
                    map((response: EntityPatchSuccessResponse) =>
                        DocumentActions.patchDocumentSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        })
                    ),
                    catchError((error: any) => {
                        this.snackbarService.show('Change failed', 1500);
                        return of(
                            DocumentActions.patchDocumentFail({ error, originAction: action })
                        );
                    })
                );
            })
        )
    );

    addDocumentVersion$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentActions.addDocumentVersion),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: EntityPatchSuccessResponse) =>
                        DocumentActions.patchDocumentSuccess({
                            id: action.payload.documentId,
                            patch: response.payload,
                        })
                    ),
                    catchError((error: any) =>
                        of(DocumentActions.patchDocumentFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    downloadDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentActions.downloadDocument),
            withLatestFrom(
                this.store$.select(DocumentSelectors.selectAll),
                this.store$.select(SiteSelectors.selectCurrentSiteId)
            ),
            mergeMap(([action, documents, currentSiteId]) => {
                const document = documents.find((doc) => doc.id === action.payload.documentId);
                this.snackbarService.showInfiniteBar('Downloading...');
                return this.http
                    .get(
                        `${environment.restApiConfig.baseUrl}/documents/${action.payload.documentId}/download/${action.payload.type}`,
                        {
                            headers: {
                                Accept: '*',
                                'Content-Type': '*',
                                tenantId: currentSiteId,
                            },
                            responseType: 'blob',
                        }
                    )
                    .pipe(
                        map((blob) => {
                            this.fileDownloadService.downloadFileByBlob(blob, document.name);
                            this.snackbarService.hide();
                            return okEmptyAction();
                        }),
                        catchError(() => {
                            this.snackbarService.show('Download failed');
                            return of(okEmptyAction());
                        })
                    );
            })
        )
    );

    downloadDocumentVersion$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentActions.downloadDocumentVersion),
            withLatestFrom(
                this.store$.select(DocumentSelectors.selectAll),
                this.store$.select(SiteSelectors.selectCurrentSiteId)
            ),
            mergeMap(([action, documents, currentSiteId]) => {
                const document = documents.find((doc) => doc.id === action.payload.documentId);
                this.snackbarService.showInfiniteBar('Downloading...');
                return this.http
                    .get(
                        `${environment.restApiConfig.baseUrl}/documents/${action.payload.documentId}/version/${action.payload.versionId}/download`,
                        {
                            headers: {
                                Accept: '*',
                                'Content-Type': '*',
                                tenantId: currentSiteId,
                            },
                            responseType: 'blob',
                        }
                    )
                    .pipe(
                        map((blob) => {
                            this.fileDownloadService.downloadFileByBlob(blob, document.name);
                            this.snackbarService.hide();
                            return okEmptyAction();
                        }),
                        catchError((err) => {
                            console.error(err);
                            this.snackbarService.show('Download failed');
                            return of(okEmptyAction());
                        })
                    );
            })
        )
    );

    getDocumentPreview$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentActions.getDocumentPreview),
            withLatestFrom(this.store$.select(SiteSelectors.selectCurrentSiteId)),
            mergeMap(([action, currentSiteId]) =>
                this.http
                    .get(
                        `${environment.restApiConfig.baseUrl}/documents/${action.payload.documentId}/preview`,
                        {
                            headers: {
                                Accept: 'application/pdf',
                                'Content-Type': 'application/pdf',
                                tenantId: currentSiteId,
                            },
                            responseType: 'blob',
                        }
                    )
                    .pipe(
                        map((response) => {
                            const blob = response
                                ? new Blob([response], { type: 'application/pdf' })
                                : null;

                            return DocumentActions.getDocumentPreviewSuccess({
                                documentId: action.payload.documentId,
                                previewBlob: blob,
                            });
                        }),
                        catchError((error: any) => {
                            return of(
                                DocumentActions.getDocumentPreviewFail({
                                    error,
                                    documentId: action.payload.documentId,
                                })
                            );
                        })
                    )
            )
        )
    );

    deleteDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentActions.deleteDocument),
            withLatestFrom(this.store$.select(DocumentSelectors.selectAll)),
            mergeMap(([action, documents]) => {
                return this.restApiService.process(action).pipe(
                    map(() =>
                        DocumentActions.deleteDocumentSuccess({
                            ids: [
                                action.payload.documentId,
                                ...this.getFileNodeDescendantIds(
                                    action.payload.documentId,
                                    documents
                                ),
                            ],
                        })
                    ),
                    catchError((error: any) =>
                        of(DocumentActions.deleteDocumentFail({ error, originAction: action }))
                    )
                );
            })
        )
    );

    deleteManyDocuments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentActions.deleteManyDocuments),
            mergeMap((action) => {
                const actions = action.payload.ids.map((id) =>
                    DocumentActions.deleteDocument({
                        payload: { documentId: id },
                        options: action.options,
                    })
                );
                return actions;
            })
        )
    );

    copyDocuments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentActions.copyDocuments),
            mergeMap((action) => {
                this.snackbarService.show('Processing...', 2000);
                return this.restApiService.process(action).pipe(
                    map((response) =>
                        DocumentActions.copyDocumentsSuccess({
                            documents: response.payload,
                        })
                    ),
                    catchError((error: any) => of(DocumentActions.copyDocumentsFail({ error })))
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppState>,
        private snackbarService: SnackBarService,
        private http: HttpClient,
        private fileDownloadService: FileDownloadService
    ) {}

    getFileNodeDescendantIds(id: string, allNodes: FileNode[]): string[] {
        const ids: string[] = [];

        getDescendant(id);
        function getDescendant(nodeId: string) {
            allNodes.forEach((node) => {
                if (node.parentFolderId === nodeId) {
                    ids.push(node.id);
                    getDescendant(node.id);
                }
            });
        }
        return ids;
    }
}
