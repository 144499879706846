import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectCurrentProjectCalendar } from '../calendar/calendar.selectors';
import { ProjectSelectors } from '../project';
import { ProjectMemberSelectors } from '../project-member';
import { StateEntry } from '../state-entry';
import { adapter, LoadingStatus, State } from './allocation.store';
import { ExtendedProjectMemberAllocation, isCurrentAllocation } from './models';

export const selectCalendarEntry = createFeatureSelector<State>(StateEntry.Allocation);

export const selectLoadingStatus = createSelector(
    selectCalendarEntry,
    (state): LoadingStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
    })
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectCalendarEntry);

export const selectExtendedAllocations = createSelector(
    selectAll,
    ProjectMemberSelectors.selectAll,
    (allocations, projectMembers) =>
        allocations.map((a) => {
            const projectMember = projectMembers.find((pm) => pm.id === a.projectMemberId);
            return new ExtendedProjectMemberAllocation({
                ...a,
                projectId: projectMember?.projectId,
                siteMemberId: a.siteMemberId || projectMember?.siteMemberId,
            });
        })
);

export const selectAllocationsBySiteMemberId = (memberId: string) =>
    createSelector(
        selectExtendedAllocations,
        ProjectMemberSelectors.selectProjectMembersBySiteMemberId(memberId),
        (allocations, projectMembers) =>
            allocations.filter(
                (al) =>
                    al.siteMemberId === memberId ||
                    projectMembers.some((m) => m.id === al.projectMemberId)
            )
    );

export const selectAllocationsByProjectMemberId = (memberId: string) =>
    createSelector(selectExtendedAllocations, (allocations) =>
        allocations.filter((al) => al.projectMemberId === memberId)
    );

export const selectCurrentProjectCurrentAllocations = createSelector(
    selectExtendedAllocations,
    ProjectSelectors.selectCurrentProjectId,
    selectCurrentProjectCalendar,
    (allocations, projectId, calendar) =>
        allocations.filter((al) => al.projectId === projectId && isCurrentAllocation(al, calendar))
);
