import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import { deepClone } from 'fast-json-patch';
import {
    AsyncUpdateStatusDefault,
    AsyncUpdateStatusDefaultLoad,
    AsyncUpdateStatusDefaultLoadSuccess,
} from '../shared/async-update-status.store';
import { getRecordNewTextId } from '../shared/models/raid-shared.model';
import * as BacklogTaskActions from './backlog-task.actions';
import { adapter, initialState } from './backlog-task.store';
import { BacklogTask } from './models';

export const reducer = createReducer(
    initialState,

    on(BacklogTaskActions.getBacklogTaskSuccess, (state, payload) =>
        adapter.addOne(payload.task, adapter.removeOne(payload.task.id, state))
    ),

    on(BacklogTaskActions.loadBacklogTasksByContainerId, (state, action) => {
        const loadingStatusMap = state.loadingStatusByContainerId;
        const containerId = action.payload.id;

        return {
            ...state,
            loadingStatusByContainerId: {
                ...loadingStatusMap,
                [containerId]: {
                    ...(loadingStatusMap[containerId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoad,
                },
            },
        };
    }),

    on(BacklogTaskActions.loadBacklogTasksByContainerIdSuccess, (state, payload) => {
        const newState = adapter.addMany(
            payload.backlogTasks,
            adapter.removeMany(
                payload.backlogTasks.map((c) => c.id),
                state
            )
        );
        const loadingStatusMap = state.loadingStatusByContainerId;
        const containerId = payload.containerId;
        return {
            ...newState,
            loadingStatusByContainerId: {
                ...loadingStatusMap,
                [containerId]: {
                    ...(loadingStatusMap[containerId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                },
            },
        };
    }),

    on(BacklogTaskActions.loadBacklogTasksByContainerIdFail, (state, payload) => {
        const loadingStatusMap = state.loadingStatusByContainerId;
        const containerId = payload.containerId;

        return {
            ...state,
            loadingStatusByContainerId: {
                ...loadingStatusMap,
                [containerId]: {
                    ...(loadingStatusMap[containerId] ?? {}),
                    ...AsyncUpdateStatusDefault,
                    loadingFailed: true,
                    error: payload.error,
                },
            },
        };
    }),

    on(BacklogTaskActions.loadBacklogTasksBySiteId, (state, action) => {
        const loadingStatusMap = state.loadingStatusBySiteId;
        const siteId = action.payload.siteId;

        return {
            ...state,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoad,
                },
            },
        };
    }),

    on(BacklogTaskActions.loadBacklogTasksBySiteIdSuccess, (state, payload) => {
        const listContainerIds = payload.listContainerIds;
        const existingTasks = Object.values(state.entities).filter((t) =>
            listContainerIds.includes(t.listContainerId)
        );
        const newState = adapter.addMany(
            payload.backlogTasks,
            adapter.removeMany(
                existingTasks.map((s) => s.id),
                state
            )
        );
        const loadingStatusMap = state.loadingStatusBySiteId;
        const siteId = payload.siteId;
        const loadingByContainerIdMap = deepClone(state.loadingStatusByContainerId);
        listContainerIds.forEach(
            (id) =>
                (loadingByContainerIdMap[id] = {
                    ...(loadingByContainerIdMap[id] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                })
        );

        return {
            ...newState,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                },
            },
            loadingStatusByContainerId: loadingByContainerIdMap,
        };
    }),

    on(BacklogTaskActions.loadBacklogTasksBySiteIdFail, (state, payload) => {
        const loadingStatusMap = state.loadingStatusBySiteId;
        const siteId = payload.siteId;

        return {
            ...state,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefault,
                    loadingFailed: true,
                    error: payload.error,
                },
            },
        };
    }),

    on(BacklogTaskActions.addBacklogTask, (state, action) => {
        if (action.options?.optimistic) {
            const tasks = Object.values(state.entities).filter(
                (r) => action.payload.listContainerId === r.listContainerId
            );
            return adapter.addOne(
                new BacklogTask({
                    ...action.payload,
                    textId: getRecordNewTextId(
                        tasks.map((t) => t.textId),
                        'B'
                    ),
                }),
                state
            );
        }
        return { ...state, taskIsAdding: true };
    }),

    on(BacklogTaskActions.addBacklogTaskSuccess, (state, payload) => ({
        ...adapter.addOne(payload.backlogTask, state),
        taskIsAdding: false,
    })),

    on(BacklogTaskActions.addBacklogTaskFail, (state) => ({ ...state, taskIsAdding: false })),

    on(BacklogTaskActions.patchBacklogTask, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patched = getPatched(state.entities[action.payload.id], action.payload.patch);
        const update: Update<BacklogTask> = {
            id: action.payload.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(BacklogTaskActions.patchBacklogTaskSuccess, (state, payload) => {
        const patched = getPatched(state.entities[payload.id], payload.patch);
        const update: Update<BacklogTask> = {
            id: payload.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(BacklogTaskActions.patchBacklogTaskFail, (state) => ({ ...state })),

    on(BacklogTaskActions.deleteBacklogTask, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(BacklogTaskActions.deleteBacklogTaskSuccess, (state, payload) =>
        adapter.removeOne(payload.id, state)
    ),

    on(BacklogTaskActions.deleteBacklogTaskFail, (state) => ({ ...state }))
);
