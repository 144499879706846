import { EntitySubmission } from 'app/core/models/shared-models';
import { JsonProperty } from 'app/utils/json-mapper';
import { ApprovalState } from '../../approval-request';
import { Model } from '../../shared/models/base.model';

export class ChangeRequest extends Model {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('projectId')
    projectId: string = undefined;

    @JsonProperty('textId')
    textId: string = undefined;

    @JsonProperty('name')
    name: string = undefined;

    @JsonProperty('description')
    description?: string;

    @JsonProperty('notes')
    notes?: string;

    @JsonProperty('approvals')
    approvals?: ChangeRequestApproval[];

    @JsonProperty('date')
    date: Date = undefined;

    @JsonProperty('closed')
    closed?: boolean = undefined;

    @JsonProperty('impact')
    impact: ChangeRequestImpact;

    constructor(init?: Partial<ChangeRequest>) {
        super();
        Object.assign(this, init);
    }
}

export class ChangeRequestApprover extends Model {
    siteMemberId: string;
    name: string;
    imageDataUrl?: string;
    avatarColor?: string;

    constructor(init?: Partial<ChangeRequestApprover>) {
        super();
        Object.assign(this, init);
    }
}

export class ChangeRequestApproval {
    id: string;
    approverSiteMemberId?: string;
    externalApprover?: ChangeRequestExternalApprover;
    status?: ApprovalState;
    approvalDate?: Date;
    submission?: EntitySubmission;

    constructor(init?: Partial<ChangeRequestApproval>) {
        Object.assign(this, init);
    }
}

export interface ChangeRequestExternalApprover {
    name: string;
    documentId?: string;
}

export interface ChangeRequestImpact {
    isScope?: boolean;
    isSchedule?: boolean;
    isBudget?: boolean;
    budget?: number;
}

export enum ChangeRequestStatus {
    Open = 'Open',
    Approved = 'Approved',
    Rejected = 'Rejected',
    Pending = 'Pending',
}

export function getChangeRequestStatus(changeRequest: ChangeRequest): ChangeRequestStatus {
    if (!changeRequest.approvals?.length || changeRequest.approvals?.some((a) => !a.status)) {
        return ChangeRequestStatus.Open;
    }

    const approvalStatusList = changeRequest.approvals?.map((approval) => approval.status) || [];

    if (approvalStatusList.includes(ApprovalState.Pending)) return ChangeRequestStatus.Pending;
    if (approvalStatusList.includes(ApprovalState.Rejected)) return ChangeRequestStatus.Rejected;
    return ChangeRequestStatus.Approved;
}

export function changeRequestCanCanBeRemoved(request: ChangeRequest): boolean {
    return (
        getChangeRequestStatus(request) !== ChangeRequestStatus.Approved &&
        getChangeRequestStatus(request) !== ChangeRequestStatus.Rejected
    );
}
