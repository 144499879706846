import * as fromRouter from '@ngrx/router-store';
import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { AppOperationActions } from 'app/core/store/app-operation';
import { AccountReducer } from './account';
import { ProjectActionReducer } from './actions';
import { AllocationReducer } from './allocation';
import { AppOperationReducer } from './app-operation';
import { ActionTypes } from './app-operation/app-operation.actions';
import { ApprovalRequestReducer } from './approval-request';
import { BacklogTaskReducer } from './backlog-task';
import { BudgetTableReducer } from './budget-table';
import { CalendarReducer } from './calendar';
import { ChangeRequestReducer } from './change-request';
import { CommentReducer } from './comment';
import { CommunicationReducer } from './communication';
import { DecisionReducer } from './decision';
import { DocumentReducer } from './document';
import { ExecutiveDashboardReducer } from './executive-dashboard';
import { FolderReducer } from './folder';
import { GlTableSettingsReducer } from './gl-table-settings';
import { HandsontableSettingsReducer } from './handsontable-settings';
import { IssueReducer } from './issue';
import { KanbanBoardReducer } from './kanban';
import { LayoutReducer } from './layout';
import { getLocalStorageState, saveLocalStorageState } from './local-storage.functions';
import { MinuteReducer } from './minute';
import { NotificationReducer } from './notification';
import { PortfolioReducer } from './portfolio';
import { ProgramReducer } from './program';
import { ProgramMemberReducer } from './program-member';
import { ProgramRoleReducer } from './program-role';
import { ProgramStatusReducer } from './program-status-report';
import { ProjectReducer } from './project';
import { ProjectFieldReducer } from './project-field';
import { ProjectMemberReducer } from './project-member';
import { ProjectPhaseReducer } from './project-phase';
import { ListContainerReducer } from './project-phase-container';
import { ProjectRequestReducer } from './project-request';
import { ProjectRoleReducer } from './project-role';
import { ProjectStatusReducer } from './project-status-report';
import { ProjectTemplateReducer } from './project-template';
import { ReminderReducer } from './reminder';
import { SiteMemberReducer } from './resource';
import { RiskReducer } from './risks';
import { ScheduleTaskReducer } from './schedule-template';
import { isFailAction, triggersUndo } from './shared/fail-action';
import { OptimisticableAction } from './shared/optimisticable-action';
import { SiteReducer } from './site';
import { SiteRoleReducer } from './site-role';
import { SiteSettingsReducer } from './site-settings';
import { SprintReducer } from './sprint';
import { AppState, getInitialState } from './store';
import { SubscriptionReducer } from './subscription';
import { SubscriptionPlanReducer } from './subscription-plan';
import { TimesheetReducer } from './timesheet';
import { UserLogReducer } from './user-log';
import { VendorReducer } from './vendor';

export const rootReducerMap: ActionReducerMap<AppState> = {
    router: fromRouter.routerReducer,
    account: AccountReducer.reducer,
    layout: LayoutReducer.reducer,
    site: SiteReducer.reducer,
    program: ProgramReducer.reducer,
    project: ProjectReducer.reducer,
    document: DocumentReducer.reducer,
    appOperation: AppOperationReducer.reducer,
    subscriptionPlan: SubscriptionPlanReducer.reducer,
    subscription: SubscriptionReducer.reducer,
    changeRequest: ChangeRequestReducer.reducer,
    projectPhase: ProjectPhaseReducer.reducer,
    calendar: CalendarReducer.reducer,
    minute: MinuteReducer.reducer,
    projectStatusReport: ProjectStatusReducer.reducer,
    portfolio: PortfolioReducer.reducer,
    projectTemplate: ProjectTemplateReducer.reducer,
    communicationTable: CommunicationReducer.reducer,
    projectRequest: ProjectRequestReducer.reducer,
    siteMember: SiteMemberReducer.reducer,
    allocation: AllocationReducer.reducer,
    scheduleTask: ScheduleTaskReducer.reducer,
    budgetTemplate: BudgetTableReducer.reducer,
    projectField: ProjectFieldReducer.reducer,
    approvalRequest: ApprovalRequestReducer.reducer,
    reminder: ReminderReducer.reducer,
    timesheet: TimesheetReducer.reducer,
    kanban: KanbanBoardReducer.reducer,
    comment: CommentReducer.reducer,
    handsontableSettings: HandsontableSettingsReducer.reducer,
    siteRole: SiteRoleReducer.reducer,
    projectRole: ProjectRoleReducer.reducer,
    programRole: ProgramRoleReducer.reducer,
    projectMember: ProjectMemberReducer.reducer,
    programMember: ProgramMemberReducer.reducer,
    listContainer: ListContainerReducer.reducer,
    risk: RiskReducer.reducer,
    projectAction: ProjectActionReducer.reducer,
    issue: IssueReducer.reducer,
    decision: DecisionReducer.reducer,
    executiveDashboard: ExecutiveDashboardReducer.reducer,
    folder: FolderReducer.reducer,
    backlogTask: BacklogTaskReducer.reducer,
    sprint: SprintReducer.reducer,
    notification: NotificationReducer.reducer,
    userLog: UserLogReducer.reducer,
    siteSettings: SiteSettingsReducer.reducer,
    programStatusReport: ProgramStatusReducer.reducer,
    glTableSettings: GlTableSettingsReducer.reducer,
    vendor: VendorReducer.reducer,
};

export const metaReducers: MetaReducer<AppState>[] = [
    // logger,
    undoableMetaReducer,
];

// Meta reducer intended to log all actions to the console
export function logger(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
    return (state: AppState, action: Action): AppState => {
        let groupHeader = action.type;

        const asOptimisticable = action as OptimisticableAction;
        if (asOptimisticable.options && asOptimisticable.options.optimistic) {
            groupHeader = groupHeader + ' (optimistic)';
        } else if (isFailAction(action) && triggersUndo(action)) {
            groupHeader = groupHeader + ' (undo)';
        }

        console.groupCollapsed(groupHeader);
        const nextState = reducer(state, action);
        console.log(`%c prev state`, `color: #9E9E9E; font-weight: bold`, state);
        console.log(`%c action`, `color: #03A9F4; font-weight: bold`, action);
        console.log(`%c next state`, `color: #4CAF50; font-weight: bold`, nextState);
        console.groupEnd();
        return nextState;
    };
}

// Meta reducer intended to provide ability to undo a failed optimistic action

const UndoableActionBufferSize = 300;
const localStorageEnabled = false;

export function undoableMetaReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
    let executedActions: Action[] = [];
    let lastSavedState: AppState;
    const bufferSize = UndoableActionBufferSize;

    const rebuildStateWithoutGivenAction = (actionToCancel: Action): AppState => {
        let newState = lastSavedState;
        executedActions = executedActions.filter((act) => act !== actionToCancel);
        executedActions.forEach((executedAction) => (newState = reducer(newState, executedAction)));
        return newState;
    };

    const shiftBufferWhenLimitReached = () => {
        if (executedActions.length < bufferSize + 1) return;

        const firstAction = executedActions[0];
        lastSavedState = reducer(lastSavedState, firstAction);
        executedActions = executedActions.slice(1, bufferSize + 1);
    };

    return (state: AppState, action: Action): AppState => {
        if (action.type === ActionTypes.LogOut) {
            return getInitialState();
        }

        if (isFailAction(action) && triggersUndo(action)) {
            const actionToUndo = action.originAction;
            const rebuiltState = rebuildStateWithoutGivenAction(actionToUndo);
            return reducer(rebuiltState, action);
        }

        executedActions.push(action);
        shiftBufferWhenLimitReached();

        const nextState: AppState = reducer(state, action);

        if (localStorageEnabled && action.type === AppOperationActions.ActionTypes.SetInit) {
            const storageValue = getLocalStorageState();
            if (storageValue) return { ...storageValue, router: nextState.router };
        }

        if (
            localStorageEnabled &&
            action.type === AppOperationActions.ActionTypes.SetLocalStorage
        ) {
            saveLocalStorageState(nextState);
        }
        return nextState;
    };
}
