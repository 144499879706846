import { Action, createAction, props } from '@ngrx/store';
import { Calendar } from 'app/core/models/calendar';
import { ImportSystemTemplatePayload } from 'app/core/models/shared-models';
import { OptimisticableActionOptions } from 'app/core/store/shared/optimisticable-action';
import {
    AddCalendarPayload,
    CalendarIdPayload,
    ChangeProjectCalendarTemplatePayload,
    LoadCalendarByProjectIdPayload,
    LoadCalendarsBySiteIdPayload,
    PatchCalendarPayload,
    UpdateCalendarPayload,
} from './calendar.payloads';

// Get Completix calendars actions

export const loadCompletixCalendarTemplates = createAction(
    '[Calendar] Load Compeltix Calendar templates'
);

export const loadCompletixCalendarTemplatesSuccess = createAction(
    '[Calendar] Load Compeltix Calendar templates success',
    props<{ calendars: Calendar[] }>()
);

export const loadCompletixCalendarTemplatesFail = createAction(
    '[Calendar] Load Compeltix Calendar templates fail',
    props<{ error: any }>()
);

// Get calendars by Site id actions

export const loadCompletixCalendarTemplatesBySiteId = createAction(
    '[Calendar] Load Compeltix Calendar templates by site',
    props<{ payload: LoadCalendarsBySiteIdPayload }>()
);

export const loadCalendarsBySiteId = createAction(
    '[Calendar] Load by site',
    props<{ payload: LoadCalendarsBySiteIdPayload }>()
);

export const loadCalendarsSuccess = createAction(
    '[Calendar] Load by site success',
    props<{ calendars: Calendar[] }>()
);

export const loadCalendarsFail = createAction(
    '[Calendar] Load by site fail',
    props<{ error: any }>()
);

// Get calendar by Project id actions

export const loadCalendarByProjectId = createAction(
    '[Calendar] Load by project',
    props<{ payload: LoadCalendarByProjectIdPayload }>()
);

export const loadCalendarByProjectIdSuccess = createAction(
    '[Calendar] Load by project success',
    props<{ calendar: Calendar }>()
);

export const loadCalendarByProjectIdFail = createAction(
    '[Calendar] Load by project fail',
    props<{ error: any }>()
);

// Add calendar actions

export const importCompletixCalendarTemplate = createAction(
    '[Calendar] Import Completix template',
    props<{ payload: ImportSystemTemplatePayload }>()
);

export const addCalendar = createAction('[Calendar] Add', props<{ payload: AddCalendarPayload }>());

export const addCalendarSuccess = createAction(
    '[Calendar] Add success',
    props<{ calendar: Calendar }>()
);

export const addCalendarFail = createAction('[Calendar] Add fail', props<{ error: any }>());

// Update calendar actions

export const updateCalendar = createAction(
    '[Calendar] Update',
    props<{ payload: UpdateCalendarPayload; options: OptimisticableActionOptions }>()
);

export const patchCalendar = createAction(
    '[Calendar] Patch',
    props<{ payload: PatchCalendarPayload; options: OptimisticableActionOptions }>()
);

export const patchCalendarSuccess = createAction(
    '[Calendar] Patch success',
    props<PatchCalendarPayload>()
);

export const patchCalendarFail = createAction(
    '[Calendar] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Clone calendar actions

export const cloneCalendar = createAction(
    '[Calendar] Clone',
    props<{ payload: CalendarIdPayload }>()
);

// Delete calendar actions

export const deleteCalendar = createAction(
    '[Calendar] Delete',
    props<{ payload: CalendarIdPayload; options: OptimisticableActionOptions }>()
);

export const deleteCalendarSuccess = createAction(
    '[Calendar] Delete success',
    props<CalendarIdPayload>()
);

export const deleteCalendarFail = createAction(
    '[Calendar] Delete fail',
    props<{ error: any; originAction: Action }>()
);

// Change calendar template

export const changeCalendarTemplate = createAction(
    '[Calendar] Change calendar template',
    props<{ payload: ChangeProjectCalendarTemplatePayload }>()
);

export const changeCalendarTemplateSuccess = createAction(
    '[Calendar] Change calendar template success',
    props<{ calendar: Calendar }>()
);

export const changeCalendarTemplateFail = createAction(
    '[Calendar] Change calendar template fail',
    props<{ error: any }>()
);
