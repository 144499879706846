import { JsonProperty } from 'app/utils/json-mapper';

export class Card {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('last4')
    last4: string = undefined;

    @JsonProperty('name')
    name: string = undefined;

    @JsonProperty('expYear')
    expYear: number = undefined;

    @JsonProperty('expMonth')
    expMonth: number = undefined;

    @JsonProperty('brand')
    brand: string = undefined;

    constructor(init?: Partial<Card>) {
        Object.assign(this, init);
    }
}
