import { TaskDurationOptions } from 'app/services/schedule';
import { ColumnSettings } from 'handsontable/settings';
import {
    GlMemberRenderData,
    GlMemberRendererSettings,
    GlStatusRenderEditorData,
    TextStyle,
} from './grid-lab.types';

export interface ColumnShape extends ColumnSettings {
    /**
     * Column number used for ordering and other reasons (should be unique)
     */
    glId: number;

    /**
     * Soure of column values (e.g. referenced external object, internal table record).
     * Defines where and how to get values for cells
     */
    glSourceProperty?: string;

    /**
     * Type of cell values.
     * This type defines how cell values will be generated (for autogenerated ones), modified by
     * user and rendered
     */
    glType?: GlColumnType;

    /**
     * Icon names of checkbox/button renderer
     */
    glMatIconName?: string;
    glSvgIconHtml?: string;
    glMatIconNameDisabled?: string;
    glMatIconStyleClass?: string;
    glMatIconCustomClass?: string;
    buttonType?: string;

    /**
     * Identifier of a calculated column provided by a custom plugin. Plugins may provide a set of
     * plugin-specific calculated columns which can be accessed via this unique identifier.
     */
    glCalculatedColumnType?: string;

    /**
     * Column maximum width
     */
    glMaxWidth?: number;

    /**
     * Column minimum width
     */
    glMinWidth?: number;

    /** Necessary data for member renderer */
    glMemberRendererData?: GlMemberRenderData;

    /** Member renderer settings */
    glMemberRendererSettings?: GlMemberRendererSettings;

    /** Necessary data for status renderer */
    glStatusRendererEditorData?: GlStatusRenderEditorData;

    glStyle?: TextStyle;
    glTitle?: string;
    glTooltip?: string;

    /** Ignores bold, italic, strikethroug and other styles. Applies only colors. */
    glLimitedTextStyling?: boolean;

    /**
     * CtxNumericRenderer setting.
     * Prefix to add to the numeric values while rendering.
     * For example, `$` value will prefix cell values with dollar sign `$15`.
     */
    glUnitPrefix?: string;

    /**
     * CtxNumericRenderer setting.
     * Postfix to add to the numeric values while rendering.
     * For example, `%` will postfix cell values with percent sign `85%`.
     */
    glUnitPostfix?: string;

    /**
     * CtxNumericRenderer setting.
     * Sets the number rendering behavior when the cell value is null or undefined.
     * If true, renders `0`. If false (default), renders empty cell.
     */
    glTreatEmptyAsZero?: boolean;

    /**
     * CtxNumericEditor setting
     * The entered numeric value will be rounded to the given precision.
     * For example, if user enters `1.235` and the setting is 2, the entered value
     * will be rounded as `1.24`.
     */
    glMaxAllowedFractionDigits?: number;

    /**
     * CtxNumericEditor setting
     * The minimum allowed numeric value.
     * For example, if user enters a value that is less than the minimum,
     * the editor output value will be the minimum.
     */
    glMinAllowedValue?: number;

    /**
     * CtxNumericEditor setting
     * The maximum allowed numeric value.
     * For example, if user enters a value that is greater than the maximum,
     * the editor output value will be the maximum.
     */
    glMaxAllowedValue?: number;

    glDurationFormatOptions?: TaskDurationOptions;
    glDateFormatOptions?: DateFormatOptions;
    glIsCellReadonly?: (obj?: any) => boolean;
}

export enum GlColumnType {
    TextNumber = 'text/number',
    Number = 'number',
    Contact = 'contact',
    Dropdown = 'dropdown',
    Date = 'date',
    Duration = 'duration',
    Checkbox = 'checkbox',
    Auto = 'auto',
    Reminder = 'reminder',
    Button = 'button',
    Member = 'member',
    MultipleMembers = 'multiple members',
    Status = 'status',
    LongText = 'LongText',
}

export const glColumnTypesWithWrapedValue = [GlColumnType.Member];

export interface DateFormatOptions {
    showTime?: boolean;
}
