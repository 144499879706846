import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EntityPatchSuccessResponse } from 'app/core/services/rest-api/features/patch.response';
import { SnackBarService } from 'app/services/snack-bar/snack-bar.service';
import { getPatch } from 'app/utils/json-patch';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import {
    catchError,
    concatMap,
    map,
    mergeMap,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { ListContainerSelectors } from '.';
import { AppStore } from '..';
import { RestApiService } from '../../services/rest-api';
import { DocumentActions, FileNode } from '../document';
import { GlTableSettings, GlTableSettingsActions } from '../gl-table-settings';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import { OptimisticDefaults } from '../shared/optimisticable-action';
import * as ListContainerActions from './list-container.actions';
import { ListContainer } from './models';

@Injectable()
export class ListContainerEffects {
    loadSystemListContainers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ListContainerActions.loadSystemListContainers),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) =>
                        response.payload.map((dto) => Model.createFromDto(ListContainer, dto))
                    ),
                    map((containers: ListContainer[]) => {
                        const loadTableSettingsActions: Action[] = containers
                            .filter((c) => c.tableView)
                            .map((c) =>
                                GlTableSettingsActions.loadGlTableSettingsByIdSuccess({
                                    glTableSettings: c.tableView,
                                })
                            );
                        loadTableSettingsActions.forEach((action) => this.store$.dispatch(action));
                        return ListContainerActions.loadSystemListContainersSuccess({
                            containers,
                        });
                    }),
                    catchError((error) =>
                        of(
                            ListContainerActions.loadSystemListContainersFail({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    loadSystemListContainersBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ListContainerActions.loadSystemListContainersBySiteId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) =>
                        response.payload.map((dto) => Model.createFromDto(ListContainer, dto))
                    ),
                    map((containers: ListContainer[]) => {
                        const loadTableSettingsActions: Action[] = containers
                            .filter((c) => c.tableView)
                            .map((c) =>
                                GlTableSettingsActions.loadGlTableSettingsByIdSuccess({
                                    glTableSettings: c.tableView,
                                })
                            );
                        loadTableSettingsActions.forEach((action) => this.store$.dispatch(action));
                        return ListContainerActions.loadSystemListContainersBySiteIdSuccess({
                            containers,
                            siteId: action.payload.siteId,
                        });
                    }),
                    catchError((error) =>
                        of(
                            ListContainerActions.loadSystemListContainersFail({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    loadListContainersBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ListContainerActions.loadListContainersBySiteId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) =>
                        response.payload.map((dto) => Model.createFromDto(ListContainer, dto))
                    ),
                    map((containers) => {
                        const loadTableSettingsActions: Action[] = containers
                            .filter((c) => c.tableView)
                            .map((c) =>
                                GlTableSettingsActions.loadGlTableSettingsByIdSuccess({
                                    glTableSettings: c.tableView,
                                })
                            );
                        loadTableSettingsActions.forEach((action) => this.store$.dispatch(action));
                        return ListContainerActions.loadListContainersBySiteIdSuccess({
                            containers,
                            siteId: action.payload.siteId,
                        });
                    }),
                    catchError((error) =>
                        of(
                            ListContainerActions.loadListContainersBySiteIdFail({
                                error,
                                siteId: action.payload.siteId,
                            })
                        )
                    )
                )
            )
        )
    );

    loadListContainersByProjectId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ListContainerActions.loadListContainersByProjectId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) =>
                        response.payload.map((dto) => Model.createFromDto(ListContainer, dto))
                    ),
                    map((containers) => {
                        const loadTableSettingsActions: Action[] = containers
                            .filter((c) => c.tableView)
                            .map((c) =>
                                GlTableSettingsActions.loadGlTableSettingsByIdSuccess({
                                    glTableSettings: c.tableView,
                                })
                            );
                        loadTableSettingsActions.forEach((action) => this.store$.dispatch(action));
                        return ListContainerActions.loadListContainersByProjectIdSuccess({
                            containers,
                            projectId: action.payload.projectId,
                        });
                    }),
                    catchError((error) =>
                        of(
                            ListContainerActions.loadListContainersByProjectIdFail({
                                error,
                                projectId: action.payload.projectId,
                            })
                        )
                    )
                )
            )
        )
    );

    addListContainer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ListContainerActions.addListContainer),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    switchMap((response: CommandResult) =>
                        of(
                            ListContainerActions.addListContainerSuccess({
                                container: Model.createFromDto(ListContainer, response.payload),
                            }),
                            GlTableSettingsActions.loadGlTableSettingsByIdSuccess({
                                glTableSettings: Model.createFromDto(
                                    GlTableSettings,
                                    response.payload.tableView
                                ),
                            })
                        )
                    ),
                    catchError((error) => of(ListContainerActions.addListContainerFail({ error })))
                )
            )
        )
    );

    importSystemListContainer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ListContainerActions.importSystemListContainer),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Importing a template...');
                return this.restApiService.process(action).pipe(
                    tap(() => this.snackBarService.hide()),
                    switchMap((response: CommandResult) => {
                        const container = Model.createFromDto(
                            ListContainer,
                            response.payload.listContainer
                        );
                        const documents =
                            response.payload.documents?.map((dto) =>
                                Model.createFromDto(FileNode, dto)
                            ) ?? [];
                        return [
                            ListContainerActions.addListContainerSuccess({
                                container,
                            }),
                            GlTableSettingsActions.loadGlTableSettingsByIdSuccess({
                                glTableSettings: Model.createFromDto(
                                    GlTableSettings,
                                    container.tableView
                                ),
                            }),
                            DocumentActions.addManyDocumentsSuccess({ documents }),
                        ];
                    }),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(ListContainerActions.addListContainerFail({ error }));
                    })
                );
            })
        )
    );

    updateListContainer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ListContainerActions.updateListContainer),
            withLatestFrom(this.store$.select(ListContainerSelectors.selectAll)),
            mergeMap(([action, containers]) => {
                const container = containers.find((c) => c.id === action.payload.id);
                const patch = getPatch(container, action.payload.changes);
                if (!patch?.length) return of(okEmptyAction());
                return of(
                    ListContainerActions.patchListContainer({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchListContainer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ListContainerActions.patchListContainer),
            concatMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: EntityPatchSuccessResponse) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ListContainerActions.patchListContainerSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) =>
                        of(
                            ListContainerActions.patchListContainerFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteListContainer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ListContainerActions.deleteListContainer),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return ListContainerActions.deleteListContainerSuccess({
                            id: action.payload.id,
                        });
                    }),
                    catchError((error) =>
                        of(
                            ListContainerActions.deleteListContainerFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    changeListContainerTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ListContainerActions.changeListContainerTemplate),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Changing template...');
                return this.restApiService.process(action).pipe(
                    switchMap((response: CommandResult) => {
                        this.snackBarService.hide();
                        return [
                            ListContainerActions.replaceListContainer({
                                container: Model.createFromDto(ListContainer, response.payload),
                            }),
                            GlTableSettingsActions.loadGlTableSettingsByIdSuccess({
                                glTableSettings: Model.createFromDto(
                                    GlTableSettings,
                                    response.payload.tableView
                                ),
                            }),
                        ];
                    }),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(ListContainerActions.changeListContainerTemplateFail({ error }));
                    })
                );
            })
        )
    );

    setListContainerAsDefault$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ListContainerActions.setListContainerAsDefault),
            withLatestFrom(this.store$.select(ListContainerSelectors.selectAll)),
            switchMap(([action, listContainers]) => {
                const container = listContainers.find((c) => c.id === action.containerId);
                const currentDefault = listContainers.find(
                    (c) =>
                        c.containerType === container.containerType &&
                        c.scope.level === container.scope.level &&
                        c.isTemplate === container.isTemplate &&
                        c.isDefault
                );
                return [
                    ListContainerActions.updateListContainer({
                        payload: {
                            id: container.id,
                            changes: { isDefault: true, isInitialTemplate: true },
                        },
                        options: OptimisticDefaults,
                    }),
                    ListContainerActions.updateListContainer({
                        payload: { id: currentDefault.id, changes: { isDefault: false } },
                        options: OptimisticDefaults,
                    }),
                ];
            })
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>,
        private snackBarService: SnackBarService
    ) {}
}
