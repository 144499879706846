import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StateEntry } from '../state-entry';
import { adapter, LoadingStatus, State } from './project-member.store';

export const selectProjectMemberEntry = createFeatureSelector<State>(StateEntry.ProjectMember);

export const selectLoadingStatus = createSelector(
    selectProjectMemberEntry,
    (state): LoadingStatus => ({
        serverRequestInProgress: state.serverRequestInProgress,
        loaded: state.loaded,
    })
);

export const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal,
} = adapter.getSelectors(selectProjectMemberEntry);

export const selectAllLoaded = createSelector(selectAll, selectLoadingStatus, (members, status) =>
    status?.loaded ? members : null
);

export const selectProjectMembersBySiteMemberId = (id: string) =>
    createSelector(selectAll, (members) => members.filter((m) => m.siteMemberId === id));
