import {
    AgilePermissions,
    ChangeRequestPermissions,
    CreateDeletePermissions,
    CrudAssignedPermissions,
    CrudPermissions,
    DecisionPermissions,
    DocumentPermissions,
    ExecutivePermissions,
    GatingPermissions,
    MinutePermissions,
    OwnDocumentPermissions,
    PortfolioPermissions,
    ProgramPermissions,
    ProjectBudgetPermissions,
    ProjectCommunicationPermissions,
    ProjectMemberPermisions,
    ProjectMemberWorkPermissions,
    ProjectPageLockPermissions,
    ProjectPermissions,
    ProjectRaciPermissions,
    ProjectRequestPermissions,
    ProjectSchedulePermissions,
    ProjectStatusPermissions,
    RaidPermissions,
    ReadUpdatePermissions,
    SiteMemberPermissions,
    SitePermissions,
    TemplatePagePermissions,
    TemplatePermissions,
    TimesheetPermissions,
} from './shared-permissions.model';

const defaultCrudPermissions: Required<CrudPermissions> = {
    create: null,
    read: null,
    update: null,
    delete: null,
};

const defaultReadUpdatePermissions: Required<ReadUpdatePermissions> = {
    read: null,
    update: null,
};

const defaultProjectMemberWorkPermissions: Required<ProjectMemberWorkPermissions> = {
    ...defaultReadUpdatePermissions,
    readDetails: null,
};

const defaultProjectMemberPermissions: Required<ProjectMemberPermisions> = {
    create: null,
    read: null,
    delete: null,
    effort: defaultProjectMemberWorkPermissions,
    name: defaultReadUpdatePermissions,
    status: defaultReadUpdatePermissions,
    roleTitle: defaultReadUpdatePermissions,
    additionalSponsorTitle: defaultReadUpdatePermissions,
    additionalManagerTitle: defaultReadUpdatePermissions,
    role: defaultReadUpdatePermissions,
    allocation: defaultProjectMemberWorkPermissions,
    rate: defaultReadUpdatePermissions,
    readLocation: null,
    inviteOutsideUser: null,
};

const defaultProjectRaciPermissions: Required<ProjectRaciPermissions> = {
    create: null,
    read: null,
    update: null,
};

const defaultGatingPermissions: Required<GatingPermissions> = {
    approve: null,
    advance: null,
    submit: null,
    changeTemplate: null,
    create: null,
    read: null,
    update: null,
    delete: null,
    deliverables: defaultCrudPermissions,
};

const defaultCrudAssignedPermissions: Required<CrudAssignedPermissions> = {
    ...defaultCrudPermissions,
    assignedRead: null,
    assignedUpdate: null,
    assignedDelete: null,
};

const defaultDecisionPermissions: Required<DecisionPermissions> = {
    ...defaultCrudAssignedPermissions,
    deleteApprovedRejected: null,
};

const defaultRaidPermissions: Required<RaidPermissions> = {
    risks: defaultCrudAssignedPermissions,
    actions: defaultCrudAssignedPermissions,
    issues: defaultCrudAssignedPermissions,
    decisions: defaultDecisionPermissions,
};

const defaultProjectPageLockPermissions: Required<ProjectPageLockPermissions> = {
    home: null,
    team: null,
    status: null,
    schedule: null,
    backlog: null,
    sprints: null,
    budget: null,
    risks: null,
    actions: null,
    issues: null,
    decisions: null,
    gating: null,
    changeRequests: null,
    vendors: null,
    minutes: null,
    documents: null,
    settings: null,
};

const defaultDocumentPermissions: Required<DocumentPermissions> = {
    ...defaultCrudPermissions,
    lockDocuments: null,
    readLockedDocuments: null,
};

const defaultOwnDocumentPermissions: Required<OwnDocumentPermissions> = {
    read: null,
    update: null,
    delete: null,
};

const defaultProjectCommunicationPermissions: Required<ProjectCommunicationPermissions> = {
    read: null,
    update: null,
    changeTemplate: null,
};

const defaultProjectBudgetPermissions: Required<ProjectBudgetPermissions> = {
    ...defaultReadUpdatePermissions,
    capexOpexEnabled: null,
};

const defaultProjectStatusPermissions: Required<ProjectStatusPermissions> = {
    create: null,
    read: null,
    update: null,
    delete: null,
    lockBudget: null,
    lockTimeline: null,
    readLockedBudget: null,
    readLockedTimeline: null,
};

const defaultProjectSchedulePermissions: Required<ProjectSchedulePermissions> = {
    ...defaultReadUpdatePermissions,
    changeTemplate: null,
    tasks: defaultCrudAssignedPermissions,
};

const defaultChangeRequestPermissions: Required<ChangeRequestPermissions> = {
    ...defaultCrudPermissions,
    approve: null,
    submitApproval: null,
    reOpen: null,
    budgetImpactRead: null,
};

export const defaultAgilePermissions: Required<AgilePermissions> = {
    backlogTasks: defaultCrudAssignedPermissions,
    sprintTasks: defaultCrudAssignedPermissions,
    sprints: defaultCrudPermissions,
};

export const defaultMinutePermissions: Required<MinutePermissions> = {
    ...defaultCrudAssignedPermissions,
    lockMinute: null,
    readLockedMinutes: null,
    allowAddTopicToRaid: null,
};

// Default Project permissions
export const defaultProjectPermissions: Required<ProjectPermissions> = {
    read: null,
    update: null,
    delete: null,
    archive: null,
    unArchive: null,
    assignManager: null,
    lockHomePageBudget: null,
    readLockedHomePageBudget: null,
    approveTimesheets: null,
    readProjectLog: null,
    home: { showPage: null, readPortfolios: null },
    priority: defaultReadUpdatePermissions,
    approvedBudget: defaultReadUpdatePermissions,
    startEndDates: defaultReadUpdatePermissions,
    schedule: defaultProjectSchedulePermissions,
    agile: defaultAgilePermissions,
    roles: defaultReadUpdatePermissions,
    status: defaultProjectStatusPermissions,
    budget: defaultProjectBudgetPermissions,
    raid: defaultRaidPermissions,
    members: defaultProjectMemberPermissions,
    raci: defaultProjectRaciPermissions,
    changeRequests: defaultChangeRequestPermissions,
    vendors: defaultCrudPermissions,
    minutes: defaultMinutePermissions,
    gating: defaultGatingPermissions,
    documents: defaultDocumentPermissions,
    ownDocuments: defaultOwnDocumentPermissions,
    settings: defaultReadUpdatePermissions,
    communication: defaultProjectCommunicationPermissions,
    tabLockToggle: defaultProjectPageLockPermissions,
    readLockedPage: defaultProjectPageLockPermissions,
    updateState: null,
    readSummaryPage: null,
};

// Default Program permissions
export const defaultProgramPermissions: Required<ProgramPermissions> = {
    read: null,
    update: null,
    delete: null,
    archive: null,
    unArchive: null,
    assignManager: null,
    unassignManager: null,
    readProgramTeam: null,
    createProjects: null,
    readSummaryPage: null,
    readProgramLogs: null,
    documents: defaultDocumentPermissions,
    ownDocuments: defaultOwnDocumentPermissions,
    projects: defaultProjectPermissions,
    statusReport: defaultCrudPermissions,
};

export const defaultTemplatePagePermissions: Required<TemplatePagePermissions> = {
    ...defaultCrudPermissions,
    showPage: null,
};

// Default Template permissions
export const defaultTemplatePermissions: Required<TemplatePermissions> = {
    schedules: defaultTemplatePagePermissions,
    documents: defaultTemplatePagePermissions,
    budgets: defaultTemplatePagePermissions,
    projects: defaultTemplatePagePermissions,
    calendars: defaultTemplatePagePermissions,
    gating: defaultTemplatePagePermissions,
    communication: defaultTemplatePagePermissions,
};

// Default Executive permissions
export const defaultExecutivePermissions: Required<ExecutivePermissions> = {
    readApprovals: null,
    readPortfolios: null,
    readPrograms: null,
    readProjects: null,
    readRequests: null,
    readResources: null,
    readSiteMetrics: null,
    readClosedProjectsConclusion: null,
    readFinancials: null,
};

// Default ProjectRequest permissions
export const defaultProjectRequestPermissions: Required<ProjectRequestPermissions> = {
    readOwn: null,
    updateOwn: null,
    deleteOwn: null,
    create: null,
    read: null,
    update: null,
    delete: null,
};

export const defaultTimesheetPermissions: Required<TimesheetPermissions> = {
    read: null,
    update: null,
    submit: null,
    reOpen: null,
};

export const defaultSiteMemberPermissions: Required<SiteMemberPermissions> = {
    create: null,
    read: null,
    readSiteMembersPage: null,
    delete: null,
    name: defaultReadUpdatePermissions,
    siteRate: defaultReadUpdatePermissions,
    siteRole: defaultReadUpdatePermissions,
    siteRoleTitle: defaultReadUpdatePermissions,
    additionalPermissions: defaultReadUpdatePermissions,
    subscription: defaultReadUpdatePermissions,
    teamDept: defaultReadUpdatePermissions,
    timesheets: defaultTimesheetPermissions,
    reportsTo: defaultReadUpdatePermissions,
    location: defaultReadUpdatePermissions,
    manualAllocation: defaultCrudPermissions,
};

export const defaultCreateDeletePermissions: Required<CreateDeletePermissions> = {
    create: null,
    delete: null,
};

export const defaultPortfolioPermissions: Required<PortfolioPermissions> = {
    ...defaultCrudPermissions,
    projects: defaultCreateDeletePermissions,
    updateApprovedFyBudget: null,
};

// Default Site permissions
export const defaultSitePermissions: Required<SitePermissions> = {
    read: null,
    update: null,
    delete: null,
    createProjects: null,
    createPrograms: null,
    readSiteLogs: null,
    portfolios: defaultPortfolioPermissions,
    projects: defaultProjectPermissions,
    programs: defaultProgramPermissions,
    projectRequests: defaultProjectRequestPermissions,
    billing: defaultReadUpdatePermissions,
    settings: defaultReadUpdatePermissions,
    roles: defaultCrudPermissions,
    members: defaultSiteMemberPermissions,
    templates: defaultTemplatePermissions,
    executive: defaultExecutivePermissions,
    approvals: defaultReadUpdatePermissions,
    changeSubscriptionType: null,
    transferProjectToProgram: null,
    myTimesheet: defaultTimesheetPermissions,
    overviewPageRead: null,
    approvedFyBudgetUpdate: null,
    financials: defaultReadUpdatePermissions,
};
