import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EntityPatchSuccessResponse } from 'app/core/services/rest-api/features/patch.response';
import { getPatch } from 'app/utils/json-patch';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { FolderSelectors } from '.';
import { AppStore } from '..';
import { RestApiService } from '../../services/rest-api';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import * as FolderActions from './folder.actions';
import { Folder } from './models';

@Injectable()
export class FolderEffects {
    loadFolders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FolderActions.loadFoldersByTag),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        response.payload.map((dto) => Model.createFromDto(Folder, dto))
                    ),
                    map((folders) => FolderActions.loadFoldersSuccess({ folders })),
                    catchError((error) => of(FolderActions.loadFoldersFail({ error })))
                )
            )
        )
    );

    addFolder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FolderActions.addFolder),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) =>
                        FolderActions.addFolderSuccess({
                            folder: Model.createFromDto(Folder, response.payload),
                        })
                    ),
                    catchError((error) => of(FolderActions.addFolderFail({ error })))
                )
            )
        )
    );

    updateFolder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FolderActions.updateFolder),
            withLatestFrom(this.store$.select(FolderSelectors.selectAll)),
            mergeMap(([action, folders]) => {
                const folderToPatch = folders.find((folder) => folder.id === action.payload.id);
                const patch = getPatch(folderToPatch, action.payload.changes);
                return of(
                    FolderActions.patchFolder({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchFolder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FolderActions.patchFolder),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: EntityPatchSuccessResponse) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return FolderActions.patchFolderSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((error) =>
                        of(FolderActions.patchFolderFail({ error, originAction: action }))
                    )
                )
            )
        )
    );

    deleteFolder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FolderActions.deleteFolder),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map(() => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return FolderActions.deleteFolderSuccess({
                            id: action.payload.id,
                        });
                    }),
                    catchError((error) =>
                        of(
                            FolderActions.deleteFolderFail({
                                error,
                                originAction: action,
                            })
                        )
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>
    ) {}
}
