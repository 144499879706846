import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import { deepClone } from 'fast-json-patch';
import * as MinuteActions from './minute.actions';
import { adapter, initialState } from './minute.store';
import { Minute } from './models';

export const reducer = createReducer(
    initialState,

    on(MinuteActions.loadMinutesByProjectId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(MinuteActions.loadMinutesByProjectIdSuccess, (state, payload) => {
        const newState = adapter.addMany(payload.minutes, adapter.removeAll(state));
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(MinuteActions.loadMinutesByProjectIdFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(MinuteActions.loadMinutesBySiteId, (state) => ({
        ...state,
        serverRequestInProgress: true,
    })),

    on(MinuteActions.loadMinutesBySiteIdSuccess, (state, payload) => {
        const newState = adapter.addMany(payload.minutes, adapter.removeAll(state));
        return { ...newState, serverRequestInProgress: false, loaded: true };
    }),

    on(MinuteActions.loadMinutesBySiteIdFail, (state) => ({
        ...state,
        serverRequestInProgress: false,
    })),

    on(MinuteActions.addMinuteSuccess, (state, payload) => adapter.addOne(payload.minute, state)),

    on(MinuteActions.addMinuteFail, (state) => state),

    on(MinuteActions.patchMinute, (state, action) => {
        if (!action.options?.optimistic) return state;
        const minute = state.entities[action.payload.id];
        const patched = getPatched(minute, action.payload.patch);
        const update: Update<Minute> = {
            id: minute.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(MinuteActions.patchMinuteSuccess, (state, payload) => {
        const minute = state.entities[payload.id];
        const patched = getPatched(minute, payload.patch);
        const update: Update<Minute> = {
            id: minute.id,
            changes: patched,
        };
        return adapter.updateOne(update, state);
    }),

    on(MinuteActions.patchMinuteFail, (state) => state),

    on(MinuteActions.deleteMinute, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(MinuteActions.deleteMinuteSuccess, (state, payload) => adapter.removeOne(payload.id, state)),

    on(MinuteActions.deleteMinuteFail, (state) => state),

    on(MinuteActions.updateMinuteFolderExpandedIds, (state, action) => {
        const expandedIdsMap = deepClone(state.expandedFolderIdsMap);
        expandedIdsMap[action.payload.projectId] = action.payload.expandedIds;
        return { ...state, expandedFolderIdsMap: expandedIdsMap };
    }),

    on(MinuteActions.updateMinuteFolderOpenedId, (state, action) => {
        const openedIdMap = deepClone(state.openedFolderIdMap);
        openedIdMap[action.payload.projectId] = action.payload.openedId;
        return { ...state, openedFolderIdMap: openedIdMap };
    })
);
