import { Action, createAction, props } from '@ngrx/store';
import { IdPayload } from 'app/core/store/shared/models/shared-payloads';
import { OptimisticableActionOptions } from '../shared/optimisticable-action';
import {
    PatchGlTableSettingsPayload,
    UpdateGlTableSettingsPayload,
} from './gl-table-settings.payloads';
import { GlTableSettings } from './models';

// Load GlTableSettings actions

export const loadGlTableSettingsById = createAction(
    '[GlTableSettings] Load GlTableSettings',
    props<{ payload: IdPayload }>()
);

export const loadGlTableSettingsByIdSuccess = createAction(
    '[GlTableSettings] Load GlTableSettings success',
    props<{ glTableSettings: GlTableSettings }>()
);

export const loadGlTableSettingsByIdFail = createAction(
    '[GlTableSettings] Load GlTableSettings fail',
    props<{ error: any; id: string }>()
);

// Update GlTableSettings actions

export const updateGlTableSettings = createAction(
    '[GlTableSettings] Update',
    props<{ payload: UpdateGlTableSettingsPayload; options?: OptimisticableActionOptions }>()
);

export const patchGlTableSettings = createAction(
    '[GlTableSettings] Patch',
    props<{ payload: PatchGlTableSettingsPayload; options?: OptimisticableActionOptions }>()
);

export const patchGlTableSettingsSuccess = createAction(
    '[GlTableSettings] Patch success',
    props<PatchGlTableSettingsPayload>()
);

export const patchGlTableSettingsFail = createAction(
    '[GlTableSettings] Patch fail',
    props<{ error: any; originAction: Action }>()
);
