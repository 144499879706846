import { AccountsEffects } from './account/account.effects';
import { ActionEffects } from './actions/action.effects';
import { AllocationEffects } from './allocation/allocation.effects';
import { AppOperationEffects } from './app-operation/app-operation.effects';
import { ApprovalRequestEffects } from './approval-request/approval-request.effects';
import { BacklogTaskEffects } from './backlog-task/backlog-task.effects';
import { BudgetTableEffects } from './budget-table/budget-table.effects';
import { CalendarEffects } from './calendar/calendar.effects';
import { ChangeRequestEffects } from './change-request/change-request.effects';
import { CommentEffects } from './comment/comment.effects';
import { CommunicationEffects } from './communication/communication.effects';
import { DecisionEffects } from './decision/decision.effects';
import { DocumentEffects } from './document/document.effects';
import { ExecutiveDashboardEffects } from './executive-dashboard/executive-dashboard.effects';
import { FolderEffects } from './folder/folder.effects';
import { GlTableSettingsEffects } from './gl-table-settings/gl-table-settings.effects';
import { HandsontableSettingsEffects } from './handsontable-settings/handsontable-settings.effects';
import { IssueEffects } from './issue/issue.effects';
import { KanbanBoardEffects } from './kanban/kanban.effects';
import { LayoutEffects } from './layout/layout.effects';
import { MinuteEffects } from './minute/minute.effects';
import { NotificationEffects } from './notification/notification.effects';
import { PortfolioEffects } from './portfolio/portfolio.effects';
import { ProgramMemberEffects } from './program-member/program-member.effects';
import { ProgramRoleEffects } from './program-role/program-role.effects';
import { ProgramStatusEffects } from './program-status-report/program-status-report.effects';
import { ProgramsEffects } from './program/program.effects';
import { ProjectFieldEffects } from './project-field/project-field.effects';
import { ProjectMemberEffects } from './project-member/project-member.effects';
import { ListContainerEffects } from './project-phase-container/list-container.effects';
import { ProjectPhaseEffects } from './project-phase/project-phase.effects';
import { ProjectRequestEffects } from './project-request/project-request.effects';
import { ProjectRoleEffects } from './project-role/project-role.effects';
import { ProjectStatusEffects } from './project-status-report/project-status-report.effects';
import { ProjectTemplateEffects } from './project-template/project-template.effects';
import { ProjectsEffects } from './project/project.effects';
import { ReminderEffects } from './reminder/reminder.effects';
import { SiteMemberEffects } from './resource/site-member.effects';
import { RiskEffects } from './risks/risk.effects';
import { ScheduleTaskTemplateEffects as ScheduleTaskEffects } from './schedule-template/schedule-task.effects';
import { SiteRoleEffects } from './site-role/site-role.effects';
import { SiteSettingsEffects } from './site-settings/site-settings.effects';
import { SiteEffects } from './site/site.effects';
import { SprintEffects } from './sprint/sprint.effects';
import { SubscriptionPlanEffects } from './subscription-plan/subscription-plan.effects';
import { SubscriptionEffects } from './subscription/subscription.effects';
import { TimesheetEffects } from './timesheet/timesheet.effects';
import { UserLogEffects } from './user-log/user-log.effects';
import { VendorEffects } from './vendor/vendor.effects';

export const effects = [
    AppOperationEffects,
    SiteEffects,
    ProgramsEffects,
    ProjectsEffects,
    DocumentEffects,
    AccountsEffects,
    SubscriptionPlanEffects,
    SubscriptionEffects,
    ChangeRequestEffects,
    ProjectPhaseEffects,
    CalendarEffects,
    MinuteEffects,
    ProjectStatusEffects,
    PortfolioEffects,
    ProjectTemplateEffects,
    CommunicationEffects,
    ProjectRequestEffects,
    SiteMemberEffects,
    AllocationEffects,
    ScheduleTaskEffects,
    BudgetTableEffects,
    ProjectFieldEffects,
    ApprovalRequestEffects,
    ReminderEffects,
    TimesheetEffects,
    KanbanBoardEffects,
    CommentEffects,
    HandsontableSettingsEffects,
    LayoutEffects,
    SiteRoleEffects,
    ProjectRoleEffects,
    ProgramRoleEffects,
    ProjectMemberEffects,
    ProgramMemberEffects,
    ListContainerEffects,
    RiskEffects,
    ActionEffects,
    IssueEffects,
    DecisionEffects,
    ExecutiveDashboardEffects,
    FolderEffects,
    BacklogTaskEffects,
    SprintEffects,
    NotificationEffects,
    UserLogEffects,
    SiteSettingsEffects,
    ProgramStatusEffects,
    GlTableSettingsEffects,
    VendorEffects,
];
