import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getPatched } from 'app/utils/json-patch';
import { deepClone } from 'fast-json-patch';
import {
    AsyncUpdateStatusDefault,
    AsyncUpdateStatusDefaultLoad,
    AsyncUpdateStatusDefaultLoadSuccess,
} from '../shared/async-update-status.store';
import { ScheduleTask } from './models';
import * as ScheduleTaskTemplateActions from './schedule-task.actions';
import { adapter, initialState } from './schedule-task.store';

export const reducer = createReducer(
    initialState,

    on(ScheduleTaskTemplateActions.loadScheduleTasksByContainerId, (state, action) => {
        const loadingStatusMap = state.loadingStatusByContainerId;
        const scheduleId = action.payload.id;

        return {
            ...state,
            loadingStatusByContainerId: {
                ...loadingStatusMap,
                [scheduleId]: {
                    ...(loadingStatusMap[scheduleId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoad,
                },
            },
        };
    }),

    on(ScheduleTaskTemplateActions.loadScheduleTasksByContainerIdSuccess, (state, payload) => {
        const existingTasks = Object.values(state.entities).filter(
            (t) => t.listContainerId === payload.containerId
        );
        const newState = adapter.addMany(
            payload.tasks,
            adapter.removeMany(
                existingTasks.map((s) => s.id),
                state
            )
        );
        const loadingStatusMap = state.loadingStatusByContainerId;
        const scheduleId = payload.containerId;

        return {
            ...newState,
            loadingStatusByContainerId: {
                ...loadingStatusMap,
                [scheduleId]: {
                    ...(loadingStatusMap[scheduleId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                },
            },
        };
    }),

    on(ScheduleTaskTemplateActions.loadScheduleTasksByContainerIdFail, (state, payload) => {
        const loadingStatusMap = state.loadingStatusByContainerId;
        const scheduleId = payload.containerId;

        return {
            ...state,
            loadingStatusByContainerId: {
                ...loadingStatusMap,
                [scheduleId]: {
                    ...(loadingStatusMap[scheduleId] ?? {}),
                    ...AsyncUpdateStatusDefault,
                    loadingFailed: true,
                    error: payload.error,
                },
            },
        };
    }),

    on(ScheduleTaskTemplateActions.loadScheduleTasksBySiteId, (state, action) => {
        const loadingStatusMap = state.loadingStatusBySiteId;
        const siteId = action.payload.siteId;

        return {
            ...state,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoad,
                },
            },
        };
    }),

    on(ScheduleTaskTemplateActions.loadScheduleTasksBySiteIdSuccess, (state, payload) => {
        const existingTasks = Object.values(state.entities).filter((t) =>
            payload.listContainerIds.includes(t.listContainerId)
        );
        const newState = adapter.addMany(
            payload.tasks,
            adapter.removeMany(
                existingTasks.map((s) => s.id),
                state
            )
        );
        const loadingStatusMap = state.loadingStatusBySiteId;
        const siteId = payload.siteId;
        const loadingByContainerIdMap = deepClone(state.loadingStatusByContainerId);
        payload.listContainerIds.forEach(
            (id) =>
                (loadingByContainerIdMap[id] = {
                    ...(loadingByContainerIdMap[id] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                })
        );

        return {
            ...newState,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefaultLoadSuccess,
                },
            },
            loadingStatusByContainerId: loadingByContainerIdMap,
        };
    }),

    on(ScheduleTaskTemplateActions.loadScheduleTasksBySiteIdFail, (state, payload) => {
        const loadingStatusMap = state.loadingStatusBySiteId;
        const siteId = payload.siteId;

        return {
            ...state,
            loadingStatusBySiteId: {
                ...loadingStatusMap,
                [siteId]: {
                    ...(loadingStatusMap[siteId] ?? {}),
                    ...AsyncUpdateStatusDefault,
                    loadingFailed: true,
                    error: payload.error,
                },
            },
        };
    }),

    on(ScheduleTaskTemplateActions.addScheduleTask, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.addOneOptimistic(new ScheduleTask(action.payload), state);
    }),

    on(ScheduleTaskTemplateActions.addScheduleTaskSuccess, (state, payload) =>
        payload.options?.optimistic
            ? adapter.addOneOptimisticSuccess(payload.task, state)
            : adapter.addOne(payload.task, state)
    ),

    on(ScheduleTaskTemplateActions.addScheduleTaskFail, (state, { taskId }) =>
        adapter.addOneOptimisticFail(taskId, state)
    ),

    on(ScheduleTaskTemplateActions.patchScheduleTasks, (state, action) => {
        if (!action.options?.optimistic) return state;
        const patched = action.payload.map((p) => getPatched(state.entities[p.id], p.patch));
        const update: Update<ScheduleTask>[] = patched.map((entity) => ({
            id: entity.id,
            changes: entity,
        }));
        return adapter.updateMany(update, state);
    }),

    on(ScheduleTaskTemplateActions.patchScheduleTasksSuccess, (state, { payload }) => {
        const patched = payload
            .filter((p) => state.entities[p.id])
            .map((p) => getPatched(state.entities[p.id], p.patch));
        const update: Update<ScheduleTask>[] = patched.map((entity) => ({
            id: entity.id,
            changes: entity,
        }));
        return adapter.updateMany(update, state);
    }),

    on(ScheduleTaskTemplateActions.patchScheduleTasksFail, (state) => state),

    on(ScheduleTaskTemplateActions.deleteScheduleTask, (state, action) => {
        if (!action.options?.optimistic) return state;
        return adapter.removeOne(action.payload.id, state);
    }),

    on(ScheduleTaskTemplateActions.deleteScheduleTaskSuccess, (state, payload) =>
        adapter.removeOne(payload.id, state)
    ),

    on(ScheduleTaskTemplateActions.importMicrosoftProjectTasks, (state, payload) => {
        const currentTaskIds = Object.values(state.entities)
            .filter((t) => t.listContainerId === payload.listContainerId)
            .map((t) => t.id);
        return adapter.addMany(payload.tasks, adapter.removeMany(currentTaskIds, state));
    }),

    on(ScheduleTaskTemplateActions.deleteScheduleTaskFail, (state) => state)
);
