import { createAction, props } from '@ngrx/store';
import { ProgramIdPayload } from '../program/program.payloads';
import { LoadByProjectIdPayload, LoadBySiteIdPayload } from '../shared/models/load.payload';
import { UserLog } from './models';

// Load UserLogs actions

export const loadUserLogsBySiteId = createAction(
    '[UserLog] Load by Site id',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadUserLogsBySiteIdSuccess = createAction(
    '[UserLog] Load by Site id success',
    props<{ logs: UserLog[] }>()
);

export const loadUserLogsBySiteIdFail = createAction(
    '[UserLog] Load by Site id fail',
    props<{ error: any }>()
);

export const loadUserLogsByProgramId = createAction(
    '[UserLog] Load by Program id',
    props<{ payload: ProgramIdPayload }>()
);

export const loadUserLogsByProgramIdSuccess = createAction(
    '[UserLog] Load by Program id success',
    props<{ logs: UserLog[] }>()
);

export const loadUserLogsByProgramIdFail = createAction(
    '[UserLog] Load by Program id fail',
    props<{ error: any }>()
);

export const loadUserLogsByProjectId = createAction(
    '[UserLog] Load by Project id',
    props<{ payload: LoadByProjectIdPayload }>()
);

export const loadUserLogsByProjectIdSuccess = createAction(
    '[UserLog] Load by Project id success',
    props<{ logs: UserLog[] }>()
);

export const loadUserLogsByProjectIdFail = createAction(
    '[UserLog] Load by Project id fail',
    props<{ error: any }>()
);

// Add UserLog
export const addUserLog = createAction('[UserLog] Add', props<{ log: UserLog }>());
