import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AlertDialogComponent } from 'app/core/services/dialog/alert-dialog/alert-dialog.component';
import { EntityPatchSuccessResponse } from 'app/core/services/rest-api/features/patch.response';
import { SnackBarService } from 'app/services/snack-bar/snack-bar.service';
import { getPatch } from 'app/utils/json-patch';
import { CommandResult } from 'app/utils/network';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { SiteMemberSelectors } from '.';
import { AppStore } from '..';
import { RestApiService } from '../../services/rest-api';
import { okEmptyAction } from '../project/project.actions';
import { Model } from '../shared/models/base.model';
import { OptimisticDefaults } from '../shared/optimisticable-action';
import { SiteMember } from './models';
import * as SiteMemberActions from './site-member.actions';

@Injectable()
export class SiteMemberEffects {
    loadMembersBySiteId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteMemberActions.loadMembersBySiteId),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response) =>
                        response.payload.map((member) => Model.createFromDto(SiteMember, member))
                    ),
                    map((members: SiteMember[]) =>
                        SiteMemberActions.loadSiteMembersSuccess({
                            siteMembers: members,
                        })
                    ),
                    catchError((error) => of(SiteMemberActions.loadSiteMembersFail({ error })))
                )
            )
        )
    );

    loadMySiteMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteMemberActions.loadMySiteMember),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: CommandResult) =>
                        SiteMemberActions.loadMySiteMemberSuccess({
                            siteMember: Model.createFromDto(SiteMember, response.payload),
                        })
                    ),
                    catchError((error) => of(SiteMemberActions.loadMySiteMemberFail({ error })))
                )
            )
        )
    );

    inviteSiteMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteMemberActions.inviteSiteMembersToSite),
            mergeMap((action) => {
                this.snackBarService.showInfiniteBar('Inviting member to the Site...');
                return this.restApiService.process(action).pipe(
                    map((response) =>
                        response.payload.map((member) => Model.createFromDto(SiteMember, member))
                    ),
                    map((members) => {
                        this.snackBarService.hide();
                        return SiteMemberActions.inviteSiteMembersToSiteSuccess({ members });
                    }),
                    catchError((errorResponse) => {
                        this.snackBarService.hide();
                        if (errorResponse.message) {
                            const dialogRef: MatDialogRef<AlertDialogComponent, boolean> =
                                this.matDialog.open(AlertDialogComponent);
                            dialogRef.componentInstance.title = 'Error';
                            dialogRef.componentInstance.message =
                                'License limit reached. Please add a license';
                        }

                        return of(
                            SiteMemberActions.inviteSiteMembersToSiteFail({ error: errorResponse })
                        );
                    })
                );
            })
        )
    );

    updateSiteMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteMemberActions.updateSiteMember),
            withLatestFrom(this.store$.select(SiteMemberSelectors.selectCurrentSiteMembers)),
            mergeMap(([action, siteMembers]) => {
                const memberToPatch = siteMembers.find((member) => member.id === action.payload.id);
                const patch = getPatch(memberToPatch, action.payload.changes);
                return of(
                    SiteMemberActions.patchSiteMember({
                        payload: { id: action.payload.id, patch },
                        options: action.options,
                    })
                );
            })
        )
    );

    patchSiteMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteMemberActions.patchSiteMember),
            mergeMap((action) =>
                this.restApiService.process(action).pipe(
                    map((response: EntityPatchSuccessResponse) => {
                        if (action.options?.optimistic) return okEmptyAction();
                        return SiteMemberActions.patchSiteMemberSuccess({
                            id: action.payload.id,
                            patch: response.payload,
                        });
                    }),
                    catchError((errorResponse) => {
                        this.snackBarService.show(errorResponse.error, 3500);
                        return of(
                            SiteMemberActions.patchSiteMemberFail({
                                error: errorResponse,
                                originAction: action,
                            })
                        );
                    })
                )
            )
        )
    );

    deleteManySiteMembers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteMemberActions.deleteManySiteMembers),
            mergeMap((action) => {
                const actions = action.payload.ids.map((id) =>
                    SiteMemberActions.deleteSiteMember({
                        payload: { id },
                        options: OptimisticDefaults,
                    })
                );
                return actions;
            })
        )
    );

    deleteSiteMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteMemberActions.deleteSiteMember),
            mergeMap((action) => {
                if (!action.options?.optimistic) {
                    this.snackBarService.showInfiniteBar('Processing...');
                }
                return this.restApiService.process(action).pipe(
                    map(() => {
                        if (!action.options?.optimistic) return okEmptyAction();
                        this.snackBarService.hide();
                        return SiteMemberActions.deleteSiteMemberSuccess({
                            id: action.payload.id,
                        });
                    }),
                    catchError((error) => {
                        this.snackBarService.hide();
                        return of(
                            SiteMemberActions.deleteSiteMemberFail({ error, originAction: action })
                        );
                    })
                );
            })
        )
    );

    resendInvite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteMemberActions.resendInvite),
            mergeMap((action) => this.restApiService.process(action))
        )
    );

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private store$: Store<AppStore.AppState>,
        private snackBarService: SnackBarService,
        private matDialog: MatDialog
    ) {}
}
