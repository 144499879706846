import {
    SubscriptionRule,
    SubscriptionRuleCreateOptions,
    SubscriptionRuleUpdateOptions,
} from 'app/core/models/billing';
import { JsonProperty } from 'app/utils/json-mapper';
import { ImageModel, ImageShape } from '../../shared/models';
import { Model } from '../../shared/models/base.model';

export class Site extends Model {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('name')
    name: string = undefined;

    @JsonProperty('accountId')
    accountId: string = undefined;

    @JsonProperty('customPlanIds')
    customPlanIds?: string[] = undefined;

    @JsonProperty('createdAtDate')
    createdAtDate: string = undefined;

    @JsonProperty({ name: 'userLimitRule', clazz: SubscriptionRule })
    userLimitRule?: SubscriptionRule = undefined;

    @JsonProperty({ name: 'projectLimitRule', clazz: SubscriptionRule })
    projectLimitRule?: SubscriptionRule = undefined;

    @JsonProperty('logo')
    logo?: ImageModel = getDefaultLogo();

    @JsonProperty('requestedSiteOwner')
    requestedSiteOwner?: RequestedSiteOwner = undefined;

    completixAdminNote?: string;
    suggestFirstProjectCreation?: boolean;
    expired?: boolean;
    grace?: boolean;

    constructor(init?: Partial<Site>) {
        super();
        Object.assign(this, init);
    }
}

export interface RequestedSiteOwner {
    requestDate: Date;
    siteMemberId: string;
}

export interface SiteCreateOptions {
    siteName: string;
    userLimitRule?: SubscriptionRuleCreateOptions;
    projectLimitRule?: SubscriptionRuleCreateOptions;
}

export interface SiteUpdateOptions {
    name?: string;
    userLimitRule?: SubscriptionRuleUpdateOptions;
    projectLimitRule?: SubscriptionRuleUpdateOptions;
    logo?: ImageModel;
    location?: string;
}

export const DefaultSiteLogoUrl = 'assets/img/default-site-logo.svg';

export function getDefaultLogo(excludedColors: string[] = void 0) {
    let colors = [
        '#a5382c',
        '#a5702c',
        '#a5a22c',
        '#7aa52c',
        '#2ca586',
        '#2c6ea5',
        '#782ca5',
        '#a52c5e',
    ];

    if (excludedColors) {
        colors = colors.filter((c) => excludedColors.indexOf(c) < 0);
        if (colors.length === 0) colors.push('#aaa');
    }

    const randomColorIndex = Math.round(Math.random() * (colors.length - 1));
    return new ImageModel({
        imageDataUrl: DefaultSiteLogoUrl,
        aspectRatio: 1,
        shape: ImageShape.CIRCLE,
        backgroundColor: colors[randomColorIndex],
        isDefault: true,
    });
}

export enum SiteModelFields {
    Id = 'id',
    Name = 'name',
    AccountId = 'accountId',
    CustomPlanId = 'customPlanId',
    CreatedAtDate = 'createdAtDate',
    UserLimitRule = 'userLimitRule',
    ProjectLimitRule = 'projectLimitRule',
    Logo = 'logo',
}

export interface SiteMetrics extends SiteMetricsBase {
    portfoliosSiteMetrics: PortfolioSiteMetrics[];
}

export interface SiteMetricsBase {
    overallSiteMetrics: SiteMetricsOptions;
    yearsSiteMetrics: YearSiteMetrics[];
}

export interface YearSiteMetrics {
    year: number;
    siteMetrics: SiteMetricsOptions;
}

export interface PortfolioSiteMetrics extends SiteMetricsBase {
    portfolioId: string;
}

export interface SiteMetricsOptions {
    successRate: number;
    overduePercent: number;
    highRisksPercent: number;
    criticalIssuesPercent: number;
    overBudgetPercent: number;
    deliveredLatePercent: number;
    redStatusPercent: number;
    yellowStatusPercent: number;
    issueResolutionTimeframe: number;
    averageProjectBudget: number;
    averageProjectDuration: number;
    averageVariance: number;
    averageProjectDelay: number;
    overdueTimesheets: number;
}

export enum SiteMetricsSection {
    SuccessRate = 'SuccessRate',
    OverduePercent = 'OverduePercent',
    HighRisksPercent = 'HighRisksPercent',
    CriticalIssuesPercent = 'CriticalIssuesPercent',
    OverBudgetPercent = 'OverBudgetPercent',
    DeliveredLate = 'DeliveredLate',
    RedStatus = 'RedStatus',
    YellowStatus = 'YellowStatus',
    IssueResolutionTimeframe = 'IssueResolutionTimeframe',
    AverageProjectBudget = 'AverageProjectBudget',
    AverageVariance = 'AverageVariance',
    AverageProjectDuration = 'AverageProjectDuration',
    AverageProjectDelay = 'AverageProjectDelay',
    OverdueTimesheets = 'OverdueTimesheets',
}
