import { Folder } from 'app/core/store/folder';
import { Minute } from 'app/core/store/minute';
import { ContextMenuItem } from 'app/shared-ui/dumb-components/context-menu/context-menu.directive';
import {
    FlatNode,
    TreeMenuItemsFilterPredicate,
} from 'app/shared-ui/dumb-components/tree/tree-source';
import { sharedMenuItems } from 'app/shared-ui/menu-items';

export class MinuteTreeFlatNode implements FlatNode {
    readonly id: string;
    readonly parentId: string;
    readonly name: string;
    readonly type?: string;
    readonly selectable?: boolean;
    readonly link?: string;
    readonly readonly?: boolean;

    constructor(init?: Partial<MinuteTreeFlatNode>) {
        Object.assign(this, init);
    }

    equals(node: MinuteTreeFlatNode): boolean {
        return this.id === node.id && this.parentId === node.parentId && this.name === node.name;
    }

    accepts(child: MinuteTreeFlatNode): boolean {
        return this.type === MinuteTreeItemType.Folder;
    }

    getMenuItems(menuItemsFilterPredicate?: TreeMenuItemsFilterPredicate) {
        return this.type === MinuteTreeItemType.Folder
            ? minuteTreeContextMenuItems.filter((i) => menuItemsFilterPredicate[i.type])
            : [sharedMenuItems.share];
    }
}

export const minuteTreeRootFolderId = 'minute-root';

export function getMinuteFolderSourceNodes(
    folders: Folder[],
    minutes: Minute[]
): {
    rootSourceNode: MinuteTreeFlatNode;
    descendantSourceNodes: MinuteTreeFlatNode[];
} {
    if (!folders || !minutes) {
        return {
            rootSourceNode: null,
            descendantSourceNodes: null,
        };
    }

    const rootSourceNode = new MinuteTreeFlatNode({
        id: minuteTreeRootFolderId,
        name: 'Minutes',
        type: MinuteTreeItemType.Folder,
        selectable: true,
        readonly: false,
        link: '/',
    });

    const descendantFolderNodes: MinuteTreeFlatNode[] = folders.map(
        (doc) =>
            new MinuteTreeFlatNode({
                id: doc.id,
                parentId: doc.parentId,
                name: doc.name,
                type: MinuteTreeItemType.Folder,
                selectable: true,
                readonly: false,
                link: '/',
            })
    );

    const descendantMinuteNodes: MinuteTreeFlatNode[] = minutes
        .filter((min) => !min.isDocument)
        .map(
            (minute) =>
                new MinuteTreeFlatNode({
                    id: minute.id,
                    parentId: minute.parentFolderId,
                    name: minute.name,
                    type: MinuteTreeItemType.Minute,
                    selectable: true,
                    readonly: false,
                    link: '/',
                })
        );

    const descendantDocumentNodes: MinuteTreeFlatNode[] = minutes
        .filter((min) => min.isDocument)
        .map(
            (minute) =>
                new MinuteTreeFlatNode({
                    id: minute.id,
                    parentId: minute.parentFolderId,
                    name: minute.name,
                    type: MinuteTreeItemType.Document,
                    selectable: true,
                    readonly: false,
                    link: '/',
                })
        );
    const descendantSourceNodes = [
        ...descendantFolderNodes,
        ...descendantMinuteNodes,
        ...descendantDocumentNodes,
    ];
    return { rootSourceNode, descendantSourceNodes };
}

export enum MinuteTreeItemType {
    Folder = 'folder',
    Minute = 'minute',
    Document = 'document',
}

export enum MinuteContextMenuItemType {
    NewFolder = 'New Folder',
    NewMinute = 'New Minute',
    NewDocument = 'New Document',
    SendPreview = 'Send Preview',
}

export const minuteTreeContextMenuItems: ContextMenuItem[] = [
    {
        name: 'New Folder',
        type: MinuteContextMenuItemType.NewFolder,
        imgSrc: 'assets/icon/tree/folder.svg?version=0.0.8',
    },
    {
        name: 'New minute (Template)',
        type: MinuteContextMenuItemType.NewMinute,
        svgIconName: 'ctx-min',
    },
    {
        name: 'New Minute (Free form)',
        type: MinuteContextMenuItemType.NewDocument,
        svgIconName: 'ctx-doc',
    },
];

export enum MinuteSortType {
    Date = 'date',
    Subject = 'subject',
}

export interface MinuteMember {
    title: string;
    imgSrc: string;
    avatarColor: string;
}

export function getSortedMinutes(minutes: Minute[], sortType: MinuteSortType): Minute[] {
    return minutes.sort((a, b) => {
        if (sortType === MinuteSortType.Date) {
            if (new Date(a.date) > new Date(b.date)) return -1;
            if (new Date(a.date) < new Date(b.date)) return 1;
        }
        if (sortType === MinuteSortType.Subject) {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
        }
        return 0;
    });
}
