import { getRandomBrightColor } from 'app/core/models/shared-models';
import { JsonProperty } from 'app/utils/json-mapper';
import { Model } from '../../shared/models/base.model';
import { HourlyRate } from '../../shared/models/hourly-rate.model';

export class ProjectMember extends Model {
    @JsonProperty('id')
    id: string = undefined;

    @JsonProperty('projectId')
    projectId: string = undefined;

    @JsonProperty('siteMemberId')
    siteMemberId: string = undefined;

    @JsonProperty('name')
    name: string = undefined;

    @JsonProperty('email')
    email: string = undefined;

    @JsonProperty('roleId')
    roleId: string = undefined;

    @JsonProperty('roleTitle')
    roleTitle?: string = undefined;

    @JsonProperty('additionalSponsorTitle')
    additionalSponsorTitle?: string = undefined;

    @JsonProperty('additionalManagerTitle')
    additionalManagerTitle?: string = undefined;

    @JsonProperty('hourlyRate')
    hourlyRate?: HourlyRate = undefined;

    @JsonProperty('imageDataUrl')
    imageDataUrl?: string = undefined;

    @JsonProperty('status')
    status: ProjectMemberStatus = undefined;

    @JsonProperty('statusChangeDate')
    statusChangeDate?: Date;

    @JsonProperty('isPrimaryManager')
    isPrimaryManager?: boolean;

    @JsonProperty('manualEffortHours')
    manualEffortHours?: number;

    @JsonProperty('avatarColor')
    avatarColor?: string = getRandomBrightColor();

    deleted?: boolean;

    constructor(init?: Partial<ProjectMember>) {
        super();
        Object.assign(this, init);
    }
}

export enum ProjectMemberStatus {
    Active = 'Active',
    Closed = 'Closed',
}
