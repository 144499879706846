import { Action, createAction, props } from '@ngrx/store';
import { OptimisticableActionOptions } from 'app/core/store/shared/optimisticable-action';
import { LoadBySiteIdPayload } from '../shared/models/load.payload';
import { ProjectRole } from './models';
import {
    AddProjectRolePayload,
    PatchProjectRolePayload,
    ProjectRoleIdPayload,
    SetProjectRoleTagPayload,
    UpdateProjectRolePayload,
} from './project-role.payloads';

// Load ProjectRoles actions

export const loadProjectRoleTemplates = createAction('[ProjectRole] Load');

export const loadProjectRoleTemplatesSuccess = createAction(
    '[ProjectRole] Load success',
    props<{ roles: ProjectRole[] }>()
);

export const loadProjectRoleTemplatesFail = createAction(
    '[ProjectRole] Load fail',
    props<{ error: any }>()
);

export const loadProjectRolesBySite = createAction(
    '[ProjectRole] Load by Site',
    props<{ payload: LoadBySiteIdPayload }>()
);

export const loadProjectRolesBySiteSuccess = createAction(
    '[ProjectRole] Load by Site success',
    props<{ roles: ProjectRole[] }>()
);

export const loadProjectRolesBySiteFail = createAction(
    '[ProjectRole] Load by Site fail',
    props<{ error: any }>()
);

// Add ProjectRole actions

export const addProjectRole = createAction(
    '[ProjectRole] Add',
    props<{ payload: AddProjectRolePayload }>()
);

export const addProjectRoleSuccess = createAction(
    '[ProjectRole] Add success',
    props<{ role: ProjectRole }>()
);

export const addProjectRoleFail = createAction('[ProjectRole] Add fail', props<{ error: any }>());

// Update ProjectRole actions

export const updateProjectRole = createAction(
    '[ProjectRole] Update',
    props<{ payload: UpdateProjectRolePayload; options: OptimisticableActionOptions }>()
);

export const patchProjectRole = createAction(
    '[ProjectRole] Patch',
    props<{ payload: PatchProjectRolePayload; options: OptimisticableActionOptions }>()
);

export const patchProjectRoleSuccess = createAction(
    '[ProjectRole] Patch success',
    props<PatchProjectRolePayload>()
);

export const patchProjectRoleFail = createAction(
    '[ProjectRole] Patch fail',
    props<{ error: any; originAction: Action }>()
);

// Delete ProjectRole actions

export const deleteProjectRole = createAction(
    '[ProjectRole] Delete',
    props<{ payload: ProjectRoleIdPayload; options?: OptimisticableActionOptions }>()
);

export const deleteProjectRoleSuccess = createAction(
    '[ProjectRole] Delete success',
    props<ProjectRoleIdPayload>()
);

export const deleteProjectRoleFail = createAction(
    '[ProjectRole] Delete fail',
    props<{ error: any; originAction: Action }>()
);

// Set role tag actions

export const setProjectRoleTag = createAction(
    '[ProjectRole] Set tag',
    props<{ payload: SetProjectRoleTagPayload; options?: OptimisticableActionOptions }>()
);

export const setProjectRoleTagSuccess = createAction(
    '[ProjectRole] Set tag success',
    props<{ projectRoles: ProjectRole[] }>()
);

export const setProjectRoleTagFail = createAction(
    '[ProjectRole] Set tag fail',
    props<{ error: any; originAction: Action }>()
);
